import React, { Fragment } from 'react';
import { Grid, Header, Table, Icon, Button, Divider } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import Money from './shared/Money';
import Item from './shared/Item';
import Number from './shared/Number';
import { fromDate } from '../utils';

export default function DappSummaryTable({ dapp }) {

    const android = dapp.androidAppLink && <a href={dapp.androidAppLink} target="_blank"><Icon name="android" color="green" size="large" /></a>
    const apple = dapp.iosAppLink && <a href={dapp.iosAppLink} target="_blank"><Icon name="apple" color="black" size="large" /></a>
    
    const copyButton = <CopyToClipboard text={dapp.accountId}
                            /*onCopy={() => handleCopyClick({copied: true})}*/>
                            <Button primary className="dapp-copy-button">COPY</Button>
                        </CopyToClipboard>
	
	const description = <span style={{fontSize: "90%"}}>{dapp.description}</span>

    const numberOfContracts = dapp.contracts ? dapp.contracts.length : '';

	return (
		<div className="dapp-summary-table">
			{/* {logo} */}
            <Table compact basic="very"/* className="dapp-summary-table"*/>

					<Table.Body>
						
                        <Table.Row>
                            <Table.Cell className="cell-text">PRIMARY CONTRACT ADDRESS</Table.Cell>
                            <Table.Cell className="cell-text">DEPLOYED</Table.Cell>
                            <Table.Cell className="cell-text">DAPP CONTRACTS</Table.Cell>
                            <Table.Cell className="cell-text">OFFICIAL MOBILE APPS</Table.Cell>
                        </Table.Row>
						
                        <Table.Row>
                            <Table.Cell className="cell-text" style={{fontWeight: "700"}}>{dapp.accountId} ({dapp.contracts[0].name})</Table.Cell>
                            <Table.Cell className="cell-text" style={{fontWeight: "700"}}>{dapp.deployed}</Table.Cell>
                            {/* <Table.Cell id="green" className="cell-text">{numberOfContracts} {numberOfContracts !== '' && <span>LISTED</span>}</Table.Cell> */}
                            <Table.Cell className="cell-text" style={{fontWeight: "700"}}>{numberOfContracts}</Table.Cell>
                            <Table.Cell className="cell-text">{android}{apple}  </Table.Cell>
                        </Table.Row>
						
					</Table.Body>
				</Table>
				<div style={{margin: "0 10px"}}>
                    <div style={{marginBottom: "-10px", fontSize: "90%"}}>DESCRIPTION:</div>
                    <Divider />
                    <p style={{marginTop: "-7px", fontWeight: "600"}}>{description}</p>
                </div>
		</div>
	);
}
