import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { isEmail } from '../../utils';

import ResetPasswordForm from '../forms/ResetPasswordForm';
import UpdatePasswordForm from '../forms/UpdatePasswordForm';

class ResetPassword extends Component {
	state = {
		data: {
			email: '',
			password: '',
			confirmPassword: ''
		},
		errors: {},
		loading: false,
		isEmailSent: false
	};

	onChange = (e) =>
		console.log(e.target.value) ||
		this.setState({
			data: { ...this.state.data, [e.target.name]: e.target.value }
		});

	handleReset = (e) => {
		e.preventDefault();
		const errors = this.validateEmail(this.state.data.email);
		this.setState({ errors });
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true });
			this.props
				.resetPassword(this.state.data.email)
				.then(() => this.setState({ errors: {}, loading: false, isEmailSent: true }))
				.catch((err) =>
					this.setState({
						errors: { auth: 'Unable to issue reset request: Email not found.' },
						loading: false
					})
				);
		}
	};

	handleUpdate = (e) => {
		e.preventDefault();
		const errors = this.validatePassword(this.state.data.password, this.state.data.confirmPassword);
		this.setState({ errors });
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true });
			const token = this.props.routeParams.get('token');
			this.props
				.updatePassword(this.state.data.password, token)
				.then(() => this.setState({ errors: {}, loading: false }, () => this.props.history.push('/login')))
				.catch((err) =>
					this.setState({
						errors: { auth: 'Unable to reset password: The link may have expired.' },
						loading: false
					})
				);
		}
	};

	validateEmail = (email) => {
		let errors = {};
		if (!isEmail(email)) errors.email = 'Invalid email';
		return errors;
	};

	validatePassword = (pwd, cPwd) => {
		return pwd === cPwd;
	};

	renderAfterResetMessage() {
		const { data: { email } } = this.state;
		return (
			<Message
				success
				header="Your password reset request was successful"
				content={`A password reset link has been sent to ${email}. Please use the link to create a new password.`}
			/>
		);
	}

	renderForm = () => {
		const { data: { email, password, confirmPassword }, loading, errors } = this.state;
		const token = this.props.routeParams.get('token');
		return (
			<div id="formFields">
				{token && (
					<UpdatePasswordForm
						password={password}
						confirmPassword={confirmPassword}
						onSubmit={this.handleUpdate}
						onChange={this.onChange}
						errors={errors}
						loading={loading}
					/>
				)}
				{!token && (
					<ResetPasswordForm
						email={email}
						onSubmit={this.handleReset}
						onChange={this.onChange}
						errors={errors}
						loading={loading}
					/>
				)}
			</div>
		);
	};

	render() {
		return (
			<div id="auth-form">
				<div className="title small">RESET PASSWORD</div>
				{!this.state.isEmailSent ? this.renderForm() : this.renderAfterResetMessage()}
				<div className="footer">
					<p>
						DON'T HAVE AN ACCOUNT? <Link to="/signup">SIGN UP</Link>
					</p>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, { location: { search } }) => ({
	routeParams: new URLSearchParams(search)
});

ResetPassword = withRouter(connect(mapStateToProps, actions)(ResetPassword));

export default ResetPassword;
