import { DG_USER } from '../constants';

export function authHeader() {
	// return authorization header with jwt token
	let user = JSON.parse(localStorage.getItem(DG_USER));

	if (user && user.access_token) {
		return { Authorization: 'Bearer ' + user.access_token };
	} else {
		return {};
	}
}
