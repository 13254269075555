import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Table, Button } from 'semantic-ui-react';
import { getUser } from '../redux/reducers';

const SubscriptionFiltersTable = ({
    subscription,
    handleCancel,
    handleAddFilters, 
    filters,
    errors,
    deleteFilter
}) => {

    // let today = new Date();
    // let dd = today.getDate();
    // let mm = today.getMonth()+1; 
    // let yyyy = today.getFullYear();
    // if(dd<10) {dd='0'+dd;} 
    // if(mm<10) {mm='0'+mm;} 
    // let date = yyyy+'-'+mm+'-'+dd;

    // console.log("THIS IS THE DATE: ", date)

    // const validSubs = props.subscriptions && props.subscriptions.filter(el => el.expiry.slice(0, 10) > date);
    // const expiredSubs = props.subscriptions && props.subscriptions.filter(el => el.expiry.slice(0, 10) <= date);
    // const allSubscriptions = [ ...validSubs, ...expiredSubs ]

    console.log("THIS IS THE FILTERS IN THE FILTERS TABLE: ", filters)
    console.log("SUBSCRIPTION IN THE FILTERS TABLE: ", subscription)

    const rowsToDisplay = filters.map(filter => {
        return (
            <Table.Row key={Math.random()}>
                <Table.Cell  width={3}><span className="text-gray">{filter.filterType}</span></Table.Cell>

                <Table.Cell style={{wordWrap: 'break-word', wordBreak: 'break-word', overflow: "auto"}}>
                    {filter.ids && filter.ids.map(id => filter.ids.indexOf(id) === filter.ids.length - 1 ? ` ${id} ` : ` ${id}, `)}
                </Table.Cell>

                <Table.Cell style={{wordWrap: 'break-word', wordBreak: 'break-word', overflow: "auto"}}>
                    {filter.signatures && filter.signatures.map(signature => filter.signatures.indexOf(signature) === filter.signatures.length - 1 ? ` ${signature} ` : ` ${signature}, `)}
                    {filter.signature && filter.signature.map(sign => filter.signature.indexOf(sign) === filter.signature.length - 1 ? ` ${sign} ` : ` ${sign}, `)}
                </Table.Cell>

                <Table.Cell
                    // className={sub.expiry.slice(0, 10) <= date && "disabled"}
                    style={{cursor: "pointer", textAlign: "center"}}
                    onClick={e => deleteFilter(filter, subscription)}
                    width={1}>
                    <i className="close icon red"></i>
                </Table.Cell>
            </Table.Row>
        )
    })

    return (
        <div>

            <Button 
                type="submit" 
                style={{margin: "30px 0 20px 0", float: "right"}} 
                primary 
                // disabled={ids.length === 0} 
                onClick={e => {/*onSubmit(e);*/ handleAddFilters(subscription)}}
                >
                ADD FILTER
            </Button>

            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Filter Type</Table.HeaderCell>
                    <Table.HeaderCell> {/*Values*/}
                        {subscription.subscriptionType === "EVENT" ? `Contract ID's` : "Values"}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Events</Table.HeaderCell>
                    <Table.HeaderCell>Delete</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rowsToDisplay}
                </Table.Body>
            </Table>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state)
});

export default connect(mapStateToProps, actions)(SubscriptionFiltersTable);
