import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getLatestNftsData } from '../redux/reducers';
import * as actions from '../redux/actions';
import NFTExplorerList from "../atoms/NFTExplorerList";
import { removeEmpty } from '../utils';
import Panel from '../atoms/Panel';

const LatestNfts = (props) => {

	const [limit, setLimit] = useState(12);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState('createdOn');
	const [order, setOrder] = useState('desc');
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	
	const { nftsData } = props;
	const nfts = nftsData && nftsData.nftsData || [];
	const totalCount = nftsData && nftsData.totalCount || 0;
	const isLoading = nftsData && nftsData.isLoading || false;

    useEffect(() => { fetchData() }, [limit, page]);

	const fetchData = () => {
		 const queryParams = {
			offset,
			limit,
			page,
			sort,
			order,
		 }
		const { fetchLatestNfts, searchParams } = props;
		const params = { ...searchParams, ...queryParams};
		const queryParamsF = removeEmpty(params);
		fetchLatestNfts(queryParamsF)
		setLoading(false);
	};

	const onLoadMore = (params) => {
		setLoading(true);
		setOffset(params.offset);
		setPage(params.page)
		setLimit(limit);
	}
	return (
		<Panel>
			<NFTExplorerList isLoading={isLoading} onLoadMore={onLoadMore} nfts={nfts} totalCount={totalCount} limit={limit}/>
		</Panel>
	);
}

const mapStateToProps = (state) => ({
	nftsData: getLatestNftsData(state),
});

export default connect(mapStateToProps, actions)(LatestNfts);