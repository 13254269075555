import React, { Component } from 'react';
import Panel from './shared/Panel';
import PeriodSelector from './shared/PeriodSelector';
import ContractUsersChart from './containers/ContractUsersChart';
import Info from './shared/Info';

export default class ContractUsersChartPanel extends Component {
	state = {
		period: '1W'
	};

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
	};

	render() {
		const { period } = this.state;
		return (
			<Panel
				title={
					<React.Fragment>
						USERS <Info id="contractUsers" />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				<ContractUsersChart period={period} />
			</Panel>
		);
	}
}
