import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AccountSummary from '../organisms/AccountSummary';
import { Grid, Table, Image } from 'semantic-ui-react';
import Number from '../atoms/Number';
import * as actions from '../redux/actions';
import { getAccount } from '../redux/reducers';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import CodeSnippet from '../components/shared/CodeSnippet';
import { isMobile, localDateTime } from '../utils';
import eye from '../assets/images/eye@2x.png';
import Info from '../components/shared/Info';


const styles = {
	accountHeader: {
		display: 'flex',
	},
	paddingRight: {
		paddingRight: '20px'
	},
	accountId: {
		display: 'flex',
		paddingRight: '20px',
		margin: '0px',
		alignItems: 'end'
	},
	bigFont: {
		fontSize: '16px',
		borderTop: '0px'
	}

}

const AccountSummaryPanel = (props) => {
	const [isNodeAccount, setIsNodeAccount] = useState(false);
	const [showKey, setShowKey] = useState(false);
	const { match: { params: { accountId } }, 
	account
 } = props;
	// const account = {
	// 	"id": "0.0.15224816",
	// 	"publicKey": {
	// 		"ed25519": "061688d90775ee9806b29271ec1e161c489e97f5d218f41a41bbc37fbb49bef1"
	// 	},
	// 	"expiryDate": "2023-10-11T06:32:54.576Z",
	// 	"memo": "",
	// 	"stakedAccountId": "0.0.15224816",
	// 	"isNodeAccount": false,
	// 	"createdOn": "2023-07-11T22:52:54.576+0000"
	// }

	useEffect(() => { 
		fetchData();
	}, [])

	const handleClick = (value) => {
		setIsNodeAccount(value);
	};

	const onShowKey = () => {
		setShowKey(true);
	  };
	
	  const onHideKey = () => {
		setShowKey(false);
	  };

	  const fetchData = () => {
		const { fetchAccount, fetchAccountBalance, fetchAccountRecentTransactionsCount, id } = props;
		fetchAccountBalance(id);
		fetchAccountRecentTransactionsCount(id);
		fetchAccount(id);
	}

	return (<>
			<Helmet>
				<title>Dragon Glass - Accounts {accountId}</title>
			</Helmet>
				<Grid stackable>
					<Grid.Row style={{borderBottom: '1px solid black',}}>
							<div style={styles.accountHeader}>
								<h2 style={styles.accountId}>{accountId}</h2>
								<h2 style={{color: '#999'}}>\\Account</h2>
							</div>
					</Grid.Row>
					{
						account && (
						<Grid.Row width={16}>
							<Grid.Column width={11} textAlign="left" style={{
									fontWeight: '500',
									fontSize: '16px'
							}}>
								<Grid>
									<Grid.Row>
										<Grid.Column mobile={5} computer={3} tablet={3}>
											Balance
										</Grid.Column>
										<Grid.Column mobile={11} computer={13} tablet={13}>
											{account && account.balance || (account && account.balance === 0)  ? <Number>{account.balance}</Number> : "None"}
											{account && account.balance || (account && account.balance === 0) ? " ℏ" : ''}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column mobile={5} computer={3} tablet={3}>
										Memo
										</Grid.Column>
										<Grid.Column width={13} mobile={11} computer={13} tablet={13}>
										{account && account.memo ? account.memo : "None"}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column mobile={5} computer={3} tablet={3}>
										Key
										</Grid.Column>
										<Grid.Column width={13} mobile={11} computer={13} tablet={13}>
											<Image src={eye} rounded size="mini" onClick={onShowKey} style={{ width: '28px' }} />
										</Grid.Column>
									</Grid.Row>
								</Grid>
								

								{account && account.publicKey &&
											showKey && <CodeSnippet code={account.publicKey} onClose={onHideKey} title="Public Key(s)" />}
							</Grid.Column>
							<Grid.Column style={{fontWeight: '500', fontSize: '16px' }} width={5} textAlign="right"> 
								<Grid style={{textAlign: 'left'}}>
									<Grid.Row>
										<Grid.Column width={5}>
											Create Date:
										</Grid.Column>
										<Grid.Column width={11}>
											{localDateTime (account && account.createdOn)}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={5}>
										Expiry Date <Info id="accountExpiry" />
										</Grid.Column>
										<Grid.Column width={11}>
											{localDateTime (account && account.expiryDate)}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={5}>
										Staked To:
										</Grid.Column>
										<Grid.Column width={11}>
											{account && account.stakedAccountId && account.stakedAccountId !== '0.0.0' ? account.stakedAccountId : 'None'}
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						)
					}
					<Grid.Row>
						{/* <AccountSummary isNodeAccount={isNodeAccount} {...props} handleClick={handleClick} handleTab={props.handleTab}/> */}
					</Grid.Row>
				</Grid>
		</>
	);
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
	id: accountId,
	account: getAccount(state, accountId)
});

export default connect(mapStateToProps, actions)(AccountSummaryPanel);
