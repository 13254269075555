import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';

export default function CodeSnippet({ code, onClose, onCopy, children, ...rest }) {
	const content = children || code;
	return (
		<Segment className="code-snippet" {...rest}>
			{onClose && <Icon className="close clickable" name="close" onClick={onClose} style={{marginTop: "-10px"}} />}
			{onCopy && <Icon className="clickable" name="copy" title="Click to copy" onClick={onCopy} style={{float: "right", marginTop: "-10px"}} />}
			{content && <pre style={{ overflowX: 'auto' }}>{JSON.stringify(content, null, 2)}</pre>}
		</Segment>
	);
}
