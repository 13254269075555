import React, { useEffect, Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import Page from '../shared/Page';
import Panel from '../shared/Panel';

// import AccountLookup from '../containers/AccountLookup';
import Search from '../containers/Search';
import TokenSearch from '../containers/TokenSearch';

import '../../animate.css';

const FungibleTokenSearchPage = (props) => {
	useEffect(() => { window.scrollTo(0, 0) }, []);
  return (
		<Page className="animated fadeIn">
			<Grid padded stackable>
        <Grid.Column width={16}>
          <Search onlyHts="true" />
        </Grid.Column>
      </Grid>
      <div id="token-search-wrapper">
        <TokenSearch showTitle="true" />
      </div>
		</Page>
	);
}

export default FungibleTokenSearchPage;
