import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import '../animate.css';

const ApiCopyUrl = (props) => {

    return (
        <Popup
            trigger={<Icon name="copy" className="copy-icon" onClick={props.handleCopyClick} />}
            content={<span className={props.clicked ? "info-content animated flash" : "info-content"}>
                        {props.clicked ? "Copied!" : "Copy to clipboard"}
                    </span>}
        />
    )
}

export default ApiCopyUrl;
