import React from 'react';
import { Table } from 'semantic-ui-react';
import Money from './shared/Money';
import { Link } from 'react-router-dom';
import { adjustWithDecimals } from '../utils';
import Panel from './shared/Panel';
import Info from './shared/Info';

import '../animate.css';

export default function TransfersTable({ transfers = [], hidePriceSymbol = false, tokenDecimals = 0, tokenTransfer = false }) {
	return (
		<Panel
			title={ <React.Fragment><Info id="hbarTransfers" /></React.Fragment> }
		>
		<Table basic="very" size="small" className="animated fadeIn" >
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>ACCOUNT</Table.HeaderCell>
					<Table.HeaderCell>AMOUNT</Table.HeaderCell>
					{/* <Table.HeaderCell>{tokenTransfer ? 'QUANTITY' : 'AMOUNT'}</Table.HeaderCell> */}
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{transfers.map((transfer, i) => (
					<Table.Row key={i}>
						<Table.Cell width={1} style={{ paddingLeft: "11px" }}>
							<Link to={`/accounts/${transfer.accountID}`}>{transfer.accountID}</Link>
						</Table.Cell>
						<Table.Cell width={5} /*textAlign="right"*/>
							{/* <Money hidePriceSymbol={hidePriceSymbol}>{adjustWithDecimals(transfer.amount, tokenDecimals)}</Money> */}
							<Money hidePriceSymbol={hidePriceSymbol}>{transfer.amount}</Money>
						</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
		</Panel>
	);
}
