import React from 'react';
import { Grid } from 'semantic-ui-react';
import PlaceHolder from './PlaceHolder';

export default function Panel({ title, titleClass, description = '', toolbar, footer, children = <PlaceHolder /> }) {
	return (
		<Grid padded stackable className="panel">
			<Grid.Row>
				<Grid.Column>{children || description}</Grid.Column>
			</Grid.Row>
			{footer && (
				<Grid.Row>
					<Grid.Column>{footer}</Grid.Column>
				</Grid.Row>
			)}
		</Grid>
	);
}
