import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import Number from './Number';
// import Money from './Money';
import MoneyStat from './MoneyStat';

export default function Stat({ count, pct, money, currency, label }) {
	return (
		<Fragment>
			{!isNaN(count) && (
				<Header as="h5" floated="left">
					{money ? (
						<MoneyStat currency={currency}>{count}</MoneyStat>
					) : label ? (
						<React.Fragment>
							<Number>{count}</Number> <i className="normal-text">{label}</i>
						</React.Fragment>
					) : (
						<Number>{count}</Number>
					)}
				</Header>
			)}

			{!isNaN(count) &&
			!isNaN(pct) && (
				<Header as="h3" floated="left" style={{ marginTop: '-3px' }}>
					|
				</Header>
			)}
			{!isNaN(pct) && (
				<Header as="h4" floated="left" color={pct < 0 ? 'red' : 'green'}>
					<Number>{pct}</Number> %
				</Header>
			)}
		</Fragment>
	);
}
