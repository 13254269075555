import { FETCH_ENV_CONFIGS_SUCCESS, FETCH_ENV_CONFIGS_FAILURE } from '../types';

const initialState = {};

export default function envConfigs(state = initialState, action) {
	switch (action.type) {
		case FETCH_ENV_CONFIGS_SUCCESS:
			return {
				...state,
				envConfigData: action.data
			};
		case FETCH_ENV_CONFIGS_FAILURE:
			return {
				...state,
				envConfigData: []
			};
		default:
			return state;
	}
}

export const getEnvConfigs = (state) => state.envConfigs && state.envConfigs.envConfigData ? state.envConfigs.envConfigData : {};
