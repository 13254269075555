import React from 'react';
import { Table } from 'semantic-ui-react';

export default function NftsTokenBalanceTableHeader(props) {
	return (
		<Table.Row>
			<Table.HeaderCell width={3}>
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'balance' ? props.direction : null}
				onClick={() => props.handleSort('balance')}
			>
				SERIAL NO.
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'tokenId' ? props.direction : null}
				onClick={() => props.handleSort('tokenId')}
			>
				OWNER
			</Table.HeaderCell>
			<Table.HeaderCell width={4}>
				Meta Data
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'tokenId' ? props.direction : null}
				onClick={() => props.handleSort('tokenId')}
			>
				DELETED
			</Table.HeaderCell>
		</Table.Row>
	);
}
