import React, { useState, useEffect, Fragment } from 'react';
// import DappUpdateForm from './forms/DappUpdateForm';
import DappAddContractForm from './forms/DappAddContractForm';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getUser, getDappContracts } from '../redux/reducers';

const DappAddContract = ({ dapp, handleCancel, handleContractMessage, updateDapp, user, uploadFiles, fetchDappContracts, dappContracts, dappAddContract }) => {

    console.log("DAPP TO UPDATE =", dapp)

    const { id } = dapp;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchData() }, []);

    const fetchData = () => {
        fetchDappContracts(id);
    }

    // console.log("THIS IS THE ID: ", id)

    // console.log("THIS IS THE DAPP CONTRACT: ", dappContracts)

    const contract = dapp && dappContracts && dappContracts.length > 0 ? dappContracts[0].id : '';
    
    // console.log("THIS IS THE CONTRACT", contract);

    const [form, setForm] = useState({
        // company: dapp.company,
        // company: '',
        title: dapp.title,
        id: dapp.title.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s+/g, '').toLowerCase(),
        // description: dapp.description,
        // accountId: dapp.accountId,
        contractId: '', //contract, // at this point it's still undefined, see useEffect below 
        // website: dapp.website,
        // androidAppLink: dapp.androidAppLink,
        // iosAppLink: dapp.iosAppLink,
        // deployed: dapp.deployed,
        primary: false
    });

    // useEffect(() => { setForm({ ...form, contractId: contract }) }, [dappContracts]);

    console.log("THIS IS THE FORM: ", form)

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const [abiFileName, setAbiFileName] = useState('');
    const [sourceCodeFileName, setSourceCodeFileName] = useState('');
    const [byteCodeFileName, setByteCodeFileName] = useState('');
    // const [logoFileName, setLogoFileName] = useState('');

    const onChange = e => {
        setForm({ 
            ...form, 
            [e.target.name]: e.target.value, 
            // id: form.title.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s+/g, '').toLowerCase(),
            // userName: user.name, 
            // userId: user.id, 
            // userEmail: user.email, 
            // userCompany: user.company
        });
    }

    const onPrimaryCheck = e => {
        setForm({ ...form, primary: !form.primary  })
    }

    const onSubmit = async e => {
        console.log('ON SUBMIT IS CALLED')
        e.preventDefault();
        const errors = validate(form);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            let files = {};
            acceptedFiles.forEach(file => files[Object.keys(file)[0]] = file[Object.keys(file)[0]])
            console.log('NO ERRORS ON SUBMIT')
            handleContractMessage();
            handleCancel();
            dappAddContract(form.id, {...form, ...files});
            console.log("THIS IS THE FORM IN ADD DAPP CONTRACT: ", {...form, ...files})
            console.log("THIS IS FORM ID AND THE FORM: ", form.id, form)
        }
    }

    const onDrop = (file) => {
        console.log("THIS IS THE FILE: ", file)
        console.log("THESE ARE THE ACCEPTED FILES: ", acceptedFiles)

        setAcceptedFiles([...acceptedFiles, file ])
    }

    // const onUpload = (e) => {
    //     console.log("ACCEPTED FILES: ", acceptedFiles)
    //     uploadFiles(form.id, acceptedFiles)
    // }


    const validate = (form) => {
        const { company, title, description, accountId, contractId, website, androidAppLink, iosAppLink } = form;
        let errors = {};
        // if (!company || company.trim().length === 0) errors.company = "Company name can't be blank";
        // if (!title || title.trim().length === 0) errors.title = "Title can't be blank";
        // if (!accountId || accountId.trim().length === 0) errors.accountId = "Account Id can't be blank";
        if (!contractId || contractId.trim().length === 0) errors.contractId = "Contract Id can't be blank";

        return errors;
    }

    const { company, title, description, accountId, contractId, website, androidAppLink, iosAppLink, deployed } = form;

    return (
        <Fragment>
            {dapp && dappContracts && <DappAddContractForm 
                dapp={dapp}
                contracts={dappContracts}
                company={company}
                title={title}
                description={description}
                accountId={accountId}
                contractId={contractId}
                website={website}
                androidAppLink={androidAppLink}
                iosAppLink={iosAppLink}
                deployed={deployed}
                handleCancel={handleCancel} 
                handleContractMessage={handleContractMessage}
                onChange={onChange}
                onSubmit={onSubmit}
                errors={errors}
                onPrimaryCheck={onPrimaryCheck}
                loading={loading}
                onDrop={onDrop}
                acceptedFiles={acceptedFiles}
                // onUpload={onUpload}
                abiFileName={abiFileName}
                setAbiFileName={setAbiFileName}
                sourceCodeFileName={sourceCodeFileName}
                setSourceCodeFileName={setSourceCodeFileName}
                byteCodeFileName={byteCodeFileName}
                setByteCodeFileName={setByteCodeFileName}
                // logoFileName={logoFileName}
                // setLogoFileName={setLogoFileName}
            />}
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: getUser(state),
        dappContracts: getDappContracts(state, ownProps.dapp.id)
    }
};

export default connect(mapStateToProps, actions)(DappAddContract);
