import React from 'react';
import { List } from 'semantic-ui-react';
import { fromDate } from '../utils';

export default function FilesList({ files = [], onFileClick }) {
	return (
		<List relaxed="very">
			{files.length ? (
				files.map((f) => {
					const { fileId, createDate, updateDate } = f;
					return (
						<List.Item key={fileId}>
							<List.Content>
								<List.Header as="a">{fileId}</List.Header>
								<List.Description>
									Created: {createDate ? fromDate(createDate) : 'N/A'}
								</List.Description>
								{updateDate !== 0 && (
									<List.Description>
										Last updated: {updateDate ? fromDate(updateDate) : 'N/A'}
									</List.Description>
								)}
							</List.Content>
						</List.Item>
					);
				})
			) : (
				<div>No files found</div>
			)}
		</List>
	);
}
