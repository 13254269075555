import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import User from '../containers/User';
import UserAccounts from '../containers/UserAccounts';
import Page from '../shared/Page';
import { Grid } from 'semantic-ui-react';
import Panel from '../shared/Panel';
import Info from '../shared/Info';

import ApiKeyPanel from '../ApiKeyPanel';

import DappCatalogPanel from '../DappCatalogPanel';

const Profile = (props) => {
	
	return (
		<Page>
			<Grid padded stackable style={{paddingTop: "40px"}}>
				{/* <Grid.Column width={10}> */}
					{/* <Panel title="DAPP CATALOG"> */}
						<DappCatalogPanel />
					{/* </Panel> */}
				{/* </Grid.Column> */}
				{/* <Grid.Column width={10}>
					<Panel
						title={
							<React.Fragment>
								MY SUBSCRIBED ACCOUNTS <Info id="subscribedAccounts" />
							</React.Fragment>
						}
					>
						<UserAccounts />
					</Panel>
					<div className="api-key-panel">
						<ApiKeyPanel />
					</div>
					<div className="api-key-panel">
						<DappCreatePanel />
					</div>
				</Grid.Column> */}
			</Grid>

		</Page>
	);
}

export default connect(null, actions)(Profile);
