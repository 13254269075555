import React, { Fragment } from 'react';
import Panel from './shared/Panel';
import DappContracts from './containers/DappContracts';
import APICodeSnippets from './APICodeSnippets';

const APICodeSnippetPanel = ({api}) => {

    return (
        <Panel
            // title={
            //     <Fragment>
            //         DAPP CONTRACTS <Info id="" />
            //     </Fragment>
            // }
            >
            <APICodeSnippets api={api} />
        </Panel>
    )
}

export default APICodeSnippetPanel;