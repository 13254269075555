import { FETCH_ACCOUNT_CREATION_VOLUME_SUCCESS, FETCH_ACCOUNT_CREATION_VOLUME_LOAD } from "../types";

const initialState = {
    period: "1D",
    numTransactions: 0,
    pctChange: 0,
    trend: [],
};

export default function accountCreationVolume(state = initialState, action) {
    switch (action.type) {
        case FETCH_ACCOUNT_CREATION_VOLUME_LOAD:
			return {
				loading: true,
				...action.payload
			};
        case FETCH_ACCOUNT_CREATION_VOLUME_SUCCESS:
            return {
                loading: false,
                ...action.payload
            };
        default:
            return state;
    }
}

export const getAccountCreationVolume = state => state;
