import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuthed } from '../redux/reducers';

const PublicRoute = ({ isAuthed, component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			const { from } = props.location.state || {
				from: { pathname: '/' }
			};
			return !isAuthed ? <Component {...props} /> : <Redirect to={from} />;
		}}
	/>
);

PublicRoute.propTypes = {
	component: PropTypes.func.isRequired,
	isAuthed: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		isAuthed: isAuthed(state)
	};
}

export default connect(mapStateToProps)(PublicRoute);
