import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Header, Image } from 'semantic-ui-react';
import * as actions from '../../redux/actions';
import { getUser } from '../../redux/reducers';

import Page from "../shared/Page";
import BulkPricing from "../BulkPricing";
import DG_LOGO from "../../assets/images/logo_dragonglass@2x.png";
import "../../assets/css/components/pricingPage.scss";
import DroppLogo from "../../assets/images/dropp_logo.png";
import { getEnvConfigs } from '../../redux/reducers/envConfig';
import AnnouncementBar from '../AnnouncementBar';
import { setMerchantAccountId, injectDroppSdk } from '../../utils';

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
          announcement: null,
          notificationOpen: false
        };
        this.goToSignupPage = this.goToSignupPage.bind(this);
        this.handleCloseNotification = this.handleCloseNotification.bind(this);
    }
	componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchEnvConfigs();
        if (this.props.user && this.props.user.id) {
            this.props.fetchAllFeatures(this.props.user.id);
        }
        injectDroppSdk();
    }

	componentDidUpdate(prevProps) {
        if (this.props.envConfigs !== prevProps.envConfigs && this.props.envConfigs && this.props.envConfigs.droppLink) {
            const {merchantId} = this.props.envConfigs;
            setMerchantAccountId(merchantId);
            this.props.fetchPricingPlans();
        }

        if (this.props.allFeatures !== prevProps.allFeatures && this.props.allFeatures && this.props.allFeatures.featuresData && Object.keys(this.props.allFeatures.featuresData).length) {
          if (!this.props.allFeatures.featuresData['PAYMENT']) {
            this.setState({notificationOpen: true, announcement: {description: "Pricing is currently disabled. Coming Soon..."}});
          }
        }
    }

    goToSignupPage = () => {
        this.props.history.push('/signup?pricing=true');
    };

    handleCloseNotification = () => {
        this.setState({notificationOpen: false});
		this.props.hideAnnouncement();
	};

	render() {
        const {announcement, notificationOpen} = this.state;
        return (
            <>
            {notificationOpen && announcement && <AnnouncementBar onClose={this.handleCloseNotification} announcement={announcement} />}
            <Page className="page-pricing">
                <Grid padded stackable className="panel">
                    <Grid.Column>
                        <React.Fragment>
                            <Grid stackable>
                                <Grid.Row
                                    style={{
                                        backgroundColor: "#F8F8F8"
                                    }}>
                                    <Grid
                                        stackable
                                        columns={2}
                                        style={{
                                            padding: "1em 3em",
                                            width: "100%"
                                        }}>
                                        <Grid.Row className="pricing-header">
                                            <Grid.Column computer={8} tablet={10} mobile={16}>
                                                <h3 style={{ fontSize: "240%", marginBottom: "5px" }}>
                                                    <Image id="dg-logo" src={DG_LOGO} />
                                                    <span style={{fontSize: "133%"}}>Pricing</span>
                                                </h3>
                                                { this.props && this.props.user ? '' :
                                                    <div>
                                                        <Button primary onClick={this.goToSignupPage} >Create a free account</Button>
                                                    </div>
                                                }
                                            </Grid.Column>
                                            <Grid.Column computer={8} tablet={6} mobile={16}>
                                                <div className="dropp-msg-section">
                                                    <div className="header-msg">
                                                        We exclusively use the Dropp Payment platform for all of our pricing options. You can sign up for Dropp and get your wallet <a href="https://dropp.cc/#download" target="_blank">here.</a>
                                                    </div>
                                                    <div>
                                                        <a href="https://dropp.cc" target="_blank"><Image inline className="dropp-logo" src={DroppLogo} /></a>
                                                    </div>
                                                </div>

                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Row>
{/*
                                 <Grid.Row>
                                    <Grid.Column className="section">
                                        <Header as="h3" className="heading">
                                            Pricing Options
                                        </Header>
                                        <div style={{ color: "#333333" }}>
                                            <p>
                                                Every development team has different needs based on the stage of their development and budget. Using Dropp you can customize your plan exactly based on your need.
                                            </p>
                                            <div>
                                                <ul className="pricing-options">
                                                    <li>Buy In Bulk, Pay as you go</li>
                                                    <li>Monthly, Pay on a projected monthly basis</li>
                                                    <li>Pay-Per-Use, Only pay for your use</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row> */}
                                <div className="separator">
                                    <div></div>
                                </div>
                                <Grid.Row>
                                    <Grid.Column className="section">
                                        <Header as="h3">
                                            Free
                                        </Header>
                                        <div style={{ color: "#333333" }}>
                                            <p>
                                                For all registered users the first 1000 API’s are free.
                                            </p>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <div className="separator">
                                    <div></div>
                                </div>
                                <Grid.Row id="bulk">
                                    <Grid.Column className="section">
                                        <BulkPricing {...this.props} />
                                    </Grid.Column>
                                </Grid.Row>
                                {/* <div className="separator">
                                    <div></div>
                                </div> */}
                                {/* <Grid.Row>
                                    <Grid.Column className="section">
                                        <SubscriptionPricing {...this.props} />
                                    </Grid.Column>
                                </Grid.Row>

                                <div className="separator">
                                    <div></div>
                                </div> */}

                                {/* <Grid.Row>
                                    <Grid.Column className="section">
                                        <PayAsYouGoPricing {...this.props} />
                                    </Grid.Column>
                                </Grid.Row> */}

                                <div className="separator">
                                    <div></div>
                                </div>

                                <Grid.Row id="dg-charges">
                                    <Grid.Column className="section">
                                        <Header as="h3" className="heading">
                                            DragonGlass charges by Unit
                                        </Header>

                                        <Header as="h4" style={{marginBottom: "1px", marginTop: "10px", fontWeight: 600}}>
                                            1 Unit = 1 API Call
                                        </Header>
                                        <div style={{color: "#333333"}}>
                                            <p>
                                                Most of the APIs in DragonGlass support "size" query parameter with a default value of 100. Incase you use the default option, it will qualify as 1 API call.
                                                If not, then the # of DragonGlass Objects* returned in the response will determine the # of APIs. For example, if in the response you get 101 Objects, it will qualify for 2 API calls.
                                            </p>
                                        </div>

                                        <Header as="h4" style={{marginBottom: "1px", marginTop: "10px", fontWeight: 600}}>
                                            1 Unit = 1 SQS Message from DragonGlass Subscription Service
                                        </Header>
                                        <div style={{color: "#333333"}}>
                                            <p>
                                                AWS SQS message sent using DragonGlass Subscription service bulks the message for 1 Sec or with max of 10 DragonGlass Objects* by default whatever happens first.
                                                Every SQS message call corresponds to 1 Unit.
                                            </p>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row
                                    style={{
                                        backgroundColor: "rgba(0, 0, 125, 0.03)",
                                    }}>

                                </Grid.Row>
                            </Grid>
                        </React.Fragment>
                    </Grid.Column>
                </Grid>
            </Page>
        </>
        );
	}
}

const mapStateToProps = (state) => ({
  user: getUser(state),
//   invoices: state.userInvoice,
  pricingPlans: state.pricingPlan,
  allFeatures: state.allFeatures,
  envConfigs: getEnvConfigs(state)
});

export default connect(mapStateToProps, actions)(Pricing);
