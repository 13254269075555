import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import DateCell from './shared/DateCell';
import eye from '../assets/images/eye@2x.png';
import { isMobile } from '../utils';

const redirectTo = (id, isAccount) => {
	return window.location.href = isAccount ? `/accounts/${id}` : `/topics/${id}`;
};

const TopicRow = ({ topic }) => {
    return (
	<Table.Row className="clickable hoverRow">
		<Table.Cell collapsing onClick={()=> redirectTo(topic.topicID)}>
			{topic.topicID}
		</Table.Cell >
		<Table.Cell collapsing onClick={()=> redirectTo(topic.createdBy, true)}>
			<Link to={`/accounts/${topic.createdBy}`}>{topic.createdBy}</Link>
		</Table.Cell>
		<Table.Cell collapsing onClick={()=> redirectTo(topic.topicID)}>
			<DateCell date={topic.createdDate} />
		</Table.Cell>
		{/* <Table.Cell collapsing>
        <Link to={`/transactions/${topic.transactionID}`}>{topic.transactionID}</Link>
		</Table.Cell> */}
		<Table.Cell
			style={{
				width: isMobile() ? '200px' : '50px', 
				maxWidth: isMobile() ? '200px' : '50px',
				overflow: 'hidden',
				textOverflow: 'ellipsis', 
				whiteSpace: 'nowrap',
			}}
		 onClick={()=> redirectTo(topic.topicID)}>
			<Popup
				trigger={<span>{topic.memo}</span>}
				content={topic.memo}
				position="top center"
				hoverable
			/>
		</Table.Cell>
		{/* <Table.Cell textAlign="center" collapsing onClick={()=> redirectTo(topic.topicID)}>
			<Link to={`/topics/${topic.topicID}`}>
				<Popup
					content="View Topic Details"
					trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
				/>
			</Link>
		</Table.Cell> */}
    </Table.Row>
    )
    
}

export default TopicRow;

TopicRow.propTypes = {
	topic: PropTypes.object.isRequired
};
