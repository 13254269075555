import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';

import LoginForm from '../forms/LoginForm';
import { parseQueryParams } from '../../utils/index';
import ReactGA from "react-ga";

class Login extends Component {
  state = {
    data: {
      email: "",
      password: "",
    },
    errors: {},
    loading: false,
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this.props
        .login(this.state.data.email, this.state.data.password)
        .then((user) => {
          if (user && user.id) ReactGA.set({ userId: user.id });
					this.setState({ errors: {}, loading: false });
					const params = parseQueryParams(this.props.location.search);
					if (params && params.pricing === 'true') {
						this.props.history.push('/pricing');
					}
        })
        .catch((err) =>
          this.setState({
            errors: { auth: "Sign in failed! Please verify your credentials." },
            loading: false,
          })
        );
    }
  };

  validate = (data) => {
    let errors = {};
    const { email, password } = data;
    if (!email || email.trim().length === 0) errors.email = "Invalid email";
    if (!password || password.trim().length === 0)
      errors.password = "Can't be blank";
    // if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
    // if (Validator.isEmpty(data.password)) errors.password = "Can't be blank";
    return errors;
  };

  render() {
    const {
      data: { email, password },
      loading,
      errors,
    } = this.state;
    return (
      <div id="auth-form">
        <div className="title">SIGN IN</div>
        <div id="formFields">
          <LoginForm
            email={email}
            password={password}
            onSubmit={this.onSubmit}
            onChange={this.onChange}
            errors={errors}
            loading={loading}
          />
        </div>
        <div className="footer">
          <p>
            DON'T HAVE AN ACCOUNT? <Link to={`/signup${this.props.location.search}`}>SIGN UP</Link>
          </p>
          <br />
          <p>
            <Link to="/reset-password">FORGOT PASSWORD?</Link>
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, actions)(Login));
