import React from 'react';
import { Segment, Container, Grid, List, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function Footer() {
	return (
		<Segment inverted vertical style={{ padding: '3em 0em 2em 0em' }} className="app-footer">
			<Container>
				<Grid inverted stackable>
					<Grid.Row>
						<Grid.Column width={2}>
							<Header inverted as="h4" content="PRODUCT" className="underline" />
							<List link inverted>
								{/* <List.Item as="">
									<a
										href="https://dragonglass.me/about#features"
										target="_blank"
										rel="noopener noreferrer"
									>
										FEATURES
									</a>
								</List.Item> */}
								<List.Item>
									<Link to="/faqs">FAQs</Link>
								</List.Item>

								{/* <List.Item as="">PRICING</List.Item> */}
							</List>
						</Grid.Column>
						<Grid.Column width={2}>
							<Header inverted as="h4" content="COMMUNITY" className="underline" />
							<List link inverted>
								{/* <List.Item as="">DAPP DEVELOPER TOOLS</List.Item> */}
								<List.Item as="">
									<a
										href="https://dragonglass.me/about#apis"
										target="_blank"
										rel="noopener noreferrer"
									>
										DATA API
									</a>
								</List.Item>
								{/* <List.Item as="">LIVE SUBSCRIPTIONS</List.Item> */}
							</List>
						</Grid.Column>
						<Grid.Column width={2}>
							<Header inverted as="h4" content="COMPANY" className="underline" />
							<List link inverted>
								{/* <List.Item>
									<Link to="/about">ABOUT</Link>
								</List.Item> */}
								{/* <List.Item as="">LEGAL</List.Item> */}
								<List.Item>
									<Link to="/contact-us">CONTACT US</Link>
								</List.Item>
							</List>
						</Grid.Column>
						<Grid.Column width={2}>
							<Header inverted as="h4" content="FOLLOW US" className="underline" />
							<List link inverted>
								<List.Item as="">
									<a href="https://discord.gg/E8dZdbS" target="_blank" rel="noopener noreferrer">
										<Icon name="discord" size="large" />
									</a>
									<a
										href="https://t.me/dragonglasscommunity"
										target="_blank"
										rel="noopener noreferrer"
									>
										<Icon name="telegram" size="large" />
									</a>
								</List.Item>
								<div>
									<a href="mailto:info@opencrowd.com" target="_blank" rel="noopener noreferrer">
										info@dragonglass.me
									</a>
								</div>
							</List>
						</Grid.Column>
						<Grid.Column width={8} textAlign="right">
							<Header as="h4" inverted>
								<a href="https://dragonglass.me" target="_blank" rel="noopener noreferrer">
									<span className="dragon">Dragon</span>
									<span className="glass">Glass</span>{' '}
								</a>is developed and managed by Dropp Inc.
							</Header>
							<div>
								<Icon name="chain" size="small" />
								<a href="https://dropp.cc/" target="_blank" rel="noopener noreferrer">
									www.dropp.cc
								</a>
							</div>

							{/* <small>© 2005-2020 OpenCrowd Inc. All Rights Reserved</small> */}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</Segment>
	);
}
