import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DappCatalogTable from '../DappCatalogTable';
import * as actions from '../../redux/actions';
import { getDapps } from '../../redux/reducers';
import { Header, /*Icon,*/ Image, Modal, Button } from 'semantic-ui-react';

import AppImage from '../../assets/images/App.png';

class DappCatalog extends Component {
	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		const { fetchDapps } = this.props;
		fetchDapps();
	}

	render() {
		return (
			<Fragment>
				<div style={{marginBottom: "40px"}}>
					{this.props.dapps.length > 0 && <DappCatalogTable dapps={this.props.dapps} />}
				</div>
				{/* <Header as="h2" textAlign="center">
					<Header.Content>
						<div style={{marginBottom: "12px"}}>Got a DApp?</div>
						<Header.Subheader>Create a trusted home for your users on DragonGlass</Header.Subheader>
					</Header.Content>
				</Header>

				<p className="text-center">
					<Link to="/contact-us">
						<Button primary size="small">
							CONTACT US
						</Button>
					</Link>
				</p> */}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	dapps: getDapps(state)
});

DappCatalog = connect(mapStateToProps, actions)(DappCatalog);

export default DappCatalog;
