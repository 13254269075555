import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import SignupForm from '../forms/SignupForm';

import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { isEmail, parseQueryParams } from '../../utils';

import ReactGA from 'react-ga';

class Signup extends Component {
	state = {
		data: {
			name: '',
			email: '',
			company: '',
			password: '',
			password1: ''
		},
		errors: {},
		loading: false,
		isSignedup: false
	};

	onChange = (e) =>
		this.setState({
			data: { ...this.state.data, [e.target.name]: e.target.value }
		});

	onSubmit = async (e) => {
		e.preventDefault();
		const errors = this.validate(this.state.data);
		this.setState({ errors });
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true });
			await this.props
				.signup(this.state.data)
				.then(() => {
					this.setState({ isSignedup: true, loading: false, errors: {} });
						ReactGA.event({
							category: 'Signup',
							action: 'Submit',
							label: 'User submitted the signup form'
						});
						const params = parseQueryParams(this.props.location.search);
						if (params && params.pricing === 'true') {
							this.props.history.push('/pricing');
						}
					}
				)
				.catch((err) =>
					this.setState({ errors: { auth: 'Sign up failed! Please try again.' }, loading: false })
				);
		}
	};

	validate = (data) => {
		let errors = {};
		const { name, email, password, password1 } = data;
		if (!name || name.trim().length === 0) errors.name = "Can't be blank";
		if (!isEmail(email)) errors.email = 'Invalid email';
		if (!password || password.trim().length === 0) errors.password = "Password can't be blank";
		if (password && password.trim().length > 0 && (!password1 || password1.trim().length === 0)) errors.password = "Please confirm your password";
		if (password && password.trim().length > 0 && password1 && password !== password1) errors.password = "Passwords don't match"

		/*
		if (Validator.isEmpty(data.name)) errors.name = "Can't be blank";
		if (!Validator.isEmail(data.email)) errors.email = 'Invalid email';
		if (Validator.isEmpty(data.password)) errors.password = "Can't be blank";
		*/
		/* To do
		// Password test. Atleast one uppercase, one lowercase, one number & one special char
		if (
		Validator.matches(
			data.password,
			"^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$"
		)
		)
		errors.password =
			"Password must, be atleast 8 characters long, and have atleast one uppercase, one lowercase, one number & one special character";
		*/
		return errors;
	};

	renderForm() {
		const { data: { name, email, company, password, password1 }, loading, errors } = this.state;
		return (
			<SignupForm
				name={name}
				email={email}
				company={company}
				password={password}
				password1={password1}
				onSubmit={this.onSubmit}
				onChange={this.onChange}
				errors={errors}
				loading={loading}
			/>
		);
	}

	renderAfterSignupMessage() {
		const { data: { email } } = this.state;
		return (
			<Message
				success
				header="Your user registration was successful"
				content={`An activation link has been sent to ${email}. Please activate your account before signing in.`}
			/>
		);
	}

	render() {
		return (
			<div id="auth-form">
				<div className="title">SIGN UP</div>
				<div id="formFields">
					{!this.state.isSignedup ? this.renderForm() : this.renderAfterSignupMessage()}
				</div>
				<div className="footer">
					{/* <p className="terms">
						BY CREATING AN ACCOUNT, YOU AGREE TO THE <Link to="/terms">TERMS</Link> and{' '}
						<Link to="/privacy">POLICY</Link>
					</p> */}
					<p>
						ALREADY HAVE AN ACCOUNT? <Link to={`/login${this.props.location.search}`}>SIGN IN</Link>
					</p>
				</div>
			</div>
		);
	}
}

Signup = connect(null, actions)(Signup);

export default withRouter(Signup);
