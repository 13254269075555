import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import DappTransferRow from './DappTransferRow';
import DappTransferTableHeader from './DappTransferTableHeader';

const DappTransferTable = ({ transfers, limit, offset, sort, order, totalCount, loadData, showDetails }) => {
	const transferRows = transfers.data.map((transfer, index) => (
		<DappTransferRow key={index} transfer={transfer} showDetails={showDetails} />
    ));
	return (
		<DataTable
			limit={limit}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={DappTransferTableHeader}
			rows={transferRows}
			loadData={loadData}
		/>
	);
};

export default DappTransferTable;

DappTransferTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired,
	showDetails: PropTypes.func.isRequired
};
