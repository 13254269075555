import {
	FETCH_TRANSACTIONS_REQUEST,
	FETCH_TRANSACTIONS_SUCCESS,
	FETCH_TRANSACTIONS_FAILURE,
	RESET_SEARCH
} from '../types';

const initialState = {
	isLoading: false,
	filters: null,
	results: [],
	totalCount: 0
};

export default function searchedTransactions(state = initialState, action) {
	switch (action.type) {
		case FETCH_TRANSACTIONS_REQUEST:
			return { ...state, isLoading: true };
		case FETCH_TRANSACTIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				results: action.response.data,
				filters: action.filtered === true ? state.filters : action.response.facets,
				totalCount: action.response.totalCount
			};
		case FETCH_TRANSACTIONS_FAILURE:
			return { ...initialState };
		case RESET_SEARCH:
			return { ...initialState };
		default:
			return state;
	}
}

export const getSearchedTransactions = (state) => state.results;
export const getSearchedTransactionsCount = (state) => state.totalCount;
export const getSearchedTransactionsFilters = (state) => state.filters;
