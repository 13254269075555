import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Tab } from 'semantic-ui-react';
import DappSourceCodeSnippet from './shared/DappSourceCodeSnippet';
import DappByteCodeSnippet from './shared/DappByteCodeSnippet';
import APICodeSnippetSingle from './APICodeSnippetSingle';
import APICodeJS from './APICodeJS';
import APICodeJava from './APICodeJava';
import APICodeGo from './APICodeGo';
import APICodeSwift from './APICodeSwift';
import APICodePython from './APICodePython';
import APICodecURL from './APICodecURL';

const APICodeSnippets = ({api}) => {

    console.log("API IN API CODE SNIPPETS: ", api)

    const panes = () => [
		{
			menuItem: {
				key: 'curl',
				content: <span style={{width: "12px", position: "relative", right: "120%"}}>cURL</span>
			},
			render: () => (
				<Tab.Pane as="div">
                    {api && <APICodecURL api={api} />}
					{/* {source} */}
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'java',
				content: <span style={{width: "10px", position: "relative", right: "120%"}}>Java</span>
			},
			render: () => (
				<Tab.Pane as="div">
                    {api && <APICodeJava api={api} />}
					{/* {source} */}
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'javascript',
				content: <span style={{width: "8px", position: "relative", right: "120%"}}>JS</span>
			},
			render: () => (
				<Tab.Pane as="div">
                    {api && <APICodeJS api={api} />}
					{/* {source} */}
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'go',
				content: <span style={{width: "8px", position: "relative", right: "120%"}}>Go</span>
			},
			render: () => (
				<Tab.Pane as="div">
                    {api && <APICodeGo api={api} />}
					{/* {source} */}
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'swift',
				content: <span style={{width: "10px", position: "relative", right: "120%"}}>Swift</span>
			},
			render: () => (
				<Tab.Pane as="div">
                    {api && <APICodeSwift api={api} />}
					{/* {source} */}
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'python',
				content: <span style={{width: "10px", position: "relative", right: "120%"}}>Python</span>
			},
			render: () => (
				<Tab.Pane as="div">
                    {api && <APICodePython api={api} />}
					{/* {source} */}
				</Tab.Pane>
			)
		}
	];

    const handleTabChange = (e, { activeIndex }) => {
        // console.log('handleTabChange = ', activeIndex);
        setActiveTabIndex(activeIndex);
        switch (activeIndex) {
            case 0:
                setContent('');
                break;
            case 1:
                setContent('');
                break;
            default:
                console.log('unknown tab');
                setContent("");
        }
    };

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [content, setContent] = useState('');

    const wide = window.innerWidth < 1324 ? true : false;

    useEffect(() => { setContent('') }, []);

    return (
        <Fragment>
            <Tab
				menu={{ stackable: true, secondary: true, pointing: true, tabular: true, /*vertical: {wide}, */style: {
                    display: "flex",
                    // justifyContent: "flex-end"
                  } }}
				panes={panes()}
				renderActiveOnly={true}
                onTabChange={handleTabChange}
                grid={{paneWidth: 1, tabWidth: 1}}
			/>
        </Fragment>
    )
}

export default APICodeSnippets;