import React, { Component } from 'react';
import { Icon, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getNetworks } from '../redux/reducers';

class NetworkSelector extends Component {
	getNetwork = () => {
		const { networks } = this.props;
		if (networks) {
			const network = networks.find((n) => n.url === window.location.origin);
			return network ? network.name : 'NETWORK';
		} else {
			return 'NETWORK';
		}
	};
	getOptions = () => {
		const { networks } = this.props;
		if (networks) {
			return networks.map((n, index) => ({
				key: n.id,
				value: n.url,
				disabled: window.location.origin === n.url,
				selected: window.location.origin === n.url,
				content: (
						<Grid.Column key={index}>
							<a style={{background: window.location.origin === n.url ? "#5cd0ff" : '', padding: "6px", color: window.location.origin === n.url ? "black" : "white" }} href={n.url} target="_blank" rel="noopener noreferrer">
								{n.url.includes('testnet') ? "TESTNET" : n.name}
							</a>
						</Grid.Column>
				)
			}));
		} else return [];
	};
	
	render() {
		return (<>{ this.getOptions().map(network => network.content)}</>)
	}
}

const mapStateToProps = (state) => ({
	networks: getNetworks(state)
});

export default withRouter(connect(mapStateToProps, null)(NetworkSelector));
