import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../redux/actions';
import Panel from './shared/Panel';
import { List, Button, Icon } from 'semantic-ui-react';
import { getCategories, isAuthed, getKeys } from '../redux/reducers';

const APISelectPanel = (props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchData() }, [])

  const fetchData = async () => {
    const keys = await props.fetchApiKey();
    props.fetchApiCategories();
  }

  const handleOnClick = (cat) => {
    setSelected(cat.title);
    props.handleCategorySelect(cat);
  }

  const [selected, setSelected] = useState('');

  props.categories && console.log('CATEGORIES IN API: ', props.categories)

  const introduction = {id: '0', title: 'Introduction', description: ''}
  const ids = [];
  props.categories.map(cat => ids.push(Object.values(cat)[0]));
  !ids.includes("0") && props.categories.unshift(introduction);

  return (
    <Panel
      title={
        <Fragment>
          PUBLIC API SELECTION
          {(!props.isAuthed || props.keys.length < 1) && <div className="api-button-div">
          <Link to="/profile"><Button primary className="api-button-small"><Icon name="key" />GENERATE API KEY</Button></Link>
          </div>}
        </Fragment>
      }
    >
      <List divided relaxed className="categories-list">
        {props.categories.map(cat => <List.Item
                                        id="padded"
                                        key={cat.id}
                                        className={selected===cat.title ? "selected" : ""}
                                        active={selected===cat.title ? true : false}
                                        onClick={() => handleOnClick(cat) }>{cat.title}
                                      </List.Item>)}
      </List>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  categories: getCategories(state),
  isAuthed: isAuthed(state),
  keys: getKeys(state)
});

export default connect(mapStateToProps, actions)(APISelectPanel);