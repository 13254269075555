import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import TokenRow from './TokenRow';
import TokenTableHeader from './TokenTableHeader';
import SearchTokenTableHeader from './SearchTokenTableHeader';

const TokenTable = ({ tokens = [], size, offset, sort, order, totalCount, loadData, isShowType }) => {
	const TokenRows = tokens.map((token, index) => <TokenRow key={index} token={token} isShowType={isShowType}/>);
	return (
		<DataTable
			limit={size}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={isShowType ? SearchTokenTableHeader : TokenTableHeader}
			rows={TokenRows}
			loadData={loadData}
		/>
	);
};

export default TokenTable;

TokenTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired
};
