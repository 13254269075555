import React, { Fragment } from 'react';
import Panel from './shared/Panel';
import DappContracts from './containers/DappContracts';

const DappContractsPanel = () => {

    return (
        <Panel
            title={
                <Fragment>
                    DAPP CONTRACTS {/*<Info id="" />*/}
                </Fragment>
            }
            >
            <DappContracts />
        </Panel>
    )
}

export default DappContractsPanel;
