import React from "react";
import { Button, Dropdown, Icon } from "semantic-ui-react";

const handleCLick = () => {
  console.log("CLICKED SETTINGS")
};

const options = [
    {
      key: "ownership",
      text: "TAKE OWNERSHIP",
      value: "ownership"
    },
    { key: "manage", text: "MANAGE DAPP PROFILE", value: "manage" },
    { key: "view", text: "VIEW CONTRACT SOURCE", value: "view" },
    { key: "redeploy", text: "REDEPLOY CONTRACT", value: "redeploy" }
  ];

const SettingsDropdown = () => (
  <div>
    <Icon
      name="setting"
      data-target="dropdown"
      style={{ position: "relative", right: "-15px", color: "grey" }}
    />
    {/* <Button.Group color='teal'> */}
    {/* <Button>Save</Button> */}
    <Dropdown
    //   style={{ color: "white" }}
      className="white"
      floating
      options={options}
      trigger={<React.Fragment />}
    />
    {/* </Button.Group> */}
  </div>
);

export default SettingsDropdown;