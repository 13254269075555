import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router";
import { Tab } from 'semantic-ui-react';
import { getSearchNFTsData } from '../redux/reducers';
import * as actions from '../redux/actions';
import NFTExplorerList from "../atoms/NFTExplorerList";
import Collections from "../atoms/Collections";
import { removeEmpty, isSerialNumber, isId, isNft } from '../utils';
import * as api from "../apis";
import Number from '../atoms/Number';

const SearchNfts = (props) => {

	const history = useHistory();
	const [limit, setLimit] = useState(12);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState('createdOn');
	const [order, setOrder] = useState('desc');
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState('');
	const [isCollectionView, setIsCollectionView] = useState(false);
	const [isNFTView, setIsNFTView] = useState(false);
	const [nfts, setNfts] = useState([]);
	const [collections, setCollections] = useState([]);
	const [collectionsCount, setCollectionsCount] = useState(0);
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [collectionLoading, setCollectionLoading] = useState(false);

	
	const { nftsData } = props;
	const { fetchSearchNfts } = props;
	const totalCount = nftsData && nftsData.totalCount || 0; 
	useEffect(() => { 
		setLoading(nftsData.isLoading);
		const tempNfts = nftsData && nftsData.nfts || [];
		if (tempNfts) {
			setNfts(tempNfts);
		}
	 }, [nftsData]);

    useEffect(() => { 
		setQuery(props.searchText);
	 }, [props.searchText]);

	 useEffect(() => { 
		fetchAdvancedAPIData();
	 }, [props.isAdvanced, props.advanceSearchParams, page]);

	 useEffect(() => {
		if (query && props.selectedTab == 4) {
			fetchAPIData()
		}
	 }, [query, limit, page]);
	 
	const prepareQueryParams = (params) => {
	
		const queryParams = {
			offset,
			limit,
			page,
			sort,
			order,
			// q: query
			...params
		 }
		const queryParamsFinal = removeEmpty(queryParams);
		return queryParamsFinal;
	}

	let panes = [
		{
			menuItem: {
				key: 'nfts',
				content: (
					<span>
						NFTs (<Number>{totalCount}</Number>)
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<NFTExplorerList isLoading={loading} onLoadMore={onLoadMore} nfts={nfts} totalCount={totalCount} limit={limit}/>
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'collections',
				content: (
					<span>
						Collections (<Number>{collectionsCount}</Number>)
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<Collections isLoading={collectionLoading} collections={collections} handleDetailsClick={handleDetailsClick} handleInternalDetailsClick={handleInternalDetailsClick} 
						onLoadMore={onLoadMore} totalCount={collectionsCount} limit={limit}/>
				</Tab.Pane>
			)
		}
	]

	if (props.isAdvanced && isNFTView) {
		const tempPanes = [
			{
				menuItem: {
					key: 'nfts',
					content: (
						<span>
							NFTs (<Number>{totalCount}</Number>)
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<NFTExplorerList isLoading={loading} onLoadMore={onLoadMore} nfts={nfts} totalCount={totalCount} limit={limit}/>
					</Tab.Pane>
				)
			},
		];
		panes = tempPanes;
	}

	if (props.isAdvanced && isCollectionView) {
		const tempPanes = [
			{
				menuItem: {
					key: 'collections',
					content: (
						<span>
							Collections (<Number>{collectionsCount}</Number>)
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<Collections isLoading={collectionLoading} collections={collections} handleDetailsClick={handleDetailsClick} handleInternalDetailsClick={handleInternalDetailsClick} 
							onLoadMore={onLoadMore} totalCount={collectionsCount} limit={limit}/>
					</Tab.Pane>
				)
			}
		];
		if (props.isAdvanced && isNFTView) {
			panes.push(...tempPanes);
		} else {
			panes = tempPanes;
		}
	}


	const fetchAPIData = async () => {
		if (isNft(query)) {
			const splitedQuery = query.split('#');
			const tokenId = splitedQuery[0];
			const serialNumber = splitedQuery[1];
			history.push(`/nft/${tokenId}?serialNumber=${serialNumber}`);
		} else if (isSerialNumber(query)) {
			const serialNumber = query.replace('#', '');
			const queryParams = prepareQueryParams({serialNumber});
			fetchSearchNfts(queryParams);
			fetchCollections({query: serialNumber});
			return;
		} else if (isId(query)) {
			const queryParams = prepareQueryParams({tokenId: query});
			const tokenDetails = await api.searchToken(queryParams)
			if (tokenDetails && tokenDetails.data && tokenDetails.data.length) {
				const tokenId = tokenDetails.data[0].id;
				history.push(`/tokens/${tokenId}`);
			} else {
				queryForText();
			}
		} else {
			console.log('searching for text query');
			queryForText();
		}
	};


	const fetchAdvancedAPIData = async () => {
		const advanceSearchParams = props.advanceSearchParams;
		if (advanceSearchParams) {
			const tempPanes = [];
			if (advanceSearchParams.collectionName || advanceSearchParams.collectionCreationDateFrom || advanceSearchParams.collectionCreationDateTo) {
				setIsCollectionView(true);
				fetchCollections({
					query: '',
					name: encodeURIComponent(advanceSearchParams.collectionName),
					createdAfter: advanceSearchParams.collectionCreationDateFrom,
					createdBefore: advanceSearchParams.collectionCreationDateTo,
				});
				tempPanes.push({
					menuItem: {
						key: 'collections',
						content: (
							<span>
								Collections (<Number>{collectionsCount}</Number>)
							</span>
						)
					},
					render: () => (
						<Tab.Pane as="div">
							<Collections collections={collections} handleDetailsClick={handleDetailsClick} handleInternalDetailsClick={handleInternalDetailsClick} 
								onLoadMore={onLoadMore} totalCount={collectionsCount} limit={limit}/>
						</Tab.Pane>
					)
				});
			} else {
				setNfts([]);
				setCollections([]);
				setCollectionsCount(0);
				setIsCollectionView(false);
			}

			if (advanceSearchParams.nftName || advanceSearchParams.owner
				|| advanceSearchParams.nftCreator
				|| advanceSearchParams.nftMintDateFrom
				|| advanceSearchParams.nftMintDateto
				) {
					const queryParamsForNft = prepareQueryParams({
						query: '',
						name: encodeURIComponent(advanceSearchParams.nftName),
						owner: encodeURIComponent(advanceSearchParams.owner),
						creator: encodeURIComponent(advanceSearchParams.nftCreator),
						startDate: advanceSearchParams.nftMintDateFrom,
						endDate: advanceSearchParams.nftMintDateto,
					});
					setIsNFTView(true);
					fetchSearchNfts(queryParamsForNft);

					tempPanes.push({
						menuItem: {
							key: 'nfts',
							content: (
								<span>
									NFTs (<Number>{totalCount}</Number>)
								</span>
							)
						},
						render: () => (
							<Tab.Pane as="div">
								<NFTExplorerList isLoading={loading} onLoadMore={onLoadMore} nfts={nfts} totalCount={totalCount} limit={limit}/>
							</Tab.Pane>
						)
					})
			} else {
				setNfts([]);
				setCollections([]);
				setCollectionsCount(0);
				setIsNFTView(false);
			}
		}
	};

	const queryForText = async () => {
		const queryParamsForNft = prepareQueryParams({query: `*${encodeURIComponent(query)}*`});
		fetchCollections();
		fetchSearchNfts(queryParamsForNft);
	}
	const fetchCollections = async (params) => {
		setCollectionLoading(true);
		const queryParams = prepareQueryParams({query: `*${encodeURIComponent(query)}*`, ...params, tokenType: 'NON_FUNGIBLE_UNIQUE'});
		const tokens = await api.fetchLatestCollection(queryParams);
		if (tokens && tokens.data && tokens.data.length) {
			setCollections(tokens.data);
			setCollectionsCount(tokens.totalCount);
		}
		setCollectionLoading(false);
	}

	const onLoadMore = (params) => {
		setOffset(params.offset);
		setPage(params.page)
		setLimit(limit);
	}

	const handleDetailsClick = (token) => {
        history.push(`/tokens/${token}`);
    }

	const handleInternalDetailsClick = (token, serialNumber) => {
        history.push(`/nft/${token}?serialNumber=${serialNumber}`);
    }

	const handleTabChange = (e, { activeIndex }) => {
		setActiveTabIndex(activeIndex);
	}

	return (
		<>
		<Tab
			className='searchNftsTabs'
			style={{width: '100%'}}
			menu={{ stackable: true, secondary: true, pointing: true }}
			panes={panes}
			renderActiveOnly={true}
			onTabChange={handleTabChange}
			activeTabIndex={activeTabIndex}
			activeIndex={activeTabIndex}
		/>
		</>
	)
}

const mapStateToProps = (state) => ({
	nftsData: getSearchNFTsData(state),
});

export default connect(mapStateToProps, actions)(SearchNfts);