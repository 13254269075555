import React, { useState} from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Table } from 'semantic-ui-react';
import SubscriptionQueuePopup from './SubscriptionQueuePopup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyTextToClipboard from './CopyTextToClipboard';
import CopyCustomTooltip from './CopyCustomTooltip';
import InfoTableHeader from './shared/InfoTableHeader';
import { getDapps, getUser } from '../redux/reducers';

const SubscriptionTable = (props) => {

    const handleAddFilters = () => {
        console.log("ADD FILTERS CLICKED")
    }

    const handleCopyClick = () => {
        console.log("QUEUE COPIED");
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000)
    }

    const audio = new Audio('/click.mp3');
    
    const [ clicked, setClicked ] = useState(false);

    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; 
    let yyyy = today.getFullYear();
    if(dd<10) {dd='0'+dd;} 
    if(mm<10) {mm='0'+mm;} 
    let date = yyyy+'-'+mm+'-'+dd;

    // console.log("THIS IS THE DATE: ", date)
    props.subscriptions && console.log("SUBSCRIPTIONS IN THE TABLE: ", props.subscriptions)

    const validSubs = props.subscriptions && props.subscriptions.filter(el => el.expiry.slice(0, 10) > date);
    const expiredSubs = props.subscriptions && props.subscriptions.filter(el => el.expiry.slice(0, 10) <= date);
    const allSubscriptions = [ ...validSubs, ...expiredSubs ]
    // }

    // const handleCellClick = (id) => {
    //     window.open(`/dapps/${id}`, '_blank');
    // }

    // const dappsToDisplay = props.dapps.filter(dapp => dapp.userId === props.user.id);

    const string_break = (string) => { // This function is intended to insert a line break after each "_" in transaction types.
        let res = '';
        for (let i = 0; i < string.length; i++) {
            string[i] === '_' ? res += string[i].concat('\n') : res += string[i];
        }
        return res;
    }

    const rowsToDisplay = allSubscriptions.map(sub => {
        console.log("SUBSCRIPTION: ", sub)
        return (
            <Table.Row key={Math.random()}>
                <Table.Cell width={2} className="api-cell">
                    <span className="text-gray">{sub.subscriptionType}</span>
                    <div><i className="icon edit text-gray" style={{cursor: "pointer"}} onClick={(e) => props.handleAddAwsArn(sub)}></i></div>
                </Table.Cell>
                <Table.Cell className="api-cell" width={4} style={{wordWrap: 'break-word', wordBreak: 'break-word', overflow: "auto", cursor: "pointer"}}>
                    <SubscriptionQueuePopup queueUrl={sub.queueUrl} />
                    <CopyToClipboard text={sub.queueUrl}
                        onCopy={() => handleCopyClick({copied: true})}>
                        <span><CopyCustomTooltip clicked={clicked} /></span>
                        {/* <span><CopyTextToClipboard clicked={clicked} /></span> */}
                    </CopyToClipboard>
                </Table.Cell>
                <Table.Cell className="api-cell" style={{wordWrap: 'break-word', wordBreak: 'break-word', overflow: "auto", fontSize: "90%"}}>
                    {sub.transactionTypes && sub.transactionTypes.
                    map(el => sub.transactionTypes.indexOf(el) === sub.transactionTypes.length - 1 ? ` ${string_break(el)} ` : ` ${string_break(el)}, `)}
                </Table.Cell>
                {sub && sub.expiry && 
                <Table.Cell 
                    className="api-cell"
                    width={2} 
                    style={{color: sub.expiry.slice(0, 10) <= date ? "red" : "gray", fontSize: "90%"}} 
                    width={3}>
                    {sub.expiry.slice(0, 10)}
                </Table.Cell>}
                <Table.Cell
                    className={sub.expiry.slice(0, 10) <= date ? "disabled api-cell" : "api-cell"}
                    style={{cursor: "pointer", textAlign: "center"}}
                    onClick={(e) => props.handleDisplayFilters(sub)}
                    width={1}>
                    <i className="filter icon text-gray" style={{color: sub.expiry.slice(0, 10) <= date ? "#CDDCF1" : "gray"}}></i>
                </Table.Cell>
                <Table.Cell
                    className={sub.expiry.slice(0, 10) <= date ? "disabled api-cell" : "api-cell"}
                    style={{cursor: "pointer", textAlign: "center", overflowWrap: "anywhere"}}
                    onClick={(e) => {sub.subscriptionType !== "HCS_TOPIC" && props.handleUploadAbi(sub.id)}}
                    width={1}>
                    {sub.subscriptionType !== "HCS_TOPIC" && 
                    <i className="file alternate outline icon text-gray" style={{color: sub.expiry.slice(0, 10) <= date ? "#CDDCF1" : "gray"}}>
                    </i>}
                    {props.displayAbiFileName && props.subscriptionId === sub.id && <p style={{fontSize: "80%"}}>{props.abiFileName}</p>}
                </Table.Cell>
                <Table.Cell
                    className={sub.expiry.slice(0, 10) <= date ? "disabled api-cell" : "api-cell"}
                    style={{cursor: "pointer", textAlign: "center"}}
                    // onClick={(e) => props.handleUpdate(dapp)} 
                    onClick={(e) => props.handleRemoveSubscription(sub.id)}
                    width={1}>
                    <i className="close icon" style={{color: sub.expiry.slice(0, 10) <= date ? "#FFB6C1" : "#DB2828"}}></i>
                </Table.Cell>
            </Table.Row>
        )
    })

    return (
        <div className="api-padded">
            <Table celled className="api-key-table subscriptions">
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Subscription Type
                        <span style={{fontSize: "80%", marginLeft: "10px"}}><InfoTableHeader id="subscriptionType" /></span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>Queue</Table.HeaderCell>
                    <Table.HeaderCell>Transaction Types</Table.HeaderCell>
                    <Table.HeaderCell>Expiry</Table.HeaderCell>
                    <Table.HeaderCell>Filters</Table.HeaderCell>
                    <Table.HeaderCell>Upload Abi</Table.HeaderCell>
                    <Table.HeaderCell>Delete</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rowsToDisplay}
                </Table.Body>
            </Table>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    dapps: getDapps(state),
    user: getUser(state)
});

export default connect(mapStateToProps, actions)(SubscriptionTable);
