import React, { useState } from 'react';
import Panel from './shared/Panel';
import TokenTransfers from '../components/TokenTransfers';
import { getTokenDetails } from '../redux/reducers/hts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Filter from './shared/Filter';
import Info from './shared/Info';

const TokenTransferPanel = (props) => {
	const [ filterText, setfilterText ] = useState('');

	function onSubmitFilter (value) {
		setfilterText(value);
	};

	return (
		<Panel
			title={ <React.Fragment><Info id="tokenTransfers" /></React.Fragment> }
			toolbar={ <React.Fragment><Filter onSubmitFilter={onSubmitFilter}/></React.Fragment> }
		>
      <TokenTransfers {...props} filterText={filterText}/>
		</Panel>
	);
}

const mapStateToProps = (state) => ({
  token: getTokenDetails(state)
});

export default withRouter(connect(mapStateToProps)(TokenTransferPanel));