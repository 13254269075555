import { 
    FETCH_SUBSCRIPTION_FILTERS_SUCCESS,
    FETCH_SUBSCRIPTION_FILTERS_FAILURE
} from '../types';

export default function subscriptionFilters (state = [], action) {
	switch (action.type) {
		case FETCH_SUBSCRIPTION_FILTERS_SUCCESS:
			console.log("IN REDUCER, FETCH SUBSCRIPTION FILTERS", action.payload)
			// return [ ...state, action.payload ];
			return action.payload;
		case FETCH_SUBSCRIPTION_FILTERS_FAILURE:
			return [];

		// case DEACTIVATE_API_KEY_SUCCESS:
		// 	const keyToDeactivate = [...state].find(el => el.key === action.payload);
		// 	const indexToDeactivate = [...state].indexOf(keyToDeactivate)
		// 	const inactiveKey = {...keyToDeactivate, state: 'INACTIVE'} 
		// 	return [...state].slice(0, indexToDeactivate).concat(inactiveKey).concat([...state].slice(indexToDeactivate + 1))
		// case DEACTIVATE_API_KEY_FAILURE:
		// 	return [...state];

		// case ACTIVATE_API_KEY_SUCCESS:
		// 	const keyToActivate = [...state].find(el => el.key === action.payload);
		// 	const indexToActivate = [...state].indexOf(keyToActivate)
		// 	const newKey = {...keyToActivate, state: 'ACTIVE'} 
		// 	return [...state].slice(0, indexToActivate).concat(newKey).concat([...state].slice(indexToActivate + 1))
		// case ACTIVATE_API_KEY_FAILURE:
		// 	return [...state];

		// case DELETE_API_KEY_SUCCESS:
		// 	return [...state].filter(el => el.key !== action.payload);
		// case DELETE_API_KEY_FAILURE:
		// 	return [...state];

		default:
			return state;
	}
}

export const getSubscriptionFilters = (state) => state;