import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import PlaceHolder from './PlaceHolder';
import GraphToggle from './GraphToggle';

export default function Panel({ title, icon, style, toggle, type, titleClass, description = '', toolbar, footer, children = <PlaceHolder /> }) {
	const customStyle = style ? { paddingBottom: '42%'} : {};
	return (
		<Grid padded stackable className="panel">
			<Grid.Row>
				<Grid.Column width="16">
					<div className="panel-header">
						<div className={`panel-title ${titleClass}`}>{title}</div>
						{(type=="txnVolume" || type=="txnValue") && <GraphToggle icon={icon} toggle={toggle}/>}
						<div className="panel-toolbar">{toolbar}</div>
					</div>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row style={{...customStyle}}>
				<Grid.Column>{children || description}</Grid.Column>
			</Grid.Row>
			{footer && (
				<Grid.Row>
					<Grid.Column>{footer}</Grid.Column>
				</Grid.Row>
			)}
		</Grid>
	);
}
