import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Icon, Button, Container } from 'semantic-ui-react';

const AnnouncementBar = ({ onClose, announcement }) => {
	const [ colorClass, setColorClass ] = useState('green');

	useEffect(() => {
		setColor();
	}, []);

	const setColor = () => {
		switch (announcement.severity) {
			case 'low':
				setColorClass('announcement-blue');
				break;
			case 'medium':
				setColorClass('announcement-orange');
				break;
			case 'critical':
				setColorClass('announcement-red');
				break;
			default:
				setColorClass('announcement-green');
		}
	};

	return (
		<Segment className={colorClass}>
			<Container textAlign="center">
				{announcement.description}

				{announcement.link && (
					<a style={{ marginLeft: '10px' }} href={announcement.link} target="_blank">
						<Button size="mini" secondary>
							Register here
						</Button>
					</a>
				)}
				<Icon name="close" className="close-notification" onClick={onClose} />
			</Container>
		</Segment>
	);
};

export default AnnouncementBar;
