import React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

export default function SignUp({ name, email, company, password, password1, onChange, onSubmit, errors = {}, loading }) {
	const isError = Object.keys(errors).length !== 0;
	return (
		<Form size="large" onSubmit={onSubmit} error={isError}>
			<Message error content={errors.non_field_errors} />
			<Form.Input
				id="name"
				name="name"
				fluid
				icon="user"
				iconPosition="left"
				placeholder="Name"
				autoFocus
				value={name}
				onChange={onChange}
				error={!!errors.name}
				autoComplete="name"
			/>
			<Message error content={errors.name} />
			<Form.Input
				id="email"
				name="email"
				fluid
				icon="mail"
				iconPosition="left"
				placeholder="Email"
				value={email}
				onChange={onChange}
				error={!!errors.email}
				autoComplete="email"
			/>
			<Message error content={errors.name} />
			<Form.Input
				id="company"
				name="company"
				fluid
				icon="building"
				iconPosition="left"
				placeholder="Company"
				value={company}
				onChange={onChange}
				error={!!errors.company}
				autoComplete="company"
				required={false}
			/>
			{/* <Message error content={errors.email} /> */}
			<Form.Input
				id="password"
				name="password"
				fluid
				icon="lock"
				iconPosition="left"
				placeholder="Password"
				type="password"
				value={password}
				onChange={onChange}
				autoComplete="password"
				error={!!errors.password}
			/>
			<Message error content={errors.password} />
			<Form.Input
				id="password1"
				name="password1"
				fluid
				icon="lock"
				iconPosition="left"
				placeholder="Confirm password"
				type="password"
				value={password1}
				onChange={onChange}
				autoComplete="password1"
				error={!!errors.password}
			/>
			{/* <Message error content={errors.password} /> */}

			<Button primary={true} type="submit" fluid size="large" loading={loading}>
				CREATE ACCOUNT
			</Button>
			<Message error content={errors.auth} />
		</Form>
	);
}
