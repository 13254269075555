import React, { Component } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import {
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton,
} from "react-share";
import {
	EmailIcon,
	FacebookIcon,
	TwitterIcon,
} from "react-share";

export default class Share extends Component {
	render() {
		const { url, params, title } = this.props;
		let shareURL = window.location.origin + url;

		return (
			<div className='share-param'>
				<Popup
					trigger={ <Icon className="clickable" name="share alternate"> </Icon>} flowing hoverable>
					<div style={{padding:"0.6em"}}>
						<EmailShareButton style={{float:"left", marginRight:"0.5em"}} url={shareURL} subject={title}>
							<EmailIcon round={true} size={35} />
						</EmailShareButton>
						<FacebookShareButton style={{float:"left", marginRight:"0.5em"}} url={shareURL}>
							<FacebookIcon round={true} size={35} />
						</FacebookShareButton>
						<TwitterShareButton url={shareURL}>
							<TwitterIcon round={true} size={35} />
						</TwitterShareButton>
					</div>
				</Popup>

				{ (params && !params.name) &&
					<a href={url} target="_blank" style={{display : 'flex'}} rel="noopener noreferrer">
						<Icon name="window restore"> </Icon>
					</a>
				}
			</div>
		)
	}
}