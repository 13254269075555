import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import TopicMessageRow from './TopicMessageRow';
import TopicMessagesTableHeader from './TopicMessagesTableHeader';

const TopicMessagesTable = ({ messages = [], limit, offset, sort, order, totalCount, loadData, showDetails }) => {
	console.log("IN MESSAGE TABLE: ", messages, totalCount)
	const messageRows = messages.map((message, index) => (
		<TopicMessageRow key={index} message={message} showDetails={showDetails} />
	));
	return (
		<DataTable
			limit={limit}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={TopicMessagesTableHeader}
			rows={messageRows}
			loadData={loadData}
		/>
	);
};

export default TopicMessagesTable;

TopicMessagesTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	showDetails: PropTypes.func.isRequired,
	loadData: PropTypes.func.isRequired
};