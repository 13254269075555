import React from "react";
import { Link } from "react-router-dom";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { formatNumber } from "../../utils";

const colors = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF7D33",
    "33FFFC",
    "#FF3368",
    "#D733FF",
    "#33FF83",
    "#FFDD33",
];
const radian = Math.PI / 180;

const renderCustomizedLabel = props => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent /*name*/ } = props;
    const sin = Math.sin(-radian * midAngle);
    const cos = Math.cos(-radian * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    // const mx = cx + (outerRadius - 10) * cos;
    // const my = cy + (outerRadius - 10) * sin;
    // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    // const ey = my;
    // const textAnchor = cos >= 0 ? 'start' : 'end';
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * cos;
    const y = cy + radius * sin;
    return (
        <g>
            {/* <text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fontSize="small"
				fill="#333"
			>{`${name}`}</text> */}
            <text
                x={percent < 0.05 ? x + radius * 1.1 : x}
                y={y}
                fill={percent < 0.05 ? "black" : "white"}
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central">
                {percent >= 0.01 && `${(percent * 100).toFixed(0)}%`}
            </text>
        </g>
    );
};

let isLinkEnabled;

const renderLegend = (value, entry) => {
    const { color, payload } = entry;
    console.log("ENTRY IN THE LEGEND: ", entry.payload.period);
    const valueLabel = value === 'HCS' ? 'CONSENSUS' : value;
    return (
        // <Link to={`/search?transactionTypes=${value}&period=${entry.payload.period}`}><span style={{ color, fontSize: "80%", cursor: "pointer" }}>
        !isLinkEnabled? 
        <Link to={`/search?serviceTypes=${entry.payload.type || valueLabel}&period=${entry.payload.period}`}>
            <span style={{ color, fontSize: "80%", cursor: "pointer" }}>
                {value} ({formatNumber(payload.value)})
            </span>
        </Link>
        :
        <span style={{ color, fontSize: "80%", cursor: "pointer" }}>
                {value} ({formatNumber(payload.value)})
            </span>
    );
};

export default ({ data, period, handleClick, dataKey = "value", customHeight = 270, legendHeight = 0, hideClickEvent, hideLink, hidePercentage, isLegend }) => {
    // console.log(data)
    isLinkEnabled = hideLink;
    return (
        <ResponsiveContainer width="100%" height={customHeight}>
            <PieChart>
                <Pie
                    data={data}
                    dataKey={dataKey}
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    labelLine={false}
                    label={!hidePercentage ? renderCustomizedLabel : null}
                    isAnimationActive={false}>
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index]}
                            style={{ cursor: "pointer" }}
                            period={period}
                            onClick={e => { //bug: click txn type
                                !hideClickEvent ? handleClick(entry.name) : e.stopPropagation();
                            }}
                        />
                    ))}
                </Pie>
                { isLegend ?
                    <Legend verticalAlign="bottom" height={legendHeight} formatter={renderLegend} align="left"/>
                    : null
                }
                <Tooltip formatter={value => new Intl.NumberFormat("en").format(value)} />
            </PieChart>
        </ResponsiveContainer>
    );
};
