import React from 'react';
import { Grid, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Item from './shared/Item';
import Number from './shared/Number';
import { fromDate } from '../utils';

export default function TopicSummaryTable({ topic = {}, onShowKey }) {
	const {
		topicID,
		expiry,
		createdDate,
		createdBy,
		transactionID,
		readableTransactionID,
		lastUpdatedOn,
		fileID,
		memo,
		balance,
		numRecentTxns
	} = topic;

	const linkToCreator = <div className="topic-links">{createdBy}</div>
	const linkToTransaction = <div className="topic-links">{transactionID}</div>

	return (
		<Grid relaxed="very" stackable>
			<Grid.Row>
				<Grid.Column> {/*width="9"*/}
					<Header as="h2">{topicID}</Header>
				</Grid.Column>
				{/* <Grid.Column width="7" className="summary-balance">
					<Header as="h2">
						ℏ <Number>{balance}</Number>
					</Header>
					<p className="balance-info">
						Current Balance (<Number>{numRecentTxns}</Number> txns last 30 days)
					</p>
				</Grid.Column> */}
			</Grid.Row>

			<Grid.Row /*columns="equal"*/>
				<Grid.Column width={2}>
					<Link to={`/accounts/${createdBy}`}>
						<Item text="Creator" sub={createdBy ? linkToCreator : 'N/A'} />
					</Link>
				</Grid.Column>
				<Grid.Column width={3}>
					<Item text="Date" sub={createdDate ? fromDate(createdDate) : 'N/A'} />
				</Grid.Column>
				<Grid.Column width={5}>
					<Link to={`/transactions/${transactionID}`}>
						<Item text="Transaction ID" sub={transactionID ? linkToTransaction : 'N/A'} />
					</Link>
				</Grid.Column>
				<Grid.Column width={6}>
					<Item text="Memo" sub={memo !== "" ? memo : 'No memo added'} />
				</Grid.Column>
			</Grid.Row>

			{/* <Grid.Row columns="equal">
				<Grid.Column>
					<Item text="Admin Key" sub={<Icon name="eye" onClick={onShowKey} className="clickable" />} />
				</Grid.Column>
				<Grid.Column>
					<Item text="File ID" sub={fileID} />
				</Grid.Column>
				<Grid.Column>
					<Item text="Memo" sub={memo} />
				</Grid.Column>
			</Grid.Row> */}
		</Grid>
	);
}
