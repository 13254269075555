import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import Panel from '../shared/Panel';
import SearchFacets from '../SearchFacets';
import ContractTable from '../ContractTable';
import TopicMessagesTable from '../TopicMessagesTable';
import * as actions from '../../redux/actions';
import {
  getSearchParams,
  getSearchedTopics,
  getSearchedTopicsCount,
  getTopicMessages,
  getMessages
  // getSearchedContractsFilters
} from '../../redux/reducers';
import { removeEmpty, concat } from '../../utils';

class TopicMessagesSearch extends Component {
  state = {
    viewParams: {
      offset: 0,
      limit: 25,
      page: 1,
      sortOn: 'topicSequenceNumber'
      // sort: 'createdOn',
      // order: 'desc'
    },
    filterParams: {}
  };

  componentDidMount = () => {
    const { searchParams } = this.props;
    // console.log('ContractSearch searchParams = ', searchParams);
    // console.log("PROPS IN DID MOUNT: ", this.props.match.params.topicID)
    this.fetchData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.searchParams !== prevProps.searchParams) {
      this.fetchData();
    }
  }

  fetchData = () => {
    // console.log("PROPS IN MESSAGES SEARCH: ", this.props)
    const { searchParams, fetchTopics, fetchTopicMessages } = this.props;
    const { viewParams, filterParams } = this.state;
    const id = this.props.match.params.topicID;

    const params = { ...searchParams, ...viewParams };
    // console.log('params = ', params);
    const queryParams = removeEmpty(params);
    // console.log('queryParams = ', queryParams);
    const esc = encodeURIComponent;
    const searchQuery = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');
    // console.log('searchQuery = ', searchQuery);
    const filterQuery = Object.keys(filterParams)
      .map((f) => {
        const values = filterParams[f];
        return values.map((v) => esc(f) + '=' + esc(v)).join('&');
      })
      .join('&');
    // console.log('filterQuery = ', filterQuery);
    const query = searchQuery + '&' + filterQuery;
    // console.log('query = ', query);

    fetchTopicMessages(id, query, filterQuery ? true : false);
  };

  loadData = (viewParams) => {
    const params = { ...this.state.viewParams, ...viewParams };
    this.setState({ viewParams: params }, () => this.fetchData());
  };

  // showTopicDetails = (topic) => this.props.history.push(`/topics/${topic.topicID}`);

  handleFilterToggle = (e, { name, checked, category }) => {
    // console.log('handleFilterToggle');
    // console.log('name = ', name);
    // console.log('checked = ', checked);
    // console.log('category = ', category);
    const oldArray = this.state.filterParams[category] || [];
    // console.log('oldArray = ', oldArray);
    let newArray;
    if (checked) newArray = concat([name], oldArray);
    else {
      newArray = oldArray.filter((k) => k !== name);
    }

    // console.log('newArray = ', newArray);
    //...this.state.filterParams.category, name ==== this line was commented out before commenting out the logs
    this.setState(
      {
        filterParams: {
          ...this.state.filterParams,
          [category]: newArray
        },
        viewParams: {
          ...this.state.viewParams,
          offset: 0
        }
      },
      () => this.fetchData()
    );
  };

  render() {
    const { filters, messages/*, totalCount*/ } = this.props;
    const { viewParams: { limit, offset, sort, order } } = this.state;
    messages && console.log('MESSAGES IN TOPIC MESSAGES SEARCH: ', messages);
    return (
      <Fragment>
        {messages && <TopicMessagesTable
          totalCount={messages.totalCount}
          messages={messages.data}
          limit={limit}
          offset={offset}
          sort={sort}
          order={order}
          loadData={this.loadData}
          showDetails={this.showTopicDetails}
        />}
      </Fragment>
      // <Grid padded stackable>
      // 	{filters && (
      // 		<Grid.Column width={4}>
      // 			<SearchFacets filters={filters} onFilterToggle={this.handleFilterToggle} />
      // 		</Grid.Column>
      // 	)}
      // 	<Grid.Column width={filters ? 12 : 16}>
      // 		<Panel>
      // 			{messages && <TopicMessagesTable
      // 				totalCount={totalCount}
      // 				messages={messages.data}
      // 				limit={limit}
      // 				offset={offset}
      // 				sort={sort}
      // 				order={order}
      // 				loadData={this.loadData}
      // 				showDetails={this.showTopicDetails}
      // 			/>}
      // 		</Panel>
      // 	</Grid.Column>
      // </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // ownProps.topic && console.log("ID IN OWN PROPS IN MESSAGES SEARCH: ", ownProps.topic)
  return {
    searchParams: getSearchParams(state),
    topics: getSearchedTopics(state),
    totalCount: getSearchedTopicsCount(state),
    messages: getMessages(state, ownProps)
    // filters: getSearchedTopicsFilters(state)
  }
};

TopicMessagesSearch = withRouter(connect(mapStateToProps, actions)(TopicMessagesSearch));

export default TopicMessagesSearch;
