import { combineReducers } from 'redux';
import {
	FETCH_CONTRACT_SUCCESS,
	FETCH_CONTRACTS_SUCCESS,
	FETCH_CONTRACT_TRANSACTIONS_SUCCESS,
	FETCH_CONTRACT_TRANSFERS_SUCCESS,
	FETCH_CONTRACT_BALANCE_SUCCESS,
	FETCH_CONTRACT_TX_COUNT_SUCCESS,
	FETCH_CONTRACT_BALANCE_HISTORY_LOAD,
	FETCH_CONTRACT_BALANCE_HISTORY_SUCCESS,
	FETCH_CONTRACT_TRANSACTION_VOLUME_LOAD,
	FETCH_CONTRACT_TRANSACTION_VOLUME_SUCCESS,
	FETCH_CONTRACT_USERS_COUNT_SUCCESS,
	FETCH_CONTRACT_BYTECODE_SUCCESS
} from '../types';

const addToContract = (state, id, data, loading = false) => {
	const contract = state[id];
	return {
		...state,
		[id]: {
			...(contract ? contract : {}),
			...data,
			loading: loading
		}
	};
};

const addEntry = (state, contract) => {
	const { contractID } = contract;
	return addToContract(state, contractID, contract);
};

const addEntries = (state, contracts) => {
	const entries = contracts.reduce((r, c) => addEntry(r, c), {});
	return {
		...state,
		...entries
	};
};

const addTransactions = (state, action) => {
	const { id, response } = action;
	return addToContract(state, id, { transactions: response });
};

const addTransfers = (state, action) => {
	const { id, response } = action;
	return addToContract(state, id, { transfers: response });
};

const addBalance = (state, payload) => {
	const { id, balance } = payload;
	return addToContract(state, id, { balance });
};

const addCount = (state, payload) => {
	const { id, numRecentTxns } = payload;
	return addToContract(state, id, { numRecentTxns });
};

const addBalanceHistory = (state, payload, loading) => {
	const { id, balanceHistory } = payload;
	return addToContract(state, id, { balanceHistory }, loading);
};

const addByteCode = (state, payload) => {
	const { id, byteCode } = payload;
	return addToContract(state, id, { byteCode });
};


const resetTxVolume = (state, action, loading) => {
	const { id, payload } = action;
	return addToContract(state, id, { txVolume: payload }, loading);
};

const addTxVolume = (state, action, loading) => {
	const { id, payload } = action;
	return addToContract(state, id, { txVolume: payload }, loading);
};


const addUsersCount = (state, action) => {
	const { id, payload } = action;
	return addToContract(state, id, { usersCount: payload });
};

const contractsById = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CONTRACT_SUCCESS:
			return addEntry(state, action.response);
		case FETCH_CONTRACTS_SUCCESS:
			return addEntries(state, action.response.data);
		case FETCH_CONTRACT_TRANSACTIONS_SUCCESS:
			return addTransactions(state, action);
		case FETCH_CONTRACT_TRANSFERS_SUCCESS:
			return addTransfers(state, action);
		case FETCH_CONTRACT_BALANCE_SUCCESS:
			return addBalance(state, action.payload);
		case FETCH_CONTRACT_TX_COUNT_SUCCESS:
			return addCount(state, action.payload);
		case FETCH_CONTRACT_BALANCE_HISTORY_LOAD:
			return addBalanceHistory(state, action.payload, true);
		case FETCH_CONTRACT_BALANCE_HISTORY_SUCCESS:
			return addBalanceHistory(state, action.payload, false);
		case FETCH_CONTRACT_BYTECODE_SUCCESS:
			return addByteCode(state, action.payload);
		case FETCH_CONTRACT_TRANSACTION_VOLUME_LOAD:
			return resetTxVolume(state, action, true);
		case FETCH_CONTRACT_TRANSACTION_VOLUME_SUCCESS:
			return addTxVolume(state, action, false);
		case FETCH_CONTRACT_USERS_COUNT_SUCCESS:
			return addUsersCount(state, action);
		default:
			return state;
	}
};


const allContracts = (state = [], action) => {
	switch (action.type) {
		case FETCH_CONTRACTS_SUCCESS:
			const entries = action.response.data.reduce((r, t) => {
				if (!state.includes(t.contractID)) r.push(t.contractID);
				return r;
			}, []);
			return [ ...state, ...entries ];
		default:
			return state;
	}
};

const contractsReducer = combineReducers({
	byId: contractsById,
	allIds: allContracts
});

export default contractsReducer;

export const getContract = (state, id) => state.byId[id];
export const getContracts = (state) => state.allIds;

export const getContractTransactions = (state, id) => {
	const contract = getContract(state, id);
	if (contract && contract.transactions) return contract.transactions.data;
	else return [];
};
export const getContractTransfers = (state, id) => {
	const contract = getContract(state, id);
	if (contract && contract.transfers) return contract.transfers.data;
	else return [];
};
export const getContractTransactionsCount = (state, id) => {
	const contract = getContract(state, id);
	if (contract && contract.transactions) return contract.transactions.totalCount;
	else return 0;
};
export const getContractTransfersCount = (state, id) => {
	const contract = getContract(state, id);
	if (contract && contract.transfers) return contract.transfers.totalCount;
	else return 0;
};
export const getContractBalanceHistory = (state, id) => {
	const contract = getContract(state, id);
	if (contract && contract.balanceHistory) return contract.balanceHistory;
	else return {};
};

export const getContractBalanceHistoryLoader = (state, id) => {
	const contract = getContract(state, id);
	return contract && contract.loading;
};

export const getContractByteCode = (state, id) => {
	const contract = getContract(state, id);
	if (contract && contract.byteCode) return contract.byteCode;
	else return {};
};

export const getContractTransactionVolume = (state, id) => {
	const contract = getContract(state, id);
	if (contract && contract.txVolume) return contract.txVolume;
	else return {};
};

export const getContractTransactionVolumeLoader = (state, id) => {
	const contract = getContract(state, id);
	return contract && contract.loading;
};

export const getContractUsersCount = (state, id) => {
	const contract = getContract(state, id);
	if (contract) return contract.usersCount;
};
