import { FETCH_CONTRACTS_REQUEST, FETCH_CONTRACTS_SUCCESS, FETCH_CONTRACTS_FAILURE, RESET_SEARCH } from '../types';

const initialState = {
	isLoading: false,
	filters: null,
	results: [],
	totalCount: 0
};

export default function searchedContracts(state = initialState, action) {
	switch (action.type) {
		case FETCH_CONTRACTS_REQUEST:
			return { ...state, isLoading: true };
		case FETCH_CONTRACTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				results: action.response.data,
				filters: action.filtered === true ? state.filters : action.response.facets,
				totalCount: action.response.totalCount
			};
		case FETCH_CONTRACTS_FAILURE:
			return { ...initialState };
		case RESET_SEARCH:
			return { ...initialState };
		default:
			return state;
	}
}

export const getSearchedContracts = (state) => state.results;
export const getSearchedContractsCount = (state) => state.totalCount;
export const getSearchedContractsFilters = (state) => state.filters;
