import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import Info from "../shared/Info";
import Panel from "../shared/Panel";
import HtsTxnTypeChartPanel from "../HtsTxnTypeChartPanel";
import TokenTable from "../TokenTable";
import * as actions from "../../redux/actions";
import { getSearchParams, getSearchedContractsFilters } from "../../redux/reducers";
import { removeEmpty } from "../../utils";
import { getHederaTokens, getHederaTokensCount } from "../../redux/reducers/hts";
import "../../assets/css/components/tokenDetails.scss";

class TokenSearch extends Component {
    state = {
        viewParams: {
            offset: 0,
            size: 25,
            page: 1,
        },
        filterParams: {},
    };

    componentDidMount = () => {
        this.fetchData();
    };

    componentDidUpdate(prevProps) {
        if (this.props.searchParams !== prevProps.searchParams) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const { searchParams, fetchTokens, location } = this.props;
        const { viewParams } = this.state;

        const params = { ...searchParams, ...viewParams, tokenType : 'NON_FUNGIBLE_UNIQUE' };
        const queryParams = removeEmpty(params);
        fetchTokens(queryParams);
    };

    loadData = viewParams => {
        const params = { ...this.state.viewParams, ...viewParams };
        this.setState({ viewParams: params }, () => this.fetchData());
    };

    showTokenDetails = token => this.props.history.push(`/tokens/${token.id}`);

    render() {
        const { tokens, totalCount, showTitle } = this.props;
        const {
            viewParams: { size, offset, sort, order },
        } = this.state;
        return (
            <Grid padded stackable>
                <Grid.Column>
                    <Panel
                        title={
                            showTitle ? (
                                <Fragment>
                                    <div style={{ marginLeft: "0px" }}>
                                        Non Fungible Tokens <Info id="hederaTokensTooltip" />
                                    </div>
                                </Fragment>
                            ) : (
                                ""
                            )
                        }>
                        <div id="token-search-wrapper">
                            <TokenTable
                                totalCount={totalCount}
                                tokens={tokens}
                                size={size}
                                offset={offset}
                                sort={sort}
                                order={order}
                                loadData={this.loadData}
                                showDetails={this.showTokenDetails}
                            />
                        </div>
                    </Panel>
                </Grid.Column>
                {/* <Grid.Column computer={5} tablet={16} mobile={16}>
                    <HtsTxnTypeChartPanel nonFungible={true}/>
                </Grid.Column> */}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    searchParams: getSearchParams(state),
    tokens: getHederaTokens(state),
    totalCount: getHederaTokensCount(state),
    filters: getSearchedContractsFilters(state),
});

TokenSearch = withRouter(connect(mapStateToProps, actions)(TokenSearch));

export default TokenSearch;
