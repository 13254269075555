import React from 'react';
import Panel from './shared/Panel';
import { getTokenDetails } from '../redux/reducers/hts';
import HederaTokenTransactions from './HederaTokenTransactions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const TokenTransactionsPanel = (props) => {

	return (
		<Panel>
      <HederaTokenTransactions />
		</Panel>
	);
}

const mapStateToProps = (state) => ({
  token: getTokenDetails(state)
});

export default withRouter(connect(mapStateToProps)(TokenTransactionsPanel));