import React, { useEffect } from 'react';
import ContactUs from '../containers/ContactUs';
import Page from '../shared/Page';
import { Header, Grid, Item, Container } from 'semantic-ui-react';



export default () => {

	useEffect(() => { window.scrollTo(0, 0) }, []);

	return (
		<Page>
			<Container>
			<Header style={{ marginTop: '3rem' }} dividing>
				CONTACT DRAGONGLASS
			</Header>

			<Grid stackable style={{ marginTop: '3rem' }}>
				<Grid.Column width={6}>
					{/* <Item.Group> */}
					<Item>
						<Item.Content>
							<Item.Header as="h4">Email</Item.Header>
							<Item.Description>info@dragonglass.me</Item.Description>
						</Item.Content>
					</Item>
					<br />
					<Item>
						<Item.Content>
							<Item.Header as="h4">Phone</Item.Header>
							<Item.Description>+1 212 768 1730</Item.Description>
						</Item.Content>
					</Item>
					{/* </Item.Group> */}
				</Grid.Column>
				<Grid.Column width={10}>
					<ContactUs />
				</Grid.Column>
			</Grid>
			</Container>
		</Page>
	);
}
