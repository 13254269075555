import React from 'react';
import { Container, Table } from 'semantic-ui-react';
import Number from './shared/Number';
import { Link } from 'react-router-dom';

export default function NodesTable({ nodes = [] }) {
	return (
		<Container fluid>
			<Table basic="very">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell className="blackColor align-center" width={2}>Node Id</Table.HeaderCell>
						<Table.HeaderCell className="blackColor" width={2}>Council Member</Table.HeaderCell>
						<Table.HeaderCell className="blackColor" width={1}>Hedera Account</Table.HeaderCell>
						<Table.HeaderCell className="blackColor align-right" width={1}>Txns*</Table.HeaderCell>
						<Table.HeaderCell className="blackColor align-right" width={2}>Value(ℏ)</Table.HeaderCell>
						<Table.HeaderCell className="blackColor align-right" width={2}>Stake(ℏ)</Table.HeaderCell>
						<Table.HeaderCell className="blackColor align-right" width={2}>Stake Rewarded(ℏ)</Table.HeaderCell>
						<Table.HeaderCell className="blackColor align-right" width={2}>Stake Not Rewarded(ℏ)</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{nodes.map((node) => {
						const { id, name, nodeId, numTransactions, hbarEarned, hedera_id, stake, stake_total, stake_not_rewarded, stake_rewarded, council_name } = node;
						return (
							<Table.Row key={id}>
								<Table.Cell className="align-center">{hedera_id}</Table.Cell>
								<Table.Cell>{council_name}</Table.Cell>
								<Table.Cell><Link to={`/accounts/${nodeId}`}>{nodeId}</Link></Table.Cell>
								<Table.Cell className="align-right">
									<Number>{numTransactions}</Number>
								</Table.Cell>
								<Table.Cell className="align-right">
									<Number>{hbarEarned}</Number>
								</Table.Cell>
								<Table.Cell className="align-right">
									<Number>{stake}</Number> {stake_total > 0 ? `(${((stake / stake_total) * 100).toFixed(2)}%)` : ''}
								</Table.Cell>
								<Table.Cell className="align-right">
									<Number>{stake_rewarded}</Number>
								</Table.Cell>
								<Table.Cell className="align-right" style={{paddingRight: '12px'}}>
									<Number>{stake_not_rewarded}</Number>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		</Container>
	);
}
