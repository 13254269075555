import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import Panel from '../shared/Panel';
import SearchFacets from '../SearchFacets';
import ContractTable from '../ContractTable';
import TopicTable from '../TopicTable';
import * as actions from '../../redux/actions';
import {
	getSearchParams,
	getSearchedTopics,
	getSearchedTopicsCount,
	getSearchedTopicsFilters
} from '../../redux/reducers';
import { removeEmpty, concat } from '../../utils';

class TopicSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			viewParams: {
				offset: 0,
				limit: props.isSearchPage ? 25 : 5,
				page: 1,
				// sort: 'createdOn',
				// order: 'desc'
			},
			filterParams: {}
		};
	}

	componentDidMount = () => {
		const { searchParams } = this.props;
		// console.log('ContractSearch searchParams = ', searchParams);
		this.fetchData();
	};

	componentDidUpdate(prevProps) {
		if (this.props.searchParams !== prevProps.searchParams) {
			this.fetchData();
		}
	}

	fetchData = () => {
		const { searchParams, fetchTopics, accountId } = this.props;
		const { viewParams, filterParams } = this.state;
		const params = { ...searchParams, ...viewParams };
		if (accountId) params['createdBy'] = accountId;
		// console.log('params = ', params);
		const queryParams = removeEmpty(params);
		// console.log('queryParams = ', queryParams);
		const esc = encodeURIComponent;
		const searchQuery = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');
		// console.log('searchQuery = ', searchQuery);
		const filterQuery = Object.keys(filterParams)
			.map((f) => {
				const values = filterParams[f];
				const key = f === 'Created_By' ? 'createdBy' : f;
				return values.map((v) => esc(key) + '=' + esc(v)).join('&');
			})
			.join('&');
		// console.log('filterQuery = ', filterQuery);
		const query = searchQuery + '&' + filterQuery;
		// console.log('query = ', query);

		fetchTopics(query, filterQuery ? true : false);
	};

	loadData = (viewParams) => {
		const params = { ...this.state.viewParams, ...viewParams };
		this.setState({ viewParams: params }, () => this.fetchData());
	};

	showTopicDetails = (topic) => this.props.history.push(`/topics/${topic.topicID}`);

	handleFilterToggle = (e, { name, checked, category }) => {
		// console.log('handleFilterToggle');
		// console.log('name = ', name);
		// console.log('checked = ', checked);
		// console.log('category = ', category);
		const oldArray = this.state.filterParams[category] || [];
		// console.log('oldArray = ', oldArray);
		let newArray;
		if (checked) newArray = concat([ name ], oldArray);
		else {
			newArray = oldArray.filter((k) => k !== name);
		}

		// console.log('newArray = ', newArray);
		//...this.state.filterParams.category, name ==== this line was commented out before commenting out the logs
		this.setState(
			{
				filterParams: {
					...this.state.filterParams,
					[category]: newArray
				},
				viewParams: {
					...this.state.viewParams,
					offset: 0
				}
			},
			() => this.fetchData()
		);
	};

	render() {
		const { filters, topics, totalCount, isAccountPage } = this.props;
		const { viewParams: { limit, offset, sort, order } } = this.state;
		topics && topics.length !== 0 && console.log('TOPICS IN TOPICS SEARCH: ', topics);
		return (
			<Grid padded stackable>
				{filters && !isAccountPage &&(
					<Grid.Column width={4}>
						<SearchFacets isComingFrom="topics" filters={filters} onFilterToggle={this.handleFilterToggle} />
					</Grid.Column>
				)}
				<Grid.Column width={filters && !isAccountPage ? 12 : 16}>
					<Panel>
						{topics && <TopicTable
							totalCount={totalCount}
							topics={topics}
							limit={limit}
							offset={offset}
							sort={sort}
							order={order}
							loadData={this.loadData}
							showDetails={this.showTopicDetails}
						/>}
					</Panel>
				</Grid.Column>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	searchParams: getSearchParams(state),
	topics: getSearchedTopics(state),
	totalCount: getSearchedTopicsCount(state),
	filters: getSearchedTopicsFilters(state)
});

TopicSearch = withRouter(connect(mapStateToProps, actions)(TopicSearch));

export default TopicSearch;
