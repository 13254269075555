import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Table, Checkbox, Button } from 'semantic-ui-react';
import { fromDate } from '../utils';
import Number from './shared/Number';
import { getUser } from '../redux/reducers';

const DappSelectTable = (props) => {

	const { dapps = [] } = props;
	dapps.sort((a, b) => a.id - b.id);

	const handleCellClick = (id) => {
        window.open(`/dapps/${id}`, '_blank');
    }
    
    const [selectedDapp, setSelectedDapp] = useState([]);
    const [disabled, setDisabled] = useState(true);

    const selectTheDapp = (dapp) => {
        console.log("THIS IS THE DAPP IN THE CHECKBOX: ", dapp)
        setSelectedDapp({ 
            ...dapp, 
            userName: props.user.name, 
            userId: props.user.id, 
            userEmail: props.user.email, 
            userCompany: props.user.company 
        });
        setDisabled(false);
    }

    const printSelectedDapp = (dapp) => {
        console.log("THIS IS THE SELECTED DAPP: ", selectedDapp);
        props.selectDappToOwn(selectedDapp.id, selectedDapp);
    }

	return (
        <Fragment>
            <Table basic="very" selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={7} /*style={{paddingLeft: "120px"}}*/>Company</Table.HeaderCell>
                        <Table.HeaderCell>Click to Select</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {dapps.map((dapp) => {
                        const { id, title, description, website, numTransactions, numAccounts, activeSince, logoURL } = dapp;
                        const image = logoURL ? <img className="logo-image" style={{maxWidth: "90px", maxHeight: "50px"}} src={logoURL} /> : '';
                        const logo = <span style={{width: "110px", textAlign: "center"}}>{image}</span>;
                        // const desc = description.slice(0, 90) + "...";
                        return (
                            <Table.Row key={id} style={{transition: "all 0.2s ease-in-out"}}>
                                <Table.Cell onClick={e => handleCellClick(id)} style={{paddingLeft: "10px", cursor: "pointer"}}>
                                        <div className="logo-title">{logo}<strong className="text-gray dapp-name">{title}</strong></div>
                                </Table.Cell>
                                <Table.Cell id="dapp-desc-homepage" style={{paddingRight: "15px", paddingLeft: "11px"}}>
                                        <Checkbox checked={selectedDapp.id === dapp.id} onChange={(e) => selectTheDapp(dapp)}/>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            <Button 
                type="submit" 
                style={{margin: "30px 0 20px 0", float: "right"}} 
                primary 
                disabled={disabled} 
                onClick={e => {printSelectedDapp(); props.handleCancel(); props.handleMessage()}}
                >
                SUBMIT
            </Button>
        </Fragment>
	);
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default withRouter(connect(mapStateToProps, actions)(DappSelectTable));
