import React, { Fragment, Component } from 'react';
import { Container, Grid, Responsive } from 'semantic-ui-react';
import AllTxnChartPanel from "./chartsPanel/AllTxnChartPanel";
import TxnTypeDimensionChartPanel from "./chartsPanel/TxnTypeDimensionChartPanel";
import AccountCreationChartPanel from "./chartsPanel/AccountCreationChartPanel";
import NftMintedChartPanel from "./chartsPanel/NftMintedChartPanel";
import NftTransferChartPanel from "./chartsPanel/NftTransferChartPanel";
import TokenTransferChartPanel from "./chartsPanel/TokenTransferChartPanel";
import TokenMintedChartPanel from "./chartsPanel/TokenMintedChartPanel";
import TxnTypeChartPanel from "./chartsPanel/TxnTypeChartPanel";
import TxnTypeNonHcsChartPanel from "./chartsPanel/TxnTypeNonHcsChartPanel";


import PeriodSelector from '../components/shared/PeriodSelector';
// import Info from './shared/Info';
import { withRouter } from 'react-router-dom';

const NumberFormater = (number) => {
	if (number > 1000000000) {
		return (number / 1000000000).toString() + 'B';
	} else if (number > 1000000) {
		return (number / 1000000).toString() + 'M';
	} else if (number > 1000) {
		return (number / 1000).toString() + 'K';
	} else {
		return number.toString();
	}
};

const styles = {
	containerStyle: {
		marginTop: '2%',
	  },
	contentGrid: {
		marginBottom: '20px',

	  },
	  searchInput: {
		marginTop: '20px',
	  },
	  p1: {
		fontSize: '20px',
		fontWeight: '600'
	  },
	  p2: {
		fontSize: '12px',
		fontWeight: '300',
	  },
	  linesContainerStyle: {
		display: 'flex',
		justifyContent: 'center',
	  },
	
	  lineStyle: {
		height: '2px',
		width: '100px',
		margin: '10px',
	  },
	  button: {
		borderRadius: "0px"
	  },
	  marginT: {
		marginTop: '20px'
	  }
}

export class Charts extends Component {
	state = {
		period: '1D',
		icon: 'chart line'
	};
	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
		//to do
		//update price and percent change
	};

	render() {
		const { period, icon } = this.state;

		return (
			<Fragment>
					<Container fluid textAlign="center" style={styles.containerStyle} className="w-98">
						<Grid columns={1} style={styles.contentGrid}>
							<Grid.Row style={{background: "black", color: 'white', padding: '5px'}}>
								<div>
									<span style={{padding: '12px'}}>Time Range</span>
									<span style={{padding: '4px', background: "white", color: 'black',}}><PeriodSelector value={period} onSelect={this.onPeriodSelect} /></span>
								</div>
							</Grid.Row>
							<Grid.Row> 
								<Grid centered stackable>
									<Grid.Row only="tablet computer mobile widescreen" >
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<AllTxnChartPanel period={period}/>
										</Grid.Column>
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<AccountCreationChartPanel period={period}/>
										</Grid.Column>
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<NftMintedChartPanel period={period}/>
										</Grid.Column>
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<NftTransferChartPanel period={period}/>
										</Grid.Column>
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<TokenTransferChartPanel period={period}/>
										</Grid.Column>
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<TokenMintedChartPanel period={period}/>
										</Grid.Column>
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<TxnTypeDimensionChartPanel period={period} isNoTooltip={true}/>
										</Grid.Column>
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<TxnTypeChartPanel period={period} customHeight={217}/>
										</Grid.Column>
										<Grid.Column computer={5} widescreen={3} style={styles.marginT}>
											<TxnTypeNonHcsChartPanel period={period} customHeight={217}/>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Row>
						</Grid>
					
					</Container>
				</Fragment>
		);
	}
};

export default withRouter(Charts);
