import React, { Component } from "react";
import { Loader, Segment } from "semantic-ui-react";

class LoadingIndicator extends Component {
    render() {
        return (
            <Segment basic>
                <Loader active />
             </Segment>
        )
    }
}

export default LoadingIndicator;
