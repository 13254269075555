import React, { Fragment } from "react";
import { getTokenDetails } from "../redux/reducers/hts";
import Panel from "./shared/Panel";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HtsTokenKeys from "../components/HtsTokenKeys";
import HtsTokenOverview from "../components/HtsTokenOverview";
import HtsTokenSummary from "../components/HtsTokenSummary";
import { Table } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";

const TokenDetailPanel = props => {
    return (
        <>
            <div
                className="title-image-block title-panel-header"
                style={{ display: "flex", height: "auto", marginBottom: 25 }}>
                {props.token && props.token.thumbnail && (
                    <div>
                        <img
                            style={{ height: 40, width: 40, marginLeft: 14, marginTop: 10 }}
                            src={props.token.thumbnail}
                        />
                    </div>
                )}
                {props.token && (
                    <div className="token-name-container" style={{ marginLeft: 10, marginTop: 20, display: "flex" }}>
                        <div className="token-label-overview" style={{ fontSize: 17, fontWeight: 500, marginLeft: 4 }}>
                            Token
                        </div>
                        <div
                            style={{
                                color: "gray",
                                marginLeft: 5,
                                fontWeight: 600,
                                marginTop: 1,
                                wordBreak: "break-word",
                                paddingRight: "1rem",
                                paddingBottom: "1rem",
                            }}>
                            {props.token.name ? props.token.name : ""}
                        </div>
                    </div>
                )}
            </div>
            <Grid stackable columns={2}>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Panel
                            title={
                                <Fragment>
                                    <div style={{ marginLeft: "0px" }}>Overview</div>
                                </Fragment>
                            }>
                            <HtsTokenOverview />
                        </Panel>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Panel
                            title={
                                <Fragment>
                                    <div style={{ marginLeft: "0px" }}>Profile Summary</div>
                                </Fragment>
                            }>
                            <HtsTokenSummary />
                        </Panel>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Grid stackable columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Panel
                            title={
                                <Fragment>
                                    <div style={{ marginLeft: "0px" }}>Keys</div>
                                </Fragment>
                            }
                            description="">
                            <HtsTokenKeys />
                        </Panel>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

const mapStateToProps = state => ({
    token: getTokenDetails(state),
});

export default withRouter(connect(mapStateToProps)(TokenDetailPanel));
