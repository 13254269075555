import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TransferTable from '../TransferTable';
import * as actions from '../../redux/actions';
import { getContractTransfers, getContractTransfersCount } from '../../redux/reducers';
import { removeEmpty } from '../../utils';

class ContractTransfers extends Component {
	state = {
		offset: 0,
		limit: 5,
		page: 1,
		sort: 'consensusTime',
		order: 'desc'
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.contractId !== prevProps.contractId || this.props.filter !== prevProps.filter) {
			this.setState(
				{
					offset: 0,
					page: 1
				},
				() => this.fetchData()
			);
		}
	}

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		const { filter, contractId, fetchContractTransfers } = this.props;
		const params = { q: filter, ...this.state };

		const queryParams = removeEmpty(params);

		const esc = encodeURIComponent;
		const query = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');

		fetchContractTransfers(contractId, query);
	};

	showTransactionDetails = (transfer) => {
		this.props.history.push(`/transactions/${transfer.transactionID}`);
	};

	render() {
		const { transfers, totalCount } = this.props;
		return (
			<TransferTable
				transfers={transfers}
				limit={this.state.limit}
				offset={this.state.offset}
				sort={this.state.sort}
				order={this.state.order}
				totalCount={totalCount}
				loadData={this.loadData}
				showDetails={this.showTransactionDetails}
			/>
		);
	}
}

const mapStateToProps = (state, { match: { params: { contractId } } }) => ({
	contractId,
	transfers: getContractTransfers(state, contractId),
	totalCount: getContractTransfersCount(state, contractId)
});

ContractTransfers = withRouter(connect(mapStateToProps, actions)(ContractTransfers));

export default ContractTransfers;
