import { FETCH_CONFIG_SUCCESS } from '../types';

const initialState = {
	networks: []
};

export default function news(state = initialState, action) {
	switch (action.type) {
		case FETCH_CONFIG_SUCCESS:
			return action.response;
		default:
			return state;
	}
}

export const getConfig = (state) => state;
export const getNetworks = (state) => state.networks;
