import React from "react";
import { Table } from "semantic-ui-react";

export default function ContractTableHeader(props) {
    return (
        <Table.Row>
            <Table.HeaderCell
                width={1}
                sorted={props.column === "id" ? props.direction : null}
                onClick={() => props.handleSort("id")}>
                ID
            </Table.HeaderCell>
            <Table.HeaderCell
                width={4}
                sorted={props.column === "name" ? props.direction : null}
                onClick={() => props.handleSort("name")}>
                Name
            </Table.HeaderCell>
            <Table.HeaderCell
                width={2}
                sorted={props.column === "symbol" ? props.direction : null}
                onClick={() => props.handleSort("symbol")}>
                Symbol
            </Table.HeaderCell>
            <Table.HeaderCell
                width={2}
                sorted={props.column === "createdOn" ? props.direction : null}
                onClick={() => props.handleSort("createdOn")}>
                Created On
            </Table.HeaderCell>
            {/* <Table.HeaderCell
        width={1}
				sorted={props.column === 'decimals' ? props.direction : null}
				onClick={() => props.handleSort('decimals')}
			>
				Decimals
			</Table.HeaderCell> */}
            <Table.HeaderCell
                sorted={props.column === "supply" ? props.direction : null}
                onClick={() => props.handleSort("supply")}>
                Total Supply
            </Table.HeaderCell>
            {/* <Table.HeaderCell /> */}
        </Table.Row>
    );
}
