import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AppliedRoute from './AppliedRoute';

//Core App pages
// import Home from '../components/pages/Home';
import LoginPage from '../components/pages/Login';
import SignupPage from '../components/pages/Signup';
import ResetPasswordPage from '../components/pages/ResetPassword';
// import AccountPage from '../components/pages/Account';
import ContractPage from '../components/pages/Contract';
// import TransactionPage from '../components/pages/Transaction';
import SearchPage from '../components/pages/Search';
import ProfilePage from '../components/pages/Profile';

import TopicPage from '../components/pages/Topic';

import NotFound from '../components/pages/NotFound';
import TermsPage from '../components/pages/Terms';
import PrivacyPage from '../components/pages/Privacy';
import ContactUsPage from '../components/pages/ContactUs';
import AboutPage from '../components/pages/AboutNew';
import PricingPage from '../components/pages/Pricing';
import FAQPage from '../components/pages/FAQ';
import PaymentErrorPage from '../components/pages/PaymentError';

import APIViewPage from '../components/pages/APIView';
import DappPage from '../components/pages/Dapp';
import DappCatalogPage from '../components/pages/DappCatalogPage';

import SubscriptionViewPage from '../components/pages/SubscriptionView';
import DeploymentViewPage from '../components/pages/DeploymentView';

import APIViewNewPage from '../components/pages/APIViewNew';

import withTracker from '../components/hoc/withTracker';
import HederaTokenPage from '../components/pages/HederaTokenPage';
import FungibleTokenSearchPage from '../components/pages/FungibleTokenSearchPage';
import NonFungibleTokenSearchPage from '../components/pages/NonFungibleTokenSearchPage';

import NftTokenPage from '../components/pages/NftToken';
import AccountCreationVolumeChartPanel from "../components/AccountCreationVolumeChartPanel";
import Charts from '../components/pages/Charts';
import TransactionCounts from '../components/pages/TransactionCounts';

import { FUNGIBLE_TOKEN, NON_FUNGIBLE_TOKEN } from '../constants';

// New Pages
import HomePage from '../pages/HomePage';
import AccountDetailsPage from '../pages/AccountDetailsPage';
import AccountsPage from '../pages/AccountsPage';
import TransactionsDetailsPage from '../pages/TransactionsDetailsPage';
import NftExplorerPage from '../pages/NftExplorerPage';
import NftCollectionDetailsPage from '../pages/NftCollectionDetailsPage';
import NftsDetailsPage from '../pages/NftsDetailsPage';
import NftMarketplaceFormPage from '../pages/NftMarketplaceFormPage';


const Routes = () => (
	<Switch>
		<Route path="/" exact render={() => <Redirect to="/home" />} />
		<AppliedRoute path="/home" exact component={withTracker(HomePage)} />
		<AppliedRoute path="/accounts" exact component={withTracker(AccountsPage)} />
		<AppliedRoute path="/accounts/:accountId" exact component={withTracker(AccountDetailsPage)} />
		{/* <AppliedRoute path="/accounts/:accountId" exact component={withTracker(AccountPage)} /> */}
		{/* <AppliedRoute path="/transactions/:txId" exact component={withTracker(TransactionPage)} /> */}
		<AppliedRoute path="/transactions/:txId" exact component={withTracker(TransactionsDetailsPage)} />
		<AppliedRoute path="/contracts/:contractId" exact component={withTracker(ContractPage)} />

		<AppliedRoute path="/topics/:topicID" exact component={withTracker(TopicPage)} />

		<AppliedRoute path="/search" exact component={withTracker(SearchPage)} />

		<AppliedRoute path={FUNGIBLE_TOKEN} exact component={withTracker(FungibleTokenSearchPage)} />
		<AppliedRoute path={NON_FUNGIBLE_TOKEN} exact component={withTracker(NonFungibleTokenSearchPage)} />

		{/* <AppliedRoute path="/tokens/:tokenId" exact component={withTracker(HederaTokenPage)} /> */}

		{/* <AppliedRoute path="/nft-token/:tokenId" exact component={withTracker(NftTokenPage)} /> */}

		<AppliedRoute path="/chart" exact component={Charts} />
		<AppliedRoute path="/count" exact component={TransactionCounts} />

		<PublicRoute path="/login" exact component={withTracker(LoginPage)} />
		<PublicRoute path="/signup" exact component={withTracker(SignupPage)} />
		<PublicRoute path="/reset-password" exact component={withTracker(ResetPasswordPage)} />
		<PrivateRoute path="/profile" exact component={withTracker(ProfilePage)} />

		<AppliedRoute path="/contact-us" exact component={withTracker(ContactUsPage)} />
		<AppliedRoute path="/about" exact component={withTracker(AboutPage)} />
		<AppliedRoute path="/pricing" exact component={withTracker(PricingPage)} />
		<AppliedRoute path="/terms" exact component={withTracker(TermsPage)} />
		<AppliedRoute path="/privacy" exact component={withTracker(PrivacyPage)} />
		<AppliedRoute path="/faqs" exact component={withTracker(FAQPage)} />

		{/* <AppliedRoute path="/apiview" exact component={withTracker(APIViewPage)} /> */}
		<AppliedRoute path="/apiview" exact component={withTracker(APIViewNewPage)} />
		<AppliedRoute path="/dapps/:dappId" exact component={withTracker(DappPage)} />
		<AppliedRoute path="/dappcatalog" exact component={withTracker(DappCatalogPage)} />

		<AppliedRoute path="/subscriptionview" exact component={withTracker(SubscriptionViewPage)} />
		<AppliedRoute path="/deploymentview" exact component={withTracker(DeploymentViewPage)} />

		<AppliedRoute path="/nft-explorer" exact component={withTracker(NftExplorerPage)} />
		<AppliedRoute path="/nft-explorer/:tokenId" exact component={withTracker(NftCollectionDetailsPage)} />
		<AppliedRoute path="/tokens/:tokenId" exact component={withTracker(NftCollectionDetailsPage)} />
		<AppliedRoute path="/nft-token/:tokenId" exact component={withTracker(NftsDetailsPage)} />
		<AppliedRoute path="/nft/:tokenId" exact component={withTracker(NftsDetailsPage)} />
		<AppliedRoute path="/nft-marketplace" exact component={withTracker(NftMarketplaceFormPage)} />

		<PrivateRoute path="/paymenterror" exact component={withTracker(PaymentErrorPage)} />

		{/* Finally, catch all unmatched routes */}
		<AppliedRoute component={NotFound} />
	</Switch>
);

export default Routes;
