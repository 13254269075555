import { HBAR_SELECTED, TBAR_SELECTED, USD_SELECTED } from '../types';
import Cookies from 'universal-cookie';

export default function currency(state = "", action) {
		const cookies = new Cookies();
		const selCurrency = cookies.get("selectedCurrency")
	switch (action.type) {
		case HBAR_SELECTED:
			return selCurrency || "hbar";
		case TBAR_SELECTED:
			return selCurrency || "tbar";
        case USD_SELECTED:
            return "usd";
		default:
			return selCurrency || state;
	}
}

export const getCurrency = (state) => {
	const cookies = new Cookies();
	const selCurrency = cookies.get("selectedCurrency")
	return selCurrency || state;
};