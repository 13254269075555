import React, { Component } from 'react';
import { Table, Pagination } from 'semantic-ui-react';
import PageSizeSelect from './PageSizeSelect';
import { formatNumber } from '../../utils';

const queryParams = [ 'limit', 'offset', 'sort', 'order', 'page' ];

export default class DataTable extends Component {
	state = {
		limit: 5,
		offset: 0,
		page: 1,
		sort: '',
		order: null,
		totalCount: 0,
		q: ''
	};

	componentDidMount() {
		const { limit, offset, page } = this.props;
		this.setState({
			limit: limit || this.state.limit,
			offset: offset || this.state.offset,
			page: page || this.state.page
		});
	}

	static directionConverter(order) {
		if (order === 'asc') {
			return 'ascending';
		} else if (order === 'desc') {
			return 'descending';
		} else {
			return null;
		}
	}

	handleSort = (clickedColumn) => {
		const { sort, order } = this.state;

		let newOrder = order === 'asc' ? 'desc' : 'asc';
		if (sort !== clickedColumn) {
			newOrder = 'asc';
		}

		this.loadData({
			sort: clickedColumn,
			page: 1,
			offset: 0,
			order: newOrder
		});
	};

	onChangeLimit = (event, data) => {
		if (data.value !== this.state.limit) {
			this.loadData({ limit: data.value, offset: 0, page: 1 });
		}
	};

	onSubmitFilter = (filter) => {
		if (filter !== this.state.q) {
			this.loadData({ q: filter, offset: 0, page: 1 });
		}
	};

	onChangePage = (event, data) => {
		const { activePage } = data;
		if (activePage !== this.state.page) {
			const offset = this.state.limit * (activePage - 1);
			this.loadData({ page: activePage, offset });
		}
	};

	loadData = (params) => {
		const newState = Object.assign({}, this.state, params);
		queryParams.forEach(function(element) {
			if (!(element in params) && newState[element] && newState[element] !== '') {
				params[element] = newState[element];
			}
		});

		// const esc = encodeURIComponent;
		// const query = Object.keys(params).map((k) => esc(k) + '=' + esc(params[k])).join('&');
		this.setState(newState, () => {
			this.props.loadData(params);
		});
	};

	render() {
		const { totalCount = 0, header, rows, isNoPagination } = this.props;
		const Header = header;

		// console.log('limit props dt = ', this.props.limit);
		// console.log('offset props dt = ', this.props.offset);

		const limit = this.props.limit || this.state.limit;
		const offset = this.props.offset >= 0 ? this.props.offset : this.state.offset;
		const sort = this.props.sort || this.state.sort;
		const order = this.props.order ? this.props.order : this.state.order;

		const activePage = offset / limit + 1 || this.state.page;
		const totalPages = Math.ceil(totalCount / limit);

		// console.log('isNoPagination = ', isNoPagination);
		// console.log('totalCount = ', totalCount);
		// console.log('limit = ', limit);
		// console.log('offset = ', offset);
		// console.log('totalPages = ', totalPages);
		// console.log('activePage = ', activePage);
		return (
			<React.Fragment>
				{/* { !isNoPagination &&
					<>
						<PageSizeSelect limit={limit.toString()} onChangeLimit={this.onChangeLimit} />Total{' '}
						<strong>{formatNumber(totalCount)}</strong>
					</>
				} */}
				<Table basic="very" sortable>
					<Table.Header>
						<Header
							column={sort}
							direction={DataTable.directionConverter(order)}
							handleSort={this.handleSort}
							{...this.props}
						/>
					</Table.Header>
					{
						rows.length ?
							(
								<Table.Body>{rows}</Table.Body>
							)
							: <Table.Body>
								<Table.Row>
									<Table.Cell colSpan={4} textAlign="center">
									No data available
									</Table.Cell>
								</Table.Row>
							</Table.Body>
					}

					{ !isNoPagination && (
						<Table.Footer>
							<Table.Row>
								<Table.HeaderCell colSpan="8">
									<Pagination
										totalPages={Math.ceil(totalCount / limit)}
										activePage={activePage}
										onPageChange={this.onChangePage}
										boundaryRange={0}
										siblingRange={0}
									/>
									<span style={{marginLeft: '12px'}}>
										<PageSizeSelect limit={limit.toString()} onChangeLimit={this.onChangeLimit} />Total{' '}
										<strong>{formatNumber(totalCount)}</strong>
									</span>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					)
					}
				</Table>
			</React.Fragment>
		);
	}
}
