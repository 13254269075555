import React from 'react';
import { Table, Header, Popup, Icon } from 'semantic-ui-react';

const SubscriptionQueuePopup = ({queueUrl}) => {

    return (
        <Popup
            content={
                // <Table basic="very">
                //     <Table.Body>
                //         {Object.keys(parameters).map((data, key) => {
                //         return (
                //         <Table.Row key={key}>
                //             <Table.Cell className="bold">{data}</Table.Cell>
                //             <Table.Cell><div style={{maxWidth: "600px", maxHeight: "200px", overflow: "auto"}}>{JSON.stringify(parameters[data])}</div></Table.Cell>
                //         </Table.Row>
                //         );
                //     })}
                //     </Table.Body>
                // </Table>
                queueUrl
            }
            // trigger={<Icon className="clickable" name="list" color="blue" />}
            trigger={<span>{queueUrl.slice(0, 10)}...</span>}
            position="bottom right"
            size="small"
            hoverable={true}
            style={{cursor: "pointer"}}
            basic
        />
    )
}

export default SubscriptionQueuePopup;
