import React from 'react';
import Number from './Number';
import { connect } from 'react-redux';
import { getCurrency } from '../../redux/reducers';
import { toHbar } from '../../utils';

function Money({ currency, hidePriceSymbol, children }) {
	let symbol, value;
	switch (currency) {
		case 'hbar':
			symbol = 'ℏ';
			value = toHbar(children);
			break;
		default:
			symbol = 'tℏ';
			value = children;
	}

	return (
		<React.Fragment>
			<Number enableToFixed={symbol === 'tℏ' ? false : true}>{value}</Number> {hidePriceSymbol ? '' : symbol}
		</React.Fragment>
	);
}

const mapStateToProps = (state) => ({
	currency: getCurrency(state),
});

export default connect(mapStateToProps)(Money);