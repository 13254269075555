import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Stat from '../../../components/shared/Stat';
import LineChart from '../../../components/shared/LineChart';
import * as actions from '../../../redux/actions';
import { getNftMintedCharts } from '../../../redux/reducers';
import { formatTimestamp } from '../../../utils';
import LoadingIndicator from "../../../components/containers/LoadingIndicator";

class NftMintedChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchNftMinted, period } = this.props;
		fetchNftMinted({period});
	}
	render() {
		const { volume: { totalSum, /* totalCount,*/ pctChange, data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [new Intl.NumberFormat('en').format(value) + ' NFTs'];
		if (!this.props.volume.loading) {
			return (
				data && data.length ?
					<Fragment>
						<Stat count={totalSum} pct={pctChange} label="NFTs" />
						<br />
						<LineChart
							graphType={this.props.graphType}
							data={data}
							tickFormatter={formatter}
							labelFormatter={formatter}
							valueFormatter={valueFormatter}
						/>
					</Fragment>
				:  <div>No Nfts available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state) => ({
	volume: getNftMintedCharts(state)
});

NftMintedChart = connect(mapStateToProps, actions)(NftMintedChart);

export default NftMintedChart;
