import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { Container, Grid } from 'semantic-ui-react';
import DisplayContent from '../atoms/DisplayContent';
import Shimmer from '../atoms/Shimmer';
import FwdPrePagination from './FwdPrePagination';
import LoadingIndicator from '../components/containers/LoadingIndicator';
import { fromDate } from '../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const styles = {
	  marginT: {
		marginTop: '50px'
	  },
	  breakEllipsis: {
		width: '100%',
		maxWidth: '100%',
		wordWrap: 'break-word',
		wordBreak: 'break-word'
	  },
}

export default function NFTExplorerList(props) {
	const nfts = props.nfts;
	const isLoading = props.isLoading;
	const history = useHistory();
	const [notFoundText, setNotFoundText] = useState("Loading...");
	useEffect(() => {
		if (!props.nfts.length) {
			setTimeout(() => { setNotFoundText('No NFTs found')}, 3000);
		}
	}, [props.nfts.length]);


	const handleDetailsClick = (token, serialNumber) => {
		history.push(`/nft/${token}?serialNumber=${serialNumber}`);
	}

	const nftDetails = (nft, padding) => {
		if (props.noRoundBorder) {
			return (
				<div style={{background: 'white', padding: '10px'}}>
					<div className="bold ellipsis">{nft.name || "None"}</div>
					<div>{nft.token}  #{nft.serialNumber}</div>
					<div>
						<Grid>
							<Grid.Row columns={2} style={{paddingBottom: "2px"}}>
								<Grid.Column>Created</Grid.Column>
								<Grid.Column>Owner</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
					<div>
						<Grid>
							<Grid.Row columns={2}>
								<Grid.Column >{nft.createdOn ? fromDate(nft.createdOn) : ''}</Grid.Column>
								<Grid.Column>{nft.owner}</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
				</div>
			)
		} else  {
			return (
				<Grid stackable>
					<Grid.Row column={2} style={{...padding}}>
						<Grid.Column className="ellipsis" onClick={() => handleDetailsClick(nft.token, nft.serialNumber)} width={ nft.marketplaceLink ? 7 : 8}>{nft.name}</Grid.Column>
						{
							nft.marketplaceLink
							?
							(
								<Grid.Column width={9} className="align-right ellipsis"><a href={nft.marketplaceLink} target='_blank'>Listed At {nft.marketplaceLink}</a></Grid.Column>
							)
							: <Grid.Column width={8} className="align-right ellipsis"><Link to={`/nft-marketplace?token=${nft.token}&nftOwner=${nft.owner}&serial=${nft.serialNumber}`}>Add NFT Marketplace</Link> </Grid.Column>
						}
					</Grid.Row>
					<Grid.Row column={2} style={{paddingTop: '0px'}} onClick={() => handleDetailsClick(nft.token, nft.serialNumber)} >
						<Grid.Column width={8}>{nft.token}</Grid.Column>
						<Grid.Column width={8} style={{textAlign: 'right'}}>#{nft.serialNumber}</Grid.Column>
					</Grid.Row>
				</Grid>
			)
		}
	}

	return (
			<Container fluid>
				<Grid centered stackable>
					<Grid.Row only="tablet computer mobile widescreen" style={{justifyContent: props.isCenter ? 'center' : 'initial', padding: '0px'}}>
						{
							nfts && nfts.length
							? nfts.map((item, nftIndex) =>
								{
									// let padding = { paddingBottom: item && item.name ? '0px': '20px' };
									let padding = { paddingBottom: '0px'};
									return (
										<Grid.Column style={{...styles.marginT}} computer={props.isCenter ? 3 : 4} widescreen={props.isCenter ? 3 : 4} key={`${item.id}-${nftIndex}`}>
											<Container className="clickable visual" 
												style={{ position: 'relative',
												paddingBottom: '100%',
												overflow: 'hidden',
												border:  '1px solid #ccc',
												borderRadius: `${props.noRoundBorder ? 'none' : '10px'}`
											}}
											fluid>
												<div onClick={() => handleDetailsClick(item.token, item.serialNumber)}>
													<DisplayContent 
													styles={{position: 'absolute',
														top: 0,
														left: 0,
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														borderTopLeftRadius: '8px', borderTopRightRadius: '8px',
													}}
													size={'xl'} mimeType={item.dataType} src={item.imageUrl} alt="NFT" />
												</div>
												<div 
												style={{ 
													padding: '1rem',
													backgroundColor: 'white',
													borderTop: '1px solid #ccc',
													color: 'black',
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													width: '100%',
													position: 'absolute',
													top: 'calc(100% - 4rem)',
												}}
												>
													{nftDetails(item, padding)}
												</div>
											</Container>
										</Grid.Column>
									)
								}
							)
							: null
						}
					</Grid.Row>
					<Grid.Row>
					{
						isLoading ?
						(
							// Shimmer effect placeholder
							// <LoadingIndicator />
							<Shimmer />
						  )
						:
						!nfts.length && (
						<Grid.Column colSpan={4} textAlign="center">
							{notFoundText}
						</Grid.Column>
						)
					}
					</Grid.Row>
					<Grid.Row> 
						{
							props.noPagination ?
							null
							: <FwdPrePagination onLoadMore={props.onLoadMore} limit={props.limit || 10} totalCount={props.totalCount || 0}/>
						}
					</Grid.Row>
				</Grid>
			</Container>
	);
}
