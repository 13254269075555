import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import 'react-toastify/dist/ReactToastify.min.css';

const CloseButton = ({ closeToast }) => <Icon name="window close outline" onClick={closeToast} />;

export default function Notification() {
	return (
		<ToastContainer
			closeButton={<CloseButton />}
			className="notification-container"
			toastClassName="notification"
			bodyClassName="notification-body"
			autoClose={4000}
			position="bottom-center"
			closeOnClick
		/>
	);
}
