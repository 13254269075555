import React from 'react';
import { Link } from 'react-router-dom';
import Login from '../containers/Login';
import Page from '../shared/Page';
import { Grid, Icon } from 'semantic-ui-react';
import Logo from '../shared/Logo';
// import logo from '../../assets/images/logo_dragonglass@2x.png';

export default () => (
	<Page className="auth">
		<Grid textAlign="center" style={{ minHeight: '100vh' }} verticalAlign="middle">
			<Grid.Column style={{ maxWidth: 450 }} textAlign="center">
				{/* <Image src={logo} size="small" /> */}
				<Logo />
				<Login />
				<br />
				<Link to="/">
					<Icon name="arrow left" /> BACK TO HOME
				</Link>
			</Grid.Column>
		</Grid>
	</Page>
);
