import {
	FETCH_MESSAGES_SUCCESS,
	FETCH_MESSAGES_FAILURE
} from '../types';

export default function messages(state = [], action) {
	switch (action.type) {
		case FETCH_MESSAGES_SUCCESS:
			console.log("IN REDUCER, FETCH MESSAGES", action.response)
			return action.response;
		case FETCH_MESSAGES_FAILURE:
			return [];
		default:
			return state;
	}
}

export const getMessages = (state) => state;