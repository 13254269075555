import React, { Component } from 'react';
import Panel from './shared/Panel';
import PeriodSelector from './shared/PeriodSelector';
import TokenTxnVolumeChart from './containers/TokenTxnVolumeChart';
import Info from './shared/Info';
import { parseQueryParams } from  '../utils';
import { withRouter } from 'react-router-dom';
import Share from './shared/Share';

export class TokenTxnVolumeChartPanel extends Component {
	state = {
		period: '1D',
		tokenId: ''
	};

	componentDidMount() {
		const params = parseQueryParams(this.props.location.search);
		if(params && params.period) {
				this.setState({
						period: params.period,
				});
		}
	}

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
	};

	sendTokenIdparam = (id) => {
		this.setState({tokenId: id})
	}

	render() {
		const { period } = this.state;
		let url = `/chart?name=token-volume&period=${this.state.period}&tokenId=${this.state.tokenId}`;
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						TRANSACTION VOLUME <Info id="txVolume" />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				footer={<Share url={url} params={params} title={"Dragon Glass Token Transaction Volume Chart"}/>}
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				<TokenTxnVolumeChart period={period} sendTokenIdparam={this.sendTokenIdparam}/>
			</Panel>
		);
	}
}

export default withRouter(TokenTxnVolumeChartPanel);