import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Container, Table, Icon, Button } from 'semantic-ui-react';
import { getNftsHistoricalDetail ,getSearchNFTsData } from '../redux/reducers';
import { getTokenDetails } from "../redux/reducers/hts";
import Page from '../components/shared/Page';
import * as actions from '../redux/actions';
import { localDateTime, removeEmpty, adjustWithDecimals, isMobile } from '../utils';
import { Link } from "react-router-dom";
import Panel from '../atoms/Panel';
import { Helmet } from 'react-helmet';
import HederaTokenDetailTabs from '../components/HederaTokenDetailTabs';
import CustomBreadcrumb from '../atoms/CustomBreadcrumb';
import Number from '../atoms/Number';
import CodeSnippet from '../components/shared/CodeSnippet';
import Item from '../components/shared/Item';
import Money from '../components/shared/Money';


const styles = {
	containerStyle: {
		marginTop: '2%',
	  },
}
const NftCollectionDetailsPage = (props) => {

	const [limit, setLimit] = useState(1);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState('createdOn');
	const [order, setOrder] = useState('desc');

	const [showAdminKey, setShowAdminKey] = useState(false);
	const [showFreezeKey, setShowFreezeKey] = useState(false);
	const [showSupplyKey, setShowSupplyKey] = useState(false);
	const [showWipeKey, setShowWipeKey] = useState(false);
	const [showKycKey, setShowKycKey] = useState(false);
	const [showMoreDetails, setShowMoreDetails] = useState(false);
	
	const { nftHistoricalData, nftsData, tokenDetails,  } = props;
	const nftCreatorDetails = nftHistoricalData && nftHistoricalData.length && nftHistoricalData[0] || {};
	const latestCollections = tokenDetails && tokenDetails || {};
	const nfts = nftsData && nftsData.nfts || [];
	const nftsTotalCount = nftsData && nftsData.totalCount || [];
	// console.log('nftsTotalCount', nftsTotalCount);

	useEffect(() => { fetchData(); fetchHistoryData();}, [limit]);
	useEffect(() => { window.scrollTo(0, 0); }, [nftHistoricalData.length]);

	const fetchData = () => {
		 const queryParams = {
			offset,
			limit,
			page: 1,
			sort,
			order,
			tokenID: props.tokenId,
			tokenId: props.tokenId,
		 }
		const { fetchTokenDetails, searchParams } = props;
		const params = { ...searchParams, ...queryParams};
		const queryParamsF = removeEmpty(params);
		let nftDetail = queryParamsF;
		fetchTokenDetails(nftDetail);
	};

	const fetchHistoryData = () => {
		const { fetchNftsHistoricalDetails, tokenId } = props;
		const queryParams = {
			offset,
			limit: 1,
			page: 1,
			order: "asc",
			tokenId: tokenId,
		 }
		const nftHistoryParams = {...queryParams };
		const nftHistoryParamsF = removeEmpty(nftHistoryParams);
		fetchNftsHistoricalDetails(nftHistoryParamsF);
  	};

	const onHideKey = () => {
		setShowAdminKey(false);
		setShowFreezeKey(false);
		setShowSupplyKey(false);
		setShowWipeKey(false);
		setShowKycKey(false);
  	};

	  const onShowKey = (keyIndex) => {
		switch (keyIndex) {
			case 1:
				setShowAdminKey(true);
				setShowFreezeKey(false);
				setShowSupplyKey(false);
				setShowWipeKey(false);
				setShowKycKey(false);
				break;
			case 2:
				setShowFreezeKey(true);
				setShowAdminKey(false);
				setShowSupplyKey(false);
				setShowWipeKey(false);
				setShowKycKey(false);
				break;
			case 3:
				setShowSupplyKey(true);
				setShowAdminKey(false);
				setShowFreezeKey(false);
				setShowWipeKey(false);
				setShowKycKey(false);
				break;
			case 4:
				setShowWipeKey(true);
				setShowAdminKey(false);
				setShowFreezeKey(false);
				setShowSupplyKey(false);
				setShowKycKey(false);
				break;
			case 5:
				setShowKycKey(true);
				setShowAdminKey(false);
				setShowFreezeKey(false);
				setShowSupplyKey(false);
				setShowWipeKey(false);
				break;
			default:
				break;
		}
  	};

	const copyToClipboard = (keys) => {
		let textField = document.createElement('textarea')
		textField.innerText = JSON.stringify(keys);
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
	  };

	const HtsTokenKeys = () => {
		return (
			<div>
				{token && Object.keys(token).length ?
				<>
					<Grid relaxed="very" stackable>
						<Grid.Column width="16">
							<Table basic="very">
							<Table.Body>
								<Table.Row>
								<Table.Cell>
									<Item
									text="ADMIN KEY"
									sub={<Icon name="eye" onClick={() => {onShowKey(1)}} className="clickable" />}
									/>
								</Table.Cell>
								<Table.Cell>
									<Item
									text="FREEZE KEY"
									sub={<Icon name="eye" onClick={() => {onShowKey(2)}} className="clickable" />}
									/>
								</Table.Cell>
								<Table.Cell>
									<Item
									text="SUPPLY KEY"
									sub={<Icon name="eye" onClick={() => {onShowKey(3)}} className="clickable" />}
									/>
								</Table.Cell>
								<Table.Cell>
									<Item
									text="WIPE KEY"
									sub={<Icon name="eye" onClick={() => {onShowKey(4)}} className="clickable" />}
									/>
								</Table.Cell>
								<Table.Cell>
									<Item
									text="KYC KEY"
									sub={<Icon name="eye" onClick={() => {onShowKey(5)}} className="clickable" />}
									/>
								</Table.Cell>
								</Table.Row>
							</Table.Body>
							</Table>
						</Grid.Column>
					</Grid>

					{showAdminKey && <CodeSnippet code={token.adminKey} onClose={()=> {onHideKey();}} onCopy={()=> {copyToClipboard(token.adminKey);}} title="Admin Key" />}
					{showFreezeKey && <CodeSnippet code={token.freezeKey} onClose={()=> {onHideKey();}} onCopy={()=> {copyToClipboard(token.freezeKey);}} title="Freeze Key" />}
					{showSupplyKey && <CodeSnippet code={token.supplyKey} onClose={()=> {onHideKey();}} onCopy={()=> {copyToClipboard(token.supplyKey);}} title="Supply Key" />}
					{showWipeKey && <CodeSnippet code={token.wipeKey} onClose={()=> {onHideKey();}} onCopy={()=> {copyToClipboard(token.wipeKey);}} title="Wipe Key" />}
					{showKycKey && <CodeSnippet code={token.kycKey} onClose={()=> {onHideKey();}} onCopy={()=> {copyToClipboard(token.kycKey);}} title="Kyc Key" />}
				</>
				: ''}
			</div>
		)
	}

	const breadCrumbOptions = [
		{ title: 'NFT Explorer', link: '/nft-explorer', isActive: false },
		{ title: 'Token Details', link: `/tokens/${latestCollections.id}`, isActive: true },
	]
	const token = latestCollections;
	const customFees = token && token.customFees || [];
	const fixed_fees = token && token.customFees && token.customFees.length && token.customFees.find(fee => fee.fixed_fees);
	const royalty_fees = token && token.customFees && token.customFees.length && token.customFees.find(fee => fee.royalty_fees);
	return (
		<Page>
			<Helmet>
				<title>Dragon Glass - NFT Collection - {props.tokenId}</title>
			</Helmet>
			<CustomBreadcrumb options={breadCrumbOptions}/>
			<Container fluid style={styles.containerStyle} className="w-98">
				<Panel>
				{
					token && token.id ?
					<Container fluid className="w-98">
						<Grid stackable style={{padding: '2%'}}>
							<Grid.Row column={1} style={{fontWeight: 600, fontSize: '20px', borderBottom: '2px solid'}}>
								<Grid.Column width={12} style={{ color: '#black', display: 'flex', alignItems: 'end'}}>
									<div>{token.name || "None"}</div>
								</Grid.Column>
								<Grid.Column width={4} className={isMobile() ? "align-left" : "align-right"}>
									<div style={{ color: '#AEBAC6'}} >{token.tokenType || "None"}</div>
									<div style={{ fontSize: 'small', marginTop: '5px'}} >
									<Button primary content="Add NFT marketplace" size="tiny" href={`/nft-marketplace?token=${token.id}&nftOwner=${token.treasury}`} />
									{/* <Link to={`/nft-marketplace?token=${token.id}&nftOwner=${token.treasury}`} >Register a Market Place</Link> */}
									</div>
								</Grid.Column>
							</Grid.Row>

							<Grid.Row column={4} style={{fontSize: '16px'}}>
								<Grid.Column width={4}>
									<Grid>
										<Grid.Row column={1}>
											<Grid.Column width={6}>
												<h4>Overview</h4>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row column={2} className="p-0">
											<Grid.Column width={6}>Token ID:</Grid.Column>
											<Grid.Column width={10}>{token.id}</Grid.Column>
										</Grid.Row>
										<Grid.Row column={2} className="p-0">
											<Grid.Column width={6}>Symbol:</Grid.Column>
											<Grid.Column width={10}>{token.symbol || "None"}</Grid.Column>
										</Grid.Row>
										<Grid.Row column={2} className="p-0">
											<Grid.Column width={6}>Status:</Grid.Column>
											<Grid.Column width={10}>
												{token.deletedOn ? "Deleted" : "Active"}
											</Grid.Column>
										</Grid.Row>
										{
											token.tokenType	== 'FUNGIBLE_COMMON' ?
												<Grid.Row column={2} className="pt-0">
													<Grid.Column width={6}>Decimals:</Grid.Column>
													<Grid.Column width={10}>{token.decimal && (token.decimal || token.decimals || 0)}</Grid.Column>
												</Grid.Row>
											: null
										}

									</Grid>
								</Grid.Column>
								{
									token.tokenType	!= 'FUNGIBLE_COMMON' ?
									<>
									<Grid.Column width={4}>
										<Grid>
											<Grid.Row column={1}>
												<Grid.Column width={6}>
													<h4>Fixed Fees</h4>
												</Grid.Column>
											</Grid.Row>
											{
												fixed_fees && fixed_fees.fixed_fees ?
													<Grid.Row column={1} className="p-0">
														<Grid.Column width={16}><Money>{fixed_fees.fixed_fees.amount}</Money>  paid to {fixed_fees.collector_account_id}</Grid.Column>
													</Grid.Row>
												: <Grid.Row column={1} className="p-0">
													<Grid.Column width={16}>None</Grid.Column>
												</Grid.Row>
											}
										</Grid>
									</Grid.Column>
									<Grid.Column width={8}>
										<Grid>
											<Grid.Row column={1}>
												<Grid.Column width={6}>
													<h4>Royalty Fees</h4>
												</Grid.Column>
											</Grid.Row>
											{

												royalty_fees && royalty_fees.royalty_fees ?
													<Grid.Row column={1} className="p-0">
														<Grid.Column width={16}>
															<Number>{((royalty_fees.royalty_fees.exchange_value_fraction.numerator/royalty_fees.royalty_fees.exchange_value_fraction.denominator) * 100)}</Number>
																% paid to {royalty_fees.collector_account_id} Fallback <Money>{royalty_fees.royalty_fees.fallback_fee.amount}</Money>
														</Grid.Column>
													</Grid.Row>
												: <Grid.Row column={1} className="p-0">
													<Grid.Column width={16}>None</Grid.Column>
												</Grid.Row>
											}
										</Grid>
									</Grid.Column>
									</>
									: null
								}
							</Grid.Row>

							<Grid.Row style={{fontSize: '12px', borderBottom: showMoreDetails ? '2px solid #AEBAC6' : '2px solid'}}>
								<Grid.Column style={{textAlign: 'left', paddingRight: '5%'}}>
									<Link>
										<span className="bold clickable" onClick={() => setShowMoreDetails(!showMoreDetails)}>
										{ !showMoreDetails ? "Show" : "Hide" } More Details...
										</span>
									</Link>
								</Grid.Column>
							</Grid.Row>

							{
								showMoreDetails ?
								<>
									<Grid.Row column={4} style={{fontSize: '16px', borderBottom: '2px solid #AEBAC6'}}>
										<Grid.Column width={4}>
											<Grid>
												<Grid.Row column={1}>
													<Grid.Column width={6}>
														<h4>More Details</h4>
													</Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="p-0">
													<Grid.Column width={6}>Supply:</Grid.Column>
													<Grid.Column width={10}><Number>{adjustWithDecimals(token.supply, token.decimal || token.decimals)}</Number></Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="p-0">
													<Grid.Column width={6}>Supply Type:</Grid.Column>
													<Grid.Column width={10}>{token.supplyType}</Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="p-0">
													<Grid.Column width={6}>Max Supply:</Grid.Column>
													<Grid.Column width={10}>
													{
														(token.maxSupply && token.supplyType == 'FINITE') ?  
														<Number>{token.maxSupply}</Number>
														: 'INFINITE'
													}
													</Grid.Column>
												</Grid.Row>
												{/* <Grid.Row column={2} className="pt-0">
													<Grid.Column width={6}>Decimals:</Grid.Column>
													<Grid.Column width={10}>{token.decimal || token.decimals || 0}</Grid.Column>
												</Grid.Row> */}
											</Grid>
										</Grid.Column>

										<Grid.Column width={4}>
											<Grid>
												<Grid.Row column={1}>
													<Grid.Column width={6}>
														<h4> &nbsp; </h4>
													</Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="p-0">
													<Grid.Column width={6}>Treasury:</Grid.Column>
													<Grid.Column width={10}>
														{
															token.treasury ?
															<Link to={`/accounts/${token.treasury}`}>{token.treasury}</Link>
															: "None"
														}
													</Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="p-0">
													<Grid.Column width={6}>Creator:</Grid.Column>
													<Grid.Column width={10}>
													{
														nftCreatorDetails.sender ?
														<Link to={`/accounts/${nftCreatorDetails.sender}`}>{nftCreatorDetails.sender}</Link>
														: "None"
													}
													</Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="pt-0">
													<Grid.Column width={6}>Auto Renew Account:</Grid.Column>
													<Grid.Column width={10}>
														{
															token.autoRenewAccount ?
															<Link to={`/accounts/${token.autoRenewAccount}`}>{token.autoRenewAccount}</Link>
															: "None"
														}
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</Grid.Column>
										
										<Grid.Column width={8}>
											<Grid>
												<Grid.Row column={1}>
													<Grid.Column width={6}>
														<h4> &nbsp; </h4>
													</Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="p-0">
													<Grid.Column width={4}>Created:</Grid.Column>
													<Grid.Column width={12}>{localDateTime(token.createdOn)}</Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="p-0">
													<Grid.Column width={4}>Expiry:</Grid.Column>
													<Grid.Column width={12}>{localDateTime(token.expiry)}</Grid.Column>
												</Grid.Row>
												<Grid.Row column={2} className="p-0">
													<Grid.Column width={4}>Memo:</Grid.Column>
													<Grid.Column width={12} style={{
														wordWrap: 'break-word'
													}}>
														{token.memo || "None"}
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</Grid.Column>
									</Grid.Row>

									<Grid.Row column={1} style={{fontSize: '16px', borderBottom: '2px solid'}}>
										<Grid.Column width={16}>
											<h4>Keys</h4>
											{HtsTokenKeys()}
										</Grid.Column>
									</Grid.Row>
								</>
								: null
							}


							<Grid.Row column={1}>
							<Container fluid>
								<HederaTokenDetailTabs />
							</Container>
							</Grid.Row>
						</Grid>
					</Container>
					: <div className='bold align-center'>No Data Found</div>

				}
				</Panel>
			</Container>
		</Page>
	);
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
	tokenId,
	nftHistoricalData : getNftsHistoricalDetail(state),
	nftsData: getSearchNFTsData(state),
	tokenDetails: getTokenDetails(state),
});

export default connect(mapStateToProps, actions)(NftCollectionDetailsPage);