import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Stat from '../shared/Stat';
import * as actions from '../../redux/actions';
import { getContractUsersCount } from '../../redux/reducers';

class ContractTxnVolumeChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchContractUsersCount, id, period } = this.props;
		fetchContractUsersCount(id, period);
	}
	render() {
		const { usersCount } = this.props;
		if (usersCount || usersCount === 0) {
			return (
				<Fragment>
					<Stat count={usersCount} />
				</Fragment>
			);
		} else {
			return <div>No data available for the selected period</div>;
		}
	}
}

const mapStateToProps = (state, { match: { params: { contractId } } }) => ({
	id: contractId,
	usersCount: getContractUsersCount(state, contractId)
});

ContractTxnVolumeChart = withRouter(connect(mapStateToProps, actions)(ContractTxnVolumeChart));

export default ContractTxnVolumeChart;
