import React, { isValidElement } from 'react';
import { Button, Form, Message, Icon } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';

export default function AbiUploadForm({ 
        // name, 
        // email, 
        // company, 
        // title, 
        // description, 
        // accountId, 
        // contractId, 
        // website, 
        // androidAppLink, 
		// iosAppLink,
		// deployed,
        onChange, 
        onSubmit, 
        errors = {}, 
		loading,
		onDrop,
		acceptedFiles,
		onUpload,
		abiFileName,
		setAbiFileName,
		sourceCodeFileName,
		setSourceCodeFileName,
		byteCodeFileName,
		setByteCodeFileName,
		logoFileName,
		setLogoFileName
    }) {

	// const isError = Object.keys(errors).length !== 0;
	
	// let files = acceptedFiles.map(file => `${file[0].path}<br/>`)

	const fileInputRef = React.createRef();
	// const fileInputRef2 = React.createRef();
	// const fileInputRef3 = React.createRef();
	// const fileInputRef4 = React.createRef();

	const fileChange = e => {
		setAbiFileName(e.target.files[0].name)
	  };
    
	return (
		<Form size="large" /*onSubmit={e => {onSubmit(e); onUpload(e)}} error={isError}*/>
			<Message error content={errors.non_field_errors} />
			
			  <Form.Field>
                <Button
				style={{width: "260px", textAlign: "left"}}
                  content="Choose Abi File"
                  labelPosition="left"
                  icon="file"
                  onClick={() => fileInputRef.current.click()}
                />&nbsp;&nbsp;
				<span>{abiFileName}</span>
                <input
                  ref={fileInputRef}
                  type="file"
                  hidden
                  onChange={e => { setAbiFileName(e.target.files[0].name); onDrop({abiFile: e.target.files[0]}) }}
                />
              </Form.Field>

			<Button onClick={e => {onSubmit(e); /*onUpload(e)*/}} primary={true} fluid loading={loading} style={{width: "14%", float: "right", marginBottom: "20px"}}>
				SUBMIT
			</Button>
			{/* <Message error content={errors.auth} /> */}
		</Form>
	);
}
