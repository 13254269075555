import React from 'react';
import { Player } from 'video-react';
//import '../../../node_modules/video-react/dist/video-react.css'; // import css
require('video-react/dist/video-react.css');

const Video = ({ src }) => {
	return <Player playsInline autoPlay src={src} />;
};

export default Video;
