import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Table, Icon } from 'semantic-ui-react';
import CodeSnippet from '../components/shared/CodeSnippet';
import * as actions from '../redux/actions';
import { getAccount } from '../redux/reducers';
import { getTokenBalances } from '../redux/reducers/hts';
import { Card } from '../atoms/Card';
import Money from '../atoms/Money';
import { fromDate } from '../utils';


const AccountSummary = (props) => {
	const { id, account, tokenBalances, fetchAccount, fetchTokenBalances, fetchAccountBalance, fetchAccountRecentTransactionsCount, handleTab } = props;
  	const [showKey, setShowKey] = useState(false);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () => {
    fetchAccountBalance(id);
    fetchAccountRecentTransactionsCount(id);
    fetchAccount(id);
	fetchTokenBalances({ accountId: id, limit: 5});
  };

  const onShowKey = () => {
    setShowKey(true);
  };

  const onHideKey = () => {
    setShowKey(false);
  };

  useEffect(() => {
    if (account && account.isNodeAccount) {
      handleClick(true);
    }
  }, [account]);

  const handleClick = (value) => {
    // Handle click logic
  };

  const renderTokenTable = () => {
	let tokenData = tokenBalances;
	const data = tokenData && tokenData.length && tokenData.slice(0, 4);
	// const data = [
	// 	{tokenId: 'dfsdf23', balance: 24762384723},
	// 	{tokenId: 'dfsdf23', balance: 24762384723},
	// 	{tokenId: 'dfsdf23', balance: 24762384723},
	// 	{tokenId: 'dfsdf23', balance: 24762384723},
	// 	{tokenId: 'dfsdf23', balance: 24762384723},
	// ]
    return (
		<Table basic="very" unstackable>
		<Table.Body>
				{
					data.length
					? data.map(dt => {
						return (
							<Fragment>
								<Table.Row> 
									<Table.Cell>{dt.tokenId}</Table.Cell>
									<Table.Cell>{dt.balance}</Table.Cell>
								</Table.Row>
							</Fragment>
									
						)
					})
					: <p>No token Balance for this Account </p>
				}
		</Table.Body>
	</Table>
    );
  };

  const renderKeysTable = () => {
    return (
		<Table basic="very" unstackable>
		<Table.Body>
			<Table.Row> 
				<Table.Cell>Public Key</Table.Cell>
				<Table.Cell className="cell-value" style={{fontWeight: 700}}><Icon name="eye" onClick={onShowKey} className="clickable" /></Table.Cell>
			</Table.Row>
		</Table.Body>
	</Table>
    );
  };

  const renderStakingTable = (account) => {
    return (
		<Table basic="very" unstackable>
			<Table.Body>
				{
					account && account.stakedNodeId
					?	<Table.Row>
							<Table.Cell>Staked Node Id</Table.Cell>
							<Table.Cell style={{fontWeight: 700}}>{account.stakedNodeId}</Table.Cell>
						</Table.Row> 
					: null
				}
				{
					account && account.stakedAccountId
					?	<Table.Row>
							<Table.Cell>Staked Account Id</Table.Cell>
							<Table.Cell style={{fontWeight: 700}}>{account.stakedAccountId && account.stakedAccountId !== '0.0.0' ? account.stakedAccountId : ''}</Table.Cell>
						</Table.Row>
					: null
				}
				{
					account && account.stakeIdStartPeriod
					?	<Table.Row>
							<Table.Cell>Stake Start Period</Table.Cell>
							<Table.Cell style={{fontWeight: 700}}>{account.stakeIdStartPeriod ? fromDate(account.stakeIdStartPeriod) : ''}</Table.Cell>
						</Table.Row>
					: null
				}
				{
					account && account.stake
					?	<Table.Row>
							<Table.Cell>Stake</Table.Cell>
							<Table.Cell style={{fontWeight: 700}}><Money>{account.stake || 0}</Money></Table.Cell>
						</Table.Row>
					: null
				}
				{
					account && account.stake_rewarded
					?	<Table.Row>
							<Table.Cell>Stake Rewarded</Table.Cell>
							<Table.Cell style={{fontWeight: 700}}><Money>{account.stake_rewarded || 0}</Money></Table.Cell>
						</Table.Row> 
					: null
				}
				{
					account && account.stake_not_rewarded
					?	<Table.Row>
							<Table.Cell>Stake Not Rewarded</Table.Cell>
							<Table.Cell style={{fontWeight: 700}}><Money>{account.stake_not_rewarded || 0}</Money></Table.Cell>
						</Table.Row> 
					: null
				}
				{
					account && account.min_stake
					?	<Table.Row>
							<Table.Cell>Min Stake</Table.Cell>
							<Table.Cell style={{fontWeight: 700}}><Money>{account.min_stake || 0}</Money></Table.Cell>
						</Table.Row>
					: null
				}
				{
					account && account.max_stake
					?	<Table.Row>
							<Table.Cell>Max Stake</Table.Cell>
							<Table.Cell style={{fontWeight: 700}}><Money>{account.max_stake || 0}</Money></Table.Cell>
						</Table.Row>
					: null
				}
			</Table.Body>
	</Table>
    );
  };

  return (
    <Grid stackable columns={2}>
      <Grid.Row stretched style={{ alignContent: 'center' }}>
        <Grid.Column width={5}>
          <Card title="Token" children={renderTokenTable()} footer={<div style={{textDecoration: 'underline'}} onClick={handleTab}>See All</div>}/>
        </Grid.Column>
        <Grid.Column width={5}>
          <Card title="Keys" children={renderKeysTable()}/>
        </Grid.Column>
        <Grid.Column width={6}>
          <Card title="Staking" children={renderStakingTable(account)}/>
        </Grid.Column>
      </Grid.Row>
	  <div>
		{account && account.publicKey &&
			showKey && <CodeSnippet code={account.publicKey} onClose={onHideKey} title="Public Key(s)" />}
	  </div>
    </Grid>
  );
};

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
  id: accountId,
  account: getAccount(state, accountId),
  tokenBalances: getTokenBalances(state)
});

export default withRouter(connect(mapStateToProps, actions)(AccountSummary));

