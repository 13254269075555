import React, { Component } from 'react';
import { getTokenBalances } from '../redux/reducers/hts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../redux/actions';
import HederaTokenBalanceTable from './HederaTokenBalanceTable';

class HederaTokenBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 5,
      sort: '',
	    order: 'desc',
      page: 1
    };
  }


  componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
  }

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		const { fetchTokenBalances, id } = this.props;
		const params = { accountId: id, ...this.state };
		fetchTokenBalances(params);
  };

  getPaginatedTokenBalances = (tokenBalances, offset, limit) => {
    let paginatedBalances = [];
    if (tokenBalances && tokenBalances.length) {
      console.log('offset = ' + offset);
      console.log('limit = ' + limit);
      paginatedBalances = tokenBalances.splice(offset, limit);
      console.log(paginatedBalances);
    }
    return paginatedBalances;
  };

	render() {
   const { tokenBalances, token } = this.props;
   const { limit, offset } = this.state;
   const totalCount = (tokenBalances && tokenBalances.length) ? tokenBalances.length : 0;
   const paginatedTokenBalances = this.getPaginatedTokenBalances([...tokenBalances], offset, limit);

		return (
      <div id="token-transactions-table">
        <HederaTokenBalanceTable
          token={token}
          tokenBalances={paginatedTokenBalances}
          limit={limit}
          offset={offset}
          sort={this.state.sort}
          order={this.state.order}
          totalCount={totalCount}
          loadData={this.loadData}
        />
      </div>
    );
	}
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
  id: accountId,
  tokenBalances: getTokenBalances(state)
});

export default withRouter(connect(mapStateToProps, actions)(HederaTokenBalance));