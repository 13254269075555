import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuthed } from '../redux/reducers';
import Nav from '../components/containers/Nav';
import Footer from '../components/Footer';

const PrivateRoute = ({ isAuthed, component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			return isAuthed ? (
				<React.Fragment>
					<div style={{position: "sticky", top: "0px", zIndex: "10"}} >
						<Nav />
					</div>
					<div>
						<Component {...props} />
					</div>
					<div style={{marginTop: "20px"}}>
						<Footer />
					</div>
				</React.Fragment>
			) : (
				<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
			);
		}}
	/>
);

PrivateRoute.propTypes = {
	component: PropTypes.func.isRequired,
	isAuthed: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		isAuthed: isAuthed(state)
	};
}

export default connect(mapStateToProps)(PrivateRoute);
