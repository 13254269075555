import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import TransactionRow from './TransactionRow';
import TransactionTableHeader from './TransactionTableHeader';

const TransactionTable = ({ transactions = [], limit, offset, sort, order, totalCount, loadData, showDetails }) => {
	const transactionRows = transactions.map((transaction, index) => (
		<TransactionRow key={index} transaction={transaction} showDetails={showDetails} />
	));
	return (
		<div className="transaction-table">
			<DataTable
				limit={limit}
				offset={offset}
				sort={sort}
				order={order}
				totalCount={totalCount}
				header={TransactionTableHeader}
				rows={transactionRows}
				loadData={loadData}
		/>
		</div>
	);
};

export default TransactionTable;

TransactionTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	showDetails: PropTypes.func.isRequired,
	loadData: PropTypes.func.isRequired
};
