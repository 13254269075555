import React from 'react';
import Panel from './shared/Panel';
import DappAccountFiles from './containers/DappAccountFiles';
import Info from './shared/Info';

export default (props) => (
	<Panel
		title={
			<React.Fragment>
				FILES<Info id="files" />
			</React.Fragment>
		}
		titleClass="light"
		description=""
	>
		<DappAccountFiles accountNumber={props.accountNumber} />
	</Panel>
);
