import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PieChart from "../shared/PieChart";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { getTransactionsPieChart } from "../../redux/reducers";
import { type } from "@amcharts/amcharts4/core";
import LoadingIndicator from "./LoadingIndicator";

class HtsTxnTypeChart extends Component {
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.period !== prevProps.period) {
            this.fetchData();
        }
    }

    fetchData() {
        const { fetchTransactionsPieChart, period } = this.props;
        console.log("PERIOD IN TXNTYPE CHART", period);
        fetchTransactionsPieChart(period);
    }

    handleSegmentClick = type => {
        console.log("PERIOD IN PIE CHART: ", this.props.period);
        // this.props.history.push(`/search?transactionTypes=${type}&period=${this.props.period}`);
        this.props.history.push(`/search?serviceTypes=TOKEN&period=${this.props.period}`);
    };

    render() {
        const { htsData: { entries } } = this.props;
        if (!this.props.htsData.loading) {
            return (
                entries && entries.length ?
                    <PieChart
                        data={entries}
                        period={this.props.period}
                        legendHeight={175}
                        customHeight={400}
                        // handleClick={this.handleSegmentClick} //bug: click txn type
                        requestType="TOKEN"
                        hideClickEvent={true} hideLink={true} hidePercentage={true}
                    />
                :   <div>No transactions available for the selected period</div>
            );
        } else {
            return  <LoadingIndicator />
        }
    }
}

const mapStateToProps = state => ({
    htsData: getTransactionsPieChart(state),
});

HtsTxnTypeChart = connect(mapStateToProps, actions)(HtsTxnTypeChart);

export default withRouter(HtsTxnTypeChart);
