import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Radio, Divider } from 'semantic-ui-react';
import Panel from './shared/Panel';
import APIButton from './shared/APIButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as actions from '../redux/actions';
import { getParameters, getAllApis } from '../redux/reducers';
import ApiParameterTable from './ApiParameterTable';
import ApiCopyUrl from './ApiCopyUrl';
import CodeSnippet from './shared/CodeSnippet';
import axios from 'axios';

import '../animate.css';

const APISingleDisplayNew = (props) => {

    useEffect(() => { window.scrollTo(0, 0) }, []);

    const handleCopyClick = () => {
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000)
    }

    // const handleTryClick = (url) => {
    //     console.log('URL IN TRY CLICK: ', url)
    //     axios.get(url).then(
    //         (response) => {
    //             console.log("RESPONSE IN TRY: ", response);
    //         }
    //     )
    // }

    const audio = new Audio('/click.mp3');

    const [ clicked, setClicked ] = useState(false);

    const [showParameters, setShowParameters] = useState(false);

    const handleOnClick = (e, {checked}) => {
        setShowParameters(checked);
    } 

    // const buttonText = showParameters ? 'Hide parameters' : 'Show parameters';
    // const buttonIcon = showParameters ? <Icon name="arrow up" /> : <Icon name="arrow down" />
    const toggleLabel = showParameters ? 'HIDE PARAMETERS' : 'SHOW PARAMETERS';
    const parametersFiltered = props.parameters.filter(parameter => parameter.api === props.api.id);

    console.log('FILTERED PARAMS: ', parametersFiltered)

    console.log("PROPS IN SINGLE VIEW NEW: ", props)

    const note1 = `Note: Your Access Key should be added to the request header "X-API-KEY,"`
    const note2 = `e.g. in JavaScript request: `
    const snippet = `headers: {'X-API-KEY': 'YOUR_ACCESS_KEY'}`

    return (

        <Panel
            title={
                <Fragment>
                    {props.api.title}
                    {/* <div style={{marginTop: "20px", fontSize: "70%"}}><span className="text-red">* </span>{note1}<br/>&nbsp;&nbsp;&nbsp;{note2}</div>
                    <CodeSnippet style={{padding: '0 0 0 10px', marginBottom: '-15px'}}>{snippet}</CodeSnippet> */}
                </Fragment>
            }
            description=""
            >
            {/* {props.api.description && <Divider />} */}
            {props.api.description}
            <Divider />

            <div style={{display: "flex", alignItems: "center"}}>
                <span style={{marginRight: "10px"}}><APIButton /></span>
                <span className="text-black bold" style={{width: "80%", fontSize: "95%", overflowWrap: "anywhere"}}>{props.api.url}</span>
                <CopyToClipboard text={props.api.url}
                    onCopy={() => handleCopyClick({copied: true})}>
                    <span style={{marginLeft: "1%"}}><ApiCopyUrl clicked={clicked} /></span>
                </CopyToClipboard>
                <span style={{marginLeft: "1%"}}>
                    <Button style={{paddingRight: "0px"}} primary className="try-it-button" onClick={(e) => props.handleTryClick(props.api.sample_url)}>
                        <Icon name="compass outline" size="large" style={{position: "relative", left: "-5px"}} />
                        <span style={{position: "relative", left: "-8px", top: "1px", fontSize: "110%"}}>TRY IT</span>
                    </Button>
                </span>
            </div>

            <Divider />

            {parametersFiltered.length > 0 && <div style={{fontSize: "120%", marginBottom: "20px"}}>Query Params</div>}

            {parametersFiltered.length > 0 && <div className="parameter-table animated fadeIn"><ApiParameterTable parameters={parametersFiltered} /></div>}

            <div>
                {/* { apisToDisplay } */}
            </div>
        </Panel>

        // <div className="animated fadeIn">
        //     <div className="shortDescription"><strong>{ props.shortDescription }</strong></div>
        //     <div className="longDescription">{ props.longDescription }</div>
        //     <div className="urlBox">
        //         <a href={props.sample_url} target="_blank" rel="noopener noreferrer">{ props.url }</a>
        //         <CopyToClipboard text={props.url}
        //             onCopy={() => handleCopyClick({copied: true})}>
        //             <span><ApiCopyUrl clicked={clicked} /></span>
        //         </CopyToClipboard>
        //     </div>
        //     {parametersFiltered.length > 0 && <Radio toggle label={toggleLabel} className="header parameters-toggle" checked={showParameters} onChange={handleOnClick} />}
        //     {/* {parametersFiltered.length > 0 && <div className="parameters-button"><Button primary onClick={handleOnClick}>{buttonIcon}{buttonText}</Button></div>} */}
        //     {parametersFiltered.length > 0 && showParameters && <div className="parameter-table animated fadeIn"><ApiParameterTable parameters={parametersFiltered} /></div>}
        // </div>
    )
}

const mapStateToProps = (state) => ({
    parameters: getParameters(state),
    apis: getAllApis(state)
  });

export default connect(mapStateToProps, actions)(APISingleDisplayNew);
