import { FETCH_STATS_REQUEST, FETCH_STATS_SUCCESS, FETCH_STATS_FAILURE } from '../types';
import { combineReducers } from 'redux';

const initialState = {
	startDate: '',
	numTransactions: 0,
	numAccounts: 0
};

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_STATS_REQUEST:
			return true;
		case FETCH_STATS_SUCCESS:
		case FETCH_STATS_FAILURE:
			return false;
		default:
			return state;
	}
};

function counts(state = initialState, action) {
	switch (action.type) {
		case FETCH_STATS_SUCCESS:
			return { ...action.payload };
		default:
			return state;
	}
}

const stats = combineReducers({
	isFetching,
	counts
});

export default stats;

export const getStats = (state) => state.counts;
export const getIsFetchingStats = (state) => state.isFetching;
