import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import DappTokenTransferRow from './DappTokenTransferRow';
import DappTokenTransferTableHeader from './DappTokenTransferTableHeader';

const DappTokenTransferTable = ({ tokenTransfers, limit, offset, sort, order, totalCount, loadData, showDetails }) => {
    // console.log("TOKEN TRANSFERS= ", tokenTransfers)
	const tokenTransferRows = tokenTransfers.map((tokenTransfer, index) => (
		<DappTokenTransferRow key={index} tokenTransfer={tokenTransfer} showDetails={showDetails} />
    ));
	return (
		<DataTable
			limit={limit}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={DappTokenTransferTableHeader}
			rows={tokenTransferRows}
			loadData={loadData}
		/>
	);
};

export default DappTokenTransferTable;

DappTokenTransferTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired,
	showDetails: PropTypes.func.isRequired
};
