import React, { Fragment } from 'react';
import Panel from './shared/Panel';
import SampleResponseCodeSnippet from './shared/SampleResponseCodeSnippet';
import { Divider } from 'semantic-ui-react';

export const APISampleResponse = (props) => {
    return (
        <Panel
      title={
        <Fragment>SAMPLE RESPONSE
          {/* {props.title}
          <div style={{marginTop: "20px", fontSize: "70%"}}><span className="text-red">* </span>{note1}<br/>&nbsp;&nbsp;&nbsp;{note2}</div>
          <CodeSnippet style={{padding: '0 0 0 10px', marginBottom: '-15px'}}>{snippet}</CodeSnippet> */}
        </Fragment>
      }
      description=""
    >
      <Divider />
      <div style={{overflowWrap: "anywhere"}}>
        {/* { apisToDisplay } */}
        {/* {JSON.stringify(props.data)} */}
        {props.data && <SampleResponseCodeSnippet style={{width: "100%", maxHeight: '300px', overflow: "auto"}}>
          <div style={{overflowWrap: "anywhere", fontSize: "90%"}}>
              {JSON.stringify(props.data, null,  2)}
          </div>
        </SampleResponseCodeSnippet>}
      </div>
    </Panel>
    )
}

export default APISampleResponse;
