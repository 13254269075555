import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import DateCell from './shared/DateCell';
import Money from './shared/Money';
import Transfers from './containers/Transfers';
import eye from '../assets/images/eye@2x.png';
import { isMobile } from '../utils';

const color = (direction) => (direction.toLowerCase() === 'credit' ? 'green' : 'red');
const TransferRow = ({ transfer, showDetails }) => {
	const uniqueAccounts = [ ...new Set(transfer.toFromAccount) ].slice(0, 2);
	const complexComp = (
		<span>
			{uniqueAccounts.map((item, index) => {
				const to = `/accounts/${item}`;
				return (
					<span key={index}>
						{!!index && ' | '}
						<Link className="item" to={to}>
							{item}
						</Link>
					</span>
				);
			})}
		</span>
	);

	const redirectTo = (id) => {
		return window.location.href =`/transactions/${id}`;
	};

	return (
		<Table.Row className="clickable hoverRow" onClick={()=> redirectTo(transfer.transactionID)}>
			<Table.Cell collapsing>
				<span className={`text-${color(transfer.transactionDirection)} bold`}>
					{transfer.transactionDirection.toUpperCase()}
				</span>
			</Table.Cell>
			<Table.Cell collapsing>
				<DateCell date={transfer.consensusTime} />
			</Table.Cell>
			<Table.Cell collapsing>
				<Transfers txId={transfer.transactionID} /> {complexComp}...
			</Table.Cell>
			<Table.Cell textAlign="right" collapsing>
				<Money>{transfer.amount}</Money>
			</Table.Cell>
			<Table.Cell
			style={{
				width: isMobile() ? '200px' : '50px', 
				maxWidth: isMobile() ? '200px' : '50px',
				overflow: 'hidden',
				textOverflow: 'ellipsis', 
				whiteSpace: 'nowrap',
			}}
			>
				<Popup
					trigger={<span>{transfer.memo} </span>}
					content={transfer.memo}
					position="top center"
					hoverable
				/>
			</Table.Cell>
			{/* <Table.Cell textAlign="center" collapsing>
				<Link to={`/transactions/${transfer.transactionID}`}>
					<Popup
						content="View Transaction Details"
						trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
					/>
				</Link>
			</Table.Cell> */}
		</Table.Row>
	);
};

export default TransferRow;

TransferRow.propTypes = {
	transfer: PropTypes.object.isRequired
};
