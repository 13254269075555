import React from 'react';
import PropTypes from 'prop-types';
import { Form, Popup } from 'semantic-ui-react';

//const regex = new RegExp('^[a-zA-Z0-9 ]+$');

export default class Filter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: '',
			filterValid: true
		};
	}

	handleOnChange = (event, { name, value }) => {
		/*
		if (!value || !value.trim().length) {
			this.setState({ [name]: value, filterValid: false });
		} else {
			this.setState({ [name]: value, filterValid: true });
			this.props.onSubmitFilter(value);
		}
		*/

		this.setState({ [name]: value, filterValid: true });
		this.props.onSubmitFilter(value);
	};

	render() {
		const { filter } = this.state;
		let popupMessage = '';
		if (!this.state.filterValid) {
			popupMessage = 'Invalid character.';
		} else if (this.props.totalCount === 0) {
			popupMessage = 'No results found.';
		}

		return (
			<Popup
				trigger={
					<Form.Input
						inline
						placeholder="Enter the filter."
						name="filter"
						value={filter}
						error={!this.state.filterValid}
						onChange={this.handleOnChange}
						icon="search"
						loading={this.props.loading}
					/>
				}
				content={popupMessage}
				on="click"
				open={!this.state.filterValid || this.props.totalCount === 0}
				position="right center"
			/>
		);
	}
}

Filter.propTypes = {
	onSubmitFilter: PropTypes.func.isRequired
};
