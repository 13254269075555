import { HBAR_SYMBOL_SELECTED, TBAR_SYMBOL_SELECTED, USD_SYMBOL_SELECTED } from '../types';
import Cookies from 'universal-cookie';

export default function symbol(state = "tℏ", action) {
	const cookies = new Cookies();
	const currencySymbol = decodeURIComponent(cookies.get("selectedSymbol"))
	switch (action.type) {
		case HBAR_SYMBOL_SELECTED:
			return currencySymbol || "ℏ";
		case TBAR_SYMBOL_SELECTED:
			return currencySymbol || "tℏ";
        case USD_SYMBOL_SELECTED:
            return "usd";
		default:
			return cookies.get("selectedSymbol") ? currencySymbol : state;
	}
}

export const getSymbol = (state) => {
	const cookies = new Cookies();
	const currencySymbol = decodeURIComponent(cookies.get("selectedSymbol"))
	return currencySymbol || state;
};