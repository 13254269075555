import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import TokenTransferRow from './TokenTransferRow';
import TokenTransferTableHeader from './TokenTransferTableHeader';
// import TokenTransferAccountTableHeader from './TokenTransferAccountTableHeader';

const TokenTransferTable = ({ transfers = [], token = {}, size, offset, sort, order, totalCount, loadData, showDetails, showArrow, isNoPagination }) => {
	const transferRows = transfers.map((transfer, index) => (
		<TokenTransferRow key={index} transfer={transfer} showDetails={showDetails} token={token} showArrow={showArrow} isNoPagination={isNoPagination}/>
	));
	return (
		<div className="token-transfer-table">
			<DataTable
				limit={size}
				offset={offset}
				sort={sort}
				order={order}
				totalCount={totalCount}
				header={TokenTransferTableHeader}
				rows={transferRows}
				loadData={loadData}
				isNoPagination ={isNoPagination}
				showArrow={showArrow}
			/>
		</div>
	);
};

export default TokenTransferTable;

TokenTransferTable.propTypes = {
	token: PropTypes.object.isRequired,
	totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired,
	showDetails: PropTypes.func.isRequired
};
