import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = {
	'1H': '1 Hour',
	'1D': '1 Day',
	'1W': '1 Week',
	'1M': '1 Month',
	'1Y': '1 Year',
	'OA': 'Since OA'
};
export default function PeriodSelector({ value, onSelect, ...rest }) {
	return (
		<Dropdown {...rest} item direction="left" text={value}>
			<Dropdown.Menu>
				{Object.entries(options).map(([ key, val ]) => (
					<Dropdown.Item
						disabled={value === key}
						key={key}
						value={key}
						onClick={(e, data) => onSelect(e, data)}
					>
						{val}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}
