import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import Money from './shared/Money';
import DateCell from './shared/DateCell';
import eye from '../assets/images/eye@2x.png';

const TopicMessageRow = ({ message, showDetails }) => {

    const onMessageClick = (number) => {
        console.log("MESSAGE CLICKED: ", number)
    }

    return (
	<Table.Row>
            <Table.Cell style={{ paddingLeft: "18px" }} collapsing width={1}>{message.topicSequenceNumber}</Table.Cell>

            <Table.Cell style={{paddingLeft: "18px"}} collapsing>
                {/* <DateCell date={transaction.consensusTime} /> */}{message.submitter}
            </Table.Cell>

            {/* <Table.Cell>
                <DateCell date={message.consensusTime} />
            </Table.Cell> */}

            {/* <Table.Cell collapsing textAlign="right">
                <Money>{transaction.amount}</Money>
            </Table.Cell>
            <Table.Cell collapsing textAlign="left" style={{color: transaction.status === "SUCCESS" ? "#09a309" : "red", fontWeight: "500"}}>
                {transaction.status}
            </Table.Cell>
            <Table.Cell style={{width: '90%', maxWidth: '90%', wordWrap: 'break-word', wordBreak: 'break-word'}}>
                {transaction.memo}
            </Table.Cell> */}

            <Table.Cell textAlign="center" collapsing>
                <div style={{marginLeft: "40%"}}>
                <Link to={`/transactions/${message.transactionID}`}>
                    <Popup
                        content="View Message Details"
                        trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} onClick={(e) => onMessageClick(message.topicSequenceNumber)} />}
                    />
                </Link>
                </div>
            </Table.Cell>
        </Table.Row>
    );
}

export default TopicMessageRow;

TopicMessageRow.propTypes = {
	transaction: PropTypes.object.isRequired,
	showDetails: PropTypes.func.isRequired
};
