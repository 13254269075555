import React, { Component } from 'react';
import Panel from './shared/Panel';
//import Filter from './shared/Filter';
import ContractTransfers from './containers/ContractTransfers';
import Info from './shared/Info';

export default class ContractTransfersPanel extends Component {
	state = {
		q: ''
	};

	onSubmitFilter = (value) => {
		this.setState({ q: value });
	};

	render() {
		const { q } = this.state;
		return (
			<Panel
				title={
					<React.Fragment>
						PAYMENTS AND RECEIPTS <Info id="transfers" />
					</React.Fragment>
				}
				description=""
				// toolbar={
				// 	<React.Fragment>
				// 		<Filter onSubmitFilter={this.onSubmitFilter} value={q} />
				// 	</React.Fragment>
				// }
			>
				<ContractTransfers filter={q} />
			</Panel>
		);
	}
}
