import React, { Component } from 'react';
import { getTokenDetails, getTokenTransations, getTokenTransactionsCount } from '../redux/reducers/hts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../redux/actions';
import TransactionTable from './TransactionTable';
// import HederaTokenTransactionTable from './HederaTokenTransactionTable';

class HederaTokenTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 5,
      sort: 'consensusTime',
	    order: 'desc',
      page: 1
    };
  }


  componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
  }

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		const { fetchTokenTransactions, id } = this.props;
		const params = { tokenId: id, ...this.state };
		fetchTokenTransactions(params);
  };

  showTransactionDetails = (transaction) => this.props.history.push(`/transactions/${transaction.transactionID}`);

	render() {
   const { tokenTransactions, totalCount, token } = this.props;
   const { limit, offset } = this.state;

		return (
      <TransactionTable
        transactions={tokenTransactions}
        limit={limit}
        offset={offset}
        sort={this.state.sort}
        order={this.state.order}
        totalCount={totalCount}
        showDetails={this.showTransactionDetails}
        loadData={this.loadData}
      />
    );
	}
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
  id: tokenId,
  tokenTransactions: getTokenTransations(state),
  totalCount: getTokenTransactionsCount(state)
});

export default withRouter(connect(mapStateToProps, actions)(HederaTokenTransactions));