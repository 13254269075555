import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import Panel from './shared/Panel';
import CodeSnippet from './shared/CodeSnippet';
import { Divider } from 'semantic-ui-react';
import { getAllApis } from '../redux/reducers';
import { getCategories } from '../redux/reducers';
import ApiSingleDisplay from './APISingleDisplay';

const APIPresentationPanel = (props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    props.fetchApiList();
    props.fetchApiCategories();
  }

  const apisFiltered = props.apis.filter(api => api.category === props.id);

  const note1 = `Note: Your Access Key should be added to the request header "X-API-KEY,"`
  const note2 = `e.g. in JavaScript request: `
  const snippet = `headers: {'X-API-KEY': 'YOUR_ACCESS_KEY'}`

  const apisToDisplay = apisFiltered.map(api => (
    <ApiSingleDisplay key={api.id} apiId={api.id} url={api.url} shortDescription={api.title} longDescription={api.description} sample_url={api.sample_url}/>
  ))

  return (
    <Panel
      title={
        <Fragment>
          {props.title}
          <div style={{marginTop: "20px", fontSize: "70%"}}><span className="text-red">* </span>{note1}<br/>&nbsp;&nbsp;&nbsp;{note2}</div>
          <CodeSnippet style={{padding: '0 0 0 10px', marginBottom: '-15px'}}>{snippet}</CodeSnippet>
        </Fragment>
      }
      description=""
    >
      <Divider />
      <div>
        { apisToDisplay }
      </div>
    </Panel>
  );
}

const mapStateToProps = (state) => ({
  apis: getAllApis(state),
  categories: getCategories(state)
});

export default connect(mapStateToProps, actions)(APIPresentationPanel);