import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../redux/actions';
import { getAccountTransactions, getAccountTransactionsCount, getDapp } from '../../redux/reducers';
import TransactionTable from '../TransactionTable';
import { removeEmpty } from '../../utils';

class DappTransactions extends React.Component {
	state = {
		offset: 0,
		limit: 5,
		page: 1,
		sort: 'consensusTime',
		order: 'desc'
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.dappId !== prevProps.dappId || this.props.filter !== prevProps.filter) {
			this.setState(
				{
					offset: 0,
					page: 1
				},
				() => this.fetchData()
			);
		}
		if (this.props.dapp !== prevProps.dapp) {
			this.fetchData()
		}
	}

	showTransactionDetails = (transaction) => {
		this.props.history.push(`/transactions/${transaction.transactionID}`);
	};

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = async () => {
		const {dapp}= this.props;
		if (!dapp || !dapp.accountNumber) return;
		//const { offset, limit, page, sort, order } = this.state;
		const { filter, dappId, fetchContractTransactions, fetchAccountTransactions, fetchDapp, fetchDappTransactions } = this.props;
		const params = { q: filter, ...this.state };

		const queryParams = removeEmpty(params);

		const esc = encodeURIComponent;
		const query = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');

		fetchAccountTransactions(dapp.accountNumber, query);
	};

	render() {
		const { transactions, totalCount } = this.props;
		return (
			<TransactionTable
				transactions={transactions}
				limit={this.state.limit}
				offset={this.state.offset}
				sort={this.state.sort}
				order={this.state.order}
				totalCount={totalCount}
				showDetails={this.showTransactionDetails}
				loadData={this.loadData}
			/>
		);
	}
}

const mapStateToProps = (state, { match: { params: { dappId } } }) => {
	const dapp = getDapp(state, dappId);
	return ({
		dapp,
		dappId,
		transactions: dapp ? getAccountTransactions(state, dapp.accountNumber) : [],
		totalCount: dapp ? getAccountTransactionsCount(state, dapp.accountNumber) : ''
	})
};

DappTransactions = withRouter(connect(mapStateToProps, actions)(DappTransactions));

export default DappTransactions;
