import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContractSummaryTable from '../ContractSummaryTable';
import CodeSnippet from '../shared/CodeSnippet';
import * as actions from '../../redux/actions';
import { getContract } from '../../redux/reducers';

class ContractSummary extends Component {
	state = {
		showKey: false
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchContract, fetchContractBalance, fetchContractRecentTransactionsCount, id } = this.props;
		fetchContractBalance(id);
		fetchContractRecentTransactionsCount(id);
		fetchContract(id);
	}

	onShowKey = () => {
		this.setState({ showKey: true });
	};

	onHideKey = () => {
		this.setState({ showKey: false });
	};

	render() {
		const { contract } = this.props;
		const { showKey } = this.state;
		return (
			<React.Fragment>
				<ContractSummaryTable contract={contract} onShowKey={this.onShowKey} />
				{contract &&
				showKey && <CodeSnippet code={contract.adminKey} onClose={this.onHideKey} title="Public Key(s)" />}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, { match: { params: { contractId } } }) => ({
	id: contractId,
	contract: getContract(state, contractId)
});

ContractSummary = withRouter(connect(mapStateToProps, actions)(ContractSummary));

export default ContractSummary;
