import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import TransferList from "./TransferList";
import NftsList from "./NftsList";
import Info from './shared/Info';
import NftHistoricalList from './NftHistoricalList';
import TokenTransferPanel from './TokenTransferPanel';
class TransactionDetailsTabs extends Component {
	constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0
    };
		this.handleTabChange = this.handleTabChange.bind(this);
		// this.panes = this.panes.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			window.location.reload();
			this.setState({activeTabIndex: 0});
		}
	}

	handleTabChange = ({ activeIndex }) => {
		this.setState({activeTabIndex: activeIndex});
	};

	render() {
		const { transfers, transactionID, nfts, typeLabel } = this.props

		let panes = [
			{
				menuItem: {
					key: 'allTransactions',
					content: (
						<span>
							HBAR TRANSFERS
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<TransferList transfers={transfers} />
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'tokenTransfers',
					content: (
						<span>
							TOKEN TRANSFERS {/*<Info id="tokenBalances" />*/}
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<TokenTransferPanel  isTransactionPage={true} transactionID={transactionID}/>
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'nftTransfers',
					content: (
						<span>
							NFT TRANSFERS
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<NftHistoricalList isTransactionPage={true} showArrow={false} isAccountPage={true} transactionID={transactionID}/>
					</Tab.Pane>
				)
			}
		]
			if(nfts && nfts.length > 0 && (typeLabel == "Burn Token" || typeLabel == "Wipe Account Token" || typeLabel == "Mint Token")) {
				panes.push(	{
					menuItem: {
						key: 'nftLists',
						content: (
							<span>
								NFTs
							</span>
						)
					},
					render: () => (
						<Tab.Pane as="div">
							<NftsList nfts={nfts} transactionID={transactionID}/>
						</Tab.Pane>
					)
				})
			}
		return (
			<div>
					<Tab
							menu={{ stackable: true, secondary: true, pointing: true }}
							panes={panes}
							renderActiveOnly={true}
							onTabChange={this.handleTabChange}
							activeIndex={this.state.activeTabIndex}
					/>
			</div>
		);
	}
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
	id: accountId
});

TransactionDetailsTabs = withRouter(connect(mapStateToProps, null)(TransactionDetailsTabs));

export default TransactionDetailsTabs;
