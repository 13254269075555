import React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

export default function ResetPasswordForm({ email, onChange, onSubmit, errors = {}, loading }) {
	const isError = Object.keys(errors).length !== 0;
	return (
		<Form size="large" onSubmit={onSubmit} error={isError}>
			<Message error content={errors.non_field_errors} />
			<Form.Input
				id="email"
				name="email"
				fluid
				icon="mail"
				iconPosition="left"
				placeholder="Email"
				autoFocus
				value={email}
				onChange={onChange}
				error={!!errors.email}
			/>
			<Message error content={errors.email} />
			<Button primary={true} fluid size="large" loading={loading}>
				SEND RESET EMAIL
			</Button>
			<Message error content={errors.auth} />
		</Form>
	);
}
