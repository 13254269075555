import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ApiCopyUrl from './ApiCopyUrl';

import '../animate.css';

const APICodecURL = ({api}) => {

    const handleCopyClick = () => {
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000)
    }
    
    const audio = new Audio('/click.mp3');

    const [ clicked, setClicked ] = useState(false);

    const codeString = 

`curl --location --request GET "${api.url}" \
--header "X-API-KEY: <YOUR_ACCESS_KEY>"`;

    return (

        <div style={{overflowWrap: "anywhere", fontSize: "95%"}}>

            <CopyToClipboard text={codeString}
                onCopy={() => handleCopyClick({copied: true})}>
                <span style={{marginLeft: "1%", position: "relative", top: "-10px"}}><ApiCopyUrl clicked={clicked} /></span>
            </CopyToClipboard>
            
            <div style={{position: "relative", top: "-18px"}}>
                <SyntaxHighlighter language="curl" style={docco}>
                    {codeString}
                </SyntaxHighlighter>
            </div>
            
        </div>
    );

};
export default APICodecURL;