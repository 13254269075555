import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Grid } from 'semantic-ui-react';
import Page from '../shared/Page';
import APISelectPanelNew from '../APISelectPanelNew';
import APIPresentationPanel from '../APIPresentationPanel';
import APIIntroductionPanel from '../APIIntroductionPanel';
import APISingleDisplayNew from '../APISingleDisplayNew';
import APISampleResponse from '../APISampleResponse';
import APICodeSnippetPanel from '../APICodeSnippetPanel';
import { getAllApis } from '../../redux/reducers';
import { getCategories } from '../../redux/reducers';

const APIView = (props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchData() }, []);
  useEffect(() => { window.scrollTo(0, 0) }, []);

  const fetchData = () => {
    props.fetchApiList();
    // props.fetchApiCategories(); // No need to fetch categories here
    props.fetchApiParameters();
  }

  useEffect(() => {
    return () => {
      props.cleanUpApiParameters()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [activeCategoryId, setActiveCategoryId] = useState('0');

  let [activeCategoryTitle, setActiveCategoryTitle] = useState('');

  let [activeApi, setActiveApi] = useState('');

  let [sampleData, setSampleData] = useState();

  const handleCategorySelect = (category) => {
    console.log("CATEGORY IN API VIEW NEW: ", category)
    setActiveCategoryId(category.id);
    setActiveApi('');
    setSampleData();
    // category.title === "Introduction" && setActiveCategoryTitle(category.title);
  }

  const handleApiSelect = (api) => {
    console.log("API IN VIEW NEW: ", api)
    setSampleData();
    setActiveApi(api);
    setActiveCategoryId('');
  }

  const fetchSampleResponse = (url) => {
    props.fetchSampleResponse(url).then(
      (response) => {
        console.log("RESPONSE SAMPLE DATA IN API VIEW NEW: ", response.data);
        setSampleData(response.data);
        return response;
      }
    )
  }

  // const panelToDisplay = (
  //   activeCategoryId === "0" ? <APIIntroductionPanel /> : <APIPresentationPanel id={activeCategoryId} title={activeCategoryTitle} />
  // );

  console.log("ACTIVE CATEGORY ID: ", activeCategoryId);
  console.log("ACTIVE API: ", activeApi);

  const panelToDisplay = (
    activeCategoryId === "0" || !activeApi ? <APIIntroductionPanel /> : <APISingleDisplayNew api={activeApi} handleTryClick={fetchSampleResponse} />
  );

  console.log("IN API VIEW NEW: ", props.apis, props.categories)

  return (
    // <Page>
      <div className="api-view-container">
      <Grid padded stackable>
        <Grid.Column computer={3} tablet={16} mobile={16}>
          <div style={{position: "sticky", top: "68px"}}>
            <Grid columns={1}>
              <Grid.Column>
                <APISelectPanelNew
                  handleCategorySelect={handleCategorySelect}
                  handleApiSelect={handleApiSelect}
                  apis={props.apis}
                  activeApi={activeApi}
                />  
              </Grid.Column>
            </Grid>
          </div>
        </Grid.Column>

        <Grid.Column computer={8} tablet={16} mobile={16} style={{paddingLeft: "0px", paddingRight: "0px", marginLeft: "-8px", marginRight: "-8px"}}>
          <div style={{position: "sticky", top: "68px"}}>
          <Grid columns={1}>
            <Grid.Column>
              { panelToDisplay }
            </Grid.Column>
          </Grid>
          </div>
        </Grid.Column>

        <Grid.Column computer={5} tablet={16} mobile={16} style={{paddingRight: "0"}}>
          <div /*style={{position: "sticky", top: "68px"}}*/>
          <Grid columns={1}>
            <Grid.Column style={{paddingRight: "8px", paddingBottom: "6px"}}>
              <APICodeSnippetPanel api={activeApi} />
            </Grid.Column>
          </Grid>
          <Grid columns={1}>
            <Grid.Column style={{paddingRight: "8px", paddingTop: "0px"}}>
              {sampleData && <APISampleResponse data={sampleData} />}
            </Grid.Column>
          </Grid>
          </div>
        </Grid.Column>
      </Grid>
      </div>
    // </Page>
  )
};

const mapStateToProps = (state) => ({
  apis: getAllApis(state),
  categories: getCategories(state)
});

export default connect(mapStateToProps, actions)(APIView);
