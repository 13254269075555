import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Button, Icon, Modal, Header, Message} from 'semantic-ui-react';
import Panel from './shared/Panel';
import DappSelectTable from './DappSelectTable';
import DappRegisteredTable from './DappRegisteredTable';
import DappCreate from './DappCreate';
import DappUpdate from './DappUpdate';
import DappCreateForm from './forms/DappCreateForm';
import ApiKeyTable from './ApiKeyTable';
import Info from './shared/Info';
import { getDapps } from '../redux/reducers';

import DappContractsDisplay from './DappContractsDisplay';

import '../animate.css';

import { CSVLink } from "react-csv";
import DappAddContract from './DappAddContract';

const DappCreatePanel = (props) => {

    const [isDisabled, setIsDisabled] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchData() }, []);

    const fetchData = async () => {
        // const keys = await props.fetchApiKey();
        // keys.length >= 3 && setIsDisabled(true);
        const { fetchDapps } = props;
		fetchDapps();
    }

    const [ownOpen, setOwnOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [addContractOpen, setAddContractOpen] = useState(false);
    const [messageOpen, setMessageOpen] = useState(false);
    const [messageFailureOpen, setMessageFailureOpen] = useState(false);
    const [contractMessageOpen, setContractMessageOpen] = useState(false);
    const [ownExistingDapp, setOwnExixtingDapp] = useState(false);

    const [selectedDapp, setSelectedDapp] = useState(null);

    const [contractsDisplayOpen, setContractsDisplayOpen ] = useState(false);

    const [uploadFailureOpen, setUploadFailureOpen] = useState(false);
    const [fileError, setFileError] = useState('');

    const handleOwn = () => {
        console.log('OWN AN EXISTING DAPP CLICKED');
        setOwnOpen(true);
    }

    const handleCreate = () => {
        console.log('CREATE A DAPP CLICKED');
        setCreateOpen(true);
    }

    const handleUpdate = async (dapp) => {
        console.log('UPDATE YOUR DAPP CLICKED');
        setSelectedDapp(dapp);
        setUpdateOpen(true);
    }

    const handleAddContract = async (dapp) => {
        console.log('HANDLE ADD CONTRACT CLICKED FOR: ', dapp);
        setSelectedDapp(dapp);
        handleContractsDisplayCancel();
        setAddContractOpen(true);
    }

    const handleContractsDisplayCancel = () => {
        setContractsDisplayOpen(false);
    }

    const handleDisplayContracts = async (dapp) => {
        console.log('HANDLE DISPLAY CONTRACTS CLICKED FOR: ', dapp);
        setSelectedDapp(dapp);
        setContractsDisplayOpen(true);
    }

    const handleCancel = () => {
        setOwnOpen(false);
        setCreateOpen(false);
        setUpdateOpen(false);
        setAddContractOpen(false);
        setContractsDisplayOpen(false);
        setUploadFailureOpen(false);
    }

    const handleMessageCancel = () => {
        setMessageOpen(false);
        window.location.reload();
    }

    const handleMessage = (response) => {
        // response && response.status === 201 ? setMessageOpen(true) : setMessageFailureOpen(true);
        if (response && response.data && response.data.exception && response.data.fileError) {
            setFileError(response.data.fileError);
            setUploadFailureOpen(true);
        } else {
            response && response.status && response.data !== "" && (response.status === 200 || response.status === 201) ?
            setMessageOpen(true) : setMessageFailureOpen(true);
        }
    }

    const handleContractMessageCancel = () => {
        setContractMessageOpen(false);
        window.location.reload();
    }

    const handleContractMessage = () => {
        setContractMessageOpen(true);
        // setTimeout(() => {setMessageOpen(false)}, 3000);
    }

    const reactivate = () => {
        setIsDisabled(false);
    }

    // const csvData = [
    //     {apiKey: key.key, accessKey: key.accessKey}
    // ]

    return (
        <Panel
            title={
                <React.Fragment>
                    MY DAPPS {/*<Info id="apiKeys" />*/}
                    {ownExistingDapp && <Button primary className="api-button" onClick={handleOwn} disabled={isDisabled}><Icon name="desktop" />OWN AN EXISTING DAPP</Button>}
                    <Button
                        primary
                        className="api-button"
                        onClick={handleCreate}
                        disabled={isDisabled}
                        size={window.innerWidth < 992 ? "tiny" : "medium"}>
                        <Icon name="keyboard outline" />
                        REGISTER A DAPP
                    </Button>
                </React.Fragment>
            }
        >
            <DappRegisteredTable handleUpdate={handleUpdate} handleAddContract={handleAddContract} handleDisplayContracts={handleDisplayContracts} />

			<Modal open={ownOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">Select an existing dapp</Modal.Header>
				<Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        {props.dapps.length > 0 && <DappSelectTable handleCancel={handleCancel} handleMessage={handleMessage} dapps={props.dapps} />}
                    </div>
				</Modal.Content>
			</Modal>

            {/* ------------------- REGISTERING A NEW DAPP ------------------- */}
            <Modal open={createOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">Register a Dapp</Modal.Header>
				<Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        {/*props.dapps.length > 0 && */<DappCreate handleCancel={handleCancel} handleMessage={handleMessage} dapps={props.dapps} />}
                    </div>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ----------------------- UPDATING A DAPP ----------------------- */}
            <Modal open={updateOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
                <Modal.Header className="modal-header">Update Your Dapp</Modal.Header>
                <Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        {selectedDapp && <DappUpdate handleCancel={handleCancel} handleMessage={handleMessage} dapp={selectedDapp} />}
                    </div>
                </Modal.Content>
            </Modal>
            {/* --------------------------------------------------------------- */}

            {/* ------------- RENDERING THE CONTRACTS DISPLAY VIEW ------------- */}
            <Modal style={{width: "450px"}} open={contractsDisplayOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
                <Modal.Header className="modal-header">Contracts</Modal.Header>
                <Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        {selectedDapp && <DappContractsDisplay handleCancel={handleCancel} handleAddContract={handleAddContract} handleMessage={handleMessage} dapp={selectedDapp} />}
                    </div>
                </Modal.Content>
            </Modal>
            {/* --------------------------------------------------------------- */}

            {/* ---------------------- ADDING A CONTRACT ---------------------- */}
            <Modal open={addContractOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
                <Modal.Header className="modal-header">Add a Contract</Modal.Header>
                <Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        {selectedDapp && <DappAddContract handleCancel={handleCancel} handleContractMessage={handleContractMessage} dapp={selectedDapp} />}
                    </div>
                </Modal.Content>
            </Modal>
            {/* --------------------------------------------------------------- */}

            {/* ---------- NOTIFICATION - CREATING OR UPDATING A DAPP ---------- */}
            <Modal size="small" open={messageOpen} closeIcon onClose={handleMessageCancel} dimmer="blurring" className="animated fadeIn">
				<Modal.Content>
                <Message positive>
                    <Message.Header style={{textAlign: "center"}}>
                        Your Dapp has been submitted for review.<br />Once approved, it will be activated on DragonGlass Dapp marketplace.
                    </Message.Header>
                </Message>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ------ NOTIFICATION - CREATING OR UPDATING A DAPP FAILED ------ */}
            <Modal size="small" open={messageFailureOpen} closeIcon onClose={handleMessageCancel} dimmer="blurring" className="animated fadeIn">
				<Modal.Content>
                <Message negative>
                    <Message.Header style={{textAlign: "center"}}>
                        Unable to complete your request at the moment, please try again later.
                    </Message.Header>
                </Message>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ---- FILE UPLOAD FAILED WHEN REGISTERING OR UPDATING A DAPP ---- */}
            <Modal size="small" open={uploadFailureOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated fadeIn">
				<Modal.Content>
                <Message negative>
                    <Message.Header style={{textAlign: "center"}}>
                        Unable to upload file {fileError} at the moment, please try again later.
                    </Message.Header>
                </Message>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* --------------- NOTIFICATION - ADDING A CONTRACT --------------- */}
            <Modal size="small" open={contractMessageOpen} closeIcon onClose={handleContractMessageCancel} dimmer="blurring" className="animated fadeIn">
				<Modal.Content>
                <Message positive>
                    <Message.Header style={{textAlign: "center"}}>
                        Your have successfully added a new contract.
                    </Message.Header>
                </Message>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

        </Panel>
    )
}

const mapStateToProps = (state) => ({
	dapps: getDapps(state),
});

export default connect(mapStateToProps, actions)(DappCreatePanel);
