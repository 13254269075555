import React, { Component } from 'react';
import Panel from './shared/Panel';
import Filter from './shared/Filter';
import ContractTransactions from './containers/ContractTransactions';
import Info from './shared/Info';

export default class ContractTransactionsPanel extends Component {
	state = {
		q: ''
	};

	onSubmitFilter = (value) => {
		this.setState({ q: value });
	};

	render() {
		const { q } = this.state;
		return (
			<Panel
				title={
					<React.Fragment>
						TRANSACTIONS <Info id="contractsTxns" />
					</React.Fragment>
				}
				description=""
				toolbar={
					<React.Fragment>
						<Filter onSubmitFilter={this.onSubmitFilter} />
						{/* <PeriodSelector /> */}
					</React.Fragment>
				}
			>
				<ContractTransactions filter={q} />
			</Panel>
		);
	}
}
