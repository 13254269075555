import React from 'react';

export default function MenuItem({ text, to, icon, ...rest }) {
	return (
		<li className="menu__item" role="menuitem" to={to} {...rest}>
			<div className="menu__link truncate">
				<span>{text}</span>
			</div>
		</li>
	);
}
