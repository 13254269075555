import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PieChart from '../../../atoms/PieChart';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { getTransactionTypesNonHcs } from '../../../redux/reducers';
import LoadingIndicator from "../../../components/containers/LoadingIndicator";

class TxnTypeNonHcsChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchTransactionTypesNonHcs, period } = this.props;
		console.log('PERIOD IN TXNTYPE CHART', period)
		fetchTransactionTypesNonHcs(period);
	}

	handleSegmentClick = (type) => {
		console.log("PERIOD IN PIE CHART: ", this.props.period)
		type = type === 'HCS' ? 'CONSENSUS' : type
		// this.props.history.push(`/search?transactionTypes=${type}&period=${this.props.period}`);
		this.props.history.push(`/search?serviceTypes=${type}&period=${this.props.period}`);
	}

	render() {
		const { txnServiceData: { types }, customHeight = 200 } = this.props;
		const filterTypes = types && types.length ? types.filter(type => type.name !== 'HCS') : types
		if (!this.props.txnServiceData.loading) {
			return (
				filterTypes && filterTypes.length ?
					<PieChart
						data={filterTypes}
						period={this.props.period}
						isLegend = {this.props.isLegend}
						handleClick={this.handleSegmentClick}
						customHeight={customHeight}
					/>
				: <div>No transactions available for the selected period</div>
			)

		} else {
			return  <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state) => ({
	txnServiceData: getTransactionTypesNonHcs(state)
});

TxnTypeNonHcsChart = connect(mapStateToProps, actions)(TxnTypeNonHcsChart);

export default withRouter(TxnTypeNonHcsChart)	;
