import { FETCH_DAPPS_SUCCESS, FETCH_DAPPS_FAILURE } from '../types';

export default function dapps(state = [], action) {
	switch (action.type) {
		case FETCH_DAPPS_SUCCESS:
			return [ ...action.payload ];
		case FETCH_DAPPS_FAILURE:
			return [];
		default:
			return state;
	}
}

export const getDapps = (state) => state;
