import React, { Component } from 'react';
import { getTokenDetails } from '../redux/reducers/hts';
import { Grid, Table, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {numberToReadableFormat} from '../utils';
import * as actions from '../redux/actions';
import DateCell from './shared/DateCell';
import Item from './shared/Item';
import CodeSnippet from './shared/CodeSnippet';
import '../assets/css/components/tokenDetails.scss';

class HtsTokenKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdminKey: false,
      showFreezeKey: false,
      showSupplyKey: false,
      showWipeKey: false,
      showKycKey: false
    };
    this.onShowKey = this.onShowKey.bind(this);
    this.onHideKey = this.onHideKey.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

	componentDidMount() {
    this.props.fetchTokenDetails({tokenId: this.props.id});
	}

	componentDidUpdate(prevProps) {
  }

  onShowKey = (params) => {
		this.setState({ showAdminKey: false, showFreezeKey: false, showSupplyKey: false, showWipeKey: false, showKycKey: false, ...params });
	};

	onHideKey = () => {
		this.setState({ showAdminKey: false, showFreezeKey: false, showSupplyKey: false, showWipeKey: false, showKycKey: false });
  };

  copyToClipboard = (keys) => {
    let textField = document.createElement('textarea')
    textField.innerText = JSON.stringify(keys);
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

	render() {
    const { token } = this.props;
    const {showAdminKey, showFreezeKey, showSupplyKey, showWipeKey, showKycKey } = this.state;
    return (
      <div>
        {token && Object.keys(token).length ?
          <>
            <Grid relaxed="very" stackable>
              <Grid.Column width="16">
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <Item
                          text="ADMIN KEY"
                          sub={<Icon name="eye" onClick={() => {this.onShowKey({showAdminKey: true})}} className="clickable" />}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Item
                          text="FREEZE KEY"
                          sub={<Icon name="eye" onClick={() => {this.onShowKey({showFreezeKey: true})}} className="clickable" />}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Item
                          text="SUPPLY KEY"
                          sub={<Icon name="eye" onClick={() => {this.onShowKey({showSupplyKey: true})}} className="clickable" />}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Item
                          text="WIPE KEY"
                          sub={<Icon name="eye" onClick={() => {this.onShowKey({showWipeKey: true})}} className="clickable" />}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Item
                          text="KYC KEY"
                          sub={<Icon name="eye" onClick={() => {this.onShowKey({showKycKey: true})}} className="clickable" />}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>

            {showAdminKey && <CodeSnippet code={token.adminKey} onClose={()=> {this.onHideKey();}} onCopy={()=> {this.copyToClipboard(token.adminKey);}} title="Admin Key" />}
            {showFreezeKey && <CodeSnippet code={token.freezeKey} onClose={()=> {this.onHideKey();}} onCopy={()=> {this.copyToClipboard(token.freezeKey);}} title="Freeze Key" />}
            {showSupplyKey && <CodeSnippet code={token.supplyKey} onClose={()=> {this.onHideKey();}} onCopy={()=> {this.copyToClipboard(token.supplyKey);}} title="Supply Key" />}
            {showWipeKey && <CodeSnippet code={token.wipeKey} onClose={()=> {this.onHideKey();}} onCopy={()=> {this.copyToClipboard(token.wipeKey);}} title="Wipe Key" />}
            {showKycKey && <CodeSnippet code={token.kycKey} onClose={()=> {this.onHideKey();}} onCopy={()=> {this.copyToClipboard(token.kycKey);}} title="Kyc Key" />}
          </>
        : ''}
      </div>
    );
	}
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
  id: tokenId,
	token: getTokenDetails(state)
});

export default withRouter(connect(mapStateToProps, actions)(HtsTokenKeys));