import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import LineChart from '../shared/LineChart';
import BarChart from '../shared/BarChart';
import * as actions from '../../redux/actions';
import { getAccountBalanceHistory, getAccountBalanceHistoryLoader } from '../../redux/reducers';
import { formatTimestamp } from '../../utils';
import LoadingIndicator from "./LoadingIndicator";

class DappAccountBalanceChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period || this.props.accountNumber !== prevProps.accountNumber) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchAccountBalanceHistory, accountNumber, period } = this.props;
		fetchAccountBalanceHistory(accountNumber, period);
	}
	render() {
		const { balanceHistory: { data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [ new Intl.NumberFormat('en').format(value) + ' ℏ' ];
		if (!this.props.loading) {
			return (
				data && data.length ?
					<Fragment>
						{/* <Stat count={totalSum} pct={pctChange} money currency={'hbar'} /> */}
						<br />
						<BarChart
							data={data}
							tickFormatter={formatter}
							labelFormatter={formatter}
							valueFormatter={valueFormatter}
						/>
					</Fragment>
				: <div>History not available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state, ownProps) => ({
	// id: accountId,
	loading: getAccountBalanceHistoryLoader(state, ownProps.accountNumber),
	balanceHistory: getAccountBalanceHistory(state, ownProps.accountNumber)
});

DappAccountBalanceChart = withRouter(connect(mapStateToProps, actions)(DappAccountBalanceChart));

export default DappAccountBalanceChart;
