import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as actions from '../../redux/actions';
import { Table, Checkbox, Button, Input, Grid, Dropdown, Radio, Form, Message, List, Icon, Popup } from 'semantic-ui-react';
import { getUser } from '../../redux/reducers';

import AwsUserCustomTooltip from '../AwsUserCustomTooltip';
import Info from '../shared/Info';
import AwsInfoCustomTooltip from '../AwsInfoCustomTooltip';
import ApiCopyUrl from '../ApiCopyUrl';
import CopyCustomTooltipRight from '../CopyCustomTooltipRight';
import SubscriptionQueuePopup from '../SubscriptionQueuePopup';
import SubscriptionCustomTooltip from '../SubscriptionCustomTooltip';

export const SubscriptionAddAwsArnForm = (
    {
        onModelSelect, 
        onSubscriptionTypeSelect, 
        onTransactionTypeSelect, 
        // onInputChange,
        onSubmit, 
        subscription,
        iams,
        handleSubscribeMessage, 
        subscriptionModels,
        onIamSelection,
        handleCancel,
        errors = {}
    }) => {

    console.log("SUBSCRIPTION IN ADD ARN FORM: ", subscription)
    console.log("IAMS IN ADD ARN FORM: ", iams)

    const [disabled, setDisabled] = useState(true);
    // const [displayTransactionTypes, setDisplayTransactionTypes] = useState(false);

    // const [checkedValue, setCheckedValue] = useState("default");

    // const displayRow = (e) => {
    //     e.target.textContent === "TRANSACTION" ? setDisplayTransactionTypes(true) : setDisplayTransactionTypes(false)
    // }

    // const handleCheck = (e, {value}) => {
    //     console.log({value});
    //     setCheckedValue(value);
    //     onIamSelection(value)
    // }

    // const models = subscriptionModels.map(el => ({key: el, text: el, value: el}));
    // const subscriptionModelOptions = models;

    // const subscriptionTypeOptions = [ 
    //     {key: "EVENT", text: "EVENT", value: "EVENT"}, 
    //     {key: "TRANSACTION", text: "TRANSACTION", value: "TRANSACTION"}, 
    //     {key: "HCS_TOPIC", text: "HCS_TOPIC", value: "HCS_TOPIC"}
    // ]

    // const transactionTypeOptions = [
    //     {key: "CONTRACT_CREATE", text: "CONTRACT_CREATE", value: "CONTRACT_CREATE"},
    //     {key: "CONTRACT_CALL", text: "CONTRACT_CALL", value: "CONTRACT_CALL"},
    //     {key: "CONTRACT_UPDATE", text: "CONTRACT_UPDATE", value: "CONTRACT_UPDATE"},
    //     {key: "CONTRACT_DELETE", text: "CONTRACT_DELETE", value: "CONTRACT_DELETE"},
    //     {key: "CRYPTO_ADD_CLAIM", text: "CRYPTO_ADD_CLAIM", value: "CRYPTO_ADD_CLAIM"},
    //     {key: "CRYPTO_CREATE_ACCOUNT", text: "CRYPTO_CREATE_ACCOUNT", value: "CRYPTO_CREATE_ACCOUNT"},
    //     {key: "CRYPTO_UPDATE_ACCOUNT", text: "CRYPTO_UPDATE_ACCOUNT", value: "CRYPTO_UPDATE_ACCOUNT"},
    //     {key: "CRYPTO_DELETE_CLAIM", text: "CRYPTO_DELETE_CLAIM", value: "CRYPTO_DELETE_CLAIM"},
    //     {key: "CRYPTO_DELETE_ACCOUNT", text: "CRYPTO_DELETE_ACCOUNT", value: "CRYPTO_DELETE_ACCOUNT"},
    //     {key: "CRYPTO_TRANSFER", text: "CRYPTO_TRANSFER", value: "CRYPTO_TRANSFER"}
    // ]

    const isError = Object.keys(errors).length !== 0;

    const audio = new Audio('/click.mp3');

    const [ clicked, setClicked ] = useState(false);

    const handleCopyClick = () => {
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000)
    }

    const [ arn, setArn ] = useState('');

    const onInputChange = e => {
        setArn(e.target.value)
    }

    // const [ iams, setIams ] = useState(subscription.iamUsers);

    // const updateIams = (arn) => {
    //     setIams(subscription.iamUsers ? [...iams, arn] : [arn]);
    //     console.log("THIS IS ARN ", arn)
    //     console.log("THESE ARE IAMS: ", iams)
    // }

    return (
        <Fragment>
        <Grid padded stackable>

            <Grid.Row columns="2">
                <Grid.Column width={8}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>SUBSCRIPTION MODEL</span>
                </Grid.Column>
                <Grid.Column width={8}>
                <Input
                    id="subscriptionModel"
                    name="subscriptionModel"
                    fluid
                    // icon="user"
                    // iconPosition="left"
                    // placeholder="Title"
                    value={subscription.subscriptionModel}
                    // onChange={onChange}
                    // error={!!errors.title}
                    // autoComplete="title"
                    required={false}
                    disabled
                />
                {/* <Dropdown
                    placeholder='Subscription Model'
                    fluid
                    selection
                    options={subscriptionModelOptions}
                    onChange={e => {onModelSelect(e); setDisabled(false)}}
                /> */}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns="2">
                <Grid.Column width={8}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>SUBSCRIPTION TYPE</span>
                </Grid.Column>
                <Grid.Column width={8}>
                <Input
                    id="subscriptionType"
                    name="subscriptionType"
                    fluid
                    // icon="user"
                    // iconPosition="left"
                    // placeholder="Title"
                    value={subscription.subscriptionType}
                    // onChange={onChange}
                    // error={!!errors.title}
                    // autoComplete="title"
                    required={false}
                    disabled
                />
                {/* <Dropdown
                    placeholder='Subscription Type'
                    fluid
                    selection
                    options={subscriptionTypeOptions}
                    onChange={e => {onSubscriptionTypeSelect(e); displayRow(e)}}
                /> */}
                </Grid.Column>
            </Grid.Row>

             {/* ------------------------------ QUEUE NAME ------------------------------ */}

             <Grid.Row columns="2">
                <Grid.Column width={8}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>QUEUE NAME</span>
                </Grid.Column>
                <Grid.Column width={8}>
                {/* <Input
                    id="queueName"
                    name="queueName"
                    fluid
                    // icon="user"
                    // iconPosition="left"
                    // placeholder="Title"
                    value={subscription.queueName}
                    // onChange={onChange}
                    // error={!!errors.title}
                    // autoComplete="title"
                    required={false}
                    disabled
                /> */}
                <SubscriptionCustomTooltip text={subscription.queueName} />
                <CopyToClipboard text={subscription.queueName}
                    onCopy={() => handleCopyClick({copied: true})}>
                    <span ><CopyCustomTooltipRight text={subscription.queueName} clicked={clicked} /></span>
                </CopyToClipboard>

                {/* <Dropdown
                    placeholder='Subscription Model'
                    fluid
                    selection
                    options={subscriptionModelOptions}
                    onChange={e => {onModelSelect(e); setDisabled(false)}}
                /> */}
                </Grid.Column>
            </Grid.Row>

            {/* ------------------------------------------------------------------------- */}

            {/* ------------------------------- QUEUE URL ------------------------------- */}

            <Grid.Row columns="2">
                <Grid.Column width={8}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>QUEUE URL</span>
                </Grid.Column>
                <Grid.Column width={8}>
                {/* <Input
                    id="queueName"
                    name="queueName"
                    fluid
                    // icon="user"
                    // iconPosition="left"
                    // placeholder="Title"
                    value={subscription.queueName}
                    // onChange={onChange}
                    // error={!!errors.title}
                    // autoComplete="title"
                    required={false}
                    disabled
                /> */}
                <SubscriptionCustomTooltip text={subscription.queueUrl} />
                <CopyToClipboard text={subscription.queueUrl}
                    onCopy={() => handleCopyClick({copied: true})}>
                    <span ><CopyCustomTooltipRight text={subscription.queueUrl} clicked={clicked} moveRight={true} /></span>
                </CopyToClipboard>

                {/* <Dropdown
                    placeholder='Subscription Model'
                    fluid
                    selection
                    options={subscriptionModelOptions}
                    onChange={e => {onModelSelect(e); setDisabled(false)}}
                /> */}
                </Grid.Column>
            </Grid.Row>

            {/* ------------------------------------------------------------------------- */}

            {/* {displayTransactionTypes && <Grid.Row columns="2">
                <Grid.Column width={10}>
                <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>TRANSACTION TYPE</span>
                </Grid.Column>
                <Grid.Column width={6}>
                <Dropdown
                    placeholder='Transaction Type'
                    fluid
                    multiple
                    selection
                    options={transactionTypeOptions}
                    onChange={(e, data) => {onTransactionTypeSelect(e, data)}}
                />
                </Grid.Column>
            </Grid.Row>} */}

            {/* -------------------------------- SQS ARN -------------------------------- */}

            <Grid.Row columns="4">
                <Grid.Column width={5}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>AWS USER ARN</span>
                    <span style={{position: "relative", bottom: "-4px"}}><AwsInfoCustomTooltip /></span>
                    <span style={{marginLeft: "14px", position: "relative", bottom: "2px", left: "18px"}}>
                        <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_users_create.html" target="_blank" rel="noopener noreferrer">
                        <AwsUserCustomTooltip />
                        {/* <Icon name="external alternate" /> */}
                        </a>
                    </span>
                </Grid.Column>
                <Grid.Column width={3}>
                    {/* <Form>
                        <Form.Field>
                            <Radio
                                label='DEFAULT USER ARN'
                                name='radioGroup'
                                value='default'
                                checked={checkedValue === 'default'}
                                onChange={handleCheck}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='CUSTOM USER ARN'
                                name='radioGroup'
                                value='custom'
                                checked={checkedValue === 'custom'}
                                onChange={handleCheck}
                            />
                        </Form.Field>
                    </Form> */}
                </Grid.Column>
                <Grid.Column width={6}>
                    {/* {checkedValue === 'custom' &&  */}
                    <Form error={isError}>
                        <Form.Input  // error={isError}
                            id="iAmUser"
                            name="iAmUser"
                            fluid
                            // icon="building"
                            // iconPosition="left"
                            placeholder="Enter your ARN (optional)"
                            autoFocus
                            value={arn}
                            onChange={onInputChange}
                            // error={!!errors.iAmUser}
                            autoComplete="iAmUser"
                            required={false}
                        />
                        <Message error content={errors.iAmUser} className="animated fadeIn" />
                    </Form> {/*}*/}
                    {/* <Button 
                        type="submit" 
                        style={{float: "right"}} 
                        size="tiny" 
                        primary
                        disabled={arn.length === 0} 
                        onClick={e => {onSubmit(subscription.id, arn); updateIams(arn); setArn('')}}
                        >
                        ADD
                    </Button> */}
                </Grid.Column>
                <Grid.Column width={2}>
                    <Button 
                        type="submit" 
                        style={{float: "right"}} 
                        size="medium" 
                        primary
                        disabled={arn.length === 0} 
                        onClick={e => {onSubmit(subscription.id, arn.replace(/:/g, '%3A').replace(/\//g, '%2F')); /*updateIams(arn); */setArn('')}}
                        >
                        ADD
                    </Button>
                </Grid.Column>
            </Grid.Row>

            {/* ------------------------------------------------------------------------- */}

            {/* --------------------------- DISPLAY iAmUsers --------------------------- */}

            <Grid.Row columns="2">
                <Grid.Column width={8}>
                    {/* <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>SUBSCRIPTION TYPE</span> */}
                </Grid.Column>
                <Grid.Column width={8}>
                    <List items={iams} />
                {/* <Input
                    id="subscriptionType"
                    name="subscriptionType"
                    fluid
                    // icon="user"
                    // iconPosition="left"
                    // placeholder="Title"
                    value={subscription.subscriptionType}
                    // onChange={onChange}
                    // error={!!errors.title}
                    // autoComplete="title"
                    required={false}
                    disabled
                /> */}
                </Grid.Column>
            </Grid.Row>

            {/* ------------------------------------------------------------------------- */}

        </Grid>

        <Button 
            type="submit" 
            style={{margin: "30px 0 20px 0", float: "right"}} 
            primary 
            // disabled={disabled} 
            onClick={e => {/*onSubmit(subscription.id, arn); */handleCancel(); window.location.reload()}}
            >
            <Icon name="close" />
            CLOSE
        </Button>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default withRouter(connect(mapStateToProps, actions)(SubscriptionAddAwsArnForm));
