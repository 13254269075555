import React from 'react';

import '../animate.css';

const SubscriptionDescriptionTransaction = () => {
    return (
        <div className="description-container animated fadeIn">
            <div className="description-text">
                Subscribing to a transaction means that a raw transaction will be sent by a subscription
                service based on transaction types and filter selection.  To create an event subscription
                for a transaction, a user must first select the Add a Subscription button in the My Subscriptions
                menu in their profile.
            </div>
            {/* -------------------------- */}
            <div className="image-div">
                <img width="90%" src="/blank_my_subscription.png" alt="" />
            </div>
            <div className="description-text">
                For the Subscription Model field, Free Subscription is the only available option.  When the
                subscription type TRANSACTION is selected, another dropdown menu will allow the user to select
                one or more Transaction Types. The options are CONTRACT_CREATE, CONTRACT_CALL, CONTRACT_UPDATE, CONTRACT_DELETE,
                CRYPTO_CREATE_ACCOUNT, CRYPTO_UPDATE_ACCOUNT, CRYPTO_DELETE_ACCOUNT, CRYPTO_TRANSFER, FILE_CREATE, FILE_APPEND,
                FILE_UPDATE, FILE_DELETE, SYSTEM_DELETE, SYSTEM_UNDELETE, CONSENSUS_CREATE_TOPIC, CONSENSUS_UPDATE_TOPIC, CONSENSUS_DELETE_TOPIC,
                CONSENSUS_SUBMIT_MESSAGE, TOKEN_CREATE, TOKEN_UPDATE, TOKEN_MINT, TOKEN_BURN, TOKEN_TRANSFER, TOKEN_WIPE_ACCOUNT, TOKEN_DELETE, TOKEN_FREEZE_ACCOUNT,
                TOKEN_UNFREEZE_ACCOUNT, GRANT_KYC, REVOKE_KYC, TOKEN_ASSOCIATE_ACCOUNT and TOKEN_DISASSOCIATE_ACCOUNT.
            </div>
            {/* -------------------------- */}
            <div className="image-div">
                <img width="90%" src="/create_transaction_subscription1.png" alt="" />
            </div>
            <div className="description-text">
                Once the subscription is submitted, the user will see it in their My Subscriptions menu.
                After creation, users will then have to add filters; if filters are not added, the DragonGlass
                queue will be empty.
            </div>
            {/* -------------------------- */}
            <div className="image-div">
                <img width="90%" src="/menu_with_a_subscription_edited.png" alt="" />
            </div>
            <div className="description-text">
                By clicking the Filter icon in the Filters column of the subscription table and selecting the
                Add Filter button, the user can define additional filters for the transaction subscription,
                as well as enter the values for the selected filters (a contract ID, an account ID, or a topic ID).
                For the fields accountID and contractID, the format is standardized as 0.0.####.  If a user selects
                subscription type TRANSACTION, another dropdown menu allows the user to select a filter type out of
                PAYER_ACCOUNT, SENDER_ACCOUNT, RECEIVER_ACCOUNT, and CONTRACT.
            </div>
            {/* -------------------------- */}
            <div className="image-div">
                <img width="90%" src="/add_filter_for_transaction.png" alt="" />
            </div>
            <div className="description-text">
                Once a filter is defined, the user selects the Add Filter button, and the new filter will be seen in
                My Subscriptions.  If a filter has already been created, clicking the Filter icon will display a table
                providing an option to delete an existing filter.
            </div>
            {/* -------------------------- */}
            <div className="image-div">
                <img width="90%" src="/filter_for_transaction.png" alt="" />
            </div>
        </div>
    )
}

export default SubscriptionDescriptionTransaction;
