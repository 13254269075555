import React, { Fragment } from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import '../animate.css';

const AwsInfoCustomTooltip = (props) => {

    return (
        <Fragment>
        {/* <Popup
            trigger={<Icon name="copy" className="copy-icon" onClick={props.handleCopyClick} />}
            content={<span className={props.clicked ? "info-content animated flash" : "info-content"}>
                        {props.clicked ? "Copied!" : "Copy to clipboard"}
                    </span>}
            hoverable
            style={{cursor: "pointer"}}
            basic
        /> */}
        <span className="hasTooltip">
            <div className="info-content">
                By default, the sqs queue has public access. To be able to restrict access to the queue, please add an aws user arn.
            </div>
            <Icon className="info-icon" size="tiny" name="info" circular />
        </span>
        </Fragment>
    )
}

export default AwsInfoCustomTooltip;