import {
  FETCH_TOP_TEN_TOKEN_VALUE_SUCCESS,
  FETCH_TOP_TEN_TOKEN_VALUE_FAILURE
} from '../types';

const initialState = {};

export default function topTenTokensByValue(state = initialState, action) {
	switch (action.type) {
		case FETCH_TOP_TEN_TOKEN_VALUE_SUCCESS:
			return {
				...state,
				topTokensByValue: action.data
			};
		case FETCH_TOP_TEN_TOKEN_VALUE_FAILURE:
			return {
				...state,
				topTokensByValue: []
			};
		default:
			return state;
	}
}

export const getTopTokensByValue = (state) => state.topTenTokensByValue && state.topTenTokensByValue.topTokensByValue ? state.topTenTokensByValue.topTokensByValue.data : [];

