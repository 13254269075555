import React from 'react';
import { Header } from 'semantic-ui-react';

export default function Item({ text, sub }) {
	return (
		<Header as="h4" image>
			<Header.Content>
				{text}
				<Header.Subheader>{sub}</Header.Subheader>
			</Header.Content>
		</Header>
	);
}
