import React from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { fromDate } from '../utils';
import Number from './shared/Number';

const FeaturedDappsTable = (props) => {

	const { dapps = [] } = props;
	const sortedDapps = dapps.filter(dapp => dapp.active === true).sort((a, b) => a.sortIndex - b.sortIndex).slice(0, 10);

	console.log("SORTED DAPPS: ", sortedDapps)

	const handleRowClick = (id) => {
		props.history.push(`/dapps/${id}`)
	}

	return (
		<Table basic="very" selectable>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell width={7} /*style={{paddingLeft: "120px"}}*/>Company</Table.HeaderCell>
					<Table.HeaderCell>Description</Table.HeaderCell>
					{/* <Table.HeaderCell width={5}>Website</Table.HeaderCell> */}
					{/* <Table.HeaderCell>Transactions(24H)</Table.HeaderCell> */}
					{/* <Table.HeaderCell>Accounts</Table.HeaderCell> */}
					{/* <Table.HeaderCell>Active Since</Table.HeaderCell> */}
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{sortedDapps.map((dapp) => {
					const { id, title, description, website, numTransactions, numAccounts, activeSince, logoURL } = dapp;
					const image = logoURL ? <img className="logo-image" style={{maxWidth: "90px", maxHeight: "50px"}} src={logoURL} /> : '';
					const logo = <span style={{width: "110px", textAlign: "center"}}>{image}</span>;
					const desc = description.slice(0, 90) + "...";
					return (
						<Table.Row key={id} onClick={e => handleRowClick(id)} style={{cursor: "pointer", transition: "all 0.2s ease-in-out"}}>
							<Table.Cell style={{paddingLeft: "10px"}}>
									<div className="logo-title">{logo}<strong className="text-gray dapp-name">{title}</strong></div>
							</Table.Cell>
							<Table.Cell id="dapp-desc-homepage" style={{paddingRight: "15px", paddingLeft: "11px"}}>
									{desc}
							</Table.Cell>
							{/* <Table.Cell style={{paddingRight: "10px"}}><a href={website} target="_blank">{website}</a></Table.Cell> */}
							{/* <Table.Cell>
								<Number>{numTransactions}</Number>
							</Table.Cell> */}
							{/* <Table.Cell>
								<Number>{numAccounts}</Number>
							</Table.Cell> */}
							{/* <Table.Cell>{fromDate(activeSince)}</Table.Cell> */}
						</Table.Row>
					);
				})}
			</Table.Body>
		</Table>
	);
}

export default withRouter(FeaturedDappsTable);
