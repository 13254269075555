import React from 'react';
import { Table } from 'semantic-ui-react';

export default function NftsBalanceTableHeader(props) {
	return (
		<Table.Row>
			<Table.HeaderCell width={3}>
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'tokenId' ? props.direction : null}
				onClick={() => props.handleSort('tokenId')}
			>
				TOKEN
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'balance' ? props.direction : null}
				onClick={() => props.handleSort('balance')}
			>
				SERIAL NO.
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'createdOn' ? props.direction : null}
				onClick={() => props.handleSort('createdOn')}
			>
				CREATED ON
			</Table.HeaderCell>
			<Table.HeaderCell width={5}>
				META DATA
			</Table.HeaderCell>
		</Table.Row>
	);
}
