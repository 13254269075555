import React from 'react';
import { Grid } from 'semantic-ui-react';

import ContractSummaryPanel from '../ContractSummaryPanel';
import ContractTransactionsPanel from '../ContractTransactionsPanel';
import ContractTransfersPanel from '../ContractTransfersPanel';
import ContractCodePanel from '../ContractCodePanel';

import ContractBalanceChartPanel from '../ContractBalanceChartPanel';
import ContractTxnVolumeChartPanel from '../ContractTxnVolumeChartPanel';
import ContractUsersChartPanel from '../ContractUsersChartPanel';

import Page from '../shared/Page';
import Search from '../containers/Search';
import AccountLookup from '../containers/AccountLookup';

export default () => (
	<Page>
		<Grid padded stackable>
			<Grid.Column computer={11} only="tablet computer">
				<Search />
			</Grid.Column>
			<Grid.Column computer={5} tablet={16} mobile={16} className="no-padding-mobile">
				<AccountLookup />
			</Grid.Column>
			<Grid.Column computer={11} tablet={16} mobile={16}>
				<Grid columns={1}>
					<Grid.Column>
						<ContractSummaryPanel />
					</Grid.Column>
					<Grid.Column>
						<ContractTransactionsPanel />
					</Grid.Column>
					<Grid.Column>
						<ContractTransfersPanel />
					</Grid.Column>
					<Grid.Column>
						<ContractCodePanel />
					</Grid.Column>
				</Grid>
			</Grid.Column>
			<Grid.Column computer={5} tablet={16} mobile={16}>
				<Grid columns={1}>
					<Grid.Column>
						<ContractBalanceChartPanel />
					</Grid.Column>
					<Grid.Column>
						<ContractTxnVolumeChartPanel />
					</Grid.Column>
					<Grid.Column>
						<ContractUsersChartPanel />
					</Grid.Column>
					<Grid.Column only="mobile" className="no-padding-mobile">
						<Search />
					</Grid.Column>
				</Grid>
			</Grid.Column>
		</Grid>
	</Page>
);
