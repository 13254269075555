import React from 'react';
import Panel from './shared/Panel';
import DappAccountSubscription from './containers/DappAccountSubscription';
import Info from './shared/Info';

export default (props) => (
	<Panel
		title={
			<React.Fragment>
				SUBSCRIPTION <Info id="subscription" />
			</React.Fragment>
		}
		titleClass="light"
		description=""
	>
		<DappAccountSubscription accountNumber={props.accountNumber} />
	</Panel>
);
