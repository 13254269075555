import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Stat from '../shared/Stat';
import * as actions from '../../redux/actions';
import { getContractUsersCount, getDapp, } from '../../redux/reducers';

class ContractTxnVolumeChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
		if (this.props.dapp !== prevProps.dapp) {
			this.fetchData()
		}
	}

	fetchData() {
		const {dapp}= this.props;
		if (!dapp || !dapp.accountId) return;
		const { fetchContractUsersCount, period } = this.props;
		fetchContractUsersCount(dapp.accountId, period);
    }
    
	render() {
		const { usersCount } = this.props;
		if (usersCount || usersCount === 0) {
			return (
				<Fragment>
					<div style={{paddingBottom: "20px", fontSize: "45px"}} className="panel-stat center">
						{usersCount}
					</div>
					{/* <Stat count={usersCount} /> */}
				</Fragment>
			);
		} else {
			return <div>No data available for the selected period</div>;
		}
	}
}

const mapStateToProps = (state, { match: { params: { dappId } } }) => {
    const dapp = getDapp(state, dappId);
    return ({
        dapp,
        dappId,
        usersCount: dapp ? getContractUsersCount(state, dapp.accountId) : ''
    })
};

ContractTxnVolumeChart = withRouter(connect(mapStateToProps, actions)(ContractTxnVolumeChart));

export default ContractTxnVolumeChart;
