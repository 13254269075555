import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ApiCopyUrl from './ApiCopyUrl';

import '../animate.css';

const APICodeSwift = ({api}) => {

    const handleCopyClick = () => {
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000)
    }
    
    const audio = new Audio('/click.mp3');

    const [ clicked, setClicked ] = useState(false);

    const codeString =

`import Foundation

var semaphore = DispatchSemaphore (value: 0)

var request = URLRequest(url: URL(string: "${api.url}")!,timeoutInterval: Double.infinity)
request.addValue("<YOUR_ACCESS_KEY>", forHTTPHeaderField: "X-API-KEY")

request.httpMethod = "GET"

let task = URLSession.shared.dataTask(with: request) { data, response, error in 
  guard let data = data else {
    print(String(describing: error))
    return
  }
  print(String(data: data, encoding: .utf8)!)
  semaphore.signal()
}

task.resume()
semaphore.wait()`;

    return (

        <div style={{overflowWrap: "anywhere", fontSize: "95%"}}>

            <CopyToClipboard text={codeString}
                onCopy={() => handleCopyClick({copied: true})}>
                <span style={{marginLeft: "1%", position: "relative", top: "-10px"}}><ApiCopyUrl clicked={clicked} /></span>
            </CopyToClipboard>

            <div style={{position: "relative", top: "-18px"}}>
                <SyntaxHighlighter language="swift" style={docco}>
                    {codeString}
                </SyntaxHighlighter>
            </div>

        </div>
    );

};
export default APICodeSwift;