export const FETCH_CONFIG_REQUEST = "FETCH_CONFIG_REQUEST";
export const FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS";
export const FETCH_CONFIG_FAILURE = "FETCH_CONFIG_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const FETCH_USER_ACCOUNTS_REQUEST = "FETCH_USER_ACCOUNTS_REQUEST";
export const FETCH_USER_ACCOUNTS_SUCCESS = "FETCH_USER_ACCOUNTS_SUCCESS";
export const FETCH_USER_ACCOUNTS_FAILURE = "FETCH_USER_ACCOUNTS_FAILURE";

export const ADD_USER_ACCOUNT_REQUEST = "ADD_USER_ACCOUNT_REQUEST";
export const ADD_USER_ACCOUNT_SUCCESS = "ADD_USER_ACCOUNT_SUCCESS";
export const ADD_USER_ACCOUNT_FAILURE = "ADD_USER_ACCOUNT_FAILURE";

export const REMOVE_USER_ACCOUNT_REQUEST = "REMOVE_USER_ACCOUNT_REQUEST";
export const REMOVE_USER_ACCOUNT_SUCCESS = "REMOVE_USER_ACCOUNT_SUCCESS";
export const REMOVE_USER_ACCOUNT_FAILURE = "REMOVE_USER_ACCOUNT_FAILURE";

export const UPDATE_USER_ACCOUNT_REQUEST = "UPDATE_USER_ACCOUNT_REQUEST";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";
export const UPDATE_USER_ACCOUNT_FAILURE = "UPDATE_USER_ACCOUNT_FAILURE";

export const SAVE_USER_PROFILE_REQUEST = "SAVE_USER_PROFILE_REQUEST";
export const SAVE_USER_PROFILE_SUCCESS = "SAVE_USER_PROFILE_SUCCESS";
export const SAVE_USER_PROFILE_FAILURE = "SAVE_USER_PROFILE_FAILURE";

export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILURE";

export const FETCH_NODES_REQUEST = "FETCH_NODES_REQUEST";
export const FETCH_NODES_SUCCESS = "FETCH_NODES_SUCCESS";
export const FETCH_NODES_FAILURE = "FETCH_NODES_FAILURE";

export const FETCH_NEWS_REQUEST = "FETCH_NEWS_REQUEST";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_FAILURE = "FETCH_NEWS_FAILURE";

export const FETCH_DAPPS_REQUEST = "FETCH_DAPPS_REQUEST";
export const FETCH_DAPPS_SUCCESS = "FETCH_DAPPS_SUCCESS";
export const FETCH_DAPPS_FAILURE = "FETCH_DAPPS_FAILURE";

export const FETCH_STATS_REQUEST = "FETCH_STATS_REQUEST";
export const FETCH_STATS_SUCCESS = "FETCH_STATS_SUCCESS";
export const FETCH_STATS_FAILURE = "FETCH_STATS_FAILURE";

export const FETCH_TRANSACTION_TYPES_LOAD = "FETCH_TRANSACTION_TYPES_LOAD";
export const FETCH_TRANSACTION_TYPES_SUCCESS = "FETCH_TRANSACTION_TYPES_SUCCESS";
export const FETCH_TRANSACTION_TYPES_FAILURE = "FETCH_TRANSACTION_TYPES_FAILURE";

export const FETCH_TRANSACTION_TYPES_NON_HCS_LOAD = "FETCH_TRANSACTION_TYPES_NON_HCS_LOAD";
export const FETCH_TRANSACTION_TYPES_NON_HCS_SUCCESS = "FETCH_TRANSACTION_TYPES_NON_HCS_SUCCESS";
export const FETCH_TRANSACTION_TYPES_NON_HCS_FAILURE = "FETCH_TRANSACTION_TYPES_NON_HCS_FAILURE";

export const FETCH_TRANSACTION_VOLUME_LOAD = "FETCH_TRANSACTION_VOLUME_LOAD";
export const FETCH_TRANSACTION_VOLUME_SUCCESS = "FETCH_TRANSACTION_VOLUME_SUCCESS";
export const FETCH_TRANSACTION_VOLUME_FAILURE = "FETCH_TRANSACTION_VOLUME_FAILURE";

export const FETCH_ACCOUNT_CREATION_VOLUME_LOAD = "FETCH_ACCOUNT_CREATION_VOLUME_LOAD";
export const FETCH_ACCOUNT_CREATION_VOLUME_SUCCESS = "FETCH_ACCOUNT_CREATION_VOLUME_SUCCESS";
export const FETCH_ACCOUNT_CREATION_VOLUME_FAILURE = "FETCH_ACCOUNT_CREATION_VOLUME_FAILURE";

export const FETCH_TRANSACTION_VALUES_LOAD = "FETCH_TRANSACTION_VALUES_LOAD";
export const FETCH_TRANSACTION_VALUES_SUCCESS = "FETCH_TRANSACTION_VALUES_SUCCESS";
export const FETCH_TRANSACTION_VALUES_FAILURE = "FETCH_TRANSACTION_VALUES_FAILURE";

export const FETCH_ACCOUNT_SUCCESS = "FETCH_ACCOUNT_SUCCESS";
export const FETCH_ACCOUNT_FAILURE = "FETCH_ACCOUNT_FAILURE";

export const FETCH_ACCOUNT_BALANCE_SUCCESS = "FETCH_ACCOUNT_BALANCE_SUCCESS";
export const FETCH_ACCOUNT_BALANCE_FAILURE = "FETCH_ACCOUNT_BALANCE_FAILURE";

export const FETCH_ACCOUNT_TX_COUNT_SUCCESS = "FETCH_ACCOUNT_TX_COUNT_SUCCESS";
export const FETCH_ACCOUNT_TX_COUNT_FAILURE = "FETCH_ACCOUNT_TX_COUNT_FAILURE";

export const FETCH_ACCOUNT_TRANSFERS_SUCCESS = "FETCH_ACCOUNT_TRANSFERS_SUCCESS";
export const FETCH_ACCOUNT_TRANSFERS_FAILURE = "FETCH_ACCOUNT_TRANSFERS_FAILURE";

export const FETCH_ACCOUNT_TRANSACTIONS_SUCCESS = "FETCH_ACCOUNT_TRANSACTIONS_SUCCESS";
export const FETCH_ACCOUNT_TRANSACTIONS_FAILURE = "FETCH_ACCOUNT_TRANSACTIONS_FAILURE";

export const FETCH_ACCOUNT_BALANCE_HISTORY_LOAD = "FETCH_ACCOUNT_BALANCE_HISTORY_LOAD";
export const FETCH_ACCOUNT_BALANCE_HISTORY_SUCCESS = "FETCH_ACCOUNT_BALANCE_HISTORY_SUCCESS";
export const FETCH_ACCOUNT_BALANCE_HISTORY_FAILURE = "FETCH_ACCOUNT_BALANCE_HISTORY_FAILURE";

export const FETCH_ACCOUNT_FILES_SUCCESS = "FETCH_ACCOUNT_FILES_SUCCESS";
export const FETCH_ACCOUNT_FILES_FAILURE = "FETCH_ACCOUNT_FILES_FAILURE";

export const FETCH_TRANSACTION_SUCCESS = "FETCH_TRANSACTION_SUCCESS";
export const FETCH_TRANSACTION_FAILURE = "FETCH_TRANSACTION_FAILURE";

export const FETCH_RAW_TRANSACTION_SUCCESS = "FETCH_RAW_TRANSACTION_SUCCESS";
export const FETCH_RAW_TRANSACTION_FAILURE = "FETCH_RAW_TRANSACTION_FAILURE";

export const FETCH_TRANSACTIONS_REQUEST = "FETCH_TRANSACTIONS_REQUEST";
export const FETCH_TRANSACTIONS_PIE_CHART_REQUEST = "FETCH_TRANSACTIONS_PIE_CHART_REQUEST";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_TRANSACTIONS_PIE_CHART_SUCCESS = "FETCH_TRANSACTIONS_PIE_CHART_SUCCESS";
export const FETCH_TRANSACTIONS_FAILURE = "FETCH_TRANSACTIONS_FAILURE";
export const FETCH_TRANSACTIONS_PIE_CHART_FAILURE = "FETCH_TRANSACTIONS_PIE_CHART_FAILURE";

export const FETCH_CONTRACTS_REQUEST = "FETCH_CONTRACTS_REQUEST";
export const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";
export const FETCH_CONTRACTS_FAILURE = "FETCH_CONTRACTS_FAILURE";

export const FETCH_ACCOUNTS_REQUEST = "FETCH_ACCOUNTS_REQUEST";
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS";
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE";

export const FETCH_CONTRACT_REQUEST = "FETCH_CONTRACT_REQUEST";
export const FETCH_CONTRACT_SUCCESS = "FETCH_CONTRACT_SUCCESS";
export const FETCH_CONTRACT_FAILURE = "FETCH_CONTRACT_FAILURE";

export const FETCH_CONTRACT_TRANSACTIONS_SUCCESS = "FETCH_CONTRACT_TRANSACTIONS_SUCCESS";
export const FETCH_CONTRACT_TRANSACTIONS_FAILURE = "FETCH_CONTRACT_TRANSACTIONS_FAILURE";

export const FETCH_CONTRACT_BALANCE_SUCCESS = "FETCH_CONTRACT_BALANCE_SUCCESS";
export const FETCH_CONTRACT_BALANCE_FAILURE = "FETCH_CONTRACT_BALANCE_FAILURE";

export const FETCH_CONTRACT_TX_COUNT_SUCCESS = "FETCH_CONTRACT_TX_COUNT_SUCCESS";
export const FETCH_CONTRACT_TX_COUNT_FAILURE = "FETCH_CONTRACT_TX_COUNT_FAILURE";

export const FETCH_CONTRACT_TRANSFERS_SUCCESS = "FETCH_CONTRACT_TRANSFERS_SUCCESS";
export const FETCH_CONTRACT_TRANSFERS_FAILURE = "FETCH_CONTRACT_TRANSFERS_FAILURE";

export const FETCH_CONTRACT_BALANCE_HISTORY_LOAD = "FETCH_CONTRACT_BALANCE_HISTORY_LOAD";
export const FETCH_CONTRACT_BALANCE_HISTORY_SUCCESS = "FETCH_CONTRACT_BALANCE_HISTORY_SUCCESS";
export const FETCH_CONTRACT_BALANCE_HISTORY_FAILURE = "FETCH_CONTRACT_BALANCE_HISTORY_FAILURE";

export const FETCH_CONTRACT_BYTECODE_SUCCESS = "FETCH_CONTRACT_BYTECODE_SUCCESS";
export const FETCH_CONTRACT_BYTECODE_FAILURE = "FETCH_CONTRACT_BYTECODE_FAILURE";

export const FETCH_CONTRACT_TRANSACTION_VOLUME_LOAD = "FETCH_CONTRACT_TRANSACTION_VOLUME_LOAD";
export const FETCH_CONTRACT_TRANSACTION_VOLUME_SUCCESS = "FETCH_CONTRACT_TRANSACTION_VOLUME_SUCCESS";
export const FETCH_CONTRACT_TRANSACTION_VOLUME_FAILURE = "FETCH_CONTRACT_TRANSACTION_VOLUME_FAILURE";

export const FETCH_CONTRACT_USERS_COUNT_SUCCESS = "FETCH_CONTRACT_USERS_COUNT_SUCCESS";
export const FETCH_CONTRACT_USERS_COUNT_FAILURE = "FETCH_CONTRACT_USERS_COUNT_FAILURE";

export const FETCH_API_CATEGORIES_SUCCESS = "FETCH_API_CATEGORIES_SUCCESS";
export const FETCH_API_CATEGORIES_FAILURE = "FETCH_API_CATEGORIES_FAILURE";

export const FETCH_API_PARAMETERS_SUCCESS = "FETCH_API_PARAMETERS_SUCCESS";
export const FETCH_API_PARAMETERS_FAILURE = "FETCH_API_PARAMETERS_FAILURE";

export const FETCH_ALL_APIS_SUCCESS = "FETCH_ALL_APIS_SUCCESS";
export const FETCH_ALL_APIS_FAILURE = "FETCH_ALL_APIS_FAILURE";

export const CREATE_API_KEY_SUCCESS = "CREATE_API_KEY_SUCCESS";
export const CREATE_API_KEY_FAILURE = "CREATE_API_KEY_FAILURE";
export const FETCH_API_KEY_SUCCESS = "FETCH_API_KEY_SUCCESS";
export const FETCH_API_KEY_FAILURE = "FETCH_API_KEY_FAILURE";

export const DELETE_API_KEY_SUCCESS = "DELETE_API_KEY_SUCCESS";
export const DELETE_API_KEY_FAILURE = "DELETE_API_KEY_FAILURE";

export const ACTIVATE_API_KEY_SUCCESS = "ACTIVATE_API_KEY_SUCCESS";
export const ACTIVATE_API_KEY_FAILURE = "ACTIVATE_API_KEY_FAILURE";

export const DEACTIVATE_API_KEY_SUCCESS = "DEACTIVATE_API_KEY_SUCCESS";
export const DEACTIVATE_API_KEY_FAILURE = "DEACTIVATE_API_KEY_FAILURE";

export const FETCH_DAPP_SUCCESS = "FETCH_DAPP_SUCCESS";
export const FETCH_DAPP_FAILURE = "FETCH_DAPP_FAILURE";

export const FETCH_DAPP_CONTRACTS_SUCCESS = "FETCH_DAPP_CONTRACTS_SUCCESS";
export const FETCH_DAPP_CONTRACTS_FAILURE = "FETCH_DAPP_CONTRACTS_FAILURE";

export const FETCH_DAPP_TRANSACTIONS_SUCCESS = "FETCH_DAPP_TRANSACTIONS_SUCCESS";
export const FETCH_DAPP_TRANSACTIONS_FAILURE = "FETCH_DAPP_TRANSACTIONS_FAILURE";

export const FETCH_DAPP_TRANSFERS_SUCCESS = "FETCH_DAPP_TRANSFERS_SUCCESS";
export const FETCH_DAPP_TRANSFERS_FAILURE = "FETCH_DAPP_TRANSFERS_FAILURE";

export const FETCH_DAPP_TOKEN_TRANSFERS_SUCCESS = "FETCH_DAPP_TOKEN_TRANSFERS_SUCCESS";
export const FETCH_DAPP_TOKEN_TRANSFERS_FAILURE = "FETCH_DAPP_TOKEN_TRANSFERS_FAILURE";

export const FETCH_TRANSACTION_PARAMS_SUCCESS = "FETCH_TRANSACTION_PARAMS_SUCCESS";
export const FETCH_TRANSACTION_PARAMS_FAILURE = "FETCH_TRANSACTION_PARAMS_FAILURE";

export const RESET_DAPP_STATE = "RESET_DAPP_STATE";

export const RESET_SEARCH = "RESET_SEARCH";

export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";
export const FETCH_SUBSCRIPTION_MODELS_SUCCESS = "FETCH_SUBSCRIPTION_MODELS_SUCCESS";
export const FETCH_SUBSCRIPTION_MODELS_FAILURE = "FETCH_SUBSCRIPTION_MODELS_FAILURE";
export const FETCH_SUBSCRIPTION_FILTERS_SUCCESS = "FETCH_SUBSCRIPTION_FILTERS_SUCCESS";
export const FETCH_SUBSCRIPTION_FILTERS_FAILURE = "FETCH_SUBSCRIPTION_FILTERS_FAILURE";

export const ACTIVATE_SUBSCRIPTION_SUCCESS = "ACTIVATE_SUBSCRIPTION_SUCCESS";
export const ACTIVATE_SUBSCRIPTION_FAILURE = "ACTIVATE_SUBSCRIPTION_FAILURE";
export const DEACTIVATE_SUBSCRIPTION_SUCCESS = "DEACTIVATE_SUBSCRIPTION_SUCCESS";
export const DEACTIVATE_SUBSCRIPTION_FAILURE = "DEACTIVATE_SUBSCRIPTION_FAILURE";
export const REMOVE_SUBSCRIPTION_SUCCESS = "REMOVE_SUBSCRIPTION_SUCCESS";
export const REMOVE_SUBSCRIPTION_FAILURE = "REMOVE_SUBSCRIPTION_FAILURE";

export const HBAR_SELECTED = "HBAR_SELECTED";
export const TBAR_SELECTED = "TBAR_SELECTED";
export const USD_SELECTED = "USD_SELECTED";

export const HBAR_SYMBOL_SELECTED = "HBAR_SYMBOL_SELECTED";
export const TBAR_SYMBOL_SELECTED = "TBAR_SYMBOL_SELECTED";
export const USD_SYMBOL_SELECTED = "USD_SYMBOL_SELECTED";

export const FETCH_TOPICS_REQUEST = "FETCH_TOPICS_REQUEST";
export const FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS";
export const FETCH_TOPICS_FAILURE = "FETCH_TOPICS_FAILURE";

export const FETCH_TOPIC_SUCCESS = "FETCH_TOPIC_SUCCESS";
export const FETCH_TOPIC_FAILURE = "FETCH_TOPIC_FAILURE";
export const FETCH_TOPIC_MESSAGES_SUCCESS = "FETCH_TOPIC_MESSAGES_SUCCESS";
export const FETCH_TOPIC_MESSAGES_FAILURE = "FETCH_TOPIC_MESSAGES_FAILURE";

export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILURE = "FETCH_MESSAGES_FAILURE";

export const FETCH_ANNOUNCEMENTS_SUCCESS = "FETCH_ANNOUNCEMENTS_SUCCESS";
export const FETCH_ANNOUNCEMENTS_FAILURE = "FETCH_ANNOUNCEMENTS_FAILURE";

export const FETCH_PROFILE_BALANCE_SUCCESS = "FETCH_PROFILE_BALANCE_SUCCESS";
export const FETCH_PROFILE_BALANCE_FAILURE = "FETCH_PROFILE_BALANCE_FAILURE";

export const FETCH_USER_INVOICES_SUCCESS = "FETCH_USER_INVOICES_SUCCESS";
export const FETCH_USER_UNPAID_INVOICES_SUCCESS = "FETCH_USER_UNPAID_INVOICES_SUCCESS";
export const FETCH_USER_INVOICES_FAILURE = "FETCH_USER_INVOICES_FAILURE";

export const FETCH_USAGE_SUMMARY_SUCCESS = "FETCH_USAGE_SUMMARY_SUCCESS";
export const FETCH_USAGE_SUMMARY_FAILURE = "FETCH_USAGE_SUMMARY_FAILURE";

export const FETCH_PRICING_PLANS_SUCCESS = "FETCH_PRICING_PLANS_SUCCESS";
export const FETCH_PRICING_PLANS_FAILURE = "FETCH_PRICING_PLANS_FAILURE";

export const FETCH_ALL_FEATURES_SUCCESS = "FETCH_ALL_FEATURES_SUCCESS";
export const FETCH_ALL_FEATURES_FAILURE = "FETCH_ALL_FEATURES_FAILURE";

export const FETCH_ENV_CONFIGS_SUCCESS = "FETCH_ENV_CONFIGS_SUCCESS";
export const FETCH_ENV_CONFIGS_FAILURE = "FETCH_ENV_CONFIGS_FAILURE";
export const FETCH_HEDERA_TOKENS_SUCCESS = "FETCH_HEDERA_TOKENS_SUCCESS";
export const FETCH_HEDERA_TOKENS_FAILURE = "FETCH_HEDERA_TOKENS_FAILURE";

export const FETCH_TOP_TEN_TOKEN_VALUE_SUCCESS = "FETCH_TOP_TEN_TOKEN_VALUE_SUCCESS";
export const FETCH_TOP_TEN_TOKEN_VALUE_FAILURE = "FETCH_TOP_TEN_TOKEN_VALUE_FAILURE";

export const FETCH_TOP_TEN_TOKEN_VOLUME_SUCCESS = "FETCH_TOP_TEN_TOKEN_VOLUME_SUCCESS";
export const FETCH_TOP_TEN_TOKEN_VOLUME_FAILURE = "FETCH_TOP_TEN_TOKEN_VOLUME_FAILURE";

export const FETCH_TOKEN_DETAIL_SUCCESS = "FETCH_TOKEN_DETAIL_SUCCESS";
export const FETCH_TOKEN_DETAIL_FAILURE = "FETCH_TOKEN_DETAIL_FAILURE";

export const FETCH_HEDERA_TOKEN_TRANSFERS_SUCCESS = "FETCH_HEDERA_TOKEN_TRANSFERS_SUCCESS";
export const FETCH_HEDERA_TOKEN_TRANSFERS_FAILURE = "FETCH_HEDERA_TOKEN_TRANSFERS_FAILURE";

export const FETCH_SEARCH_TOKEN_TRANSFERS_SUCCESS = "FETCH_SEARCH_TOKEN_TRANSFERS_SUCCESS";
export const FETCH_SEARCH_TOKEN_TRANSFERS_FAILURE = "FETCH_SEARCH_TOKEN_TRANSFERS_FAILURE";

export const FETCH_HEDERA_TOKEN_TXN_VOLUME_LOAD = "FETCH_HEDERA_TOKEN_TXN_VOLUME_LOAD";
export const FETCH_HEDERA_TOKEN_TXN_VOLUME_SUCCESS = "FETCH_HEDERA_TOKEN_TXN_VOLUME_SUCCESS";
export const FETCH_HEDERA_TOKEN_TXN_VOLUME_FAILURE = "FETCH_HEDERA_TOKEN_TXN_VOLUME_FAILURE";

export const FETCH_HEDERA_TOKEN_TXN_VALUE_SUCCESS = "FETCH_HEDERA_TOKEN_TXN_VALUE_SUCCESS";
export const FETCH_HEDERA_TOKEN_TXN_VALUE_FAILURE = "FETCH_HEDERA_TOKEN_TXN_VALUE_FAILURE";

export const FETCH_HEDERA_TOKEN_TRANSACTIONS_SUCCESS = "FETCH_HEDERA_TOKEN_TRANSACTIONS_SUCCESS";
export const FETCH_HEDERA_TOKEN_TRANSACTIONS_FAILURE = "FETCH_HEDERA_TOKEN_TRANSACTIONS_FAILURE";

export const FETCH_HEDERA_TOKEN_BALANCES_SUCCESS = "FETCH_HEDERA_TOKEN_BALANCES_SUCCESS";
export const FETCH_HEDERA_TOKEN_BALANCES_FAILURE = "FETCH_HEDERA_TOKEN_BALANCES_FAILURE";

export const FETCH_NFTS_DETAIL_REQUEST = "FETCH_NFTS_DETAIL_REQUEST";
export const FETCH_NFTS_DETAIL_SUCCESS = "FETCH_NFTS_DETAIL_SUCCESS";
export const FETCH_NFTS_DETAIL_FAILURE = "FETCH_NFTS_DETAIL_FAILURE";

export const FETCH_NFTS_HISTORICAL_DETAIL_SUCCESS = "FETCH_NFTS_HISTORICAL_DETAIL_SUCCESS";
export const FETCH_NFTS_HISTORICAL_DETAIL_FAILURE = "FETCH_NFTS_HISTORICAL_DETAIL_FAILURE";

export const FETCH_TOP_NFTS_DETAIL_SUCCESS = "FETCH_TOP_NFTS_DETAIL_SUCCESS";
export const FETCH_TOP_NFTS_DETAIL_FAILURE = "FETCH_TOP_NFTS_DETAIL_FAILURE";

export const FETCH_LATEST_NFTS_LOAD = "FETCH_LATEST_NFTS_LOAD";
export const FETCH_LATEST_NFTS_SUCCESS = "FETCH_LATEST_NFTS_SUCCESS";
export const FETCH_LATEST_NFTS_FAILURE = "FETCH_LATEST_NFTS_FAILURE";

export const FETCH_LATEST_COLLECTION_NFTS_REQUEST = "FETCH_LATEST_COLLECTION_NFTS_REQUEST";
export const FETCH_LATEST_COLLECTION_NFTS_SUCCESS = "FETCH_LATEST_COLLECTION_NFTS_SUCCESS";
export const FETCH_LATEST_COLLECTION_NFTS_FAILURE = "FETCH_LATEST_COLLECTION_NFTS_FAILURE";

export const FETCH_ACTIVE_COLLECTION_NFTS_REQUEST = "FETCH_ACTIVE_COLLECTION_NFTS_REQUEST";
export const FETCH_ACTIVE_COLLECTION_NFTS_SUCCESS = "FETCH_ACTIVE_COLLECTION_NFTS_SUCCESS";
export const FETCH_ACTIVE_COLLECTION_NFTS_FAILURE = "FETCH_ACTIVE_COLLECTION_NFTS_FAILURE";

export const FETCH_SEARCH_NFTS_REQUEST = "FETCH_SEARCH_NFTS_REQUEST";
export const FETCH_SEARCH_NFTS_SUCCESS = "FETCH_SEARCH_NFTS_SUCCESS";
export const FETCH_SEARCH_NFTS_FAILURE = "FETCH_SEARCH_NFTS_FAILURE";

// Charts
export const FETCH_ALL_TRANSACTION_CHART_LOAD = "FETCH_ALL_TRANSACTION_CHART_LOAD";
export const FETCH_ALL_TRANSACTION_CHART_SUCCESS = "FETCH_ALL_TRANSACTION_CHART_SUCCESS";
export const FETCH_ALL_TRANSACTION_CHART_FAILURE = "FETCH_ALL_TRANSACTION_CHART_FAILURE";

export const FETCH_TRANSACTION_TYPE_DIMENSION_CHART_LOAD = "FETCH_TRANSACTION_TYPE_DIMENSION_CHART_LOAD";
export const FETCH_TRANSACTION_TYPE_DIMENSION_CHART_SUCCESS = "FETCH_TRANSACTION_TYPE_DIMENSION_CHART_SUCCESS";
export const FETCH_TRANSACTION_TYPE_DIMENSION_CHART_FAILURE = "FETCH_TRANSACTION_TYPE_DIMENSION_CHART_FAILURE";

export const FETCH_ACCOUNTS_CREATED_CHART_LOAD = "FETCH_ACCOUNTS_CREATED_CHART_LOAD";
export const FETCH_ACCOUNTS_CREATED_CHART_SUCCESS = "FETCH_ACCOUNTS_CREATED_CHART_SUCCESS";
export const FETCH_ACCOUNTS_CREATED_CHART_FAILURE = "FETCH_ACCOUNTS_CREATED_CHART_FAILURE";

export const FETCH_NFT_MINTED_CHART_LOAD = "FETCH_NFT_MINTED_CHART_LOAD";
export const FETCH_NFT_MINTED_CHART_SUCCESS = "FETCH_NFT_MINTED_CHART_SUCCESS";
export const FETCH_NFT_MINTED_CHART_FAILURE = "FETCH_NFT_MINTED_CHART_FAILURE";

export const FETCH_NFT_TRANSFER_CHART_LOAD = "FETCH_NFT_TRANSFER_CHART_LOAD";
export const FETCH_NFT_TRANSFER_CHART_SUCCESS = "FETCH_NFT_TRANSFER_CHART_SUCCESS";
export const FETCH_NFT_TRANSFER_CHART_FAILURE = "FETCH_NFT_TRANSFER_CHART_FAILURE";

export const FETCH_TOKEN_TRANSFER_CHART_LOAD = "FETCH_TOKEN_TRANSFER_CHART_LOAD";
export const FETCH_TOKEN_TRANSFER_CHART_SUCCESS = "FETCH_TOKEN_TRANSFER_CHART_SUCCESS";
export const FETCH_TOKEN_TRANSFER_CHART_FAILURE = "FETCH_TOKEN_TRANSFER_CHART_FAILURE";

export const FETCH_TOKEN_MINTED_CHART_LOAD = "FETCH_TOKEN_MINTED_CHART_LOAD";
export const FETCH_TOKEN_MINTED_CHART_SUCCESS = "FETCH_TOKEN_MINTED_CHART_SUCCESS";
export const FETCH_TOKEN_MINTED_CHART_FAILURE = "FETCH_TOKEN_MINTED_CHART_FAILURE";
