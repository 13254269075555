import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { getTime } from 'date-fns';

const periodOptions = [
	{ key: '1H', text: '1 Hour', value: '1H' },
	{ key: 'TODAY', text: 'Today', value: 'TODAY' },
	{ key: '1D', text: 'Last 24 Hours', value: '1D' },
	{ key: '2D', text: 'Last 48 Hours', value: '2D' }
];

export default class AdvSearchForm extends Component {
	handleSearch = (e) => {
		e.preventDefault();
		this.props.onSearch();
	};

	render() {
		const { q, accountFrom, accountTo, startDate, endDate, amountFrom, amountTo, contractID, period } = this.props;
		const { onChange, onReset, isAdvanced } = this.props;
		return (
			<Form onSubmit={this.handleSearch}>
				<Form.Field>
					<Form.Input
						fluid
						label="Keyword(s)"
						name="q"
						value={q}
						onChange={onChange}
						action={{ icon: 'search', onClick: this.handleSearch }}
					/>
				</Form.Field>
				<div style={{fontSize: '12px'}}>
					<span>To access historical HCS transactions older than three days, please contact us at <a href="mailto:info@dragonglass.me">info@dragonglass.me</a></span>
				</div>
				{isAdvanced && (
					<React.Fragment>
						<Form.Group widths="equal">
							<Form.Input
								fluid
								label="Payer Account ID"
								name="accountFrom"
								value={accountFrom}
								onChange={onChange}
								placeholder="e.g. 0.0.3"
							/>
							<Form.Input
								fluid
								label="Recipient Account ID"
								name="accountTo"
								value={accountTo}
								onChange={onChange}
								placeholder="e.g. 0.0.3208"
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								fluid
								label="Contract Account ID"
								name="contractID"
								value={contractID}
								onChange={onChange}
								placeholder="e.g. 0.0.9054"
							/>
							<Form.Select
								fluid
								label="Time Period"
								name="period"
								value={period}
								options={periodOptions}
								placeholder="-Select-"
								onChange={(e, data) => {
									onChange(e, data);
									onChange(null, { name: 'startDate', value: null });
									onChange(null, { name: 'endDate', value: null });
								}}
								clearable
							/>
						</Form.Group>

						<Form.Field style={{ margin: '0 0 .28571429rem 0' }}>
							<label>Date Range</label>
						</Form.Field>
						<Form.Group widths="equal">
							<Form.Field>
								<DatePicker
									name="startDate"
									selected={startDate ? new Date(startDate) : null}
									onChange={(date) => {
										onChange(null, { name: 'startDate', value: date ? getTime(date) : '' });
										onChange(null, { name: 'period', value: null });
									}}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="d MMM yyyy, h:mm aa"
									placeholderText="Date From"
									minDate={new Date(2018, 8, 1)}
									maxDate={new Date()}
									autoComplete="off"
								/>
							</Form.Field>
							<Form.Field>
								<DatePicker
									name="endDate"
									selected={endDate ? new Date(endDate) : null}
									onChange={(date) => {
										onChange(null, { name: 'endDate', value: date ? getTime(date) : '' });
										onChange(null, { name: 'period', value: null });
									}}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="d MMM yyyy, h:mm aa"
									placeholderText="Date To"
									minDate={startDate ? new Date(startDate) : null}
									maxDate={new Date()}
									autoComplete="off"
								/>
							</Form.Field>
						</Form.Group>
						<Form.Field style={{ margin: '0 0 .28571429rem 0' }}>
							<label>Amount Range</label>
						</Form.Field>
						<Form.Group widths="equal">
							<Form.Input
								fluid
								type="number"
								placeholder="Min Amount"
								name="amountFrom"
								value={amountFrom}
								onChange={onChange}
							/>
							<Form.Input
								fluid
								type="number"
								placeholder="Max Amount"
								name="amountTo"
								value={amountTo}
								onChange={onChange}
							/>
						</Form.Group>
						<Button primary onClick={this.handleSearch}>
							SEARCH
						</Button>
						<Button basic primary onClick={() => onReset()}>
							RESET
						</Button>
					</React.Fragment>
				)}
			</Form>
		);
	}
}
