import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import Panel from './shared/Panel';
import CodeSnippet from './shared/CodeSnippet';
import { Divider } from 'semantic-ui-react';
// import { getAllApis } from '../redux/reducers';
// import { getCategories } from '../redux/reducers';
import ApiSingleDisplay from './APISingleDisplay';

import DeploymentDescription from './DeploymentDescription';

import '../animate.css';

const DeploymentDescriptionPanel = (props) => {

  const contentToDisplay = <div><DeploymentDescription /></div>;

  return (
    <Panel
      title={
        <Fragment>
          {/* {props.title} */}
          {/* <div style={{marginTop: "20px", fontSize: "70%"}}><span className="text-red">* </span>{note1}<br/>&nbsp;&nbsp;&nbsp;{note2}</div>
          <CodeSnippet style={{padding: '0 0 0 10px', marginBottom: '-15px'}}>{snippet}</CodeSnippet> */}
        </Fragment>
      }
      description=""
    >
      {/* <Divider /> */}
      <div>
        { contentToDisplay }
      </div>
    </Panel>
  );
}

// const mapStateToProps = (state) => ({
//   apis: getAllApis(state),
//   categories: getCategories(state)
// });

// export default connect(mapStateToProps, actions)(DeploymentDescriptionPanel);

export default DeploymentDescriptionPanel;