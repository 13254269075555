import { FETCH_TOPICS_REQUEST, FETCH_TOPICS_SUCCESS, FETCH_TOPICS_FAILURE, RESET_SEARCH } from '../types';

const initialState = {
	isLoading: false,
	filters: null,
	results: [],
	totalCount: 0
};

export default function searchedTopics(state = initialState, action) {
	switch (action.type) {
		case FETCH_TOPICS_REQUEST:
			return { ...state, isLoading: true };
		case FETCH_TOPICS_SUCCESS:
			console.log("IN REDUCER, FETCH TOPICS: ", action.response.data, action.response.totalCount)
			return {
				...state,
				isLoading: false,
				results: action.response.data || [],
				filters: action.filtered === true ? state.filters : action.response.facets,
				totalCount: action.response.totalCount
			};
		case FETCH_TOPICS_FAILURE:
			return { ...initialState };
		case RESET_SEARCH:
			return { ...initialState };
		default:
			return state;
	}
}

export const getSearchedTopics = (state) => state.results;
export const getSearchedTopicsCount = (state) => state.totalCount;
export const getSearchedTopicsFilters = (state) => state.filters;
