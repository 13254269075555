import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Grid, Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Panel from '../shared/Panel';
import ProfileBalance from '../containers/ProfileBalance';
import StatementHistory from '../containers/StatementHistory';
import PaymentHistory from '../containers/PaymentHistory';
import Info from '../shared/Info';

import ApiKeyPanel from '../ApiKeyPanel';
import DappCreatePanel from '../DappCreatePanel';
import SubscriptionPanel from '../SubscriptionPanel';
import UserAccounts from '../containers/UserAccounts';

const ProfileHeader = (props) => {
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [showCreate, setShowCreate] = useState(true);
	const [showSubscribe, setShowSubscribe] = useState(true);

	const handleTabChange = (e, { activeIndex }) => {
		setActiveTabIndex(activeIndex);
	};
	const panes = () => [
		{
			menuItem: {
				key: 'profile',
				className: "to-right",
				content: (<span>	PROFILE </span>)
			},
			render: () => (
				<Tab.Pane as="div">
					<Grid>
						<Grid.Row>
							<Grid.Column width={16}>
								<ProfileBalance />
							</Grid.Column>
							<Grid.Column width={16}>
								<div className="api-key-panel">
									<Panel className="api-key-panel"
										title={
											<React.Fragment>
												MY SUBSCRIBED ACCOUNTS <Info id="subscribedAccounts" />
											</React.Fragment>
										}
									>
										<UserAccounts />
									</Panel>
								</div>
							</Grid.Column>
							<Grid.Column width={16}>
								<div className="api-key-panel">
									<ApiKeyPanel />
								</div>
							</Grid.Column>
							<Grid.Column width={16}>
								{showSubscribe && <div className="api-key-panel">
									<SubscriptionPanel />
								</div>}
							</Grid.Column>
							<Grid.Column width={16}>
								{showCreate && <div className="api-key-panel">
									<DappCreatePanel />
								</div>}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'statements',
				className: "to-right",
				content: (<span>STATEMENTS</span>)
			},
			render: () => (
				<Tab.Pane as="div">
					<Grid>
						<Grid.Row>
							<Grid.Column width={16}>
								<ProfileBalance />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={16}>
								<Panel title="STATEMENT HISTORY">
									<StatementHistory />
								</Panel>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'payments',
				className: "to-right",
				content: (<span>PAYMENTS</span>)
			},
			render: () => (
				<Tab.Pane as="div">
					<Grid>
						<Grid.Row>
							<Grid.Column width={16}>
								<ProfileBalance />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="payment-history-wrapper">
							<Grid.Column width={16}>
								<Panel title="PAYMENTS">
									<PaymentHistory />
								</Panel>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Tab.Pane>
			)
		}
	];

	return (
		<Grid.Column computer={12} tablet={10} mobile={16}>
			<Tab
				menu={{ stackable: true, secondary: true, pointing: true, tabular: true }}
				panes={panes()}
				renderActiveOnly={true}
				onTabChange={handleTabChange}
				menuPosition="right"
			/>
		</Grid.Column>
	);
}

export default withRouter(connect(null, actions)(ProfileHeader));
