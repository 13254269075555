import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import {getNftsHistoricalDetail, getNftsHistoricalDetailCount } from '../redux/reducers';
import NftHistoricalTable from './NftHistoricalTable';
import { Grid } from 'semantic-ui-react';
import Panel from "./shared/Panel";
import Filter from './shared/Filter';
import Info from './shared/Info';

class NftHistoricalList extends Component {
	constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 10,
      sort: '',
	  order: 'desc',
      page: 1,
	  q: ''
    };
  }

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.accountId !== prevProps.accountId || this.props.filter !== prevProps.filter) {
			this.setState(
				{
					offset: 0,
					page: 1
				},
				() => this.fetchData()
			);
		}
	}

	fetchHistoryData = () => {
		const { fetchNftsHistoricalDetails, accountId, transactionID, tokenId } = this.props;
		const nftHistoryParams = {"account": accountId, transactionId: transactionID, tokenId: tokenId, ...this.state };
		fetchNftsHistoricalDetails(nftHistoryParams);
  	};

	fetchData() {
		this.fetchHistoryData();
	}

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchHistoryData());
	};

	onSubmitFilter = (value) => {
		this.setState({q: value}, () => this.fetchHistoryData())
	};

	render() {
		const { nftHistoricalData, totalCount, isAccountPage, accountId, showArrow } = this.props;
		const { limit, offset } = this.state;

			return (
			 <Grid padded stackable>
				 <Grid.Column>
					<Panel
					title={ <React.Fragment><Info id="nftsTransfers" /></React.Fragment> }
					toolbar={ <React.Fragment><Filter onSubmitFilter={this.onSubmitFilter}/></React.Fragment> }
					>
						<div id="nfts-transactions-table">
							<NftHistoricalTable
								nftHistoricalData={nftHistoricalData}
								limit={limit}
								offset={offset}
								sort={this.state.sort}
								order={this.state.order}
								totalCount={totalCount}
								loadData={this.loadData}
								isAccountPage={isAccountPage}
								accountId={accountId}
								showArrow={showArrow}
								{...this.props}
							/>
						</div>
					</Panel>
				 </Grid.Column>
			   </Grid>
			  );
	}
}
const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
	accountId: accountId,
	nftHistoricalData : getNftsHistoricalDetail(state),
	totalCount: getNftsHistoricalDetailCount(state),
});

NftHistoricalList = withRouter(connect(mapStateToProps, actions)(NftHistoricalList));

export default NftHistoricalList;

