import React, { Fragment } from 'react';
import { Grid, Header, Table, Icon } from 'semantic-ui-react';
// import Money from './shared/Money';
import Item from './shared/Item';
import Number from './shared/Number';
import { fromDate } from '../utils';
import Money from './shared/Money';
import '../assets/css/components/tokenDetails.scss';
import Panel from './shared/Panel';
import CodeSnippet from './shared/CodeSnippet';
import Info from './shared/Info';
import _ from 'lodash';

export default function AccountSummaryTable({ account = {}, onShowKey, onHideKey, showKey }) {
	const { id, isNodeAccount, createdOn, expiryDate, memo, balance, numRecentTxns, stakedNodeId, stakedAccountId, stakeIdStartPeriod } = account;

	return (
		<Fragment>
			{
				account ?
				<>
					<Grid stackable columns={2}>
						<Grid.Row>
							<Grid.Column width={8} height={10}>
								<Panel
									title={
										<Fragment>
											<div style={{ marginLeft: "0px" }}>{isNodeAccount ? 'NODE' : ''} ACCOUNT SUMMARY <Info id="accountSummary" /></div>
											<Header style={{marginTop:'5px', borderBottom: '2px solid #ccccccde', paddingBottom: '10px'}} as="h2">{id}</Header>
										</Fragment>
									}
								>
									<Table basic="very">
										<Table.Body>
											{
												account
												?	<Table.Row>
														<Table.Cell>Current Balance:</Table.Cell>
														<Table.Cell className="cell-value"><span style={{fontWeight: 700}}><Number>{balance}</Number> ℏ </span>(<Number>{numRecentTxns}</Number> txns last 30 days)</Table.Cell>
													</Table.Row>
												: ''
											}
											{
												createdOn
												?
													<Table.Row>
														<Table.Cell>Created Date:</Table.Cell>
														<Table.Cell className="cell-value" style={{fontWeight: 700}}>{createdOn ? fromDate(createdOn) : ''}</Table.Cell>
													</Table.Row>
												: ''
											}
											{
												memo ?
												<Table.Row>
													<Table.Cell><span>Memo:</span></Table.Cell>
													<Table.Cell className="cell-value" style={{fontWeight: 700}}>{memo}</Table.Cell>
												</Table.Row>
												: ''
											}
											{
												account && account.publicKey && !_.isEmpty(account.publicKey)
												?
													<Table.Row>
													<Table.Cell><span>Public Key:</span></Table.Cell>
														<Table.Cell>
															<Item
															text=""
															sub={<Icon name="eye" 
															onClick={onShowKey}
															className="clickable" />}
															/>
														</Table.Cell>
													</Table.Row>
												: ''
											}
										</Table.Body>
									</Table>
								</Panel>
							</Grid.Column>
							<Grid.Column width={8}>
								<Panel
								title={
									<Fragment>
										<div style={{ fontSize: '18px', padding: '15px' }}>STAKING SUMMARY</div>
										<hr style={{height: '2px', backgroundColor: '#ccccccde' ,border: 'none'}}/>
									</Fragment>
								}
								>
									
									<Table basic="very">
										<Table.Body>
											{
												stakedNodeId
												? 	<Table.Row>
														<Table.Cell>Staked Node Id</Table.Cell>
														<Table.Cell style={{fontWeight: 700}}>{stakedNodeId}</Table.Cell>
													</Table.Row>
												: ''
											}
											{
												stakedAccountId && stakedAccountId !== '0.0.0'
												?	<Table.Row>
														<Table.Cell>Staked Account Id</Table.Cell>
														<Table.Cell style={{fontWeight: 700}}>{stakedAccountId && stakedAccountId !== '0.0.0' ? stakedAccountId : ''}</Table.Cell>
													</Table.Row>
												: ''
											}
											{
												stakeIdStartPeriod
												? 	<Table.Row>
														<Table.Cell>Stake Start Period</Table.Cell>
														<Table.Cell style={{fontWeight: 700}}>{stakeIdStartPeriod ? fromDate(stakeIdStartPeriod) : ''}</Table.Cell>
													</Table.Row> 
												: ''
											}
											{
												account.stake
												?	<Table.Row>
														<Table.Cell>Stake</Table.Cell>
														<Table.Cell style={{fontWeight: 700}}><Money>{account.stake || 0}</Money></Table.Cell>
													</Table.Row> 
												: ''
											}
											{
												account.stake_rewarded
												? 	<Table.Row>
														<Table.Cell>Stake Rewarded</Table.Cell>
														<Table.Cell style={{fontWeight: 700}}><Money>{account.stake_rewarded || 0}</Money></Table.Cell>
													</Table.Row> 
												: ''
											}
											{
												account.stake_not_rewarded
												?	<Table.Row>
														<Table.Cell>Stake Not Rewarded</Table.Cell>
														<Table.Cell style={{fontWeight: 700}}><Money>{account.stake_not_rewarded || 0}</Money></Table.Cell>
													</Table.Row>
												: ''
											}
											{
												account.min_stake
												?	<Table.Row>
														<Table.Cell>Min Stake</Table.Cell>
														<Table.Cell style={{fontWeight: 700}}><Money>{account.min_stake || 0}</Money></Table.Cell>
													</Table.Row>
												: ''
											}
											{
												account.max_stake
												? <Table.Row>
														<Table.Cell>Max Stake</Table.Cell>
														<Table.Cell style={{fontWeight: 700}}><Money>{account.max_stake || 0}</Money></Table.Cell>
													</Table.Row>
												: ''
											}
										</Table.Body>
									</Table>
								</Panel>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					{/* <Grid stackable columns={1}>
						<Grid.Row>
							<Grid.Column>
								<Panel
									title={
										<Fragment>
											<div style={{ marginLeft: "0px" }}>Keys</div>
										</Fragment>
									}
									description="">
									<Grid relaxed="very" stackable>
										<Grid.Column width="16">
											<Table basic="very">
											<Table.Body>
												<Table.Row>
												<Table.Cell>
													<Item
													text="Public Key"
													sub={<Icon name="eye" 
													onClick={onShowKey}
													className="clickable" />}
													/>
												</Table.Cell>
												</Table.Row>
											</Table.Body>
											</Table>
										</Grid.Column>
										</Grid>
								</Panel>
							</Grid.Column>
						</Grid.Row>
					</Grid> */}
					{account && account.publicKey &&
					showKey && <CodeSnippet code={account.publicKey} onClose={onHideKey} title="Public Key(s)" />}
				</>
				: ''
			}
		</Fragment>
	);
}
