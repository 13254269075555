import React, { Component } from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import TransferList from '../TransferList';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { withRouter } from 'react-router-dom';

class Transfers extends Component {
	state = {
		open: false,
		transfers: []
	};

	onOpen = (e) => {
		const { fetchAccountTransaction, txId, acctId, accountId } = this.props;
		let accId =  acctId || accountId;
		fetchAccountTransaction(txId, accId).then(({ transfers }) => {
			this.setState({ transfers, open: true });
		});
	};

	onClose = () => this.setState({ open: false });

	render() {
		const { transfers = [], open } = this.state;
		const { hidePriceSymbol, tokenTransfer, tokenDecimals } = this.props;
		return (
			<Popup
				open={open}
				content={transfers.length > 0 ? <TransferList transfers={transfers} hidePriceSymbol={hidePriceSymbol} tokenTransfer={tokenTransfer} tokenDecimals={tokenDecimals} /> : 'N/A'}
				trigger={<Icon className="clickable" name="list" color="blue" />}
				onOpen={this.onOpen}
				onClose={this.onClose}
				position="right center"
			/>
		);
	}
}
const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
	acctId: accountId
});

Transfers = withRouter(connect(mapStateToProps, actions)(Transfers));

export default Transfers;
