import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Container, Form, Button, Tab, Input, Modal, Message } from 'semantic-ui-react';
import Page from '../components/shared/Page';
import NftHistoricalList from '../components/NftHistoricalList';
import Info from '../components/shared/Info';
import * as actions from '../redux/actions';
import LatestNfts from "../organisms/LatestNfts";
import SearchNfts from "../organisms/SearchNfts";
import LatestCollections from "../organisms/LatestCollections";
import ActiveCollections from "../organisms/ActiveCollections";
import { Helmet } from 'react-helmet';
import CustomBreadcrumb from '../atoms/CustomBreadcrumb';
import AdvNFTSearchForm from "../organisms/forms/AdvNFTSearchForm";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { isMobile } from "../utils";

const styles = {
	containerStyle: {
		marginTop: '2%',
	}
}
const NftExplorerPage = (props) => {
	const history = useHistory();
	const location = useLocation();

	let paramsString = '';
	const queryParams = new URLSearchParams(location.search);
	queryParams.forEach((value, key) => {
		if (value !== '') {
			paramsString += `${key}=${value}&`
		}
	});
	const paramSearchText = queryParams.get('searchText');

	const [originalPanes, setOriginalPanes] = useState([
		{
			menuItem: {
				key: 'latestNFTs',
				content: (
					<span>
						Latest NFTs
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<LatestNfts />
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'latestCollection',
				content: (
					<span>
						Latest Collection
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<LatestCollections />
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'activeCollections',
				content: (
					<span>
						Active Collections <Info id="activeCollections" />
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<ActiveCollections />
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'latestTransfers',
				content: (
					<span>
						Latest Transfers
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<NftHistoricalList showArrow={true}/>
				</Tab.Pane>
			)
		},
	]);

	const [panes, setPanes] = useState(originalPanes);
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [searchText, setSearchText] = useState(paramSearchText || '');
	const [isAdvanced, setIsAdvanced] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [advanceSearchParams, setAdvanceSearchParams] = useState({
		collectionName: queryParams.get('collectionName') || '',
		nftName: queryParams.get('nftName') || '',
		collectionCreationDateFrom: Number(queryParams.get('collectionCreationDateFrom')) || '',
		collectionCreationDateTo: Number(queryParams.get('collectionCreationDateTo')) || '',
		nftMintDateFrom: Number(queryParams.get('nftMintDateFrom')) || '',
		nftMintDateto: Number(queryParams.get('nftMintDateto')) || '',
		owner: queryParams.get('owner') || '',
		nftCreator: queryParams.get('nftCreator') || ''
	});

    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

    useEffect(() => {
		if (!(searchText || isAdvanceSearchParamsInURL())) {
			const paramValue = queryParams.get('activeTab');
			if (paramValue && paramValue != 4) {
				setActiveTabIndex(paramValue);
			} else {
				setQueryParamToUrl('activeTab', 0);
				setQueryParamToUrl('searchText', searchText);
				setActiveTabIndex(0);
				setPanes([...originalPanes]);
			}
		} else {
			const paramActiveTabValue = queryParams.get('activeTab');
			if (paramActiveTabValue && paramActiveTabValue == 4
				) {
				handleSearch(isAdvanceSearchParamsInURL())
			}
		}
	 }, [searchText]);

	const isAdvanceSearchParamsInURL = () => {
		let isAdvanceSearchParamsInURLFlag = false;
		if (advanceSearchParams) {
			Object.keys(advanceSearchParams).forEach((key) => {
				if (advanceSearchParams[key]) {
					isAdvanceSearchParamsInURLFlag = true;
					return isAdvanceSearchParamsInURLFlag;
				}
			});
		}
		if (isAdvanceSearchParamsInURLFlag) {
			setIsAdvanced(true)
		 }
		return isAdvanceSearchParamsInURLFlag;
	}

	const handleSearch = (bypassSearchText) => {
		if (searchText || bypassSearchText) {
			const search = {
				menuItem: {
					key: 'searchNFTs',
					content: (
						<span>
							Search Results
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<SearchNfts isAdvanced={ isAdvanceSearchParamsInURL() ? true : isAdvanced} searchText={searchText} advanceSearchParams={advanceSearchParams} selectedTab={4}/>
					</Tab.Pane>
				)
			}
			setPanes([...originalPanes, search]);
			setQueryParamToUrl('searchText', searchText);
			setQueryParamToUrl('activeTab', 4);
			setActiveTabIndex(4);
			setAdvanceSearchQueryParamToUrl();
		}
	}

	const handleAdvSearch = () => {
		const { collectionName, owner, nftCreator, nftMintDateFrom, nftMintDateto, nftName, collectionCreationDateFrom, collectionCreationDateTo} = advanceSearchParams
		if (!(collectionName || owner || nftCreator || nftMintDateFrom || nftMintDateto || nftName || collectionCreationDateFrom || collectionCreationDateTo)) {
			return;
		};
		closeModal();
		handleSearch(true)
	}

	const handleTabChange = (e, { activeIndex }) => {
		setQueryParamToUrl('activeTab', activeIndex);
		setActiveTabIndex(activeIndex);
	}

	const setQueryParamToUrl = (key, value) => {
		queryParams.set(key, value);
		history.push({ search: queryParams.toString() });
	}

	const setAdvanceSearchQueryParamToUrl = (advanceSearchParamsParameters) => {
		const advanceSearchParamsTemp = advanceSearchParamsParameters || advanceSearchParams;
		advanceSearchParamsTemp && Object.keys(advanceSearchParamsTemp).forEach((key) => {
			if (advanceSearchParamsTemp[key]) {
				queryParams.set(key, advanceSearchParamsTemp[key]);
			} else {
				queryParams.delete(key);
			}
		});
		history.push({ search: queryParams.toString() });
	}

	const breadCrumbOptions = [
		{ title: 'NFT Explorer', link: '/nft-explorer', isActive: true },
	]
	const handleToggleChange = () => {
		setIsAdvanced(!isAdvanced)
		openModal();
	}

	const openModal = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setIsAdvanced(false)
		setModalOpen(false);
	};

	const onReset = () => {
		const clearValue = {
			collectionName: '',
			nftName: '',
			collectionCreationDateFrom: '',
			collectionCreationDateTo: '',
			nftMintDateFrom: '',
			nftMintDateto: '',
			owner: '',
			nftCreator: ''
		}
		setAdvanceSearchParams(clearValue);
		setActiveTabIndex(0);
		setQueryParamToUrl('activeTab', 0);
		setAdvanceSearchQueryParamToUrl(clearValue);
	};

	const handleChange = (e, { name, value }) => {
		const updatedValue = {...advanceSearchParams, [name]: value};
		setAdvanceSearchParams(updatedValue);
	}

	const AdvancedSearchModal = () => {
		return (
			<div>
			  <Modal open={modalOpen} onClose={closeModal}>
				<Modal.Header>Advanced NFT Search</Modal.Header>
				<Modal.Content>
				  {<AdvNFTSearchForm onChange={handleChange} advanceSearchParams={advanceSearchParams} setAdvanceSearchParams={setAdvanceSearchParams}/>}
				</Modal.Content>
				<Modal.Actions>
				  <Button primary onClick={handleAdvSearch}>
					Search
				  </Button>
				  <Button basic primary onClick={() => onReset()}>
					RESET
				</Button>
				  <Button negative onClick={closeModal}>
					Close
				  </Button>
				</Modal.Actions>
			  </Modal>
			</div>
		  );
	}

	return (
		<Page>
			<Helmet>
				<title>Dragon Glass - NFT Explorer</title>
			</Helmet>
			{/* <CustomBreadcrumb options={breadCrumbOptions}/> */}
			<Container fluid style={styles.containerStyle} className="w-98">
				<Grid stackable style={{padding: '10px'}}>
					<Grid.Row>
						<Grid.Column width={16}>
							<Message className={"nft-form-box"}>
								<Message.Header> 
									<Button primary content="Click Here" size="tiny" onClick={() => history.push('/nft-marketplace')} />
									To showcase your NFT with its listed marketplace
									</Message.Header>
							</Message>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable style={{padding: '10px'}}>
					<Grid.Row className="mobile-only">
						<Grid.Column width={16}>
							<Form>
								<Form.Field style={{margin: '0px'}}>
									<Form.Input
										fluid
										label=''
										name="searchText"
										value={searchText}
										onChange={(e) => setSearchText(e.target.value)}
										placeholder="Search NFTs"
										action={
											<div className="ui  input-button__wrapper">
												<Button primary content="search" size="tiny" onClick={() => handleSearch()} />
											</div>
										}
									/>
								</Form.Field>
							</Form>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="mobile-only">
						<Link to={`/nft-explorer?${paramsString}`} style={{width: '28%', textAlign: 'end', alignSelf: 'center'}} onClick={() => handleToggleChange()}>Advance Search</Link>
					</Grid.Row>
					<Grid.Row>
						{
							!isMobile() && (
								<div className="nav-desktop computer-only tablet-only"
								style={{position: 'absolute', right: '25px', top: '-6px', width: '30%',
								display: 'flex', justifyContent: 'end'
								}}>
											<Input
												style={{width: '100%'}}
												fluid
												name="searchText"
												value={searchText}
												onChange={(e) => setSearchText(e.target.value)}
												placeholder="Search NFTs"
												action={
													<div className="ui  input-button__wrapper">
														<Button primary content="search" size="tiny" onClick={() => handleSearch()} />
													</div>
												}
											/>
											<Link to={`/nft-explorer?${paramsString}`} style={{width: '28%', textAlign: 'end', alignSelf: 'center'}} onClick={() => handleToggleChange()}>Advance Search</Link>
								</div>
							)
						}
						{
							isAdvanced && (AdvancedSearchModal())
						}
						<Tab
							className='explorerTabs'
							style={{width: '100%'}}
							menu={{ stackable: true, secondary: true, pointing: true }}
							panes={panes}
							renderActiveOnly={true}
							onTabChange={handleTabChange}
							activeTabIndex={activeTabIndex}
							activeIndex={activeTabIndex}
						/>
					</Grid.Row>
				</Grid>
			</Container>
		</Page>
	);
}

const mapStateToProps = (state) => ({
	// nftsData: getTopNftsData(state),
});

export default connect(mapStateToProps, actions)(NftExplorerPage);