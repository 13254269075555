import React,  { useRef, useEffect, useState } from 'react';
import { Image, Embed, Icon } from "semantic-ui-react";
import NFTStockVideo from '../assets/images/NFT-Stock-Video.png';
import NFTStockImage from '../assets/images/NFT-STOCK-IMAGE.png';
import VideoThumb from 'react-video-thumbnail';
// import VideoPlayer from 'simple-react-video-thumbnail'

const DisplayContent = ({mimeType, src, alt, size, playVideo, styles, height, isThumbnail, thumbnailwidth, thumbnailheight }) => {
	const innerWidth = window.innerWidth;
	const YoutubeEmbed = (url) => {
		return (
		  <Embed
			id={url} // Replace this with the actual YouTube video ID you want to embed
			source='youtube'
			active={true}
		  />
		);
	  };

	  const ImageEmbed = (src, alt, style) => {
		return (
			<Image 
			onError={(e) => {
				e.target.src = NFTStockImage;
			}}
			style={{...style}} alt={alt} src={src} />
		);
	  };

	  const videoThumbnail = (src, style) => {
		// console.log('thumbnailwidth', thumbnailwidth);
		// console.log('thumbnailheight', thumbnailheight);
		return (
			<VideoThumb
			// <VideoPlayer
				// style={{...style}}
				videoUrl={src}
				snapshotAtTime={1}
				width={thumbnailwidth ? thumbnailwidth : 380}
				height={thumbnailheight ? thumbnailheight : 380}
				onError={(e) => {
					e.target.src = NFTStockVideo;
				}}
			/>
		)
	  }

	  const VideoEmbed = (src, alt, style) => {

		if (!playVideo) {
			if (isThumbnail) {
				return videoThumbnail(src, style);
			}
			return ImageEmbed(NFTStockVideo, alt, style)
		}

		if (src.includes('youtu.be') || src.includes('youtube.com')) {
			let id = src && src.split('youtu.be/')[1];
			if (!id && src.includes('youtube.com')) {
				id = src && src.split('v=')[1];
			}
			return YoutubeEmbed(id)
		}

		return (
			<Embed
				style={{...style}}
				placeholder={NFTStockVideo}
				url={src}
				active={true}
			/>
		);
	  };
	  
	if (src && mimeType.includes('image')) {
		const style = styles ? styles : {margin: innerWidth >= 2000 ? '' : '0 auto', width: '50%'};
		if (size == 'xl') {
			style['width'] =  '100%';
			style['maxWidth'] =  innerWidth >= 2000 ? '1800px' : '1000px';
		}
		if (size == 'large') {
			style['width'] =  '50%'
		}
		if (size == 'medium') {
			style['width'] =  '20%'
		}
		if (size == 'small') {
			style['width'] =  '15%'
		}

		return ImageEmbed(src, alt, style)
	}

	if (src && mimeType.includes('BASE64')) {
		const style = styles ? styles : {margin: innerWidth >= 2000 ? '' : '0 auto', width: '50%'};
		if (size == 'xl') {
			style['width'] =  '100%';
			style['maxWidth'] =  '1000px';
		}
		if (size == 'large') {
			style['width'] =  '50%'
		}
		if (size == 'medium') {
			style['width'] =  '20%'
		}
		if (size == 'small') {
			style['width'] =  '15%'
		}
		if (src.includes('.mp4')) {
			return VideoEmbed(src, alt, style)
		}
		return ImageEmbed(src, alt, style)
	}

	if (src && mimeType.includes('video')) {
		const style = styles ? styles : {margin: innerWidth >= 2000 ? '' : '0 auto', width: '100%'};
		if (size == 'xl') {
			style['width'] =  '100%'
			style['maxWidth'] =  '1000px';
		}
		if (size == 'large') {
			style['width'] =  '50%'
		}
		if (size == 'medium') {
			style['width'] =  '20%'
		}
		if (size == 'small') {
			style['width'] =  '15%'
		}
		if (height) {
			style['height'] =  '100%'
		}

		return VideoEmbed(src, alt, style)
	}

	return ImageEmbed(src, alt, styles);
	// <Image style={{margin: '0 auto', width: '50%'}} alt={alt} src={src} />
};

export default DisplayContent;
