import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import Number from './shared/Number';
import eye from '../assets/images/eye@2x.png';
import DateCell from "./shared/DateCell";
import ArrowIcon from '../assets/Icons/ArrowIcon';

const NftsHistoricRow = ({ nftData, isAccountPage, accountId, showArrow, isTransactionPage }) => {
	let arrowIconOption = {}
	if (nftData.sender !== accountId) {
		arrowIconOption = {
			color: "green",
			flipped: 'vertically'
		}
	}
	const redirectTo = (id) => {
		return window.location.href =`/transactions/${id}`;
	};

	return (
		<Table.Row className="clickable hoverRow">
			{ isAccountPage && showArrow &&
			(<Table.Cell collapsing onClick={()=> redirectTo(nftData.transactionId)}>
				<span>
				<ArrowIcon {...arrowIconOption} style={{ margin: '0px 0px 0px 40px' }}/>
				</span>
			</Table.Cell>)
			}
			<Table.Cell collapsing>
				<span>
					{nftData.tokenId ?
						<Link to={`/tokens/${nftData.tokenId}`}>{nftData.tokenId}</Link>
						: <>{nftData.tokenId}</>
					}
				</span>
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(nftData.transactionId)}>
				<span>{nftData.serialNumber ? nftData.serialNumber : ''}</span>
			</Table.Cell>
			<Table.Cell collapsing>
				<span>
					{nftData.sender != "0.0.0" ?
						<Link to={`/accounts/${nftData.sender}`}>{nftData.sender}</Link>
						: <>MINT</>
					}
				</span>
			</Table.Cell>
			<Table.Cell collapsing>
				{nftData.receiver != "0.0.0" ?
					<Link to={`/accounts/${nftData.receiver}`}>{nftData.receiver}</Link>
					: <>{nftData.receiver}</>
				}
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(nftData.transactionId)}>
				<DateCell collapsing date={nftData.consensusTime} />
			</Table.Cell>
				{/* {
					!isTransactionPage && (
						<Table.Cell textAlign="center" collapsing>
							<a href={`/transactions/${nftData.transactionId}`}>
								<Popup
									content="Transaction Details"
									trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
								/>
							</a>
						</Table.Cell>
					)
				} */}
		</Table.Row>
	);
};

export default NftsHistoricRow;

NftsHistoricRow.propTypes = {
	nftData: PropTypes.object.isRequired
};
