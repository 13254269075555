import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Link } from "react-router-dom";
import { /*Menu,*/ Dropdown, Icon } from "semantic-ui-react";

import * as actions from "../../redux/actions";
import { isAuthed } from "../../redux/reducers";
import MenuItem from "../shared/MenuItem";
import { isMobile } from '../../utils';
import AccountSelector from "./AccountSelector";
import NetworkSelector from "./NetworkSelector";

const userOptions = [
  {
    key: "profile",
    text: "PROFILE",
    value: "profile",
    icon: "id card",
  },
  {
    key: "logout",
    text: "SIGN OUT",
    value: "logout",
    icon: "power",
  },
];

const dappsOptions = [
  // {
  // 	key: 'medcore',
  // 	text: 'MEDCORE',
  // 	value: 'medcore',
  // 	disabled: false
  // },
  // {
  // 	key: 'sweattrack',
  // 	text: 'SWEATTRACK',
  // 	value: 'sweattrack',
  // 	disabled: false
  // },
  {
    key: "popular",
    text: "POPULAR DAPPs",
    value: "popular",
    disabled: true,
  },
  {
    key: "recent",
    text: "RECENT DAPPs",
    value: "recent",
    disabled: true,
  },
  {
    key: "manage",
    text: "MANAGE DAPP",
    value: "manage",
    disabled: true,
  },
  {
    key: "deploy",
    text: "DEPLOY A NEW DAPP",
    value: "deploy",
    disabled: true,
  },
  {
    key: "featured",
    text: "FEATURED DAPPs",
    value: "featured",
    disabled: false,
    sub: true,
  },
];

const devOptions = [
  {
    key: "api",
    text: "HEDERA DATA API",
    value: "api",
    // disabled: true
  },
  {
    key: "subscriptions",
    text: "REAL-TIME SUBSCRIPTIONS",
    value: "subscriptions",
    // disabled: true
  },
  {
    key: "faqs",
    text: "FAQs",
    value: "faqs",
    // disabled: true
  },
  {
    key: "subscribe",
    text: "SUBSCRIBE",
    value: "subscribe",
    disabled: true,
  },
];

const tokenOptions = [
  {
		key: 'token',
		text: <div className="item-hoverable">FUNGIBLE</div>,
		value: 'fungible'
	},
  {
		key: 'token',
		text: <div className="item-hoverable">NON FUNGIBLE</div>,
		value: 'non-fungible'
	}
];

class NavLinks extends Component {
  handleClick = (e, { value }) => {
    switch (value) {
      case "logout":
        this.props.logout();
        break;
      case "profile":
        this.props.history.push("/profile");
        break;
      case "api":
        this.props.history.push("/apiview");
        break;
      case "subscriptions":
        this.props.history.push("/subscriptionview");
        break;
      case "faqs":
        this.props.history.push("/faqs");
        break;
      case "medcore":
        this.props.history.push("/dapps/1");
        break;
      case "sweattrack":
        this.props.history.push("/dapps/2");
				break;
      case 'fungible':
        this.props.history.push('/tokens/fungible');
        break;
      case 'non-fungible':
        this.props.history.push('/tokens/non-fungible');
        break;
      default:
        this.props.history.push(`/dapps/${value}`);
        window.location.reload();
    }
  };

  render() {
    const { open, onClose, location, isAuthed = false } = this.props;

    return (
      <nav id="ml-menu" className={`app-menu ${open ? "menu--open" : ""}`}>
        <button
          className="action action--close"
          aria-label="Close Menu"
          onClick={onClose}
        >
          <Icon name="close" />
        </button>
        <div className="menu__wrap">
          <ul role="menu" className="menu__category" onClick={onClose}>
            <MenuItem
              text={
                <NavLink to="/nft-explorer" className="text-white">
                  NFT Explorer
                  <span style={{color: "red", position: "absolute", bottom: "48%", fontSize: "10px"}}>New</span>
                </NavLink>
              }
            />
            {/* { isMobile()
              ? <MenuItem text={<AccountSelector />} />
              : ''
						} */}
            {/* <MenuItem text={<Dropdown text={'DEV TOOLS'} options={devOptions} />} /> */}
            <MenuItem
              text={
                <Dropdown
                  direction="left"
                  text={"DEVELOPER RESOURCES"} /*options={devOptions}*/
                >
                  <Dropdown.Menu>
                    {devOptions.map((option, index) => (
                      <Dropdown.Item
												key={`${option.value}_${index}`}
                        {...option}
                        onClick={this.handleClick}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              }
            />

            {/* <MenuItem text={<Dropdown text={'DAPPs'} options={dappsOptions} />} /> */}

            {/* <MenuItem
              text={
                <Dropdown text={"DAPPs"}>
                  <Dropdown.Menu>
                    {dappsOptions.map((option, ind) => (
                      <Dropdown.Item
                        key={`${option.value}_${ind}`}
                        {...option}
                        onClick={this.handleClick}
                      >
                        {option.sub == true ? (
                          <Dropdown text={option.text}>
                            <Dropdown.Menu className="submenu">
                              {this.props.dapps.map((option, index) => (
                                <Dropdown.Item
												          key={`${option.value}_${index}`}
                                  key={option.id}
                                  text={option.title}
                                  value={option.id}
                                  onClick={this.handleClick}
                                />
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          option.text
                        )}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              }
            /> */}

            <MenuItem text={<NetworkSelector />} />

						<MenuItem
						  text={
								<Dropdown direction="left" text={'TOKENS'}>
									<Dropdown.Menu>
										{tokenOptions.map((option, indexToken) => (
											<Dropdown.Item
												key={`${option.value}_${indexToken}`}
												{...option}
												onClick={this.handleClick}
											/>
										))}
									</Dropdown.Menu>
								</Dropdown>
							}
						/>

            <MenuItem
              text={
                <NavLink to="/pricing" className="text-white">
                  PRICING
                </NavLink>
              }
            />

            {/* <MenuItem
              text={
                <NavLink to="/about" className="text-white">
                  ABOUT
                </NavLink>
              }
            /> */}
            {!isAuthed && location.pathname !== "/login" && (
              <MenuItem
                text={
                  <NavLink to="/login" className="text-white">
                    SIGN IN
                  </NavLink>
                }
              />
            )}

						{isAuthed && (
              <MenuItem
                text={
                  <Dropdown
                    icon={<Icon name="user circle" size="large" />}
                    floating
                    labeled
                  >
                    <Dropdown.Menu>
                      {userOptions.map((option, index) => (
                        <Dropdown.Item
												  key={`${option.value}_${index}`}
                          {...option}
                          onClick={this.handleClick}
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                }
              />
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthed: isAuthed(state),
  dapps: state.dapps,
});

export default withRouter(connect(mapStateToProps, actions)(NavLinks));
