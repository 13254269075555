import React, { useState } from 'react';
import { List } from 'semantic-ui-react';
import APIButton from './shared/APIButton';

const APIGetList = ({ cat, apis, handleApiSelect, activeApi }) => {

    // console.log("CATEGORY IN GET LIST: ", cat);
    // console.log("ACTIVE API IN GET LIST: ", activeApi);

    const [selected, setSelected] = useState('');

    // const handleClick = (api) => {
    //     console.log("API: ", api)
    //     setSelected(api.title)
    // }

    const apisToDisplay = apis.filter(api => api.category === cat.id);

    return (
            <List className="categories-list">
                {apisToDisplay.map((api, index) =>
                    <List.Item key={index} active={selected===cat.title ? true : false} >
                        <div style={{display: "flex"}} onClick={() => {/*handleClick(api); */handleApiSelect(api)}}>
                            <span style={{marginRight: "10px"}}><APIButton /></span>
                            <span
                                // className={selected===api.title ? "selected" : ""}
                                className={activeApi.title===api.title ? "selected" : ""}
                            >{api.title}</span>
                        </div>
                    </List.Item>)}
            </List>
    )
}

export default APIGetList;
