import React from 'react';
import { Table } from 'semantic-ui-react';

const ApiParameterTable = (props) => {

    const required = <div className="note"><span className="text-red">*</span> required</div>
    const multiple = <div className="note"><span className="text-red">*</span> multiple values allowed</div>

    const rowsToDisplay = props.parameters.map(p => {
        let nameToDisplay = p.name;
        if (p.multi_value) {nameToDisplay = <div><div>{p.name}</div><div>{multiple}</div></div>};
        if (p.required) {nameToDisplay = <div><div>{p.name}</div><div>{required}</div></div>};
        if (p.required && p.multi_value) {nameToDisplay = <div><div>{p.name}</div><div>{multiple}</div></div>};

        const optionsToDisplay = window.innerWidth < 1170 ? p.options.replace(/_/g, `_  `) : p.options;  // to narrow the column by breaking the line

        return (
            <Table.Row key={Math.random()}>
                <Table.Cell>
                    {nameToDisplay}
                </Table.Cell>
                <Table.Cell>{p.default_value}</Table.Cell>
                {/* Classes assigned case-sensitively for media queries: */}
                <Table.Cell width={2} className={p.options === p.options.toUpperCase() && "wide-cell"}>
                    <span className={p.options !== p.options.toUpperCase() && "cell"}>{optionsToDisplay}</span>
                </Table.Cell>
                <Table.Cell>{p.description}</Table.Cell>
            </Table.Row>
        )
    })

    return (
        <div>
            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Default Value</Table.HeaderCell>
                    <Table.HeaderCell>Available Options</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rowsToDisplay}
                </Table.Body>
            </Table>
        </div>
    )
}

export default ApiParameterTable;
