import React, { Component } from 'react';
import Panel from './shared/Panel';
import PeriodSelector from './shared/PeriodSelector';
import TokenTxnValueChart from './containers/TokenTxnValueChart';
import Info from './shared/Info';

export default class TokenTxnValueChartPanel extends Component {
	state = {
		period: '1D'
	};

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
	};

	render() {
		const { period } = this.state;
		return (
			<Panel
				title={
					<React.Fragment>
						TOKEN TRANSFERS <Info id="tokenTransfersChart" />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				<TokenTxnValueChart period={period} />
			</Panel>
		);
	}
}