import {
	FETCH_NFTS_DETAIL_REQUEST,
	FETCH_NFTS_DETAIL_SUCCESS,
	FETCH_NFTS_DETAIL_FAILURE,
	FETCH_NFTS_HISTORICAL_DETAIL_SUCCESS,
	FETCH_NFTS_HISTORICAL_DETAIL_FAILURE,
	FETCH_TOP_NFTS_DETAIL_SUCCESS,
	FETCH_TOP_NFTS_DETAIL_FAILURE,
	FETCH_LATEST_COLLECTION_NFTS_SUCCESS,
	FETCH_LATEST_COLLECTION_NFTS_REQUEST,
	FETCH_LATEST_COLLECTION_NFTS_FAILURE,
	FETCH_ACTIVE_COLLECTION_NFTS_SUCCESS,
	FETCH_ACTIVE_COLLECTION_NFTS_REQUEST,
	FETCH_ACTIVE_COLLECTION_NFTS_FAILURE,
	FETCH_SEARCH_NFTS_REQUEST,
	FETCH_SEARCH_NFTS_SUCCESS,
	FETCH_SEARCH_NFTS_FAILURE,
	FETCH_LATEST_NFTS_LOAD,
	FETCH_LATEST_NFTS_SUCCESS,
	FETCH_LATEST_NFTS_FAILURE
 } from '../types';
import { combineReducers } from 'redux';

const initialState = {};

const NftsDetails = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_NFTS_DETAIL_REQUEST:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case FETCH_NFTS_DETAIL_SUCCESS:
			return {
				...state,
				nftTokenData: action.data,
				totalCount: action.data.totalCount,
				filters: action.filtered === true ? state.filters : action.data.facets,
				isLoading: action.isLoading,
			};
		case FETCH_NFTS_DETAIL_FAILURE:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case FETCH_NFTS_HISTORICAL_DETAIL_SUCCESS:
			return {
				...state,
				nftHistoricalData: action.data,
				isLoading: action.isLoading,
			};
		case FETCH_NFTS_HISTORICAL_DETAIL_FAILURE:
			return {
				...state,
				isLoading: action.isLoading,
			};
		default:
			return state;
	}
};
const TopNftsDetails = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_TOP_NFTS_DETAIL_SUCCESS:
			return {
				...state,
				nftTokenData: action.data,
				totalCount: action.data.totalCount,
			};
		case FETCH_TOP_NFTS_DETAIL_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
};

const LatestNfts = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_LATEST_NFTS_LOAD:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case FETCH_LATEST_NFTS_SUCCESS:
			return {
				...state,
				nftTokenData: action.data,
				totalCount: action.data.totalCount,
				isLoading: action.isLoading,
			};
		case FETCH_LATEST_NFTS_FAILURE:
			return {
				...state,
				isLoading: action.isLoading,
			};
		default:
			return state;
	}
};

const LatestCollections = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_LATEST_COLLECTION_NFTS_REQUEST:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case FETCH_LATEST_COLLECTION_NFTS_SUCCESS:
			return {
				...state,
				latestCollections: action.data,
				totalCount: action.data.totalCount,
				isLoading: action.isLoading,
			};
		case FETCH_LATEST_COLLECTION_NFTS_FAILURE:
			return {
				...state,
				isLoading: action.isLoading,
			};
		default:
			return state;
	}
};

const ActiveCollections = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ACTIVE_COLLECTION_NFTS_REQUEST:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case FETCH_ACTIVE_COLLECTION_NFTS_SUCCESS:
			return {
				...state,
				activeCollections: action.data,
				totalCount: action.data.totalCount,
				isLoading: action.isLoading,
			};
		case FETCH_ACTIVE_COLLECTION_NFTS_FAILURE:
			return {
				...state,
				isLoading: action.isLoading,
			};
		default:
			return state;
	}
};

const SearchNFTs = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_SEARCH_NFTS_REQUEST:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case FETCH_SEARCH_NFTS_SUCCESS:
			return {
				...state,
				searchNFTs: action.data,
				totalCount: action.data.totalCount,
				isLoading: action.isLoading,
			};
		case FETCH_SEARCH_NFTS_FAILURE:
			return {
				...state,
				isLoading: action.isLoading,
			};
		default:
			return state;
	}
};

const nfts = combineReducers({
	NftsDetails,
	TopNftsDetails,
	LatestNfts,
	LatestCollections,
	ActiveCollections,
	SearchNFTs
});

export default nfts;

export const getNftsDetail = (state) => {
	return state.NftsDetails && state.NftsDetails.nftTokenData && state.NftsDetails.nftTokenData.data && state.NftsDetails.nftTokenData.data.length ? state.NftsDetails.nftTokenData.data[0] : [];
}

export const getNftsHistoricalDetail = (state) => {
	return state.NftsDetails && state.NftsDetails.nftHistoricalData && state.NftsDetails.nftHistoricalData.data && state.NftsDetails.nftHistoricalData.data.length ? state.NftsDetails.nftHistoricalData.data : [];
}

export const getNftsHistoricalDetailCount = (state) => {
	return state.NftsDetails && state.NftsDetails.nftHistoricalData && state.NftsDetails.nftHistoricalData.totalCount ? state.NftsDetails.nftHistoricalData.totalCount : 0;
}

export const getNftsData = (state) => {
	return state.NftsDetails && state.NftsDetails.nftTokenData && state.NftsDetails.nftTokenData.data && state.NftsDetails.nftTokenData.data.length ? {nftsData: state.NftsDetails.nftTokenData.data, totalCount: state.NftsDetails.nftTokenData.totalCount} : {};
}

export const getSearchedNftsCount = (state) => state.NftsDetails && state.NftsDetails.nftTokenData && state.NftsDetails.nftTokenData.data.length ? state.NftsDetails.nftTokenData.totalCount : 0;

export const getTopNftsData = (state) => {
	return state.TopNftsDetails && state.TopNftsDetails.nftTokenData && state.TopNftsDetails.nftTokenData.data && state.TopNftsDetails.nftTokenData.data.length ? {nftsData: state.TopNftsDetails.nftTokenData.data, totalCount: state.TopNftsDetails.nftTokenData.totalCount} : {};
}
export const getNftFilters = (state) => state.NftsDetails && state.NftsDetails ? state.NftsDetails.filters : {};

export const getLatestCollectionsData = (state) => {
	return state.LatestCollections && state.LatestCollections.latestCollections && state.LatestCollections.latestCollections.data && state.LatestCollections.latestCollections.data.length ? {latestCollections: state.LatestCollections.latestCollections.data, totalCount: state.LatestCollections.latestCollections.totalCount, isLoading: state.LatestCollections.isLoading} : {isLoading: state.LatestCollections ? state.LatestCollections.isLoading : true};
}

export const getActiveCollectionsData = (state) => {
	return state.ActiveCollections && state.ActiveCollections.activeCollections && state.ActiveCollections.activeCollections.data && state.ActiveCollections.activeCollections.data.length ? {activeCollections: state.ActiveCollections.activeCollections.data, totalCount: state.ActiveCollections.activeCollections.totalCount, isLoading: state.ActiveCollections.isLoading} : {isLoading: state.ActiveCollections ? state.ActiveCollections.isLoading : true};
}

export const getSearchNFTsData = (state) => {
	return state.SearchNFTs && state.SearchNFTs.searchNFTs && state.SearchNFTs.searchNFTs.data && state.SearchNFTs.searchNFTs.data.length ? {nfts: state.SearchNFTs.searchNFTs.data, totalCount: state.SearchNFTs.searchNFTs.totalCount, isLoading: state.SearchNFTs.isLoading} : {isLoading: state && state.SearchNFTs && state.SearchNFTs.isLoading ? state.SearchNFTs.isLoading : false};
}

export const getLatestNftsData = (state) => {
	return state.LatestNfts && state.LatestNfts.nftTokenData && state.LatestNfts.nftTokenData.data && state.LatestNfts.nftTokenData.data.length ? {nftsData: state.LatestNfts.nftTokenData.data, totalCount: state.LatestNfts.nftTokenData.totalCount, isLoading: state.LatestNfts.isLoading} : {isLoading: state.LatestNfts ? state.LatestNfts.isLoading : true};
}