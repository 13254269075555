import { FETCH_TRANSACTION_VOLUME_SUCCESS, FETCH_TRANSACTION_VOLUME_LOAD } from '../types';

const initialState = {
	period: '1D',
	numTransactions: 0,
	pctChange: 0,
	trend: []
};

export default function transactionVolume(state = initialState, action) {
	switch (action.type) {
		case FETCH_TRANSACTION_VOLUME_LOAD:
			return {
				loading: true,
				...action.payload
			};
		case FETCH_TRANSACTION_VOLUME_SUCCESS:
			return {
				loading: false,
				...action.payload
			};
		default:
			return state;
	}
}

export const getTransactionVolume = (state) => state;
