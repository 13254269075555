import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TopicMessagesTable from '../TopicMessagesTable';
import CodeSnippet from '../shared/CodeSnippet';
import * as actions from '../../redux/actions';
import { getTopic, getTopicMessages } from '../../redux/reducers';
import { fetchTopicMessages } from '../../apis';

class TopicMessages extends Component {
	state = {
		showKey: false
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchTopic, fetchTopicBalance, fetchTopicRecentTransactionsCount, fetchTopicMessages, id } = this.props;
		// fetchTopicBalance(id);
		// fetchTopicRecentTransactionsCount(id);
        // fetchTopic(id);
        // fetchTopicMessages(id);
	}

	onShowKey = () => {
		this.setState({ showKey: true });
	};

	onHideKey = () => {
		this.setState({ showKey: false });
	};

	render() {
		const { topic } = this.props;
		const { showKey } = this.state;
		return (
			<React.Fragment>
				<TopicMessagesTable topic={topic} messages={this.props.messages} onShowKey={this.onShowKey} />
				{/* {topic &&
				showKey && <CodeSnippet code={topic.adminKey} onClose={this.onHideKey} title="Public Key(s)" />} */}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, { match: { params: { topicID } } }) => ({
	id: topicID,
    topic: getTopic(state, topicID),
    messages: getTopicMessages(state, topicID)
});

TopicMessages = withRouter(connect(mapStateToProps, actions)(TopicMessages));

export default TopicMessages;