import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Table } from 'semantic-ui-react';
import { getKeys } from '../redux/reducers';

const ApiKeyTable = (props) => {

    console.log(props)

    const handleDelete = (keyId) => {
        props.deleteApiKey(keyId);
    }

    const handleToggleState = (keyId, status) => {
        if (status === 'ACTIVE') {
            props.deactivateApiKey(keyId);
        } else if (status === 'INACTIVE') {
            props.activateApiKey(keyId);
        }
    }

    const rowsToDisplay = props.keys.map(k => {
        return (
            <Table.Row key={Math.random()}>
                <Table.Cell className="api-cell">***************{/*`${key.key.slice(-4)}`*/}</Table.Cell>
                <Table.Cell style={{overflowWrap: "anywhere"}} className="api-cell">{k.key}</Table.Cell>
                <Table.Cell className="toggle-state api-cell" onClick={() => handleToggleState(k.key, k.state)}>{k.state}</Table.Cell>
                <Table.Cell className="toggle-state api-cell" style={{textAlign: "center"}} onClick={() => handleDelete(k.key)}><i className="close icon red"></i></Table.Cell>
            </Table.Row>
        )
    })

    return (
        <div>
            <Table celled stackable className="api-key-table">
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Access Key</Table.HeaderCell>
                    <Table.HeaderCell>API Key</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Delete</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rowsToDisplay}
                </Table.Body>
            </Table>

        </div>
    )
}

const mapStateToProps = (state) => ({
	keys: getKeys(state),
});

export default connect(mapStateToProps, actions)(ApiKeyTable);
