import { combineReducers } from 'redux';
import {
	FETCH_ACCOUNT_SUCCESS,
	FETCH_ACCOUNT_BALANCE_SUCCESS,
	FETCH_ACCOUNT_TX_COUNT_SUCCESS,
	FETCH_ACCOUNT_TRANSACTIONS_SUCCESS,
	FETCH_ACCOUNT_TRANSFERS_SUCCESS,
	FETCH_ACCOUNT_BALANCE_HISTORY_LOAD,
	FETCH_ACCOUNT_BALANCE_HISTORY_SUCCESS,
	FETCH_ACCOUNT_FILES_SUCCESS
} from '../types';

const addToAccount = (state, id, data, loading = false) => {
	const account = state[id];
	return {
		...state,
		[id]: {
			...(account ? account : {}),
			...data,
			loading: loading,
		}
	};
};
const addEntry = (state, account) => {
	const { id, ...rest } = account;
	return addToAccount(state, id, rest);
};

const addBalance = (state, payload) => {
	const { id, balance } = payload;
	return addToAccount(state, id, { balance });
};

const addCount = (state, payload) => {
	const { id, numRecentTxns } = payload;
	return addToAccount(state, id, { numRecentTxns });
};

const addTransactions = (state, action) => {
	const { id, response } = action;
	return addToAccount(state, id, { transactions: response });
};

const addTransfers = (state, action) => {
	const { id, response } = action;
	return addToAccount(state, id, { transfers: response });
};

const addBalanceHistory = (state, payload, loading) => {
	const { id, balanceHistory } = payload;
	return addToAccount(state, id, { balanceHistory }, loading);
};

const addFiles = (state, payload) => {
	const { id, files } = payload;
	return addToAccount(state, id, { files });
};

const accountsById = (state = {}, action) => {
	switch (action.type) {
		case FETCH_ACCOUNT_SUCCESS:
			return addEntry(state, action.payload);
		case FETCH_ACCOUNT_BALANCE_SUCCESS:
			return addBalance(state, action.payload);
		case FETCH_ACCOUNT_TX_COUNT_SUCCESS:
			return addCount(state, action.payload);
		case FETCH_ACCOUNT_TRANSACTIONS_SUCCESS:
			return addTransactions(state, action);
		case FETCH_ACCOUNT_TRANSFERS_SUCCESS:
			return addTransfers(state, action);
		case	FETCH_ACCOUNT_BALANCE_HISTORY_LOAD:
			return addBalanceHistory(state, action.payload, true);
		case FETCH_ACCOUNT_BALANCE_HISTORY_SUCCESS:
			return addBalanceHistory(state, action.payload, false);
		case FETCH_ACCOUNT_FILES_SUCCESS:
			return addFiles(state, action.payload);
		default:
			return state;
	}
};

const allAccounts = (state = [], action) => state;

const accountsReducer = combineReducers({
	byId: accountsById,
	allIds: allAccounts
});

export default accountsReducer;

/* Selectors */
export const getAccount = (state, id) => state.byId[id];
export const getAccountTransfers = (state, id) => {
	const account = getAccount(state, id);
	if (account && account.transfers) return account.transfers.data;
	else return [];
};
export const getAccountTransfersCount = (state, id) => {
	const account = getAccount(state, id);
	if (account && account.transfers) return account.transfers.totalCount;
	else return 0;
};
export const getAccountTransactions = (state, id) => {
	const account = getAccount(state, id);
	if (account && account.transactions) return account.transactions.data;
	else return [];
};
export const getAccountTransactionsCount = (state, id) => {
	const account = getAccount(state, id);
	if (account && account.transactions) return account.transactions.totalCount;
	else return 0;
};

export const getAccountBalanceHistory = (state, id) => {
	const account = getAccount(state, id);
	if (account && account.balanceHistory) return account.balanceHistory;
	else return {};
};

export const getAccountBalanceHistoryLoader = (state, id) => {
	const account = getAccount(state, id);
	return account && account.loading;
};

export const getAccountFiles = (state, id) => {
	const account = getAccount(state, id);
	if (account && account.files) return account.files;
	else return [];
};
