import React from 'react';
import { Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Page from '../shared/Page';

export default () => (
	<Page>
		<div className="page not-found">
			<div className="page-content">
				<div className="error-block">
					<Header size="huge" className="error-heading">
						Page not found
					</Header>
					<p>This page has moved or no longer exists.</p>
					<Button as={Link} to="/" primary basic>
						Go Home
					</Button>
				</div>
			</div>
		</div>
	</Page>
);
