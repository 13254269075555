import React from 'react';
import { Table } from 'semantic-ui-react';

export default function TopicTableHeader(props) {
	return (
		<Table.Row>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'topicID' ? props.direction : null}
				onClick={() => props.handleSort('topicID')}
			>
				ID
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'createdBy' ? props.direction : null}
				onClick={() => props.handleSort('createdBy')}
			>
				CREATED BY
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'createdDate' ? props.direction : null}
				onClick={() => props.handleSort('createdDate')}
			>
				Creation Date
			</Table.HeaderCell>
			{/* <Table.HeaderCell
				width={1}
				sorted={props.column === 'transactionID' ? props.direction : null}
				onClick={() => props.handleSort('transactionID')}
			>
				Transaction ID
			</Table.HeaderCell> */}
			<Table.HeaderCell
				width={4}
				sorted={props.column === 'memo' ? props.direction : null}
				onClick={() => props.handleSort('memo')}
			>
				Memo
			</Table.HeaderCell>
			{/* <Table.HeaderCell width={1} /> */}
		</Table.Row>
	);
}
