import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Button, Icon, Modal, Header, Message, Popup} from 'semantic-ui-react';
import Panel from './shared/Panel';
import SubscriptionTable from './SubscriptionTable';
import Subscribe from './Subscribe';
import SubscribeNew from './SubscribeNew';
import AbiUpload from './AbiUpload';
import SubscriptionFilters from './SubscriptionFilters';
import SubscriptionFiltersDisplay from './SubscriptionFiltersDisplay';
import Info from './shared/Info';
import { getDapps, getUser, getSubscriptions, getSubscriptionModels, getSubscriptionFilters } from '../redux/reducers';

import SubscriptionAddAwsArnForm from './forms/SubscriptionAddAwsArnForm';

import * as constants from '../constants';

import '../animate.css';    

import { CSVLink } from "react-csv";

const SubscriptionPanel = (props) => {

    const [isDisabled, setIsDisabled] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchData() }, []);

    const fetchData = async () => {
        const { fetchDapps, fetchSubscriptions, fetchSubscriptionModels } = props;
        fetchSubscriptions();
        fetchSubscriptionModels();
    }

    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; 
    let yyyy = today.getFullYear();
    if(dd<10) {dd='0'+dd;} 
    if(mm<10) {mm='0'+mm;} 
    let date = yyyy+'-'+mm+'-'+dd;

    // console.log("THIS IS THE DATE: ", date)

    const validSubs = props.subscriptions.filter(el => el.expiry.slice(0, 10) > date);
    const [newFilter, setNewFilter] = useState({});

    const [subscriptionId, setSubscriptionId] = useState('');   // this is for AbiUpload
    const [subscription, setSubscription] = useState({});       // this is for SubscriptionFilters, also used in AddAwsArnForm 
    const [ iams, setIams ] = useState([]);

    const [isRegistered, setIsRegistered] = useState(false);

    const [subscribeOpen, setSubscribeOpen] = useState(false);
    const [subscribeNewOpen, setSubscribeNewOpen] = useState(false);
    const [subscriptionFiltersOpen, setSubscriptionFiltersOpen] = useState(false);
    const [displayFiltersOpen, setDisplayFiltersOpen] = useState(false);

    const [uploadAbiOpen, setUploadAbiOpen] = useState(false);
    const [abiMessageSuccessOpen, setAbiMessageSuccessOpen] = useState(false);
    const [abiMessageFailureOpen, setAbiMessageFailureOpen] = useState(false);
    const [abiFileName, setAbiFileName] = useState('');

    const [ addAwsArnOpen, setAddAwsArnOpen ] = useState(false);
    const [ addAwsArnFailureOpen, setAddAwsArnFailureOpen ] = useState(false);

    const [messageOpen, setMessageOpen] = useState(false);
    const [registerMessageOpen, setRegisterMessageOpen] = useState(false);
    const [subscribeMessageOpen, setSubscribeMessageOpen] = useState(false);
    const [unregisterMessageOpen, setUnregisterMessageOpen] = useState(false);
    const [removeSubscriptionMessageOpen, setRemoveSubscriptionMessageOpen] = useState(false);

    const handleRegister = (user) => {
        console.log('REGISTER FOR A SUBSCRIPTION CLICKED FOR: ', user.id);
        const userToRegister = JSON.parse(localStorage.getItem(constants.DG_USER));
        localStorage.setItem(constants.DG_USER, JSON.stringify({ ...userToRegister, registeredForSubscriptions: true }));
        setIsRegistered(true);
        props.registerForSubscriptions(user.id);
    }

    const handleCancelRegistration = (user) => {
        console.log('CANCEL REGISTRATION FOR SUBSCRIPTION CLICKED FOR: ', user.id);
        const userToCancel = JSON.parse(localStorage.getItem(constants.DG_USER));
        localStorage.setItem(constants.DG_USER, JSON.stringify({ ...userToCancel, registeredForSubscriptions: false }));
        setIsRegistered(false);
        props.cancelSubscriptionRegistration(user.id);
    }
    
    const handleCancel = () => {
        setSubscribeOpen(false);
        setSubscribeNewOpen(false);
        setSubscriptionFiltersOpen(false);
        setDisplayFiltersOpen(false);
        setUploadAbiOpen(false);
        setAddAwsArnOpen(false);
    }

    const handleAwsArnCancel = () => {
        setAddAwsArnFailureOpen(false);
    }

    const handleFilterFormCancel = () => {
        setDisplayFiltersOpen(false);
    }

    const handleMessageCancel = () => {
        setRegisterMessageOpen(false);
        window.location.reload();
    }

    const handleRegisterMessage = () => {
        setRegisterMessageOpen(true);
    }

    const handleSubscribeMessage = () => {
        setSubscribeMessageOpen(true);
    }

    const handleUnregisterMessage = () => {
        setUnregisterMessageOpen(true)
    }

    const handleSubscribe = () => {
        setSubscribeOpen(true);
    }

    const handleSubscribeNew = () => {
        setSubscribeNewOpen(true);
    }

    const [ displayAbiFileName, setDisplayAbiFileName ] = useState(false);

    const handleAbiMessage = (fileName, response) => {
        setAbiFileName(fileName);
        setDisplayAbiFileName(true);
        response && response.status === 201 ? setAbiMessageSuccessOpen(true) : setAbiMessageFailureOpen(true)
    }

    const handleAbiMessageCancel = () => {
        setAbiMessageSuccessOpen(false);
        setAbiMessageFailureOpen(false);
    }

    const handleUploadAbi = (subId) => {
        console.log('HANDLE UPLOAD ABI CLICKED FOR: ', subId);
        setSubscriptionId(subId);
        setUploadAbiOpen(true);
    }

    const handleAddAwsArn = (sub) => {
        console.log('HANDLE ADD AWS ARN CLICKED FOR: ', sub);
        setSubscription(sub);
        setIams(sub.iamUsers);
        setAddAwsArnOpen(true);
    }

    const onAddAwsArnSubmit = (id, arn) => {
        console.log("ADDING ARN: ", id, arn);
        props.addAwsArn(id, arn).then(
            (response) => {
                console.log("THIS IS THE RESPONSE IN ADD AWS ARN: ", response);
                response ? setIams(response.data.iamUsers) : setAddAwsArnFailureOpen(true);
            }
        )
    }

    const reactivate = () => {
        setIsDisabled(false);
    }

    const handleAddFilters = (sub) => {
        const query = (`queueName=${sub.queueName}&user=${props.user.email}`)
        console.log('HANDLE ADD FILTERS CLICKED FOR THIS QUERY: ', query);
        setSubscription(sub);
        setSubscriptionFiltersOpen(true);
        handleFilterFormCancel();
        props.fetchSubscriptionFilters(query);
    }

    const handleDisplayFilters = (sub) => {
        console.log('IN HANDLE REMOVE FILTERS');
        const query = (`queueName=${sub.queueName}&user=${props.user.email}`);
        setSubscription(sub);
        props.fetchSubscriptionFilters(query);
        setDisplayFiltersOpen(true);
    }

    const addSubscriptionFilters = (form) => {
        const ids = form.ids.map(el => `ids=${el}&`);
        const signature = form.signature.map(el => `signature=${el}&`)
        const query = (`filterType=${form.filterType}&${ids}queueName=${form.queueName}&${signature}user=${form.user}`).replace(/,/gi, '');
        console.log("THIS IS THE QUERY IN SUBSCRIPTION PANEL: ", query);
        props.postSubscriptionFilters(form, query);
        setNewFilter(form);
        // setDisplayFiltersOpen(true);
    }

    const handleRemoveSubscription = (subId) => {
        console.log('HANDLE REMOVE SUBSCRIPTION CLICKED FOR THIS ID: ', subId);
        props.removeSubscription(subId);
        setRemoveSubscriptionMessageOpen(true);
    }

    // console.log("THIS IS THE USER IN THE SUBSCRIPTION PANEL :", props.user);
    // console.log("THESE ARE THE SUBSCRIPTIONS: ", props.subscriptions);
    // console.log("THESE ARE THE SUBSCRIPTION MODELS: ", props.subscriptionModels);
    // console.log("THESE ARE THE FILTERS IN SUBSCRIPTION PANEL: ", props.subscriptionFilters)

    return (
        <Panel
            title={
                <div className={window.innerWidth < 992 && "subscriptions-header"}>
                    <span className="subscriptions-span">MY SUBSCRIPTIONS <Info id="subscriptions" /></span>
                    {/* <Popup 
                        size="small"
                        position="top center"
                        content='Cancel subscription registration' 
                        trigger={(isRegistered || props.user.registeredForSubscriptions) && <i className="close icon red" 
                        onClick={e => {handleCancelRegistration(props.user); handleUnregisterMessage()}} 
                        style={{cursor: "pointer", marginLeft: "15px"}}></i>} 
                    /> */}
                    {/* {(isRegistered || props.user.registeredForSubscriptions) ?  */}
                        <Button 
                            primary 
                            className="subscription-button" 
                            onClick={handleSubscribeNew} 
                            disabled={validSubs.length >= 3} 
                            size={window.innerWidth < 992 ? "tiny" : "medium"} >
                            <Icon name="checkmark" />
                            ADD A SUBSCRIPTION
                        </Button> {/*:*/}
                        {/* <Button primary className="api-button" onClick={e => {handleRegister(props.user); handleRegisterMessage()}} disabled={isDisabled}>
                            <Icon name="signup" />REGISTER FOR SUBSCRIPTIONS</Button>} */}
                </div>
            }
        >
            <SubscriptionTable 
                subscriptions={props.subscriptions} 
                handleAddFilters={handleAddFilters} 
                handleDisplayFilters={handleDisplayFilters}
                handleUploadAbi={handleUploadAbi}  
                handleRemoveSubscription={handleRemoveSubscription} 
                displayAbiFileName={displayAbiFileName}
                abiFileName={abiFileName}
                subscriptionId={subscriptionId}
                handleAddAwsArn={handleAddAwsArn}
            />

            {/* ------------ RENDERING THE SUBSCRIPTION CREATION FORM ------------ */}
            <Modal open={subscribeNewOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">Create a Subscription</Modal.Header>
				<Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        <SubscribeNew 
                            handleCancel={handleCancel} 
                            handleSubscribeMessage={handleSubscribeMessage} 
                            subscriptionModels={props.subscriptionModels} 
                        />
                    </div>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* --------- RENDERING THE FORM FORM TO ADD AWS USER ARN --------- */}
            <Modal open={addAwsArnOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">DragonGlass Subscription</Modal.Header>
				<Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        <SubscriptionAddAwsArnForm 
                            handleCancel={handleCancel} 
                            subscription={subscription}
                            iams={iams}
                            onSubmit={onAddAwsArnSubmit}
                            // handleSubscribeMessage={handleSubscribeMessage} 
                            // subscriptionModels={props.subscriptionModels} 
                        />
                    </div>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ----------- RENDERING THE SUBSCRIPTION FILTERS CREATE FORM ----------- */}
            <Modal open={subscriptionFiltersOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">Add Filter</Modal.Header>
				<Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        <SubscriptionFilters 
                            handleCancel={handleCancel} 
                            handleSubscribeMessage={handleSubscribeMessage} 
                            subscription={subscription} 
                            addSubscriptionFilters={addSubscriptionFilters}
                        />
                    </div>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ----------- RENDERING THE SUBSCRIPTION FILTERS DISPLAY FORM ----------- */}
            <Modal open={displayFiltersOpen} closeIcon onClose={e => {handleFilterFormCancel(); handleCancel()}} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">Filters</Modal.Header>
				<Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        <SubscriptionFiltersDisplay 
                            handleCancel={handleCancel} 
                            handleAddFilters={handleAddFilters} 
                            handleSubscribeMessage={handleSubscribeMessage} 
                            subscription={subscription} 
                            // subscriptionFilters={props.subscriptionFilters}
                            addSubscriptionFilters={addSubscriptionFilters}
                            newFilter={newFilter}
                        />
                    </div>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ------------ RENDERING THE ABI UPLOAD FORM ------------ */}
            <Modal open={uploadAbiOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">Upload an Abi File</Modal.Header>
				<Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        <AbiUpload 
                            handleCancel={handleCancel} 
                            handleSubscribeMessage={handleSubscribeMessage} 
                            subscriptionId={subscriptionId} 
                            handleAbiMessage={handleAbiMessage}
                        />
                    </div>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ------------ RENDERING THE SUBSCRIPTION MODEL FORM ------------ */}
            <Modal open={subscribeOpen} closeIcon onClose={handleCancel} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">Select a Subscription Model</Modal.Header>
				<Modal.Content>
                    <div style={{marginBottom: "10px"}}>
                        <Subscribe handleCancel={handleCancel} handleSubscribeMessage={handleSubscribeMessage} />
                    </div>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ------------------ ABI UPLOADED SUCCESSFULLY ------------------ */}
            <Modal size="small" open={abiMessageSuccessOpen} closeIcon onClose={handleAbiMessageCancel} dimmer="blurring" className="animated fadeIn">
				<Modal.Content>
                <Message positive>
                    <Message.Header style={{textAlign: "center"}}>
                        File &nbsp;<span style={{fontStyle: "italic", fontSize: "85%"}}>{abiFileName}</span>&nbsp;&nbsp; has been successfully uploaded.
                    </Message.Header>
                </Message>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ---------------------- ABI UPLOAD FAILED ---------------------- */}
            <Modal size="small" open={abiMessageFailureOpen} closeIcon onClose={handleAbiMessageCancel} dimmer="blurring" className="animated fadeIn">
				<Modal.Content>
                <Message negative>
                    <Message.Header style={{textAlign: "center"}}>
                        Unable to upload ABI at the moment, please try again later.
                    </Message.Header>
                </Message>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}

            {/* ----------------- ADDING AWS USER IAM FAILED ----------------- */}
            <Modal size="small" open={addAwsArnFailureOpen} closeIcon onClose={handleAwsArnCancel} dimmer="blurring" className="animated fadeIn">
				<Modal.Content>
                <Message negative>
                    <Message.Header style={{textAlign: "center"}}>
                        Unable to add AWS User IAM, please check the format of your entry.
                    </Message.Header>
                </Message>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}
            
            <Modal size="small" open={registerMessageOpen} closeIcon onClose={handleMessageCancel} dimmer="blurring" className="animated fadeIn">
				<Modal.Content>
                <Message positive>
                    <Message.Header style={{textAlign: "center"}}>
                        Thank you for registering for subscriptions.<br />Next please add a Subscription.
                    </Message.Header>
                </Message>
				</Modal.Content>
			</Modal>

            <Modal size="small" open={subscribeMessageOpen} closeIcon onClose={handleMessageCancel} dimmer="blurring" className="animated fadeIn">
                <Modal.Content>
                <Message positive>
                    <Message.Header style={{textAlign: "center"}}>
                        Thank you for subscribing.
                    </Message.Header>
                </Message>
                </Modal.Content>
            </Modal>

            <Modal size="small" open={unregisterMessageOpen} closeIcon onClose={handleMessageCancel} dimmer="blurring" className="animated fadeIn">
                <Modal.Content>
                <Message positive>
                    <Message.Header style={{textAlign: "center"}}>
                        Your subscription registration has been successfully cancelled.
                    </Message.Header>
                </Message>
                </Modal.Content>
            </Modal>

            <Modal size="small" open={removeSubscriptionMessageOpen} closeIcon onClose={handleMessageCancel} dimmer="blurring" className="animated fadeIn">
                <Modal.Content>
                <Message positive>
                    <Message.Header style={{textAlign: "center"}}>
                        Your subscription has been successfully removed.
                    </Message.Header>
                </Message>
                </Modal.Content>
            </Modal>

        </Panel>
    )
}

const mapStateToProps = (state) => ({
    dapps: getDapps(state),
    user: getUser(state),
    subscriptions: getSubscriptions(state),
    subscriptionModels: getSubscriptionModels(state),
    subscriptionFilters: getSubscriptionFilters(state)
});

export default connect(mapStateToProps, actions)(SubscriptionPanel);
