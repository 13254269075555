import React, { Component } from 'react';
import { Form, Input, Button, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { isHederaAccount } from '../../utils';
import * as actions from '../../redux/actions';
import { connect } from 'react-redux';
import { isAuthed, getSearchedTransactionsCount, getSearchedAccountsCount, getSearchedContractsCount, getSearchedTopicsCount } from '../../redux/reducers';
import { getHederaTokensCount } from '../../redux/reducers/hts';

class AccountLookup extends Component {
	state = {
		q: '',
		error: false,
		msg: ''
	};

	handleChange = (e, { value }) => {
		this.setState({ q: value, error: false, msg: '' });
	};

	handleLookup = () => {
		this.lookupAccount(this.state.q);
	};

	lookupAccount = (acct) => {
		if (!isHederaAccount(acct)) return this.setState({ error: true, msg: 'Invalid format! Should be x.y.z' });
		this.setState({ error: false, msg: '' }, () => this.goToAccount(acct));
	};

	onClick = (e, { value }) => this.goToAccount(value);
	goToAccount = (acct) => {
		// console.log('acct', acct);
		const searchText = acct;
		const { fetchTransactions, fetchAccounts, fetchContracts, fetchTopics, fetchTokens } = this.props;
		const countOnlyParams = { limit: 0, query: searchText };
		const esc = encodeURIComponent;
		
		const countOnlyQuery = Object.keys(countOnlyParams)
			.map((p) => esc(p) + '=' + esc(countOnlyParams[p]))
			.join('&');
		const a = fetchAccounts(countOnlyQuery, false);
		const c = fetchContracts(countOnlyQuery, false);
		const tp = fetchTopics(countOnlyQuery, false);
		const ht = fetchTokens(countOnlyParams);

		Promise.all([ a, c, tp, ht ]).then(() => {
			const { acctCount, cxtCount, topicsCount, tokensCount } = this.props;
			// if (isMatch) {
				if (acctCount) {
					this.props.history.push(`/accounts/${searchText}`);
				} else if (tokensCount) {
					this.props.history.push(`/tokens/${searchText}`);
				} else if (topicsCount) {
					this.props.history.push(`/topics/${searchText}`);
				} else if (cxtCount) {
					this.props.history.push(`/contracts/${searchText}`);
				} else {
					this.props.history.push(`/search?q=${searchText}`);
				}
				return;
			// }
		});
	};

	render() {
		return (
			<div className="search-home-page" style={{paddingTop: '5px'}}>
			<Form onSubmit={this.handleLookup}>
				<Input
					fluid
					size="mini"
					action={
						<div className="ui  input-button__wrapper">
							<Button primary content="SEARCH" size="tiny" onClick={this.handleLookup} />
						</div>
					}
					placeholder="Account ID, Contract ID, Topic ID, etc (e.g. 0.0.9838)"
					onClick={(e) => e.stopPropagation()}
					onChange={this.handleChange}
					error={this.state.error}
				/>
				{this.state.error && (
					<Message size="mini" negative>
						{this.state.msg}
					</Message>
				)}
			</Form>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	acctCount: getSearchedAccountsCount(state),
	txCount: getSearchedTransactionsCount(state),
	cxtCount: getSearchedContractsCount(state),
	topicsCount: getSearchedTopicsCount(state),
	tokensCount: getHederaTokensCount(state),
});

AccountLookup = withRouter(connect(mapStateToProps, actions)(AccountLookup));
export default AccountLookup;
