import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getNodes } from '../redux/reducers';
import NodesMap from '../components/NodesMap';
import NodesList from '../components/NodesList';
import NodesTable from '../components/NodesTable';
import * as constants from '../constants';
import LoadingIndicator from "../components/containers/LoadingIndicator";
import { Grid } from 'semantic-ui-react';
import Money from '../atoms/Money';
import Number from '../atoms/Number';
import Panel from '../components/shared/Panel';

class Nodes extends Component {
	interval = null;
	constructor(props) {
    super(props);
    this.state = {
			totals: {},
			isLoading: false
		};
	}

	componentDidMount() {
		this.fetchData();
		this.interval = setInterval(() => this.fetchData(), constants.REFRESH_INTERVAL);
	}

	componentDidUpdate(prevProps) {
		const { period } = this.props;
		if (period !== prevProps.period  && !this.state.isLoading) {
			this.setState({ isLoading: true });
			this.fetchData();
		}

		if (this.props.nodes && prevProps.nodes && this.props.nodes.length && prevProps.nodes.length
			&& (this.props.nodes[0].numTransactions === prevProps.nodes[0].numTransactions )
			&& this.state.isLoading) {
			this.setState({ isLoading: false });
		}
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	fetchData = async () => {
		if (!document.hidden) {
			const { fetchNodes, period } = this.props;
			await fetchNodes(period);
			await this.prepareNodeHeader();
		}
	};

	calculateTotals = (objectsArray) => {
		const totals = {};
	  
		objectsArray.forEach(object => {
		  Object.entries(object).forEach(([key, value]) => {
			if (typeof value === 'number') {
			  totals[key] = (totals[key] || 0) + value;
			}
		  });
		});
	  
		return totals;
	}

	prepareNodeHeader = () => {
		const { nodes } = this.props;
		const totals = {...this.calculateTotals(nodes), nodes: nodes.length}
		this.setState({totals});
	}

	render() {
		const { nodes} = this.props;
		const { totals } = this.state;
		nodes.sort((a, b) => a.hedera_id - b.hedera_id);
		return (
			!this.state.isLoading || nodes ?
				<Panel>
					{/* {totals
					? 
						<Grid stackable coloumns={4}>
							<Grid.Row>
								<Grid.Column width={4}>
									<div>Nodes:</div>
									<div>{totals.nodes}</div>
								</Grid.Column>
								<Grid.Column width={4}>
									<div>Staked:</div>
									<div><Money>{totals.stake || 0}</Money></div>
								</Grid.Column>
								<Grid.Column width={4}>
									<div>Staked Rewarded:</div>
									<div><Money>{totals.stake_rewarded || 0}</Money></div>
								</Grid.Column>
								<Grid.Column width={4}>
									<div>Staked Not Rewarded:</div>
									<div><Money>{totals.stake_not_rewarded || 0}</Money></div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					: null
					} */}
					<NodesList nodes={nodes} />
					<NodesMap nodes={nodes} />
					<NodesTable nodes={nodes} />
				</Panel>
			: <LoadingIndicator />
		);
	}
}

const mapStateToProps = (state) => ({
	nodes: getNodes(state)
});

Nodes = connect(mapStateToProps, actions)(Nodes);

export default Nodes;
