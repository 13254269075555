import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../redux/actions';
import { getAccountTransactions, getAccountTransactionsCount } from '../../redux/reducers';
import TransactionTable from '../TransactionTable';
import { removeEmpty, concat } from '../../utils';
import { Grid } from 'semantic-ui-react';
import SearchFacets from '../SearchFacets';
class AccountTransactions extends React.Component {
	state = {
		viewParams: {
			offset: 0,
			limit: 25,
			page: 1,
			sort: 'consensusTime',
			order: 'desc'
		},
		filterParams: {}
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.accountId !== prevProps.accountId || this.props.filter !== prevProps.filter) {
			this.setState(
				{
					offset: 0,
					page: 1
				},
				() => this.fetchData()
			);
		}
	}

	showTransactionDetails = (transaction) => {
		this.props.history.push(`/transactions/${transaction.transactionID}`);
	};

	loadData = (viewParams) => {
		const params = { ...this.state.viewParams, ...viewParams };
		this.setState({ viewParams: params }, () => this.fetchData());
	};

	fetchData = () => {
		const { filter, accountId, fetchAccountTransactions } = this.props;
		const params = { q: filter, ...this.state.viewParams };

		const queryParams = removeEmpty(params);

		const { viewParams, filterParams } = this.state;

		const esc = encodeURIComponent;
		const searchQuery = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');
		const filterQuery = Object.keys(filterParams)
			.map((f) => {
				const values = filterParams[f];
				return values.map((v) => esc(f) + '=' + esc(v)).join('&');
			})
			.join('&');
		const query = searchQuery + '&' + filterQuery;
		console.log('query = ', query);

		fetchAccountTransactions(accountId, query);
	};

	handleFilterToggle = (e, { name, checked, category }) => {
		const oldArray = this.state.filterParams[category] || [];
		let newArray;
		if (checked) newArray = concat([ name ], oldArray);
		else {
			newArray = oldArray.filter((k) => k !== name);
		}

		// console.log('newArray = ', newArray);
		//...this.state.filterParams.category, name ======== this line was commented out before commenting out the logs
		this.setState(
			{
				filterParams: {
					...this.state.filterParams,
					[category]: newArray
				},
				offset: 0
			},
			() => this.fetchData()
		);
	};

	render() {
		const { transactions, totalCount, filters } = this.props;
		return (
				<Grid padded stackable>
					{filters && (
						<Grid.Column width={4}>
							<SearchFacets filters={filters} onFilterToggle={this.handleFilterToggle} />
						</Grid.Column>
					)}
					<Grid.Column width={filters ? 12 : 16}>
						{/* <Panel> */}
							<TransactionTable
								transactions={transactions}
								limit={this.state.viewParams.limit}
								offset={this.state.viewParams.offset}
								sort={this.state.viewParams.sort}
								order={this.state.viewParams.order}
								totalCount={totalCount}
								showDetails={this.showTransactionDetails}
								loadData={this.loadData}
							/>
						{/* </Panel> */}
					</Grid.Column>
				</Grid>
		);
	}
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
	accountId,
	transactions: getAccountTransactions(state, accountId),
	totalCount: getAccountTransactionsCount(state, accountId)
});

AccountTransactions = withRouter(connect(mapStateToProps, actions)(AccountTransactions));

export default AccountTransactions;
