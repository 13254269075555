import {
  FETCH_ALL_FEATURES_SUCCESS,
  FETCH_ALL_FEATURES_FAILURE
} from '../types';

const initialState = {};

export default function allFeatures(state = initialState, action) {
	switch (action.type) {
		case FETCH_ALL_FEATURES_SUCCESS:
			return {
				...state,
				featuresData: action.data
			};
		case FETCH_ALL_FEATURES_FAILURE:
			return {
				...state,
				featuresData: {}
			};
		default:
			return state;
	}
}
