import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import '../animate.css';

const CopyTextToClipboard = (props) => {

    return (
        <Popup
            trigger={<Icon name="copy" className="copy-icon" onClick={props.handleCopyClick} />}
            content={<span className={props.clicked ? "info-content animated flash" : "info-content"}>
                        {props.clicked ? "Copied!" : "Copy to clipboard"}
                    </span>}
            // position=/*"right center"*/ "bottom center"
            hoverable
            style={{cursor: "pointer"}}
            basic
        />
    )
}

export default CopyTextToClipboard;