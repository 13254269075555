import { FETCH_TRANSACTION_TYPES_NON_HCS_SUCCESS, FETCH_TRANSACTION_TYPES_NON_HCS_LOAD } from '../types';

export default function transactionTypesNonHcs(state = {}, action) {
	switch (action.type) {
		case FETCH_TRANSACTION_TYPES_NON_HCS_LOAD:
			return {
				loading: true,
				types: [...action.payload]
			};
		case FETCH_TRANSACTION_TYPES_NON_HCS_SUCCESS:
			return {
				loading: false,
				types: [...action.payload]
			};
		default:
			return state;
	}
}

export const getTransactionTypesNonHcs = (state) => state;
