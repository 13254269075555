import React, { useEffect } from 'react';

import '../animate.css';

const SubscriptionDescriptionEvent = () => {

    useEffect(() => { window.scrollTo(0, 0) }, []);

    return (
        <div className="description-container animated fadeIn">
            {/* <div className="description-text">
                At the moment, an ABI upload is required to subscribe to an event; it will be 
                optional in future releases. Once a subscription is created, a user can upload an 
                ABI file by clicking the icon in the Upload ABI column in the My Subscriptions menu in their profile.
            </div> */}
            
            {/* --------------- commented out earlier ---------------------- */}
            {/* <strong>Upload ABI</strong><br />
            <div className="description-text">Additionally, DragonGlass provides the ability for users to 
                upload ABIs for EVENTS and TRANSACTIONS exclusively.  Once a subscription is created, a user can upload an 
                ABI file by clicking the icon in the Upload ABI column in the My Subscriptions menu in their profile.
            </div> */}
            {/* ------------------------------------------------------------ */}

            {/* <div className="image-div">
                <img width="90%" src="/upload_ABI.png" />
            </div>
            <div className="description-text">
                The ABI is going to be used for parsing the event data in case of a subscription type EVENT.
            </div> */}
                
            <div className="description-text">Subscribing to an event means that for a contract call, 
                if a specified event is emitted, the subscription service will push the event details 
                to the DragonGlass queue.  Users can add an event subscription by selecting the Add a 
                Subscription button in the My Subscriptions section of their Profile.
            </div>
            <div className="image-div">
                <img width="90%" src="/blank_my_subscription.png" alt="" />
            </div>
            <div className="description-text">
                When creating an event subscription, users must select Free Subscription in the 
                Subscription Model field, and EVENT in Subscription Type field.  Clicking Submit 
                will create the event subscription.
            </div>
            {/* -------------------------------- */}
            <div className="image-div">
                <img width="90%" src="/create_event_subscription1.png" alt="" />
            </div>

            {/* -------------------------------- */}
            <div className="description-text">
                At the moment, an ABI upload is required to subscribe to an event; it will be 
                optional in future releases. Once a subscription is created, a user can upload an 
                ABI file by clicking the icon in the Upload ABI column in the My Subscriptions menu in their profile.
            </div>

            <div className="image-div">
                <img width="90%" src="/upload_ABI.png" />
            </div>
            <div className="description-text">
                The ABI is going to be used for parsing the event data in case of a subscription type EVENT.
            </div>
            {/* -------------------------------- */}

            <div className="description-text">
                Once the subscription is submitted, the user will see it in their My Subscriptions menu.  
                After creation, users will then have to add filters; if filters are not added, the DragonGlass 
                queue will not have any messages for the user.
            </div>
            <div className="image-div">
                <img width="90%" src="/add_filter_for_event_edited.png" alt="" />
            </div>
            <div className="description-text">
                By clicking the Filter icon in the Filters column of the subscription table and selecting the Add 
                Filter button, the user can define additional filters for the event subscription.  A user must select 
                the Filter Type (the only available option being FUNCTION_NAME) and enter the contract IDs, as well as 
                adding a Function Name.  Users can add a Function Name filter that will request the contract IDs and the 
                function names; these names will take either the name of the event, such as “Transfer”, or the EventSignature 
                in Hex that is present in topic zero of your contract log info.
            </div>
            <div className="image-div">
                <img width="90%" src="/display_filter_for_event.png" alt="" />
            </div>
            <div className="description-text">
                Once the filter parameters are defined, the user selects the Add Filter button, and the new filter will be seen 
                in My Subscriptions.  If a filter has already been created, clicking the Filter icon will display a table providing 
                an option to delete an existing filter.
            </div>
        </div>
    )
}

export default SubscriptionDescriptionEvent;
