import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Table, Checkbox, Button, Input } from 'semantic-ui-react';
import { fromDate } from '../../utils';
import Number from '../shared/Number';
import { getUser } from '../../redux/reducers';

const SubscriptionForm = ({
    
    selectedModel,
    date,
    dataPeriod,
    count,
    setSelectedModel,
    setDate,
    setDataPeriod,
    setCount,
    onSubmit,
    handleSubscribeMessage
    }) => {

	// const handleCellClick = (id) => {
    //     window.open(`/dapps/${id}`, '_blank');
    // }
    
    // const [selectedModel, setSelectedModel] = useState('');
    // const [date, setDate] = useState('');
    const [localDataPeriod, setLocalDataPeriod] = useState('');
    // const [count, setCount] = useState('');

    const [disabled, setDisabled] = useState(true);
    const [dateDisabled, setDateDisabled] = useState(true);
    const [dataPeriodDisabled, setDataPeriodDisabled] = useState(true);
    const [countDisabled, setCountDisabled] = useState(true);

    const selectModel = () => {
        console.log("THIS MODEL HAS BEEN SELECTED: ", selectedModel)
        // setSelectedDapp({ 
        //     ...dapp, 
        //     userName: props.user.name, 
        //     userId: props.user.id, 
        //     userEmail: props.user.email, 
        //     userCompany: props.user.company 
        // });
        setDisabled(false);
    }

    const printSelectedModel = (e) => {
        console.log("THIS IS THE SELECTED MODEL: ", selectedModel);
        // props.selectDappToOwn(selectedDapp.id, selectedDapp);
    }

	return (
        <Fragment>
            <Table basic="very" selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Click to Select</Table.HeaderCell>
                        <Table.HeaderCell width={7}>Subscription Model</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row style={{transition: "all 0.2s ease-in-out"}}>
                        <Table.Cell id="dapp-desc-homepage" style={{paddingRight: "15px", paddingLeft: "11px"}}>
                                <Checkbox 
                                    checked={selectedModel === 'expire-date'} 
                                    onChange={(e) => {setSelectedModel('expire-date'); setDisabled(false); setDateDisabled(false); setDataPeriodDisabled(true); setCountDisabled(true)}}/>
                        </Table.Cell>
                        <Table.Cell style={{paddingLeft: "10px"}}>
                                <div className="logo-title"><strong className="text-gray" style={{fontSize: "115%"}}>Exipre Date</strong></div>
                                <div style={{margin: "15px 0"}}><Input type="date" size="small" disabled={dateDisabled} onChange={e => setDate(e.target.value)} /></div>
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row style={{transition: "all 0.2s ease-in-out"}}>
                        <Table.Cell id="dapp-desc-homepage" style={{paddingRight: "15px", paddingLeft: "11px"}}>
                                <Checkbox 
                                    checked={selectedModel === 'data'} 
                                    onChange={(e) => {setSelectedModel('data'); setDisabled(false); setDateDisabled(true); setDataPeriodDisabled(false); setCountDisabled(true)}}/>
                        </Table.Cell>
                        <Table.Cell style={{paddingLeft: "10px"}}>
                                <div className="logo-title"><strong className="text-gray" style={{fontSize: "115%"}}>Data</strong></div>
                                <div className="data-period-selection">
                                    <Checkbox onChange={(e) => {setDataPeriod('15 days'); setLocalDataPeriod('15 days')}} checked={localDataPeriod === "15 days"} label="15 days" style={{marginRight: "30px"}} disabled={dataPeriodDisabled} />
                                    <Checkbox onChange={(e) => {setDataPeriod('1 month'); setLocalDataPeriod('1 month')}} checked={localDataPeriod === "1 month"} label="1 month" style={{marginRight: "30px"}} disabled={dataPeriodDisabled} />
                                    <Checkbox onChange={(e) => {setDataPeriod('1 year'); setLocalDataPeriod('1 year')}} checked={localDataPeriod === "1 year"} label="1 year" disabled={dataPeriodDisabled} />
                                </div>
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row style={{transition: "all 0.2s ease-in-out"}}>
                        <Table.Cell id="dapp-desc-homepage" style={{paddingRight: "15px", paddingLeft: "11px"}}>
                                <Checkbox 
                                    checked={selectedModel === 'count'} 
                                    onChange={(e) => {setSelectedModel('count'); setDisabled(false); setDateDisabled(true); setDataPeriodDisabled(true); setCountDisabled(false)}}/>
                        </Table.Cell>
                        <Table.Cell style={{paddingLeft: "10px"}}>
                                <div className="logo-title"><strong className="text-gray" style={{fontSize: "115%"}}>Count</strong></div>
                                <div style={{margin: "15px 0"}}><Input type="number" size="small" disabled={countDisabled} onChange={e => setCount(e.target.value)} /></div>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Button 
                type="submit" 
                style={{margin: "30px 0 20px 0", float: "right"}} 
                primary 
                disabled={disabled} 
                onClick={e => {printSelectedModel(); onSubmit(e); handleSubscribeMessage()}}
                >
                SUBMIT
            </Button>
        </Fragment>
	);
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default withRouter(connect(mapStateToProps, actions)(SubscriptionForm));
