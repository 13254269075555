import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Autocomplete from '../components/shared/Autocomplete';
import * as actions from '../redux/actions';
import { isAuthed, getSearchedTransactionsCount, getSearchedAccountsCount, getSearchedContractsCount, getSearchedTopicsCount } from '../redux/reducers';
import { getHederaTokensCount } from '../redux/reducers/hts';
import { removeEmpty, isRegexValidForId, isMobile } from '../utils';
import { FUNGIBLE_TOKEN, NON_FUNGIBLE_TOKEN } from '../constants';
import { Button, Container, Grid, Image, Radio } from 'semantic-ui-react';
import filterIcon from '../assets/images/filter.png';
import AdvSearchForm from './forms/AdvSearchForm';

const getInitialState = () => {
	const initialState = {
		searchParams: {
			q: '',
			startDate: null,
			endDate: null,
			accountFrom: '',
			accountTo: '',
			amountFrom: '',
			amountTo: '',
			contractID: '',
			period: null,
			transactionTypes: '',
			serviceTypes: ''
		},
		isAdvanced: false,
		activeTabIndex: 0,
		value: ''
	};
	return initialState;
};
class Search extends Component {
	constructor(props) {
    super(props);
	this.state = getInitialState();
	}

	handleAdvancedSearchToggle = (e, { checked }) => {
		this.setState({ isAdvanced: checked });
	};

	styles = {
		containerStyle: {
			marginTop: '1%',
			paddingLeft: isMobile() ? ' ' : '13%',
			paddingRight: isMobile() ? ' ' : '13%',
		  },
		contentGrid: {
			marginBottom: '20px',
	
		  },
		  searchInput: {
			marginTop: '20px',
		  },
		  p1: {
			fontSize: '20px',
			fontWeight: '600'
		  },
		  p2: {
			fontSize: '12px',
			fontWeight: '300',
		  },
		  linesContainerStyle: {
			display: 'flex',
			justifyContent: 'right',
		  },
		
		  lineStyle: {
			margin: '8px 0px',
		  },
		  button: {
			borderRadius: "0px"
		  },
		  toggle: {
			display: 'flex',
    		flexDirection: 'column',
    		justifyContent: 'center',
		  }
	}

	toggleAdvanceFilter = (e) => {
		this.setState({ isAdvanced: !this.state.isAdvanced });
	};

	handleChange = (e, { name, value }) => {
		this.setState((state, props) => {
			const { searchParams } = state;
			return { searchParams: { ...searchParams, [name]: value } };
		});
	};

	handleReset = () => {
		this.setState({ ...getInitialState(), isAdvanced: true });
	};

	handleSearch = () => {
		const { searchParams } = this.state;
		const params = { ...searchParams, isAdvanced: this.state.isAdvanced };
		// console.log('params = ', params);
		const queryParams = removeEmpty(params);
		// console.log('queryParams = ', queryParams);
		const isMatch = isRegexValidForId(params.q);
		this.doSearch(queryParams, isMatch);
	};

	doSearch = (queryParams, isMatch) => {
		const searchText = queryParams.q;
		const esc = encodeURIComponent;
		const queryParam = Object.keys(queryParams)
		.map((p) => esc(p) + '=' + esc(queryParams[p]))
		.join('&');
		console.log('queryParams', queryParam);
		this.props.history.push(`/search?${queryParam}`);
		return ;
		const { fetchTransactions, fetchAccounts, fetchContracts, fetchTopics, fetchTokens } = this.props;
		const countOnlyParams = { ...queryParams, limit: 0 };
		// const esc = encodeURIComponent;
		const countOnlyQuery = Object.keys(countOnlyParams)
			.map((p) => esc(p) + '=' + esc(countOnlyParams[p]))
			.join('&');
		const a = fetchAccounts(countOnlyQuery, false);
		const c = fetchContracts(countOnlyQuery, false);
		const tp = fetchTopics(countOnlyQuery, false);
		const ht = fetchTokens(countOnlyParams);

		Promise.all([ a, c, tp, ht ]).then(() => {
			const { acctCount, cxtCount, topicsCount, tokensCount } = this.props;
			if (isMatch) {
				if (acctCount) {
					this.props.history.push(`/accounts/${searchText}`);
				} else if (tokensCount) {
					this.props.history.push(`/tokens/${searchText}`);
				} else if (topicsCount) {
					this.props.history.push(`/topics/${searchText}`);
				} else if (cxtCount) {
					this.props.history.push(`/contracts/${searchText}`);
				} else {
					this.props.history.push(`/search?q=${searchText}`);
				}
				return;
			}
			const t = fetchTransactions(countOnlyQuery, false);
			const { txCount } = this.props;
			if (txCount) {
				this.props.history.push(`/transactions/${searchText ? searchText : ''}`);
			} else {
				this.props.history.push(`/search?q=${searchText ? searchText : ''}`);
			}
		});

	};

	render() {
		const { searchParams, isAdvanced, activeIndex, value } = this.state;
		const {onlyHts} = this.props;
		const placeholder = <span style={{fontSize: "12px", color: "#999"}}></span>
		return (
			<Fragment>
				<Container fluid textAlign="center" className="app-container" style={this.styles.containerStyle}>
					{/* <Grid centered columns={1} className="content-grid" style={this.styles.contentGrid}> */}
						{/* <Grid.Row>
						<Grid.Column textAlign="justify">
							<p style={this.styles.p1}>What Would you like to explore?</p>
						</Grid.Column>
						</Grid.Row> */}
						{/* <Grid.Row style={{padding: '0px'}}>
						<Grid.Column textAlign="justify">
							<p style={this.styles.p2}>Search the hashgrah like never before. All data since it went open access.</p>
						</Grid.Column>
						</Grid.Row> */}
					{/* </Grid> */}
					<AdvSearchForm
						{...searchParams}
						showLabel = {false}
						isAdvanced={isAdvanced}
						onChange={this.handleChange}
						handleSearch={this.handleSearch}
						showSearchBtn={true}
						toggleAdvanceFilter={this.toggleAdvanceFilter}
					/>
					<div>
					<div style={this.styles.linesContainerStyle}>
						{/* <div style={{margin: '10px'}}>
							<Image src={filterIcon} size="mini" onClick={this.toggleAdvanceFilter}></Image>
						</div> */}
						{ isAdvanced ? 
							<div style={this.styles.lineStyle}><Button primary style={this.styles.button} onClick={this.handleSearch}>Advance Search</Button></div>
						: null}
						{ isAdvanced ? 
							<div style={this.styles.lineStyle}><Button primary style={this.styles.button} onClick={this.handleReset}>Reset</Button></div>
						: null}
						{/* <div style={{...this.styles.lineStyle, ...this.styles.toggle}}><span><Radio style={this.styles.toggle} toggle label="ADVANCED" className="header" checked={isAdvanced} onChange={this.toggleAdvanceFilter} /></span></div> */}
						{/* <div style={this.styles.lineStyle}><Button primary style={this.styles.button}>Surprise</Button></div> */}
					</div>
					</div>
				</Container>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	acctCount: getSearchedAccountsCount(state),
	txCount: getSearchedTransactionsCount(state),
	cxtCount: getSearchedContractsCount(state),
	topicsCount: getSearchedTopicsCount(state),
	tokensCount: getHederaTokensCount(state),
});

Search = withRouter(connect(mapStateToProps, actions)(Search));

export default Search;
