import React, { Component } from "react";
import Panel from "./shared/Panel";
import PeriodSelector from "./shared/PeriodSelector";
import AccountCreationVolumeChart from "./containers/AccountCreationVolumeChart";
import Info from "./shared/Info";
import SourceInWidget from "./SourceInWidget";
import { parseQueryParams } from  '../utils';
import { withRouter } from 'react-router-dom';
import Share from './shared/Share';

export class AccountCreationVolumeChartPanel extends Component {
    constructor(props) {
    super(props);
        this.state = {
            period: "1D",
            icon: "chart line",
        };
    }

    componentDidMount() {
        const params = parseQueryParams(this.props.location.search);
        if(params && params.period) {
            this.setState({
                period: params.period,
                icon: params.chartType == 'bar' ? "chart bar" : "chart line",
            });
        }

	}

    onPeriodSelect = (e, { value }) => {
        this.setState({ period: value });
        //to do
        //update price and percent change
    };

    toggle = () => {
        if (this.state.icon === "chart line") {
            this.setState({ icon: "chart bar" });
        } else {
            this.setState({ icon: "chart line" });
        }
    };

    render() {
        const { period, icon } = this.state;
        let chartType = 'line';
        if  (icon == "chart bar") {
            chartType = 'bar';
        }
        let url = `/chart?name=account-creation&period=${this.state.period}&chartType=${chartType}`;
		const params = parseQueryParams(this.props.location.search);
        return (
            <Panel
                title={
                    <React.Fragment>
                        ACCOUNT CREATION <Info id="accountCreationVolume" />
                        <SourceInWidget />
                    </React.Fragment>
                }
                titleClass="light"
                description=""
                toggle={this.toggle}
                icon={this.state.icon}
                type="txnVolume"
                footer={<Share url={url} params={params} title={"Dragon Glass Account Creation Volume Chart"}/>}
                toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}>
                <AccountCreationVolumeChart period={period} graphType={this.state.icon} />
            </Panel>
        );
    }
}

export default withRouter(AccountCreationVolumeChartPanel);