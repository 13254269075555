import React, { Component } from 'react';
import { Icon, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

class NavLinksForPC extends Component {
	render() {
		const { location } = this.props;
		console.log('location', location.pathname);
		return (<>
			<Grid.Column width={2}><Link style={{ color: location.pathname === '/accounts' ? '#1b97ae' : '#FFF' }} to="/accounts">Accounts</Link></Grid.Column>
			<Grid.Column width={3}><Link style={{ color: location.pathname === '/accounts' ? '#1b97ae' : '#FFF' }} to="/accounts">Transactions</Link></Grid.Column>
			<Grid.Column width={2}><Link style={{ color: location.pathname === '/accounts' ? '#1b97ae' : '#FFF' }} to="/accounts">Token</Link></Grid.Column>
			<Grid.Column width={2}><Link style={{ color: location.pathname === '/accounts' ? '#1b97ae' : '#FFF' }} to="/accounts">Nfts</Link></Grid.Column>
			<Grid.Column width={2}><Link style={{ color: location.pathname === '/accounts' ? '#1b97ae' : '#FFF' }} to="/accounts">Topics</Link></Grid.Column>
			<Grid.Column width={2}><Link style={{ color: location.pathname === '/accounts' ? '#1b97ae' : '#FFF' }} to="/accounts">Contracts</Link></Grid.Column>
			<Grid.Column width={3}><Link style={{ color: location.pathname === '/nft-explorer' ? '#1b97ae' : '#FFF' }} to="/nft-explorer">NFT Explorer</Link></Grid.Column>
		</>)
	}
}

const mapStateToProps = (state) => ({
	
});

export default withRouter(connect(mapStateToProps, null)(NavLinksForPC));
