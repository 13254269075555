import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import Panel from '../shared/Panel';
import SearchFacets from '../SearchFacets';
import TransactionTable from '../TransactionTable';
import * as actions from '../../redux/actions';
import {
	getSearchParams,
	getSearchedTransactions,
	getSearchedTransactionsCount,
	getSearchedTransactionsFilters
} from '../../redux/reducers';
import { removeEmpty, concat } from '../../utils';

class TransactionSearch extends Component {
	state = {
		viewParams: {
			offset: 0,
			limit: 25,
			page: 1,
			sort: 'consensusTime',
			order: 'desc'
		},
		filterParams: {}
	};

	componentDidMount = () => {
		const { searchParams } = this.props;
		// console.log('TransactionSearch searchParams = ', searchParams);
		if (searchParams) this.fetchData();
	};

	componentDidUpdate(prevProps) {
		if (this.props.searchParams !== prevProps.searchParams) {
			this.fetchData();
		}
	}

	fetchData = () => {
		const { searchParams, fetchTransactions } = this.props;
		const { viewParams, filterParams } = this.state;

		const params = { ...searchParams, ...viewParams };
		// console.log('params = ', params);
		const queryParams = removeEmpty(params);
		// console.log('queryParams = ', queryParams);
		const esc = encodeURIComponent;
		const searchQuery = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');
		// console.log('searchQuery = ', searchQuery);
		const filterQuery = Object.keys(filterParams)
			.map((f) => {
				const values = filterParams[f];
				return values.map((v) => esc(f) + '=' + esc(v)).join('&');
			})
			.join('&');
		// console.log('filterQuery = ', filterQuery);
		const query = searchQuery + '&' + filterQuery;
		console.log('query = ', query);

		fetchTransactions(query, filterQuery ? true : false);
	};

	loadData = (viewParams) => {
		const params = { ...this.state.viewParams, ...viewParams };
		this.setState({ viewParams: params }, () => this.fetchData());
	};

	showTransactionDetails = (transaction) => this.props.history.push(`/transactions/${transaction.transactionID}`);

	handleFilterToggle = (e, { name, checked, category }) => {
		// console.log('handleFilterToggle');
		// console.log('name = ', name);
		// console.log('checked = ', checked);
		// console.log('category = ', category);
		const oldArray = this.state.filterParams[category] || [];
		// console.log('oldArray = ', oldArray);
		let newArray;
		if (checked) newArray = concat([ name ], oldArray);
		else {
			newArray = oldArray.filter((k) => k !== name);
		}

		// console.log('newArray = ', newArray);
		//...this.state.filterParams.category, name ======== this line was commented out before commenting out the logs
		this.setState(
			{
				filterParams: {
					...this.state.filterParams,
					[category]: newArray
				},
				viewParams: {
					...this.state.viewParams,
					offset: 0
				}
			},
			() => this.fetchData()
		);
	};

	render() {
		const { filters, transactions, totalCount } = this.props;
		const { viewParams: { limit, offset, sort, order } } = this.state;
		// console.log('TXN Search filters = ', filters);
		return (
			<Grid padded stackable>
				{filters && (
					<Grid.Column width={4}>
						<SearchFacets isComingFrom="transactions" filters={filters} onFilterToggle={this.handleFilterToggle} />
					</Grid.Column>
				)}
				<Grid.Column width={filters ? 12 : 16}>
					<Panel>
						<TransactionTable
							totalCount={totalCount}
							transactions={transactions}
							limit={limit}
							offset={offset}
							sort={sort}
							order={order}
							loadData={this.loadData}
							showDetails={this.showTransactionDetails}
						/>
					</Panel>
				</Grid.Column>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	searchParams: getSearchParams(state),
	transactions: getSearchedTransactions(state),
	totalCount: getSearchedTransactionsCount(state),
	filters: getSearchedTransactionsFilters(state)
});

TransactionSearch = withRouter(connect(mapStateToProps, actions)(TransactionSearch));

export default TransactionSearch;
