import React, {useEffect, useState} from 'react';
import { Table, Image, Popup } from 'semantic-ui-react';
import NftsBalanceTable from './NftsBalanceTable';
import Panel from './shared/Panel';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getSearchNFTsData } from '../redux/reducers';
import '../animate.css';

const NftsList = (props) => {

	const [limit, setLimit] = useState(5);
	const [page, setPage] = useState(1);
	const [offset, setOffset] = useState(0);
	const [order, setOrder] = useState(0);
	const [sort, setSort] = useState(0);
	const { nftsData } = props;
	const nfts = nftsData && nftsData.nfts || [];
	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = (viewParams) => {
		const { fetchSearchNfts, transactionID } = props;
		const params = { mintTransactionId: transactionID, limit, page, offset, ...viewParams };
		fetchSearchNfts(params);
	}

	const loadData = (viewParams) => {
		const params = { ...viewParams };
		setLimit(params.limit);
		setOffset(params.offset);
		setPage(params.page);
		fetchData(params);
	};

	return (
		<Panel>
			<div id="token-search-wrapper scroll-table-x">
				<NftsBalanceTable
					nftsData={nfts}
					size={limit}
					offset={offset}
					sort={sort}
					order={order}
					totalCount={nftsData.totalCount}
					loadData={loadData}
				/>
			</div>
		</Panel>
	);
}

const mapStateToProps = (state) => ({
	// id: txId,
	// transaction: getTransaction(state, txId),
	nftsData: getSearchNFTsData(state),
});

export default connect(mapStateToProps, actions)(NftsList);
