import { combineReducers } from "redux";
import {
    FETCH_ALL_TRANSACTION_CHART_LOAD,
    FETCH_ALL_TRANSACTION_CHART_SUCCESS,
    FETCH_ALL_TRANSACTION_CHART_FAILURE,
	FETCH_TRANSACTION_TYPE_DIMENSION_CHART_LOAD,
	FETCH_TRANSACTION_TYPE_DIMENSION_CHART_SUCCESS,
	FETCH_TRANSACTION_TYPE_DIMENSION_CHART_FAILURE,
	FETCH_ACCOUNTS_CREATED_CHART_LOAD,
	FETCH_ACCOUNTS_CREATED_CHART_SUCCESS,
	FETCH_ACCOUNTS_CREATED_CHART_FAILURE,
	FETCH_NFT_MINTED_CHART_LOAD,
	FETCH_NFT_MINTED_CHART_SUCCESS,
	FETCH_NFT_MINTED_CHART_FAILURE,
	FETCH_NFT_TRANSFER_CHART_LOAD,
	FETCH_NFT_TRANSFER_CHART_SUCCESS,
	FETCH_NFT_TRANSFER_CHART_FAILURE,
	FETCH_TOKEN_TRANSFER_CHART_LOAD,
	FETCH_TOKEN_TRANSFER_CHART_SUCCESS,
	FETCH_TOKEN_TRANSFER_CHART_FAILURE,
	FETCH_TOKEN_MINTED_CHART_LOAD,
	FETCH_TOKEN_MINTED_CHART_SUCCESS,
	FETCH_TOKEN_MINTED_CHART_FAILURE,


} from "../types";

const allTransactionsCharts = (state = {}, action) => {
    switch (action.type) {
		// All TXN
        case FETCH_ALL_TRANSACTION_CHART_LOAD:
            return {
				loading: true,
				...action.payload
			};
		case FETCH_ALL_TRANSACTION_CHART_SUCCESS:
            return {
				loading: false,
				...action.payload
			};
		case FETCH_ALL_TRANSACTION_CHART_FAILURE:
			return {
				loading: false,
			};
        default:
            return state;
    }
};

const transactionsTypeDimensionCharts = (state = {}, action) => {
    switch (action.type) {
		// Dimension
		case FETCH_TRANSACTION_TYPE_DIMENSION_CHART_LOAD:
            return {
				loading: true,
				...action.payload
			};
		case FETCH_TRANSACTION_TYPE_DIMENSION_CHART_SUCCESS:
            return {
				loading: false,
				...action.payload
			};
		case FETCH_TRANSACTION_TYPE_DIMENSION_CHART_FAILURE:
			return {
				loading: false,
			};
        default:
            return state;
    }
};

const accountsCreatedCharts = (state = {}, action) => {
    switch (action.type) {
		// Dimension
		case FETCH_ACCOUNTS_CREATED_CHART_LOAD:
            return {
				loading: true,
				...action.payload
			};
		case FETCH_ACCOUNTS_CREATED_CHART_SUCCESS:
            return {
				loading: false,
				...action.payload
			};
		case FETCH_ACCOUNTS_CREATED_CHART_FAILURE:
			return {
				loading: false,
			};
        default:
            return state;
    }
};

const nftMintedCharts = (state = {}, action) => {
    switch (action.type) {
		// Dimension
		case FETCH_NFT_MINTED_CHART_LOAD:
            return {
				loading: true,
				...action.payload
			};
		case FETCH_NFT_MINTED_CHART_SUCCESS:
            return {
				loading: false,
				...action.payload
			};
		case FETCH_NFT_MINTED_CHART_FAILURE:
			return {
				loading: false,
			};
        default:
            return state;
    }
};

const nftTransferCharts = (state = {}, action) => {
    switch (action.type) {
		// Dimension
		case FETCH_NFT_TRANSFER_CHART_LOAD:
            return {
				loading: true,
				...action.payload
			};
		case FETCH_NFT_TRANSFER_CHART_SUCCESS:
            return {
				loading: false,
				...action.payload
			};
		case FETCH_NFT_TRANSFER_CHART_FAILURE:
			return {
				loading: false,
			};
        default:
            return state;
    }
};

const tokenTransferCharts = (state = {}, action) => {
    switch (action.type) {
		// Dimension
		case FETCH_TOKEN_TRANSFER_CHART_LOAD:
            return {
				loading: true,
				...action.payload
			};
		case FETCH_TOKEN_TRANSFER_CHART_SUCCESS:
            return {
				loading: false,
				...action.payload
			};
		case FETCH_TOKEN_TRANSFER_CHART_FAILURE:
			return {
				loading: false,
			};
        default:
            return state;
    }
};

const tokenMintedCharts = (state = {}, action) => {
    switch (action.type) {
		// Dimension
		case FETCH_TOKEN_MINTED_CHART_LOAD:
            return {
				loading: true,
				...action.payload
			};
		case FETCH_TOKEN_MINTED_CHART_SUCCESS:
            return {
				loading: false,
				...action.payload
			};
		case FETCH_TOKEN_MINTED_CHART_FAILURE:
			return {
				loading: false,
			};
        default:
            return state;
    }
};

const charts = combineReducers({
    allTransactionsCharts,
	transactionsTypeDimensionCharts,
	accountsCreatedCharts,
	nftMintedCharts,
	nftTransferCharts,
	tokenTransferCharts,
	tokenMintedCharts,
});

export default charts;

export const getAllTransactionsChart = state => state.charts && state.charts.allTransactionsCharts;
export const getTransactionsTypeDimensionCharts = state => state.charts && state.charts.transactionsTypeDimensionCharts;
export const getAccountsCreatedCharts = state => state.charts && state.charts.accountsCreatedCharts;
export const getNftMintedCharts = state => state.charts && state.charts.nftMintedCharts;
export const getNftTransferCharts = state => state.charts && state.charts.nftTransferCharts;
export const getTokenTransferCharts = state => state.charts && state.charts.tokenTransferCharts;
export const getTokenMintedCharts = state => state.charts && state.charts.tokenMintedCharts;