import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { getNftsDetail, getNftsHistoricalDetail, getNftsHistoricalDetailCount } from './../../redux/reducers';
import { getTokenDetails } from "../../redux/reducers/hts";
import NftsDetails from '../NftsDetails';
import { Header } from "semantic-ui-react";
import NftHistoricalTable from '../NftHistoricalTable';
import { parseQueryParams } from  '../../utils';
class NftsData extends Component {
	constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 5,
      sort: '',
	    order: 'desc',
      page: 1
    };
  }

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate() {
		const { token } = this.props;

		if(this.props.token && this.props.token.name) {
			this.props.tokenName(token);
		}
	}

	fetchHistoryData = () => {
		const { fetchNftsHistoricalDetails, tokenId } = this.props;
		const params = parseQueryParams(this.props.location.search);
		const nftHistoryParams = {"serialNumber": params.serialNumber, "tokenId": tokenId , ...this.state };
		fetchNftsHistoricalDetails(nftHistoryParams);
  };

	fetchData() {
		const { tokenId, fetchNftsDetails, fetchTokenDetails, token } = this.props;
		const params = parseQueryParams(this.props.location.search);
		let nftDetail = {"serialNumber": params.serialNumber, "tokenId": tokenId}
		fetchTokenDetails({ "tokenId": tokenId});
		fetchNftsDetails(nftDetail);
		this.fetchHistoryData();
	}

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchHistoryData());
	};

	render() {
		const { nftsDetail, nftHistoricalData, totalCount } = this.props;
		const { limit, offset } = this.state;


		 return (
		 <React.Fragment>
		 	<NftsDetails nftsDetail={nftsDetail} />

			 <Header as="h5">HISTORICAL TRANSFERS</Header>
			 <div id="nfts-transactions-table">
				 <NftHistoricalTable
					 nftHistoricalData={nftHistoricalData}
					 limit={limit}
					 offset={offset}
					 sort={this.state.sort}
					 order={this.state.order}
					 totalCount={totalCount}
					 loadData={this.loadData}
				 />
			 </div>
		 </React.Fragment>
		 )
	}
}
const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
	tokenId: tokenId,
	nftsDetail : getNftsDetail(state),
	nftHistoricalData : getNftsHistoricalDetail(state),
	totalCount: getNftsHistoricalDetailCount(state),
	token: getTokenDetails(state),
});

NftsData = withRouter(connect(mapStateToProps, actions)(NftsData));

export default NftsData;
