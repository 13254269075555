import React, { useState } from 'react';
import Panel from './shared/Panel';
import NftsTokenBalance from './NftsTokenBalance';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Info from './shared/Info';
import Filter from './shared/Filter';

const NftsTokenPanel = (props) => {
	const [ filterText, setfilterText ] = useState('');

	function onSubmitFilter (value) {
		setfilterText(value);
	};

	return (
		<Panel
			title={
				<React.Fragment>
					<Info id="nftsToken" 
					/>
				</React.Fragment>
			}
			toolbar={
				<React.Fragment>
					<Filter onSubmitFilter={onSubmitFilter} />
				</React.Fragment>
			}
		>
      <NftsTokenBalance filterText={filterText}/>
		</Panel>
	);
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
  tokenId: tokenId
});

export default withRouter(connect(mapStateToProps)(NftsTokenPanel));