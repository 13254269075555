import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import DateCell from './shared/DateCell';
import Transfers from './containers/Transfers';
import { numberToReadableFormat, adjustWithDecimals } from '../utils';
import eye from '../assets/images/eye@2x.png';
import Number from './shared/Number';
import PopupWithContent from './containers/PopupWithContent';
import ArrowIcon from '../assets/Icons/ArrowIcon';

const TokenTransferRow = ({ transfer, token, showArrow, isNoPagination }) => {
	// const uniqueAccounts = [ ...new Set(transfer.toFromAccount) ].slice(0, 2);
	// const complexComp = (
	// 	<span>
	// 		{uniqueAccounts.map((item, index) => {
	// 			const to = `/accounts/${item}`;
	// 			return (
	// 				<span key={index}>
	// 					{!!index && ' | '}
	// 					<Link className="item" to={to}>
	// 						{item}
	// 					</Link>
	// 				</span>
	// 			);
	// 		})}
	// 	</span>
	// );
	let quantity = transfer.amount;
	if (token && quantity) {
		quantity = adjustWithDecimals(quantity, token.decimal || token.decimals);
	}
	let arrowIconOption = {}
	if (quantity < 0) {
		arrowIconOption = {
			color: "red",
		}
	}

	if (quantity > 0) {
		arrowIconOption = {
			color: "green",
			flipped: 'vertically'
		}
	}

	const redirectTo = (id) => {
		return window.location.href =`/transactions/${id}`;
	};

	return (
		<Table.Row className="clickable hoverRow">
			{
				showArrow && (
					<Table.Cell collapsing onClick={()=> redirectTo(transfer.transactionId)}>
						<span>
							<ArrowIcon {...arrowIconOption} style={{ margin: '0px 0px 0px 40px' }}/>
						</span>
					</Table.Cell>
				)
			}
			<Table.Cell collapsing>
				<span>
					{
						transfer.tokenId
						? <Link to={`/tokens/${transfer.tokenId}`}>{transfer.tokenId}</Link>
						: ''
					}
				</span>
			</Table.Cell>
			<Table.Cell collapsing>
				<span>
					{transfer.amount < 0
					? <Link className="item" to={`/accounts/${transfer.accountId}`}>{transfer.accountId}</Link>
					: transfer.toFromAccount && transfer.toFromAccount.length 
						? <Link className="item" to={`/accounts/${transfer.toFromAccount[0]}`}>{transfer.toFromAccount[0]}</Link>
						: <Link className="item" to={`/accounts/${transfer.accountId}`}>{transfer.accountId}</Link>
					}
				</span>
			</Table.Cell>
			<Table.Cell collapsing>
				<span>
					{transfer.amount < 0 ? transfer.toFromAccount && transfer.toFromAccount.length 
						? <Link className="item" to={`/accounts/${transfer.toFromAccount[0]}`}>{transfer.toFromAccount[0]}</Link>
						: <Link className="item" to={`/accounts/${transfer.accountId}`}>{transfer.accountId}</Link>
					: <Link className="item" to={`/accounts/${transfer.accountId}`}>{transfer.accountId}</Link> }
				</span>
			</Table.Cell>
			{/* <Table.Cell collapsing>
				{transfer.toFromAccount && transfer.toFromAccount.length ?
					<>
						{((transfer.toFromAccount.length > 1) || (transfer.toFromAccount.length === 1 && transfer.toFromAccount[0] !== transfer.accountId)) && <PopupWithContent contentData={transfer.toFromAccount} contentLabel={'Account'} />}
					</>
				: ''}
				<span>
					<Link className="item" to={`/accounts/${transfer.accountId}`}>
						{transfer.accountId}
					</Link>
				</span>
					</Table.Cell>
				)
			}
        	{/* <Transfers txId={transfer.transactionId} accountId={transfer.accountId} hidePriceSymbol={true} tokenDecimals={token.decimals} tokenTransfer={true} /> */}
			<Table.Cell collapsing textAlign="right" onClick={()=> redirectTo(transfer.transactionId)}>
				{quantity}
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(transfer.transactionId)}>
				<DateCell date={transfer.transferTime} />
			</Table.Cell>
			{/* {
				!isNoPagination && (
					<Table.Cell textAlign="center" collapsing>
						<Link to={`/transactions/${transfer.transactionId}`}>
							<Popup
								content="View Transaction Details"
								trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
							/>
						</Link>
					</Table.Cell>
				)
			} */}
		</Table.Row>
	);
};

export default TokenTransferRow;

TokenTransferRow.propTypes = {
	transfer: PropTypes.object.isRequired
};
