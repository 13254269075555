import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Table, Checkbox, Button, Input, Grid, Dropdown, Radio, Form, Message, List } from 'semantic-ui-react';
import { getUser } from '../../redux/reducers';

import AwsUserCustomTooltip from '../AwsUserCustomTooltip';
import Info from '../shared/Info';
import AwsInfoCustomTooltip from '../AwsInfoCustomTooltip';

const SubscriptionFormNew = (
    {
        onModelSelect,
        onSubscriptionTypeSelect,
        onTransactionTypeSelect,
        onInputChange,
        onSubmit,
        handleSubscribeMessage,
        subscriptionModels,
        onIamSelection,
        addArn,
        errors = {}
    }) => {

    // console.log("THESE ARE MODELS IN SUBSCRIPTION FORM: ", subscriptionModels)

    const [disabled, setDisabled] = useState(true);
    const [displayTransactionTypes, setDisplayTransactionTypes] = useState(false);

    const [checkedValue, setCheckedValue] = useState("default");

    const displayRow = (e) => {
        e.target.textContent === "TRANSACTION" ? setDisplayTransactionTypes(true) : setDisplayTransactionTypes(false)
    }

    const handleCheck = (e, {value}) => {
        console.log({value});
        setCheckedValue(value);
        onIamSelection(value)
    }

    // const [arn, setArn] = useState('');

    // const onInputChange = e => {
    //     setArn(e.target.value )
    // }

    const [list, setList] = useState([]);

    const addToList = arn => {
        setList([ ...list, arn ])
    }

    const models = subscriptionModels.map(el => ({key: el, text: el, value: el}));
    const subscriptionModelOptions = models;

    const subscriptionTypeOptions = [
        {key: "EVENT", text: "EVENT", value: "EVENT"},
        {key: "TRANSACTION", text: "TRANSACTION", value: "TRANSACTION"},
        {key: "HCS_TOPIC", text: "HCS_TOPIC", value: "HCS_TOPIC"}
    ]

    const transactionTypeOptions = [
        {key: "CONTRACT_CREATE", text: "CONTRACT_CREATE", value: "CONTRACT_CREATE"},
        {key: "CONTRACT_CALL", text: "CONTRACT_CALL", value: "CONTRACT_CALL"},
        {key: "CONTRACT_UPDATE", text: "CONTRACT_UPDATE", value: "CONTRACT_UPDATE"},
        {key: "CONTRACT_DELETE", text: "CONTRACT_DELETE", value: "CONTRACT_DELETE"},
        // {key: "CRYPTO_ADD_CLAIM", text: "CRYPTO_ADD_CLAIM", value: "CRYPTO_ADD_CLAIM"},
        {key: "CRYPTO_CREATE_ACCOUNT", text: "CRYPTO_CREATE_ACCOUNT", value: "CRYPTO_CREATE_ACCOUNT"},
        {key: "CRYPTO_UPDATE_ACCOUNT", text: "CRYPTO_UPDATE_ACCOUNT", value: "CRYPTO_UPDATE_ACCOUNT"},
        // {key: "CRYPTO_DELETE_CLAIM", text: "CRYPTO_DELETE_CLAIM", value: "CRYPTO_DELETE_CLAIM"},
        {key: "CRYPTO_DELETE_ACCOUNT", text: "CRYPTO_DELETE_ACCOUNT", value: "CRYPTO_DELETE_ACCOUNT"},
        {key: "CRYPTO_TRANSFER", text: "CRYPTO_TRANSFER", value: "CRYPTO_TRANSFER"},
        {key: "FILE_CREATE", text: "FILE_CREATE", value: "FILE_CREATE"},
        {key: "FILE_APPEND", text: "FILE_APPEND", value: "FILE_APPEND"},
        {key: "FILE_UPDATE", text: "FILE_UPDATE", value: "FILE_UPDATE"},
        {key: "FILE_DELETE", text: "FILE_DELETE", value: "FILE_DELETE"},
        {key: "SYSTEM_DELETE", text: "SYSTEM_DELETE", value: "SYSTEM_DELETE"},
        {key: "SYSTEM_UNDELETE", text: "SYSTEM_UNDELETE", value: "SYSTEM_UNDELETE"},
        {key: "CONSENSUS_CREATE_TOPIC", text: "CONSENSUS_CREATE_TOPIC", value: "CONSENSUS_CREATE_TOPIC"},
        {key: "CONSENSUS_UPDATE_TOPIC", text: "CONSENSUS_UPDATE_TOPIC", value: "CONSENSUS_UPDATE_TOPIC"},
        {key: "CONSENSUS_DELETE_TOPIC", text: "CONSENSUS_DELETE_TOPIC", value: "CONSENSUS_DELETE_TOPIC"},
        {key: "CONSENSUS_SUBMIT_MESSAGE", text: "CONSENSUS_SUBMIT_MESSAGE", value: "CONSENSUS_SUBMIT_MESSAGE"},
        {key: "TOKEN_CREATE", text: "TOKEN_CREATE", value: "TOKEN_CREATE"},
        {key: "TOKEN_UPDATE", text: "TOKEN_UPDATE", value: "TOKEN_UPDATE"},
        {key: "TOKEN_MINT", text: "TOKEN_MINT", value: "TOKEN_MINT"},
        {key: "TOKEN_BURN", text: "TOKEN_BURN", value: "TOKEN_BURN"},
        {key: "TOKEN_TRANSFER", text: "TOKEN_TRANSFER", value: "TOKEN_TRANSFER"},
        {key: "TOKEN_WIPE_ACCOUNT", text: "TOKEN_WIPE_ACCOUNT", value: "TOKEN_WIPE_ACCOUNT"},
        {key: "TOKEN_DELETE", text: "TOKEN_DELETE", value: "TOKEN_DELETE"},
        {key: "TOKEN_FREEZE_ACCOUNT", text: "TOKEN_FREEZE_ACCOUNT", value: "TOKEN_FREEZE_ACCOUNT"},
        {key: "TOKEN_UNFREEZE_ACCOUNT", text: "TOKEN_UNFREEZE_ACCOUNT", value: "TOKEN_UNFREEZE_ACCOUNT"},
        {key: "GRANT_KYC", text: "GRANT_KYC", value: "GRANT_KYC"},
        {key: "REVOKE_KYC", text: "REVOKE_KYC", value: "REVOKE_KYC"},
        {key: "TOKEN_ASSOCIATE_ACCOUNT", text: "TOKEN_ASSOCIATE_ACCOUNT", value: "TOKEN_ASSOCIATE_ACCOUNT"},
        {key: "TOKEN_DISASSOCIATE_ACCOUNT", text: "TOKEN_DISASSOCIATE_ACCOUNT", value: "TOKEN_DISASSOCIATE_ACCOUNT"}
    ]

    // const onTransactionTypeSelect = (e, data) => {
    //     console.log("TRANSACTION TYPE SELECT: ", e.target.textContent)
    //     console.log("TRANSACTION TYPE SELECT - DATA: ", data.value)
    // }

    const isError = Object.keys(errors).length !== 0;

    return (
        <Fragment>
        <Grid padded stackable>

            <Grid.Row columns="2">
                <Grid.Column width={8}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>SUBSCRIPTION MODEL</span>
                </Grid.Column>
                <Grid.Column width={8}>
                <Dropdown
                    placeholder='Subscription Model'
                    fluid
                    selection
                    options={subscriptionModelOptions}
                    onChange={e => {onModelSelect(e); setDisabled(false)}}
                />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns="2">
                <Grid.Column width={8}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>SUBSCRIPTION TYPE</span>
                </Grid.Column>
                <Grid.Column width={8}>
                <Dropdown
                    placeholder='Subscription Type'
                    fluid
                    selection
                    options={subscriptionTypeOptions}
                    onChange={e => {onSubscriptionTypeSelect(e); displayRow(e)}}
                />
                </Grid.Column>
            </Grid.Row>

            {displayTransactionTypes && <Grid.Row columns="2">
                <Grid.Column width={8}>
                <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>TRANSACTION TYPE</span>
                </Grid.Column>
                <Grid.Column width={8}>
                <Dropdown
                    placeholder='Transaction Type'
                    fluid
                    multiple
                    selection
                    options={transactionTypeOptions}
                    onChange={(e, data) => {onTransactionTypeSelect(e, data)}}
                />
                </Grid.Column>
            </Grid.Row>}

            {/* -------------------------------- SQS ARN -------------------------------- */}

            <Grid.Row columns="4">
                <Grid.Column width={5}>
                <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>AWS USER ARN</span>
                <span style={{position: "relative", bottom: "-4px"}}><AwsInfoCustomTooltip /></span>
                <span style={{marginLeft: "14px", position: "relative", bottom: "2px", left: "18px"}}>
                    <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_users_create.html" target="_blank" rel="noopener noreferrer">
                    <AwsUserCustomTooltip />
                    {/* <Icon name="external alternate" /> */}
                    </a>
                </span>
                </Grid.Column>
                <Grid.Column width={3}>
                    {/* <Form>
                        <Form.Field>
                            <Radio
                                label='DEFAULT USER ARN'
                                name='radioGroup'
                                value='default'
                                checked={checkedValue === 'default'}
                                onChange={handleCheck}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='CUSTOM USER ARN'
                                name='radioGroup'
                                value='custom'
                                checked={checkedValue === 'custom'}
                                onChange={handleCheck}
                            />
                        </Form.Field>
                    </Form> */}
                </Grid.Column>
                <Grid.Column width={8}>
                    {/* {checkedValue === 'custom' &&  */}
                    <Form error={isError}>
                        <Form.Input  // error={isError}
                            id="iAmUser"
                            name="iAmUser"
                            fluid
                            // icon="building"
                            // iconPosition="left"
                            placeholder="Enter your ARN (optional)"
                            autoFocus
                            // value={arn}
                            onChange={onInputChange}
                            // error={!!errors.iAmUser}
                            autoComplete="iAmUser"
                            required={false}
                        />
                        <Message error content={errors.iAmUser} className="animated fadeIn" />
                    </Form> {/*}*/}
                </Grid.Column>
                {/* <Grid.Column width={2}>
                    <Button
                        type="submit"
                        style={{float: "right"}}
                        size="medium"
                        primary
                        disabled={arn.length === 0 || list.length > 1}
                        onClick={e => {addArn(arn); addToList(arn); setArn('')}}
                        >
                        ADD
                    </Button>
                </Grid.Column> */}
            </Grid.Row>

            {/* ------------------------------------------------------------------------- */}

            {/* --------------------------- DISPLAY iAmUsers --------------------------- */}

            <Grid.Row columns="2">
                <Grid.Column width={8}>
                </Grid.Column>
                <Grid.Column width={8}>
                    <List items={list} />
                </Grid.Column>
            </Grid.Row>

            {/* ------------------------------------------------------------------------- */}

        </Grid>

        <Button
            type="submit"
            style={{margin: "30px 0 20px 0", float: "right"}}
            primary
            disabled={disabled}
            onClick={e => {onSubmit(e)/*; handleSubscribeMessage()*/}}
            >
            SUBMIT
        </Button>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default withRouter(connect(mapStateToProps, actions)(SubscriptionFormNew));
