import React, { useState, useEffect } from 'react';
import { Grid, Container } from 'semantic-ui-react';
import TransactionSummaryPanel from '../molecules/TransactionSummaryPanel';
// import AccountDetailsTabs from '../components/AccountDetailsTabs';
import Page from '../components/shared/Page';
import TransactionDetailsTabs from "../components/TransactionDetailsTabs";
import { getTransaction } from '../redux/reducers';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import RawTransaction from "../components/containers/RawTransaction";

const styles = {
	containerStyle: {
		// marginTop: '4%',
	  },
	contentGrid: {
		marginBottom: '20px',

	  },
}
const TransactionsPage = (props) => {
	const [notFoundMsg, setNotFoundMsg] = useState('');
	const [notFoundMsg2, setNotFoundMsg2] = useState('');

	const { match: { params: { txId } }, transaction } = props;

	const fetchData = () => {
		const { fetchTransaction, id } = props;
		fetchTransaction(id);
		msgReturn();
	}


	useEffect(() => {
		fetchData();
	}, []);

	function msgReturn () {
		setTimeout(() => {
			const msg = `The requested transaction was not found. Please verify the transaction details and try again.`;
			const msg1 = 'If you were searching for historical HCS transactions older than three days, please contact us at info@dragonglass.me.'
			setNotFoundMsg(msg)
			setNotFoundMsg2(msg1)
			}, 1000);
	}
	const msgComponent = () => {
		return (<p>
			{notFoundMsg}
			<p>
			{notFoundMsg2}
			</p>
		</p>)
	};

	const token = transaction && transaction.tokenIDs && transaction.tokenIDs.length ? transaction.tokenIDs[0] : [];

	return (
		<Page>
			<Container fluid style={styles.containerStyle} className="w-98">
				{
					!transaction
					? <div style={{padding: '20px', fontSize: '20px', fontWeight: 400, textAlign: 'center'}}>
							<span>
								{msgComponent()}
							</span>
						</div>
					: null
				}
				<Grid padded stackable>
					<Grid.Column computer={16} tablet={16} mobile={16}>
						<Grid columns={1}>
							<Grid.Column>
								{
									transaction ? <TransactionSummaryPanel {...props}/>
									: null
								}
							</Grid.Column>
							<Grid.Column>
								{
									transaction ?
									<TransactionDetailsTabs
										transfers={transaction.transfers}
										tokenId={token}
										nfts={transaction.nfts}
										typeLabel={transaction.typeLabel}
										transactionID={transaction.transactionID}
									/>
									: null
								}
							</Grid.Column>
							{
								transaction ?
								<RawTransaction txId={props.id || transaction.transactionID} />
								: null
							}
						</Grid>
					</Grid.Column>
				</Grid>
			</Container>
		</Page>
	);
}

const mapStateToProps = (state, { match: { params: { txId } } }) => ({
	id: txId,
	transaction: getTransaction(state, txId)
});

export default connect(mapStateToProps, actions)(TransactionsPage);
