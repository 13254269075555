import React, { Component } from 'react';
import { Dropdown, Input, Button, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getUser, getUserAccounts, isAuthed } from '../../redux/reducers';
import * as actions from '../../redux/actions';
import { isHederaAccount } from '../../utils';

class AccountSelector extends Component {
	state = {
		q: '',
		error: false,
		msg: ''
	};

	componentDidMount() {
		const { user, fetchUserAccounts } = this.props;
		if (user) fetchUserAccounts(user.id);
	}

	handleChange = (e, { value }) => {
		this.setState({ q: value, error: false, msg: '' });
	};

	onClose = (e) => {
		//on enter. e is undef on normal doc click
		if (e && e.keyCode === 13) {
			const { q } = this.state;
			//this.goToSearch(q);
			//this.goToAccount(q);
			this.lookupAccount(q);
		}
	};

	handleLookup = (e) => {
		e.stopPropagation();
		const { q } = this.state;
		this.lookupAccount(q);
	};

	lookupAccount = (acct) => {
		if (!isHederaAccount(acct)) return this.setState({ error: true, msg: 'Invalid format! Should be x.y.z' });

		this.setState({ error: false, msg: '' }, () => this.goToAccount(acct));
		/*
		const { lookupAccount } = this.props;
		lookupAccount(acct)
			.then(() => {
				console.log('acct valid');
				this.setState({ error: false, msg: '' }, () => this.goToAccount(acct));
			})
			.catch((err) => {
				console.log('acct invalid');
				this.setState({ error: true, msg: 'Not found!' });
			});
		*/
	};

	onClick = (e, { value }) => this.goToAccount(value);
	goToSearch = (q) => this.props.history.push(`/search?q=${q}`);
	goToAccount = (acct) => this.props.history.push(`/accounts/${acct}`);

	options = () => [
		{
			key: 'profile',
			value: 'profile',
			onClick: this.handleSubmit,
			content: (
				<React.Fragment>
					<Input
						size="mini"
						action={
							<div className="ui  input-button__wrapper">
								<Button primary content="LOOK UP" size="tiny" onClick={this.handleLookup} />
							</div>
						}
						placeholder="e.g. 0.0.9838"
						onClick={(e) => e.stopPropagation()}
						onChange={this.handleChange}
						error={this.state.error}
					/>
					{this.state.error && (
						<Message size="mini" negative color="black">
							{this.state.msg}
						</Message>
					)}
				</React.Fragment>
			)
		},
		...(this.props.isAuthed
			? [
					...this.props.accounts.map((account, i) => ({
						key: account.accountId,
						value: account.accountId,
						onClick: this.handleSubmit,
						content: (
							<Link to={`/accounts/${account.accountId}`}>
								{account.alias ? account.alias : 'UNNAMED ACCOUNT'}
								<br />
								({account.accountId})
							</Link>
						)
					}))
				]
			: [
					{
						key: 'signup',
						value: 'profile',
						onClick: this.handleSubmit,
						content: (
							<Link to="/signup">
								SIGN UP TO RECEIVE REAL-TIME <br /> ACCOUNT ALERTS
							</Link>
						)
					}
				])
	];

	render() {
		return (
			<Dropdown
				onClose={this.onClose}
				// direction="left"
				// text={this.props.account || 'ACCOUNTS'}
				text={'ACCOUNTS'}
				options={this.options()}
			>
				{/* <Dropdown.Menu onKeyPress={this.handleSubmit}>
					<Menu.Item>
						<Form onSubmit={this.handleSubmit}>
							<Input
								icon="search"
								placeholder="Search mail..."
								onClick={(e) => e.stopPropagation()}
								onKeyPress={this.handleSubmit}
								onKeyUp={this.handleSubmit}
							/>
						</Form>
					</Menu.Item>
					<Form>
						<Input
							icon="search"
							iconPosition="left"
							className="search"
							onKeyPress={this.handleSubmit}
							onClick={(e) => e.stopPropagation()}
						/>
						{accounts.length > 0 && <Dropdown.Divider />}
					</Form>
					{accounts.map((account, i) => (
						<Dropdown.Item
							key={account}
							value={account}
							onClick={(e, { value }) => this.props.history.push(`/accounts/${value}`)}>
							{account}
						</Dropdown.Item>
					))}
				</Dropdown.Menu> */}
			</Dropdown>
		);
	}
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
	account: accountId,
	user: getUser(state),
	accounts: getUserAccounts(state),
	isAuthed: isAuthed(state)
});

export default withRouter(connect(mapStateToProps, actions)(AccountSelector));
