import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Icon, Item, Button, Modal } from 'semantic-ui-react';
import EditProfile from '../forms/EditProfile';
import * as actions from '../../redux/actions';
import { getUser } from '../../redux/reducers';
import { notify } from '../../utils';

class User extends Component {
	state = {
		isEditing: false,
		data: {
			name: '',
			contact: '',
			company: ''
		},
		errors: {}
	};

	componentDidMount() {
		this.updateUserState();
	}

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user) {
			this.updateUserState();
		}
	}

	updateUserState = () => {
		const { user } = this.props;
		if (user && Object.keys(user).length !== 0) {
			this.setState({
				data: { name: user.name, contact: user.contact, company: user.company }
			});
		}
	};

	handleEdit = () => {
		this.setState({ isEditing: true });
	};

	handleChange = (e, { name, value }) => {
		this.setState({
			data: { ...this.state.data, [name]: value }
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const errors = this.validate(this.state.data);
		this.setState({ errors });

		if (Object.keys(errors).length === 0) {
			const { saveProfile, user } = this.props;
			saveProfile({ ...this.state.data }, user.id)
				.then(() => {
					this.setState({ errors: {}, isEditing: false });
					const toastId = 'edit-profile';
					if (!notify.isActive(toastId)) {
						const message = `Profile edited successfully. If you have updated your mobile number, a sms verification will be sent to your registered number ${this
							.state.data.contact || ''}. Please click the link in the sms to verify your number.`;
						notify(message, { toastId, autoClose: 8000 });
					}
				})
				.catch((err) =>
					this.setState({
						errors: { auth: 'Profile update failed! Please try again.' }
					})
				);
		}
	};

	validate = (data) => {
		let errors = {};
		const { name } = data;
		if (!name || name.trim().length === 0) errors.name = "Can't be blank";
		//if (!contact || contact.trim().length === 0) errors.contact = "Can't be blank";
		return errors;
	};

	handleCancel = () => {
		this.setState({ isEditing: false });
	};

	render() {
		this.props.user && this.props.user.registeredForSubscriptions && console.log("THIS IS THE USER: ", this.props.user)
		const { user } = this.props;
		const { data: { name, contact, company }, errors } = this.state;
		return (
			<Grid stackable >
				<Grid.Column width={16} textAlign="center" style={{padding: 0}}>
					<Icon className="user-icon" name="user" size={window.innerWidth > 992? "massive" : "huge"} color="grey" circular />
				</Grid.Column>
				<Grid.Column width={16}>
					<Header as="h4" className="user-name">
						{user.name.toUpperCase()}
						<Header.Subheader>JOINED IN {(new Date(user.dateCreated)).getFullYear()}</Header.Subheader>
					</Header>
					<Item>
						<Item.Content><br />
							<Item.Description>{user.company}</Item.Description>
							<Item.Description>{user.email}</Item.Description>
							<Item.Description>{user.contact}</Item.Description>
							{/* {user.registeredForSubscriptions && <Item.Description><Icon name="checkmark" size="small" />Registered for Subscriptions</Item.Description>} */}
							<Item.Extra>
								<Button primary onClick={this.handleEdit} floated="right" size={window.innerWidth < 992 ? "tiny" : "medium"}>
									<Icon name="edit" />
									EDIT
								</Button>
							</Item.Extra>
						</Item.Content>
					</Item>
				</Grid.Column>

				<Modal open={this.state.isEditing} size="tiny" closeIcon onClose={this.handleCancel} dimmer="blurring">
					<Modal.Header>Edit Profile</Modal.Header>
					<Modal.Content>
						<EditProfile
							onChange={this.handleChange}
							onSubmit={this.handleSubmit}
							onCancel={this.handleCancel}
							name={name}
							contact={contact}
							company={company}
							errors={errors}
						/>
					</Modal.Content>
				</Modal>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	user: getUser(state)
});

User = connect(mapStateToProps, actions)(User);

export default User;
