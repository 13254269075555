import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DappTokenTransferTable from '../DappTokenTransferTable';
import * as actions from '../../redux/actions';
import {getDapp, getDappTransfers, getDappTokenTransfers, getDappTransfersCount } from '../../redux/reducers';
import { removeEmpty } from '../../utils';

class DappTokenTransfers extends Component {
	state = {
		offset: 0,
		limit: 5,
		page: 1,
		sort: 'consensusTime',
		order: 'desc'
	};

	componentDidMount() {
		console.log("DAPP TOKEN TRANSFERS MOUNTING")
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.dappId !== prevProps.dappId || this.props.filter !== prevProps.filter) {
			this.setState(
				{
					offset: 0,
					page: 1
				},
				() => this.fetchData()
			);
		}
		// if (this.props.dapp !== prevProps.dapp) {
		// 	this.fetchData()
		// }
	}

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		console.log("PROPS IN THE DAPP TOKEN TRANSFERS = ", this.props)
		const {accountId, dappId}= this.props;
		if (!accountId) return;
		const { filter, fetchDappTokenTransfers } = this.props;
		// const params = { q: filter, ...this.state, contractID: accountId };
		console.log("ACCOUNT ID IN DAPP TOKEN TRANSFERS", accountId)
		const params = { contractID: accountId, ...this.state }
		console.log("PARAMS IN DAPP TOKEN TRANSFERS", params)
		const queryParams = removeEmpty(params);

		const esc = encodeURIComponent;
		const query = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');
		console.log("THIS IS THE QUERY: ", query)
		fetchDappTokenTransfers(dappId, accountId, query);
	};

	showTransactionDetails = (transfer) => {
		this.props.history.push(`/transactions/${transfer.transactionID}`);
	};

	render() {

		const { tokenTransfers, totalCount } = this.props;

		const { accountId } = this.props.accountId;

		// console.log("IN DAPP TOKEN TRANSFERS: ", this.props.dapp.tokenTransfers)

		return (
			<div>{this.props.dapp.tokenTransfers &&
			<DappTokenTransferTable
				tokenTransfers={this.props.dapp.tokenTransfers.data}
				limit={this.state.limit}
				offset={this.state.offset}
				sort={this.state.sort}
				order={this.state.order}
				totalCount={this.props.dapp.tokenTransfers.totalCount}
				loadData={this.loadData}
				showDetails={this.showTransactionDetails}
			/>
			}</div>
		);
	}
}

const mapStateToProps = (state, { match: { params: { dappId } } }) => {
	const dapp = getDapp(state, dappId);
	const id = dapp.accountId;
	// console.log(dappId)
	// dapp && id && console.log("THIS IS THE STATE IN DAPP TOKEN TRANSFERS", state)
    return ({
		dapp,
		dappId,
		accountId: dapp && dapp.accountId ? dapp.accountId : null,
        transfers: dapp ? getDappTransfers(state, dapp.accountId) : [],
		tokenTransfers: dapp ? getDappTokenTransfers(state, dapp.accountId) : []
    })
};

DappTokenTransfers = withRouter(connect(mapStateToProps, actions)(DappTokenTransfers));

export default DappTokenTransfers;
