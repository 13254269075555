import React, { Component } from 'react';
import Panel from '../../components/shared/Panel';
import PeriodSelector from '../../components/shared/PeriodSelector';
import TxnTypeDimensionChart from './charts/TxnTypeDimensionChart';
import Info from '../../components/shared/Info';
import SourceInWidget from '../../components/SourceInWidget';
import { isMobile, parseQueryParams } from  '../../utils';
import { withRouter } from 'react-router-dom';
import Share from '../../components/shared/Share';
import { Icon } from 'semantic-ui-react';

export class TxnTypeDimensionChartPanel extends Component {
	state = {
		period: "1D",
		icon: 'chart line',
		showLegend: false,
		customHeight: 200
	};

	componentDidMount() {
        const params = parseQueryParams(this.props.location.search);
        if(params && params.period) {
            this.setState({
                period: params.period,
                icon: params.chartType == 'bar' ? "chart bar" : "chart line",
            });
        }
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.setState({ period: this.props.period });
		}
	}

	onPeriodSelect = (e, { value }) => {
        this.setState({ period: value });
    };


	toggle = () => {
		if(this.state.icon === "chart line"){
			this.setState({icon:"chart bar"});
		}
		else{
			this.setState({icon:"chart line"});
		}
	}

	onShowLegend = () => {
		this.setState({ showLegend: !this.state.showLegend, customHeight: this.state.showLegend ? 200 : 400 });
	};

	render() {
		const { icon, period, showLegend } = this.state;
		console.log('showLegend panel', showLegend)
		const buttonIcon = showLegend ? <Icon name="caret up" /> : <Icon name="caret down" />
		let chartType = 'line';
		if  (icon == "chart bar") {
			chartType = 'bar';
		}
		let url = `/chart?name=txn-type-dimension&period=${period}`;
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						TRANSACTION TYPE <Info id="txTypes" /> 
						<SourceInWidget />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				type="txnVolume"
				toggle={this.toggle}
				icon={icon}
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
				footer={
					<>
					{
						!this.props.isLegend ?
						<p className='clickable' onClick={() => this.onShowLegend()} style={{position: 'absolute'}}>{this.state.showLegend ? 'Hide' : 'Show'} Legend
							<span style={{marginLeft: '10px'}}>{buttonIcon}</span>
						</p>
						: ''
					}
					<Share url={url} params={params} title={"Dragon Glass Transaction Volume Chart"}/>
					</>
			}
			>
				<TxnTypeDimensionChart period={period} graphType={icon} isLegend={this.props.isLegend  || this.state.showLegend} showLegend={this.state.showLegend} isNoTooltip={this.props.isNoTooltip} 
				customHeight={this.props.isLegend ? isMobile() ? 1000 :  this.props.customHeight : this.state.customHeight}
				/>
			</Panel>
		);
	}
}

export default withRouter(TxnTypeDimensionChartPanel);