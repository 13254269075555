import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Stat from '../shared/Stat';
import LineChart from '../shared/LineChart';
import * as actions from '../../redux/actions';
import { getTokenDetails, getTokenTxnValue } from '../../redux/reducers/hts';
import { formatTimestamp, adjustWithDecimals } from '../../utils';

class TokenTxnValueChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchTokenTxnValue, period, tokenId } = this.props;
		fetchTokenTxnValue({tokenId: tokenId, interval: period});
	}

	adjustDataWithDecimals = (data, token) => {
		if (data && data.length) {
			for (let i = 0; i < data.length; i++) {
				data[i].value = adjustWithDecimals(data[i].value, token.decimal || token.decimals);
			}
		}
		return data;
	};

	render() {
		const { volume: { totalSum, pctChange, data }, period, token } = this.props;
		const modifiedData = this.adjustDataWithDecimals(data, token);
    const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [ new Intl.NumberFormat('en').format(value) ];
		if (modifiedData && modifiedData.length) {
			return (
				<Fragment>
					<Stat count={adjustWithDecimals(totalSum, token.decimal || token.decimals)} pct={pctChange} />
					<br />
					<LineChart
						data={modifiedData}
						tickFormatter={formatter}
						labelFormatter={formatter}
						valueFormatter={valueFormatter}
					/>
				</Fragment>
			);
		} else {
			return <div>No transactions available for the selected period</div>;
		}
	}
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => {
    return ({
				tokenId: tokenId,
				token: getTokenDetails(state),
        volume: getTokenTxnValue(state)
    })
};

TokenTxnValueChart = withRouter(connect(mapStateToProps, actions)(TokenTxnValueChart));

export default TokenTxnValueChart;
