import {
	FETCH_USER_INVOICES_SUCCESS,
	FETCH_USER_UNPAID_INVOICES_SUCCESS,
  FETCH_USER_INVOICES_FAILURE
} from '../types';

const initialState = {};

export default function userInvoice(state = initialState, action) {
	switch (action.type) {
		case FETCH_USER_INVOICES_SUCCESS:
			return {
				...state,
				invoicesData: action.data
			};
		case FETCH_USER_UNPAID_INVOICES_SUCCESS:
			return {
				...state,
				unpaidInvoicesData: action.data
			};
		case FETCH_USER_INVOICES_FAILURE:
			return {
				...state,
				invoicesData: []
			};
		default:
			return state;
	}
}
