import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import AccountRow from './AccountRow';
import AccountTableHeader from './AccountTableHeader';

const AccountTable = ({ accounts = [], limit, offset, sort, order, totalCount, loadData }) => {
	const AccountRows = accounts.map((account, index) => <AccountRow key={index} account={account} />);
	return (
		<DataTable
			limit={limit}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={AccountTableHeader}
			rows={AccountRows}
			loadData={loadData}
		/>
	);
};

export default AccountTable;

AccountTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired
};
