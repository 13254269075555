import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../redux/actions';
import { Grid, Tab } from 'semantic-ui-react';
import TokenTransferPanel from './TokenTransferPanel';
import TokenTransactionsPanel from './TokenTransactionsPanel';
import NftsTokenPanel from './NftsTokenPanel';
import { getTokenDetails } from '../redux/reducers/hts';
import { getNftsData, getSearchNFTsData } from '../redux/reducers';
import NftHistoricalList from './NftHistoricalList';
import NFTExplorerList from "../atoms/NFTExplorerList";
import Filter from './shared/Filter';
import Panel from './shared/Panel';

export const HederaTokenDetailTabs = (props) => {
	const token = props.token;
	const nftTokenBalances = props.nftTokenBalances;

	const [limit, setLimit] = useState(12);
	const [offset, setOffset] = useState(0);
	const [page, setPage] = useState(1);

	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const handleTabChange = (e, { activeIndex }) => {
		setActiveTabIndex(activeIndex);
		switch (activeIndex) {
			case 0:
				console.log('token transfers tab');
				break;
			case 1:
				console.log('nft transfer tab');
				break;
			case 2:
				console.log('nfts tab');
				fetchData();
				break;
			default:
				console.log('default transfers tab');
		}
  };
	const isEnableNftsData = props.tokenId && nftTokenBalances && nftTokenBalances.nftsData && nftTokenBalances.nftsData.length && nftTokenBalances.nftsData.filter(nft => nft && nft.token === props.tokenId);
	const totalCount = nftTokenBalances && nftTokenBalances.nftsData && nftTokenBalances.totalCount || 0; 

	useEffect(() => {
		if(token && token.tokenType == "NON_FUNGIBLE_UNIQUE") {
			setActiveTabIndex(2);
			fetchData();
		}
	},[limit, page]);

	useEffect(() => {
		if(token && token.tokenType == "NON_FUNGIBLE_UNIQUE") {
			if (isEnableNftsData && isEnableNftsData.length) {
				setActiveTabIndex(2);
			}
		}
	}, [nftTokenBalances && nftTokenBalances.nftsData && nftTokenBalances.nftsData.length])

	const fetchData = () => {
		const { fetchNftsDetails, tokenId} = props;
		const params = { tokenId, limit, offset, page };
		fetchNftsDetails(params);
	}

	const onLoadMore = (params) => {
		setOffset(params.offset);
		setPage(params.page)
		setLimit(limit);
	}

	const onSubmitFilter = (filter) => {
		const { fetchNftsDetails, tokenId } = props;
		const params = { tokenId, q: filter, limit, offset };
		fetchNftsDetails(params);
	}

    const panesFungible = () => [
		{
			menuItem: {
				key: 'allTransfers',
				content: (
					<span>
						TOKEN TRANSFERS
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div" id="token-transfers-table">
					<TokenTransferPanel />
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'allTransactions',
				content: (
					<span>
						ALL TRANSACTIONS
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div" id="token-transactions-table">
					<TokenTransactionsPanel />
				</Tab.Pane>
			)
		}]

		let panesNonFungible = [
			{
				menuItem: {
					key: 'allTransactions',
					content: (
						<span>
							ALL TRANSACTIONS
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div" id="token-transactions-table">
						<TokenTransactionsPanel />
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'nftTransfers',
					content: (
						<span>
							NFT TRANSFERS
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<NftHistoricalList isAccountPage={true} {...props} />
					</Tab.Pane>
				)
			},
		]
			if(isEnableNftsData && isEnableNftsData.length) {
				panesNonFungible.push({
					menuItem: {
						key: 'NftToken',
						content: (
							<span>
								NFTs
							</span>
						)
					},
					render: () => (
						<Tab.Pane as="div" id="nfts-transfers-table">
							<Panel 
							toolbar={
								<React.Fragment>
									<Filter onSubmitFilter={onSubmitFilter}/>
								</React.Fragment>
							}
							>
								<NFTExplorerList nfts={nftTokenBalances.nftsData} onLoadMore={onLoadMore} totalCount={totalCount} limit={limit}/>
							</Panel>
						</Tab.Pane>
					)
				})
			}

    return (
        <div className='w-100'>
            <Tab
                menu={{ stackable: true, secondary: true, pointing: true }}
                panes={token && token.tokenType == "FUNGIBLE_COMMON" ? panesFungible() : panesNonFungible}
                renderActiveOnly={true}
                onTabChange={handleTabChange}
				activeTabIndex={activeTabIndex}
				activeIndex={activeTabIndex}
            />
        </div>
    )
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
	token: getTokenDetails(state),
	tokenId: tokenId,
  	nftTokenBalances: getNftsData(state),
	nftsData: getSearchNFTsData(state),
});


export default withRouter(connect(mapStateToProps, actions)(HederaTokenDetailTabs));
