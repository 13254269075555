import React from 'react';
import './App.css';
import Routes from './routes/Routes';
import ReactGA from 'react-ga';
import { GA_TRACKING_ID } from './constants';
import Notification from './components/shared/Notification';
import TidioChatWidget from './components/shared/TidioChatWidget';

console.log("GA TRACKING ID: ", GA_TRACKING_ID);

ReactGA.initialize(GA_TRACKING_ID);
ReactGA.event({
	category: 'App',
	action: 'Load',
	label: 'User accessed the Hedera DragonGlass App'
});
function App() {
	return (
		<div className="app">
			{/* <TidioChatWidget /> */}
			<Routes />
			<Notification />
		</div>
	);
}

export default App;
