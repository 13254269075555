import React from 'react';
import Panel from './shared/Panel';
import DappAccountSummary from './containers/DappAccountSummary';
import Info from './shared/Info';

export default function DappAccountSummaryPanel({ accountNumber }) {
	return (
		<Panel
			title={
				<React.Fragment>
					ACCOUNT SUMMARY <Info id="accountSummary" />
				</React.Fragment>
			}
			description=""
		>
			<DappAccountSummary accountNumber={accountNumber}/>
		</Panel>
	);
}