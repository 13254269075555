import React, { Component } from 'react';
import Panel from './shared/Panel';
import Filter from './shared/Filter';
import AccountTransfers from './containers/AccountTransfers';
import Info from './shared/Info';

export default class AccountTransfersPanel extends Component {
	state = {
		q: ''
	};

	onSubmitFilter = (value) => {
		this.setState({ q: value });
	};

	render() {
		const { q } = this.state;
		return (
			<Panel
				title={
					<React.Fragment>
						{/* PAYMENTS AND RECEIPTS <Info id="transfers" /> */}
						<Info id="transfers" />
					</React.Fragment>
				}
				description=""
				toolbar={
					<React.Fragment>
						<Filter onSubmitFilter={this.onSubmitFilter} value={q} />
					</React.Fragment>
				}
			>
				<AccountTransfers filter={q} />
			</Panel>
		);
	}
}
