import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import ContactUsForm from '../forms/ContactUs';
import { isEmail } from '../../utils';
import * as actions from '../../redux/actions';
import ReactGA from 'react-ga';

class ContactUs extends Component {
	state = {
		data: {
			name: '',
			email: '',
			message: ''
		},
		errors: {},
		loading: false,
		sent: false
	};

	handleChange = (e, { name, value }) => this.setState({ data: { ...this.state.data, [name]: value } });
	handleSubmit = (e) => {
		e.preventDefault();
		const errors = this.validate(this.state.data);
		this.setState({ errors });
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true });
			this.props
				.contact({ ...this.state.data })
				.then(() => {
					this.setState({ errors: {}, loading: false, sent: true });
						ReactGA.event({
							category: 'Contact',
							action: 'Submit',
							label: 'User submitted the contact form'
						})
					}
				)
				.catch((err) =>
					this.setState({
						errors: {
							non_field_errors:
								'Unable to send message! Please try again or contact us by another method.'
						},
						loading: false
					})
				);
		}
	};

	validate = (data) => {
		let errors = {};
		const { name, email, message } = data;
		if (!name || name.trim().length === 0) errors.name = "Can't be blank";
		if (!isEmail(email)) errors.email = 'Invalid email';
		if (!message || message.trim().length === 0) errors.message = "Can't be blank";
		return errors;
	};

	render() {
		const { data, loading, errors, sent } = this.state;
		if (!sent) {
			return (
				<ContactUsForm
					{...data}
					onChange={this.handleChange}
					onSubmit={this.handleSubmit}
					errors={errors}
					loading={loading}
				/>
			);
		} else {
			return (
				<Message positive>
					<Message.Header>Message received!</Message.Header>
					<p>Thanks for contacting us! We will get back to you within 2 business days.</p>
				</Message>
			);
		}
	}
}

ContactUs = connect(null, actions)(ContactUs);

export default ContactUs;
