import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Grid, Tab, Radio } from 'semantic-ui-react';

import * as actions from '../../redux/actions';
import { getSearchedTransactionsCount, getSearchedAccountsCount, getSearchedContractsCount, getSearchedTopicsCount } from '../../redux/reducers';

import Panel from '../shared/Panel';
import Number from '../shared/Number';
import AdvSearchForm from '../forms/AdvSearchForm';
import TransactionSearch from '../containers/TransactionSearch';
import ContractSearch from '../containers/ContractSearch';
import AccountSearch from '../containers/AccountSearch';
import TopicSearch from '../containers/TopicSearch';
import TokenSearch from '../containers/TokenSearch';
import NftsBalance from '../NftsBalance';

import { removeEmpty } from '../../utils';
import { getHederaTokensCount } from '../../redux/reducers/hts';
import { getSearchedNftsCount } from '../../redux/reducers';

//const queryParams = [ 'q', 'startDate', 'endDate', 'accountFrom', 'accountTo', 'amountFrom', 'amountTo', 'query' ];

const getInitialState = () => {
	const initialState = {
		searchParams: {
			q: '',
			startDate: null,
			endDate: null,
			accountFrom: '',
			accountTo: '',
			amountFrom: '',
			amountTo: '',
			contractID: '',
			period: null,
			transactionTypes: '',
			serviceTypes: ''
		},
		isAdvanced: false,
		activeTabIndex: 0
	};
	return initialState;
};

class AdvancedSearch extends Component {
	state = getInitialState();

	componentDidMount() {
		const q = this.props.routeParams.get('q') || '';
		const transactionTypes = this.props.routeParams.get('transactionTypes') || '';
		const period = this.props.routeParams.get('period') || '';
		const serviceTypes = this.props.routeParams.get('serviceTypes') || '';
		const startDate = this.props.routeParams.get('startDate') ? parseInt(this.props.routeParams.get('startDate')) || '' : '';
		const endDate = this.props.routeParams.get('endDate') ? parseInt(this.props.routeParams.get('endDate')) || '' : '';
		const accountFrom = this.props.routeParams.get('accountFrom') || '';
		const accountTo = this.props.routeParams.get('accountTo') || '';
		const contractID = this.props.routeParams.get('contractID') || '';
		const amountFrom = this.props.routeParams.get('amountFrom') || '';
		const amountTo = this.props.routeParams.get('amountTo') || '';
		const activeTab = this.props.routeParams.get('activeTab') || '';
		const isAdvanced = this.props.routeParams.get('isAdvanced') || false;
		let transactionTypes_value;
		// switch (transactionTypes) {
		// 	case "CONSENSUS":
		// 		transactionTypes_value = "CONSENSUS_SUBMIT_MESSAGE&transactionTypes=CONSENSUS_CREATE_TOPIC&transactionTypes=CONSENSUS_UPDATE_TOPIC&transactionTypes=CONSENSUS_DELETE_TOPIC";
		// 		break;
		// 	case "CRYPTO":
		// 		transactionTypes_value = "CRYPTO_TRANSFER&transactionTypes=CRYPTO_CREATE_ACCOUNT&transactionTypes=CRYPTO_DELETE_ACCOUNT";
		// 		break;
		// 	case "CONTRACT":
		// 		transactionTypes_value = "CONTRACT_CALL&transactionTypes=CONTRACT_CREATE&transactionTypes=CONTRACT_DELETE&transactionTypes=CONTRACT_UPDATE";
		// 		break;
		// 	case "FILE":
		// 		transactionTypes_value = "FILE_CREATE&transactionTypes=FILE_DELETE&transactionTypes=FILE_APPEND";
		// 		break;
		// 	default:
		// 		transactionTypes_value = "";
		// }
		this.setState({ isAdvanced: (isAdvanced && isAdvanced == 'true') ? true : false, searchParams: { ...this.state.searchParams, q, transactionTypes, serviceTypes, period, accountFrom, accountTo, amountTo, amountFrom, contractID, startDate, endDate } },
			() => {
				if (activeTab) {
					this.setState({activeTabIndex: activeTab});
				}
				//startDate, endDate
				// this.doSearch({ q: `*${q}*`, transactionTypes, serviceTypes, period, accountFrom, accountTo, amountTo, amountFrom, contractID })
				this.doSearch({ q, transactionTypes, serviceTypes, period, accountFrom, accountTo,
					amountTo, amountFrom, contractID,
					startDate, endDate
				})
			});
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		window.location.reload()
	}

	handleAdvancedSearchToggle = (e, { checked }) => {
		this.setState({ isAdvanced: checked });
	};

	handleChange = (e, { name, value }) => {
		// console.log('name = ', name);
		// console.log('value = ', value);
		this.setState((state, props) => {
			const { searchParams } = state;
			return { searchParams: { ...searchParams, [name]: value } };
		});
		//this.setState({ searchParams: { ...this.state.searchParams, [name]: value } }); ===== this line was commented out befor commenting out the logs
	};

	handleReset = () => {
		this.setState({ ...getInitialState() });
		const { resetSearch } = this.props;
		resetSearch();
		this.props.history.push(`/search`);
	};

	handleSearch = () => {
		const { searchParams } = this.state;
		// const params = { ...searchParams, q: `*${searchParams.q}*`  };
		const params = { ...searchParams };
		// console.log('params = ', params);
		const queryParams = removeEmpty(params);
		// console.log('queryParams = ', queryParams);

		this.doSearch(queryParams);
	};

	doSearch = (queryParams) => {
		const { doSearch, fetchTransactions, fetchAccounts, fetchContracts, fetchTopics, fetchTokens, fetchNftsDetails } = this.props;
		/*trigger count only queries */
		const countOnlyParam = { ...queryParams, limit: 0, onlyCount: true };
		const esc = encodeURIComponent;
		const countOnlyParams = removeEmpty(countOnlyParam);
		const countOnlyQuery = Object.keys(countOnlyParams)
			.map((p) => esc(p) + '=' + esc(countOnlyParams[p]))
			.join('&');
		// console.log('countOnlyQuery = ', countOnlyQuery);
		// const t = fetchTransactions(countOnlyQuery, false); 	- this line commented out to remove count call for txns
		const a = fetchAccounts(countOnlyQuery, false);
		const c = fetchContracts(countOnlyQuery, false);
		const tp = fetchTopics(countOnlyQuery, false);
		const ht = fetchTokens(countOnlyParams);
		const nfts = fetchNftsDetails(countOnlyParams);

		doSearch(queryParams);

		Promise.all([ /*t, */a, c, tp, ht, nfts ]).then(() => { 				// t commented out to remove count call for txns
			// console.log('all done');
			console.log("QUERY PARAMS IN ADVANCED SEARCH: ", queryParams)
			// doSearch(queryParams); 							- this line moved up above Promise.all
		}).catch((err) => {
			console.log('err = ', err);
		});
	};

	handleTabChange = (e, { activeIndex }) => {
		// console.log('handleTabChange = ', activeIndex);
		this.setState({ activeTabIndex: activeIndex });
		switch (activeIndex) {
			case 0:
				// console.log('tx tab');
				// if (this.props.history.location.search !== '') {
				// 	this.props.history.push('/search');
				// 	window.location.reload();
				// }
				break;
			case 1:
				// console.log('acct tab');
				break;
			case 2:
				// console.log('contract tab');
				break;
			case 3:
				console.log('Tokens tab');
				break;
			case 4:
				console.log('Nfts tab');
				break;
			case 5:
				console.log('Nfts tab');
				break;
			default:
				// console.log('unknown tab');
		}
	};

	panes = () => [
		{
			menuItem: {
				key: 'transactions',
				content: (
					<span>
						TRANSACTIONS (<Number>{this.props.txCount}</Number>)
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<TransactionSearch />
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'accounts',
				content: (
					<span>
						ACCOUNTS (<Number>{this.props.acctCount}</Number>)
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<AccountSearch />
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'contracts',
				content: (
					<span>
						CONTRACTS (<Number>{this.props.cxtCount}</Number>)
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<ContractSearch />
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'topics',
				content: (
					<span>
						TOPICS (<Number>{this.props.topicsCount}</Number>)
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<TopicSearch isSearchPage={true}/>
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'tokens',
				content: (
					<span>
						TOKENS (<Number>{this.props.tokensCount}</Number>)
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<TokenSearch isShowType={true} isSearchPage={true}/>
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'nfts',
				content: (
					<span>
						NFTs (<Number>{this.props.nftsCount}</Number>)
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<NftsBalance isSearchPage={true} searchParams ={this.state.searchParams} {...this.props}/>
				</Tab.Pane>
			)
		}
	];

	render() {
		const { searchParams, isAdvanced, activeIndex } = this.state;
		return (
			<Container fluid>
				<Grid padded stackable>
					<Grid.Column width={16}>
						<Grid columns={1}>
							<Grid.Column>
								<Panel
									title="SEARCH"
									description=""
									toolbar={
										<Radio
											toggle
											label="ADVANCED"
											className="header"
											checked={isAdvanced}
											onChange={this.handleAdvancedSearchToggle}
										/>
									}
								>
									<AdvSearchForm
										{...searchParams}
										isAdvanced={isAdvanced}
										onChange={this.handleChange}
										onSearch={() => this.handleSearch()}
										onReset={() => this.handleReset()}
									/>
								</Panel>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} computer={16}>
								<Tab
									menu={{ stackable: true, secondary: true, pointing: true }}
									panes={this.panes()}
									renderActiveOnly={true}
									onTabChange={this.handleTabChange}
									activeTabIndex={this.state.activeTabIndex}
									activeIndex={this.state.activeTabIndex}
								/>
							</Grid.Column>
						</Grid>
					</Grid.Column>
				</Grid>
			</Container>
		);
	}
}

const mapStateToProps = (state, { location: { search } }) => ({
	routeParams: new URLSearchParams(search),
	txCount: getSearchedTransactionsCount(state),
	acctCount: getSearchedAccountsCount(state),
	cxtCount: getSearchedContractsCount(state),
	topicsCount: getSearchedTopicsCount(state),
	tokensCount: getHederaTokensCount(state),
	nftsCount: getSearchedNftsCount(state)
});

AdvancedSearch = withRouter(connect(mapStateToProps, actions)(AdvancedSearch));

export default AdvancedSearch;
