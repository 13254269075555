import React from 'react';
import Panel from './shared/Panel';
import TopicMessages from './containers/TopicMessages';
import TopicMessagesSearch from './containers/TopicMessagesSearch';
import Info from './shared/Info';

const TopicMessagesPanel = ({ topic }) => {

	console.log("TOPIC IN MESSAGES PANEL: ", topic)

	return (
		<Panel
			title={
				<React.Fragment>
					TOPIC MESSAGES {/*<Info id="contractSummary" />*/}
				</React.Fragment>
			}
			description=""
		>
			{/* <TopicMessages /> */}
			<TopicMessagesSearch topic={topic} />
		</Panel>
	);
}

export default TopicMessagesPanel;