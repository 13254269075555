import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import Panel from '../shared/Panel';
import SearchFacets from '../SearchFacets';
import ContractTable from '../ContractTable';
import * as actions from '../../redux/actions';
import {
	getSearchParams,
	getSearchedContracts,
	getSearchedContractsCount,
	getSearchedContractsFilters
} from '../../redux/reducers';
import { removeEmpty, concat } from '../../utils';

class ContractSearch extends Component {
	state = {
		viewParams: {
			offset: 0,
			limit: 25,
			page: 1,
			sort: 'createdOn',
			order: 'desc'
		},
		filterParams: {}
	};

	componentDidMount = () => {
		const { searchParams } = this.props;
		// console.log('ContractSearch searchParams = ', searchParams);
		this.fetchData();
	};

	componentDidUpdate(prevProps) {
		if (this.props.searchParams !== prevProps.searchParams) {
			this.fetchData();
		}
	}

	fetchData = () => {
		const { searchParams, fetchContracts } = this.props;
		const { viewParams, filterParams } = this.state;

		const params = { ...searchParams, ...viewParams };
		// console.log('params = ', params);
		const queryParams = removeEmpty(params);
		// console.log('queryParams = ', queryParams);
		const esc = encodeURIComponent;
		const searchQuery = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');
		// console.log('searchQuery = ', searchQuery);
		const filterQuery = Object.keys(filterParams)
			.map((f) => {
				const values = filterParams[f];
				return values.map((v) => esc(f) + '=' + esc(v)).join('&');
			})
			.join('&');
		// console.log('filterQuery = ', filterQuery);
		const query = searchQuery + '&' + filterQuery;
		// console.log('query = ', query);

		fetchContracts(query, filterQuery ? true : false);
	};

	loadData = (viewParams) => {
		const params = { ...this.state.viewParams, ...viewParams };
		this.setState({ viewParams: params }, () => this.fetchData());
	};

	showContractDetails = (contract) => this.props.history.push(`/contracts/${contract.contractID}`);

	handleFilterToggle = (e, { name, checked, category }) => {
		// console.log('handleFilterToggle');
		// console.log('name = ', name);
		// console.log('checked = ', checked);
		// console.log('category = ', category);
		const oldArray = this.state.filterParams[category] || [];
		// console.log('oldArray = ', oldArray);
		let newArray;
		if (checked) newArray = concat([ name ], oldArray);
		else {
			newArray = oldArray.filter((k) => k !== name);
		}

		// console.log('newArray = ', newArray);
		//...this.state.filterParams.category, name ==== this line was commented out before commenting out the logs
		this.setState(
			{
				filterParams: {
					...this.state.filterParams,
					[category]: newArray
				},
				viewParams: {
					...this.state.viewParams,
					offset: 0
				}
			},
			() => this.fetchData()
		);
	};

	render() {
		const { filters, contracts, totalCount } = this.props;
		const { viewParams: { limit, offset, sort, order } } = this.state;
		// console.log('TXN Search filters = ', filters);
		return (
			<Grid padded stackable>
				{filters && (
					<Grid.Column width={4}>
						<SearchFacets isComingFrom="contracts" filters={filters} onFilterToggle={this.handleFilterToggle} />
					</Grid.Column>
				)}
				<Grid.Column width={filters ? 12 : 16}>
					<Panel>
						<ContractTable
							totalCount={totalCount}
							contracts={contracts}
							limit={limit}
							offset={offset}
							sort={sort}
							order={order}
							loadData={this.loadData}
							showDetails={this.showContractDetails}
						/>
					</Panel>
				</Grid.Column>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	searchParams: getSearchParams(state),
	contracts: getSearchedContracts(state),
	totalCount: getSearchedContractsCount(state),
	filters: getSearchedContractsFilters(state)
});

ContractSearch = withRouter(connect(mapStateToProps, actions)(ContractSearch));

export default ContractSearch;
