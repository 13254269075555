import React, { Component } from 'react';
import Panel from './shared/Panel';
import Filter from './shared/Filter';
import DappTransactions from './containers/DappTransactions';
import Info from './shared/Info';

import '../animate.css';

export default class DappTransactionsPanel extends Component {
	state = {
		q: ''
	};

	onSubmitFilter = (value) => {
		this.setState({ q: value });
	};

	render() {
		const { q } = this.state;
		return (
			<Panel
				title={
					<React.Fragment>
						{/*TRANSACTIONS <Info id="contractsTxns" />*/}
					</React.Fragment>
				}
				description=""
				toolbar={
					<React.Fragment>
						<Filter onSubmitFilter={this.onSubmitFilter} />
						{/* <PeriodSelector /> */}
					</React.Fragment>
				}
			>
				<div className="transactions animated fadeIn"><DappTransactions filter={q} /></div>
			</Panel>
		);
	}
}
