import React from 'react';
import PropTypes from 'prop-types';
import DataTable from './shared/DataTable';
import NftsHistoricRow from './NftsHistoricRow';
import NftsHistoricTableHeader from './NftsHistoricTableHeader';
// import NftsHistoricAccountTableHeader from './NftsHistoricAccountTableHeader';

const NftHistoricalTable = (props) => {
	const { nftHistoricalData = [], size, offset, sort, order, totalCount, loadData, isAccountPage, accountId, showArrow } = props;
	const nftsDataRows = nftHistoricalData.map((nftData, index) => (
		<NftsHistoricRow key={index} nftData={nftData} isAccountPage={isAccountPage} accountId={accountId} showArrow={showArrow} {...props}/>
	));
	return (
		<DataTable
			limit={size}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={NftsHistoricTableHeader}
			rows={nftsDataRows}
			loadData={loadData}
			{...props}
		/>
	);
};

export default NftHistoricalTable;

NftHistoricalTable.propTypes = {
	loadData: PropTypes.func.isRequired
};
