import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';

export default function CodeSnippet({ code, onClose, children, ...rest }) {
	const content = children || code;
	return (
		<Segment className="code-snippet" {...rest}>
			{onClose && <Icon className="close clickable" name="close" onClick={onClose} />}
			{content && <p /*style={{ overflowX: 'auto' }}*/>{content}</p>}
		</Segment>
	);
}