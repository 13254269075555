import React, { Fragment } from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import '../animate.css';

const SubscriptionCustomTooltip = (props) => {

    return (
        <Fragment>
        {/* <Popup
            trigger={<Icon name="copy" className="copy-icon" onClick={props.handleCopyClick} />}
            content={<span className={props.clicked ? "info-content animated flash" : "info-content"}>
                        {props.clicked ? "Copied!" : "Copy to clipboard"}
                    </span>}
            hoverable
            style={{cursor: "pointer"}}
            basic
        /> */}
        <span className="hasTooltip">
            <div className="info-content">
                {props.text}
            </div>
            {props.text.slice(0, 40)}...
            {/* <Icon name="copy" className="copy-icon" onClick={props.handleCopyClick} /> */}
        </span>
        </Fragment>
    )

}

export default SubscriptionCustomTooltip;