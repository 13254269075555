import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DappAutocomplete from '../shared/DappAutocomplete';
import * as actions from '../../redux/actions';

class DappSearch extends Component {
	state = {
		value: ''
	};

	handleSearchChange = (e, { value }) => {
		this.setState({ value });
	};

	handleSubmit = (e, data) => {
		const { value } = this.state;
		const query = value && value.trim().length > 0 ? `q=${value.trim()}` : '';
		this.props.history.push(`/search?${query}`);
	};

	render() {
		const { value } = this.state;
		return (
			<DappAutocomplete
				// loading={isLoading}
				onResultSelect={this.handleResultSelect}
				onSearchChange={this.handleSearchChange}
				onSubmit={this.handleSubmit}
				// results={results}
				value={value}
				// placeholder="Search accounts, transactions, etc."
				showNoResults={false}
			/>
		);
	}
}

DappSearch = withRouter(connect(null, actions)(DappSearch));

export default DappSearch;
