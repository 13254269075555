import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import Number from './shared/Number';
import eye from '../assets/images/eye@2x.png';

const HederaTokenBalanceRow = ({ tokenBalance }) => {

	const redirectTo = (id) => {
		return window.location.href = `/tokens/${id}`;
	};

	return (
		<Table.Row className="clickable hoverRow">
			<Table.Cell collapsing>
				<span>
          			<Link to={`/tokens/${tokenBalance.tokenId}`}>{tokenBalance.tokenId}</Link>
				</span>
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(tokenBalance.tokenId)}>
        		<Number>{tokenBalance.balance}</Number>
			</Table.Cell>
			{/* <Table.Cell textAlign="center" collapsing>
        		<Link to={`/tokens/${tokenBalance.tokenId}`}>
					<Popup
						content="View Token Details"
						trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
					/>
				</Link>
      		</Table.Cell> */}
		</Table.Row>
	);
};

export default HederaTokenBalanceRow;

HederaTokenBalanceRow.propTypes = {
	tokenBalance: PropTypes.object.isRequired
};
