import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import ProfileHeader from './ProfileHeader';
import User from '../containers/User';
import Page from '../shared/Page';
import { Grid, Button } from 'semantic-ui-react';
import Panel from '../shared/Panel';


const Profile = (props) => {
	const Dropp = window.Dropp;

	const connectWallet = () => {
		if (Dropp) {
			Dropp.connect({});
			// Dropp.getAccountInfo()
		}
	}
	return (
		<Page>
			<Grid padded stackable>
				<Grid.Column width={5}>
					<div className="sticky" style={{position: "sticky", top: "82px"}} >
						<Panel title="MY PROFILE">
							<User />
						</Panel>
						{
							Dropp && (
								<div style={{marginTop: "5px"}}>
									<Panel title="Wallet Connect">
										<Button primary size='medium' onClick={() => connectWallet()} >Click here to Connect Dropp Wallet</Button>
									</Panel>
								</div>
							)
						}

					</div>
				</Grid.Column>
				<Grid.Column width={11}>
					<ProfileHeader />
				</Grid.Column>
			</Grid>
		</Page>
	);
}

export default connect(null, actions)(Profile);
