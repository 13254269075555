import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Table } from 'semantic-ui-react';
import { getDapps, getUser } from '../redux/reducers';

const DappRegisteredTable = (props) => {

    // console.log("THESE ARE THE PROPS IN DAPP REGISTERED TABLE: ", props)

    // const handleDelete = (keyId) => {
    //     props.deleteApiKey(keyId);
    //     props.reactivate();
    // }

    // const handleToggleState = (keyId, status) => {
    //     if (status === 'ACTIVE') {
    //         props.deactivateApiKey(keyId);
    //     } else if (status === 'INACTIVE') {
    //         props.activateApiKey(keyId);
    //     }
    // }

    // const handleDappClick = (id) => {
    //     console.log("THIS DAPP HAS BEEN CLICKED: ", id);
    //     props.handleUpdate(id);
    // }

    const handleCellClick = (id) => {
        window.open(`/dapps/${id}`, '_blank');
    }

    const dappsToDisplay = props.dapps.filter(dapp => dapp.userId === props.user.id);

    dappsToDisplay && console.log("DAPPS IN THE TABLE: ", dappsToDisplay)

    const rowsToDisplay = dappsToDisplay.map(dapp => {
        return (
            <Table.Row key={Math.random()}>
                <Table.Cell 
                    className="api-cell"
                    onClick={e => {handleCellClick(dapp.id)}} 
                    style={{fontWeight: "700", cursor: "pointer"}}>
                    <span className="text-gray">{dapp.title}</span>{/*`${key.key.slice(-4)}`*/}
                </Table.Cell>
                <Table.Cell className="api-cell">{dapp.description.slice(0, 30)}...</Table.Cell>
                <Table.Cell /*className="toggle-state" onClick={() => handleToggleState(k.key, k.state)}*/className="api-cell">
                    {dapp.active ? "ACTIVE" : "INACTIVE"}
                </Table.Cell>

                {/* ------------- ADD A CONTRACT ------------- */}
                <Table.Cell
                    className="api-cell"
                    style={{cursor: "pointer", textAlign: "center"}}
                    onClick={(e) => props.handleDisplayContracts(dapp)} 
                    width={2}>
                    <i className="icon file alternate outline text-gray"></i>
                </Table.Cell>
                {/* ------------------------------------------ */}

                <Table.Cell
                    className="api-cell"
                    style={{cursor: "pointer", textAlign: "center"}}
                    onClick={(e) => props.handleUpdate(dapp)} 
                    /*onClick={() => handleDelete(k.key)}*/ 
                    width={2}>
                    <i className="icon edit text-gray"/*"close icon red"*/></i>
                </Table.Cell>
            </Table.Row>
        )
    })

    return (
        <div>
            <Table celled className="api-key-table subscriptions">
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Dapp Name</Table.HeaderCell>
                    <Table.HeaderCell>Dapp Description</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Contracts</Table.HeaderCell>
                    <Table.HeaderCell>Update</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rowsToDisplay}
                </Table.Body>
            </Table>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    dapps: getDapps(state),
    user: getUser(state)
});

export default connect(mapStateToProps, actions)(DappRegisteredTable);
