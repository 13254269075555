import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';

export default class GraphToggle extends Component {

  constructor(props) {
		super(props);
	}

	render() {
		return(<div style={{marginTop:"-6.5px",marginRight:"-7px"}}><Button style={{backgroundColor:"white"}} onClick={this.props.toggle} icon={this.props.icon} /></div>);
	};
}
