import React, { useEffect } from 'react';
import { Grid, Container } from 'semantic-ui-react';
import NFTDetailsSummaryPanel from '../molecules/NFTDetailsSummaryPanel';
import NFTDetailsTabPanel from '../molecules/NFTDetailsTabPanel';
import Page from '../components/shared/Page';
import Panel from '../atoms/Panel';

export default (props) => {
    useEffect(() => { window.scrollTo(0, 0) }, []);
	return (
		<Page>
			<Container fluid className="w-98">
				<Grid padded stackable>
					<Grid.Column computer={16} tablet={16} mobile={16}>
						<Grid columns={1}>
							<Grid.Column>
								<NFTDetailsSummaryPanel {...props}/>
							</Grid.Column>
							<Grid.Column>
								<Panel>
                                	<NFTDetailsTabPanel {...props}/>
								</Panel>
							</Grid.Column>
						</Grid>
					</Grid.Column>
				</Grid>
			</Container>
		</Page>
	);
}

