import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import LineChart from '../shared/LineChart';
import BarChart from '../shared/BarChart';
import * as actions from '../../redux/actions';
import { getContractBalanceHistory, getContractBalanceHistoryLoader } from '../../redux/reducers';
import { formatTimestamp, parseQueryParams } from '../../utils';
import LoadingIndicator from "./LoadingIndicator";

class ContractBalanceChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period || this.props.id !== prevProps.id) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchContractBalanceHistory, id, period } = this.props;
		fetchContractBalanceHistory(id, period);
		this.props.sendContractIdparam(id);
	}
	render() {
		const { balanceHistory: { data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [ new Intl.NumberFormat('en').format(value) + ' ℏ' ];
		if (!this.props.loading) {
			return (
				data && data.length ?
				<Fragment>
					{/* <Stat count={totalSum} pct={pctChange} money currency={'hbar'} /> */}
					<br />
					<BarChart
						data={data}
						tickFormatter={formatter}
						labelFormatter={formatter}
						valueFormatter={valueFormatter}
					/>
				</Fragment>
				:  <div>History not available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />;
		}
	}
}

const mapStateToProps = (state, { match: { params: { contractId } } }) => {
	const params = parseQueryParams(window.location.search);
	return ({
		id: contractId ? contractId : params.contractId,
		balanceHistory: getContractBalanceHistory(state, contractId ? contractId : params.contractId),
		loading: getContractBalanceHistoryLoader(state, contractId ? contractId : params.contractId)
	});
}

ContractBalanceChart = withRouter(connect(mapStateToProps, actions)(ContractBalanceChart));

export default ContractBalanceChart;
