import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Menu, Image, Form, Dropdown, Container, Icon, Grid, Responsive} from 'semantic-ui-react';
import logoDG from '../assets/images/logo_dragonglass@2x.png';
import logoHH from '../assets/images/logo_hedera.svg';
import menuImage from '../assets/images/menu.svg';
import * as actions from '../redux/actions';
import { isAuthed, getSearchedTransactionsCount, getSearchedAccountsCount, getSearchedContractsCount, getSearchedTopicsCount } from '../redux/reducers';
import { getHederaTokensCount } from '../redux/reducers/hts';
import AccountSelector from '../components/containers/AccountSelector';
// import NetworkSelector from '../components/containers/NetworkSelector';
import NetworkSelector from '../atoms/NetworkSelector';
import NavLinks from '../components/containers/NavLinks';
import NavLinksForPC from '../atoms/NavLinksForPC';
import { isMobile, isRegexValidForId } from '../utils';
import NotificationBar from '../components/NotificationBar';
import AnnouncementBar from '../components/AnnouncementBar';
import Cookies from 'universal-cookie';
// import announcements from "../../reducers/announcements";
import moment from 'moment';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
import Search from "../components/containers/Search";
import Counts from '../atoms/Counts';

const userOptions = [
	{
		key: 'profile',
		text: 'PROFILE',
		value: 'profile',
		icon: 'id card'
	},
	{
		key: 'logout',
		text: 'SIGN OUT',
		value: 'logout',
		icon: 'power'
	}
];

const currencyOptions = [
	{
		key: 'hbar',
		text: 'show in  ℏBar',
		value: 'hbar'
	},
	{
		key: 'tbar',
		text: 'show in  tBar',
		value: 'tbar'
	}
	// {
	//   key: "usd",
	//   text: "USD",
	//   value: "usd",
	//   disabled: true
	// }
];

class NavBar extends Component {
	state = {
		menuOpen: false,
		notificationOpen: true,
		searchParams: {
			query: '',
		}
	};

	componentDidMount() {
		const { fetchConfig, fetchDapps, fetchAnnouncements } = this.props;
		fetchConfig();
		fetchDapps();
		fetchAnnouncements();
	}

	onOpenMenu = () => this.setState({ menuOpen: true });
	onCloseMenu = () => this.setState({ menuOpen: false });

	handleCloseNotification = () => {
		this.setState({ notificationOpen: false });
		this.props.hideAnnouncement();
	};

    handleCurrencyCookie = (currency, symbol) => {
       const cookies = new Cookies();
       cookies.set('selectedCurrency', currency, { path: '/' });
       cookies.set('selectedSymbol', encodeURIComponent(symbol), { path: '/' })
    }

	handleClick = (e, { value }) => {
		switch (value) {
			case 'logout':
				this.props.logout();
				break;
			case 'profile':
				this.props.history.push('/profile');
				break;
			case 'api':
				this.props.history.push('/apiview');
				break;
			case 'subscriptions':
				this.props.history.push('/subscriptionview');
				break;
			case 'faqs':
				this.props.history.push('/faqs');
				break;
			case 'featured':
				break;
			case 'medcore':
				this.props.history.push('/dapps/1');
				break;
			case 'sweattrack':
				this.props.history.push('/dapps/2');
				break;

			case 'deploy':
				this.props.history.push('/deploymentview');
				break;

			case 'hbar':
				console.log('HBAR CLICKED');
                this.handleCurrencyCookie('hbar', 'ℏ')
				this.props.selectHbar();
				break;
			case 'tbar':
				console.log('TINY BAR CLICKED');
                this.handleCurrencyCookie('tbar', 'tℏ')
				this.props.selectTbar();
				break;
			case 'usd':
				console.log('USD CLICKED');
				this.props.selectUSD();
				break;
			case 'fungible':
				this.props.history.push('/tokens/fungible');
				break;
			case 'non-fungible':
				this.props.history.push('/tokens/non-fungible');
				break;
			case 'dapps':
				break;
			default:
				this.props.history.push(`/dapps/${value}`);
		}
	};

	handleSearch = (e) => {
		e.preventDefault();
		const { searchParams } = this.state;
		const isMatch = isRegexValidForId(searchParams.query);
		this.doSearch(searchParams, isMatch)
	};

	handleChange = (e, { name, value }) => {
		this.setState((state, props) => {
			const { searchParams } = this.state;
			return { searchParams: { ...searchParams, [name]: value } };
		});
	};

	doSearch = (queryParams, isMatch) => {
		const { fetchTransactions, fetchAccounts, fetchContracts, fetchTopics, fetchTokens } = this.props;
		const countOnlyParams = { ...queryParams, limit: 0 };
		const searchText = queryParams.query;
		const esc = encodeURIComponent;
		const countOnlyQuery = Object.keys(countOnlyParams)
			.map((p) => esc(p) + '=' + esc(countOnlyParams[p]))
			.join('&');
		const a = fetchAccounts(countOnlyQuery, false);
		const c = fetchContracts(countOnlyQuery, false);
		const tp = fetchTopics(countOnlyQuery, false);
		const ht = fetchTokens(countOnlyParams);

		Promise.all([ a, c, tp, ht ]).then(() => {
			const { acctCount, cxtCount, topicsCount, tokensCount } = this.props;
			if (isMatch) {
				if (acctCount) {
					this.props.history.push(`/accounts/${searchText}`);
				} else if (tokensCount) {
					this.props.history.push(`/tokens/${searchText}`);
				} else if (topicsCount) {
					this.props.history.push(`/topics/${searchText}`);
				} else if (cxtCount) {
					this.props.history.push(`/contracts/${searchText}`);
				} else {
					this.props.history.push(`/search?${searchText}`);
				}
				return;
			}
			const t = fetchTransactions(countOnlyQuery, false);
			const { txCount } = this.props;
			if (txCount) {
				this.props.history.push(`/transactions/${searchText}`);
			} else {
				this.props.history.push(`/search?${searchText}`);
			}
		});

	};

	render() {
		let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth() + 1;
		let yyyy = today.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		let date = yyyy + '-' + mm + '-' + dd;

		// const validAnnouncement = this.props.announcements.find(ann => ann.date_from <= date && ann.date_to >= date && ann.active);

		const validAnnouncements = this.props.announcements.filter(
			(ann) => ann.date_from <= date && ann.date_to >= date && ann.active && ann.for_logged_in
		);
		const validNotifications = this.props.announcements.filter(
			(ann) => ann.date_from <= date && ann.date_to >= date && ann.active && !ann.for_logged_in
		);

		const announcementsToDisplay = validAnnouncements.map((ann) => (
			<AnnouncementBar onClose={this.handleCloseNotification} announcement={ann} />
		));

		const notificationsToDisplay = validNotifications.map((notif) => (
			<AnnouncementBar onClose={this.handleCloseNotification} announcement={notif} />
		));

		console.log('VALID ANNOUNCEMENTS: ', validAnnouncements);
		console.log('ALL ANNOUNCEMENTS: ', this.props.announcements);

		const { history, location, isAuthed = false } = this.props;
		const { menuOpen, notificationOpen } = this.state;
		
		const currentDateTime = new Date();
		const isHomePage = this.props.match.path.includes('home');

		const { q } = this.props;
		const { onChange, onReset, isAdvanced } = this.props;
		// console.log("ANNOUNCEMENTS IN NAV.JS: ", this.props.announcements)
		// console.log("ismobile = ", isMobile());
		return (
			<React.Fragment>
				{!isAuthed && notificationOpen && validNotifications && notificationsToDisplay}
				{isAuthed && notificationOpen && validAnnouncements && announcementsToDisplay}
				<Segment inverted className="app-header">

				<Responsive as="div" {...Responsive.onlyMobile} className="mobile-only">
					{/* {isMobile() && ( */}
						<Menu secondary inverted>
							<Container fluid={true}>
								<Menu.Item className="action action--open" onClick={this.onOpenMenu}>
									<Image src={menuImage} name="sidebar" aria-label="Open Menu" />
								</Menu.Item>
								{/* {isMobile() && ( */}
									<div onClick={() => history.push('/')} only="mobile">
										<Image size="small" src={logoDG} />
										<Image
											size="small"
											src={logoHH}
											className="logo-hedera"
											style={{ marginTop: '-0.5em' }}
										/>
									</div>
								{/* )} */}

								{/* For mobile View */}
								<NavLinks open={menuOpen} onClose={this.onCloseMenu} />
							</Container>
						</Menu>
						{/* )} */}

				</Responsive>

				<Responsive as="div" {...Responsive.onlyTablet} className="tablet-only">
					{/* {isMobile() && ( */}
						<Menu secondary inverted>
							<Container fluid={true}>
								<Menu.Item className="action action--open" onClick={this.onOpenMenu}>
									<Image src={menuImage} name="sidebar" aria-label="Open Menu" />
								</Menu.Item>
								{/* {isMobile() && ( */}
									<div onClick={() => history.push('/')} only="mobile">
										<Image size="small" src={logoDG} />
										<Image
											size="small"
											src={logoHH}
											className="logo-hedera"
											style={{ marginTop: '-0.5em' }}
										/>
									</div>
								{/* )} */}

								{/* For mobile View */}
								<NavLinks open={menuOpen} onClose={this.onCloseMenu} />
							</Container>
						</Menu>
						{/* )} */}

				</Responsive>

					{/* For PC View */}
					<Grid className="nav-desktop computer-only">
						<Grid.Row stretched style={{height: "100px"}}>
							<Grid.Column width={2}>
									<Menu.Item only="computer" onClick={() => history.push('/')} style={{borderRadius: "0px", padding: "10px 6px", borderRight: "1px solid white"}}>
										<Grid.Row>
											<Image size="medium" src={logoDG} />
										</Grid.Row>
										<Grid.Row>
											<p style={{color: "white"}}>The First Hedera Explorer</p>
										</Grid.Row>
									</Menu.Item>
							</Grid.Column>
							<Grid.Column width={11}>
								<Grid>
									<Grid.Row columns={3} style={{display: 'flex', justifyContent: 'end', padding: '0px'}}>
										<Counts isNewNavBar={true}/>
									</Grid.Row>
									<Grid.Row stretched style={{paddingTop: '0px'}}>
										<Grid.Column width={2} style={{padding: "10px 6px"}}>
											<NetworkSelector></NetworkSelector>
										</Grid.Column>
										<Grid.Column width={14}>
											<Grid>
												<Grid.Row stretched style={{alignContent: isHomePage ? 'center' : 'normal'}}>
													<NavLinksForPC></NavLinksForPC>
												</Grid.Row>
											</Grid>
											{
												!isHomePage
												?
													<Grid.Row stretched>
														
														<Form.Input
															fluid
															label=""
															name="query"
															placeholder="Transaction hash/ID, Account ID, Contract ID, Topic ID, Token ID, memo field - anything goes!"
															value={this.state.query}
															onChange={this.handleChange}
															action={{ icon: 'search', 
																onClick: this.handleSearch 
															}}
														/>
													</Grid.Row>
												: null
											}
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Column>
							<Grid.Column width={3}>
									<Grid.Row stretched style={{display: 'flex'}}>
										<Grid.Column className="left floated clickable" width={2}>
											{!isAuthed &&
											location.pathname !== '/login' && (
												<Menu.Item>
													<Link to="/login">SIGN IN</Link>
												</Menu.Item>
											)}
											{isAuthed && (
												<Menu.Item>
													<Dropdown icon={<Icon name="user circle" size="large" />} floating labeled>
														<Dropdown.Menu>
															{userOptions.map((option) => (
																<Dropdown.Item
																	key={option.value}
																	{...option}
																	onClick={this.handleClick}
																/>
															))}
														</Dropdown.Menu>
													</Dropdown>
												</Menu.Item>
											)}
										</Grid.Column>
										<Grid.Column className="left floated clickable" width={3} onClick={() => this.props.history.push('apiView')}>API Access</Grid.Column>
										{
											!(this.props.match.path.includes('home') ||
											this.props.match.path.includes('apiview') ||
											this.props.match.path.includes('subscriptionview') ||
											this.props.match.path.includes('deploymentview') ||
											this.props.match.path.includes('dappcatalog') ||
											this.props.match.path.includes('profile') ||
											this.props.match.path.includes('about') ||
											this.props.match.path.includes('terms') ||
											this.props.match.path.includes('privacy') ||
											this.props.match.path.includes('contact-us'))
											 && (
												<Grid.Column className="left floated" width={2}>
													<div className='visible'>
														<Menu.Item>
															<Dropdown
																direction="left"
																text={this.props.symbol}
															>
																<Dropdown.Menu>
																	{currencyOptions.map((option) => (
																		<Dropdown.Item
																			key={option.value}
																			{...option}
																			onClick={this.handleClick}
																		/>
																	))}
																</Dropdown.Menu>
															</Dropdown>
														</Menu.Item>
													</div>
												</Grid.Column>
											 )
										}
									</Grid.Row>
								{/* </Grid> */}
									<Grid.Row stretched style={{paddingTop: "2%"}}>
										<Grid.Column className="right floated" width={4} style={{display: "flex", justifyContent: "right"}}>
											<div style={{ textAlign: "center", background: "#ababab", padding: "4%", width: "100%"}}>
												<span style={{fontSize: "20px", color: "green"}}>NFT Explorer</span>
												<span style={{color: "red", position: "absolute", bottom: "35%", fontSize: "12px"}}>New</span>
											</div>
										</Grid.Column>

									</Grid.Row>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	isAuthed: isAuthed(state),
	dapps: state.dapps,
	symbol: state.symbol,
	announcements: state.announcements,
	acctCount: getSearchedAccountsCount(state),
	txCount: getSearchedTransactionsCount(state),
	cxtCount: getSearchedContractsCount(state),
	topicsCount: getSearchedTopicsCount(state),
	tokensCount: getHederaTokensCount(state),
});

export default withRouter(connect(mapStateToProps, actions)(NavBar));
