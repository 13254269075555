import React, { Fragment } from "react";
import { getTokenDetails } from "../redux/reducers/hts";
import Panel from "./shared/Panel";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tab, Table } from "semantic-ui-react";
import "../assets/css/components/tokenDetails.scss";

const HtsTokenSummary = props => {
    const { token } = props;
    return (
        <>
            {token ? (
                <Table celled stackable basic="very" id="token-summary">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Id:</Table.Cell>
                            <Table.Cell className="cell-summ-val" style={{ fontWeight: 700 }}>
                                {token.id}
                            </Table.Cell>
                        </Table.Row>
                        {/* <Table.Row>
                            <Table.Cell>Name:</Table.Cell>
                            <Table.Cell className="cell-value" style={{ fontWeight: 700 }}>
                                {token.name}
                            </Table.Cell>
                        </Table.Row> */}
                        <Table.Row>
                            <Table.Cell>Symbol:</Table.Cell>
                            <Table.Cell className="cell-summ-val" style={{ fontWeight: 700 }}>
                                {token.symbol}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Decimals:</Table.Cell>
                            <Table.Cell className="cell-summ-val" style={{ fontWeight: 700 }}>
                                {token.decimal || token.decimals}
                            </Table.Cell>
                        </Table.Row>
                        {/* <Table.Row>
                            <Table.Cell>Created On:</Table.Cell>
                            <Table.Cell className="cell-value" style={{ fontWeight: 700 }}>
                                {token.createdOn}
                            </Table.Cell>
                        </Table.Row> */}
                        <Table.Row>
                            <Table.Cell>Memo:</Table.Cell>
                            <Table.Cell className="cell-summ-val" style={{ fontWeight: 700 }}>
                                {token.memo}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Status:</Table.Cell>
                            <Table.Cell className="cell-summ-val" style={{ fontWeight: 700 }}>
                                {token.deletedOn ? "Deleted" : "Active"}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            ) : (
                ""
            )}
        </>
    );
};

const mapStateToProps = state => ({
    token: getTokenDetails(state),
});

export default withRouter(connect(mapStateToProps)(HtsTokenSummary));
