import { FETCH_ACCOUNTS_REQUEST, FETCH_ACCOUNTS_SUCCESS, FETCH_ACCOUNTS_FAILURE, RESET_SEARCH } from '../types';

const initialState = {
	isLoading: false,
	filters: null,
	results: [],
	totalCount: 0
};

export default function searchedAccounts(state = initialState, action) {
	switch (action.type) {
		case FETCH_ACCOUNTS_REQUEST:
			return { ...state, isLoading: true };
		case FETCH_ACCOUNTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				results: action.response.data,
				filters: action.filtered === true ? state.filters : action.response.facets,
				totalCount: action.response.totalCount
			};
		case FETCH_ACCOUNTS_FAILURE:
			return { ...initialState };
		case RESET_SEARCH:
			return { ...initialState };
		default:
			return state;
	}
}

export const getSearchedAccounts = (state) => state.results;
export const getSearchedAccountsCount = (state) => state.totalCount;
export const getSearchedAccountsFilters = (state) => state.filters;
