import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';
import Panel from './shared/Panel';

import SubscriptionDescriptionIntro from './SubscriptionDescriptionIntro'

import '../animate.css';

const introText = 
    <div>

      <SubscriptionDescriptionIntro />
      {/* <span>Live Subscription enable DApp developers to subscribe to either Transaction, Event (emitted from a Contract Call), 
        or HCS message for a topic ID (testnet only).</span><br /><br /> 
        <strong>Upload ABI </strong><br />
        <span>DragonGlass provides an ability for you upload ABIs for events and functions.
        (This will contain ABI Upload documentation from Domenico)</span><br /><br /> 
        <strong>Sample Code in Java to subscribe to DragonGlass queue</strong><br />
        <span>(From Domenico)</span> */}
    </div>

export default () => (
  <Panel
    title={
      <Fragment>
        Introduction
      </Fragment>
    }
    description=""
  >
    <Divider />
    <div className="introduction animated fadeIn">
      {introText}
    </div>
  </Panel>
);