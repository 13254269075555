import { FETCH_TRANSACTION_TYPES_SUCCESS, FETCH_TRANSACTION_TYPES_LOAD } from '../types';

export default function transactionTypes(state = {}, action) {
	switch (action.type) {
		case FETCH_TRANSACTION_TYPES_LOAD:
			return {
				loading: true,
				types: [...action.payload]
			};
		case FETCH_TRANSACTION_TYPES_SUCCESS:
			return {
				loading: false,
				types: [...action.payload]
			};
		default:
			return state;
	}
}

export const getTransactionTypes = (state) => state;
