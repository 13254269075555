import React from 'react';
import { Container } from 'semantic-ui-react';

function nonEmpty(parts) {
	var res = parts[0];
	for (var i = 1; i < parts.length; i++) {
		if (
			arguments[i] // you might want to handle `0` different
		)
			res += arguments[i];
		res += parts[i];
	}
	return res;
}

export default function Page(props) {
	return (
		<div className={nonEmpty`page ${props.className}`}>
			<Container fluid>
				<div className="page-content">{props.children}</div>
			</Container>
		</div>
	);
}
