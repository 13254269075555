import React, { Fragment, useState } from 'react';
import Panel from './shared/Panel';
import { Icon, Modal } from 'semantic-ui-react';
import Video from './shared/Video';
import Info from './shared/Info';

const DemoVideoPanel = () => {
	const [ demoOpen, setDemoOpen ] = useState(false);

	const handleCancel = () => {
		setDemoOpen(false);
	};

	const handleDemo = () => {
		setDemoOpen(true);
	};

	return (
		<Fragment>
			<Panel
				title={
					<React.Fragment>
						EXPLORER OVERVIEW <Info id="demoVideo" />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				// toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				{/* <div style={{marginBottom: "30px"}}><TxnTypeChart period={period} /></div> */}
				<div onClick={handleDemo}>
					<img style={{ width: '100%', cursor: 'pointer' }} src="/dg_demo_video_first_frame.png" />
				</div>
				<div
					onClick={handleDemo}
					style={{
						position: 'absolute',
						top: '10px',
						left: '20px',
						cursor: 'pointer'
					}}
				>
					<Icon size="big" name="caret square right outline" inverted />
				</div>
			</Panel>

			<Modal
				open={demoOpen}
				closeIcon
				onClose={handleCancel}
				size="large"
				dimmer="blurring"
				className="animated bounceInDown"
			>
				{/* <Modal.Header className="modal-header">Select a Subscription Model</Modal.Header> */}
				<Modal.Content>
					<div style={{ marginBottom: '10px' }}>
						<Video src="/dg_demo_video.mp4" />
					</div>
				</Modal.Content>
			</Modal>
		</Fragment>
	);
};

export default DemoVideoPanel;
