import React, { Component } from 'react';
import { Popup, Icon, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { withRouter, Link } from 'react-router-dom';

class PopupWithContent extends Component {
	state = {
    open: false,
    contentData: []
	};

	onOpen = (e) => {
		this.setState({ open: true });
	};

  onClose = () => this.setState({ open: false });

  showContent = (contentData, contentLabel) => {
    return (
      <Table basic="very" size="small" className="animated fadeIn" >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{contentLabel}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {contentData.map((content, i) => (
            <Table.Row key={i}>
              <Table.Cell width={1} style={{ paddingLeft: "11px" }}>
                <Link to={`/accounts/${content}`}>{content}</Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

	render() {
    const { open } = this.state;
    const { contentData = [], contentLabel } = this.props;
		return (
			<Popup
				open={open}
				content={contentData.length > 0 ? this.showContent(contentData, contentLabel) : ''}
				trigger={<Icon className="clickable" name="list" color="blue" />}
				onOpen={this.onOpen}
				onClose={this.onClose}
				position="right center"
			/>
		);
	}
}

PopupWithContent = withRouter(connect(null, actions)(PopupWithContent));

export default PopupWithContent;
