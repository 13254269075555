import {
  FETCH_PRICING_PLANS_SUCCESS,
  FETCH_PRICING_PLANS_FAILURE
} from '../types';

const initialState = {};

export default function pricingPlan(state = initialState, action) {
	switch (action.type) {
		case FETCH_PRICING_PLANS_SUCCESS:
			return {
				...state,
				pricingPlansData: action.data
			};
		case FETCH_PRICING_PLANS_FAILURE:
			return {
				...state,
				pricingPlansData: []
			};
		default:
			return state;
	}
}
