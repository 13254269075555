import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup, Grid, Icon } from 'semantic-ui-react';
import Number from './shared/Number';
import eye from '../assets/images/eye@2x.png';
import { decodeBase64, fromDate, isMobile } from '../utils';
import Base64Icon from "../assets/images/icons8-base-64-24.png";
import TxtIcon from "../assets/images/icons8-txt-24.png";
// import DisplayContent from "../components/shared/DisplayContent";
import DateCell from './shared/DateCell';
import DisplayContent from "../atoms/DisplayContent";

const NftsBalanceRow = ({ nftData, isHomePage, filters }) => {
	const [showText, setShowText] = useState(true);

	const handleShowTextClick = () => {
		setShowText(!showText);
		return;
	};
	const redirectTo = (nftData) => {
		return window.location.href =`/nft-token/${nftData.token}?serialNumber=${nftData.serialNumber}`;
	};

	return (
		<Table.Row style={{cursor: "pointer"}} className="hoverRow">
			<Table.Cell collapsing onClick={()=> redirectTo(nftData)} textAlign="center">
				<Grid.Column column={1}>
				{/* {
					nftData.imageUrl && isImgUrl(nftData.imageUrl)
					? <Image style={{margin: '0 auto', width: isHomePage ? '20%': '15%'}} alt="NFT Image" src={nftData.imageUrl} />
					: <Icon style={{margin: '0 auto', width: '100%'}} name="video"></Icon>
				} */}
				{
					<DisplayContent size={isHomePage ? 'medium' : filters ? 'large' : 'small' } mimeType={nftData.dataType} src={nftData.imageUrl} alt="NFT"/>
				}
				</Grid.Column>
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(nftData)}>
				<span>
          			{/* <Link to={`/tokens/${nftData.token}`}>{nftData.token}</Link> */}
					  {nftData.token}
					  <div style={{
						color: "gray",
						fontWeight: 600,
						wordBreak: "break-word",
						paddingRight: "1rem",
						paddingBottom: "1rem",
						overflow: 'hidden',
						textOverflow: 'ellipsis', 
						whiteSpace: 'nowrap',
						maxWidth: isMobile() ? "230px" : '85px',
						}}>{nftData.name}</div>
				</span>
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(nftData)}>
        		<Number>{nftData.serialNumber}</Number>
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(nftData)}>
        		{/* {nftData.createdOn ? fromDate(nftData.createdOn) : ''} */}
				<DateCell date={nftData.createdOn} />
			</Table.Cell>
			<Table.Cell collapsing>
				<div onClick={()=> redirectTo(nftData)} title={showText ? decodeBase64(nftData.metaData) : nftData.metaData}
					style={{
						overflow: "hidden",
						maxWidth: isHomePage ? "100px" : isMobile() ? "230px" : filters ? "200px" : "320px",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						position: "relative",
						top: "8px"
					}}>
						<span>{showText ? decodeBase64(nftData.metaData) : nftData.metaData}</span>
					</div>
						{ nftData && nftData.metaData
							?<>
							<span style={{"position": "relative", "bottom": "12px", "right": "32px"}}>
								{ showText 
									? <Image src={Base64Icon} className="copy-icon" size="mini" style={{ width: '18px', height: '18px' }} onClick={handleShowTextClick}/>
									: <Image src={TxtIcon} className="copy-icon" size="mini" style={{ width: '18px', height: '18px' }} onClick={handleShowTextClick}/>
								}
							</span>
							</>
							: null
						}
			</Table.Cell>
		</Table.Row>
	);
};

export default NftsBalanceRow;

NftsBalanceRow.propTypes = {
	nftData: PropTypes.object.isRequired
};
