import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Statistic, Divider, Grid } from 'semantic-ui-react';
import Number from '../atoms/Number';
import * as actions from '../redux/actions';
import { getStats, getNetworks } from '../redux/reducers';
import * as constants from '../constants';
import { fromDate } from '../utils';
import Info from '../components/shared/Info';

const Stat = ({ label, value }) => <Statistic horizontal size="small" label={label} value={<Number>{value}</Number>} />;

class Stats extends Component {
	interval = null;
	state = {
		hbarPrice: 0 
	}
	componentDidMount() {
		this.fetchData();
		this.interval = setInterval(() => this.fetchData(), constants.REFRESH_INTERVAL);
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	fetchData() {
		if (!document.hidden) {
			const { fetchStats } = this.props;
			fetchStats();
			this.fetchPriceTrend();
		}
	}

	fetchPriceTrend = () => {
		this.props.fetchPriceTrend(this.props.period).then(
			(response) => {
				const hbarPric = response && response.data || 0;
				this.setState({ hbarPrice: hbarPric });
			}
		)
	}

	getNetworkDate = () => {
		const { networks } = this.props;
		if (networks) {
			const network = networks.find((n) => n.url === window.location.origin);
			return network ? network.genesisDate : null;
		} else {
			return null;
		}
	};

	render() {
		const { numTransactions, numAccounts, latestAccountId } = this.props.stats;
		const { isNewNavBar } = this.props;
		const genesisDate = this.getNetworkDate();

		return (
			<Fragment>
				{		
						<>
							<Grid.Column mobile={4} computer={5} className="align-center">
								<div style={{background: "#ababab", marginRight: "30px"}}>
									HBAR Price: $<Number>{this.state.hbarPrice}</Number>
								</div>
							</Grid.Column>
							<Grid.Column mobile={4} computer={6} className="align-center">
								<div style={{background: "#ababab", marginRight: "30px"}}>
									Transactions: <Number>{numTransactions}</Number>
								</div>
							</Grid.Column>
							<Grid.Column mobile={4} computer={5} className="align-center">
								<div style={{background: "#ababab", marginRight: "30px"}}>
									Accounts: <Number>{numAccounts}</Number>
								</div>
							</Grid.Column>
						</>
						// <Stat label="transactions" value={numTransactions} />
						// <span>
						// 	<Info id="totalTxns" />
						// </span>
						// <div style={{ fontSize: '12px', color: '#999' }}>
						// 	{`${genesisDate ? `Since, ${fromDate(genesisDate)}` : ''}`}
						// </div>
						// <Divider />
						// <Stat label="total accounts" value={numAccounts} />
						// <span>
						// 	<Info id="totalAccounts" />
						// </span>
						// <div style={{ fontSize: '12px', color: '#999' }}>{`${latestAccountId
						// 	? `Last account created, ${latestAccountId}`
						// 	: ''}`}</div>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	stats: getStats(state),
	networks: getNetworks(state)
});

Stats = connect(mapStateToProps, actions)(Stats);

export default Stats;
