import React from 'react';
import JSONPretty from 'react-json-pretty';

export const SubscriptionEvent = () => {
    
    const data = `{"size": 1, "data": [{ "id": "001568041585103039331346800-SUCCESS", "consensusTime": "2020-03-25T02:24:09.084593001Z", 
    "message": {"functionName":"HighestBidIncreased","inputNames":["bidder","amount"],"inputTypes":["address","uint256"],
    "inputValues":["0.0.156804","13"],"transactionID":{"accountID":{"num":156804,"shardNum":0,"realmNum":0},
    "validStartDate":"2020-03-25T02:23:59.331346800Z"}} }]}`

    return (
        <div style={{color: "white", backgroundColor: "black"}}>
            <JSONPretty id="json-pretty" data={data}></JSONPretty>
        </div>
    )
}

export default SubscriptionEvent;
