import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import DappContractsTable from './DappContractsTable';
import DappContractsTableNew from './DappContractsTableNew';
// import CodeSnippet from '../shared/CodeSnippet';
// import DappContractsListTabs from '../DappContractsListTabs';
import * as actions from '../redux/actions';
import { getDappContracts } from '../redux/reducers';

const DappContractsDisplay = (props) => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchData() }, []);

    const fetchData = async () => {
        props.fetchDappContracts(props.dapp.id);
    }

    props.dappContracts.length > 0 && console.log("CONTRACTS IN DAPP CONTRACTS DISPLAY: ", props.dappContracts)

    return (
        props.dappContracts.length > 0 && <div>
            {/* <DappContractsTable contracts={props.dappContracts} handleAddContract={props.handleAddContract} dapp={props.dapp} /> */}
            
            <DappContractsTableNew 
                contracts={props.dappContracts} 
                handleAddContract={props.handleAddContract} 
                dapp={props.dapp} 
                handleCancel={props.handleCancel} 
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
	id: ownProps.dapp.id,
	// dapp: getDapp(state, dappId),
	dappContracts: getDappContracts(state, ownProps.dapp.id)
});

export default connect(mapStateToProps, actions)(DappContractsDisplay);
