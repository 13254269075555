import React, { useState, useEffect } from 'react';
import { Divider, Radio, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SubscriptionEventExamples from './SubscriptionEventExamples';
import SubscriptionMessageExamples from './SubscriptionMessageExamples';

import SubscriptionEvent from './SubscriptionEvent';
import SubscriptionMessage from './SubscriptionMessage';

const profile = <Link to="/profile" target="_blank">Profile page</Link>

const signup = <Link to="/signup" target="_blank">sign up</Link>

const iam = <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_identifiers.html" target="_blank">AWS IAM ARN</a>
const iamuser = <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_identifiers.html" target="_blank">IAM User</a>

const gosdk = <a href="https://docs.aws.amazon.com/sdk-for-go/api/service/sqs/" target="_blank">Go SDK</a>
const javasdk = <a href="https://docs.aws.amazon.com/sdk-for-java/v1/developer-guide/examples-sqs.html" target="_blank">Java SDK</a>
const pythonsdk = <a href="https://boto3.amazonaws.com/v1/documentation/api/latest/guide/sqs-example-sending-receiving-msgs.html" target="_blank">Python SDK</a>
const phpsdk = <a href="https://docs.aws.amazon.com/aws-sdk-php/v2/guide/service-sqs.html" target="_blank">PHP SDK</a>
const javascriptsdk = <a href="https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/sqs-examples.html" target="_blank">JavaScript SDK</a>

const SubscriptionIntroDescription = () => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { window.scrollTo(0, 0) }, [])

    const [showTransaction, setShowTransaction] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const handleTransactionClick = (e, {checked}) => {
        setShowTransaction(checked);
    } 

    const handleEventClick = (e, {checked}) => {
        setShowEvent(checked);
    } 

    const handleMessageClick = (e, {checked}) => {
        setShowMessage(checked);
    } 

    return (
        <div>
            {/* <strong>DragonGlass Realtime Live Subscriptions</strong><br /> */}

            <div className="description-text" style={{marginBottom: "20px"}}>Welcome to the DragonGlass subscription service. 
                Here you can get access to real-time high quality data from Hedera Hashgraph.</div>

            <div className="description-text" style={{marginBottom: "20px"}}>The Subscription Service is useful for the following use-cases:</div>

            <div className="description-text" style={{marginBottom: "20px"}}>
                <ol>
                    <li>A DApp with a high transaction volume that makes it difficult to poll using RESTful APIs.</li>
                    <li>A DApp using a smart contract emitting an event</li>
                    <li><strong>Hedera’s Consensus Service</strong> is the ideal use case for the Subscription Service, 
                        since you can read messages on a topic directly from the DragonGlass queue. 
                        The queue provides a pub/sub mechanism for a DApp to subscribe to the queue to get messages in real-time.</li>
                </ol>
            </div>

            <div className="description-text" style={{marginBottom: "20px"}}>The DragonGlass queue uses Amazon Simple Queue Service. 
                Every queue that is created would be available on AWS <strong>“us-east-1”</strong> region.</div>
{/* ---------- */}
            <div className="intro-title-small" id="batching" style={{paddingTop: "70px", marginTop: "-50px"}}>Batching</div>

            <div className="description-text" style={{marginBottom: "20px"}}>To ensure higher throughput for clients, 
                the Subscription Service batches messages and sends them in a group, 
                with a default message size of 10. Batch messages are sent to the SQS queue when either the batch size is 10 
                or there is an elapsed time of 5 seconds since the first message was collected, whichever occurs first.</div>
{/* ---------- */}
            <div className="intro-title-small" id="subscriptionsetup" style={{paddingTop: "70px", marginTop: "-50px"}}>Subscription Setup</div>

            <div className="description-text" style={{marginBottom: "20px"}}>
                DragonGlass gives DApp developers the ability to subscribe to different kinds of events as they
                happen in Hedera in real-time. Users can subscribe to Transactions, Events emitted from Smart Contracts, 
                and HCS messages for a topic ID.
                <div className="description-text">
                    To start using event subscription, users must {signup} and create an account with
                    DragonGlass.
                </div>
            </div>

            <div className="image-div">
                <img width="90%" src="/sign_in_circled.jpg" />
            </div>
            <div className="image-div" style={{marginLeft: "30%"}}>
                <img width="55%" src="/sign_up_menu.jpg" />
            </div>
            <div className="description-text" style={{marginBottom: "20px"}}>
                After signing in, navigate to the {profile}. To subscribe to a live realtime 
                subscription, click the "Add a Subscription button" under the My Subscription section.</div>

            <div className="image-div" style={{marginLeft: "5%"}}>
                <img width="90%" src="/subscriptions_intro_add.png" />
            </div>

            <div className="image-div" style={{marginLeft: "6%"}}>
                <img width="89%" src="/subscriptions_intro_create.png" />
            </div>
                
            <div className="description-text" style={{marginBottom: "20px"}}>Details can befound under the SubscriptionType 
                subsection for TRANSACTION, EVENT, and HCS_TOPIC.<br />
            </div>

            <div className="description-text" style={{marginBottom: "20px"}}>Please note that &nbsp;{iam} can be added at time of creation of subscription. 
                Adding a user will remove public access from the queue and give “Read Only” access to the AWS IAM user. </div>
{/* ---------- */}
            <div className="intro-title-small" id="subauthentication" style={{paddingTop: "70px", marginTop: "-50px"}}>Authentication</div>

            <div className="description-text" style={{marginBottom: "20px"}}>By default, the created queue 
                will be public and any consumer using any AWS IAM user would be able to read messages from the queue. </div>

            <div className="description-text" style={{marginBottom: "20px"}}>You could use AWS-SDK to access the DragonGlass queue. 
                Please refer below for language specific AWS-SDK:</div>

            <div className="description-text" style={{marginBottom: "20px"}}>
                <ol>
                    <li>{gosdk}</li>
                    <li>{javasdk}</li>
                    <li>{pythonsdk}</li>
                    <li>{phpsdk}</li>
                    <li>{javascriptsdk}</li>
                </ol>
            </div>

            <div className="description-text" style={{marginBottom: "20px"}}>To be able to use your own credentials 
                to access the DragonGlass queue, you will need an AWS account and an {iamuser}. You can add credentials 
                to an already existing queue by clicking on the Edit Queue button as shown below.</div>

            <div className="image-div" style={{marginLeft: "4.5%"}}>
                <img width="90.5%" src="/subscriptions_intro_add_queue.png" />
            </div>

            <div className="image-div" style={{marginLeft: "6%"}}>
                <img width="89%" src="/subscriptions_intro_edit.png" />
            </div>
{/* ---------- */}
            <div className="intro-title-small" style={{marginBottom: "25px"}} id="subdatatypes">Data Types</div>

            <div className="intro-title-small" style={{fontSize: "105%"}}>Transaction</div>

            <div style={{marginBottom: "-15px"}}>
                <Radio toggle label={showTransaction ? 'HIDE TRANSACTION DATA' : 'SHOW TRANSACTION DATA'} 
                className="header parameters-toggle" checked={showTransaction} onChange={handleTransactionClick} />
            </div>

            {showTransaction && <div className="image-div" style={{marginLeft: "2%", position: "relative", bottom: "-25px"}}>
                <img width="98%" src="/subscriptions_transaction_1st.png" />
            </div>}

            {showTransaction && <div className="image-div" style={{marginLeft: "1.6%", position: "relative"}}>
                <img width="98.4%" src="/subscriptions_transaction_2nd.png" />
            </div>}

            <div style={{height: "30px"}}></div>

            <div className="intro-title-small" style={{fontSize: "105%", marginTop: "15px"}}>Event</div>

            <div style={{marginBottom: "-15px"}}>
                <Radio toggle label={showEvent ? 'HIDE EVENT DATA' : 'SHOW EVENT DATA'} 
                className="header parameters-toggle" checked={showEvent} onChange={handleEventClick} />
            </div>

            {/* {showEvent && <div style={{width: "90%", marginLeft: "5%", marginTop: "30px"}}><SubscriptionEventExamples /></div>} */}
            {showEvent && <div style={{width: "90%", marginLeft: "5%", marginTop: "30px"}}><SubscriptionEvent /></div>}

            <div className="intro-title-small" style={{fontSize: "105%", marginTop: "45px"}}>HCS Message</div>

            <div style={{marginBottom: "-15px"}}>
                <Radio toggle label={showMessage ? 'HIDE HCS MESSAGE DATA' : 'SHOW HCS MESSAGE DATA'} 
                className="header parameters-toggle" checked={showMessage} onChange={handleMessageClick} />
            </div>

            {/* {showMessage && <div style={{width: "90%", marginLeft: "5%", marginTop: "30px"}}><SubscriptionMessageExamples /></div>} */}
            {showMessage && <div style={{width: "90%", marginLeft: "5%", marginTop: "30px"}}><SubscriptionMessage /></div>}


            {/* <strong>Upload ABI</strong><br />
            <div className="description-text">Additionally, DragonGlass provides the ability for users to 
                upload ABIs for EVENTS and TRANSACTIONS exclusively.  Once a subscription is created, a user can upload an 
                ABI file by clicking the icon in the Upload ABI column in the My Subscriptions menu in their profile.
            </div>
            <div className="image-div">
                <img width="90%" src="/upload_ABI.png" />
            </div>
            <div className="description-text">
                The ABI is going to be used for parsing the event data in case of a subscription type EVENT or the contract 
                call data in case of the subscription type TRANSACTION.
            </div> */}
        </div>
    )
}

export default SubscriptionIntroDescription;