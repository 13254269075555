import React from 'react';
import Panel from './shared/Panel';
import HederaTokenBalance from './HederaTokenBalance';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Info from './shared/Info';

const HederaTokenBalancePanel = (props) => {

	return (
		<Panel
			title={
				<React.Fragment>
					<Info id="tokenBalances" />
				</React.Fragment>
			}
		>
      <HederaTokenBalance />
		</Panel>
	);
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
  id: accountId
});

export default withRouter(connect(mapStateToProps)(HederaTokenBalancePanel));