import React from 'react';
import PropTypes from 'prop-types';
import DataTable from './shared/DataTable';
import HederaTokenBalanceRow from './HederaTokenBalanceRow';
import HederaTokenBalanceTableHeader from './HederaTokenBalanceTableHeader';

const HederaTokenBalanceTable = ({ tokenBalances = [], size, offset, sort, order, totalCount, loadData }) => {
	const tokenBalanceRows = tokenBalances.map((tokenBalance, index) => (
		<HederaTokenBalanceRow key={index} tokenBalance={tokenBalance} />
	));
	return (
		<DataTable
			limit={size}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={HederaTokenBalanceTableHeader}
			rows={tokenBalanceRows}
			loadData={loadData}
		/>
	);
};

export default HederaTokenBalanceTable;

HederaTokenBalanceTable.propTypes = {
	// totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired
};
