import { combineReducers } from 'redux';
import { 
	FETCH_API_CATEGORIES_SUCCESS,
	FETCH_API_CATEGORIES_FAILURE,
	FETCH_ALL_APIS_SUCCESS,
	FETCH_ALL_APIS_FAILURE,
	FETCH_API_PARAMETERS_SUCCESS,
	FETCH_API_PARAMETERS_FAILURE
} from '../types';

const allApis = (state = [], action) => {
	switch (action.type) {
		case FETCH_ALL_APIS_SUCCESS:
			// console.log(action.payload);
			return [ ...action.payload ];
		case FETCH_ALL_APIS_FAILURE:
				return [];
		default:
				return state;
	}
}
		
const categories = (state = [], action) => {
	switch (action.type) {
		case FETCH_API_CATEGORIES_SUCCESS:
            // console.log(action.payload);
			return [ ...action.payload ];
        case FETCH_API_CATEGORIES_FAILURE:
            return [];
		default:
			return state;
	}
};

const parameters = (state = [], action) => {
	switch (action.type) {
		case FETCH_API_PARAMETERS_SUCCESS:
            // console.log(action.payload);
			return [ ...state, ...action.payload ];
        case FETCH_API_PARAMETERS_FAILURE:
            return [];
		default:
			return state;
	}
};

const apiCatalog = combineReducers({
	allApis,
	categories,
	parameters
});

export default apiCatalog;

export const getCategories = (state) => state.categories;
export const getAllApis = (state) => state.allApis;
export const getParameters = (state) => state.parameters;