import React, { useEffect } from "react";
import { Grid, Image, Header, Button, Icon } from "semantic-ui-react";
import Page from "../shared/Page";
import Panel from "../shared/Panel";
import Logo from "../../assets/images/logo_dragonglass@2x.png";
import DLTLogos from "../../assets/images/Logos.png";
import IconGears from "../../assets/images/icon-gears.png";
import IconHistorical from "../../assets/images/icon-historical.png";
import HederaApp from "../../assets/images/hedera-app.png";
import IconLiveFeeds from "../../assets/images/icon-live_feeds.svg";
import IconHistoricalData from "../../assets/images/icon-historical_data.svg";
import IconBulkData from "../../assets/images/icon-bulk_data.svg";
import EA from "../../assets/images/legacy-ea.svg";
import DGA from "../../assets/images/dga.png";
import TArrow from "../../assets/images/transformation-arrow.svg";
import { Link } from "react-router-dom";

import DemoVideoPanel from "../DemoVideoPanel";

export default function AboutNew() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const apis = <Link to="/apiview">prebuilt APIs</Link>;
  const explorer = <Link to="/search">DragonGlass Explorer</Link>;

  return (
    <Page className="page-about">
      <Grid padded stackable>
        <Grid.Column>
          <Panel>
            <Grid stackable>
              <Grid.Row className="intro" centered>
                <Grid.Column
                  width="12"
                  verticalAlign="middle"
                  textAlign="center"
                >
                  {/* <div>
										<Image
											src={Logo}
											style={{
												margin: 'auto',
												// width: '60%'
											}}
										/>
									</div> */}
                  <h3
                    className="section-subheading"
                    style={{ fontSize: "240%" }}
                  >
                    <span className="dragon">Dragon</span>
                    <span className="glass">Glass</span> is a cloud-based
                    service that provides live and historical data across all
                    distributed ledgers.
                  </h3>

                  <div
                    style={{
                      textAlign: "left",
                      color: "#BA303A",
                      fontSize: "220%",
                      marginBottom: "17px",
                    }}
                  >
                    Why DragonGlass?
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "130%",
                      marginBottom: "30px",
                    }}
                  >
                    DragonGlass addresses several key needs in the distributed
                    ledger ecosystem:
                  </div>

                  <div style={{ display: "flex", margin: "0 25px 25px 40px" }}>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "240%",
                        color: "#0B91AB",
                        position: "relative",
                        top: "8px",
                      }}
                    >
                      <Icon name="caret right" />
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "120%",
                        lineHeight: "130%",
                      }}
                    >
                      Hedera Hashgraph does not store historical data by design;
                      developers need a way to access this information and
                      integrate it into their products
                    </div>
                  </div>

                  <div style={{ display: "flex", margin: "0 25px 25px 40px" }}>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "240%",
                        color: "#0B91AB" /*position: "relative", top: "5px"*/,
                      }}
                    >
                      <Icon name="caret right" />
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "120%",
                        lineHeight: "130%",
                      }}
                    >
                      Processing and storing ledger data requires highly
                      scalable and available infrastructure
                    </div>
                  </div>

                  <div style={{ display: "flex", margin: "0 25px 25px 40px" }}>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "240%",
                        color: "#0B91AB",
                        position: "relative",
                        top: "8px",
                      }}
                    >
                      <Icon name="caret right" />
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "120%",
                        lineHeight: "130%",
                      }}
                    >
                      Developers need a simple way to receive realtime data and
                      access historical data without requiring expensive
                      infrastructure
                    </div>
                  </div>

                  <div style={{ display: "flex", margin: "0 25px 25px 40px" }}>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "240%",
                        color: "#0B91AB",
                        position: "relative",
                        top: "8px",
                      }}
                    >
                      <Icon name="caret right" />
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "120%",
                        lineHeight: "130%",
                      }}
                    >
                      Crypto traders and dApps need a way to easily and quickly
                      view specific transactions and accounts
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row
                columns="1"
                style={{
                  /*paddingTop: '3rem',*/ height: "160px",
                  background: "#FBFBFB",
                }}
                verticalAlign="middle"
              >
                <Grid padded columns="1" textAlign="center">
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <h2 className="section-heading">
                        Request Access to <span className="dragon">DRAGON</span>
                        <span className="glass">GLASS</span> Beta
                      </h2>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <Grid.Column textAlign="center">
                      <Link to="/contact-us">
                        <Button primary>GET STARTED</Button>
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Row>

              <Grid.Row className="intro get-started-mobile" centered>
                <Grid.Column
                  width="12"
                  verticalAlign="middle"
                  textAlign="center"
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "130%",
                      margin: "30px 0 40px 0",
                      lineHeight: "150%",
                    }}
                  >
                    With DragonGlass, developers get realtime and complete
                    transparency of all data in Hedera Hashgraph. dApps can
                    easily access this information with our set of {apis}.
                    DragonGlass does the heavy lifting by providing automated
                    data services to developers so they can focus on building
                    their product. For those who aren't building dApps but need
                    a way to view specific pieces of information, the powerful{" "}
                    {explorer}&nbsp; makes it quick and easy to view any piece
                    of information.
                  </div>

                  <div
                    style={{
                      fontSize: "250%",
                      lineHeight: "150%",
                      margin: "20px 0 30px 0",
                    }}
                  >
                    The Data Layer for your Decentralized Apps
                  </div>
                  {/* <Header>The Data Layer for your Decentralized Apps</Header> */}
                  <div style={{ width: "100%" }}>
                    <DemoVideoPanel />
                  </div>
                </Grid.Column>
                {/* <Grid.Column width="7" style={{ background: '#FBFBFB', padding: '4rem' }}>
									<Image src={DLTLogos} className="dlt-logos" />
								</Grid.Column> */}
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column
                  width="16"
                  style={{ background: "#FBFBFB", padding: "1rem 4rem" }}
                >
                  <div style={{ display: "flex", alignItems: "spaceBetween" }}>
                    <div style={{ flexGrow: 1, margin: "auto 0" }}>
                      <img width="80%" src="/logo_hedera.png" alt="" />
                    </div>
                    <div style={{ flexGrow: 1, margin: "auto 0" }}>
                      <img width="70%" src="/logo_ethereum.png" alt="" />
                    </div>
                    <div style={{ flexGrow: 1, margin: "auto 0" }}>
                      <img width="70%" src="/logo_eos.png" alt="" />
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>

              {/* ----------------------------------------------------------------------------- */}

              {/* <Grid.Row style={{ background: '#FBFBFB', paddingTop: '4rem' }} columns="1">
								<Grid.Column textAlign="center">
									<h3 className="section-subheading">
										<span className="dragon">Dragon</span>
										<span className="glass">Glass</span> is a cloud-based service that provides live
										and historical data across all distributed ledgers
									</h3>
								</Grid.Column>
								<Grid.Column>
									<Grid columns="equal" stackable>
										<Grid.Column>
											<div className="service-details">
												<div>
													DApp Developers, Crypto account holders, Exchanges … need a simple,
													fast and cost- effective method to access data from distributed
													ledgers.
												</div>
												<Image src={IconGears} />
											</div>
										</Grid.Column>
										<Grid.Column>
											<Image src={HederaApp} />
										</Grid.Column>
										<Grid.Column>
											<div className="service-details">
												<Image src={IconHistorical} />
												<div>
													DApp Developers, Crypto account holders, Exchanges … need a simple,
													fast and cost- effective method to access data from distributed
													ledgers.
												</div>
											</div>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row> */}

              <Grid.Row className="features" style={{ paddingTop: "3rem" }}>
                <Grid padded stackable>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <h2 className="section-heading">FEATURES</h2>
                      <div className="section-heading-underline">
                        <hr style={{ width: "25%" }} />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="4">
                    <Grid.Column className="feature-item">
                      <div className="feature-caption">
                        REAL-TIME DATA SUBSCRIPTIONS
                      </div>
                      <p>
                        Access live transactions and events via real-time data
                        feeds using Amazon SQS
                      </p>
                    </Grid.Column>
                    <Grid.Column className="feature-item feature-item-alt">
                      <div className="feature-caption">
                        MULTI-LEVEL DATA ENRICHMENT
                      </div>
                      <p>
                        Data enriched from public sources with additional
                        capability for custom enrichment
                      </p>
                    </Grid.Column>
                    <Grid.Column className="feature-item">
                      <div className="feature-caption">
                        SUPPORT FOR CUSTOM SMART CONTRACTS
                      </div>
                      <p>
                        Link ABI's to decode smart contract calls and events
                      </p>
                    </Grid.Column>
                    <Grid.Column className="feature-item feature-item-alt">
                      <div className="feature-caption">
                        REST-BASED SEARCH AND DATA API
                      </div>
                      <p>
                        All historical data available via a high performance
                        REST API
                      </p>
                    </Grid.Column>
                    <Grid.Column className="feature-item feature-item-alt">
                      <div className="feature-caption">
                        SINGLE DATA SOURCE ACROSS MULTIPLE LEDGERS
                      </div>
                      <p>
                        A common interface to access data from across all
                        popular public distributed ledgers
                      </p>
                    </Grid.Column>
                    <Grid.Column className="feature-item">
                      <div className="feature-caption">
                        SUPPORT FOR PRIVATE BLOCKCHAINS
                      </div>
                      <p>
                        Bring in data from private and Permissioned Blockchains
                      </p>
                    </Grid.Column>
                    <Grid.Column className="feature-item feature-item-alt">
                      <div className="feature-caption">
                        MANAGED SERVICES FOR ENTERPRISE CLIENTS
                      </div>
                      <p>
                        Option to use secure, private instances with
                        enterprise-grade SLAs
                      </p>
                    </Grid.Column>
                    <Grid.Column className="feature-item">
                      <div className="feature-caption">
                        HIGHLY SCALABLE ARCHITECTURE
                      </div>
                      <p>
                        DragonGlass provides data explorer to use by crypto
                        traders, developers, end users, etc.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Row>

              <Grid.Row columns="1" style={{ paddingTop: "3rem" }}>
                <Grid padded stackable>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <h2 className="section-heading">DEVELOPER APIs</h2>
                      <div className="section-heading-underline">
                        <hr style={{ width: "35%" }} />
                      </div>
                      <h3 className="section-subheading text-muted">
                        A comprehensive set of APIs for access to live and
                        historical data.
                      </h3>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal" centered>
                    <Grid.Column className="feature-column" width={4}>
                      <Image src={IconLiveFeeds} />
                      <div className="feature-caption">LIVE FEEDS</div>
                      <ul className="text-muted">
                        <li>Available via Amazon SQS</li>
                        <li>
                          Filter data based on contracts or transaction
                          participants
                        </li>
                        <li>In-stream enrichment and mapping</li>
                      </ul>
                    </Grid.Column>
                    <Grid.Column className="feature-column" width={4}>
                      <Image src={IconHistoricalData} />
                      <div className="feature-caption">HISTORICAL DATA</div>
                      <ul className="text-muted">
                        <li>Accessible via REST API</li>
                        <li>Powerful search and aggregation capabilities</li>
                        <li>Easy integration with enterprise applications</li>
                      </ul>
                    </Grid.Column>
                    {/* <Grid.Column className="feature-column">
											<Image src={IconBulkData} />
											<div className="feature-caption">BULK DATA</div>
											<ul className="text-muted">
												<li>Accessible via asynchronous interface</li>
												<li>All historical data available for analysis</li>
												<li>No download size limits</li>
											</ul>
										</Grid.Column> */}
                  </Grid.Row>
                  <Grid.Row centered style={{ marginBottom: "25px" }}>
                    <Grid.Column textAlign="center">
                      <Link to="/subscriptionview">
                        <Button primary>SEE THE DOCS</Button>
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Row>

              {/* <Grid.Row columns="1" style={{ background: '#1B97AE', paddingTop: '3rem' }}>
								<Grid padded stackable>
									<Grid.Row>
										<Grid.Column textAlign="center">
											<h2 className="section-heading text-white">Dapp Developers Need</h2>
											<div className="section-heading-underline text-white">
												<hr style={{ width: '45%' }} />
											</div>
											<h3 className="section-subheading text-white">
												As Blockchain technology gains adoption in real-world business
												applications, efficient access to blockchain data has become critical
											</h3>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row columns="equal" centered>
										<Grid.Column width="7" className="card">
											<div className="market-desc">
												<h4>
													Blockchain data is difficult to access and consume, and has limited
													value without enrichment
												</h4>
												<p>
													Beyond application-specific needs, blockchain data is required for a
													variety of use cases including analytics, AI/ML, regulatory
													reporting and taxation.
												</p>
											</div>
										</Grid.Column>
										<Grid.Column width="7">
											<div className="market-desc">
												<h4>
													Current approaches for accessing blockchain data are inefficient,
													expensive and cumbersome
												</h4>
												<p>
													Requires operating nodes to listen to each network, and with slow
													native access, historical data must be indexed and stored of-chain.
													Data formats are also obscure and constantly changing.
												</p>
											</div>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Row> */}

              {/* <Grid.Row columns="1" style={{ background: '#EBF6F8', paddingTop: '3rem' }}>
								<Grid padded stackable>
									<Grid.Row>
										<Grid.Column textAlign="center">
											<h2 className="section-heading">Simplified Architecture for Dapps</h2>
											<h3 className="section-subheading ">
												Simplify your Dapp architecture stack by leveraging{' '}
												<span className="dragon">Dragon</span>
												<span className="glass">Glass</span> for access, enrichment and storage
												of all blockchain data.
											</h3>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row columns="equal" centered>
										<Grid.Column width="7" verticalAlign="middle">
											<Image src={EA} />
										</Grid.Column>
										<Grid.Column width="2" verticalAlign="middle" textAlign="center">
											<Image src={TArrow} className="t-arrow" />
										</Grid.Column>
										<Grid.Column width="7" verticalAlign="middle">
											<Image src={DGA} />
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Row> */}

              <Grid.Row
                columns="1"
                style={{
                  paddingTop: "3rem",
                  height: "300px",
                  background: "#D8D8D8",
                }}
                verticalAlign="middle"
              >
                <Grid padded columns="1" textAlign="center">
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <h2 className="section-heading">
                        Request Access to <span className="dragon">DRAGON</span>
                        <span className="glass">GLASS</span> Beta
                      </h2>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <Grid.Column textAlign="center">
                      <Link to="/contact-us">
                        <Button primary>GET STARTED</Button>
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Row>
            </Grid>
          </Panel>
        </Grid.Column>
      </Grid>
    </Page>
  );
}
