import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import TopicRow from './TopicRow';
import TopicTableHeader from './TopicTableHeader';

const TopicTable = ({ topics = [], limit, offset, sort, order, totalCount, loadData }) => {
	const TopicRows = topics.map((topic, index) => (<TopicRow key={index} topic={topic} />));
	return (
		<DataTable
			limit={limit}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={TopicTableHeader}
			rows={TopicRows}
			loadData={loadData}
		/>
	);
};

export default TopicTable;

TopicTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired
};
