import React, { Component } from 'react';
import Panel from '../../atoms/Panel';
import TxnTypeChart from './charts/TxnTypeChart';
import PeriodSelector from '../../components/shared/PeriodSelector';
import Info from '../../components/shared/Info';
import SourceInWidget from '../../components/SourceInWidget';
import { parseQueryParams } from  '../../utils';
import { withRouter } from 'react-router-dom';
import Share from '../../components/shared/Share';
import { Icon } from 'semantic-ui-react';


export class TxnTypeChartPanel extends Component {
	state = {
		period: '1D',
		showLegend: false,
		customHeight: 217
	};

	componentDidMount() {
		const params = parseQueryParams(this.props.location.search);
		if(params && params.period) {
			this.setState({
					period: params.period,
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.setState({ period: this.props.period });
		}
	}

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
		//to do
		//update price and percent change
	};

	onShowLegend = () => {
		this.setState({ showLegend: !this.state.showLegend, customHeight: this.state.showLegend ? 217 : 300 });
	};

	render() {
		const { period, showLegend } = this.state;
		const buttonIcon = showLegend ? <Icon name="caret up" /> : <Icon name="caret down" />
		let url = `/chart?name=txn-service-type&period=${this.state.period}`;
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						TRANSACTION SERVICE TYPES <Info id="txTypes" />
						<SourceInWidget />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				footer={
					<>
						{
							!this.props.isLegend ?
							<p className='clickable' onClick={() => this.onShowLegend()} style={{position: 'absolute'}}>{this.state.showLegend ? 'Hide' : 'Show'} Legend
								<span style={{marginLeft: '10px'}}>{buttonIcon}</span>
							</p>
							: ''
						}
						<Share url={url} params={params} title={"Dragon Glass Transaction Type Chart"}/>
					</>}
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				<div><TxnTypeChart period={period} isLegend={this.props.isLegend || this.state.showLegend}
				customHeight={this.props.isLegend ? this.props.customHeight : this.state.customHeight}
				//  customHeight={this.props.customHeight}
				 /></div>
			</Panel>
		);
	}
}

export default withRouter(TxnTypeChartPanel);
