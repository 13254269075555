import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../redux/actions';

import { getTopic, getTopics, getTopicMessages } from '../../redux/reducers';

import TopicSummaryPanel from '../TopicSummaryPanel';
import TopicMessagesPanel from '../TopicMessagesPanel';

// import ContractTransfersPanel from '../ContractTransfersPanel';
// import ContractCodePanel from '../ContractCodePanel';

// import ContractBalanceChartPanel from '../ContractBalanceChartPanel';
// import ContractTxnVolumeChartPanel from '../ContractTxnVolumeChartPanel';
// import ContractUsersChartPanel from '../ContractUsersChartPanel';

import Page from '../shared/Page';
import Search from '../containers/Search';
import AccountLookup from '../containers/AccountLookup';

const Topic = (props) => {

	useEffect(() => { fetchData() }, []);
	useEffect(() => { window.scrollTo(0, 0) }, []);

	const fetchData = () => {
		props.fetchTopic(props.id)
	}

	console.log("PROPS IN TOPIC PAGE: ", props.topic);

	return (
		<Page>
			<Grid padded stackable centered>
				<Grid.Column computer={14} only="tablet computer">
					<Search />
				</Grid.Column>
				{/* <Grid.Column computer={5} tablet={16} mobile={16} className="no-padding-mobile">
					<AccountLookup />
				</Grid.Column> */}
				<Grid.Column computer={14} tablet={16} mobile={16}>
					<Grid columns={1}>
						<Grid.Column>
							<TopicSummaryPanel topic={props.topic} />
						</Grid.Column>
						<Grid.Column>
							<TopicMessagesPanel topic={props.topic} />
						</Grid.Column>
						{/* <Grid.Column>
							<ContractTransfersPanel />
						</Grid.Column>
						<Grid.Column>
							<ContractCodePanel />
						</Grid.Column> */}
					</Grid>
				</Grid.Column>
				{/* <Grid.Column computer={5} tablet={16} mobile={16}>
					<Grid columns={1}>
						<Grid.Column>
							<ContractBalanceChartPanel />
						</Grid.Column>
						<Grid.Column>
							<ContractTxnVolumeChartPanel />
						</Grid.Column>
						<Grid.Column>
							<ContractUsersChartPanel />
						</Grid.Column>
						<Grid.Column only="mobile" className="no-padding-mobile">
							<Search />
						</Grid.Column>
					</Grid>
				</Grid.Column> */}
			</Grid>
		</Page>
	);
}

const mapStateToProps = (state, { match: { params: { topicID } } }) => {
	const topic = getTopic(state, topicID);
	return ({
		topic,
		id: topicID,
		topicMessages: topic ? getTopicMessages(state, topic.topicID) : [],
	})
};

export default withRouter(connect(mapStateToProps, actions)(Topic));