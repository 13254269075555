import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getNftsDetail, getNftsHistoricalDetail, getNftsHistoricalDetailCount } from '../redux/reducers';
import { getTokenDetails } from "../redux/reducers/hts";
import { Header } from "semantic-ui-react";
import NftHistoricalTable from '../components/NftHistoricalTable';
import { parseQueryParams } from  '../utils';

const NFTDetailsTabPanel = (props) => {

	const [limit, setLimit] = useState(5);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState('');
	const [order, setOrder] = useState('desc');
	const [page, setPage] = useState(1);
	const [loadingText, setLoadingText] = useState("Loading...");

	useEffect(() => { 
		fetchData();
	}, [])

	useEffect(() => { 
		setTimeout(() => {
			setLoadingText("No NFTs found.")
		}, 3000);
	}, [])

	const fetchData = () => {
		const { fetchNftsHistoricalDetails, tokenId } = props;
		const params = parseQueryParams(props.location.search);
		const nftHistoryParams = {"serialNumber": params.serialNumber, "tokenId": tokenId, limit, offset, sort, order, page };
		fetchNftsHistoricalDetails(nftHistoryParams);
	}
	const loadData = (viewParams) => {
		 fetchData();
	};
	const { nftHistoricalData, totalCount } = props;
	return (
		<React.Fragment>
			{
				props.nftsDetail && props.nftsDetail.token && props.nftsDetail.token == props.tokenId ?
				<>
					<Header as="h5">HISTORICAL TRANSFERS</Header>
					<div id="nfts-transactions-table">
						<NftHistoricalTable
							nftHistoricalData={nftHistoricalData}
							limit={limit}
							offset={offset}
							sort={sort}
							order={order}
							totalCount={totalCount}
							loadData={loadData}
						/>
					</div>
				</>
				: <div className='bold align-center font20'>{loadingText}</div>
			}
		</React.Fragment>
		)
}
const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
	tokenId: tokenId,
	nftsDetail : getNftsDetail(state),
	nftHistoricalData : getNftsHistoricalDetail(state),
	totalCount: getNftsHistoricalDetailCount(state),
	token: getTokenDetails(state),
});

export default connect(mapStateToProps, actions)(NFTDetailsTabPanel);
