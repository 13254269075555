import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Container, Grid, Icon } from 'semantic-ui-react';
import DisplayContent from './DisplayContent';
import { useHistory } from "react-router";
import FwdPrePagination from './FwdPrePagination';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { isMobile } from '../utils';
import LoadingIndicator from '../components/containers/LoadingIndicator';
import Shimmer from '../atoms/Shimmer';

const Collections = (props) => {
	
	const history = useHistory();
	const [notFoundText, setNotFoundText] = useState("Loading...");
	const noNft = 'No NFTs found';
	const { collections } = props;

	useEffect(() => {
		if (!props.collections.length) {
			setTimeout(() => { setNotFoundText('No NFTs found')}, 3000);
		}
	}, [props.collections.length]);

    useEffect(() => { window.scrollTo(0, 0);}, []);

	const thumbnailWidthHeight = () => {
		const width = window.innerWidth;
		if (width >= 750 && width < 1200) {
			return {width: 300, height: 300};
		}
		if (width >= 1660) {
			return {width: 600, height: 600};
		}
		return {width: 380, height: 380};
	}
	
	return (
		<Container fluid>
					{
						collections && collections.length ?
							collections.map((collection, index) => {
							return (
								<React.Fragment key={'collections_'+index}>
									{
										collection ?
										<>
											<Grid stackable style={{background: 'white', margin: '10px', marginBottom: '3%'}}>
												<Grid.Row style={{borderBottom: '2px solid', paddingBottom: '0px' }}>
													<Grid.Column>
														<div style={{display: isMobile() ? 'block' : 'flex', justifyContent: 'space-between'}}>
														<h3> <span style={{color: "#627a93"}}>Collection: </span> <Link onClick={() => props.handleDetailsClick(collection.id)}>{collection.name}</Link></h3>
														<div className='bold' onClick={() => props.handleDetailsClick(collection.id)}>
															<span className="clickable" style={{color: '#1b97ae'}}>
															See More <Icon name="arrow right" />
															</span>
															</div>
														</div>
													</Grid.Column>
												</Grid.Row>
												<Grid.Row>
													<Grid.Column>
														<Grid centered stackable>
															<Grid.Row only="tablet computer mobile widescreen" style={{justifyContent: 'initial', padding: '15px'}} columns={4}>
																{
																	collection && collection.nfts && collection.nfts.length ?
																	collection.nfts.map((nft, nftIndex) => {
																		return (
																			<Grid.Column key={'nftCollection_'+nftIndex}>
																				<Container className="clickable" onClick={() => props.handleInternalDetailsClick(nft.token, nft.serialNumber)} 
																				style={{ position: 'relative',
																					paddingBottom: '100%', /* Set the aspect ratio here, e.g., 60% for 16:9 */
																					overflow: 'hidden',
																					border: '1px solid #ccc', borderRadius: '10px'
																				}}
																				fluid>
																					<DisplayContent mimeType={nft.dataType} 
																					src={nft.imageUrl}
																					// isThumbnail={true}
																					// thumbnailheight={thumbnailWidthHeight().height}
																					// thumbnailwidth={thumbnailWidthHeight().width}
																					styles={{position: 'absolute',
																						top: 0,
																						left: 0,
																						width: '100%',
																						height: '100%',
																						objectFit: 'cover',
																						borderTopLeftRadius: '8px', borderTopRightRadius: '8px',
																					}}
																					size={'xl'} alt="NFT" />
																					<div 
																					style={{ 
																						padding: '1rem',
																						backgroundColor: 'white',
																						borderTop: '1px solid #ccc',
																						color: 'black',
																						whiteSpace: 'nowrap',
																						overflow: 'hidden',
																						textOverflow: 'ellipsis',
																						width: '100%',
																						position: 'absolute',
																						top: 'calc(100% - 4rem)',
																					}}
																					>
																						<Grid stackable>
																							<Grid.Row style={{paddingBottom: '0px'}}>
																								<Grid.Column width={ nft.marketplaceLink ? 7 : 8} 
																								className="ellipsis">{nft.name}&nbsp;</Grid.Column>
																								{
																									nft.marketplaceLink
																									?
																									(
																										<Grid.Column width={9} className="align-right ellipsis"><a href={nft.marketplaceLink} target='_blank'>Listed At {nft.marketplaceLink}</a></Grid.Column>
																									)
																									: <Grid.Column width={8} className="align-right ellipsis"><Link to={`/nft-marketplace?token=${nft.token}&nftOwner=${nft.owner}&serial=${nft.serialNumber}`}>Add NFT Marketplace</Link> </Grid.Column>
																								}
																							</Grid.Row>
																							<Grid.Row column={2} style={{paddingTop: '0px'}}>
																								<Grid.Column width={8}>{nft.token}</Grid.Column>
																								<Grid.Column width={8} style={{textAlign: 'right'}}>#{nft.serialNumber}</Grid.Column>
																							</Grid.Row>
																						</Grid>
																					</div>
																				</Container>
																			</Grid.Column>
																		)
																	})
																	:	<>
																			<Grid.Column colSpan={4} textAlign="left">
																				{noNft}
																			</Grid.Column>
																		</>
																}
															</Grid.Row>
														</Grid>
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</>
										: null
									}
								</React.Fragment>
							)
						})
						: null
					}
					{
						props.isLoading ?
						<Grid.Column colSpan={4} textAlign="center">
							{/* <LoadingIndicator /> */}
							{/* Shimmer effect placeholder */}
							<Shimmer />
						</Grid.Column>
						: !(collections && collections.length) && (
							<Grid.Column colSpan={4} textAlign="center">
								{notFoundText}
							</Grid.Column>
						)
					}
					<Grid centered stackable>
						<Grid.Row>
							<FwdPrePagination onLoadMore={props.onLoadMore} limit={props.limit || 10} totalCount={props.totalCount || 0}/>
						</Grid.Row>
					</Grid>
			</Container>
	);
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, actions)(Collections);