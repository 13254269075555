import {
  FETCH_TOP_TEN_TOKEN_VOLUME_SUCCESS,
  FETCH_TOP_TEN_TOKEN_VOLUME_FAILURE
} from '../types';

const initialState = {};

export default function topTenTokensByVolume(state = initialState, action) {
	switch (action.type) {
		case FETCH_TOP_TEN_TOKEN_VOLUME_SUCCESS:
			return {
				...state,
				topTokensByVolume: action.data
			};
		case FETCH_TOP_TEN_TOKEN_VOLUME_FAILURE:
			return {
				...state,
				topTokensByVolume: []
			};
		default:
			return state;
	}
}

export const getTopTokensByVolume = (state) => state.topTenTokensByVolume && state.topTenTokensByVolume.topTokensByVolume ? state.topTenTokensByVolume.topTokensByVolume.data : [];

