import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getActiveCollectionsData } from '../redux/reducers';
import * as actions from '../redux/actions';
import { removeEmpty } from '../utils';
import Collections from '../atoms/Collections';
import { useHistory } from "react-router";
import LoadingIndicator from "../components/containers/LoadingIndicator";

const ActiveCollections = (props) => {
	
	const history = useHistory();
	const [limit, setLimit] = useState(5);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState('createdOn');
	const [order, setOrder] = useState('desc');
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	
	const { activeCollection } = props;
	const activeCollections = activeCollection && activeCollection.activeCollections && activeCollection.activeCollections || [];
	const totalCount = activeCollection && activeCollection.totalCount || 0;
	// useEffect(() => { window.scrollTo(0, 0); fetchData(); }, []);
    useEffect(() => { fetchData() }, [limit, page]);

	useEffect(() => {
		setIsLoading(props.activeCollection.isLoading)
	}, [props.activeCollection.isLoading]);
	
	const fetchData = () => {
		 const queryParams = {
			offset,
			limit,
			page,
			sort,
			order,
		 }
		const { fetchActiveCollection, searchParams } = props;
		const params = { ...searchParams, ...queryParams};
		const queryParamsF = removeEmpty(params);
		fetchActiveCollection(queryParamsF)
	};

	const onLoadMore = (params) => {
		setIsLoading(true);
		setOffset(params.offset);
		setPage(params.page)
		setLimit(limit);
	}

    const handleDetailsClick = (token) => {
        history.push(`/tokens/${token}`);
    }

	const handleInternalDetailsClick = (token, serialNumber) => {
        history.push(`/nft/${token}?serialNumber=${serialNumber}`);
    }

	return <Collections
	isLoading={isLoading}
	collections={activeCollections} 
	handleDetailsClick={handleDetailsClick} 
	handleInternalDetailsClick={handleInternalDetailsClick}
	totalCount={totalCount} limit={limit}
	onLoadMore={onLoadMore}
	/>
}

const mapStateToProps = (state) => ({
	activeCollection: getActiveCollectionsData(state),
});

export default connect(mapStateToProps, actions)(ActiveCollections);