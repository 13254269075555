import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';

const NumberFormater = (number) => {
	if (number > 1000000000) {
		return (number / 1000000000).toString() + 'B';
	} else if (number > 1000000) {
		return (number / 1000000).toString() + 'M';
	} else if (number > 1000) {
		return (number / 1000).toString() + 'K';
	} else {
		return number.toString();
	}
};

export default ({ data = [], dataKey = 'value', tickFormatter, labelFormatter, valueFormatter }) => {
	return (
		<ResponsiveContainer width="100%" height={200}>
			<BarChart data={data}>
				<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter} />
				<YAxis tickFormatter={NumberFormater} width={48} />
				<Tooltip
					labelFormatter={labelFormatter}
					formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
				/>
				<Bar dataKey={dataKey} fill="#8884d8" />
			</BarChart>
		</ResponsiveContainer>
	);
};
