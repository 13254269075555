import React from 'react';
import { Grid, Form, Checkbox } from 'semantic-ui-react';
import * as constants from '../constants';
import Panel from './shared/Panel';
import { formatNumber } from '../utils';
import Info from "./shared/Info";

export default function SearchFacets({ filters, onFilterToggle, isComingFrom }) {
	function getTooltip(type) {
		let tooltipType = type;
		if (isComingFrom == 'accounts') {
			if (type === 'payerID') {
				tooltipType = 'accountsPayerID';	
			}
		} else if (isComingFrom == 'transactions') {
			if (type === 'payerID') {
				tooltipType = 'transactionsPayerID';	
			}
		} else if (isComingFrom == 'contracts') {
			if (type === 'payerID') {
				tooltipType = 'contractsPayerID';	
			}
		} else if (isComingFrom == 'topics') {
			if (type === 'Created_By') {
				tooltipType = 'topicsPayerID';	
			}
		}
		return tooltipType;
	}

	function getFacetsHeaderType(type) {
		let facetsType = type;
		if (isComingFrom == 'accounts') {
			if (type === 'payerID') {
				facetsType = 'accountsPayerID';	
			}
		} else if (isComingFrom == 'transactions') {
			if (type === 'payerID') {
				facetsType = 'transactionsPayerID';	
			}
		} else if (isComingFrom == 'contracts') {
			if (type === 'payerID') {
				facetsType = 'contractsPayerID';	
			}
		} else if (isComingFrom == 'topics') {
			if (type === 'Created_By') {
				facetsType = 'topicsPayerID';	
			}
		}
		return facetsType;
	}
	return (
		<Grid>
			{Object.keys(filters).map((type) => {
				return (
					constants[type] && (
						<Grid.Row key={type}>
							<Grid.Column>
								<Panel title={
									<React.Fragment>
									{constants[getFacetsHeaderType(type)]} <Info id={getTooltip(type)} />
								</React.Fragment>
								} titleClass="light" description=""
								>
									<Form>
										{Object.keys(filters[type]).map((f) => (
											<Form.Field key={f}>
												<Checkbox
													className='checkbox-filter-facets'
													label={`${constants[f] || f} (${formatNumber(filters[type][f])})`}
													name={f}
													category={type}
													onChange={onFilterToggle}
												/>
											</Form.Field>
										))}
									</Form>
								</Panel>
							</Grid.Column>
						</Grid.Row>
					)
				);
			})}
		</Grid>
	);
}
