import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Stat from '../../../components/shared/Stat';
import LineChart from '../../../atoms/LineChart';
import * as actions from '../../../redux/actions';
import { getTransactionsTypeDimensionCharts } from '../../../redux/reducers';
import { formatTimestamp } from '../../../utils';
import LoadingIndicator from "../../../components/containers/LoadingIndicator";

class TxnTypeDimensionChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchTransactionTypeDimension, period } = this.props;
		fetchTransactionTypeDimension({period});
	}
	render() {
		const { volume: { totalSum, /* totalCount,*/ pctChange, data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [name + ': ' + new Intl.NumberFormat('en').format(value) +' txns'];
		if (!this.props.volume.loading) {
			return (
				data && data.length ?
					<Fragment>
						{/* <Stat count={totalSum} pct={pctChange} label="txns" /> */}
						<br />
						<LineChart
							graphType={this.props.graphType}
							data={data}
							tickFormatter={formatter}
							labelFormatter={formatter}
							valueFormatter={valueFormatter}
							multiple={true}
							isLegend = {this.props.isLegend}
							customHeight={this.props.customHeight}
							isNoTooltip = {this.props.isNoTooltip}
							showLegend = {this.props.showLegend}
						/>
					</Fragment>
				:  <div>No transactions available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state) => ({
	volume: getTransactionsTypeDimensionCharts(state)
});

TxnTypeDimensionChart = connect(mapStateToProps, actions)(TxnTypeDimensionChart);

export default TxnTypeDimensionChart;
