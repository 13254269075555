import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import NftsDetailsPanel from '../NftsDetailsPanel';
import Page from '../shared/Page';

export default () => {
	const [ tokenNameData, setTokenNameData ] = useState({});
	const tokenName = (nameData) => {
		setTokenNameData(nameData);
	}

return (
	<Page>
		<Grid padded stackable>
			<Grid.Row>
				<Grid columns={1}>
					<Grid.Column style={{paddingTop: '0', paddingBottom: '0'}}>
						<div className="title-image-block title-panel-header"
							style={{ display: "flex", height: "auto"}}>
							{tokenNameData && tokenNameData.thumbnail && (
								<div>
										<img
												style={{ height: 40, width: 40, marginLeft: 14, marginTop: 10 }}
												src={tokenNameData.thumbnail}
										/>
								</div>
							)}
							{tokenNameData && (
								<div className="token-name-container" style={{ marginLeft: 10, marginTop: 20, display: "flex" }}>
									<div className="token-label-overview" style={{ fontSize: 17, fontWeight: 500, marginLeft: 4 }}>
											NFT Token
									</div>
									<div
											style={{
													color: "gray",
													marginLeft: 5,
													fontWeight: 600,
													marginTop: 1,
													wordBreak: "break-word",
													paddingRight: "1rem",
													paddingBottom: "1rem",
											}}>
											{tokenNameData.name ? tokenNameData.name : ""}
									</div>
								</div>
							)}
						</div>
					</Grid.Column>
				</Grid>
			</Grid.Row>



			<Grid.Row>
				<Grid columns={1}>
					<Grid.Column>
						<NftsDetailsPanel tokenName={tokenName}/>
					</Grid.Column>
				</Grid>
			</Grid.Row>
		</Grid>
	</Page>
);
	}

