import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Radio } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as actions from '../redux/actions';
import { getParameters } from '../redux/reducers';
import ApiParameterTable from './ApiParameterTable';
import ApiCopyUrl from './ApiCopyUrl';

import '../animate.css';

const APISingleDisplay = (props) => {

    const handleCopyClick = () => {
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000)
    }

    const audio = new Audio('/click.mp3');

    const [ clicked, setClicked ] = useState(false);

    const [showParameters, setShowParameters] = useState(false);

    const handleOnClick = (e, {checked}) => {
        setShowParameters(checked);
    } 

    // const buttonText = showParameters ? 'Hide parameters' : 'Show parameters';
    // const buttonIcon = showParameters ? <Icon name="arrow up" /> : <Icon name="arrow down" />
    const toggleLabel = showParameters ? 'HIDE PARAMETERS' : 'SHOW PARAMETERS';
    const parametersFiltered = props.parameters.filter(parameter => parameter.api === props.apiId);

    return (
        <div className="animated fadeIn">
            <div className="shortDescription"><strong>{ props.shortDescription }</strong></div>
            <div className="longDescription">{ props.longDescription }</div>
            <div className="urlBox">
                <a href={props.sample_url} target="_blank" rel="noopener noreferrer">{ props.url }</a>
                <CopyToClipboard text={props.url}
                    onCopy={() => handleCopyClick({copied: true})}>
                    <span><ApiCopyUrl clicked={clicked} /></span>
                </CopyToClipboard>
            </div>
            {parametersFiltered.length > 0 && <Radio toggle label={toggleLabel} className="header parameters-toggle" checked={showParameters} onChange={handleOnClick} />}
            {/* {parametersFiltered.length > 0 && <div className="parameters-button"><Button primary onClick={handleOnClick}>{buttonIcon}{buttonText}</Button></div>} */}
            {parametersFiltered.length > 0 && showParameters && <div className="parameter-table animated fadeIn"><ApiParameterTable parameters={parametersFiltered} /></div>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    parameters: getParameters(state)
  });

export default connect(mapStateToProps, actions)(APISingleDisplay);
