import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Stat from '../shared/Stat';
import LineChart from '../shared/LineChart';
import * as actions from '../../redux/actions';
import { getTransactionValues } from '../../redux/reducers';
import { formatTimestamp } from '../../utils';
import LoadingIndicator from "./LoadingIndicator";

class TxnValueChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchTransactionValues, period } = this.props;
		fetchTransactionValues(period);
	}
	render() {
		const { values: { totalSum, /* totalCount,*/ pctChange, data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [ new Intl.NumberFormat('en').format(value) + ' ℏ' ];
		if (!this.props.values.loading) {
			return (
				data && data.length ?
					<Fragment>
						<Stat count={totalSum} pct={pctChange} money currency={'hbar'} />
						<br />
						<LineChart
							graphType={this.props.graphType}
							data={data}
							tickFormatter={formatter}
							labelFormatter={formatter}
							valueFormatter={valueFormatter}
						/>
					</Fragment>
				:  <div>No transactions available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state) => ({
	values: getTransactionValues(state)
});

TxnValueChart = connect(mapStateToProps, actions)(TxnValueChart);

export default TxnValueChart;
