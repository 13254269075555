import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Stat from '../shared/Stat';
import LineChart from '../shared/LineChart';
import * as actions from '../../redux/actions';
import { getContractTransactionVolume, getDapp, getContractTransactionVolumeLoader } from '../../redux/reducers';
import { formatTimestamp, parseQueryParams } from '../../utils';
import LoadingIndicator from "./LoadingIndicator";

class DappContractTxnVolumeChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
		if (this.props.dapp !== prevProps.dapp) {
			this.fetchData()
		}
	}

	fetchData() {
		const {dapp}= this.props;
		if (!dapp || !dapp.accountId) return;
		const { fetchContractTransactionVolume, period } = this.props;
		fetchContractTransactionVolume(dapp.accountId, period);
		this.props.sendDappIdparam(dapp.accountId);
  }

	render() {
		const { volume: { totalSum, /* totalCount,*/ pctChange, data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [ new Intl.NumberFormat('en').format(value) + ' txns' ];
		if (!this.props.loading) {
			return (
				data && data.length ?
					<Fragment>
						<Stat count={totalSum} pct={pctChange} label="txns" />
						<br />
						<LineChart
							data={data}
							tickFormatter={formatter}
							labelFormatter={formatter}
							valueFormatter={valueFormatter}
						/>
					</Fragment>
				:  <div>No transactions available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state, { match: { params: { dappId } } }) => {
		const params = parseQueryParams(window.location.search);
    const dapp = getDapp(state, dappId ? dappId : params.dappId);
    return ({
        dapp,
        dappId: dappId ? dappId : params.dappId,
        volume: dapp ? getContractTransactionVolume(state, dapp.accountId) : {},
				loading: dapp ? getContractTransactionVolumeLoader(state, dapp.accountId) : {},
    })
};

DappContractTxnVolumeChart = withRouter(connect(mapStateToProps, actions)(DappContractTxnVolumeChart));

export default DappContractTxnVolumeChart;
