import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Button, Icon, Modal, Header, Message } from 'semantic-ui-react';
import Panel from './shared/Panel';
import ApiKeyTable from './ApiKeyTable';
import Info from './shared/Info';
import { getKeys } from '../redux/reducers';


import { CSVLink } from "react-csv";

const ApiKeyPanel = (props) => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchData() }, [])

    const fetchData = async () => {
        const keys = await props.fetchApiKey();
      }

    const [open, setOpen] = useState(false);
    const [key, setKey] = useState({});

    const handleOnClick = async () => {
        const key = await props.createApiKey();
        setKey(key);
        setOpen(true);
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const csvData = [
        {apiKey: key.key, accessKey: key.accessKey}
    ]

    return (
        <Panel
            title={
                <React.Fragment>
                    MY API KEYS <Info id="apiKeys" />
                    <Button
                        primary
                        className="api-button"
                        onClick={handleOnClick}
                        size={window.innerWidth < 992 ? "tiny" : "medium"}>
                        <Icon name="key" />
                        GENERATE API KEY
                    </Button>
                </React.Fragment>
            }
        >
            <ApiKeyTable />

			<Modal open={open} closeIcon onClose={handleCancel} dimmer="blurring">
				<Modal.Header className="modal-header">Your Access Key</Modal.Header>
				<Modal.Content>
                <Message positive>
                    <Message.Header>You have successfully created your Access Key</Message.Header>
                    <p>This is the only time your Access Key can be viewed or downloaded.
                        You cannot recover it later.
                        However, you can create new access keys at any time.</p>
                </Message><br />
                <CSVLink filename="DGAccessKeys" data={csvData}><Button icon labelPosition='left'><Icon name='download' />Click to download your keys</Button></CSVLink>
				<br /><br /><br />
				<Modal.Description>
					<Header>Your Access Key:</Header>
					<p>{key.accessKey}</p>
					<Header>Your API Key:</Header>
					<p>{key.key}</p>
				</Modal.Description>
				</Modal.Content>
			</Modal>
        </Panel>
    )
}

const mapStateToProps = (state) => ({
	keys: getKeys(state),
});

export default connect(mapStateToProps, actions)(ApiKeyPanel);
