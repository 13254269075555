import React, { Component } from 'react';
import Panel from '../../components/shared/Panel';
import PeriodSelector from '../../components/shared/PeriodSelector';
import TokenTransferChart from './charts/TokenTransferChart';
import Info from '../../components/shared/Info';
import SourceInWidget from '../../components/SourceInWidget';
import { parseQueryParams } from  '../../utils';
import { withRouter } from 'react-router-dom';
import Share from '../../components/shared/Share';

export class NftTransferChartPanel extends Component {
	state = {
		period: "1D",
		icon: 'chart line'
	};

	componentDidMount() {
        const params = parseQueryParams(this.props.location.search);
        if(params && params.period) {
            this.setState({
                period: params.period,
                icon: params.chartType == 'bar' ? "chart bar" : "chart line",
            });
        }
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.setState({ period: this.props.period });
		}
	}

	onPeriodSelect = (e, { value }) => {
        this.setState({ period: value });
    };


	toggle = () => {
		if(this.state.icon === "chart line"){
			this.setState({icon:"chart bar"});
		}
		else{
			this.setState({icon:"chart line"});
		}
	}

	render() {
		const { icon, period } = this.state;
		let chartType = 'line';
		if  (icon == "chart bar") {
			chartType = 'bar';
		}
		let url = `/chart?name=nft-transfer&period=${period}&&chartType=${chartType}`;
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						NFT TRANSFERS <Info id="nftsTransfers" />
						<SourceInWidget />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				type="txnVolume"
				toggle={this.toggle}
				icon={icon}
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
				footer={<Share url={url} params={params} title={"Dragon Glass Transaction Volume Chart"}/>}
			>
				<TokenTransferChart period={period} graphType={icon} />
			</Panel>
		);
	}
}

export default withRouter(NftTransferChartPanel);