/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Input, Button } from 'semantic-ui-react';

export default function DappAutocomplete({ onSubmit, onSearchChange, ...rest }) {
	
	return (
		<Form /*onSubmit={onSubmit}*/>
			<Input
				fluid
				size="mini"
				action={
					<div className="ui input-button__wrapper">
						<Button id="dapp-advanced" primary content="ADVANCED" size="tiny" /*onClick={onSubmit}*/ />
					</div>
                }
				icon="search"
				// iconPosition="right"
				placeholder="SEARCH ACCOUNTS, TRANSACTIONS, CONTRACTS, OR FILES"
				onChange={onSearchChange}
				// {...rest}
			/>
		</Form>
	);
}
