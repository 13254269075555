import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import DateCell from './shared/DateCell';
import Money from './shared/Money';
import DappTransfers from './containers/DappTransfers';
import eye from '../assets/images/eye@2x.png';

import DappPopup from './DappPopup';

const color = (direction) => (direction.toLowerCase() === 'credit' ? 'green' : 'red');
const DappTransferRow = ({ transfer, showDetails }) => {
    return (
        <Table.Row>
            {/* <Table.Cell collapsing>
				<span className={`text-${color(transfer.transactionDirection)} bold`}>
					{transfer.transactionDirection.toUpperCase()}
				</span>
			</Table.Cell> */}
            <Table.Cell collapsing>
                <DateCell date={transfer.consensusTime} />
            </Table.Cell>
            <Table.Cell collapsing>
                {transfer.calledBy}
            </Table.Cell>
            <Table.Cell width="4" textAlign="left" collapsing>

                <div
                    style={{
                        width: '100%',
                        maxWidth: '100%',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word'
                    }}
                >
                    {`${transfer.functionParameters.slice(0, 20)}...`} &nbsp;
                    <DappPopup parameters={transfer.decodedFunctionParams} />
                </div>
            </Table.Cell>
            <Table.Cell textAlign="center" collapsing>
                <Link to={`/transactions/${transfer.transactionID}`}>
                    <Popup
                        content="View Transaction Details"
                        trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
                    />
                </Link>
            </Table.Cell>
        </Table.Row>
    );
};

export default DappTransferRow;

DappTransferRow.propTypes = {
    transfer: PropTypes.object.isRequired
};
