import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Table, Icon, Popup } from 'semantic-ui-react';
import * as actions from '../redux/actions';
import { Card } from '../atoms/Card';
import Money from '../atoms/Money';
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyTextToClipboard from "../components/CopyTextToClipboard";

const TransactionSummary = (props) => {

    const [clicked, setClicked] = useState(false);

	const { transaction } = props;
	const {
        id,
        contractID,
        fileID,
        transactionID,
        createdID,
		accountID,
        transactionHash,
        payerID,
        nodeID,
        transactionFee,
        nodeFees,
        networkFees,
        amount,
        startTime,
        consensusTime,
        transfers,
        typeLabel,
        functionParameters,
        gasOffered,
        gasUsed,
        raw,
        topicSequenceNumber,
        topicRunningHash,
        message,
        topicID,
        status,
        readableTransactionID,
        memo,
        tokenIDs,
        nfts,
        tokenAmount,
        tokenAccount,
        parentTransaction
    } = transaction;

	const audio = new Audio("/click.mp3");
	const handleCopyClick = () => {
        console.log("MESSAGE COPIED");
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000);
    };

	const renderMoreTransaction = () => {
		return (
			<Table basic="very" unstackable>
			<Table.Body>
				<Table.Row> 
					<Table.Cell>Memo</Table.Cell>
					<Table.Cell className="bold" style={{
						maxWidth: '150px',
						wordWrap: 'break-word',
						wordBreak: 'break-word'
					}}>{transaction && transaction.memo  || "None"}</Table.Cell>
				</Table.Row>
				<Table.Row> 
					<Table.Cell>Node</Table.Cell>
					<Table.Cell className="bold">{transaction && transaction.nodeID || "None"}</Table.Cell>
				</Table.Row>

				{typeLabel === "Submit Message" && (
					<>
					{
						topicID
						?	<Table.Row>
								<Table.Cell>Topic ID</Table.Cell>
								<Table.Cell className="bold" >
									<Link to={`/topics/${topicID}`}>{topicID}</Link>
								</Table.Cell>
							</Table.Row>
						:	<>
								<Table.Cell>Topic ID</Table.Cell>
								<Table.Cell>None</Table.Cell>
							</>
					}
					<Table.Row>
						<Table.Cell>Topic Sequence Number</Table.Cell>
						<Table.Cell className="bold">{topicSequenceNumber || "None"}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Topic Running Hash</Table.Cell>
						<Table.Cell style={{ overflowWrap: "anywhere" }}>{topicRunningHash || "None"}</Table.Cell>
					</Table.Row>
					{message ?
						<Table.Row>
							<Table.Cell>Message</Table.Cell>
							<Table.Cell style={{ overflowWrap: "anywhere" }}>
								{message.slice(0, 90)}...
								<CopyToClipboard text={message} onCopy={() => handleCopyClick({ copied: true })}>
									<span>
										<CopyTextToClipboard clicked={clicked} />
									</span>
								</CopyToClipboard>
							</Table.Cell>
						</Table.Row>
					:	<>
						<Table.Row>
							<Table.Cell>Message</Table.Cell>
							<Table.Cell>None</Table.Cell>
						</Table.Row>
						</>
					}
					</>
				)}

				{ ["Token Associate Account", "Token DisAssociate Account"].includes(typeLabel) ?
					<>
					{
						tokenAccount && tokenAccount ?
						<Table.Row>
							<Table.Cell>{typeLabel === "Token Associate Account" ? "Associated Account" : "Disassociated Account" }</Table.Cell>
							<Table.Cell className="bold">
								<Link to={`/accounts/${tokenAccount}`}>{tokenAccount}</Link>
							</Table.Cell>
						</Table.Row>
						:	<Table.Row>
								<Table.Cell>Associated Account</Table.Cell>
								<Table.Cell className="bold">None</Table.Cell>
							</Table.Row>
					}
					<Table.Row>
						<Table.Cell>{typeLabel === "Token Associate Account" ? "Associated Tokens" : "Disassociated Tokens" }</Table.Cell>
						<Table.Cell className="bold">
							{
								tokenIDs && tokenIDs.length ?
									tokenIDs.map((tokenID) => {
										return (
											<Link to={`/tokens/${tokenID}`}>{tokenID}</Link>
										)	
									})
								: "None"
							}
						</Table.Cell>
					</Table.Row>
					</>
					: ""
				}

				{ typeLabel === "Wipe Account Token" ?
					<>
					{
						tokenAccount && tokenAccount ?
						<Table.Row>
							<Table.Cell>Wiped Account</Table.Cell>
							<Table.Cell className="bold">
								<Link to={`/accounts/${tokenAccount}`}>{tokenAccount}</Link>
							</Table.Cell>
						</Table.Row>
						:	<Table.Row>
								<Table.Cell>Wiped Account</Table.Cell>
								<Table.Cell className="bold">None</Table.Cell>
							</Table.Row>
					}
					<Table.Row>
						<Table.Cell>Wiped Token Id</Table.Cell>
						<Table.Cell className="bold">
							{
								tokenIDs && tokenIDs.length ?
									tokenIDs.map((tokenID) => {
										return (
											<Link to={`/tokens/${tokenID}`}>{tokenID}</Link>
										)	
									})
								: "None"
							}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Wiped Amount</Table.Cell>
						<Table.Cell className="bold">
							{tokenAmount || "None"}
						</Table.Cell>
					</Table.Row>
					</>
					: ""
				}

				{ typeLabel === "Burn Token" ?
					<>
					<Table.Row>
						<Table.Cell>Burned Token Id</Table.Cell>
						<Table.Cell className="bold">
							{
								tokenIDs && tokenIDs.length ?
									tokenIDs.map((tokenID) => {
										return (
											<Link to={`/tokens/${tokenID}`}>{tokenID}</Link>
										)	
									})
								: "None"
							}
						</Table.Cell>
					</Table.Row>
					</>
					: ""
				}

				{ ["Token Freeze Account", "Token Unfreeze Account"].includes(typeLabel) ?
					<>
						<Table.Row>
							<Table.Cell>{typeLabel === "Freeze Tokens" ? "Freeze Tokens" : "Unfreeze Tokens" }</Table.Cell>
							<Table.Cell className="bold">
								{
									tokenIDs && tokenIDs.length ?
										tokenIDs.map((tokenID) => {
											return (
												<Link to={`/tokens/${tokenID}`}>{tokenID}</Link>
											)	
										})
									: "None"
								}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>{typeLabel === "Freeze Tokens" ? "Freeze Account" : "Unfreeze Account" }</Table.Cell>
							<Table.Cell className="bold">
								{
									tokenAccount ?
										<Link to={`/accounts/${tokenAccount}`}>{tokenAccount}</Link>
										: "None"
								}
							</Table.Cell>
						</Table.Row>
					</>
					: ""
				}

				{typeLabel === "Schedule Create" && (
					<Table.Row>
						<Table.Cell>Schedule Id</Table.Cell>
						<Table.Cell className="bold">{createdID || "None"}</Table.Cell>
					</Table.Row>
                )}

				{
					typeLabel == "Contract Call" && (
						<>
							<Table.Row>
								<Table.Cell>
									Contract ID
								</Table.Cell>
								<Table.Cell className="bold">
									{ contractID ?
										<Link to={`/contracts/${contractID}`}>{contractID}</Link>
									: "None"
									}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Function Parameters</Table.Cell>
								<Table.Cell className="bold">
								<div
									style={{
										width: "90%",
										maxWidth: "90%",
										wordWrap: "break-word",
										wordBreak: "break-word",
									}}>
										{/* {functionParameters} */}
									{functionParameters ?
										(functionParameters.length > 70
											? `${functionParameters.slice(0, 70)}...`
											: functionParameters)
										: "None"
									}
								</div>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Gas Offered</Table.Cell>
								<Table.Cell className="bold">{gasOffered}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Gas Used</Table.Cell>
								<Table.Cell className="bold">{gasUsed}</Table.Cell>
							</Table.Row>
						</>
					)
				}

				{typeLabel === "Contract Create" && (
					<>
						<Table.Row>
							<Table.Cell>Contract Id</Table.Cell>
							<Table.Cell className="bold">
								{
									contractID ?
										<Link to={`/contracts/${contractID}`}>{contractID}</Link>
										: "None"
								}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Constructor Parameters</Table.Cell>
							<Table.Cell className="bold">{raw && raw.data && raw.data.constructorParameters ? raw.data.constructorParameters.slice(0, 80) + "..." : "None"}...</Table.Cell>
						</Table.Row>
					</>
				)}

				{ typeLabel == "Mint Token" ?
					<>
						<Table.Row>
								<Table.Cell>Token Id</Table.Cell>
								<Table.Cell className="bold">
									{
										tokenIDs && tokenIDs.length ?
											tokenIDs.map((tokenID) => {
												return (
													<Link to={`/tokens/${tokenID}`}>{tokenID}</Link>
												)	
											})
										: "None"
									}
								</Table.Cell>
							</Table.Row>
						<Table.Row>
							<Table.Cell>Amount</Table.Cell>
							<Table.Cell className="bold">{tokenAmount || "None"}</Table.Cell>
						</Table.Row>
					</>
					: ""
				}

				{["Create Account"].includes(typeLabel) && (
					<Table.Row>
						<Table.Cell>Created Account</Table.Cell>
						<Table.Cell className="bold">
							{ createdID ? <Link to={`/accounts/${createdID}`}>{createdID}</Link> : "None" }
						</Table.Cell>
					</Table.Row>
				)}

				{["Update Account"].includes(typeLabel) && accountID && (
					<Table.Row>
						<Table.Cell>Updated Account</Table.Cell>
						<Table.Cell className="bold">
							{ accountID ? <Link to={`/accounts/${accountID}`}>{accountID}</Link> : "None" }
						</Table.Cell>
					</Table.Row>
				)}

				{["Create Token"].includes(typeLabel) && (
					<Table.Row>
						<Table.Cell>Created Token</Table.Cell>
						<Table.Cell className="bold">
							{ createdID ? <Link to={`/tokens/${createdID}`}>{createdID}</Link> : "None" }
						</Table.Cell>
					</Table.Row>
				)}

				{["Create Topic"].includes(typeLabel) && (
					<Table.Row>
						<Table.Cell>Created Topic</Table.Cell>
						<Table.Cell className="bold">
							{ createdID ? <Link to={`/topics/${createdID}`}>{createdID}</Link> : "None" }
						</Table.Cell>
					</Table.Row>
				)}
			</Table.Body>
		</Table>
		);
	};

	const payerInfoTable = (transaction) => {
		return (
			<Table basic="very" unstackable>
				<Table.Body>
				<Table.Row style={{borderBottom: "1px solid #d4d4d5"}}>
					<Table.Cell>
						Payer
					</Table.Cell>
					<Table.Cell className="bold">
						{ transaction.payerID ?
						<Link to={`/accounts/${transaction.payerID}`}>{transaction.payerID}</Link>
						: 'None'
						}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Transfer Amount</Table.Cell>
					<Table.Cell className="bold"><Money>{transaction.amount || 'None'}</Money></Table.Cell>
				</Table.Row> 
					<Table.Row>
						{
							transaction && transaction.transactionFee ?
								<>
									<Table.Cell>Transaction Fees</Table.Cell>
									<Table.Cell style={{fontWeight: 700}}>
										<Money>{transaction.transactionFee}</Money>
										<Popup
											content={
												<Table basic="very">
													<Table.Body>
														<Table.Row>
															<Table.Cell className="bold">NODE FEE</Table.Cell>
															<Table.Cell textAlign="right">
																<Money>{transaction.nodeFees}</Money>
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="bold">NETWORK FEE</Table.Cell>
															<Table.Cell textAlign="right">
																<Money>{transaction.networkFees}</Money>
															</Table.Cell>
														</Table.Row>
													</Table.Body>
												</Table>
											}
											trigger={<Icon className="clickable" name="list" color="blue" />}
											position="right center"
											hoverable={true}
										/>
									</Table.Cell>
								</>
							: <>
							<Table.Cell>Transaction Fees</Table.Cell>
							<Table.Cell>None</Table.Cell>
							</>
						}
					</Table.Row> 
					<Table.Row>
						{
							transaction && transaction.amount ?
								<>
									<Table.Cell>Total Amount</Table.Cell>
									<Table.Cell style={{fontWeight: 700}}><Money>{transaction.amount}</Money></Table.Cell>
								</>
							: <>
								<Table.Cell>Total Amount</Table.Cell>
								<Table.Cell>None</Table.Cell>
								</>
						}
					</Table.Row> 
				</Table.Body>
		</Table>
		);
	};

	return (
		<Grid stackable columns={2}>
		<Grid.Row stretched style={{ alignContent: 'center' }}>
			<Grid.Column width={8}>
			<Card noTitle={true} children={payerInfoTable(transaction)} />
			</Grid.Column>
			<Grid.Column width={8}>
				{
					transaction ?
					<Card title="More Transaction Info" children={renderMoreTransaction(transaction)}/>
					: ''
				}
			</Grid.Column>
		</Grid.Row>
		</Grid>
	);
};

const mapStateToProps = (state, { match: { params: { txId } } }) => ({
	id: txId,
});

export default withRouter(connect(mapStateToProps, actions)(TransactionSummary));

