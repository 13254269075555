import { SEARCH_REQUEST, RESET_SEARCH } from '../types';

const initialState = null;

export default function search(state = initialState, action) {
	switch (action.type) {
		case SEARCH_REQUEST:
			return { ...action.query };
		case RESET_SEARCH:
			return { ...initialState };
		default:
			return state;
	}
}

export const getSearchParams = (state) => state;
