import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import DateCell from './shared/DateCell';
import Money from './shared/Money';
import eye from '../assets/images/eye@2x.png';
import { isMobile } from '../utils';

const redirectTo = (id) => {
	return window.location.href =`/accounts/${id}`;
};

const AccountRow = ({ account }) => (
	<Table.Row className="clickable hoverRow">
		<Table.Cell collapsing onClick={()=> redirectTo(account.accountID)}>
			{account.accountID}
		</Table.Cell>
		<Table.Cell collapsing onClick={()=> redirectTo(account.payerID)}>
			<Link to={`/accounts/${account.payerID}`}>{account.payerID}</Link>
		</Table.Cell>
		<Table.Cell collapsing onClick={()=> redirectTo(account.accountID)}>
			<DateCell date={account.createdOn} />
		</Table.Cell>
		<Table.Cell collapsing onClick={()=> redirectTo(account.accountID)}>
			<DateCell date={account.expiry} />
		</Table.Cell>
		<Table.Cell collapsing textAlign="right" onClick={()=> redirectTo(account.accountID)}>
			<Money>{account.balance}</Money>
		</Table.Cell>
		<Table.Cell width={3} style={{
			// wordWrap: 'break-word', wordBreak: 'break-word'
			width: isMobile() ? '200px' : '50px', 
			maxWidth: isMobile() ? '200px' : '50px',
			overflow: 'hidden',
			textOverflow: 'ellipsis', 
			whiteSpace: 'nowrap',
		}} onClick={()=> redirectTo(account.accountID)}>
			<Popup
				trigger={<span>{account.memo}</span>}
				content={account.memo}
				position="top center"
				hoverable
			/>
			</Table.Cell>
		{/* <Table.Cell textAlign="center" collapsing onClick={()=> redirectTo(account.accountID)}>
			<Link to={`/accounts/${account.accountID}`}>
				<Popup
					content="View Account Details"
					trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
				/>
			</Link>
		</Table.Cell> */}
	</Table.Row>
);

export default AccountRow;

AccountRow.propTypes = {
	account: PropTypes.object.isRequired
};
