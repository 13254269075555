import React, {useState} from 'react';
import { Grid, Container } from 'semantic-ui-react';
import AccountSummaryPanel from '../molecules/AccountSummaryPanel';
import AccountDetailsTabs from '../components/AccountDetailsTabs';
import Page from '../components/shared/Page';

export default (props) => {

	const [tabChangetoTokenBalance, setTabChangetoTokenBalance] = useState(false);

	function handleTab () {
		setTabChangetoTokenBalance(true);
	}
	return (
		<Page>
			<Container fluid className="w-98">
				<Grid padded stackable>
					<Grid.Column computer={16} tablet={16} mobile={16}>
						<Grid columns={1}>
							<Grid.Column>
								<AccountSummaryPanel {...props} handleTab={handleTab}/>
							</Grid.Column>
							<Grid.Column>
								<AccountDetailsTabs tabChangetoTokenBalance={tabChangetoTokenBalance} setTabChangetoTokenBalance={setTabChangetoTokenBalance}/>
							</Grid.Column>
						</Grid>
					</Grid.Column>
				</Grid>
			</Container>
		</Page>
	);
}
