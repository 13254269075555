import React, { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
//import am4themes_dark from '@amcharts/amcharts4/themes/dark';

am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_dark);

export default class NodesMap extends Component {
	componentDidMount() {
		this.initMap();
	}

	initMap = () => {
		// Create map instance
		let map = am4core.create('map', am4maps.MapChart);

		// map.homeZoomLevel = 1;
		// map.homeGeoPoint = {
		// 	latitude: 52,
		// 	longitude: 11
		// };

		// Set map definition
		map.geodata = am4geodata_worldLow;

		//set projection
		map.projection = new am4maps.projections.Miller();

		// Create map polygon series
		let polygonSeries = new am4maps.MapPolygonSeries();
		polygonSeries.useGeodata = true;
		polygonSeries.exclude = [ 'AQ' ]; //exclude antartica
		map.series.push(polygonSeries);

		// Configure series
		let polygonTemplate = polygonSeries.mapPolygons.template;
		//polygonTemplate.tooltipText = '{name}';
		polygonTemplate.fill = am4core.color('#B5EAFA');
		// Create hover state and set alternative fill color
		//let hs = polygonTemplate.states.create('hover');
		//hs.properties.fill = am4core.color('#4da1e0');

		// Create image series
		let mapImageSeries = new am4maps.MapImageSeries();
		map.series.push(mapImageSeries);

		mapImageSeries.tooltip.getFillFromObject = false;
		mapImageSeries.tooltip.background.fill = am4core.color('#fff');
		mapImageSeries.tooltip.background.cornerRadius = 10;
		mapImageSeries.tooltip.background.fillOpacity = 1;
		mapImageSeries.tooltip.background.stroke = am4core.color('#1b97ae');
		mapImageSeries.tooltip.label.fill = am4core.color('#333333');

		//disable touch scrolling
		map.seriesContainer.draggable = false;
		map.seriesContainer.resizable = false;
		//disable mouse wheel zoom
		map.chartContainer.wheelable = false;

		// Create image
		let imageSeriesTemplate = mapImageSeries.mapImages.template;
		imageSeriesTemplate.propertyFields.latitude = 'latitude';
		imageSeriesTemplate.propertyFields.longitude = 'longitude';
		// imageSeriesTemplate.tooltipText =
		// 	'[bold]{numTransactions}[/] txns received \n [bold]{hbarEarned}[/] HBAR earned \n\n {name}';
		imageSeriesTemplate.tooltipHTML = `
		<div class="map-marker-tooltip">
			<span class="count">{council_name}</span> <br />
			<span class="count">{hedera_id}</span> Hedera Id <br />
			<span class="count">{stake} ℏ</span> Stake <br />
			<span class="count">{stake_rewarded} ℏ</span> Stake Rewarded <br />
			<span class="count">{stake_not_rewarded} ℏ</span> Stake Not Rewarded <br />
			<span class="count">{numTransactions}</span> txns <br />
			<span class="count">{hbarEarned}</span> ℏ <br />
			<br />
			<span class="bold">{name}<span>
		</div>`;

		//imageSeriesTemplate.text = `[bold] {name}`;

		let marker = imageSeriesTemplate.createChild(am4core.Sprite);
		marker.path = `
        M 5, 10
        a 5,5 0 1,1 10,0
        a 5,5 0 1,1 -10,0
        `;
		marker.fill = am4core.color('#FC6E4F');
		//marker.href = markerImage;
		marker.width = 40;
		marker.height = 40;
		marker.nonScaling = true;
		marker.horizontalCenter = 'middle';
		marker.verticalCenter = 'middle';
		//.fill = am4core.color('#fff');
		//marker.alwaysShowTooltip = false;
		// marker.tooltipText =
		// 	'[bold]{numTransactions}[/] txns received \n [bold]{hbarEarned}[/] HBAR earned \n\n {name}';
		//marker.tooltipPosition = 'fixed';
		//marker.tooltipHTML ='<div><div><h5 style="display: inline-block; margin-right: 5px">{numTransactions}</h5><span style="font-size: 0.8rem">txn received</span></div><div><h5 style="display: inline-block; margin-right: 5px">{hbarEarned}</h5><span style="font-size: 0.8rem">HBAR earned</span></div><div>{name}</div></div>';

		//marker.propertyFields.alwaysShowTooltip = 'showTooltip';

		let label = imageSeriesTemplate.createChild(am4core.Label);

		//label.html = '<strong class="title">{name}</strong>';

		label.verticalCenter = 'end';
		label.horizontalCenter = 'middle';
		label.nonScaling = true;
		label.dy = 15;
		// label.dx = 10;

		this.map = map;
		this.series = mapImageSeries;
	};

	componentDidUpdate(prevProps) {
		if (prevProps.nodes !== this.props.nodes) {
			if (this.series) {
				this.series.data = this.props.nodes;
			}
			//this.map.data = nodes;
		}
	}

	componentWillUnmount() {
		if (this.map) {
			this.map.dispose();
		}
	}

	render() {
		return <div id="map" className="map" />;
	}
}
