import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import TransferRow from './TransferRow';
import TransferTableHeader from './TransferTableHeader';

const TransferTable = ({ transfers = [], limit, offset, sort, order, totalCount, loadData, showDetails }) => {
	const transferRows = transfers.map((transfer, index) => (
		<TransferRow key={index} transfer={transfer} showDetails={showDetails} />
	));
	return (
		<DataTable
			limit={limit}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={TransferTableHeader}
			rows={transferRows}
			loadData={loadData}
		/>
	);
};

export default TransferTable;

TransferTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired,
	showDetails: PropTypes.func.isRequired
};
