import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import Money from './shared/Money';
import DateCell from './shared/DateCell';
import eye from '../assets/images/eye@2x.png';
import { isMobile } from '../utils';


const redirectTo = (transaction) => {
	return window.location.href =`/transactions/${transaction.transactionID}`;
};

const TransactionRow = ({ transaction, showDetails }) => (
	<Table.Row className="clickable hoverRow" onClick={()=> redirectTo(transaction)}>
		<Table.Cell collapsing>{transaction.typeLabel.toUpperCase()}</Table.Cell>
		<Table.Cell collapsing>
			<DateCell date={transaction.consensusTime} />
		</Table.Cell>
		{/* <Table.Cell>{transaction.account}</Table.Cell> */}
		<Table.Cell collapsing textAlign="right">
			<Money>{transaction.amount}</Money>
		</Table.Cell>
		<Table.Cell width={3} textAlign="left" style={{color: transaction.status === "SUCCESS" ? "#09a309" : "red", fontWeight: "500",
			width: isMobile() ? '200px' : '50px', 
			maxWidth: isMobile() ? '200px' : '50px',
			overflow: 'hidden',
			textOverflow: 'ellipsis', 
			whiteSpace: 'nowrap',
		 }}>
			<Popup
				trigger={<span>{transaction.status}</span>}
				content={transaction.status}
				position="top center"
				hoverable
			/>
			
		</Table.Cell>
		<Table.Cell width={4} style={{
			width: isMobile() ? '200px' : '50px', 
			maxWidth: isMobile() ? '300px' : '50px',
			overflow: 'hidden',
			textOverflow: 'ellipsis', 
			whiteSpace: 'nowrap',
			}}>
			<Popup
				trigger={<span>{transaction.memo}</span>}
				content={transaction.memo}
				position="top center"
				hoverable
			/>
		</Table.Cell>
		{/* <Table.Cell textAlign="center" collapsing>
			<a href={`/transactions/${transaction.transactionID}`}>
				<Popup
					content="View Transaction Details"
					trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
				/>
			</a>
		</Table.Cell> */}
	</Table.Row>
);

export default TransactionRow;

TransactionRow.propTypes = {
	transaction: PropTypes.object.isRequired,
	showDetails: PropTypes.func.isRequired
};
