import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Stat from '../shared/Stat';
import LineChart from '../shared/LineChart';
import * as actions from '../../redux/actions';
import { getContractTransactionVolume, getContractTransactionVolumeLoader } from '../../redux/reducers';
import { formatTimestamp, parseQueryParams } from '../../utils';
import LoadingIndicator from "./LoadingIndicator";

class ContractTxnVolumeChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchContractTransactionVolume, id, period } = this.props;
		fetchContractTransactionVolume(id, period);
		this.props.sendContractIdparam(id);
	}
	render() {
		const { volume: { totalSum, /* totalCount,*/ pctChange, data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [ new Intl.NumberFormat('en').format(value) + ' txns' ];
		if (!this.props.loading) {
			return (
				data && data.length ?
					<Fragment>
						<Stat count={totalSum} pct={pctChange} label="txns" />
						<br />
						<LineChart
							data={data}
							tickFormatter={formatter}
							labelFormatter={formatter}
							valueFormatter={valueFormatter}
						/>
					</Fragment>
				: <div>No transactions available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state, { match: { params: { contractId } } }) => {
	const params = parseQueryParams(window.location.search);
	return {
		id: contractId ? contractId : params.contractId,
		volume: getContractTransactionVolume(state, contractId ? contractId : params.contractId),
		loading: getContractTransactionVolumeLoader(state, contractId ? contractId : params.contractId),
	}
};

ContractTxnVolumeChart = withRouter(connect(mapStateToProps, actions)(ContractTxnVolumeChart));

export default ContractTxnVolumeChart;
