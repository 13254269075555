import React, { useState } from 'react';
import SubscriptionForm from './forms/SubscriptionForm';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getUser } from '../redux/reducers';

const Subscribe = ({ handleCancel, handleSubscribeMessage, subscribe, user, uploadFiles }) => {

    const [form, setForm] = useState({
        userId: user.id
    });

    const setSelectedModel = (model) => {
        setForm({ ...form, selectedModel: model });
    }

    const setDate = (date) => {
        setForm({ ...form, date: date, period: '', count: '' });
    }

    const setDataPeriod = (period) => {
        setForm({ ...form, period: period, date: '', count: '' });
    }

    const setCount = (count) => {
        setForm({ ...form, count: count, date: '', period: '' });
    }

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const onSubmit = async e => {
        console.log('ON SUBMIT IS CALLED')
        e.preventDefault();
        handleCancel();
        console.log("THIS IS THE FORM: ", form)
    }

    // const validate = (form) => {
    //     const { company, title, description, accountId, contractId, website, androidAppLink, iosAppLink } = form;
    //     let errors = {};
    //     if (!company || company.trim().length === 0) errors.company = "Company name can't be blank";
    //     if (!title || title.trim().length === 0) errors.title = "Title can't be blank";
    //     if (!accountId || accountId.trim().length === 0) errors.accountId = "Account Id can't be blank";
    //     // if (!contractId || contractId.trim().length === 0) errors.contractId = "Contract Id can't be blank";

    //     return errors;
    // }

    const { selectedModel, date, dataPeriod, count } = form;

    return (
        <SubscriptionForm 
            selectedModel={selectedModel}
            date={date} 
            dataPeriod={dataPeriod}
            count={count}

            setSelectedModel={setSelectedModel}
            setDate={setDate}
            setDataPeriod={setDataPeriod}
            setCount={setCount}

            handleSubscribeMessage={handleSubscribeMessage}
            handleCancel={handleCancel}
            onSubmit={onSubmit}
            errors={errors}

        />
    )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default connect(mapStateToProps, actions)(Subscribe);
