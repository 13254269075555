import React, { Component } from 'react';
import Panel from './shared/Panel';
import PeriodSelector from './shared/PeriodSelector';
import Info from './shared/Info';
import DappAccountBalanceChart from './containers/DappAccountBalanceChart';
import { parseQueryParams } from  '../utils';
import { withRouter } from 'react-router-dom';
import Share from './shared/Share';

// export default () => (
// 	<Panel title="BALANCE HISTORY" description="">
// 		<AccountBalanceChart />
// 	</Panel>
// );

export class DappAccountBalanceChartPanel extends Component {
	state = {
		period: '1W',
	};

	componentDidMount() {
		const params = parseQueryParams(this.props.location.search);
		if(params && params.period) {
				this.setState({
						period: params.period,
				});
		}
	}

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
		//to do
		//update price and percent change
	};

	render() {
		const { period } = this.state;
		let url = `/chart?name=dapp-acc-bal&period=${this.state.period}&accountNumber=${this.props.accountNumber}`;
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						BALANCE HISTORY <Info id="balanceHistory" />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				footer={<Share url={url} params={params} title={"Dragon Glass Dapp Account Balance Chart"}/>}
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				{/* History coming soon */}
				<DappAccountBalanceChart period={period} accountNumber={this.props.accountNumber} />
			</Panel>
		);
	}
}

export default withRouter(DappAccountBalanceChartPanel);
