import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import PlaceHolder from '../components/shared/PlaceHolder';
import GraphToggle from '../components/shared/GraphToggle';

export default function Panel({ title, icon, style, toggle, type, titleClass, description = '', toolbar, isHideHeader, footer, children = <PlaceHolder /> }) {
	const customStyle = style ? { paddingBottom: '42%'} : {};
	return (
		<Grid padded stackable className="panel">
			{
				!isHideHeader && (
					<Grid.Row>
						<Grid.Column width="16">
							<div className="panel-header">
								<div className={`panel-title ${titleClass}`}>{title}</div>
								{(type=="txnVolume" || type=="txnValue") && <GraphToggle icon={icon} toggle={toggle}/>}
								<div className="panel-toolbar">{toolbar}</div>
							</div>
						</Grid.Column>
					</Grid.Row>
				) 
			}
			<Grid.Row style={{...customStyle}}>
				<Grid.Column>{children || description}</Grid.Column>
			</Grid.Row>
			{footer && (
				<Grid.Row>
					<Grid.Column>{footer}</Grid.Column>
				</Grid.Row>
			)}
		</Grid>
	);
}
