import React from 'react';
import Number from './Number';

export default function MoneyStat({ currency, children }) {
	let symbol;
	switch (currency) {
		case 'hbar':
			symbol = 'ℏ';
			break;
		default:
			symbol = 'tℏ';
	}

	return (
		<React.Fragment>
			<Number>{children}</Number> {symbol}
		</React.Fragment>
	);
}
