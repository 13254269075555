import React, { isValidElement } from 'react';
import { Button, Form, Message, Icon } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';

export default function DappCreateForm({ 
        name, 
        email, 
        company, 
        title, 
        description, 
        accountId, 
        contractId, 
        website, 
        androidAppLink, 
		iosAppLink,
		deployed,
        onChange, 
        onSubmit, 
        errors = {}, 
		loading,
		onDrop,
		acceptedFiles,
		onUpload,
		abiFileName,
		setAbiFileName,
		sourceCodeFileName,
		setSourceCodeFileName,
		byteCodeFileName,
		setByteCodeFileName,
		logoFileName,
		setLogoFileName
    }) {

	const isError = Object.keys(errors).length !== 0;
	
	// let files = acceptedFiles.map(file => `${file[0].path}<br/>`)

	const fileInputRef1 = React.createRef();
	const fileInputRef2 = React.createRef();
	const fileInputRef3 = React.createRef();
	const fileInputRef4 = React.createRef();

	const fileChange = e => {
		setAbiFileName(e.target.files[0].name)
	  };
    
	return (
		<Form size="large" /*onSubmit={e => {onSubmit(e); onUpload(e)}}*/ error={isError}>
			<Message error content={errors.non_field_errors} />
			<Form.Input
				id="company"
				name="company"
				fluid
				// icon="building"
				// iconPosition="left"
				placeholder="Company"
				autoFocus
				value={company}
				onChange={onChange}
				error={!!errors.company}
				autoComplete="company"
				required={false}
				maxLength="50"
			/>
			<Message error content={errors.company} className="animated fadeIn" />
			<Form.Input
				id="title"
				name="title"
				fluid
				// icon="user"
				// iconPosition="left"
				placeholder="Title"
				value={title}
				onChange={onChange}
				error={!!errors.title}
                autoComplete="title"
                required={false}
				maxLength="50"
			/>
			<Message error content={errors.title} className="animated fadeIn" />
			<Form.TextArea style={{wordWrap: 'break-word', wordBreak: 'break-word', overflow: "auto"}}
				id="description"
				name="description"
				// fluid
				placeholder="Description"
				value={description}
				onChange={onChange}
				error={!!errors.description}
				autoComplete="description"   
				required={false}
				maxLength="300"
			/>
			<Message error content={errors.description} />
			<Form.Input
				id="accountId"
				name="accountId"
				fluid
				// icon="building"
				// iconPosition="left"
				placeholder="Deployer Account ID"
				value={accountId}
				onChange={onChange}
				error={!!errors.accountId}
				autoComplete="accountId"
				required={false}
			/>
			<Message error content={errors.accountId} className="animated fadeIn" />
			<Form.Input
				id="contractId"
				name="contractId"
				fluid
				// icon="building"
				// iconPosition="left"
				placeholder="Contract ID"
				value={contractId}
				onChange={onChange}
				error={!!errors.contractId}
				autoComplete="contractId"   
				required={false}
			/>
			<Message error content={errors.contractId} className="animated fadeIn" />
			<Form.Input
				id="website"
				name="website"
				fluid
				// icon="building"
				// iconPosition="left"
				placeholder="Website"
				value={website}
				onChange={onChange}
				// error={!!errors.company}
				autoComplete="website"   
				required={false}
			/>
			<Form.Input
				id="androidAppLink"
				name="androidAppLink"
				fluid
				// icon="building"
				// iconPosition="left"
				placeholder="Android App Link"
				value={androidAppLink}
				onChange={onChange}
				// error={!!errors.company}
				autoComplete="androidAppLink"   
				required={false}
			/>
			<Form.Input
				id="iosAppLink"
				name="iosAppLink"
				fluid
				// icon="building"
				// iconPosition="left"
				placeholder="iOS App Link"
				value={iosAppLink}
				onChange={onChange}
				// error={!!errors.company}
				autoComplete="iosAppLink"   
				required={false}
			/>
			<Form.Input
				id="deployed"
				name="deployed"
				fluid
				// icon="building"
				// iconPosition="left"
				placeholder="Date of Deployment"
				value={deployed}
				onChange={onChange}
				// error={!!errors.company}
				autoComplete="deployed"   
				required={false}
			/>

			{/* <div className="text-center mt-5 dropzone">
				<Dropzone multiple onDrop={onDrop}>
					{({getRootProps, getInputProps}) => (
					<div {...getRootProps()} className="drop">
						<input {...getInputProps()} />
						<p style={{cursor: "pointer"}}>Click here or drag and drop to upload<br />
						logo, abi, source code, and byte code</p>
						<Icon className="file alternate outline" size="big" style={{color: "#bdc3c7"}} />

						{acceptedFiles.map(file => 
							<span key={Math.random()}>{file.path}</span>)}
					</div>
					)}
				</Dropzone>
			</div> */}

			<Form.Field>
                <Button
				style={{width: "260px", textAlign: "left"}}
                  content="Choose Abi File"
                  labelPosition="left"
                  icon="file"
                  onClick={() => fileInputRef1.current.click()}
                />&nbsp;&nbsp;
				<span>{abiFileName}</span>
                <input
                  ref={fileInputRef1}
                  type="file"
                  hidden
                  onChange={e => { setAbiFileName(e.target.files[0].name); onDrop({abi: e.target.files[0]}) }}
                />
              </Form.Field>

			  <Form.Field>
                <Button
				style={{width: "260px", textAlign: "left"}}
                  content="Choose Source Code File"
                  labelPosition="left"
                  icon="file"
                  onClick={() => fileInputRef2.current.click()}
                />&nbsp;&nbsp;
				<span>{sourceCodeFileName}</span>
                <input
                  ref={fileInputRef2}
                  type="file"
                  hidden
                  onChange={e => { setSourceCodeFileName(e.target.files[0].name); onDrop({sourceCode: e.target.files[0]}) }}
                />
              </Form.Field>

			  <Form.Field>
                <Button
				style={{width: "260px", textAlign: "left"}}
                  content="Choose Byte Code File"
                  labelPosition="left"
                  icon="file"
                  onClick={() => fileInputRef3.current.click()}
                />&nbsp;&nbsp;
				<span>{byteCodeFileName}</span>
                <input
                  ref={fileInputRef3}
                  type="file"
                  hidden
                  onChange={e => { setByteCodeFileName(e.target.files[0].name); onDrop({byteCode: e.target.files[0]}) }}
                />
              </Form.Field>

			  <Form.Field>
                <Button
				style={{width: "260px", textAlign: "left"}}
                  content="Choose Logo File"
                  labelPosition="left"
                  icon="file"
                  onClick={() => fileInputRef4.current.click()}
                />&nbsp;&nbsp;
				<span>{logoFileName}</span>
                <input
                  ref={fileInputRef4}
                  type="file"
                  hidden
                  onChange={e => { setLogoFileName(e.target.files[0].name); onDrop({logoURL: e.target.files[0]}) }}
                />
              </Form.Field>

			<Button onClick={e => {onSubmit(e); /*onUpload(e)*/}} primary={true} fluid loading={loading} style={{width: "14%", float: "right", marginBottom: "20px"}}>
				SUBMIT
			</Button>
			{/* <Message error content={errors.auth} /> */}
		</Form>
	);
}
