import React, { Fragment } from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import '../animate.css';

const CopyCustomTooltipRight = (props) => {

    return (
        <Fragment>
        {/* <Popup
            trigger={<Icon name="copy" className="copy-icon" onClick={props.handleCopyClick} />}
            content={<span className={props.clicked ? "info-content animated flash" : "info-content"}>
                        {props.clicked ? "Copied!" : "Copy to clipboard"}
                    </span>}
            hoverable
            style={{cursor: "pointer"}}
            basic
        /> */}
        <span className="hasTooltip">
            <div className={props.clicked ? "info-content-right animated flash" : "info-content-right"}>
                {props.clicked ? "Copied!" : "Copy to clipboard"}
            </div>
            <Icon name="copy" className="copy-icon" onClick={props.handleCopyClick} />
        </span>
        </Fragment>
    )
}

export default CopyCustomTooltipRight;