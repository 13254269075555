import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import * as actions from '../redux/actions';
import { getTokenDetails } from "../redux/reducers/hts";
import { getNftsDetail, getNftsHistoricalDetail, getNftsHistoricalDetailCount, getSearchedTransactions } from '../redux/reducers';
import { parseQueryParams, removeEmpty } from '../utils';
import NFTDetailsSummary from '../organisms/NFTDetailsSummary';
import { Helmet } from 'react-helmet';
import CustomBreadcrumb from '../atoms/CustomBreadcrumb';

const styles = {
	accountHeader: {
		display: 'flex',
	},

}

const NFTDetailsSummaryPanel = (props) => {

	const [limit, setLimit] = useState(5);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState('');
	const [order, setOrder] = useState('desc');
	const [page, setPage] = useState(1);

	const { match: { params: { tokenId } }, account, nftsDetail, nftHistoricalData, transactions } = props;
	const nftCreatorDetails = nftHistoricalData && nftHistoricalData.length && nftHistoricalData[0] || {};

	useEffect(() => { 
		fetchData();
	}, [])

	  const fetchData = () => {
		const { tokenId, fetchNftsDetails, fetchTokenDetails, fetchTransactions } = props;
		const params = parseQueryParams(props.location.search);
		let nftDetail = {"serialNumber": params.serialNumber, "tokenId": tokenId}
		fetchTokenDetails({ "tokenId": tokenId});
		fetchNftsDetails(nftDetail);
		fetchHistoryData(nftDetail);
		const esc = encodeURIComponent;
		const queryParams = {"tokenID": tokenId, limit: 1, order: 'desc'}
		const searchQuery = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');
		fetchTransactions(searchQuery);
	}

	const fetchHistoryData = (nftDetailParams) => {
		const { fetchNftsHistoricalDetails } = props;
		const queryParams = {
			offset,
			limit: 1,
			page: 1,
			order: "asc",
			...nftDetailParams
		 }
		const nftHistoryParams = {...queryParams };
		const nftHistoryParamsF = removeEmpty(nftHistoryParams);
		fetchNftsHistoricalDetails(nftHistoryParamsF);
  	};
	  const breadCrumbOptions = [
		{ title: 'NFT Details', isActive: true },
	]
	return (<>
			{
				nftsDetail && nftsDetail.token && nftsDetail.token == tokenId ?
				<Grid stackable>
					<Helmet>
						<title>Dragon Glass - NFT Details - {nftsDetail.name || nftsDetail.token }</title>
					</Helmet>
					<CustomBreadcrumb options={breadCrumbOptions}/>
					<Grid.Row style={{paddingBottom:'5px', paddingTop: '2%', borderBottom: '1px solid black',}}>
							<Grid.Column width={8}>
								{
									nftsDetail && nftsDetail.nftName
									? <>
										<h2 style={{margin: '0px'}}>{nftsDetail && nftsDetail.nftName || ''}</h2>
										<span style={{color: 'gray'}}>{nftsDetail && nftsDetail.name || ''}</span>
									</>
									:	<h2 style={{margin: '0px'}}>{nftsDetail && nftsDetail.name || ''}</h2>	 
								}
							</Grid.Column>
					</Grid.Row>					
					<Grid.Row>
						<NFTDetailsSummary {...props} nftCreatorDetails={nftCreatorDetails} />
					</Grid.Row>
				</Grid>
				: null
			}
		</>
	);
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
	tokenId: tokenId,
	nftsDetail : getNftsDetail(state),
	nftHistoricalData : getNftsHistoricalDetail(state),
	totalCount: getNftsHistoricalDetailCount(state),
	tokenDetails: getTokenDetails(state),
	transactions: getSearchedTransactions(state),
});

export default connect(mapStateToProps, actions)(NFTDetailsSummaryPanel);
