import React from 'react';

import '../animate.css';

export const SubscriptionDescriptionHCS = () => {
    return (
        <div className="description-container animated fadeIn">
            <div className="description-text">
                For the subscription type HCS_TOPIC, the only available filter type is TOPIC.  
                HCS_TOPIC subscriptions are specifically for Hedera Consensus Service where 
                the messages will be pushed to the DragonGlass queue for a given topic ID.
            </div>
            {/* ------------------------------ */}
            <div className="image-div">
                <img width="90%" src="/hcs_subscription1.png" alt="" />
            </div>
            <div className="description-text">
                Once an HCS_TOPIC subscription is created, users can see it in the My Subscription menu in their Profile.  
                For HCS_TOPIC, the only available filter type is TOPIC; users will have to provide a Topic Name by selecting the Filter.
            </div>
            <div className="image-div">
                <img width="90%" src="/display_hcs_filters_edited.png" alt="" />
            </div>
            <div className="description-text">
                Users can add a filter by clicking the Filter icon in the Filters column of the subscription table and selecting the 
                Add Filter button.  There, users can select the filter type (the only option being TOPIC), and add a contract ID for the Filter Value.
            </div>
            <div className="image-div">
                <img width="90%" src="/adding_a_filter_for_hcs.png" alt="" />
            </div>
            <div className="description-text">
                Once a filter’s parameters are defined, the user selects the Add Filter button, and the new filter will be seen in 
                My Subscriptions.  If a filter has already been created, clicking the Filter icon will display a table providing 
                an option to delete an existing filter. 
            </div>
            <div className="image-div">
                <img width="90%" src="/display_filter_for_hcs.png" alt="" />
            </div>
            <div className="description-text">
                Creating a subscription generates a unique SQS queue partially displayed in the Queue column of the subscription 
                table. Hovering over the table cell displays the entire queue which can be then copied by double-clicking and 
                further used in Amazon Simple Queue Service for integration of decentralized applications. 
            </div>
        </div>
    )
}

export default SubscriptionDescriptionHCS;
