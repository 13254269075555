import React from 'react';
import { ResponsiveContainer, CartesianGrid, Legend, LineChart, BarChart, Bar, Line, XAxis, YAxis, Tooltip, Cell } from 'recharts';
import { Grid, Popup } from 'semantic-ui-react';
import { divideArrayIntoMany } from '../utils';

const NumberFormater = (number) => {
	if (number > 1000000000) {
		return (number / 1000000000).toString() + 'B';
	} else if (number > 1000000) {
		return (number / 1000000).toString() + 'M';
	} else if (number > 1000) {
		return (number / 1000).toString() + 'K';
	} else {
		return number.toString();
	}
};

const styles = {
	ellipsis: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',

	  },
}

export default ({ graphType, data = [], dataKey = 'value', tickFormatter, labelFormatter, valueFormatter, multiple, isLegend, customHeight= 300, isNoTooltip = false, showLegend }) => {
	console.log("graphType : ", graphType, showLegend, customHeight)
	const colors = [
		"#8884c8",
		"#82aa9d",
		"#ffc658",
		"#fg7300",
		"#01C49F",
		"#FFBC28",
		"#0088GE",
		"#00C49F",
		"#FFBB27",
		"#FF8042",
		"#AF19FF",
		"#0088FE",
		"#00K49F",
		"#FFBB21",
		"#FF8032",
		"#AF19FG",
		"#FLBB28",
		"#FF8052",
		"#AF10FF",
		"#FF9042",
		"#8884d8",
		"#82ca9d",
		"#ffc648",
		"#ff7300",
		"#00C49E",
		"#FFBB28",
		"#1088FE",
		"#00D49F",
		"#FFBC28",
		"#FF8152",
		"#AF198F",
		"#0018FE",
		"#00C46F",
		"#FFCB28",
		"#FF804K",
		"#AF194F",
		"#FEBB28",
		"#FF80E2",
		"#AF19F1",
		"#FF8L42",
	  ];
	const transformData = () => {
		const transformedData = [];
		const transactionTypes = new Set();
	  
		data.forEach((entry) => {
		  const timestamp = entry.timestamp;
	  
		  entry.value.forEach((item) => {
			const { transactionType, count } = item;
			transactionTypes.add(transactionType);
	  
			const existingData = transformedData.find((dataItem) => dataItem.timestamp === timestamp);
			if (existingData) {
			  existingData[transactionType] = count;
			} else {
			  const newData = {
				timestamp,
				[transactionType]: count,
			  };
			  transformedData.push(newData);
			}
		  });
		});
	  
		return { transformedData, transactionTypes: Array.from(transactionTypes) };
	  };
	  	  
	  const StackedBarChart = () => {
		const { transformedData, transactionTypes } = transformData();
	  
		return (
			<ResponsiveContainer width="100%" height={customHeight}>
				{
					graphType=="chart line"
					? 	<BarChart data={transformedData}>
							{/* <CartesianGrid strokeDasharray="3 3" /> */}
							<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter}/>
							<YAxis tickFormatter={NumberFormater} width={48}/>
							{/* {isLegend ? <Legend /> : ''} */}
							{isLegend ? <Legend content={<CustomLegend />} /> : ''}
							{!isNoTooltip ?
							<Tooltip
							labelFormatter={labelFormatter}
							formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
							/>
							: ''
							}
							{transactionTypes.map((transactionType, index) => (
							<Bar key={transactionType} dataKey={transactionType} stackId="stack" fill={colors[index % colors.length]} />
							))}
						</BarChart>
					:	<LineChart data={transformedData} style={{fontSize: "80%", marginLeft: "-15px"}}>
							<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter} />
							<YAxis tickFormatter={NumberFormater} width={48}/>
							<Tooltip
								labelFormatter={labelFormatter}
								formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
								wrapperStyle={{ fontSize: '8px' }} // Adjust the font size for a smaller tooltip
								// position={{ y: 10, x: 0 }}
							/>
							{/* {isLegend ? <Legend /> : ''} */}
							{isLegend ? <Legend content={<CustomLegend />} /> : ''}
							{transactionTypes.map((transactionType, index) => (
							<Line key={transactionType} dataKey={transactionType} type="monotone" dot={false} strokeWidth={2} stroke={colors[index % colors.length]} />
							))}
						</LineChart>
				}
		  </ResponsiveContainer>
		);
	  };

	  const CustomLegend = ({ payload }) => {
		const dividedArrays = divideArrayIntoMany(payload, 3);
		return (
			<Grid container>
				<Grid.Row column={16}>
					{dividedArrays.map((row, index) => (
					<Grid.Column width={5}>
						<Grid.Row>
							{row.map((entry, index) => (
								<Grid.Column key={`item-${index}`} style={showLegend ? styles.ellipsis : {}}>
									<Popup
										trigger={<span><span className='font20' style={{ color: entry.color }}>&#x25A0;</span> <span style={{ color: entry.color }}>{entry.value}</span></span>}
										content={entry.value}
										position="top center"
										hoverable
									/>
								</Grid.Column>
							))}
						</Grid.Row>
					</Grid.Column>
					))}
				</Grid.Row>
			</Grid>
		);
	  };

	if (multiple) {
		return <>
		{StackedBarChart(data)}
		</>
	} else {
		return (
			<ResponsiveContainer width="100%" height={200} >
	
		{		graphType=="chart line" ? (
						<BarChart width={150} height={40} data={data} style={{fontSize: "80%", marginLeft: "-15px"}}>
							<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter} />
							<YAxis tickFormatter={NumberFormater} width={48} />
							<Tooltip
								labelFormatter={labelFormatter}
								formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
							/>
							<Bar dataKey={dataKey} >
								{
									 data.map((entry, index) => (
										 <Cell key={`cell-${index}`} fill={index === data.length -1 ? "purple" : "#8884d8"}/>
									 ))
								 }
							</Bar>
						</BarChart>
					):
					(
						<LineChart data={data} style={{fontSize: "80%", marginLeft: "-15px"}}>
								<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter} />
								<YAxis tickFormatter={NumberFormater} width={48} />
								<Tooltip
									labelFormatter={labelFormatter}
									formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
								/>
								<Line dataKey={dataKey} type="monotone" dot={false} strokeWidth={2} />
							</LineChart>
					)
				}
			</ResponsiveContainer>
		);
	}
};
