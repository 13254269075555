import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, Image, Popup } from "semantic-ui-react";
import Number from "./shared/Number";
import DateCell from "./shared/DateCell";
import eye from "../assets/images/eye@2x.png";
import { adjustWithDecimals, formatNumber, numberToReadableFormat } from "../utils";

const setToFixed = (val, decimals) => {
    if (val) {
        let value = val / Math.pow(10, decimals);
        return value.toFixed(decimals);
    }
    return val;
};

const redirectTo = (id) => {
	return window.location.href = `/tokens/${id}`;
};

const TokenRow = ({ token, isShowType }) => (
    <Table.Row className="clickable hoverRow" onClick={()=> redirectTo(token.id)}>
        <Table.Cell collapsing>
            <div style={{ display: "flex", alignItems: "center" }}>
                {token && token.thumbnail && (
                    <img style={{ height: 20, width: 20, marginRight: 5 }} src={token.thumbnail} />
                )}
                {token.id}
            </div>
        </Table.Cell>
        { isShowType && (
            <Table.Cell collapsing>{token.tokenType == 'FUNGIBLE_COMMON' ? 'FUNGIBLE' : 'NON FUNGIBLE' }</Table.Cell>
        )}
        <Table.Cell width={3} collapsing style={{ overflow: "hidden", maxWidth: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{token.name}</Table.Cell>
        <Table.Cell width={3} collapsing style={{ overflow: "hidden", maxWidth: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{token.symbol}</Table.Cell>
        <Table.Cell collapsing>
            <DateCell date={token.createdOn} />
        </Table.Cell>
        {/* <Table.Cell collapsing>{token.decimals}</Table.Cell> */}
        {/* <Table.Cell collapsing title={formatNumber(adjustWithDecimals(token.supply, token.decimals))}>
			<Number>{adjustWithDecimals(token.supply, token.decimals)}</Number>
		</Table.Cell> */}
        { (token.decimal <= 20 || token.decimals <= 20)
            ?   <Table.Cell title={formatNumber(token.supply)}>
                    <Number>{adjustWithDecimals(token.supply, token.decimal || token.decimals)}</Number>
                </Table.Cell>
            :   <Table.Cell title="Invalid">
                    <span style={{color: 'red'}}> Invalid </span>
                </Table.Cell>
        }
        {/* <Table.Cell textAlign="center">
            <Link to={`/tokens/${token.id}`}>
                <Popup
                    content="View Token Details"
                    trigger={<Image src={eye} rounded size="mini" style={{ width: "28px" }} />}
                />
            </Link>
        </Table.Cell> */}
    </Table.Row>
);

export default TokenRow;

TokenRow.propTypes = {
    token: PropTypes.object.isRequired,
};
