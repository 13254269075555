/* local storage key*/
export const DG_USER = '_dguser';

/* Google Analytics */
//export const GA_TRACKING_ID = 'UA-148177171-1'; //dev
export const GA_TRACKING_ID = getGAKey(); //prod

/* for nodes and counts */
export const REFRESH_INTERVAL = 10000; //10 secs

/* ADV SEARCH */
export const serviceTypes = 'MOST ACTIVE HEDERA SERVICES';
export const CRYPTO = 'CRYPTO';
export const CONTRACT = 'CONTRACT';
export const FILE = 'FILE';

export const transactionTypes = 'MOST ACTIVE TRANSACTION TYPES';
export const CONTRACT_CALL = 'CONTRACT CALL';
export const CONTRACT_CREATE = 'CONTRACT CREATE';
export const CONTRACT_UPDATE = 'CONTRACT UPDATE';
export const CREATED_BY = 'CREATED BY';
export const Owners = 'TOP NFT OWNERS';
export const Tokens = 'TOP NFT COLLECTIONS';
export const Created_By = 'CREATOR ACCOUNT TOPICS';
export const Treasury_Account = 'TOP TOKEN TREASURY ACCOUNTS';

// Accounts
export const accountsPayerID = 'TOP CREATORS OF HEDERA ACCOUNT';
export const contractsPayerID = 'TOP CREATORS OF CONTRACTS';
export const topicsPayerID = 'TOP CREATORS OF TOPICS';

// export const CONTRACT_DELETE = 'CONTRACT_DELETE';
export const CRYPTO_TRANSFER = 'CRYPTO TRANSFER';
export const CRYPTO_CREATE_ACCOUNT = 'CRYPTO CREATE ACCOUNT';
export const CRYPTO_UPDATE_ACCOUNT = 'CRYPTO UPDATE ACCOUNT';
// export const CRYPTO_DELETE_ACCOUNT = 'CRYPTO_DELETE_ACCOUNT';
export const FILE_APPEND = 'FILE APPEND';
export const FILE_CREATE = 'FILE CREATE';
export const FILE_DELETE = 'FILE DELETE';
export const FILE_UPDATE = 'FILE UPDATE';
// export const SYSTEM_DELETE = 'SYSTEM_DELETE';
// export const SYSTEM_UNDELETE = 'SYSTEM_UNDELETE';
// export const CONSENSUS_CREATE_TOPIC = 'CONSENSUS_CREATE_TOPIC';
// export const CONSENSUS_UPDATE_TOPIC = 'CONSENSUS_UPDATE_TOPIC';
// export const CONSENSUS_DELETE_TOPIC = 'CONSENSUS_DELETE_TOPIC';
// export const CONSENSUS_SUBMIT_MESSAGE = 'CONSENSUS_SUBMIT_MESSAGE';
// export const TOKEN_CREATE = 'TOKEN_CREATE';
// export const TOKEN_UPDATE = 'TOKEN_UPDATE';
// export const TOKEN_MINT = 'TOKEN_MINT';
// export const TOKEN_BURN = 'TOKEN_BURN';
// export const TOKEN_TRANSFER = 'TOKEN_TRANSFER';
// export const TOKEN_WIPE_ACCOUNT = 'TOKEN_WIPE_ACCOUNT';
// export const TOKEN_DELETE = 'TOKEN_DELETE';
// export const TOKEN_FREEZE_ACCOUNT = 'TOKEN_FREEZE_ACCOUNT';
// export const TOKEN_UNFREEZE_ACCOUNT = 'TOKEN_UNFREEZE_ACCOUNT';
// export const GRANT_KYC = 'GRANT_KYC';
// export const REVOKE_KYC = 'REVOKE_KYC';
// export const TOKEN_ASSOCIATE_ACCOUNT = 'TOKEN_ASSOCIATE_ACCOUNT';
// export const TOKEN_DISASSOCIATE_ACCOUNT = 'TOKEN_DISASSOCIATE_ACCOUNT';

export const payer = 'PAYER';
export const payerID = 'CREATOR ACCOUNT';
export const transactionsPayerID = 'MOST ACTIVE PAYOR ACCOUNTS';
export const contractID = 'CONTRACT ID';
// export const DG_MERCHANT_ID = '0.0.281525';
export const MONTHLY = 'MONTHLY';
export const YEARLY = 'YEARLY';
// Path for fungible token
export const FUNGIBLE_TOKEN = '/tokens/fungible'
export const NON_FUNGIBLE_TOKEN = '/tokens/non-fungible'
// export const SUBSCRIPTION_PRICING = [
//     {
//         text: "1-5K",
//         units: 1000,
//         totalCost: 0.5,
//     },
//     {
//         text: "5-10K",
//         units: 5000,
//         totalCost: 0.69,
//     },
//     {
//         text: "10-50K",
//         units: 10000,
//         totalCost: 2.19,
//     },
//     {
//         text: "50-100K",
//         units: 50000,
//         totalCost: 9.69,
//     },
//     {
//         text: "100-300K",
//         units: 100000,
//         totalCost: 19.06,
//     }
// ]


function getGAKey() {
  const subdomain = getSubdomain();
  switch (subdomain) {
    case 'app':
        return 'UA-148423726-1';
    case 'testnet':
        return 'UA-148423726-2';
    default:
        return 'UA-148423726-3';
  }
}

function getSubdomain() {
    return window.location.hostname.split('.')[0];
}