import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AccountSummaryTable from '../AccountSummaryTable';
import CodeSnippet from '../shared/CodeSnippet';
import * as actions from '../../redux/actions';
import { getAccount } from '../../redux/reducers';

class DappAccountSummary extends Component {
    state = {
        showKey: false,
    };
    

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.accountNumber !== prevProps.accountNumber) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchAccount, fetchAccountBalance, fetchAccountRecentTransactionsCount, accountNumber } = this.props;
		fetchAccountBalance(accountNumber);
		fetchAccountRecentTransactionsCount(accountNumber);
        fetchAccount(accountNumber);
	}

	onShowKey = () => {
		this.setState({ showKey: true });
	};

	onHideKey = () => {
		this.setState({ showKey: false });
	};

	render() {
        const { account, id } = this.props;
        this.props.account && this.props.account.balance && console.log("ACCOUNT NUMBER =", account)
        const { showKey } = this.state;
		return (
			<React.Fragment>
				<AccountSummaryTable account={{ ...account, id }} onShowKey={this.onShowKey} />
				{account &&
				showKey && <CodeSnippet code={account.publicKey} onClose={this.onHideKey} title="Public Key(s)" />}
			</React.Fragment>
		);
	}
}


const mapStateToProps = (state, ownProps) => ({
	id: ownProps.accountNumber,
	account: getAccount(state, ownProps.accountNumber)
});

DappAccountSummary = withRouter(connect(mapStateToProps, actions)(DappAccountSummary));

export default DappAccountSummary;
