import moment from 'moment';
import { toast } from 'react-toastify';
import { MONTHLY, YEARLY } from "../constants";
import queryString from 'querystring';
import md5 from 'md5';
import { fetchUserInvoices } from "../apis";
import { Base64 } from 'js-base64';

export const notify = toast;
let merchantAccountId = null;

const regex = /(\d)(?=(\d{3})+(?!\d))/g;
const applyRegex = (value) => value.replace(regex, '$1,');
//export const formatNumber = (num = 0) => applyRegex(num.toString());
export const formatCurrency = (num = 0) => '$' + applyRegex(num.toFixed(2));
const removeTrailingZeros = (num = 0) => {
	if (!num) return 0;
	return parseFloat(num).toString();
}
export const formatNumber = (num = 0, enableToFixed = false, removeDecimalSpace = false) => {
	if (num === null || num === 'null' || (!isNaN(num) && parseFloat(num) === 0)) {
		return 0;
	}
	num = parseFloat(num).toFixed(8);
	let numParts = enableToFixed && num < 9 ? num.split('.') : removeTrailingZeros(num).split('.');
	if (numParts[0].length >= 4) {
		numParts[0] = numParts[0].replace(regex, '$1,');
	}
	if (numParts[1] && numParts[1].length >= 4) {
		numParts[1] = removeDecimalSpace ? numParts[1].replace(/(\d{3})/g, '$1') : numParts[1].replace(/(\d{3})/g, '$1 ');
	}
	return numParts.join('.').trim();
};
// export const formatCurrency = (num = 0) => num.toLocaleString(undefined, { style: 'currency', currency: 'USD' });

export const numberToReadableFormat = (num) => {
	return num.toLocaleString('en-US');
};

export const formatTimestamp = (timestamp, period) => {
	let time = null;
	if (timestamp && (period === '1W' || period === '1M' || period === '1Y')) {
		time = moment.utc(timestamp.split('T')[0]);
	} else {
		time = moment(timestamp);
	}
	switch (period) {
		case '1H':
			return time.utc().format('H:mm');
		case '1D':
			return time.utc().format('H:mm');
		case '1W':
			return time.format('MM-DD-YYYY');
		case '1M':
			return time.format('MM-DD-YYYY');
		case '1Y':
			return time.format('MMM');
		default:
			return time.format('DD MMM YY');
	}
};

export const toEpoch = (date) => moment(date, 'YYYY-MM-DD').unix();
export const fromDate = (date) => moment(date).format('MM-DD-YYYY');
// export const fromTime = (date) => moment(date).utc().format('LTS');
export const fromTime = (date) => moment(date).format('LTS');

export const createQuery = (params) => {
	const esc = encodeURIComponent;
	return Object.keys(params).map((k) => esc(k) + '=' + esc(params[k])).join('&');
};

export const isEmail = (email) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false);

export const isHederaAccount = (val) =>
	val && val.trim().length && /^(([0-9]\.[0-9]\.[0-9]+)?)$/.test(val) ? true : false;

export const isHederaReservedAccount = (val) =>
	val && val.trim().length && /^\d[.]\d[.][1-9][0-9](?!00$)[0-9][1-9]?\d+$/.test(val) ? false : true;

export const toHbar = (val) => {
	let value = Number((val / Math.pow(10, 8)))
	return value.toFixed(8)
};

export const toTbar = (val) => Number(val * Math.pow(10, 8));

export const toCurrency = (val = 0, currencyCode) => {
	const num = formatNumber(val);
	switch (currencyCode) {
		case 'usd':
			return `$ ${num}`;
		case 'tbar':
			return `tℏ ${num}`;
		default:
			return `ℏ ${num}`;
	}
};

export const removeEmpty = (obj) =>
	Object.keys(obj)
		.filter((k) => obj[k] !== null && obj[k] !== '') // Remove undef. and null.
		.reduce(
			(newObj, k) =>
				typeof obj[k] === 'object'
					? { ...newObj, [k]: removeEmpty(obj[k]) } // Recurse.
					: { ...newObj, [k]: obj[k] }, // Copy value.
			{}
		);

export const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));

export const isMobile = () => {
	let check = false;
	(function(a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
				a.substr(0, 4)
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
};

export const calculatePaymentUrlParams = (options) => { //(userId, amount, units, type, nextSubscriptionStartDate, invoiceId, invoiceStartDate, changeInvoiceStartDate, currentPage) => {
	let subscriptionStartDate = moment().utc().format("MMM DD, YYYY");
	let paymentInfo = {
		id: options.userId,
		units: options.units,
		amount: options.cost,
		period: options.period
	}
	if (options.nextSubscriptionStartDate && !options.startFromToday) {
		subscriptionStartDate = moment.utc(options.nextSubscriptionStartDate, 'YYYY-MM-DD').format("MMM DD, YYYY");
		if (options.changeInvoiceStartDate) {
			paymentInfo["subscriptionStart"] = moment.utc(options.nextSubscriptionStartDate, 'YYYY-MM-DD').unix();
		}
	}
	let description = "";
	if (options.period === "MONTHLY") {
		description = `Monthly subscription of ${numberToReadableFormat(options.units)} units starting from ${subscriptionStartDate}`;
	} else if (options.period === "YEARLY") {
		description = `Yearly subscription of ${numberToReadableFormat(options.units)} units starting from ${subscriptionStartDate}`;
	} else if (options.period === "BULK") {
		description = `Bulk purchase of ${numberToReadableFormat(options.units)} units`;
	}
	if (!options.startFromToday) {
		if (options.invoiceId) {
			paymentInfo["invoiceId"] = options.invoiceId;
		}
		if (options.invoiceStartDate) {
			paymentInfo["invoiceStartDate"] = options.invoiceStartDate;
		}
	}
	if (!Object.keys(options.envConfigs).length) {
		options['envConfigs'] = {};
	}
	// if (!options.envConfigs.host) {
	// 	options['envConfigs']['host'] = 'https://hashscan.dragonglass.me';
	// }
	// if (!options.envConfigs.merchantId) {
	// 	options['envConfigs']['merchantId'] = DG_MERCHANT_ID;
	// }

	console.log(paymentInfo);
	const merchantReference = {'userId': options.userId, 'currentTime': moment.utc().unix(), 'randomNumber': Math.floor(Math.random() * 10000)};
	const url = {
			amount: options.cost,
			type: "DATA",
			merchantAccount: options.envConfigs.merchantId,
			// reference: "sdgfhdsiu",
			reference: md5(JSON.stringify(merchantReference)),
			currency: "USD",
			thumbnail: `${options.envConfigs.host}/logo_dragonglass.png`,
			description: description,
			// url: "http://localhost:3001/api/dropp/" + btoa(JSON.stringify(paymentInfo)) + "/p2p"
			url: `${options.envConfigs.host}/api/dropp/${btoa(JSON.stringify(paymentInfo))}/p2p`
	};
	console.log(url);
	const esc = encodeURIComponent;
	const validQueryParams = Object.keys(url)
	.reduce((newObj, k) => ({ ...newObj, [k]: url[k] }), {});
	const queryStr = Object.keys(validQueryParams)
		.map(p => esc(p) + '=' + esc(validQueryParams[p]))
		.join('&');
	return queryStr;
};

export const parseQueryParams = (queryparams) => {
	if (!queryparams) {
		return {};
	}
	queryparams = queryparams.replace('?', '');
	return queryString.parse(queryparams);
};

export const getLastSubscription = (invoices) => {
	let lastSubscription = {}
	for (let i = 0; i < invoices.length; i++) {

		if (invoices[i].status === "PAID" && (invoices[i].period === MONTHLY || invoices[i].period === YEARLY)) {
			lastSubscription = invoices[i];
			break;
		}
	}
	return lastSubscription;
};

export const getSubscriptionPricing = (pricingPlans) => {
	let subscriptionPricing = [];
	for (let i = 0; i < pricingPlans.length; i++) {
			if (pricingPlans[i].type === "SUBSCRIPTION") {
					if (pricingPlans[i].cost) {
							pricingPlans[i].totalCost = parseFloat(pricingPlans[i].cost);
					}
					subscriptionPricing.push(pricingPlans[i]);
			}
	}
	if (subscriptionPricing.length) {
		subscriptionPricing = subscriptionPricing.sort((a, b) => {
			return a.units - b.units;
		});
	}
	return subscriptionPricing;
};

const open = (queryString) => {
	if (window && window.Dropp && window.Dropp.isDroppInitiated()) {
		window.Dropp.payWithDropp(queryString);
	} else {
		setTimeout(() => { open(queryString); }, 100);
	}
};

const prepareUrlAndOpenWallet = (options) => { // (userId, cost, units, period, nextSubscriptionStartDate, invoiceId, invoiceStartDate, showConfirmation, changeInvoiceStartDate, currentPage) => {
	console.log(options.showConfirmation);
	// const droppPurchaseUrl = calculatePaymentUrlParams(userId, cost, units, period, nextSubscriptionStartDate, (invoiceId ? invoiceId : 0), invoiceStartDate, changeInvoiceStartDate, currentPage);
	const droppPurchaseUrl = calculatePaymentUrlParams(options);
	if (options.showConfirmation && options.nextSubscriptionStartDate) {
		if (window.confirm(`You already have paid for current period. The new start date will be expiry of the most recent paid invoice i.e. ${moment.utc(options.nextSubscriptionStartDate, "YYYY-MM-DD").format("MMM DD, YYYY")}. Do you want to continue?`)) {
			open(droppPurchaseUrl);
		}
	} else {
		open(droppPurchaseUrl);
	}
};

export const payWithLink = (e, options) => { // userId, userEmail, cost, units, period, currentPage, showConfirmation, changeInvoiceStartDate, invoiceId, invoiceStartDate) => {
	if (options.period === MONTHLY || options.period === YEARLY) {
		const merchantId = options.envConfigs.merchantId;
		fetchUserInvoices({merchantId: merchantId, userId: options.userId, email: options.userEmail, pageSize: 50, sortOn: "expiry_date"}).then((resp) => {
			const activeInvoice = (resp && resp.data.length) ? getLastSubscription(resp.data) : {};
			options['nextSubscriptionStartDate'] = '';
			if (activeInvoice && activeInvoice.expiryDate && (moment.utc(activeInvoice.expiryDate).unix() > moment.utc().unix())) {
				options['nextSubscriptionStartDate'] = moment.utc(activeInvoice.expiryDate).format('YYYY-MM-DD');
			}
			fetchUserInvoices({merchantId: merchantId, userId: options.userId, email: options.userEmail, unpaidOnly: true}).then((result) => {
				if (result && result.data.length) {
					const unpaidInvoice = result.data[0];
					options['invoiceId'] = unpaidInvoice.id;
					options['invoiceStartDate'] = unpaidInvoice.startDate;
					prepareUrlAndOpenWallet(options);
				} else {
					prepareUrlAndOpenWallet(options);
				}
			}).catch((err) => {
				prepareUrlAndOpenWallet(options);
			});
		}).catch(err => {
				console.log(err);
		});
	} else {
		prepareUrlAndOpenWallet(options);
	}

	e.preventDefault();
	e.stopPropagation();
};

export const setMerchantAccountId = (id) => merchantAccountId = id;
export const getMerchantAccountId = () => merchantAccountId;
export const adjustWithDecimals = (val, decimals) => {
	if (val && decimals) {
		val /= Math.pow(10, decimals);
	}
	return val;
};

export const decodeBase64 = (str) => {
	let decodedStringAtoB  = '';
	if (str) {
		decodedStringAtoB = Base64.decode(str);
	}
	return decodedStringAtoB;
};

export const isImgUrl = (url) => {
	return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url)
}

export const isRegexValidForId = (string) => {
	const regex = /^0\.0/;
	return regex.test(string)
}

export const localDateTime = (date, format) => {
	const tz = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
	let timeZone = 'EST';
	if (tz) {
		switch (tz) {
			case '+0530':
				timeZone = 'IST';
				break;
			case '-0400':
				timeZone = 'EDT';
				break;
			case '-0500':
				timeZone = 'EST';
				break;
			case '-0600':
				timeZone = 'CST';
				break;
			case '-0700':
				timeZone = 'MST';
				break;
			default:
				timeZone = 'EST';
				break;
		}
	}
	let formattedDate = date ? `${moment(date).format('MMM DD YYYY, HH:mm A z')} ${timeZone}` : 'N/A';
	switch (format) {
		case 'dateOnly':
			formattedDate = date ? `${moment(date).format('MMM DD YYYY')}` : 'N/A';
			break;
		case 'timeOnly':
			formattedDate = date ? `${moment(date).format('HH:mm A z')} ${timeZone}` : 'N/A';
			break;
		default:
			formattedDate = date ? `${moment(date).format('MMM DD YYYY, HH:mm A z')} ${timeZone}` : 'N/A';
			break;
	}
	return formattedDate;
}

export const divideArrayIntoMany = (array, divideValue) => {

	const chunkSize = Math.ceil(array.length / divideValue);
	const dividedArrays = [];

	for (let i = 0; i < array.length; i += chunkSize) {
	  const chunk = array.slice(i, i + chunkSize);
	  dividedArrays.push(chunk);
	}

	return dividedArrays;
  };

export const isSerialNumber = (text) => {
	const regex = /^#\d+$/;
	return regex.test(text)
}

export const isId = (text) => {
	const regex = /^0.0.[0-9]*/;
	return regex.test(text)
}

export const isNft = (text) => {
	const regex = /^0.0.[0-9]*#\d+$/;
	return regex.test(text)
}

export const removeEmptyFields = (obj) => {
    const newObj = {};
	if (obj) {
		Object.keys(obj).forEach((key) => {
		  if (obj && key && obj[key] && obj[key].trim() !== '') {
			newObj[key] = obj[key];
		  }
		});
	}
    return newObj;
  };

export const isEmpty = (obj) => {
	return obj && Object.keys(obj).length === 0;
};

export const getEnviornment = () => {
	let enviornment = 'hashscan';
	let baseUrl = 'https://hashscan.dragonglass.me/';
	if (window && window.location && window.location.origin) {
		console.log('window', window.location);
		if (window.location.origin.includes('testnet')) {
			enviornment = "testnet";
			baseUrl = "https://testnet.dragonglass.me/";
		} else if (window.location.origin.includes('app.dragonglass.me')) {
			enviornment = "mainnet";
			baseUrl = "https://app.dragonglass.me/";
		}
	}
	return {enviornment, baseUrl};
};

export const injectDroppSdk = () => {
	const envConfigs = getEnviornment();
	let sdkHost = 'https://merchant.portal.dropp.cc';
	switch (envConfigs.enviornment) {
		case "hashscan":
			sdkHost = 'https://merchantportal.qa.dropp.cc';
			break;
		case "testnet":
			sdkHost = 'https://sandbox.merchantportal.dropp.cc';
			break;
		default:
			sdkHost = 'https://merchant.portal.dropp.cc';
	}
	const sdkUrl = `${sdkHost}/dropp-sdk/dropp.min.js?qrCode=true`;

	const existingScript = document.querySelectorAll(`script[src*="${sdkUrl}"]`);
	if (existingScript && existingScript.length === 0) {
			let scriptElem = document.createElement("script");
			scriptElem.src = sdkUrl;
			document.body.appendChild(scriptElem);
	}
};