import React from 'react';
import '../assets/css/components/shimmers.scss';

export default function Shimmer (props) {
	const  { title, data, children, footer, noTitle= false } = props;
	const renderShimmerColumns = () => {
		const shimmerColumns = [];
		for (let i = 0; i < 4; i++) {
		  shimmerColumns.push(
			<div className="shimmer-column" key={i}>
			  <div className="shimmer-image" />
			  <div className="shimmer-text" />
			</div>
		  );
		}
		return shimmerColumns;
	  };
	return (
		<React.Fragment>
			<div className="shimmer-wrapper shimmer-grid shimmer">
				{renderShimmerColumns()}
			</div>
		</React.Fragment>
	);
}