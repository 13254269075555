import React, { useState } from 'react';
import AbiUploadForm from './forms/AbiUploadForm';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getUser } from '../redux/reducers';

const AbiUpload = ({ handleCancel, /*handleMessage,*/ createNewDapp, user, uploadFiles, subscriptionId, uploadAbi, handleAbiMessage }) => {

    const [form, setForm] = useState({});

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const [abiFileName, setAbiFileName] = useState('');
    // const [sourceCodeFileName, setSourceCodeFileName] = useState('');
    // const [byteCodeFileName, setByteCodeFileName] = useState('');
    // const [logoFileName, setLogoFileName] = useState('');

    // const onChange = e => {
    //     setForm({ 
    //         ...form, 
    //         [e.target.name]: e.target.value, 
    //         id: form.title.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s+/g, '').toLowerCase(),
    //         userName: user.name, 
    //         userId: user.id, 
    //         userEmail: user.email, 
    //         userCompany: user.company
    //     });
    // }

    const onSubmit = async e => {
        console.log('ON SUBMIT IS CALLED')
        e.preventDefault();
        // const errors = validate(form);
        // setErrors(errors);
        let files = {};
        acceptedFiles.forEach(file => files[Object.keys(file)[0]] = file[Object.keys(file)[0]])
        handleCancel();
        uploadAbi(subscriptionId, files, acceptedFiles[0].abiFile.name).then(
            (response) => {
                console.log("RESPONSE IN ABIUPLOAD.JS: ", response);
                handleAbiMessage(acceptedFiles[0].abiFile.name, response);
            }
        );
        console.log("THIS IS THE ENTIRE FORM: ", files, acceptedFiles[0].abiFile.name)
    }

    const onDrop = (file) => {
        console.log(file)
        console.log(acceptedFiles)

        setAcceptedFiles([...acceptedFiles, file ])
    }

    // const onUpload = (e) => {
    //     console.log("ACCEPTED FILES: ", acceptedFiles)
    //     uploadFiles(form.id, acceptedFiles)
    // }


    const validate = (form) => {
        const { company, title, description, accountId, contractId, website, androidAppLink, iosAppLink } = form;
        let errors = {};
        if (!company || company.trim().length === 0) errors.company = "Company name can't be blank";
        if (!title || title.trim().length === 0) errors.title = "Title can't be blank";
        if (!accountId || accountId.trim().length === 0) errors.accountId = "Account Id can't be blank";
        // if (!contractId || contractId.trim().length === 0) errors.contractId = "Contract Id can't be blank";

        return errors;
    }

    // const { company, title, description, accountId, contractId, website, androidAppLink, iosAppLink, deployed } = form;

    return (
        <AbiUploadForm 
            // company={company}
            // title={title}
            // description={description}
            // accountId={accountId}
            // contractId={contractId}
            // website={website}
            // androidAppLink={androidAppLink}
            // iosAppLink={iosAppLink}
            // deployed={deployed}
            handleCancel={handleCancel} 
            // handleMessage={handleMessage}
            // onChange={onChange}
            onSubmit={onSubmit}
            errors={errors}
            loading={loading}
            onDrop={onDrop}
            acceptedFiles={acceptedFiles}
            // onUpload={onUpload}
            abiFileName={abiFileName}
            setAbiFileName={setAbiFileName}
            // sourceCodeFileName={sourceCodeFileName}
            // setSourceCodeFileName={setSourceCodeFileName}
            // byteCodeFileName={byteCodeFileName}
            // setByteCodeFileName={setByteCodeFileName}
            // logoFileName={logoFileName}
            // setLogoFileName={setLogoFileName}

        />
    )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default connect(mapStateToProps, actions)(AbiUpload);
