import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDapp } from '../redux/reducers';
import ThinPanel from './shared/ThinPanel';
import SettingsDropdown from './shared/SettingsDropdown';
import Info from './shared/Info';

 const DappLogoPanel = (props) => {

    const image = props.dapp && (props.dapp.logoURL ? <img className="logo-image" src={props.dapp.logoURL} /> : props.dapp.title);

    const logo = props.dapp && 
        <div className="sample-logo">
            <div className="logo-container">{image}</div>
            <div style={{height: "60px", borderLeft: "2px solid #B3B3B3", marginRight: "18px"}}></div>
            <div style={{fontSize: "90%", marginTop: "-7px", fontWeight: "600"}}>
                <div>{props.dapp.title}</div>
                <div><a href={props.dapp.website} target="_blank">{props.dapp.website}</a></div>
            </div>
        </div>

	return (
		<ThinPanel>
            <div className="dapp-logo">
                <div>{logo}</div>
                <div id="dapp-setting"><SettingsDropdown /></div>
            </div>
		</ThinPanel>
	);
} 

const mapStateToProps = (state, { match: { params: { dappId } } }) => ({
	id: dappId,
	dapp: getDapp(state, dappId)
});

export default withRouter(connect(mapStateToProps)(DappLogoPanel));