import React from 'react';
import { Placeholder, Header } from 'semantic-ui-react';

export default function PlaceHolder() {
	return (
		<React.Fragment>
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
			<Header as="h5">Coming soon</Header>
		</React.Fragment>
	);
}
