import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Table, Checkbox, Button, Input, Grid, Dropdown, StrictFormInputProps, Message, CommentContent, Icon } from 'semantic-ui-react';
import { getUser } from '../../redux/reducers';

export const SubscriptionFiltersRemoveForm = ({
    subscription, 
    // subscriptionFilters,
    handleCancel,
    filters,
    signature,
    errors, 
    onFilterTypeSelect, 
    handleFilterValues, 
    deleteFilter,
    handleSignature,
    deleteSignature,
    onTransactionTypeSelect, 
    onSubmit, 
    handleSubscribeMessage}) => {

    console.log("THIS IS THE SUBSCRIPTION IN THE REMOVE FILTERS: ", subscription);

    const isError = Object.keys(errors).length !== 0;

    // const inputFilterValueKeyUp = e => {
    //     e.which = e.which || e.keyCode;
    //     if(e.which == 13) {
    //         console.log(e.target.value);
    //         handleFilterValues(e.target.value);
    //         e.target.value = '';
    //     }
    // }

    // const inputSignatureKeyUp = e => {
    //     e.which = e.which || e.keyCode;
    //     if(e.which == 13) {
    //         console.log(e.target.value);
    //         handleSignature(e.target.value);
    //         e.target.value = '';
    //     }
    // }

    return (
        <Fragment>
        <Grid padded stackable>

            <Grid.Row columns="2">
                <Grid.Column width={10}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>SUBSCRIPTION TYPE</span>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Input  error={isError}
                        id="subscriptionType"
                        name="subscriptionType"
                        fluid
                        // icon="building"
                        // iconPosition="left"
                        // placeholder="Company"
                        autoFocus
                        value={subscription.subscriptionType}
                        // onChange={onChange}
                        error={!!errors.subscriptionType}
                        autoComplete="subscriptionType"
                        required={false}
                    />
                {isError && <Message error content={errors.queueName} className="animated fadeIn" />}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns="2">
                <Grid.Column width={10}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>SUBSCRIPTION ID</span>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Input  error={isError}
                        id="id"
                        name="id"
                        fluid
                        // icon="building"
                        // iconPosition="left"
                        // placeholder="Company"
                        autoFocus
                        value={subscription.id}
                        // onChange={onChange}
                        error={!!errors.id}
                        autoComplete="id"
                        required={false}
                    />
                {isError && <Message error content={errors.queueName} className="animated fadeIn" />}
                </Grid.Column>
            </Grid.Row>

            {/* <Grid.Row columns="2">
                <Grid.Column width={10}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>FILTER TYPE</span>
                </Grid.Column>
                <Grid.Column width={6}>
                <Dropdown
                    placeholder='Filter Type'
                    fluid
                    selection
                    options={filterTypeOptions}
                    onChange={e => {onFilterTypeSelect(e); displayRow(e)}}
                />
                </Grid.Column>
            </Grid.Row> */}

            <Grid.Row columns="2">
                <Grid.Column width={10}>
                <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>FILTER TYPE AND ID</span>
                </Grid.Column>
                <Grid.Column width={6}>
                    {/* <Input  error={isError}
                        id="filterValue"
                        name="filterValue"
                        fluid
                        // icon="building"
                        // iconPosition="left"
                        placeholder={placeholder}
                        autoFocus
                        // value={}
                        // onChange={onChange}
                        // error={!!errors.queueName}
                        autoComplete="filterValue"
                        required={false}
                        onKeyUp={e => inputFilterValueKeyUp(e)}
                    /> */}
                    {filters.map((filter) =>
                        <Input
                            style={{marginTop: "5px"}}
                            key={Math.random()}
                            icon={<Icon name='close' link={true} onClick={e => deleteFilter(filter, subscription)} />}
                            value={`${filter.filterType}, ${filter.filterId}`}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>

            {/* {displaySignatures && <Grid.Row columns="2">
                <Grid.Column width={10}>
                <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>SIGNATURES</span>
                </Grid.Column>
                <Grid.Column width={6}>
                <Input  error={isError}
                        id="signature"
                        name="signature"
                        fluid
                        // icon="building"
                        // iconPosition="left"
                        placeholder="Enter a signature and press Enter"
                        autoFocus
                        // value={}
                        // onChange={onChange}
                        // error={!!errors.queueName}
                        autoComplete="signature"
                        required={false}
                        onKeyUp={e => inputSignatureKeyUp(e)}
                    />
                    {signature.map((sign) =>
                        <Input
                            style={{marginTop: "5px"}}
                            key={Math.random()}
                            icon={<Icon name='close' link={true} onClick={e => deleteSignature(sign)} />}
                            value={sign}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>} */}

        </Grid>

        <Button 
            type="submit" 
            style={{margin: "30px 0 20px 0", float: "right"}} 
            primary 
            // disabled={ids.length === 0} 
            onClick={e => {/*onSubmit(e);*/ handleCancel()}}
            >
            CLOSE
        </Button>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default withRouter(connect(mapStateToProps, actions)(SubscriptionFiltersRemoveForm));
