import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Stat from "../shared/Stat";
import LineChart from "../shared/LineChart";
import * as actions from "../../redux/actions";
import { getAccountCreationVolume } from "../../redux/reducers";
import { formatTimestamp } from "../../utils";
import LoadingIndicator from "./LoadingIndicator";

class AccountCreationVolumeChart extends Component {
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.period !== prevProps.period) {
            this.fetchData();
        }
    }

    fetchData() {
        const { fetchAccountCreationVolume, period, transactionType } = this.props;
        fetchAccountCreationVolume(period, transactionType);
    }
    render() {
        const {
            volume: { totalSum, /* totalCount,*/ pctChange, data },
            period,
        } = this.props;
        const formatter = date => formatTimestamp(date, period);
        const valueFormatter = (value, name, props) => [new Intl.NumberFormat("en").format(value)];
        if (!this.props.volume.loading) {
            return (
                data && data.length ?
                    <Fragment>
                        <Stat count={totalSum} pct={pctChange} />
                        <br />
                        <LineChart
                            graphType={this.props.graphType}
                            data={data}
                            tickFormatter={formatter}
                            labelFormatter={formatter}
                            valueFormatter={valueFormatter}
                        />
                    </Fragment>
                :  <div>No transactions available for the selected period</div>
            );
        } else {
            return <LoadingIndicator />
        }
    }
}

const mapStateToProps = state => ({
    volume: getAccountCreationVolume(state),
});

AccountCreationVolumeChart = connect(mapStateToProps, actions)(AccountCreationVolumeChart);

export default AccountCreationVolumeChart;
