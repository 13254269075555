import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FeaturedDappsTable from '../FeaturedDappsTable';
import * as actions from '../../redux/actions';
import { getDapps } from '../../redux/reducers';
import { getUser } from '../../redux/reducers';
import { isAuthed } from "../../redux/reducers";
import { Header, /*Icon,*/ Image, Modal, Button } from 'semantic-ui-react';

import AppImage from '../../assets/images/App.png';

class FeaturedDapps extends Component {
	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		const { fetchDapps, fetchClientIp } = this.props;
		fetchDapps();
		this.props.user && this.props.captureLastLogin(this.props.user.id, new Date().toJSON());
		fetchClientIp();
	}

	render() {
		// console.log("DAPPS AND USER: ", this.props.dapps, this.props.user, new Date())
		return (
			<Fragment>
				<div style={{marginBottom: "40px"}}>
					{this.props.dapps.length > 0 && <FeaturedDappsTable dapps={this.props.dapps} />}
				</div>
				<Header as="h2" textAlign="center">
					<Header.Content>
						<div style={{marginBottom: "12px"}}>Got a DApp?</div>
						<Header.Subheader>Create a trusted home for your users on DragonGlass</Header.Subheader>
					</Header.Content>
				</Header>

				{/* <Modal
					trigger={<p className="text-center clickable colored-link">View Sample</p>}
					style={{ width: '90%' }}
					closeIcon
					dimmer="blurring"
				>
					<Modal.Header>Sample DApp Page</Modal.Header>
					<Modal.Content scrolling>
						<Image src={AppImage} />
					</Modal.Content>
				</Modal> */}

				<p className="text-center">
					<Link to="/contact-us">
						<Button primary size="small">
							CONTACT US
						</Button>
					</Link>
				</p>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	dapps: getDapps(state),
	user: getUser(state),
});

FeaturedDapps = connect(mapStateToProps, actions)(FeaturedDapps);

export default FeaturedDapps;
