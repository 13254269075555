import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import LineChart from '../shared/LineChart';
import BarChart from '../shared/BarChart';
import * as actions from '../../redux/actions';
import { getAccountBalanceHistory, getAccountBalanceHistoryLoader } from '../../redux/reducers';
import { formatTimestamp, parseQueryParams } from '../../utils';
import LoadingIndicator from "./LoadingIndicator";

class AccountBalanceChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period || this.props.id !== prevProps.id) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchAccountBalanceHistory, id, period } = this.props;
		fetchAccountBalanceHistory(id, period);
		this.props.sendAccountIdparam(id);
	}
	render() {
		const { balanceHistory: { data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [ new Intl.NumberFormat('en').format(value) + ' ℏ' ];
		if (!this.props.loading) {
			return (
				data && data.length ?
				<Fragment>
					{/* <Stat count={totalSum} pct={pctChange} money currency={'hbar'} /> */}
					<br />
					<BarChart
						data={data}
						tickFormatter={formatter}
						labelFormatter={formatter}
						valueFormatter={valueFormatter}
					/>
				</Fragment>
				: <div>History not available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => {
	const params = parseQueryParams(window.location.search);
	return ({
		id: accountId ? accountId : params.accountId,
		balanceHistory: getAccountBalanceHistory(state, accountId ? accountId : params.accountId),
		loading: getAccountBalanceHistoryLoader(state, accountId ? accountId : params.accountId),
	});
}

AccountBalanceChart = withRouter(connect(mapStateToProps, actions)(AccountBalanceChart));

export default AccountBalanceChart;
