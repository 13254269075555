import React, { Fragment } from 'react';
import Panel from '../atoms/Panel';
import { Table } from 'semantic-ui-react';


export const Card = (props) => {
	const  { title, data, children, footer, noTitle= false } = props;
	let datas = [{
		tokenId: '2131234',
		stakedTo: 'sdfsfsd',
	}]
	return (
		<React.Fragment>
			<Panel
			isHideHeader= {true}
			footer={footer}
			>
				{
					!noTitle ?
						<div style={{textAlign:"center", borderBottom: '1px solid #d4d4d5',}}>
							<h3 style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title || 'Card Title'}</h3>
						</div>
					: null
				}
				<div style={{ paddingTop: '10px' }}>
					{
					children ?
						children
						: <Table basic="very" unstackable>
							<Table.Body>
									{
										datas.length && datas.map(dt => {
											return (
												<Fragment>
													<Table.Row> 
														<Table.Cell>Token</Table.Cell>
														<Table.Cell>{dt.tokenId}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>stakedTo</Table.Cell>
														<Table.Cell>{dt.stakedTo}</Table.Cell>
													</Table.Row>	
												</Fragment>
														
											)
										})
									}
							</Table.Body>
						</Table>
					}
				</div>
			</Panel>
		</React.Fragment>
	);
}