import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import Nav from '../components/containers/Nav';
import NavBar from '../organisms/NavBar';
import Footer from '../components/Footer';

const AppliedRoute = ({ component: C, props: cProps, ...rest }) => {
	const redirectToPage = () => {
		window.location.href = "https://app.dragonglass.me/hedera/tokens/0.0.127877";
	};

	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					{(props.match && props.match.params && props.match.params.dappId == "tunefm") ? <>
							{redirectToPage()}
						</>
					:
						<React.Fragment>
							<div style={{position: "sticky", top: "0px", zIndex: "10"}} >
								{/* <NavBar {...props} {...cProps} {...rest}/> */}
								<Nav/>
							</div>
							<div>
								<C {...props} {...cProps} />
							</div>
							<div style={{marginTop: "20px"}}>
								<Footer />
							</div>
						</React.Fragment>
					}
				</>
			)}
		/>
	);
}

export default AppliedRoute;
