import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Grid } from 'semantic-ui-react';
import Page from '../shared/Page';
import APISelectPanel from '../APISelectPanel';
import APIPresentationPanel from '../APIPresentationPanel';
import APIIntroductionPanel from '../APIIntroductionPanel';
import { getAllApis } from '../../redux/reducers';
import { getCategories } from '../../redux/reducers';

const APIView = (props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchData() }, []);
  useEffect(() => { window.scrollTo(0, 0) }, []);

  const fetchData = () => {
    props.fetchApiList();
    props.fetchApiCategories();
    props.fetchApiParameters();
  }

  useEffect(() => {
    return () => {
      props.cleanUpApiParameters()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [activeCategoryId, setActiveCategoryId] = useState('0');

  let [activeCategoryTitle, setActiveCategoryTitle] = useState('');

  const handleCategorySelect = (category) => {
    setActiveCategoryId(category.id);
    setActiveCategoryTitle(category.title);
  }

  const panelToDisplay = (activeCategoryId === "0" ? <APIIntroductionPanel /> : <APIPresentationPanel id={activeCategoryId} title={activeCategoryTitle} />);

  return (
    <Page>
      <Grid padded stackable>
        <Grid.Column computer={5} tablet={16} mobile={16}>
          <div /*className="sticky"*/ style={{position: "sticky", top: "68px"}}>
            <Grid columns={1}>
              <Grid.Column>
                <APISelectPanel
                  handleCategorySelect={handleCategorySelect}
                />  
              </Grid.Column>
            </Grid>
          </div>
        </Grid.Column>
        <Grid.Column computer={11} tablet={16} mobile={16}>
          <Grid columns={1}>
            <Grid.Column>
              { panelToDisplay }
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Page>
  )
};

const mapStateToProps = (state) => ({
  apis: getAllApis(state),
  categories: getCategories(state)
});

export default connect(mapStateToProps, actions)(APIView);
