import { 
	CREATE_API_KEY_SUCCESS,
	CREATE_API_KEY_FAILURE,
	FETCH_API_KEY_SUCCESS,
	FETCH_API_KEY_FAILURE,
	ACTIVATE_API_KEY_SUCCESS,
	ACTIVATE_API_KEY_FAILURE,
	DEACTIVATE_API_KEY_SUCCESS,
	DEACTIVATE_API_KEY_FAILURE,
	DELETE_API_KEY_SUCCESS,
	DELETE_API_KEY_FAILURE
} from '../types';

export default function keys (state = [], action) {
	switch (action.type) {
		case CREATE_API_KEY_SUCCESS:
			return [ ...state, action.payload ];
		case FETCH_API_KEY_SUCCESS:
			return [ ...action.payload ];
		case CREATE_API_KEY_FAILURE:
		case FETCH_API_KEY_FAILURE:
			return [];

		case DEACTIVATE_API_KEY_SUCCESS:
			const keyToDeactivate = [...state].find(el => el.key === action.payload);
			const indexToDeactivate = [...state].indexOf(keyToDeactivate)
			const inactiveKey = {...keyToDeactivate, state: 'INACTIVE'} 
			return [...state].slice(0, indexToDeactivate).concat(inactiveKey).concat([...state].slice(indexToDeactivate + 1))
		case DEACTIVATE_API_KEY_FAILURE:
			return [...state];

		case ACTIVATE_API_KEY_SUCCESS:
			const keyToActivate = [...state].find(el => el.key === action.payload);
			const indexToActivate = [...state].indexOf(keyToActivate)
			const newKey = {...keyToActivate, state: 'ACTIVE'} 
			return [...state].slice(0, indexToActivate).concat(newKey).concat([...state].slice(indexToActivate + 1))
		case ACTIVATE_API_KEY_FAILURE:
			return [...state];

		case DELETE_API_KEY_SUCCESS:
			return [...state].filter(el => el.key !== action.payload);
		case DELETE_API_KEY_FAILURE:
			return [...state];

		default:
			return state;
	}
}

export const getKeys = (state) => state;