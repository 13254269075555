import React, { Component } from 'react';
import { Grid, Breadcrumb } from 'semantic-ui-react';
import Page from '../shared/Page';
import CountsPanel from '../CountsPanel';
import { parseQueryParams } from  '../../utils';
import { withRouter, Link } from 'react-router-dom';

export class TransactionCounts extends Component {
	render() {
		const params = parseQueryParams(this.props.location.search);
		return (
			<Page className="page-chart">
				<Grid>
					<Grid.Row  padded stackable columns={1}>
						<Grid.Column>
							<Breadcrumb style={{padding: '20px 0 0 10px'}}>
								<Breadcrumb.Section link> <Link to='home' >Home</Link></Breadcrumb.Section>
								<Breadcrumb.Divider />
								<Breadcrumb.Section active>Counts</Breadcrumb.Section>
							</Breadcrumb>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row columns={1}>
						<Grid.Column>
							{ params && params.name == 'transaction-counts'
								?  <CountsPanel />
								: ''
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Page>
		);
	}
};

export default withRouter(TransactionCounts);
