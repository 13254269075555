import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Table, Button, Form, Checkbox, Radio, Icon } from 'semantic-ui-react';
import { getUser } from '../redux/reducers';
import subscriptions from '../redux/reducers/subscriptions';

const DappContractsTableNew = ({
    contracts,
    dapp,
    handleAddContract,
    handleCancel,
    handleAddFilters, 
    filters,
    errors,
    deleteFilter,
    dappUpdateContract
}) => {

    console.log("DAPP IN DAPP CONTRACTS TABLE NEW: ", dapp)
    console.log("CONTRACTS IN DAPP CONTRACTS TABLE NEW: ", contracts)

    const [checkedValue, setCheckedValue] = useState(null);

    const [contractsToUpdate, setContractsToUpdate] = useState(contracts);

    useEffect(() => { contracts.map(contract => {
        if(contract.primary === true) setCheckedValue(contract.id)
    }) }, [])

    checkedValue !== "" && console.log("CHECKED VALUE AND DAPP ID: ", checkedValue, dapp.id)

    const handleCheck = async (id) => {
        setCheckedValue(id);
        let newContracts = [];
        contractsToUpdate.forEach(contract => {
            if (contract.id === id) {
                newContracts.push({ ...contract, primary: true })
            } else {
                newContracts.push({ ...contract, primary: false })
            }
            
        })
        console.log("NEW CONTRACTS: ", newContracts)
        setContractsToUpdate(newContracts)
    }

    const handleSave = e => {
        dappUpdateContract(checkedValue, dapp.id, contractsToUpdate).then(
            (response) => {
                console.log('RESPONSE IN DAPP CONTRACTS TABLE: ', response.data.data.contracts)
            }
        )
        window.location.reload();
    }


    const rowsToDisplay = contracts.map(contract => {
        return (
            <Table.Row key={Math.random()}>
                <Table.Cell width={3} textAlign="center">
                    <span className="text-gray">
                    <Form.Field>
                            <Radio
                                // label={<label style={{fontSize: "100%"/*, width: "100%"*/}}>{contract.id}</label>}
                                name='radioGroup'
                                value={contract.id}
                                checked={checkedValue===contract.id}
                                onChange={e => handleCheck(contract.id)}
                            />
                        </Form.Field>
                    </span>
                </Table.Cell>
                
                <Table.Cell width={3} textAlign="center"><span className="text-gray">{contract.id}</span></Table.Cell>

                {/* <Table.Cell
                    // className={sub.expiry.slice(0, 10) <= date && "disabled"}
                    style={{cursor: "pointer", textAlign: "center"}}
                    onClick={e => deleteFilter(filter, subscription)}
                    width={1}>
                    <i className="close icon red"></i>
                </Table.Cell> */}
            </Table.Row>
        )
    })

    return (
        <div>

            <Button 
                type="submit" 
                style={{margin: "20px 0 30px 0", float: "right"}} 
                primary 
                // disabled={ids.length === 0} 
                onClick={e => {/*onSubmit(e);*/ handleAddContract(dapp)}}
                >
                ADD CONTRACT
            </Button>

            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={3} textAlign="center">PRIMARY</Table.HeaderCell>
                    <Table.HeaderCell width={3} textAlign="center">CONTRACT ID</Table.HeaderCell>
                    {/* <Table.HeaderCell>Delete</Table.HeaderCell> */}
                </Table.Row>
                </Table.Header>

                <Table.Body>
                    {/* <Form> */}
                        {rowsToDisplay}
                    {/* </Form> */}
                </Table.Body>
            </Table>

            <span>
                <Button 
                    type="submit" 
                    style={{margin: "20px 0 30px 15px", float: "right"}} 
                    primary 
                    // disabled={ids.length === 0} 
                    onClick={handleCancel}
                    >
                    <Icon name='close' /> CLOSE
                </Button>
                <Button 
                    type="submit" 
                    style={{margin: "20px 0 30px 0", float: "right"}} 
                    primary 
                    // disabled={ids.length === 0} 
                    onClick={e => {/*onSubmit(e);*/ handleSave()}}
                    >
                    <Icon name='check' /> SAVE
                </Button>
            </span>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state)
});

export default connect(mapStateToProps, actions)(DappContractsTableNew);