import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Icon, Image } from 'semantic-ui-react';
import * as actions from '../../redux/actions';
import { getUser } from '../../redux/reducers';
import DroppIcon from "../../assets/images/dropp_icon@2x.png";
import moment from 'moment';
import { MONTHLY, YEARLY } from "../../constants";
import { numberToReadableFormat, getSubscriptionPricing, payWithLink, getMerchantAccountId, setMerchantAccountId, injectDroppSdk } from "../../utils/index";
import { fetchUserInvoices } from "../../apis";
import { getEnvConfigs } from '../../redux/reducers/envConfig';

class ProfileBalance extends Component {
	constructor(props) {
		super(props);
		this.subscriptionPricing =  [];
		this.allFeatures = null;
		this.state = {
			remainingUnitBalance: null,
			invoiceAmt: null,
			units: null,
			invoiceStartDate: null,
			unpaidInvoiceLoaded: false,
			showRenewalLink: false
		};
		this.refreshRemainingBalance = this.refreshRemainingBalance.bind(this);
	}

	componentDidMount() {
		this.props.fetchEnvConfigs();
		this.props.fetchAllFeatures(this.props.user.id);
		injectDroppSdk();
	}

	getRemainingUnitBalance(data) {
		let remainingUnit = 0;
		for (let i = 0; i < data.length; i++) {
			remainingUnit += data[i].unitBalance;
		}

		return remainingUnit;
	}

	refreshRemainingBalance() {
		this.setState({loadingBalance: true});
		this.props.fetchProfileBalance({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email, currentBalance: true});
	}

	getAmountForUnits(units, period) {
		let amount = 0;
		for (let i = 0; i < this.subscriptionPricing.length; i++) {
			if (this.subscriptionPricing[i].units === units) {
				if (period === MONTHLY) {
					amount = (this.subscriptionPricing[i].totalCost).toFixed(2);
				} else if (period === YEARLY) {
					amount = (this.subscriptionPricing[i].totalCost * 12).toFixed(2);
				}
			}
		}
		return amount;
	}

	componentDidUpdate(prevProps) {
		if (this.props.allFeatures !== prevProps.allFeatures) {
			this.allFeatures = this.props.allFeatures.featuresData && Object.keys(this.props.allFeatures.featuresData).length ? this.props.allFeatures.featuresData : null;
		}
		if (this.props.envConfigs !== prevProps.envConfigs && this.props.envConfigs) {
			const {merchantId} = this.props.envConfigs;
			setMerchantAccountId(merchantId);
			this.props.fetchPricingPlans();
			if (merchantId) {
				this.props.fetchProfileBalance({merchantId: merchantId, userId: this.props.user.id, email: this.props.user.email, currentBalance: true});
			}
		}

		if (this.props.profileBalance !== prevProps.profileBalance) {
			this.setState({
				loadingBalance: false,
				remainingUnitBalance: this.getRemainingUnitBalance(this.props.profileBalance.balanceData)
			});
		}

		if (this.props.pricingPlans !== prevProps.pricingPlans && this.props.pricingPlans.pricingPlansData && this.props.pricingPlans.pricingPlansData.length) {
			this.subscriptionPricing = getSubscriptionPricing(this.props.pricingPlans.pricingPlansData);
			this.props.fetchUserInvoices({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email, unpaidOnly: true});
		}

		if (this.props.unpaidInvoices !== prevProps.unpaidInvoices && this.props.unpaidInvoices && this.props.unpaidInvoices.unpaidInvoicesData && this.props.unpaidInvoices.unpaidInvoicesData.data.length) {
			for (let i = 0; i < this.props.unpaidInvoices.unpaidInvoicesData.data.length; i++) {
				const invoice = this.props.unpaidInvoices.unpaidInvoicesData.data[i];
				if (invoice.expiryDate && moment.utc(invoice.expiryDate).unix() > moment.utc().unix()) {
					fetchUserInvoices({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email, pageSize: 50, sortOn: "expiry_date", beginDate: moment.utc(invoice.startDate).format("YYYY-MM-DD")}).then((res) => {
						let showRenewalLink = true;
						if (res && res.data && res.data.length) {
							for (let i = 0; i < res.data.length; i++) {
								if (res.data[i].status === "PAID" && res.data[i].expiryDate && moment.utc(res.data[i].expiryDate).unix() > moment.utc(invoice.startDate).unix()) {
									showRenewalLink = false;
									break;
								}
							}
						}
						this.setState({
							invoiceId: invoice.id,
							invoiceAmt: this.getAmountForUnits(invoice.units, invoice.period),
							units: invoice.units,
							subscriptionPeriod: invoice.period,
							invoiceStartDate: invoice.startDate,
							showRenewalLink: showRenewalLink
						});
					}, (err) => {});
					break;
				}
			}

			this.setState({unpaidInvoiceLoaded: true});
		}
	}

	render() {
		const { remainingUnitBalance, showRenewalLink } = this.state;
		return (
			<Grid padded stackable className="panel">
				<Grid.Column width={16}>
          <Grid>
            <Grid.Column computer={8} tablet={16} mobile={16} textAlign="center">
							<Header as="h2" className="remaining-unit-header">
								<div className="remaining-unit-text">Remaining Unit Balance</div>
								<div>
									{(remainingUnitBalance || remainingUnitBalance === 0)  ? <>
									 <span>{numberToReadableFormat(remainingUnitBalance)}</span>
									 <span>
										 <Icon title="Refresh balance" className={`refresh small ${this.state.loadingBalance ? 'loading' : ''}`} onClick={this.refreshRemainingBalance} style={{color: '#1b97ae', marginLeft: '5px', fontSize: '15px', cursor: 'pointer'}} />
									 </span>
									 </> : ''}
								</div>
							</Header>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16} mobile={16}>
							<div className="monthly-msg-box">
								{ this.state.unpaidInvoiceLoaded ?
									<>
										{showRenewalLink ?
											<div className="renewal-link-msg">
												<span>Pay for renewal </span>
												{this.allFeatures && this.allFeatures['PAYMENT'] ?
													<a title="Pay with Dropp" data-dropp-purchase="#" onClick={(e) => { payWithLink(e, {userId: this.props.user.id, userEmail: this.props.user.email, cost: this.state.invoiceAmt, units: this.state.units, period: this.state.subscriptionPeriod, currentPage: 'profileBalance', showConfirmation: false, changeInvoiceStartDate: false, invoiceId: (this.state.invoiceId ? this.state.invoiceId : 0), invoiceStartDate: (this.state.invoiceStartDate? this.state.invoiceStartDate : ''), envConfigs: this.props.envConfigs}); }} href="#">
														<span className="monthly-payment-link" style={{borderBottom: "2px solid grey"}}>
															<Image inline style={{ height: "25px", marginTop: "-15px" }} src={DroppIcon} />
														</span>
													</a>
												:
													<span title="This feature is not available" className="monthly-payment-link" style={{borderBottom: "2px solid grey"}}>
														<Image inline style={{ height: "25px", marginTop: "-15px" }} src={DroppIcon} />
													</span>
												}

												<span> ${this.state.invoiceAmt}</span>
											</div>
										:
											<div className="msg-heading"> No payment required at this time</div>
										}
									</>
								: ''}
							</div>
            </Grid.Column>
          </Grid>
				</Grid.Column>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
  user: getUser(state),
	profileBalance: state.profileBalance,
	unpaidInvoices: state.userInvoice,
	pricingPlans: state.pricingPlan,
	allFeatures: state.allFeatures,
	envConfigs: getEnvConfigs(state)
});

export default connect(mapStateToProps, actions)(ProfileBalance);
