import React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

export default function ContactUs({ name, email, message, onChange, onSubmit, errors, loading }) {
	const isError = Object.keys(errors).length !== 0;
	return (
		<Form onSubmit={onSubmit} error={isError}>
			<Message error content={errors.non_field_errors} />
			<Form.Input label="Name" name="name" value={name} placeholder="Joe Cordana" onChange={onChange} />
			<Message error content={errors.name} />
			<Form.Input label="Email" name="email" value={email} placeholder="joe@email.com" onChange={onChange} />
			<Message error content={errors.email} />
			<Form.TextArea
				label="Message"
				name="message"
				value={message}
				placeholder="Tell us more..."
				onChange={onChange}
			/>
			<Message error content={errors.message} />
			<Button type="submit" primary onClick={onSubmit}>
				SUBMIT
			</Button>
		</Form>
	);
}
