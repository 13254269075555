import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import CodeSnippet from '../shared/CodeSnippet';
import * as actions from '../../redux/actions';
import { getContractByteCode } from '../../redux/reducers';

class ContractCode extends Component {
	componentDidMount() {
		const { fetchContractByteCode, id } = this.props;
		fetchContractByteCode(id);
	}

	panes = () => [
		{
			menuItem: {
				key: 'byte',
				content: <span>BYTE CODE</span>
			},
			render: () => (
				<Tab.Pane as="div">
					<CodeSnippet>{this.props.byteCode}</CodeSnippet>
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'source',
				content: <span>SOURCE CODE</span>
			},
			render: () => (
				<Tab.Pane as="div">
					<CodeSnippet>Source Code coming soon!</CodeSnippet>
				</Tab.Pane>
			)
		}
	];
	render() {
		return (
			<Tab
				menu={{ stackable: true, secondary: true, pointing: true }}
				panes={this.panes()}
				renderActiveOnly={true}
				onTabChange={this.handleTabChange}
			/>
		);
	}
}

const mapStateToProps = (state, { match: { params: { contractId } } }) => ({
	id: contractId,
	byteCode: getContractByteCode(state, contractId)
});

ContractCode = withRouter(connect(mapStateToProps, actions)(ContractCode));

export default ContractCode;
