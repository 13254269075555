import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Grid } from 'semantic-ui-react';
import Page from '../shared/Page';
// import SubscriptionSelectPanel from '../SubscriptionSelectPanel';
import DeploymentDescriptionPanel from '../DeploymentDescriptionPanel';
import { getCategories } from '../../redux/reducers';

const DeploymentView = (props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
//   useEffect(() => { fetchData() }, []);
  useEffect(() => { window.scrollTo(0, 0) }, []);

//   const fetchData = () => {
//     props.fetchApiList();
//     props.fetchApiCategories();
//     props.fetchApiParameters();
//   }

  // useEffect(() => {
  //   return () => {
  //     props.cleanUpApiParameters()
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const categories = [ 
      {id: "1", title: "Transaction"}, {id: "2", title: "Event"}, {id: "3", title: "HCS_Topic"}, {id: "4", title: "Sample Client Java Code"} ]

  let [activeCategoryId, setActiveCategoryId] = useState('0');

  let [activeCategoryTitle, setActiveCategoryTitle] = useState('');

  const handleCategorySelect = (category) => {
    setActiveCategoryId(category.id);
    setActiveCategoryTitle(category.title);
  }

  const panelToDisplay = <DeploymentDescriptionPanel title={"DApp Deployment Instructions"} />;

  return (
    <Page>
      <Grid padded stackable centered>
        {/* <Grid.Column computer={5} tablet={16} mobile={16}>
          <div style={{position: "sticky", top: "68px"}} >
            <Grid columns={1}>
              <Grid.Column>
                <SubscriptionSelectPanel
                  handleCategorySelect={handleCategorySelect} categories={categories}
                />  
              </Grid.Column>
            </Grid>
          </div>
        </Grid.Column> */}
        <Grid.Column computer={14} tablet={16} mobile={16}>
          <Grid columns={1}>
            <Grid.Column>
              { panelToDisplay }
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Page>
  )
};

const mapStateToProps = (state) => ({
  categories: getCategories(state)
});

export default connect(mapStateToProps, actions)(DeploymentView);
