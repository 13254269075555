import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import DappSummaryTable from '../DappSummaryTable';
import DappAccountSummaryTable from '../DappAccountSummaryTable';
import CodeSnippet from '../shared/CodeSnippet';
import * as actions from '../../redux/actions';
import { getDapp, getDappContracts } from '../../redux/reducers';

class DappSummary extends Component {

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchDapp, fetchDappContracts, id } = this.props;
		fetchDapp(id);
		fetchDappContracts(id);
	}
    
	render() {
        // const description = <span style={{fontSize: "90%", color: "grey"}}>FreeMint is a platform for asset-backend and 
		// 	freely mintable utility tokens along with the necessary
        //     payments infrastructure and interfaces to support widespread usage for retail payments.
		// </span>
		
		const displayContractDapp = this.props.dapp && this.props.dapp.contracts && this.props.dapp.contracts.length != 0;

		const displayAccountDapp = this.props.dapp && this.props.dapp.contracts && this.props.dapp.contracts.length == 0;

		return (
			<Fragment>
				{displayContractDapp && <DappSummaryTable dapp={this.props.dapp} /> }
				{displayAccountDapp && <DappAccountSummaryTable dapp={this.props.dapp} /> }
			</Fragment>
		);
	}
}

const mapStateToProps = (state, { match: { params: { dappId } } }) => ({
	id: dappId,
	dapp: getDapp(state, dappId),
	dappContracts: getDappContracts(state, dappId)
});

DappSummary = withRouter(connect(mapStateToProps, actions)(DappSummary));

export default DappSummary;