import React, { useEffect, useState } from "react";
import { Grid, Image, Header } from "semantic-ui-react";
import { calculatePaymentUrlParams } from "../utils/index";
import Rcslider from "rc-slider";
import "rc-slider/assets/index.css";
import "../assets/css/components/pricing-slider.css";
import DroppIcon from "../assets/images/dropp_icon@2x.png";

export default function BulkPricing(props) {
    const [bulkSlider, setbulkSlider] = useState(2);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const needsLogin = () => {
        if (window.confirm("You need to Log-in first to make a purchase. Do you want to continue?")) {
            props.history.push("/login?pricing=true");
        }
    };

    const getBulkPricing = (pricingPlans) => {
        let bulkPricingOptions = [];
        let counter = 0;
        for (let i = 0; i < pricingPlans.length; i++) {
            if (pricingPlans[i].type === "BULK") {
                bulkPricingOptions[`${counter}`] = pricingPlans[i];
                counter++;
            }
        }
        if (bulkPricingOptions.length) {
            bulkPricingOptions = bulkPricingOptions.sort((a, b) => {
                return a.units - b.units;
            });
        }
        return bulkPricingOptions;
    }
    const plans = props && props.pricingPlans && props.pricingPlans.pricingPlansData && props.pricingPlans.pricingPlansData.length ? getBulkPricing(props.pricingPlans.pricingPlansData) : {};
    const priceTier = plans[bulkSlider - 1];
    const cost = priceTier && priceTier.cost ? parseFloat(priceTier.cost).toFixed(2) : 0;
    const units = priceTier ? priceTier.units : 0;
    let allFeatures = null;
    if (props && props.allFeatures && props.allFeatures.featuresData && Object.keys(props.allFeatures.featuresData).length) {
        allFeatures = props.allFeatures.featuresData;
    }
    return (
        <>
        { Object.keys(plans).length ?
            <Grid>
                <Grid.Row columns="1">
                    <Grid.Column>
                        {/* <Header as="h3">Buy In Bulk</Header> */}
                        <Header as="h3">Pay as you go</Header>
                        <p>
                            For teams that are experimenting or in the development phase. No monthly fees, refill after every use.
                        </p>
                        <p>Move the slider to select the number of units; More you buy less you pay</p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{}}>
                    <Grid.Column computer={10} tablet={7} mobile={16} style={{}}>
                        <Rcslider
                            min={1}
                            max={plans.length}
                            value={bulkSlider}
                            step={1}
                            defaultValue={2}
                            onChange={val => setbulkSlider(val)}
                            style={{
                                height: "25px",
                                margin: "0 auto",
                                paddingTop: "25px",
                                width: "98%"
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={9} mobile={16} style={{}}>
                        <Grid.Row columns="2" className="bib-txt">
                            <Grid.Column>
                                <Grid.Row>
                                    <div className="unit-box-wrapper">
                                        <div className="unit-box">
                                            <div style={{padding: "5px 15px", fontSize: "32px"}}>
                                                <div style={{lineHeight: "35px"}}><span>{priceTier.label}</span> <span><b>Units</b></span></div>
                                                <div style={{fontSize: "14px", color: "#20C2EE"}}>Cost per Unit ${(cost / priceTier.units).toFixed(7)}</div>
                                            </div>
                                        </div>
                                        <div className="bulk-pay-link">
                                            {props.user ?
                                                <>
                                                    {allFeatures && allFeatures['PAYMENT'] ?
                                                        <>
                                                            <a style={{color: "#20C2EE"}} data-dropp-purchase={calculatePaymentUrlParams({userId: props.user.id, cost: cost, units: units, period: "BULK", envConfigs: props.envConfigs})} href="#">
                                                                Pay ${cost} with <Image inline style={{ height: "17px", marginTop: "-4px" }} src={DroppIcon} />
                                                            </a>
                                                        </>
                                                    :
                                                        <span className="disable-payment-link" title="Pricing is currently disabled. Coming Soon...">
                                                            Pay ${cost} with <Image inline style={{ height: "17px", marginTop: "-4px" }} src={DroppIcon} />
                                                        </span>
                                                    }
                                                </>
                                                :
                                                <a style={{color: "#20C2EE"}} onClick={needsLogin} href="#">
                                                    Pay ${cost} with <Image inline style={{ height: "17px", marginTop: "-4px" }} src={DroppIcon} />
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
                {/* <Grid.Row columns="1">
                    <Grid.Column>
                        <p><span className="small-links">1 Unit= 1 Rest API or 1 Subscription call, <a href="#dg-charges">For details...</a></span></p>
                    </Grid.Column>
                </Grid.Row> */}
            </Grid>
        : ''}
        </>
    );
}
