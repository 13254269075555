import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../redux/actions';
import { getAccountTransactions, getAccountTransactionsCount } from '../../redux/reducers';
import TransactionTable from '../TransactionTable';
import { removeEmpty } from '../../utils';

class DappAccountTransactions extends React.Component {
	state = {
		offset: 0,
		limit: 5,
		page: 1,
		sort: 'consensusTime',
		order: 'desc'
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.accountNumber !== prevProps.accountNumber || this.props.filter !== prevProps.filter) {
			this.setState(
				{
					offset: 0,
					page: 1
				},
				() => this.fetchData()
			);
		}
	}

	showTransactionDetails = (transaction) => {
		this.props.history.push(`/transactions/${transaction.transactionID}`);
	};

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		//const { offset, limit, page, sort, order } = this.state;
		const { filter, accountNumber, fetchAccountTransactions } = this.props;
		const params = { q: filter, ...this.state };

		const queryParams = removeEmpty(params);

		const esc = encodeURIComponent;
		const query = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');

		fetchAccountTransactions(accountNumber, query);
	};

	render() {
		const { transactions, totalCount } = this.props;
		return (
			<TransactionTable
				transactions={transactions}
				limit={this.state.limit}
				offset={this.state.offset}
				sort={this.state.sort}
				order={this.state.order}
				totalCount={totalCount}
				showDetails={this.showTransactionDetails}
				loadData={this.loadData}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	// accountId,
	transactions: getAccountTransactions(state, ownProps.accountNumber),
	totalCount: getAccountTransactionsCount(state, ownProps.accountNumber)
});

DappAccountTransactions = withRouter(connect(mapStateToProps, actions)(DappAccountTransactions));

export default DappAccountTransactions;
