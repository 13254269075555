import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Table, Checkbox, Button, Input, Grid, Dropdown, StrictFormInputProps, Message, CommentContent, Icon } from 'semantic-ui-react';
import { getUser } from '../../redux/reducers';

export const SubscriptionFiltersForm = ({
    subscription, 
    ids,
    signature,
    errors, 
    onFilterTypeSelect, 
    handleFilterValues, 
    deleteFilterValue,
    handleSignature,
    deleteSignature,
    onTransactionTypeSelect, 
    onSubmit, 
    handleSubscribeMessage,
    filterValuesFieldName}) => {

    console.log("THIS IS THE SUBSCRIPTION IN THE FILTERS: ", subscription)

    // const [disabled, setDisabled] = useState(true);
    const [displayFunctionNames, setDisplayFunctionNames] = useState(false);

    const [placeholder, setPlaceholder] = useState('');

    const displayRow = (e) => {
        e.target.textContent === "FUNCTION_NAME" ? setDisplayFunctionNames(true) : setDisplayFunctionNames(false);

        if (e.target.textContent.includes("ACCOUNT")) {
            setPlaceholder("Enter an account ID and press Enter")
        } else if (e.target.textContent.includes("TOPIC")) {
            setPlaceholder("Enter a topic ID and press Enter")
        } else if (e.target.textContent.includes("MEMO")) {
            setPlaceholder("Enter substrings and press Enter")
        } else {
            setPlaceholder("Enter a contract ID and press Enter")
        }

        // e.target.textContent.includes("ACCOUNT") ? setPlaceholder("Enter an account ID and press Enter") : 
        // setPlaceholder("Enter a contract ID and press Enter");

        // if (e.target.textContent === "CONTRACT" || e.target.textContent === "SIGNATURE") {
        //     setPlaceholder("Enter a contract ID and press Enter")
        // } else if (e.target.textContent === "ACCOUNT") {
        //     setPlaceholder("Enter an account ID and press Enter")
        // }
    }

    // const filterTypeOptions = subscription.subscriptionType === "TRANSACTION" ? [
    //     {key: "PAYER_ACCOUNT", text: "PAYER_ACCOUNT", value: "PAYER_ACCOUNT"}, 
    //     {key: "SENDER_ACCOUNT", text: "SENDER_ACCOUNT", value: "SENDER_ACCOUNT"}, 
    //     {key: "RECEIVER_ACCOUNT", text: "RECEIVER_ACCOUNT", value: "RECEIVER_ACCOUNT"}, 
    //     {key: "CONTRACT", text: "CONTRACT", value: "CONTRACT"}] : [{key: "FUNCTION_NAME", text: "FUNCTION_NAME", value: "FUNCTION_N

    let filterTypeOptions = [];

    if (subscription.subscriptionType === "EVENT") {
        filterTypeOptions = [{key: "FUNCTION_NAME", text: "FUNCTION_NAME", value: "FUNCTION_NAME"}]
    } else if (subscription.subscriptionType === "HCS_TOPIC") {
        filterTypeOptions = [{key: "TOPIC", text: "TOPIC", value: "TOPIC"}]
    } else if (subscription.subscriptionType === "TRANSACTION") {
        filterTypeOptions = [
            {key: "PAYER_ACCOUNT", text: "PAYER_ACCOUNT", value: "PAYER_ACCOUNT"},
            {key: "SENDER_ACCOUNT", text: "SENDER_ACCOUNT", value: "SENDER_ACCOUNT"},
            {key: "RECEIVER_ACCOUNT", text: "RECEIVER_ACCOUNT", value: "RECEIVER_ACCOUNT"},
            {key: "CONTRACT", text: "CONTRACT", value: "CONTRACT"},
            {key: "MEMO", text: "MEMO", value: "MEMO"},
        ]
    }

    const isError = Object.keys(errors).length !== 0;

    const inputFilterValueKeyUp = e => {
        e.which = e.which || e.keyCode;
        if(e.which == 13) {
            console.log("FILTER VALUE IN FILTERS FORM: ", encodeURI(e.target.value));
            handleFilterValues(encodeURI(e.target.value));
            e.target.value = '';
        }
    }

    const inputFilterValueOnBlur = e => {
        e.target.value.length > 0 && handleFilterValues(encodeURI(e.target.value));
        e.target.value = '';
    }

    const inputSignatureKeyUp = e => {
        e.which = e.which || e.keyCode;
        if(e.which == 13) {
            console.log(e.target.value);
            handleSignature(e.target.value);
            e.target.value = '';
        }
    }

    const inputSignatureOnBlur = e => {
        e.target.value.length > 0 && handleSignature(e.target.value);
        e.target.value = '';
    }

    return (
        <Fragment>
        <Grid padded stackable>

            <Grid.Row columns="2">
                <Grid.Column width={10}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>QUEUE NAME</span>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Input  error={isError}
                        id="queueName"
                        name="queueName"
                        fluid
                        // icon="building"
                        // iconPosition="left"
                        // placeholder="Company"
                        autoFocus
                        value={subscription.queueName}
                        // onChange={onChange}
                        error={!!errors.queueName}
                        autoComplete="queueName"
                        required={false}
                    />
                {isError && <Message error content={errors.queueName} className="animated fadeIn" />}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns="2">
                <Grid.Column width={10}>
                    <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>FILTER TYPE</span>
                </Grid.Column>
                <Grid.Column width={6}>
                <Dropdown
                    placeholder='Filter Type'
                    fluid
                    selection
                    options={filterTypeOptions}
                    onChange={e => {onFilterTypeSelect(e); displayRow(e)}}
                />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns="2">
                <Grid.Column width={10}>
                <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>{filterValuesFieldName}</span>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Input  error={isError}
                        id="filterValue"
                        name="filterValue"
                        fluid
                        // icon="building"
                        // iconPosition="left"
                        placeholder={placeholder}
                        autoFocus
                        // value={}
                        // onChange={onChange}
                        // error={!!errors.queueName}
                        autoComplete="filterValue"
                        required={false}
                        onKeyUp={e => inputFilterValueKeyUp(e)}
                        onBlur={e => inputFilterValueOnBlur(e)}
                    />
                    {ids.map((value) =>
                        <Input
                            style={{marginTop: "5px"}}
                            key={Math.random()}
                            icon={<Icon name='close' link={true} onClick={e => deleteFilterValue(value)} />}
                            value={decodeURI(value)}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>

            {displayFunctionNames && <Grid.Row columns="2">
                <Grid.Column width={10}>
                <span className="text-gray" style={{fontWeight: "600", fontSize: "120%"}}>FUNCTION NAMES</span>
                </Grid.Column>
                <Grid.Column width={6}>
                <Input  error={isError}
                        id="signature"
                        name="signature"
                        fluid
                        // icon="building"
                        // iconPosition="left"
                        placeholder="Enter a function name and press Enter"
                        autoFocus
                        // value={}
                        // onChange={onChange}
                        // error={!!errors.queueName}
                        autoComplete="signature"
                        required={false}
                        onKeyUp={e => inputSignatureKeyUp(e)}
                        onBlur={e=> inputSignatureOnBlur(e)}
                    />
                    {signature.map((sign) =>
                        <Input
                            style={{marginTop: "5px"}}
                            key={Math.random()}
                            icon={<Icon name='close' link={true} onClick={e => deleteSignature(sign)} />}
                            value={sign}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>}

        </Grid>

        <Button 
            type="submit" 
            style={{margin: "30px 0 20px 0", float: "right"}} 
            primary 
            disabled={displayFunctionNames ? (ids.length === 0 || signature.length === 0) : (ids.length === 0)}
            onClick={e => {onSubmit(e); /*handleSubscribeMessage()*/}}
            >
            ADD FILTER
        </Button>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default withRouter(connect(mapStateToProps, actions)(SubscriptionFiltersForm));
