import React, { useEffect } from 'react';
import Page from '../shared/Page';
import { Header, Grid, Accordion } from 'semantic-ui-react';

const panels = [
	{
		key: 'message-for-topic',
		title:
			'I am trying to get HCS messages by topicID but the returned message is not in the original format I submitted. How can I get the original message back using DragonGlass’ APIs?',
		content: [
			'The message for a topic is returned in hex format. You can use standard Hex converters to convert to ASCII or a byte format.'
		].join(' ')
	},
	{
		key: 'use-curl',
		title: 'Is it possible to use cURL instead of javascript to send a request for data?',
		content: {
			content: (
				<div>
					<p>
						Yes it is possible to request for the data calling our rest APIs using cURL. Please get an API
						key from your My Profile page and pass it in the header. Please check the below example:
					</p>
					<p>curl -H "X-API-KEY: [your-api-key]" https://api.dragonglass.me/hedera/api/transactions</p>

					<p>
						Please refer to the DragonGlass API documentation, where you can find code examples for cURL, as
						well as Java, JS, Go, Swift, and Python.
					</p>
				</div>
			)
		}
	},
	{
		key: 'use-rest-api-c#',
		title: 'Can I use REST API (in C#) for downloading balance snapshots of all accounts?',
		content: {
			content: (
				<div>
					<p>Yes, you can view the sample code below to see how</p>
					<p>
						<b>Sample C# code</b>
					</p>
					<p>var client = new RestClient("https://api.dragonglass.me/hedera/api/transactions");</p>
					<p>client.Timeout = -1;</p>
					<p>var request = new RestRequest(Method.GET);</p>
					<p>request.AddHeader("x-api-key", "eae6efc6-658e-3ae1-9e60-7343905c0a85");</p>
					<p>request.AddParameter("text/plain", "", ParameterType.RequestBody);</p>
					<p>IRestResponse response = client.Execute(request);</p>
					<p>Console.WriteLine(response.Content);</p>
				</div>
			)
		}
	},
	{
		key: 'rate-limit',
		title: 'What is the DragonGlass rate limit?',
		content: [ '10 requests per second' ].join(' ')
	},
	{
		key: 'total-transactions',
		title:
			'How do you calculate total transactions on the networks? If there is transfer from 1 account to 3 accounts, is that counted as 1, 3 or 4 transactions? If there is a transfer from 3 accounts to 2 is that 1 to 3?',
		content: [
			'Transactions equate to total number of transaction records generated in the Hedera network. If a transfer is part of one network transaction. it will be counted as one (regardless of how many accounts are associated to the transaction)'
		].join(' ')
	},
	{
		key: 'example-code',
		title: 'Are there any JS example code to get up and running with real time data subscriptions?',
		content: [
			'Please refer to the following documentation https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/sqs-examples.html'
		].join(' ')
	},
	{
		key: 'balance-units',
		title: 'Why is balance shown in tinyhbar instead of hbar?',
		content: [ 'There is a toggle button in the top right corner of the screen to switch between the two' ].join(
			' '
		)
	},
	// {
	// 	key: 'composer-extension',
	// 	title: 'Is there a way to connect the Composer extension to DragonGlass?',
	// 	content: [
	// 		'Yes, see the following post for a detailed explanation https://dailystamp.io/post/5df9a4c4a671e8434b0a612b'
	// 	].join(' ')
	// },
	// {
	// 	key: 'expiration-date',
	// 	title: 'What does “expiration date” refer to in an Account Summary page?',
	// 	content: [
	// 		' Files and smart contracts in Hedera have a defined lifespan period, but can be renewed indefinitely.  DragonGlass provides the service of storing transaction events that Hedera processes. Like them, anyone can set up a mirror node to capture events for transactions they care about: i.e. their  assets or dapp events. The network will not be overwhelmed by every node being forced to store every event like Dragonglass is doing.'
	// 	].join(' ')
	// },
	{
		key: 'geographic-location-of-nodes',
		title: 'Is there anywhere I can find the general geographic location of nodes that are running?',
		content: [
			'Hedera does not provide node locations. The ones listed on the map are based on the headquarters of companies hosting the nodes. These are just symbolic.'
		].join(' ')
	},
	{
		key: 'value-in-map',
		title: 'What does "Value" refer to on the DragonGlass map?',
		content: [
			'Value on the map represents the node fee.  You can select the interval using the drop down on the top right corner of the map.'
		].join(' ')
	},
	{
		key: 'sqs-queue-msgs',
		title: 'I created a DragonGlass subscription, but I’m still not receiving messages on the SQS queue.',
		content: [
			'Please ensure that you have set a valid filter to the subscription.  It takes a maximum of 2 hours for any new subscription or addition of a filter to a subscription to take effect.'
		].join(' ')
	},
	{
		key: 'events-sqs-queue-msgs',
		title:
			'I created a DragonGlass subscription to receive Smart Contract events, but I’m still not receiving messages on the SQS queue',
		content: [
			'To receive Smart Contract events, you will need to upload the ABI.  It takes maximum of 2 hours for any new subscription or addition of a filter to a subscription to take effect'
		].join(' ')
	},
	{
		key: 'use-curl-php',
		title: "I'm trying to query using the API, could you help with my PHP script?",
		content: {
			content: (
				<div>
					<p>You only need to send accessKey as below as part of the header.</p>
					<p>$auth = 'x-api-key'.':'.$accessKey; </p>
					<p>“x-api-key” is the header key, which is static.</p>
				</div>
			)
		}
	},
	{
		key: 'add-units',
		title:
			'I paid for a subscription but have already expired my units. How can I add units so that my access to the service does not stop?',
		content: {
			content: (
				<div>
					<p>We recommend buying bulk units sufficient to last until the end of the month.  You can purchase more units on the <a href="/pricing">Pricing Page</a></p>
				</div>
			)
		}
	},
	{
		key: 'insufficient-balance',
		title:
			'On the explorer, I see that I have a sufficient balance, but when I try to make a call, I get the message "Service not performed due to insufficient balance."',
		content: {
			content: (
				<div>
					<p>Please reach out to DragonGlass support either through our chatbot or by emailing <a href="mailto:info@dragonglass.me">info@dragonglass.me</a> and a member of our team</p>
				</div>
			)
		}
	},
	{
		key: 'unable-to-find-message-transaction',
		title:
			'I am unable to find the HCS Submit Message transaction on DragonGlass',
		content: {
			content: (
				<div>
					<p>DragonGlass does not keep HCS messages for more than three days. "To access historical HCS transactions older than three days, please contact us at <a href="mailto:info@dragonglass.me">info@dragonglass.me</a>"</p>
				</div>
			)
		}
	},
	
];

export default () => {
	return (
		<Page>
			<Header style={{ marginTop: '3rem' }} dividing>
				FREQUENTLY ASKED QUESTIONS
			</Header>

			<Grid stackable style={{ marginTop: '3rem' }}>
				<Grid.Column width={16}>
					<Accordion fluid styled defaultActiveIndex={0} panels={panels} />
				</Grid.Column>
			</Grid>
		</Page>
	);
};
