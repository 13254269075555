import React, { Component } from 'react';
import Panel from './shared/Panel';
//import Filter from './shared/Filter';
import DappTokenTransfers from './containers/DappTokenTransfers';
import Info from './shared/Info';

import '../animate.css';

export default class DappTokenTransfersPanel extends Component {
	state = {
		q: ''
	};

	onSubmitFilter = (value) => {
		this.setState({ q: value });
	};

	render() {
		const { q } = this.state;
		return (
			<Panel
				title={
					<React.Fragment>
						{/*TRANSFERS <Info id="transfers" />*/}
					</React.Fragment>
				}
				description=""
				// toolbar={
				// 	<React.Fragment>
				// 		<Filter onSubmitFilter={this.onSubmitFilter} value={q} />
				// 	</React.Fragment>
				// }
			>
				<div className="animated fadeIn"><DappTokenTransfers filter={q} /></div>
			</Panel>
		);
	}
}