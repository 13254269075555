import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import { Grid, Button, Form } from 'semantic-ui-react';
import { isMobile, removeEmptyFields } from '../../utils';
import * as api from "../../apis";
import { toast } from 'react-toastify';
import { useHistory } from "react-router";
import info from '../../info';

const styles = {
  error: {
    color: 'red',
    border: `1px solid red`,
  }
}
const RegisterForMarketPlaceForm = (props) => {

  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenId = queryParams.get('token');
  const serial = queryParams.get('serial');
  const nftOwner = queryParams.get('nftOwner');

  const initialFormData = {
    email: '',
    phone: '',
    tokenId: tokenId || '',
    serial: serial || '',
    nftOwner: nftOwner || '',
    marketplaceLink: '',
  }

  const [formData, setFormData] = useState(initialFormData);

  const [formErrors, setFormErrors] = useState({
    email: '',
    phone: '',
    tokenId: '',
    serial: '',
    nftOwner: '',
    marketplaceLink: '',
    other: ''
  });

  const validateForm = () => {
    const errors = {};

    // Validate the form data
    // if (!formData.phone.trim()) {
    //   errors.phone = 'Phone is required';
    // }

    if (!formData.tokenId.trim()) {
      errors.tokenId = 'TokenId is required';
    }

    if (!formData.nftOwner.trim()) {
      errors.nftOwner = 'Owner is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email address';
    }
    console.log('errors', errors)
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Form data is valid, proceed with submission
      const validData = removeEmptyFields(formData);
      api.nftMarketplaceRequest(validData).then((res) => {
        toast.success('Your submission is under review. Once completed, MarketPlace link will be shown on NFT Page');
        resetForm();
        history.goBack();
      }).catch((err) => {
        const errors = {};
        errors.other = 'Please verify the form Data';
        toast.error(errors.other);
        setFormErrors(errors);
      });
    } else {
      // Form data is invalid, handle errors
      console.log('formErrors:', formErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      // [name]: name == 'serial' ? Number(value) : value,
      [name]: value,
    }));

    // Clear the corresponding error when the user types
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const resetForm = () => {
    setFormData(initialFormData)
  }
	return (
		<Grid stackable basic="very" size="small" className="animated fadeIn" columns={isMobile() ? 1 : 2} divider>
			<Grid.Row>
				<Grid.Column width={6} style={{maxWidth: '600px'}}>
					{/* <Grid>
						<Grid.Row>
							<Grid.Column>
								<div>
									<div className="bold font20">Email</div>
									<div>info@dragonglass.me</div>
								</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column className="bold" width={16}>Phone</Grid.Column>
							<Grid.Column width={16}>+1 212 768 1730</Grid.Column>
						</Grid.Row>
					</Grid> */}
				</Grid.Column>
				<Grid.Column width={4}>
					<Grid.Row>
						<Grid.Column column={1}>
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <Form.Input
                    id="email"
                    fluid
                    label="Email ID"
                    className="required"
                    name="email"
                    placeholder={formErrors.email ? formErrors.email : ""}
                    type="email"
                    style={formErrors.email ? styles.error : {}}
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Field>
                {/* <Form.Field>
                  <Form.Input
                    fluid
                    label="Phone"
                    className="required"
                    name="phone"
                    placeholder={formErrors.phone ? formErrors.phone : ""}
                    type="number"
                    style={formErrors.phone ? styles.error : {}}
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </Form.Field> */}
                <Form.Field>
                  <Form.Input
                    id="token"
                    fluid
                    label="Token ID"
                    className="required"
                    name="tokenId"
                    placeholder={formErrors.tokenId ? formErrors.tokenId : "0.0.123456"}
                    style={formErrors.tokenId ? styles.error : {}}
                    value={formData.tokenId}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    id="serial"
                    fluid
                    name="serial"
                    label="Serial #"
                    placeholder={formErrors.serial ? formErrors.serial : info.serialNumberForm}
                    style={formErrors.serial ? styles.error : {}}
                    type="number"
                    value={formData.serial}
                    onChange={handleChange}
                  />
                </Form.Field>

                <Form.Field>
                  <Form.Input
                    id="owner"
                    fluid
                    label="NFT Owner"
                    className="required"
                    name="nftOwner"
                    placeholder={formErrors.nftOwner ? formErrors.nftOwner : "0.0.123456"}
                    style={formErrors.nftOwner ? styles.error : {}}
                    value={formData.nftOwner}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    id="marketplaceLink"
                    fluid
                    label="NFT Marketplace Link"
                    name="marketplaceLink"
                    placeholder="https://sentix.io/nft-marketplace/0.0.878200"
                    value={formData.marketplaceLink}
                    onChange={handleChange}
                  />
                </Form.Field>
                {/* {
                  formErrors && formErrors.other && (
                    <Form.Field>
                      <p style={{color: 'red'}}>{formErrors.other}</p>
                    </Form.Field>
                  )
                } */}

                <Button primary>
                  SUBMIT
                </Button>
              </Form>
						</Grid.Column>
					</Grid.Row>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default withRouter(RegisterForMarketPlaceForm);

