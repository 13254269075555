import React from 'react';

import '../animate.css';

export const SubscriptionSampleJava = () => {
    return (
        <div className="animated fadeIn">
            <div className="image-div">
                <img width="90%" src="/java_code.png" alt="" />
            </div>
        </div>
    )
}

export default SubscriptionSampleJava;