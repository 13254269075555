import React from 'react';
import Panel from './shared/Panel';
import ContractSummary from './containers/ContractSummary';
import Info from './shared/Info';

export default function ContractSummaryPanel() {
	return (
		<Panel
			title={
				<React.Fragment>
					CONTRACT SUMMARY <Info id="contractSummary" />
				</React.Fragment>
			}
			description=""
		>
			<ContractSummary />
		</Panel>
	);
}
