import React from 'react';
import { Table } from 'semantic-ui-react';

export default function DappTransferTableHeader(props) {
	return (
		<Table.Row>
			{/* <Table.HeaderCell
				width={1}
				sorted={props.column === 'transactionDirection' ? props.direction : null}
				onClick={() => props.handleSort('transactionDirection')}
			>
				Type
			</Table.HeaderCell> */}
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'consensusTime' ? props.direction : null}
				onClick={() => props.handleSort('consensusTime')}
			>
				Date
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'toFromAccount' ? props.direction : null}
				onClick={() => props.handleSort('toFromAccount')}
			>
				From Account
			</Table.HeaderCell>
			<Table.HeaderCell
				sorted={props.column === 'amount' ? props.direction : null}
				onClick={() => props.handleSort('amount')}
			>
				Txn Data
			</Table.HeaderCell>
			{/* <Table.HeaderCell
				sorted={props.column === 'memo' ? props.direction : null}
				onClick={() => props.handleSort('memo')}
			>
				Memo
			</Table.HeaderCell> */}
			<Table.HeaderCell width={1} />
		</Table.Row>
	);
}
