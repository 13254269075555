import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DappSummaryTable from '../DappSummaryTable';
import CodeSnippet from '../shared/CodeSnippet';
import DappContractsListTabs from '../DappContractsListTabs';
import * as actions from '../../redux/actions';
import { getDappContracts } from '../../redux/reducers';

class DappContracts extends Component {

	// componentDidMount() {
	// 	this.fetchData();
	// }

	// componentDidUpdate(prevProps) {
	// 	if (this.props.id !== prevProps.id) {
	// 		this.fetchData();
	// 	}
	// }

	// fetchData() {
	// 	const { fetchDapp, fetchDappContracts, id } = this.props;
	// 	fetchDapp(id);
	// 	fetchDappContracts(id);
    // }
    
    
	render() {
		return (
			<Fragment>
				{/* {(this.props.dappContracts.length > 0) && <DappContractsList contracts={this.props.dappContracts} /> } */}
				<DappContractsListTabs contracts={this.props.dappContracts}/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, { match: { params: { dappId } } }) => ({
	id: dappId,
	// dapp: getDapp(state, dappId),
	dappContracts: getDappContracts(state, dappId)
});

DappContracts = withRouter(connect(mapStateToProps, actions)(DappContracts));

export default DappContracts;