import React, { Fragment } from 'react';
import { getTokenDetails } from '../redux/reducers/hts';
import Panel from './shared/Panel';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import '../assets/css/components/tokenDetails.scss';
import DateCell from './shared/DateCell';
import Number from './shared/Number';
import { adjustWithDecimals } from '../utils';

const HtsTokenOverview = (props) => {
  const {token} = props;
  console.log("token : ", token);
	return (
		<>
    {token ?
      <Table celled stackable basic="very" id="token-overview">
        <Table.Body>
          { token.supply != 0 ?
            <Table.Row>
              <Table.Cell>Supply:</Table.Cell>
              <Table.Cell className="cell-value" style={{fontWeight: 700}}>
                  {/* <Number>{token.supply}</Number> */}
                  <Number>{adjustWithDecimals(token.supply, token.decimal || token.decimals)}</Number>
                </Table.Cell>
              {/* <Table.Cell className="cell-value" style={{fontWeight: 700}}><Number>{token.supply}</Number></Table.Cell> */}
            </Table.Row>
            : ''
          }
          <Table.Row>
            <Table.Cell>Type:</Table.Cell>
            <Table.Cell className="cell-value" style={{fontWeight: 700}}>{token.tokenType}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Supply Type:</Table.Cell>
            <Table.Cell className="cell-value" style={{fontWeight: 700}}>{token.supplyType}</Table.Cell>
          </Table.Row>
          { token.supply != 0 ?
            <Table.Row>
              <Table.Cell>Max Supply:</Table.Cell>
              <Table.Cell className="cell-value" style={{fontWeight: 700}}>
                {
                  (token.maxSupply && token.supplyType == 'FINITE') ?  
                  <Number>{token.maxSupply}</Number>
                  : 'INFINITE'
                }
              </Table.Cell>
            </Table.Row>
            : ''
          }
          <Table.Row>
            <Table.Cell>Treasury:</Table.Cell>
            <Table.Cell className="cell-value" style={{fontWeight: 700}}>{token.treasury}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Auto Renew A/c:</Table.Cell>
            <Table.Cell className="cell-value" style={{fontWeight: 700}}>{token.autoRenewAccount}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Expiry:</Table.Cell>
            <Table.Cell className="cell-value" style={{fontWeight: 700}}><DateCell date={token.expiry} /></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell className="cell-value" style={{fontWeight: 700}}></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    : ''}
		</>
	);
}

const mapStateToProps = (state) => ({
	token: getTokenDetails(state)
});

export default  withRouter(connect(mapStateToProps)(HtsTokenOverview));