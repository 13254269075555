import React, { useState } from 'react';
import { List } from 'semantic-ui-react';
import APIButton from './shared/APIButton';

const SubscriptionIntroList = ({ cat, apis, handleApiSelect, activeApi }) => {

    console.log("CATEGORY IN SUB INTRO LIST: ", cat);
    // console.log("ACTIVE API IN SUB INTRO LIST: ", activeApi);

    const [selected, setSelected] = useState('');

    // const handleClick = (api) => {
    //     console.log("API: ", api)
    //     setSelected(api.title)
    // }

    // const apisToDisplay = apis.filter(api => api.category === cat.id);

    return (
            <List className="categories-list">
                {/* <List.Item><a href="#introduction">Introduction</a></List.Item> */}
                <List.Item className="subintro"><a href="#batching">Batching</a></List.Item>
                <List.Item className="subintro"><a href="#subscriptionsetup">Subscription Setup</a></List.Item>
                <List.Item className="subintro"><a href="#subauthentication">Authentication</a></List.Item>
                <List.Item className="subintro"><a href="#subdatatypes">Data Types</a></List.Item>
                {/* {apisToDisplay.map(api => 
                    <List.Item active={selected===cat.title ? true : false} >
                        <div style={{display: "flex"}} onClick={() => {handleApiSelect(api)}}>
                            <span style={{marginRight: "10px"}}><APIButton /></span>
                            <span 
                                className={activeApi.title===api.title ? "selected" : ""}
                            >{api.title}</span>
                        </div>
                    </List.Item>)} */}
            </List>
    )
}

export default SubscriptionIntroList;