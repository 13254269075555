import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Table, Pagination } from 'semantic-ui-react';
import * as actions from '../../redux/actions';
import { getUser } from '../../redux/reducers';
// import { DG_MERCHANT_ID } from "../../constants";
import { fetchUsageDetails, fetchUsageSummary } from "../../apis/index";
import moment from 'moment';
import { numberToReadableFormat, formatNumber, getMerchantAccountId } from "../../utils/index";
import PageSizeSelect from '../shared/PageSizeSelect';

class StatementHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexes: [],
      usageSummaries: [],
      usageDetails: {},
      pageSize: 5,
      pageNo: 1,
      totalPages: 0,
      totalCount: 0
    };
    this.getUsageDetails = this.getUsageDetails.bind(this);
    this.getSummaries = this.getSummaries.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

	componentDidMount() {
    this.getSummaries({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email});
  }

  prepareSummaryData(summaries) {
    for (let i = 0; i < summaries.length; i++) {
      summaries[i].displayDate = moment.utc(summaries[i].cycle).format('MMM DD, YYYY');
    }
    return summaries;
  }

  prepareUsageDetails(usageDetails, startDate) {
    let details = {};
    let totalConsumedUnits = 0;
    for (let i = 0; i < usageDetails.length; i++) {
      if (details[usageDetails[i].serviceType] && details[usageDetails[i].serviceType]) {
        details[usageDetails[i].serviceType].totalCalls += usageDetails[i].unitsUsed;
        details[usageDetails[i].serviceType].callDetails.push(usageDetails[i]);
      } else {
        details[usageDetails[i].serviceType] = {totalCalls: (usageDetails[i].unitsUsed || 0), callDetails: [usageDetails[i]]};
      }
      totalConsumedUnits += usageDetails[i].unitsUsed || 0;
    }
    let usageSummaries = this.state.usageSummaries;
    for (let i = 0; i < usageSummaries.length; i++) {
      if (usageSummaries[i].cycle === startDate) {
        usageSummaries[i]['consumedUnits'] = totalConsumedUnits;
      }
    }
    this.setState({usageSummaries: usageSummaries});

    return details;
  }

	componentDidUpdate(prevProps) {
  }

  getUsageDetails(startDate) {
    let startDateMillisec = (moment.utc(startDate).startOf('month').unix() * 1000);
    let endDateMillisec = (moment.utc(startDate).endOf('month').unix() * 1000);
    fetchUsageDetails({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email, startDateMillisec: startDateMillisec, endDateMillisec: endDateMillisec}).then(
      (resp) => {
        let usageDetails = this.state.usageDetails;
        usageDetails[`${startDate}`] = this.prepareUsageDetails(resp, startDate);
        this.setState({usageDetails: usageDetails});
      });
  }

  getSummaries(params) {
    fetchUsageSummary(params).then(resp => {
      if (resp && resp.data && resp.data.length) {
        this.setState({
          usageSummaries: this.prepareSummaryData(resp.data),
          totalPages: resp.totalPages,
          totalCount: resp.totalCount,
          pageNo: (params.pageNo || params.pageNo === 0 ? (params.pageNo + 1) : this.state.pageNo)
        });
      } else {
        this.setState({usageSummaries: []});
      }
    });
  }

  handleClick = (index, date) => {
    const { activeIndexes } = this.state;
    let newIndex = false;
    if (activeIndexes.indexOf(index) != -1) {
      activeIndexes.splice(activeIndexes.indexOf(index), 1);
    } else {
      activeIndexes.push(index);
      newIndex = true;
    }
    this.setState({ activeIndexes: activeIndexes });
    if (newIndex) {
      this.getUsageDetails(date);
    }
  };

  onChangePage = (event, data) => {
		let { activePage } = data;
		if (activePage !== this.state.pageNo) {
      activePage -= 1;
      if (activePage >= 0) {
        this.getSummaries({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email, pageNo: activePage, pageSize: this.state.pageSize});
      }
		}
  };

  onChangeLimit = (event, data) => {
		if (data.value !== this.state.pageSize) {
      this.getSummaries({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email, pageSize: data.value});
		}
	};

	render() {
    const { activeIndexes, usageSummaries, totalPages, pageNo, pageSize, totalCount, usageDetails } = this.state;
    return ( <>
      <div>
        <PageSizeSelect limit={pageSize.toString()} onChangeLimit={this.onChangeLimit} /> Total{' '}<strong>{formatNumber(totalCount)}</strong>
        <Table celled stackable basic="very" className="api-key-table table-no-border">
          <Table.Header>
            <Table.Row key={Math.random()}>
              <Table.HeaderCell style={{width: "50%"}}>Date</Table.HeaderCell>
              <Table.HeaderCell style={{width: "50%"}}># of Units Used</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {usageSummaries.map((data, index) =>
              <React.Fragment key={index}>
                <Table.Row index={index} onClick={() => this.handleClick(index, data.cycle)} style={{cursor: "pointer"}} className={(activeIndexes.indexOf(index) !== -1) ? 'no-border' : ''}>
                  <Table.Cell><Icon name={(activeIndexes.indexOf(index) !== -1) ? 'minus' : 'chevron right'} />{data.displayDate}</Table.Cell>
                  <Table.Cell>{data.consumedUnits >= 0 ? numberToReadableFormat(data.consumedUnits) : '-'}</Table.Cell>
                </Table.Row>
                {(activeIndexes.indexOf(index) !== -1) ?
                  <>
                    {usageDetails && usageDetails[`${data.cycle}`] ?
                      <>
                        {Object.keys(usageDetails[`${data.cycle}`]).map((key, i) =>
                          <React.Fragment key={Math.random()}>
                            {usageDetails[`${data.cycle}`][`${key}`].callDetails.map((usage, j) =>
                              <React.Fragment key={Math.random()}>
                                <Table.Row className="usage-details-row no-border">
                                  {window.innerWidth < 768 ?
                                    <Table.Cell>
                                      <Grid>
                                        {j === 0 ?
                                          <>
                                            <Grid.Row>
                                              <Grid.Column width={16}><div><strong>{key}</strong></div></Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                              <Grid.Column width={12}>{key} Calls Total</Grid.Column>
                                              <Grid.Column width={4}>{numberToReadableFormat(usageDetails[`${data.cycle}`][`${key}`].totalCalls)}</Grid.Column>
                                            </Grid.Row>
                                          </> : ''}
                                        <Grid.Row>
                                          <Grid.Column width={12}>{usage.serviceSubType}</Grid.Column>
                                          <Grid.Column width={4}>{numberToReadableFormat(usage.unitsUsed)}</Grid.Column>
                                        </Grid.Row>
                                      </Grid>
                                    </Table.Cell>
                                  :
                                    <>
                                      <Table.Cell>
                                        {j === 0 ?
                                          <div>
                                            <div style={{padding: "10px 0 10px 10px"}}><strong>{key}</strong></div>
                                            <div style={{paddingLeft: "20px", paddingBottom: "5px"}}>
                                            {key} Calls Total
                                            </div>
                                          </div>
                                           : ''}
                                        <div style={{paddingLeft: "20px"}}>
                                          {usage.serviceSubType}
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        {(j === 0 && window.innerWidth >= 768) ?
                                          <div>
                                            <div style={{padding: "10px 0 10px 10px"}}>&nbsp;</div>
                                            {numberToReadableFormat(usageDetails[`${data.cycle}`][`${key}`].totalCalls)}
                                          </div>
                                        : ''}
                                        {numberToReadableFormat(usage.unitsUsed)}
                                      </Table.Cell>
                                    </>
                                  }
                                </Table.Row>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                        {window.innerWidth >= 768 ?
                          <Table.Row className="usage-details-row no-border">
                            <Table.Cell>&nbsp;</Table.Cell>
                            <Table.Cell>&nbsp;</Table.Cell>
                          </Table.Row>
                          : ''}
                      </>
                     : ''
                    }
                  </>
                  : ''
                }
              </React.Fragment>
            )}
          </Table.Body>
          <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <Pagination
                    totalPages={totalPages}
                    activePage={pageNo}
                    onPageChange={this.onChangePage}
                    boundaryRange={0}
                    siblingRange={0}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
        </Table>
      </div>
   </>
		);
	}
}

const mapStateToProps = (state) => ({
  user: getUser(state)
});

export default connect(mapStateToProps, actions)(StatementHistory);
