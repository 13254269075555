import React, { useState, useEffect } from "react";
import Search from "../organisms/Search";
import Charts from "../organisms/Charts";
import CountsBox from "../organisms/CountsBox";
import NodesMapPanel from "../molecules/NodesMapPanel";
import TopNfts from "../molecules/TopNfts";
import Page from "../atoms/Page";
import { Container, Grid } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

function HomePage() {
    let [isExpanded, setExpandState] = useState(false);
    // adding this to fix the issue of the Multiple API calls (rerendering of mobile block)
    const [screenSize, getDimension] = useState({ dynamicWidth: window.innerWidth });

    function updateState(value) {
        if (value == -1) {
            setExpandState(true);
        } else {
            setExpandState(false);
        }
    }

      const setDimension = () => {
        getDimension({ dynamicWidth: window.innerWidth })
      }

      useEffect(() => {
        window.addEventListener('resize', setDimension);

        return(() => {
            window.removeEventListener('resize', setDimension);
        })
      }, [screenSize])

    return (
        <Page>
        <Helmet>
            <title>Dragon Glass - The First Hedera Explorer</title>
        </Helmet>
            <Container fluid>
                <Search />
                <CountsBox />
                <TopNfts />
                <Charts />
                <NodesMapPanel />
            </Container>
        </Page>
    );
};

export default HomePage;
