import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Menu, Image, Dropdown, Container, Icon } from 'semantic-ui-react';
import logoDG from '../../assets/images/logo_dragonglass@2x.png';
import logoHH from '../../assets/images/logo_hedera.svg';
import menuImage from '../../assets/images/menu.svg';
import * as actions from '../../redux/actions';
import { isAuthed } from '../../redux/reducers';
import AccountSelector from './AccountSelector';
import NetworkSelector from './NetworkSelector';
import NavLinks from './NavLinks';
import { isMobile } from '../../utils';
import NotificationBar from '../NotificationBar';
import AnnouncementBar from '../AnnouncementBar';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom';

// import announcements from "../../reducers/announcements";

const userOptions = [
	{
		key: 'profile',
		text: 'PROFILE',
		value: 'profile',
		icon: 'id card'
	},
	{
		key: 'logout',
		text: 'SIGN OUT',
		value: 'logout',
		icon: 'power'
	}
];

const dappsOptions = [
	{
		key: 'deploy',
		text: <div className="item-hoverable">DEPLOY A NEW DAPP</div>,
		value: 'deploy',
		disabled: false
	},
	{
		key: 'manage',
		text: <div className="item-hoverable">MANAGE DAPP</div>,
		value: 'manage',
		disabled: true
	},
	{
		key: 'featured',
		text: 'FEATURED DAPPs',
		value: 'featured',
		disabled: false,
		sub: true
	}
	// {
	//   key: "medcore",
	//   text: "MEDCORE",
	//   value: "medcore"
	//   // disabled: true
	// },
	// {
	//   key: "sweattrack",
	//   text: "SWEATTRACK",
	//   value: "sweattrack"
	//   // disabled: true
	// }
];

const devOptions = [
	{
		key: 'api',
		text: <div className="item-hoverable">API ACCESS</div>,
		value: 'api'
		// disabled: true
	},
	{
		key: 'subscriptions',
		text: <div className="item-hoverable">REAL-TIME SUBSCRIPTIONS</div>,
		value: 'subscriptions'
		// disabled: true
	},
	{
		key: 'faqs',
		text: <div className="item-hoverable">FAQs</div>,
		value: 'faqs'
		// disabled: true
	},
	// {
	// 	key: 'dapps',
	// 	text: <div className="item-hoverable">DAPPs</div>,
	// 	value: 'dapps',
	// 	dapps: true
	// }
	// {
	//   key: "subscribe",
	//   text: "SUBSCRIBE",
	//   value: "subscribe",
	//   disabled: true
	// }
];

const tokenOptions = [
  {
		key: 'token',
		text: <div className="item-hoverable">FUNGIBLE</div>,
		value: 'fungible'
	},
  {
		key: 'token',
		text: <div className="item-hoverable">NON FUNGIBLE</div>,
		value: 'non-fungible'
	}
];

const currencyOptions = [
	{
		key: 'hbar',
		text: 'show in  ℏBar',
		value: 'hbar'
	},
	{
		key: 'tbar',
		text: 'show in  tBar',
		value: 'tbar'
	}
	// {
	//   key: "usd",
	//   text: "USD",
	//   value: "usd",
	//   disabled: true
	// }
];

class Nav extends Component {
	state = {
		menuOpen: false,
		notificationOpen: true
	};

	componentDidMount() {
		const { fetchConfig, fetchDapps, fetchAnnouncements } = this.props;
		fetchConfig();
		fetchDapps();
		fetchAnnouncements();
	}

	onOpenMenu = () => this.setState({ menuOpen: true });
	onCloseMenu = () => this.setState({ menuOpen: false });
	

	handleCloseNotification = () => {
		this.setState({ notificationOpen: false });
		this.props.hideAnnouncement();
	};

    handleCurrencyCookie = (currency, symbol) => {
       const cookies = new Cookies();
       cookies.set('selectedCurrency', currency, { path: '/' });
       cookies.set('selectedSymbol', encodeURIComponent(symbol), { path: '/' })
    }

	handleClick = (e, { value }) => {
		switch (value) {
			case 'logout':
				this.props.logout();
				break;
			case 'profile':
				this.props.history.push('/profile');
				break;
			case 'api':
				this.props.history.push('/apiview');
				break;
			case 'subscriptions':
				this.props.history.push('/subscriptionview');
				break;
			case 'faqs':
				this.props.history.push('/faqs');
				break;
			case 'featured':
				break;
			case 'medcore':
				this.props.history.push('/dapps/1');
				break;
			case 'sweattrack':
				this.props.history.push('/dapps/2');
				break;

			case 'deploy':
				this.props.history.push('/deploymentview');
				break;

			case 'hbar':
				console.log('HBAR CLICKED');
                this.handleCurrencyCookie('hbar', 'ℏ')
				this.props.selectHbar();
				break;
			case 'tbar':
				console.log('TINY BAR CLICKED');
                this.handleCurrencyCookie('tbar', 'tℏ')
				this.props.selectTbar();
				break;
			case 'usd':
				console.log('USD CLICKED');
				this.props.selectUSD();
				break;
			case 'fungible':
				this.props.history.push('/tokens/fungible');
				break;
			case 'non-fungible':
				this.props.history.push('/tokens/non-fungible');
				break;
			case 'dapps':
				break;
			default:
				this.props.history.push(`/dapps/${value}`);
		}
	};

	render() {
		let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth() + 1;
		let yyyy = today.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		let date = yyyy + '-' + mm + '-' + dd;

		// const validAnnouncement = this.props.announcements.find(ann => ann.date_from <= date && ann.date_to >= date && ann.active);

		const validAnnouncements = this.props.announcements.filter(
			(ann) => ann.date_from <= date && ann.date_to >= date && ann.active && ann.for_logged_in
		);
		const validNotifications = this.props.announcements.filter(
			(ann) => ann.date_from <= date && ann.date_to >= date && ann.active && !ann.for_logged_in
		);

		const announcementsToDisplay = validAnnouncements.map((ann) => (
			<AnnouncementBar onClose={this.handleCloseNotification} announcement={ann} />
		));

		const notificationsToDisplay = validNotifications.map((notif) => (
			<AnnouncementBar onClose={this.handleCloseNotification} announcement={notif} />
		));

		console.log('VALID ANNOUNCEMENTS: ', validAnnouncements);
		console.log('ALL ANNOUNCEMENTS: ', this.props.announcements);

		const { history, location, isAuthed = false } = this.props;
		// console.log('Path', location.pathname);
		const { menuOpen, notificationOpen } = this.state;

		// console.log("ANNOUNCEMENTS IN NAV.JS: ", this.props.announcements)
		// console.log("ismobile = ", isMobile());
		// console.log("===========props=====", this.props);
		return (
			<React.Fragment>
				{/* {!isAuthed && notificationOpen && <NotificationBar onClose={this.handleCloseNotification} />} */}

				{!isAuthed && notificationOpen && validNotifications && notificationsToDisplay}

				{isAuthed && notificationOpen && validAnnouncements && announcementsToDisplay}

				{/* {isAuthed && notificationOpen && validAnnouncement && (
          <AnnouncementBar onClose={this.handleCloseNotification} announcement={validAnnouncement}  />
        )} */}

				<Segment inverted className="app-header">
					<Menu secondary inverted>
						<Container fluid={true}>
							<Menu.Item className="action action--open" onClick={this.onOpenMenu}>
								<Image src={menuImage} name="sidebar" aria-label="Open Menu" />
							</Menu.Item>

							{!isMobile() && (
								<Menu.Item onClick={() => history.push('/')}>
									<Image size="small" src={logoDG} />
									<Image
										size="small"
										src={logoHH}
										className="logo-hedera"
										style={{ marginLeft: '0.7em', marginTop: '-0.5em' }}
									/>

									{/* <Image size="small" src={logoHH} /> */}
								</Menu.Item>
							)}

							{isMobile() && (
								<div onClick={() => history.push('/')}>
									<Image size="small" src={logoDG} />
									<Image
										size="small"
										src={logoHH}
										className="logo-hedera"
										style={{ marginTop: '-0.5em' }}
									/>
								</div>
							)}

							{/* For mobile View */}
							<NavLinks open={menuOpen} onClose={this.onCloseMenu} />

							{/* For PC View */}
							<Menu.Menu position="right" className="nav-desktop">
								{/* { !isMobile()
									? <Menu.Item>{<AccountSelector />}</Menu.Item>
									: ''
								} */}
								<Menu.Item style={{border: "1px dashed cyan"}}>
										<Link to="/nft-explorer">NFT Explorer</Link>
										<span style={{color: "cyan", position: "absolute", bottom: "60%", right: '4px', fontSize: "10px"}}>New</span>
								</Menu.Item>
								<Menu.Item>
									{/* <Dropdown text={'DEV TOOLS'} /*options={devOptions}> */}
									<Dropdown text={'DEVELOPER RESOURCES'} /*options={devOptions}*/>
										<Dropdown.Menu>
											{devOptions.map((option) => (
												<Dropdown.Item
													key={option.value}
													{...option}
													onClick={this.handleClick}
												>
													{option.dapps === true ? (
														<Dropdown text={option.key}>
															<Dropdown.Menu className="submenu">
																{dappsOptions.map((dappOption) => (
																	<Dropdown.Item
																		key={dappOption.value}
																		{...dappOption}
																		onClick={this.handleClick}
																	>
																		{dappOption.sub == true ? (
																			<Dropdown text={dappOption.text}>
																				<Dropdown.Menu className="submenu">
																					{this.props.dapps
																						.filter((dapp) => dapp.active === true)
																						.sort((a, b) => a.sortIndex - b.sortIndex)
																						.slice(0, 10)
																						.map((dappOption) => (
																							<Dropdown.Item
																								key={dappOption.id}
																								text={
																									<div className="item-hoverable">
																										{dappOption.title}
																									</div>
																								}
																								value={dappOption.id}
																								onClick={this.handleClick}
																							/>
																						))}
																				</Dropdown.Menu>
																			</Dropdown>
																		) : (
																			dappOption.text
																		)}
																	</Dropdown.Item>
																))}
																<Dropdown.Item>
																	<Link to="/dappcatalog">DAPP CATALOG</Link>
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													) : option.text}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</Menu.Item>

								<Menu.Item>{<NetworkSelector />}</Menu.Item>

								<Menu.Item>
									<Dropdown direction="left" text={'TOKENS'} /*options={devOptions}*/>
										<Dropdown.Menu>
											{tokenOptions.map((option) => (
												<Dropdown.Item
													key={option.value}
													{...option}
													onClick={this.handleClick}
												/>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</Menu.Item>

								<Menu.Item>
									<Link to="/pricing">PRICING</Link>
								</Menu.Item>

								{/* <Menu.Item>
									<Link to="/about">ABOUT</Link>
								</Menu.Item> */}

								{/*--------------------- CURRENCY SELECTION ---------------------*/}
								<div
									className={
										this.props.match.path.includes('home') ||
										this.props.match.path.includes('apiview') ||
										this.props.match.path.includes('subscriptionview') ||
										this.props.match.path.includes('deploymentview') ||
										this.props.match.path.includes('dappcatalog') ||
										this.props.match.path.includes('profile') ||
										this.props.match.path.includes('about') ||
										this.props.match.path.includes('terms') ||
										this.props.match.path.includes('privacy') ||
										this.props.match.path.includes('contact-us') ? (
											'hidden'
										) : (
											'visible'
										)
									}
								>
									<Menu.Item>
										<Dropdown
											direction="left"
											text={this.props.symbol}
										>
											<Dropdown.Menu>
												{currencyOptions.map((option) => (
													<Dropdown.Item
														key={option.value}
														{...option}
														onClick={this.handleClick}
													/>
												))}
											</Dropdown.Menu>
										</Dropdown>
									</Menu.Item>
								</div>
								{/* ------------------------------------------------------------- */}

								{/*-------- CURRENCY SELECTION OR LINK TO THE SEARCH PAGE --------*/}
								{/* {(this.props.match.path.includes("home") ||
                  this.props.match.path.includes("apiview") ||
                  this.props.match.path.includes("subscriptionview") ||
                  this.props.match.path.includes("deploymentview") ||
                  this.props.match.path.includes("dappcatalog") ||
                  this.props.match.path.includes("profile") ||
                  this.props.match.path.includes("about") ||
                  this.props.match.path.includes("terms") ||
                  this.props.match.path.includes("privacy") ||
                  this.props.match.path.includes("contact-us")) ?
                  <Menu.Item>
                    <Link to="/search"><Icon name="compass outline" size="large" /></Link>
                  </Menu.Item> :
                  <Menu.Item>
                  <Dropdown
                    direction="center"
                    text={<span style={{fontSize: "120%"}}>{this.props.symbol}</span>}
                  >
                    <Dropdown.Menu>
                      {currencyOptions.map(option => (
                        <Dropdown.Item
                          key={option.value}
                          {...option}
                          onClick={this.handleClick}
                        ></Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>} */}
								{/* ------------------------------------------------------------- */}

								{!isAuthed &&
								location.pathname !== '/login' && (
									<Menu.Item>
										<Link to="/login">SIGN IN</Link>
									</Menu.Item>
								)}

								{isAuthed && (
									<Menu.Item>
										<Dropdown icon={<Icon name="user circle" size="large" />} floating labeled>
											<Dropdown.Menu>
												{userOptions.map((option) => (
													<Dropdown.Item
														key={option.value}
														{...option}
														onClick={this.handleClick}
													/>
												))}
											</Dropdown.Menu>
										</Dropdown>
									</Menu.Item>
								)}

								{/* <Menu.Item>
									<div style={{ textAlign: "center", background: "#ababab", padding: "4%", width: "100%"}}>
										<span style={{fontSize: "20px", color: "green"}}>NFT Explorer</span>
										<span style={{color: "red", position: "absolute", bottom: "35%", fontSize: "12px"}}>New</span>
									</div>
								</Menu.Item> */}
							</Menu.Menu>
						</Container>
					</Menu>
				</Segment>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthed: isAuthed(state),
	dapps: state.dapps,
	symbol: state.symbol,
	announcements: state.announcements
});

export default withRouter(connect(mapStateToProps, actions)(Nav));
