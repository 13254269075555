import React from 'react';
import { Table } from 'semantic-ui-react';

export default function TransactionTableHeader(props) {
	return (
		<Table.Row column={5}>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'transactionType' ? props.direction : null}
				onClick={() => props.handleSort('transactionType')}
			>
				Type
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'consensusTime' ? props.direction : null}
				onClick={() => props.handleSort('consensusTime')}
			>
				Date
			</Table.HeaderCell>
			<Table.HeaderCell
				width={3}
				sorted={props.column === 'amount' ? props.direction : null}
				onClick={() => props.handleSort('amount')}
			>
				Amount
			</Table.HeaderCell>
			<Table.HeaderCell
				width={3}
				// sorted={props.column === 'amount' ? props.direction : null}
				// onClick={() => props.handleSort('amount')}
			>
				Status
			</Table.HeaderCell>
			<Table.HeaderCell
				width={4}
				sorted={props.column === 'memo' ? props.direction : null}
				onClick={() => props.handleSort('memo')}
			>
				Memo
			</Table.HeaderCell>
			{/* <Table.HeaderCell width={1} /> */}
		</Table.Row>
	);
}
