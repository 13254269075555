import React, { Component } from 'react';
import Panel from './shared/Panel';
import PeriodSelector from './shared/PeriodSelector';
import DappContractTxnVolumeChart from './containers/DappContractTxnVolumeChart';
import Info from './shared/Info';
import { parseQueryParams } from  '../utils';
import { withRouter } from 'react-router-dom';
import Share from './shared/Share';

export class DappContractTxnVolumeChartPanel extends Component {
	state = {
		period: '1W',
		dappId: '',
	};

	componentDidMount() {
		const params = parseQueryParams(this.props.location.search);
		if(params && params.period) {
				this.setState({
						period: params.period,
				});
		}
	}

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
	};

	sendDappIdparam = (id) => {
		this.setState({ dappId: id });
	}

	render() {
		const { period } = this.state;
		let url = window.location.host + `/chart?name=dapp-token-volume&period=${this.state.period}&dappId=${this.state.dappId}`;
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						TRANSACTION VOLUME <Info id="txVolume" />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				footer={<Share url={url} params={params} title={"Dragon Glass Dapp Contract Txn Volume Chart"}/>}
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				<DappContractTxnVolumeChart period={period} sendDappIdparam={this.sendDappIdparam} />
			</Panel>
		);
	}
}

export default withRouter(DappContractTxnVolumeChartPanel);