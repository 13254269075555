import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, Icon } from 'semantic-ui-react';
import * as actions from '../../redux/actions';
import { getRawTransaction } from '../../redux/reducers';
import CodeSnippet from '../shared/CodeSnippet';

class RawTransaction extends Component {
	state = {
		showRaw: false
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.txId !== prevProps.txId) {
			this.fetchData();
		}
	}

	fetchData = () => {
		const { fetchRawTransaction, txId } = this.props;
		if (txId) fetchRawTransaction(txId);
	};

	onShowRaw = () => {
		this.setState({ showRaw: true });
	};

	onHideRaw = () => {
		this.setState({ showRaw: false });
	};

	render() {
		const { raw } = this.props;
		const { showRaw } = this.state;
		return (
			<React.Fragment>
				<Header as="h5" image>
					<Header.Content>
						RAW TRANSACTION
						<Header.Subheader onClick={this.onShowRaw} className="clickable">
							{raw ? <Icon name="eye" /> : 'N/A'}
						</Header.Subheader>
					</Header.Content>
				</Header>

				{showRaw && (
					<React.Fragment>
						<CodeSnippet code={raw ? raw.data : ''} onClose={this.onHideRaw} title="" />
						{raw &&
						raw.fileName && (
							<Header as="h5">
								<Header.Content>
									FILE NAME
									<Header.Subheader>{raw.fileName}</Header.Subheader>
								</Header.Content>
							</Header>
						)}
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state, { match: { params: { txId } } }) => ({
	raw: getRawTransaction(state, txId)
});

RawTransaction = withRouter(connect(mapStateToProps, actions)(RawTransaction));

export default RawTransaction;
