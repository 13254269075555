import React from "react";

const info = {
    nodesMap:
        "Map represents the number and the geographical locations of the Council nodes, the total number of transactions processed and the amount of hbar earned for each node over the selected period of one hour, one day or one week. Rolling over each node will display these values. In addition you can click on the circular blue arrow to scroll through the council nodes.",
    totalTxns: "Transactions displays the total count of all transaction types since 8/17/2019.",
    totalAccounts:
        "Total Accounts is the total number of accounts created on the Hedera Hashgraph network, along with the account number of the latest account created.",
    featuredDapps:
        "The featured dapps table displays a list of curated dapps that have been selected to highlight some of the innovative dapps running on the Hedera Hashgraph network.",
    news:
        "Displays a list of curated news that have been selected to highlight some of the latest news and information regarding DragonGlass and the Hedera Hashgraph network.",
    counts:
        "Transactions displays the total count of all transaction types since 31st July 2019 (oldest data is actually from 8/17/2019). Total Accounts is the total number of accounts created on the Hedera Hashgraph network along with the account number of the latest account created.",
    hbarPrice: `The Hedera Network HBAR price displays the most recent HBAR price that was represented on the Hedera Hashgraph network at the time of the
		last transaction. The HBAR exchange rates published by Binance, Okex, and Bittrex are taken from public websites of each respective exchange and
		are updated every 10 minutes.`,
    txVolume: `Charts the transaction count over the selected period of time. Shows the total transaction count for the selected period and the percentage change over the prior period. Time period shown on the chart is in UTC.`,
    accountCreationVolume: `Accounts created during this specified time period, Time period shown on the chart is in UTC.`,
    txValue: `Charts the hbars transacted over the selected period of time.  Shows the total hbar transacted for the selected period and the percentage change over the prior period. Time period shown on the chart is in UTC.`,
    txTypes: (
        <div>
            <div>
                Charts the breakdown of available service types over the selected period of time. The content of the
                chart represents the number and percentage of transactions for the service type in a selected period. By
                clicking on either the pie chart area or actual text below the chart representing service type will
                direct you to all the transactions for the service type.
            </div>
            <div style={{ marginTop: "5px" }}>
                * PLEASE NOTE: the chart does not display service types with a volume of less than 1%. Absolute values
                of such service types will be displayed below the chart instead of within it.
            </div>
        </div>
    ),
    htsTokenTxValue: (
        <div>
            <div>
                Charts the breakdown of available service types over the selected period of time. The content of the
                chart represents the number and percentage of transactions for the service type in a selected period. By
                clicking on either the pie chart area or actual text below the chart representing service type will
                direct you to all the transactions for the service type.
            </div>
        </div>
    ),
    // `Charts the breakdown of transaction types over the selected period of time. The chart shows the percent of transactions by type and the count of transaction types for the period. `,
    gasPrice: "Gas price represents the cost of a unit of gas in tiny bar",
    accountSummary:
        "The Summary control will list the current balance of the account in hbar.  The user can also view the account public key, expiration date of the account, and any memo information associated with the account.",
    transfers:
        "The Payments and Receipts control table lists all of the historical debits and credits associated with the account. The payments and receipt information is derived from transaction records. Each transaction is processed and parsed into debit and credit line items. Rolling over the blue account bars will show a complete list of all account transfers associated with this payment or receipt. This definition relates to Hedera “Receipts” since in order to view payment or receipt transaction details, a user would click on the eye icon and be taken to the detail transaction page for that line item.",
    txns:
        "The Transactions table lists all of the historical transactions associated with the account. In order to view transaction details, a user would click on the eye icon and be taken to the detail transaction page for that line item.",
    balanceHistory: "Displays historical balance (in HBARs) information. Time period shown on the chart is in UTC.",
    files: "The Files control lists any files that are associated with this account.",
    subscription:
        "The subscription function will allow you to subscribe and receive alerts on any activity associated with an account. You must create an account on DragonGlass to receive Hedera account alerts",
    txDetails:
        "The transaction details page allows a user to review all attributes associated with a transaction.  Clicking the Raw Transaction eye icon will display the entire json file for the transaction.",
    subscribedAccounts: `The table shows a list of accounts that you are following. All your subscribed accounts will appear under the 'ACCOUNTS' navigation link on top, making it easy for you to directly navigate to the account page. You can also subscribe for real-time alerts as SMS/Email. Note: Hedera reserved accounts i.e. Account numbers below 1001 are not allowed for real-time alerts.`,
    contractSummary: `The Summary control will list the current balance of the contract in hbar. The user can also view the contract's  admin key, expiration date, creation date, last updated date, file id, and associated memo information.`,
    contractsTxns: `The Transactions table lists all of the historical transactions associated with the contract. In order to view transaction details, a user would click on the eye icon and be taken to the detail transaction page for that line item.`,
    contractUsers: `Total number of distinct accounts that interacted with this contract in the selected time period.`,
    apiKeys: `You can deactivate and reactivate your API Keys by clicking directly the status of the key in the Status column.`,
    subscriptions: `You can create and maintain up to 3 active subscriptions. The default region for all the queues created will be AWS us-east-1.`,
    subscriptionType: `You can add an AWS User ARN to a Subscription by clicking the Edit icon next to the Subscription Type.`,
    demoVideo: `This video provides a brief overview of the DragonGlass Explorer. It covers how to create a profile on DragonGlass,
		how to search historical data, and how to incorporate this data into applications using DragonGlass APIs.`,
    searchVideo: `This video provides a brief overview of the DragonGlass Explorer Search capabilities. It covers how to conduct basic and advanced search to discover transactions, accounts, & contracts`,
    utcDates: "All the dates are in UTC.",
    top10Tokens: "Top Hedera tokens based on tokens transfered in last 24 hours",
    tokenBalances: "Balances shown are not adjusted based on the decimal value for the token",
    tokenTransfersChart:
        "Charts the total # of token transferred over the selected time period. Value is adjusted based on the token Decimal. Time period shown on the chart is in UTC.",
    transactionsVolume: "This includes token transfers in last 24 hours",
    priceToptokens: "Pricing data coming soon",
    volumeTopTokens: "This includes number of tokens transfered in last 24 hours",
    hederaTokensTooltip: "Sorted by token creation date",
    nftsAccount: "Nfts owned by the account",
    nftsToken: "Nfts minted",
    recentNfts: "Recently Minted Nfts",
    nftsTransfers: "Nfts Transfers",
    tokenTransfers: "Token Transfers",
    tokenMinted: "Token Minted",
    hbarTransfers: "HBAR Transfers",
    binancePrice: `The HBAR exchange rate is from Binance public website. The rates are updated after every 10 minutes.`,
    // facets
    transactionTypes: 'These are the most frequently executed transaction types.',
    transactionsPayerID: 'These accounts that generated the most transaction volume.',
    serviceTypes: 'These are the most frequently used services.',
    accountsPayerID: 'These are the  top creators of Hedera accounts.',
    contractsPayerID: 'These are the top creators of smart contracts.',
    topicsPayerID: 'These are the top creators of consensus topics.',
    Treasury_Account: 'These are the treasury accounts that hosts the most token types.',
    Tokens: "These are NFT type with the most minted NFTs.",
    Owners: 'These are the accounts that own the most NFTs.',
    activeCollections: 'Active NFT Collections based on # of NFTs minted or transferred in last 24 hours.',
    accountExpiry: 'Account Expiry is not turned on yet. This field is not relevant.',
    serialNumberForm: 'Leave this field empty to include all NFTs in this collection.',
};

export default info;
