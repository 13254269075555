import React, { Component } from 'react';
// import Panel from './shared/Panel';
import Nodes from '../organisms/Nodes';
import PeriodSelector from '../components/shared/PeriodSelector';
import Info from '../components/shared/Info';
import { Button, Grid, Icon, Container } from 'semantic-ui-react';

const styles = {
	containerStyle: {
		marginTop: '2%',
	  },
	contentGrid: {
		marginBottom: '20px',
	  },
}
export default class NodesMapPanel extends Component {
	constructor(props) {
    super(props);
    this.state = {
      period: '1D',
	};
	this.onPeriodSelect = this.onPeriodSelect.bind(this);
  }

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
	};

	render() {
		const { period, view, activeIndex } = this.state;
		return (
			<Container textAlign="center" style={styles.containerStyle} fluid className="w-98">
				<Grid stackable>
					<Grid.Row style={{background: "black", color: 'white', padding: '5px'}}>
						<div>
							<span style={{padding: '12px'}}>Nodes</span>
							<span style={{padding: '4px', background: "white", color: 'black',}}><PeriodSelector value={period} onSelect={this.onPeriodSelect} /></span>
						</div>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column style={{padding: '0px'}} mobile={16} computer={16} widescreen={16}>
							<Nodes period={period} view={view} activeIndex={activeIndex} />
						</Grid.Column>
					</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<p>
									<small>
										* Txns is the number of transactions submitted through that node. A node with a zero may
										still be running and validating transactions correctly, just not submitting new
										transactions.
									</small>
								</p>
							</Grid.Column>
						</Grid.Row>
				</Grid>
			</Container>
		);
	}
}
