import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TransactionSummary from '../organisms/TransactionSummary';
import { Grid, Container, Popup } from 'semantic-ui-react';
import Number from '../atoms/Number';
import * as actions from '../redux/actions';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import LoadingIndicator from '../components/containers/LoadingIndicator';
import { localDateTime } from '../utils';
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyTextToClipboard from "../components/CopyTextToClipboard";


const styles = {
	accountHeader: {
		display: 'flex',
	},

	accountHeader: {
		display: 'flex',
	},
	
	paddingRight: {
		paddingRight: '20px'
	},
	txId: {
		display: 'flex',
		paddingRight: '20px',
		margin: '0px',
		alignItems: 'end'
	},
	ellipses: {
		// textOverflow: 'ellipsis',
		// wordWrap: 'break-word'
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	},
	alignLeft: {
		textAlign: 'left'
	}
}

const TransactionSummaryPanel = (props) => {

	const [clicked, setClicked] = useState(false);

	const handleCopyClick = () => {
        console.log("MESSAGE txn metaData");
        // audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000);
    };

	const { transaction, txId } = props
	const renderStatus = () => {
		if (transaction) {
			switch (transaction.status) {
				case 'SUCCESS':
					return <h3
					style={{color: 'green'}}
				> {transaction.status}</h3>
				
				default:
					return <h3
						style={{color: 'red'}}
					> {transaction.status}</h3>
			}
		}
	}

		if (!transaction) {
			return <LoadingIndicator />
		} else {
			return (<>
					<Helmet>
						<title>Dragon Glass - Transaction { transaction ? transaction.readableTransactionID : txId }</title>
					</Helmet>
						<Grid padded stackable>
							<Grid.Row width={16} style={{borderBottom: '1px solid black'}}>
								<Grid.Column computer={5} tablet={5} mobile={16}>
								<Popup
									trigger={<h2 style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left' }} >{transaction.readableTransactionID}</h2>}
									content={transaction.readableTransactionID}
									position="top center"
									hoverable
								/>
									
								</Grid.Column> 
								<Grid.Column style={styles.alignLeft} computer={2} tablet={4} mobile={16}>
									<h2 >\\Transaction</h2>
								</Grid.Column>
								<Grid.Column computer={4} tablet={3} mobile={16} style={{paddingTop: '6px', textAlign: 'left'}}>
									{renderStatus()}
								</Grid.Column>
							</Grid.Row>
							<Grid.Row width={16}>
								<Grid.Column width={8} style={{
										fontWeight: '500',
										fontSize: '16px',
										textAlign: 'left'
								}}>
												{/* <Popup
													trigger={<div style={styles.ellipses}><span>Transaction Hash: </span>{props.transaction && props.transaction.transactionHash ? 
													<CopyToClipboard text={props.transaction.transactionHash} onCopy={() => handleCopyClick({ copied: true })}>
														<span>
															<CopyTextToClipboard clicked={clicked} />
														</span>
													</CopyToClipboard> : 'None'}</div>}
													content={<span>{props.transaction.transactionHash}
													</span>}
													position="top center"
													hoverable
												/> */}
												<Grid>
													<Grid.Row>
														<Grid.Column width={4}>Transaction Type:</Grid.Column>
														<Grid.Column width={12}>{props.transaction && props.transaction.typeLabel ? props.transaction.typeLabel : 'None'}</Grid.Column>
													</Grid.Row>
													<Grid.Row>
														<Grid.Column width={4}>Transaction Hash:</Grid.Column>
														<Grid.Column width={12} style={{ textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
														<span>{props.transaction.transactionHash}
															<CopyToClipboard text={props.transaction.transactionHash} onCopy={() => handleCopyClick({ copied: true })}>
																<span>
																	<CopyTextToClipboard clicked={clicked} />
																</span>
															</CopyToClipboard>
														</span>
														</Grid.Column>
													</Grid.Row>
												</Grid>
								</Grid.Column>
								<Grid.Column width={8} style={{
									fontWeight: '500',
									fontSize: '16px',
									textAlign: 'right'
								}}>
									<Grid>
										<Grid.Row style={{textAlign: 'left'}}>
											<Grid.Column only="tablet computer" computer={6} widescreen={6}></Grid.Column>
											<Grid.Column mobile={4} computer={4} widescreen={4}>Consensus Timestamp:</Grid.Column>
											<Grid.Column mobile={12} computer={6} widescreen={6}>{localDateTime(props.transaction && props.transaction.startTime)}</Grid.Column>
										</Grid.Row>
									</Grid>
									{/* <p> <span> Consensus Timestamp: </span> {localDateTime(props.transaction && props.transaction.startTime)}</p> */}

									{/* {
										props.transaction && props.transaction.transactionHash && (
											<p>
												<Popup
													trigger={<div style={styles.ellipses}><span className='bold'>Transaction Hash: </span>{props.transaction.transactionHash}</div>}
													content={<span>{props.transaction.transactionHash}</span>}
													position="top center"
													hoverable
												/>
											</p>
										)
									} */}
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<TransactionSummary {...props}/>
							</Grid.Row>
						</Grid>
				</>
			);
		}
}

const mapStateToProps = (state, { match: { params: { txId } } }) => ({
	txId: txId,
});

export default connect(mapStateToProps, actions)(TransactionSummaryPanel);
