import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ApiCopyUrl from './ApiCopyUrl';

import '../animate.css';

const APICodePython = ({api}) => {

    const handleCopyClick = () => {
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000)
    }
    
    const audio = new Audio('/click.mp3');

    const [ clicked, setClicked ] = useState(false);

    const codeString =

`import requests

url = "${api.url}"

payload = {}
headers = {
    'X-API-KEY': '<YOUR_ACCESS_KEY>'
}

response = requests.request("GET", url, headers=headers, data = payload)

print(response.text.encode('utf8'))`;

    return (

        <div style={{overflowWrap: "anywhere", fontSize: "95%"}}>

            <CopyToClipboard text={codeString}
                onCopy={() => handleCopyClick({copied: true})}>
                <span style={{marginLeft: "1%", position: "relative", top: "-10px"}}><ApiCopyUrl clicked={clicked} /></span>
            </CopyToClipboard>

            <div style={{position: "relative", top: "-18px"}}>
                <SyntaxHighlighter language="python" style={docco}>
                    {codeString}
                </SyntaxHighlighter>
            </div>

        </div>
    );

};
export default APICodePython;