import { combineReducers } from 'redux';
import { 
	FETCH_DAPP_SUCCESS, 
	FETCH_DAPP_FAILURE, 
	FETCH_DAPP_CONTRACTS_SUCCESS, 
	FETCH_DAPP_CONTRACTS_FAILURE,
	FETCH_DAPP_TRANSACTIONS_SUCCESS,
	FETCH_DAPP_TRANSACTIONS_FAILURE,
	FETCH_DAPP_TRANSFERS_SUCCESS,
	FETCH_DAPP_TRANSFERS_FAILURE,
	FETCH_DAPP_TOKEN_TRANSFERS_SUCCESS,
	FETCH_DAPP_TOKEN_TRANSFERS_FAILURE, 
	RESET_DAPP_STATE} from '../types';

	
const addToDapp = (state, id, data) => {
	const dapp = state[id];
	return {
		...state,
		[id]: {
			...(dapp ? dapp : {}),
			...data
		}
	};
};

const addEntry = (state, dapp) => {
	const { id, ...rest } = dapp;
	return addToDapp(state, id, rest);
};

const addContracts = (state, action) => {
	const { id, payload } = action;
	return addToDapp(state, id, { contracts: payload });
};

const addTransactions = (state, action) => {
	const { id, response } = action;
	return addToDapp(state, id, { transactions: response });
};

const addTransfers = (state, action) => {
	const { id, response } = action;
	return addToDapp(state, id, { transfers: response });
};

const addTokenTransfers = (state, action) => {
	const { id, response } = action;
	console.log("THIS IS THE RESPONSE IN DAPP REDUCER: ", response)
	return addToDapp(state, id, { tokenTransfers: response });
};

const dappById = (state = {}, action) => {
	switch (action.type) {
		case FETCH_DAPP_SUCCESS:
			return addEntry(state, action.payload);
		case FETCH_DAPP_FAILURE:
			return state;
		case FETCH_DAPP_CONTRACTS_SUCCESS:
			return addContracts(state, action);
		case FETCH_DAPP_TRANSACTIONS_SUCCESS:
			return addTransactions(state, action);	
		case FETCH_DAPP_TRANSFERS_SUCCESS:
			return addTransfers(state, action);
		case FETCH_DAPP_TOKEN_TRANSFERS_SUCCESS:
			return addTokenTransfers(state, action);
		case RESET_DAPP_STATE:
			return {...state, tokenTransfers: {}};
		default:
			return state;
	}
};

// const alltransactions = (state = [], action) => {
// 	switch (action.type) {
// 		case FETCH_TRANSACTIONS_SUCCESS:
// 			const entries = action.response.data.reduce((r, t) => {
// 				if (!state.includes(t.transactionID)) r.push(t.transactionID);
// 				return r;
// 			}, []);
// 			return [ ...state, ...entries ];
// 		default:
// 			return state;
// 	}
// };

const dappReducer = combineReducers({
	dappById: dappById,
	// allIds: alltransactions
});

export default dappReducer;

export const getDapp = (state, id) => state.dappById[id];

export const getDappContracts = (state, id) => {
	const dapp = getDapp(state, id);
	if (dapp && dapp.contracts) {
		return dapp.contracts;
	}
	else return [];
};

export const getDappTransactions = (state, id) => {
	const dapp = getDapp(state, id);
	if (dapp && dapp.transactions) return dapp.transactions.data;
	else return [];
};

export const getDappTransfers = (state, id) => {
	const dapp = getDapp(state, id);
	if (dapp && dapp.transfers) {
		return dapp.transfers.data;
	}
	else return [];
};

export const getDappTokenTransfers = (state, id) => {
	const dapp = getDapp(state, id);
	if (dapp && dapp.tokenTransfers) {
		console.log("IN DAPP TOKEN TRANSFERS SELECTOR: ", dapp.tokenTransfers)
		return dapp.tokenTransfers;
	}
	else return [];
};

export const getDappTransfersCount = (state, id) => {
	const dapp = getDapp(state, id);
	if (dapp && dapp.transfers) return dapp.transfers.totalCount;
	else return [];
};

// export const getTransactions = (state) => state.allIds;
// export const getRawTransaction = (state, id) => (state.byId[id] ? state.byId[id].raw : null);