import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	SAVE_USER_PROFILE_SUCCESS,
	FETCH_USER_ACCOUNTS_SUCCESS,
	ADD_USER_ACCOUNT_SUCCESS,
	REMOVE_USER_ACCOUNT_SUCCESS,
	UPDATE_USER_ACCOUNT_SUCCESS
} from '../types';
import { DG_USER } from '../../constants';

let user = JSON.parse(localStorage.getItem(DG_USER));
const initialState = user ? { loggedIn: true, accounts: [], user } : { loggedIn: false, accounts: [] };

export default function auth(state = initialState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				loggedIn: true,
				user: { ...state.user, ...action.user }
			};
		case SAVE_USER_PROFILE_SUCCESS:
			return {
				...state,
				user: { ...state.user, ...action.profile }
			};
		case FETCH_USER_ACCOUNTS_SUCCESS:
			return {
				...state,
				user: { ...state.user, accounts: action.response }
			};

		case ADD_USER_ACCOUNT_SUCCESS:
			return {
				...state,
				user: { ...state.user, accounts: [ ...state.user.accounts, action.account ] }
			};
		case REMOVE_USER_ACCOUNT_SUCCESS:
			const newAccounts = state.user.accounts.filter((account) => account.accountId !== action.account.accountId);
			return {
				...state,
				user: { ...state.user, accounts: newAccounts }
			};
		case UPDATE_USER_ACCOUNT_SUCCESS:
			const accounts = state.user.accounts.map((account) => {
				if (account.accountId === action.account.accountId) {
					const updatedAccount = { ...account, ...action.account };
					return updatedAccount;
				} else {
					return account;
				}
			});

			return {
				...state,
				user: { ...state.user, accounts }
			};
		case LOGOUT_SUCCESS:
			return { loggedIn: false, accounts: [] };
		default:
			return state;
	}
}

export const isAuthed = (state) => state.loggedIn;
export const getUser = (state) => state.user;
export const getUserAccounts = (state) =>
	state.user && state.user.accounts && state.user.accounts.length ? state.user.accounts : [];
