import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Autocomplete from '../shared/Autocomplete';
import * as actions from '../../redux/actions';
import { removeEmpty } from '../../utils';
import { FUNGIBLE_TOKEN, NON_FUNGIBLE_TOKEN } from '../../constants';

class Search extends Component {
	constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSearchChange = (e, { value }) => {
		this.setState({ value });
	};

	handleSubmit = (e, data) => {
		const { value } = this.state;
		if (this.props.onlyHts) {
			let params = {q: value.trim()};
			const { fetchTokens, location } = this.props;
			let tokenType = '';
			if (location && location.pathname && location.pathname.includes(NON_FUNGIBLE_TOKEN)) {
				tokenType = 'NON_FUNGIBLE_UNIQUE';
			} else if(location && location.pathname && location.pathname.includes(FUNGIBLE_TOKEN)) {
				tokenType = 'FUNGIBLE_COMMON';
			}
			if (tokenType) {
				params.tokenType = tokenType
			}
			const queryParams = removeEmpty(params);

			fetchTokens(queryParams);
			e.preventDefault();
		} else {
			const query = value && value.trim().length > 0 ? `q=${value.trim()}` : '';
			this.props.history.push(`/search?${query}`);
		}
	};

	render() {
		const { value } = this.state;
		const {onlyHts} = this.props;
		const placeholder = <span style={{fontSize: "12px", color: "#999"}}></span>
		return (
			<Autocomplete
				// loading={isLoading}
				onResultSelect={this.handleResultSelect}
				onSearchChange={this.handleSearchChange}
				onSubmit={this.handleSubmit}
				// results={results}
				value={value}
				placeholder={this.props.onlyHts ? "Search Hedera Tokens" : "Search accounts, transactions, etc."}
				showNoResults={false}
				onlyHts={this.props.onlyHts}
			/>
		);
	}
}

Search = withRouter(connect(null, actions)(Search));

export default Search;
