import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Table, Image, Pagination } from 'semantic-ui-react';
import * as actions from '../../redux/actions';
import { getUser } from '../../redux/reducers';
import { MONTHLY, YEARLY } from "../../constants";
import { fetchPurchaseDetails, fetchUserInvoices } from "../../apis/index";
import moment from 'moment';
import { numberToReadableFormat, formatCurrency, getSubscriptionPricing, payWithLink, formatNumber, getMerchantAccountId, injectDroppSdk } from "../../utils/index";
import Info from '../shared/Info';
import DroppIcon from "../../assets/images/dropp_icon@2x.png";
import PageSizeSelect from '../shared/PageSizeSelect';
import { getEnvConfigs } from '../../redux/reducers/envConfig';

class PaymentHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexes: [],
      invoices: [],
      invoiceDetails: {},
      hasUnpaidInvoice: false,
      subscriptionPricing: [],
      pageSize: 5,
      pageNo: 1,
      totalPages: 0,
      totalCount: 0,
      showRenewalLink: false,
      allFeatures: null
    };
    this.getPurchaseDetails = this.getPurchaseDetails.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.setShowRenewalLinkFlag = this.setShowRenewalLinkFlag.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

	componentDidMount() {
    this.props.fetchAllFeatures(this.props.user.id);
    this.props.fetchPricingPlans();
    this.getInvoices({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email});
    injectDroppSdk();
  }

  prepareInvoicesData(invoices, showRenewalLink) {
    let filteredInvoices = [];
    for (let i = 0; i < invoices.length; i++) {
      invoices[i].displayDate = moment.utc(invoices[i].purchaseDate).format('MMM DD, YYYY');
      invoices[i].showRenewalLink = showRenewalLink;
      filteredInvoices.push(invoices[i]);
    }
    return filteredInvoices;
  }

  setShowRenewalLinkFlag(params, allInvoicesData, unpaidInvoice) {
    fetchUserInvoices(params).then(resp => {
      if (resp && resp.data && resp.data.length) {
        let showRenewalLink = true;
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i].status === "PAID" && resp.data[i].expiryDate && moment.utc(resp.data[i].expiryDate).unix() > moment.utc(unpaidInvoice.startDate).unix()) {
            showRenewalLink = false;
            break;
          }
        }
        this.setState({
          invoices: this.prepareInvoicesData(allInvoicesData.data, showRenewalLink),
          totalPages: allInvoicesData.totalPages,
          totalCount: allInvoicesData.totalCount,
          hasUnpaidInvoice: showRenewalLink,
          pageNo: (params.pageNo || params.pageNo === 0 ? (params.pageNo + 1) : this.state.pageNo)
        });
      } else {
        this.setState({
          invoices: this.prepareInvoicesData(allInvoicesData.data),
          totalPages: allInvoicesData.totalPages,
          totalCount: allInvoicesData.totalCount,
          hasUnpaidInvoice: false,
          pageNo: (params.pageNo || params.pageNo === 0 ? (params.pageNo + 1) : this.state.pageNo)
        });
      }
    });
  }

	componentDidUpdate(prevProps) {
    if (this.props.allFeatures !== prevProps.allFeatures) {
      const allFeatures = this.props.allFeatures.featuresData && Object.keys(this.props.allFeatures.featuresData).length ? this.props.allFeatures.featuresData : null;
      this.setState({allFeatures: allFeatures});
    }

    if (this.props.pricingPlans !== prevProps.pricingPlans && this.props.pricingPlans.pricingPlansData && this.props.pricingPlans.pricingPlansData.length) {
			this.setState({subscriptionPricing: getSubscriptionPricing(this.props.pricingPlans.pricingPlansData)});
		}
  }

  getInvoices(params) {
    fetchUserInvoices(params).then(resp => {
      if (resp && resp.data && resp.data.length) {
        let hasUnpaidInvoice = false;
        let unpaidInvoice = {};
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i].status === "UNPAID" && resp.data[i].expiryDate && moment.utc(resp.data[i].expiryDate).unix() > moment.utc().unix()) {
            unpaidInvoice = resp.data[i];
            hasUnpaidInvoice = true;
            break;
          }
        }
        if (hasUnpaidInvoice) {
          params['beginDate'] = moment.utc(unpaidInvoice.startDate).format("YYYY-MM-DD");
          params['pageSize'] = 50;
          this.setShowRenewalLinkFlag(params, resp, unpaidInvoice);
        } else {
          this.setState({
            invoices: this.prepareInvoicesData(resp.data),
            totalPages: resp.totalPages,
            totalCount: resp.totalCount,
            hasUnpaidInvoice: false,
            pageNo: (params.pageNo || params.pageNo === 0 ? (params.pageNo + 1) : this.state.pageNo)
          });
        }
      } else {
        this.setState({invoices: []});
      }
    });
  }

  getPurchaseDetails(data) {
    let invoiceDetails = this.state.invoiceDetails;
    if (data.paymentRef) {
      fetchPurchaseDetails({paymentRef: data.paymentRef, userId: this.props.user.id}).then(
        (resp) => {
          if (resp.data) {
            invoiceDetails[`${data.paymentRef}`] = resp.data;
          } else {
            invoiceDetails[`${data.paymentRef}`] = {};
          }
          this.setState({invoiceDetails: invoiceDetails});
        });
    } else {
      invoiceDetails[`${data.id}`] = {msg: 'No payment associated with this invoice' };
      this.setState({invoiceDetails: invoiceDetails});
    }
  }

  getAmountForUnits(units, period) {
    let amount = 0;
		for (let i = 0; i < this.state.subscriptionPricing.length; i++) {
			if (this.state.subscriptionPricing[i].units === units) {
				if (period === MONTHLY) {
					amount = (this.state.subscriptionPricing[i].totalCost).toFixed(2);
				} else if (period === YEARLY) {
					amount = (this.state.subscriptionPricing[i].totalCost * 12).toFixed(2);
				}
			}
    }
		return parseFloat(amount);
	}

  handleClick = (index, data) => {
    const { activeIndexes } = this.state;
    let newIndex = false;

    if (activeIndexes.indexOf(index) !== -1) {
      activeIndexes.splice(activeIndexes.indexOf(index), 1);
    } else {
      activeIndexes.push(index);
      newIndex = true;
    }
    this.setState({ activeIndexes: activeIndexes });
    if (newIndex) {
      this.getPurchaseDetails(data);
    }
  }

  onChangePage = (event, data) => {
		let { activePage } = data;
		if (activePage !== this.state.pageNo) {
      activePage -= 1;
      if (activePage >= 0) {
        this.getInvoices({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email, pageNo: activePage, pageSize: this.state.pageSize});
      }
		}
  };

  onChangeLimit = (event, data) => {
		if (data.value !== this.state.pageSize) {
      this.getInvoices({merchantId: getMerchantAccountId(), userId: this.props.user.id, email: this.props.user.email, pageSize: data.value});
		}
	};

	render() {
    const { invoices, activeIndexes, totalPages, pageNo, subscriptionPricing, hasUnpaidInvoice, pageSize, totalCount, showRenewalLink, allFeatures, invoiceDetails } = this.state;
    return ( <>
      { !hasUnpaidInvoice || (subscriptionPricing && subscriptionPricing.length) ?
        <div className="payments-list">
          <PageSizeSelect limit={pageSize.toString()} onChangeLimit={this.onChangeLimit} /> Total{' '}<strong>{formatNumber(totalCount)}</strong>
          <Table celled stackable basic="very" className="api-key-table table-no-border">
            <Table.Header>
              <Table.Row key={Math.random()}>
                <Table.HeaderCell style={{position: "relative"}}>Purchase Date <Info id="utcDates" /></Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell># of Units</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell style={{position: "relative"}}>Start Date <Info id="utcDates" /></Table.HeaderCell>
                <Table.HeaderCell style={{position: "relative"}}>Expiry Date <Info id="utcDates" /></Table.HeaderCell>
                {hasUnpaidInvoice ?
                  <Table.HeaderCell></Table.HeaderCell>
                : ''}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {invoices.map((data, index) =>
              <React.Fragment key={Math.random()}>
                <Table.Row index={index} onClick={() => this.handleClick(index, data)} style={{cursor: "pointer"}} className={(activeIndexes.indexOf(index) !== -1) ? 'no-border' : ''}>
                  <Table.Cell  style={{height: "53px"}}><Icon name={(activeIndexes.indexOf(index) !== -1) ? 'minus' : 'chevron right'} />{data.displayDate}</Table.Cell>
                  <Table.Cell>{data.status}</Table.Cell>
                  <Table.Cell>{data.period}</Table.Cell>
                  <Table.Cell>{numberToReadableFormat(data.units)}</Table.Cell>
                  <Table.Cell>{(data.status === "UNPAID") ? formatCurrency(this.getAmountForUnits(data.units, data.period)) : formatCurrency(data.amount)}</Table.Cell>
                  <Table.Cell>{moment.utc(data.startDate).format('MMM DD, YYYY')}</Table.Cell>
                  <Table.Cell>{data.expiryDate ? moment.utc(data.expiryDate).format('MMM DD, YYYY') : ''}</Table.Cell>
                  {hasUnpaidInvoice ?
                    <Table.Cell>
                      {(data.status === "UNPAID" && data.showRenewalLink && (data.expiryDate && moment.utc(data.expiryDate).unix() > moment.utc().unix())) ?
                        <>
                          {allFeatures && allFeatures['PAYMENT'] ?
                            <a data-dropp-purchase="#" onClick={(e) => { payWithLink(e, {userId: this.props.user.id, userEmail: this.props.user.email, cost: this.getAmountForUnits(data.units, data.period), units: data.units, period: data.period, currentPage: 'paymentHistory', showConfirmation: false, invoiceId: (data.id ? data.id : 0), invoiceStartDate: (data.startDate ? data.startDate : ''), envConfigs: this.props.envConfigs}); }} href="#">
                              <Image title="Pay with dropp" inline style={{ height: "24px" }} src={DroppIcon} />
                            </a>
                          :
                            <span className="payment-link">
                              <Image title="Pricing is currently disabled. Coming Soon..." inline style={{ height: "24px" }} src={DroppIcon} />
                            </span>
                          }
                        </>
                      : <div>&nbsp;</div>}
                    </Table.Cell>
                  : ''}

                </Table.Row>
                {(activeIndexes.indexOf(index) !== -1) ?
                  <Table.Row key={Math.random()} className="inner-row">
                    <Table.Cell colSpan={hasUnpaidInvoice ? 8 : 7}>
                      <div>
                        {invoiceDetails && invoiceDetails[`${data.paymentRef}`] ?
                          <Grid>
                            <Grid.Row>
                              <Grid.Column computer={5} tablet={5} mobile={16}>
                                <div>DROPP ACCOUNT</div>
                                {invoiceDetails[`${data.paymentRef}`]['userAccountId'] ?
                                  <div>{invoiceDetails[`${data.paymentRef}`]['userAccountId']['shard']}.{invoiceDetails[`${data.paymentRef}`]['userAccountId']['realm']}.{invoiceDetails[`${data.paymentRef}`]['userAccountId']['accountNumber']}</div> : <div> -</div>}
                              </Grid.Column>
                              <Grid.Column computer={10} tablet={10}  mobile={16}>
                                <div>STATUS</div>
                                {data.status === 'PAID' ?
                                  <div>This payment has been received from your Dropp Wallet and the Units were posted to your DragonGlass Account.</div> : <div>-</div>}
                              </Grid.Column>
                            </Grid.Row>
                            <br />
                          </Grid>
                          :
                          <>
                            {invoiceDetails && invoiceDetails[`${data.id}`] ?
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column textAlign="center">
                                    <div>{invoiceDetails[`${data.id}`].msg}</div>
                                  </Grid.Column>
                                </Grid.Row>
                                <br />
                              </Grid> : ''}
                          </>
                        }
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  : ''
                }
              </React.Fragment>
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <Pagination
                    totalPages={totalPages}
                    activePage={pageNo}
                    onPageChange={this.onChangePage}
                    boundaryRange={0}
                    siblingRange={0}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      : ''}
    </>
		);
	}
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  pricingPlans: state.pricingPlan,
  allFeatures: state.allFeatures,
  envConfigs: getEnvConfigs(state)
});

export default connect(mapStateToProps, actions)(PaymentHistory);
