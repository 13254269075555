import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDapp, getDappTokenTransfers } from '../../redux/reducers';
import * as actions from '../../redux/actions';
import { Grid } from 'semantic-ui-react';
// import AccountSummaryPanel from '../AccountSummaryPanel';
import DappAboutPanel from '../DappAboutPanel';
import DappLogoPanel from '../DappLogoPanel';
import DappContractsPanel from '../DappContractsPanel';
import DappContractUsersChartPanel from '../DappContractUsersChartPanel';
import DappContractTxnVolumeChartPanel from '../DappContractTxnVolumeChartPanel';
// import DappStatsPanel from '../DappStatsPanel';

import DappTransactionsAndTransfers from '../DappTransactionsAndTransfers';
import DappAccountSummaryPanel from '../DappAccountSummaryPanel';
import DappAccountTransfersPanel from '../DappAccountTransfersPanel';
import DappAccountTransactionsPanel from '../DappAccountTransactionsPanel';

import DappAccountBalanceChartPanel from '../DappAccountBalanceChartPanel';
import DappAccountFilesPanel from '../DappAccountFilesPanel';

import DappAccountSubscriptionPanel from '../DappAccountSubscriptionPanel';
// import AccountFilesPanel from '../AccountFilesPanel';
import Page from '../shared/Page';
import DappSearch from '../containers/DappSearch';

// import AccountLookup from '../containers/AccountLookup';
import Search from '../containers/Search';

import '../../animate.css';

const Dapp = (props) => {

	useEffect(() => { window.scrollTo(0, 0) }, []);
	// useEffect(() => { console.log("DAPP PAGE MOUNTED") }, [])
	// useEffect(() => {fetchTokenInfo()}, []);

	// const fetchTokenInfo = () => {
	// 	props.dapp && console.log('FETCHING TOKEN INFO')
	// 	const query = (`contractID=${props.dapp.accountID}&from=0&size=50&sortBy=desc`)
	// 	actions.fetchDappTokenTransfers(props.dapp.accountID, query)
	// }

	// props.dapp && console.log("PROPS DAPP: ", props)

	// useEffect(() => {
	// 	return () => {
	// 	  console.log("DAPP PAGE UNMOUNTED");
	// 	};
	//   }, []);

	const displayContractDapp = props.dapp && props.dapp.contracts && props.dapp.contracts.length !== 0;

	const displayAccountDapp = props.dapp && props.dapp.contracts && props.dapp.contracts.length === 0;

	// props.dapp && props.dapp.contracts && props.dapp.contracts.length !== 0 && console.log(props.dapp.contracts[0].token)

	return (
		<Page className="animated fadeIn">
			<Grid padded stackable>
				{/* <Grid.Column computer={11} only="tablet computer">
					<Search />
				</Grid.Column> */}
				{/* <Grid.Column computer={5} tablet={16} mobile={16} className="no-padding-mobile">
					<AccountLookup />
				</Grid.Column> */}
				<Grid.Column computer={11} tablet={16} mobile={16}>
					<Grid columns={1}>
						<Grid.Column>
							<Search />
						</Grid.Column>
						<Grid.Column>
							<DappLogoPanel />
						</Grid.Column>
						<Grid.Column>
							{/*displayContractDapp && */<DappAboutPanel />}
						</Grid.Column>
						<Grid.Column>
							{displayContractDapp && <DappTransactionsAndTransfers dapp={props.dapp} />}
							{displayAccountDapp && <DappAccountSummaryPanel accountNumber={props.dapp.accountNumber} />}
						</Grid.Column>
						<Grid.Column>
							{displayContractDapp && <DappContractsPanel />}
							{displayAccountDapp && <DappAccountTransfersPanel accountNumber={props.dapp.accountNumber} />}
						</Grid.Column>
						<Grid.Column>
							{displayAccountDapp && <DappAccountTransactionsPanel accountNumber={props.dapp.accountNumber} />}
						</Grid.Column>
					</Grid>
				</Grid.Column>
				<Grid.Column computer={5} tablet={16} mobile={16}>
					<Grid columns={1}>
						<Grid.Column>
							{displayContractDapp && <DappContractUsersChartPanel />}
							{displayAccountDapp && <DappAccountBalanceChartPanel accountNumber={props.dapp.accountNumber} />}
						</Grid.Column>
						<Grid.Column>
							{displayContractDapp && <DappContractTxnVolumeChartPanel />}
							{displayAccountDapp && <DappAccountFilesPanel accountNumber={props.dapp.accountNumber} />}
						</Grid.Column>
						<Grid.Column>
							{displayAccountDapp && <DappAccountSubscriptionPanel accountNumber={props.dapp.accountNumber} />}
						</Grid.Column>
						<Grid.Column only="mobile" className="no-padding-mobile">
							<DappSearch />
						</Grid.Column>
					</Grid>
				</Grid.Column>
			</Grid>
		</Page>
	);
}

const mapStateToProps = (state, { match: { params: { dappId } } }) => {
	const dapp = getDapp(state, dappId);
	return ({
	dapp,
	id: dappId,
	tokenTransfers: dapp ? getDappTokenTransfers(state, dapp.accountId) : [],
})};

export default withRouter(connect(mapStateToProps, actions)(Dapp));