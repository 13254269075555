import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DappTransferTable from '../DappTransferTable';
import * as actions from '../../redux/actions';
import {getDapp, getDappTransfers, getDappTransfersCount } from '../../redux/reducers';
import { removeEmpty } from '../../utils';

class DappTransfers extends Component {
	state = {
		offset: 0,
		limit: 5,
		page: 1,
		sort: 'consensusTime',
		order: 'desc'
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.dappId !== prevProps.dappId || this.props.filter !== prevProps.filter) {
			this.setState(
				{
					offset: 0,
					page: 1
				},
				() => this.fetchData()
			);
		}
		// if (this.props.dapp !== prevProps.dapp) {
		// 	this.fetchData()
		// }
	}

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		const {accountId, dappId}= this.props;
		if (!accountId) return;
		const { filter, fetchDappTransfers } = this.props;
		const params = { q: filter, ...this.state };

		const queryParams = removeEmpty(params);

		const esc = encodeURIComponent;
		const query = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');

		fetchDappTransfers(dappId, accountId, query);
	};

	showTransactionDetails = (transfer) => {
		this.props.history.push(`/transactions/${transfer.transactionID}`);
	};

	render() {

		const { transfers, totalCount } = this.props;

		return (
			<div>{this.props.dapp.transfers &&
			<DappTransferTable
				transfers={this.props.dapp.transfers}
				limit={this.state.limit}
				offset={this.state.offset}
				sort={this.state.sort}
				order={this.state.order}
				totalCount={this.props.dapp.transfers.totalCount}
				loadData={this.loadData}
				showDetails={this.showTransactionDetails}
			/>
			}</div>
		);
	}
}

const mapStateToProps = (state, { match: { params: { dappId } } }) => {
	const dapp = getDapp(state, dappId);
    return ({
		dapp,
		dappId,
		accountId: dapp && dapp.accountId ? dapp.accountId : null,
        transfers: dapp ? getDappTransfers(state, dapp.accountId) : [],
        totalCount: dapp ? getDappTransfersCount(state, dapp.accountId) : ''
    })
};

DappTransfers = withRouter(connect(mapStateToProps, actions)(DappTransfers));

export default DappTransfers;
