import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import info from '../../info';

export default function Info({ id }) {
	const content = id && info[id] ? info[id] : '';
	return (
		<Popup
			className="info-popup"
			trigger={<Icon className="info circle" size="large" color="teal" name="info"/>}
			content={<span className="info-content">{content}</span>}
		/>
	);
}