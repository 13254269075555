import React, { Component } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getNetworks } from '../../redux/reducers';

class NetworkSelector extends Component {
	getNetwork = () => {
		const { networks } = this.props;
		if (networks) {
			const network = networks.find((n) => n.url === window.location.origin);
			return network ? network.name : 'NETWORK';
		} else {
			return 'NETWORK';
		}
	};
	getOptions = () => {
		const { networks } = this.props;
		if (networks) {
			return networks.map((n) => ({
				key: n.id,
				value: n.url,
				disabled: window.location.origin === n.url,
				selected: window.location.origin === n.url,
				content: (
					<a href={n.url} target="_blank" rel="noopener noreferrer">
						{n.name} {window.location.origin === n.url ? <Icon name="check" /> : null}
					</a>
				)
			}));
		} else return [];
	};
	render() {
		return <Dropdown text={this.getNetwork()} options={this.getOptions()} />;
	}
}

const mapStateToProps = (state) => ({
	networks: getNetworks(state)
});

export default withRouter(connect(mapStateToProps, null)(NetworkSelector));
