import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import { Tab } from 'semantic-ui-react';
import DappContractView from './DappContractView';
import DappContractCodeView from './DappContractCodeView';

const DappContractsListTabs = ({ contracts }) => {

    const childRef = useRef();

    const handleClick = () => {
        childRef.current.clearView();
    }

    const panes = [];
    contracts.map((contract) => {
        const item = 
        panes.push(
            {
                menuItem: {
                    content: <div>
                        <strong style={{marginLeft: "-12px"}}>{contract.name.toUpperCase()}</strong>
                        <br />
                        <small style={{marginLeft: "-12px"}}><Link to={`/contracts/${contract.id}`} target="_blank">{contract.id}</Link></small>
                    </div>,
                    key: contract.id},
                render: () => <Tab.Pane attached={false}><DappContractCodeView ref={childRef} contract={contract} /></Tab.Pane>,
            }
        )
    });
  
    return (
        <Tab onTabChange={handleClick} menu={{ secondary: true, pointing: true }} panes={panes} />
    );
}

export default DappContractsListTabs;
