import React, { Fragment, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Link } from 'react-router-dom';
import { Divider, Radio, Table } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ApiCopyUrl from './ApiCopyUrl';
import Panel from './shared/Panel';

import '../animate.css';

const APIIntroductionPanel = () => {

const here = <Link to="/profile" target="_blank">here</Link>
const signingup = <Link to="/signup" target="_blank">signing up</Link>
const userprofile = <Link to="/profile" target="_blank">user profile</Link>

let sample_url = "https://api.hashscan.dragonglass.me/";
if (window && window.location && window.location.origin) {
  console.log('window', window.location);
  if (window.location.origin.includes('testnet')) {
    sample_url = "https://api-testnet.dragonglass.me/";
  } else if (window.location.origin.includes('app.dragonglass.me')) {
    sample_url = "https://api.dragonglass.me.me/";
  }
}

const codeString = `curl -X GET \\
-H "accept: application/json" \\
-H "x-api-key: <access-key>" \\
"${sample_url}hedera/api/transactions"
`

const [showTransaction, setShowTransaction] = useState(false);
const [showRaw, setShowRaw] = useState(false);
const [showAccount, setShowAccount] = useState(false);
const [showContract, setShowContract] = useState(false);
const [showTopic, setShowTopic] = useState(false);

const handleCopyClick = () => {
  audio.play();
  setClicked(true);
  setTimeout(() => setClicked(false), 2000)
}
    
const audio = new Audio('/click.mp3');

const [ clicked, setClicked ] = useState(false);

const handleTransactionClick = (e, {checked}) => {
  setShowTransaction(checked);
} 

const handleRawClick = (e, {checked}) => {
  setShowRaw(checked);
}

const handleAccountClick = (e, {checked}) => {
  setShowAccount(checked);
}

const handleContractClick = (e, {checked}) => {
  setShowContract(checked);
}

const handleTopicClick = (e, {checked}) => {
  setShowTopic(checked);
}


const introText = 
  <div>

      {/* <div>
        <span>Welcome to the DragonGlass Public API Repository.<br /><br /> Here you can access all the DragonGlass APIs for your usage. 
        In order to use the API service, you must first create a DragonGlass user profile, which can be done {here}. After creating a profile, 
        you can use the “Generate API Key” button to generate a Key.</span><br /><br />
        To be able to use Dragonglass apis, client will be required to send accessKey as part of the request.
        Access Key should be added to the request header "X-API-KEY". If not provided api will return with Unauthorized (401) status.<br /> <br />
        <strong>Please note that you will not be able to access the APIs without an Access Key; the Access Key is 
        non-retractable and will never be displayed again after it has been generated.  You must copy and paste it to a different, secure location, 
        as there is no way for the Key to be displayed after the initial viewing.</strong>
      </div>
      <br /><br /> */}

      <div className="intro-container">

        <div id="introduction" style={{paddingTop: "87px", marginTop: "-50px"}}></div>

        <div className="intro-title" /*id="introduction" style={{paddingTop: "130px", marginTop: "-50px"}}*/>
          Introduction
        </div>
        <div className="intro-paragraph">Welcome to the DragonGlass Public API Repository. This is the main place to get access 
          to high quality historical and real-time data from Hedera Hashgraph with our rich set of APIs.</div>
        <div className="intro-paragraph">Please be aware that DragonGlass APIs are authenticated APIs and you will need an access-key, 
          which should be passed as a header “x-api-key”.</div>
          
        <div className="intro-title" id="authentication" style={{paddingTop: "70px", marginTop: "-50px"}}>Authentication</div>
        <div className="intro-paragraph">All requests made to the API server will require a unique access key that has to be 
          passed in as a header. Without an access-key, you will get Unauthorized (401) status in the response.</div>
        <div className="intro-paragraph">To get your unique access-key, you can start by creating your user profile by {signingup} on 
          DragonGlass, if you haven’t already.</div>
        <div className="intro-paragraph">After signing-up, go to your {userprofile} and click on “Generate API Key” under “My API Keys” section.</div>

        <br />

        {/* --------------------------------------------------------- */}
        <div className="image-div" style={{marginLeft: "3%"}}>
          <img width="95%" src="/generate_key.png" />
        </div>
        {/* --------------------------------------------------------- */}

        <br />

        <div className="intro-paragraph">This will generate a unique API key for you that can be used to access data using DragonGlass RESTful APIs.</div>

        <br />

        {/* --------------------------------------------------------- */}
        <div className="image-div" style={{marginLeft: "3%"}}>
          <img width="95%" src="/your_access_key.png" />
        </div>
        {/* --------------------------------------------------------- */}

        <br />

        <div className="intro-paragraph">Please note that, since the access key that you see here is non-recoverable, 
          we suggest that you download the key.</div>
        <div className="intro-paragraph">* Currently, we only allow 3 access keys per user.</div>
        <div className="intro-paragraph">With any public API request, please pass the access-key generated above 
          as part of the request header as shown in the cURL example below.</div>

          <CopyToClipboard text={codeString}
            onCopy={() => handleCopyClick({copied: true})}>
            <span style={{position: "relative", right: "10%", top: "8px"}}><ApiCopyUrl clicked={clicked} /></span>
          </CopyToClipboard>

          <div style={{width: "80%", marginLeft: "10%"}}>
            <SyntaxHighlighter language="curl" style={docco}>
              {codeString}
            </SyntaxHighlighter>
          </div>

        {/* --------------------------------------------------------- */}
        {/* <div className="image-div" style={{marginLeft: "20%"}}>
          <img width="75%" src="/curl.png" />
        </div> */}
        {/* --------------------------------------------------------- */}

        {/* <br /><br /> */}

        <div className="intro-title" id="responses" style={{paddingTop: "70px", marginTop: "-50px"}}>Responses</div>
        <div className="intro-paragraph">DragonGlass Public APIs can return the following error responses:</div>

        <Table celled size="small" style={{width: "80%", marginLeft: "10%"}}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Response Code</Table.HeaderCell>
              <Table.HeaderCell>Reason</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>400</Table.Cell>
              <Table.Cell>Bad Request - Invalid Request format</Table.Cell>
              <Table.Cell>Please check the parameter values you are sending</Table.Cell>
            </Table.Row>
            
            <Table.Row>
              <Table.Cell>401</Table.Cell>
              <Table.Cell>Unauthorized - Invalid API Key</Table.Cell>
              <Table.Cell>Please ensure that you are sending correct access-key in the header</Table.Cell>
            </Table.Row>
            
            <Table.Row>
              <Table.Cell>404</Table.Cell>
              <Table.Cell>Not Found</Table.Cell>
              <Table.Cell>Incorrect API url</Table.Cell> 
            </Table.Row>
            
            <Table.Row>
              <Table.Cell>500</Table.Cell>
              <Table.Cell>Internal Server Error - we had a problem with our server</Table.Cell>
              <Table.Cell>Please reach out to us</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {/* --------------------------------------------------------- */}
        {/* <div className="image-div" style={{marginLeft: "20%"}}>
          <img width="75%" src="/response.png" />
        </div> */}
        {/* --------------------------------------------------------- */}

        <div className="intro-paragraph">For <strong>Successful</strong> request, responses will be returned 
          in json format with an Http status code of 200</div>
          
        <br />

        <div className="intro-title" id="datatypes" style={{paddingTop: "70px", marginTop: "-50px"}}>Data Types</div><br />

        <div className="intro-title-small">Transaction</div>
        <div className="intro-paragraph" style={{marginBottom: "15px"}}>Transactions represent a Hedera Transaction Record. 
          This includes Transaction Response code along with other details such as transfers, payerID, consensusTime, startTime, etc.</div>

        <Radio toggle label={showTransaction ? 'HIDE TRANSACTION DATA' : 'SHOW TRANSACTION DATA'} 
          className="header parameters-toggle" checked={showTransaction} onChange={handleTransactionClick} />

        {/* --------------------------------------------------------- */}
        {showTransaction && <div className="image-div" style={{marginLeft: "4%"}}>
          <img width="96.5%" src="/transaction_example2.png" />
        </div>}
        {/* --------------------------------------------------------- */}

        <br /><br />

        <div className="intro-title-small">Raw Transaction</div>
        <div className="intro-paragraph" style={{marginBottom: "15px"}}>Raw transaction is a superset of the 
          Transaction object that contains raw transactions and record information received from the Hedera network.</div>

        <Radio toggle label={showRaw ? 'HIDE RAW TRANSACTION DATA' : 'SHOW RAW TRANSACTION DATA'} 
          className="header parameters-toggle" checked={showRaw} onChange={handleRawClick} />

        {/* --------------------------------------------------------- */}
        {showRaw && <div className="image-div" style={{marginLeft: "1%", position: "relative", bottom: "-26px"}}>
          <img width="98%" src="/raw_transaction_1st.png" />
        </div>}
        {showRaw && <div className="image-div" style={{marginLeft: "1.8%"}}>
          <img width="98.3%" src="/raw_transaction_2nd.png" />
        </div>}
        {/* --------------------------------------------------------- */}

        <br /><br />

        <div className="intro-title-small">Account</div>
        <div className="intro-paragraph" style={{marginBottom: "15px"}}>Account represents Hedera Account information, including balances.</div>

        <Radio toggle label={showAccount ? 'HIDE ACCOUNT DATA' : 'SHOW ACCOUNT DATA'} 
          className="header parameters-toggle" checked={showAccount} onChange={handleAccountClick} />

        {/* --------------------------------------------------------- */}
        {showAccount && <div className="image-div" style={{marginLeft: "1.5%"}}>
          <img width="98%" src="/account2.png" />
        </div>}
        {/* --------------------------------------------------------- */}

        <br /><br />

        <div className="intro-title-small">Contract</div>
        <div className="intro-paragraph" style={{marginBottom: "15px"}}>Contract represents Smart Contracts executed on the Hedera platform. 
          The public API also returns calls made to Smart Contracts as well.</div>

        <Radio toggle label={showContract ? 'HIDE CONTRACT DATA' : 'SHOW CONTRACT DATA'} 
          className="header parameters-toggle" checked={showContract} onChange={handleContractClick} />

        {/* --------------------------------------------------------- */}
        {showContract && <div className="image-div" style={{marginLeft: "1.5%"}}>
          <img width="100%" src="/contract.png" />
        </div>}
        {/* --------------------------------------------------------- */}


        <div className="intro-title-small">Topic</div>
        <div className="intro-paragraph" style={{marginBottom: "15px"}}>Topic is an object from Hedera Consensus Service. 
          The topic APIs let you access topic info along with messages sent to a topic.</div>

        <Radio toggle label={showTopic ? 'HIDE TOPIC DATA' : 'SHOW TOPIC DATA'} 
          className="header parameters-toggle" checked={showTopic} onChange={handleTopicClick} />

        {/* --------------------------------------------------------- */}
        {showTopic && <div className="image-div" style={{marginLeft: "1.5%"}}>
          <img width="100%" src="/topic.png" />
        </div>}
        {/* --------------------------------------------------------- */}

      </div>

    </div>

return (
  <Panel
    title={
      <Fragment>
        Getting Started
      </Fragment>
    }
    description=""
  >
    <Divider />
    <div className="introduction animated fadeIn"/* style={{marginTop: "-50px"}}*/>
      {introText}
    </div>
  </Panel>
);
}

  export default APIIntroductionPanel;