import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Container } from 'semantic-ui-react';
import AccountSearch from '../components/containers/AccountSearch';
import Page from '../components/shared/Page';
import Panel from '../atoms/Panel';
import AccountTable from '../components/AccountTable';
import {
	getSearchParams,
	getSearchedAccounts,
	getSearchedAccountsCount,
	getSearchedAccountsFilters
} from '../redux/reducers';

import * as actions from '../redux/actions';

const styles = {
	containerStyle: {
		marginTop: '4%',
	  },
	contentGrid: {
		marginBottom: '20px',

	  },
}
const AccountsPage = (props) => {

	const [limit, setLimit] = useState(5);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState('desc');
	const [order, setOrder] = useState('createdOn');


    useEffect(() => { loadData() }, []);

	const fetchData = () => {
		 const { fetchAccounts } = props;
		 const queryParams = {
			offset,
			limit,
			page: 1,
			// sort,
			// order,
		 }
		const esc = encodeURIComponent;
		const searchQuery = Object.keys(queryParams).map((p) => esc(p) + '=' + esc(queryParams[p])).join('&');
		console.log('searchQuery', searchQuery);
		fetchAccounts(searchQuery);
	};

	const loadData = (viewParams) => {
		fetchData();
	};
	const { accounts, totalCount } = props
	console.log('accounts', accounts, totalCount);

	const showAccountDetails = (account) => this.props.history.push(`/accounts/${account.accountID}`);

	return (
		<Page>
			<Container fluid style={styles.containerStyle} className="w-98">
				<Panel>
					<div style={{fontWeight: 400}}>
						Active
					</div>
						<AccountTable
						totalCount={totalCount}
						accounts={accounts}
						limit={limit}
						offset={offset}
						sort={sort}
						order={order}
						loadData={loadData}
						showDetails={showAccountDetails}
						
						/>
				</Panel>
			</Container>
		</Page>
	);
}

const mapStateToProps = (state) => ({
	searchParams: getSearchParams(state),
	accounts: getSearchedAccounts(state),
	totalCount: getSearchedAccountsCount(state),
});

export default connect(mapStateToProps, actions)(AccountsPage);
