import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import AccountTransfersPanel from './AccountTransfersPanel';
import AccountTransactionsPanel from './AccountTransactionsPanel';
import HederaTokenBalancePanel from './HederaTokenBalancePanel';
import NftsPanel from './NftsPanel';
import * as actions from '../redux/actions';
import Info from './shared/Info';
import Number from './shared/Number';
import TopicSearch from './containers/TopicSearch';
import NftHistoricalList from './NftHistoricalList';
import TokenTransferPanel from './TokenTransferPanel';
import { getTokenTransfersCount, getTokenBalancesCount, getSearchTokenTransfersCount } from '../redux/reducers/hts';
import { getNftsData, getAccountTransfersCount, getAccountTransactionsCount, getNftsHistoricalDetailCount, getSearchedTopicsCount } from '../redux/reducers';
import { createQuery } from '../utils';


class AccountDetailsTabs extends Component {
	constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
    };
		this.handleTabChange = this.handleTabChange.bind(this);
		// this.panes = this.panes.bind(this);
	}

	componentDidMount() {
		const { fetchNftsDetails, id } = this.props;
		const params = { owner: id };
		this.counts();
		fetchNftsDetails(params);
	}

	componentDidUpdate(prevProps) {
		if (this.props.tabChangetoTokenBalance !== prevProps.tabChangetoTokenBalance) {
			if (this.props.tabChangetoTokenBalance) {
				this.setState({activeTabIndex: 2});
			}
		}
		if (this.props.id !== prevProps.id) {
			window.location.reload();
			this.setState({activeTabIndex: 0});
		}
	}

	handleTabChange = (event, {activeIndex}) => {
		this.setState({activeTabIndex: activeIndex});
		if (this.props.tabChangetoTokenBalance) {
			this.props.setTabChangetoTokenBalance(false);
		}
	};

	counts = () => {
		const { id, fetchAccountTransfers, fetchAccountTransactions, fetchTokenBalances, fetchSearchTokenTransfer, fetchTokenTransfers, fetchNftsHistoricalDetails,
			fetchTopics, fetchNftsDetails } = this.props;
		/*trigger count only queries */
		const countOnlyParams = { accountId: id, account: id, limit: 1 };
		const limitParams = { limit: 0, page: 1, offset: 0 };
		countOnlyParams['createdBy'] = id;
		const countOnlyQuery = createQuery(countOnlyParams);
		const limitParamsQuery = createQuery(limitParams);
		const paymtTxnParamsQuery = createQuery({...limitParams, limit: 5});
		const accountTransfer = fetchAccountTransfers(id, paymtTxnParamsQuery);
		const accountTransactions = fetchAccountTransactions(id, countOnlyQuery);
		const tokenBalances = fetchTokenBalances(countOnlyParams);
		const tokenTransfers = fetchSearchTokenTransfer(countOnlyParams);
		const topics = fetchTopics(countOnlyQuery);
		const NftsHistorical = fetchNftsHistoricalDetails(countOnlyParams);

		Promise.all([ accountTransfer, accountTransactions, tokenBalances, tokenTransfers, topics, NftsHistorical])
			.then(() => {
			console.log("QUERY PARAMS IN Account details tabs: ", countOnlyQuery)
		});
	}
	
	render() {
		const nftsData = this.props.nftsData;
		let panes = [
			{
				menuItem: {
					key: 'PaymentsAndReceipts',
					content: (
						<span>
							PAYMENTS AND RECEIPTS
							<div className='inline-responsive'>
							(<Number>{this.props.accountTransfersCount}</Number>)
							</div> 
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<AccountTransfersPanel />
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'allTransactions',
					content: (
						<span>
							TRANSACTIONS 
							<div className='inline-responsive'>
							 (<Number>{this.props.accountTransactionCount}</Number>)
							</div> 
							{/* (<Number>{this.props.accountTransactionCount}</Number>) */}
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<AccountTransactionsPanel />
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'tokenBalances',
					content: (
						<span>
							TOKEN BALANCES 
							<div className='inline-responsive'>
							(<Number>{this.props.tokenBalancesCount}</Number>)
							</div> 
							{/* (<Number>{this.props.tokenBalancesCount}</Number>) */}
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<HederaTokenBalancePanel />
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'tokenTransfers',
					content: (
						<span>
							TOKEN TRANSFERS
							<div className='inline-responsive'>
							(<Number>{this.props.tokenTransfersCount}</Number>)
							</div> 
							 {/* (<Number>{this.props.tokenTransfersCount}</Number>) */}
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<TokenTransferPanel showArrow={true} isAccountPage={true} accountId={this.props.id}/>
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'topics',
					content: (
						<span>
							TOPICS 
							<div className='inline-responsive'>
							(<Number>{this.props.topicsCount}</Number>)
							</div> 
							{/* (<Number>{this.props.topicsCount}</Number>) */}
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<TopicSearch accountId={this.props.id} isAccountPage={true}/>
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'nftTransfers',
					content: (
						<span>
							NFT TRANSFERS 
							<div className='inline-responsive'>
							 (<Number>{this.props.nftTransfersCount}</Number>)
							</div> 
							{/* (<Number>{this.props.nftTransfersCount}</Number>) */}
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<NftHistoricalList showArrow={true} isAccountPage={true} accountId={this.props.id}/>
					</Tab.Pane>
				)
			},
		];
		const isEnableNftsData = this.props.id && nftsData && nftsData.nftsData && nftsData.nftsData.length;
		// && nftsData.nftsData.filter(nft => nft && nft.owner === this.props.id);
		if(isEnableNftsData 
			// && isEnableNftsData.length
			) {
			panes.push({
				menuItem: {
					key: 'nfts',
					content: (
						<span>
							NFTs
							<div className='inline-responsive'>
							(<Number>{this.props.nftsData.totalCount}</Number>)
							</div> 
							 {/* (<Number>{this.props.nftsData.totalCount}</Number>) */}
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<NftsPanel />
					</Tab.Pane>
				)
			});
		}

		return (
			<div>
					<Tab
							menu={{ stackable: true, secondary: true, pointing: true }}
							panes={panes}
							renderActiveOnly={true}
							onTabChange={this.handleTabChange}
							activeTabIndex={this.state.activeTabIndex}
							activeIndex={this.state.activeTabIndex}
					/>
			</div>
		);
	}
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
	id: accountId,
	accountTransfersCount: getAccountTransfersCount(state, accountId),
	accountTransactionCount: getAccountTransactionsCount(state, accountId),
	tokenBalancesCount: getTokenBalancesCount(state),
	// tokenTransfersCount: getTokenTransfersCount(state),
	tokenTransfersCount: getSearchTokenTransfersCount(state),
	topicsCount: getSearchedTopicsCount(state),
	nftTransfersCount: getNftsHistoricalDetailCount(state),
	nftsData : getNftsData(state),
});

AccountDetailsTabs = withRouter(connect(mapStateToProps, actions)(AccountDetailsTabs));

export default AccountDetailsTabs;
