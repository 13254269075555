import React, { useEffect, useState } from 'react';
import { Grid, Table, Card } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getStats, getNetworks } from '../redux/reducers';
import { fetchAccCount, fetchTxnCount} from '../apis';
import * as constants from '../constants';
import * as actions from '../redux/actions';
import Number from '../atoms/Number';
import LoadingIndicator from '../components/containers/LoadingIndicator';
import Info from '../atoms/Info';
import { parseQueryParams } from  '../utils';
import Share from '../components/shared/Share';
import SourceInWidget from "../components/SourceInWidget";
import * as api from "../apis";

const CountsBox = (props) => {

    const [numAccountsCount, setNumAccountsCount] = useState();
    const [latestAccountId, setLatestAccountId] = useState('');
    const [numTransactions, setNumTransactions] = useState();
    const [hbarPrice, setHbarPrice] = useState(0);
    const [binancePrice, setBinancePrice] = useState(0);
	
    useEffect(() => { 
		fetchStaticData();
		const interval = setInterval(() => fetchData(), constants.REFRESH_INTERVAL);

		return () => {
		clearInterval(interval);
		}
	}, [])

	const fetchStaticData = async() => {
		fetchPriceTrend();
		const counts = await api.fetchStaticCount()
		console.log('counts -->', counts);
		if (counts && counts.numAccounts) {
			setNumAccountsCount(counts.numAccounts);
		}
		if (counts && counts.numTransactions) {
			setNumTransactions(counts.numTransactions);
		}
	}
	const fetchData = () => {
		if (!document.hidden) {
			fetchStaticData();
			// fetchPriceTrend();
			console.log('fetching data', new Date());
			// Promise.all([fetchTxnCount(), fetchAccCount()]).then((response) => {
			// 	console.log('fetching data after promise -->', new Date());
			// 	setLoading(false);
			// 	setNumTransactions(response[0].numTransactions)
			// 	setNumAccountsCount(response[1].numAccounts);
			// });
			// fetchTxnCount().then(resp => {
			// 	console.log('fetching data txn -->',new Date(), resp.numTransactions);
			// 	setNumTransactions(resp.numTransactions)
			// 	// setLatestAccountId(resp.latestAccountId)
			// });
			// console.log('fetching data txn', new Date());
			// fetchAccCount().then(resp => {
			// 	if (resp) {
			// 		console.log('fetching data acc -->', new Date(), resp.numAccounts);
			// 		setNumAccountsCount(resp.numAccounts);
			// 	}
			// });
		}
	}

	const fetchPriceTrend = () => {
		// props.fetchPriceTrend(props.period).then(
		// 	(response) => {
		// 		const hbarPric = response && response.data || 0;
		// 		console.log('hbarPric', hbarPric);
		// 		setHbarPrice(hbarPric)
		// 	}
		// )
		props.fetchBinance().then(
			(response) => {
				if (response && response.data && response.data.data && response.data.data.price) {
					setBinancePrice(response.data.data.price || 0)
				}

			}
		)
	}

	const isLoading = numAccountsCount || numTransactions || numAccountsCount ? false : true;
	let url = "/count?name=transaction-counts";
	// const params = parseQueryParams(props.location.search);
	const params = parseQueryParams('');

	if (true) {
		return (
			<React.Fragment>
				 <Grid stackable style={{marginTop: '2%'}}>
					<Grid.Row only="tablet computer mobile widescreen">
						<Grid.Column width={2} computer={2} widescreen={2}>
						</Grid.Column>
						<Grid.Column width={10} computer={12} widescreen={12} mobile={16}>
							<Grid stackable centered>
								<Grid.Row column={3}>
									<Grid.Column mobile={10} computer={5} widescreen={3} style={{marginBottom: '10px'}}>
									<Card style={{padding: '5px', textAlign: 'center', width: '100%'}}>
										<div style={{fontSize: '18px', fontWeight: 600}}>
											Transactions<Info id="totalTxns" customSize="mini" />
										</div>
										<Card.Content style={{textAlign: 'center'}}>
											<Card.Description style={{fontWeight: 600, fontSize: '30px'}}>
												{
													numTransactions
													? <Number>{numTransactions}</Number>
													: <LoadingIndicator />
												}
											</Card.Description>
										</Card.Content>
										<Grid>
											<Grid.Row column={2}>
												<Grid.Column width={12}>
													<SourceInWidget/>
												</Grid.Column>
												<Grid.Column width={4}>
													<Share url={url} params={params} title={"Dragon Glass Transaction Counts"}/>
												</Grid.Column>
											</Grid.Row>
										</Grid>
										{/* <div style={{display: 'flex', justifyContent: 'space-around'}}>
											<SourceInWidget/>
											<Share url={url} params={params} title={"Dragon Glass Transaction Counts"}/>
										</div> */}
									</Card>
									</Grid.Column>
									<Grid.Column mobile={10} computer={5} widescreen={3} style={{marginBottom: '10px'}}>
									<Card style={{padding: '5px', textAlign: 'center',  width: '100%'}}>
										<div style={{ fontSize: '18px', fontWeight: 600,}}>
										Accounts<Info id="totalAccounts" customSize="mini"/>
										</div>
										<Card.Content style={{textAlign: 'center'}}>
											<Card.Description style={{fontWeight: 600, fontSize: '30px'}}>
												{
													numAccountsCount
													? <Number>{numAccountsCount}</Number>
													: <LoadingIndicator />
												}
												{/* <Number>{numAccountsCount}</Number> */}
											</Card.Description>
										</Card.Content>
										<Grid>
											<Grid.Row column={2}>
												<Grid.Column width={12}>
													<SourceInWidget/>
												</Grid.Column>
												<Grid.Column width={4}>
													<Share url={url} params={params} title={"Dragon Glass Account Counts"}/>
												</Grid.Column>
											</Grid.Row>
										</Grid>
										{/* <div style={{display: 'flex', justifyContent: 'space-around'}}>
											<SourceInWidget/>
											<Share url={url} params={params} title={"Dragon Glass Transaction Counts"}/>
										</div> */}
									</Card>
									</Grid.Column>
									<Grid.Column mobile={10} computer={5} widescreen={3} style={{marginBottom: '0px'}}>
										<Card style={{padding: '5px', textAlign: 'center',  width: '100%'}}>
											<div style={{ fontSize: '18px', fontWeight: 600,}}>
											HBAR Price<Info id="binancePrice" customSize="mini"/>
											</div>
											<Card.Content style={{textAlign: 'center'}}>
												<Card.Description style={{fontWeight: 600, fontSize: '30px'}}>
													{
														binancePrice
														? <>$ <Number>{binancePrice}</Number></>
														: <LoadingIndicator />
													}
												</Card.Description>
											</Card.Content>
											<Grid>
												<Grid.Row>
													<Grid.Column>
														<SourceInWidget/>
													</Grid.Column>
												</Grid.Row>
											</Grid>
											{/* <div style={{display: 'flex', paddingLeft: '22px'}}> */}
												{/* <SourceInWidget customStyle={{textAlign: 'center', fontSize: '10px'}}/> */}
												{/* <Share url={url} params={params} title={"Dragon Glass Transaction Counts"}/> */}
											{/* </div> */}
										</Card>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column width={2} computer={2} widescreen={2}>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</React.Fragment>
		);
	} else {
		return  <LoadingIndicator />
	}
}

const mapStateToProps = (state) => ({
	stats: getStats(state),
	networks: getNetworks(state)
});

export default connect(mapStateToProps, actions)(CountsBox);