import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { isAuthed, getUserAccounts } from '../../redux/reducers';

class DappAccountSubscription extends Component {
	render() {
		const { isAuthed } = this.props;
		if (!isAuthed)
			return (
				<div>
					<p>Get real-time account notifications via email and/or text</p>
					<Button as={Link} to="/signup" primary floated="right">
						SUBSCRIBE
					</Button>
				</div>
			);
		else {
			const { userAccounts, accountNumber } = this.props;
            const found = userAccounts.find((account) => account.accountId === accountNumber);
            console.log("USER ACCOUNT FOUND: ", found, this.props)
			let text = '';
			if (found) {
				if (found.email && found.sms) {
					text = 'You are subscribed for notifications';
				} else if (found.email && !found.sms) {
					text = 'You are subscribed for email notifications';
				} else if (!found.email && found.sms) {
					text = 'You are subscribed for sms notifications';
				} else {
					text = 'You are not subscribed for notifications on this account';
				}
			} else {
				text = 'You are not subscribed for notifications on this account';
			}
			return (
				<div>
					<p>{text}</p>
					<Button as={Link} to="/profile" primary floated="right">
						MODIFY
					</Button>
				</div>
			);
		}
	}
}

const mapStateToProps = (state, ownProps) => ({
	// accountId,
	isAuthed: isAuthed(state),
	userAccounts: getUserAccounts(state)
});

DappAccountSubscription = withRouter(connect(mapStateToProps, null)(DappAccountSubscription));

export default DappAccountSubscription;
