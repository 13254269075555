import React from 'react';
import JSONPretty from 'react-json-pretty';

export const SubscriptionMessage = () => {
    
    const data = `{"size": 1, "data": [{ "id": "001552711585103128811074500-SUCCESS", "consensusTime": "2020-03-25T02:25:38.592588001Z", 
        "message": {"transactionID": "001552711585103128811074500", "readableTransactionID": "0.0.155271@1585103128-811074500", 
        "consensusTime": "2020-03-25T02:25:38.592588001Z", 
        "message": "7b2274797065223a2022726573657441756374696f6e222c2022746f7069634964223a2022302e302e313638343532227d", 
        "topic": "0.0.168452", 
        "topicRunningHash": "eb79b583221cbbf925cd9dbcb68df23ec603d3c162c37943c3dcd36f08b054e7808960652b09c14947292c6661c46cc9", 
        "topicSequenceNumber": 67,"status": "SUCCESS"} }]}`

    return (
        <div style={{
                    color: "white", 
                    backgroundColor: "black",
                    overflow: "scroll"
                }}
            >
            <JSONPretty id="json-pretty" data={data}></JSONPretty>
        </div>
    )
}

export default SubscriptionMessage;
