import React, { Component } from 'react';
import { Table, Form, Icon, Checkbox, Message, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getUser, getUserAccounts } from '../../redux/reducers';
import * as actions from '../../redux/actions';
import { isHederaAccount, isHederaReservedAccount, notify } from '../../utils';

class UserAccounts extends Component {
	state = {
		accountId: '',
		alias: '',
		error: false,
		msg: '',
		open: false
	};

	close = () => this.setState({ open: false });

	handleSubmit = () => {
		const { accountId, alias } = this.state;
		const { addUserAccount, user } = this.props;

		if (!isHederaAccount(accountId)) return this.setState({ error: true, msg: 'Invalid format! Should be x.y.z' });

		if (user)
			addUserAccount(
				{
					accountId,
					alias
				},
				user.id
			);
	};

	handleChange = (e, { name, value }) => this.setState({ [name]: value, error: false });

	handleRemove = (accountId) => {
		const { removeUserAccount, user } = this.props;
		if (user)
			removeUserAccount(
				{
					accountId
				},
				user.id
			);
	};

	toggleNotification = ({ name }, account) => {
		const { updateUserAccount, user } = this.props;
		if (user) {
			const { contact, email } = user;
			if (name === 'sms') {
				if (!account[name] && (!contact || contact.trim().length < 1)) {
					this.setState({ open: true });
					return;
				}
			}

			updateUserAccount(
				{
					accountId: account.accountId,
					[name]: !account[name]
				},
				user.id
			).then(() => {
				if (!account[name]) {
					const toastId = 'subscription';
					if (!notify.isActive(toastId)) {
						const message =
							name === 'sms'
								? `SMS subscription successful. You will now start receiving messages on your registered mobile number ${contact}.`
								: `A verification link has been sent to your registered email adress ${email}. Please click the link in the email to activate your subscription.`;
						notify(message, { toastId, autoClose: 8000 });
					}
				}
			});
		}
	};

	render() {
		const { accounts } = this.props;
		const { open } = this.state;
		return (
			<React.Fragment>
				<Form onSubmit={this.handleSubmit}>
					<div className="subscribed-accounts-form">
					{/* <Form.Group widths="equal" > */}
						<Form.Input
							// label="Account"
							placeholder="Account ID (e.g. 0.0.9838)"
							name="accountId"
							value={this.state.accountId}
							onChange={this.handleChange}
							error={this.state.error}
							className="user-account-input"
						/>
						<Form.Input
							// label="Alias"
							placeholder="Alias"
							name="alias"
							value={this.state.alias}
							onChange={this.handleChange}
							className="user-account-input"
						/>
						<Form.Button content="ADD" primary size={window.innerWidth < 992 ? "tiny" : "medium"} />
					{/* </Form.Group> */}
					</div>
					{this.state.error && (
						<Message size="mini" negative>
							{this.state.msg}
						</Message>
					)}
				</Form>
				<Table compact basic="very">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>ACCOUNT</Table.HeaderCell>
							<Table.HeaderCell>ALIAS</Table.HeaderCell>
							<Table.HeaderCell>NOTIFICATIONS</Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{accounts.map((account) => (
							<Table.Row key={account.accountId}>
								<Table.Cell>{account.accountId}</Table.Cell>
								<Table.Cell>{account.alias}</Table.Cell>
								<Table.Cell>
									{!isHederaReservedAccount(account.accountId) && (
										<React.Fragment>
											<Checkbox
												name="sms"
												label="SMS"
												checked={account.sms}
												style={{ marginRight: '20px' }}
												onChange={(e, data) => this.toggleNotification(data, account)}
												disabled={isHederaReservedAccount(account.accountId)}
											/>
											<Checkbox
												name="email"
												label="EMAIL"
												value="email"
												checked={account.email}
												onChange={(e, data) => this.toggleNotification(data, account)}
												disabled={isHederaReservedAccount(account.accountId)}
											/>
										</React.Fragment>
									)}
								</Table.Cell>
								<Table.Cell collapsing>
									<Icon
										name="trash"
										className="clickable"
										onClick={() => this.handleRemove(account.accountId)}
									/>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
				<Modal dimmer="blurring" open={open} onClose={this.close} closeIcon size="tiny">
					<Modal.Header>Mobile number required for SMS notifications</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<p>Click 'EDIT' under your profile to add your mobile number.</p>
						</Modal.Description>
					</Modal.Content>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: getUser(state),
	accounts: getUserAccounts(state)
});

UserAccounts = connect(mapStateToProps, actions)(UserAccounts);

export default UserAccounts;
