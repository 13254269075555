import React from 'react'

export const APIButton = () => {
    return (
        <div style={{
                backgroundColor: "#6BBD5B", 
                height: "16px", width: "27px", 
                borderRadius: "3px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <span style={{
                    fontFamily: "Titillium Web, san-serif", 
                    fontWeight: "600", 
                    fontSize: "70%", 
                    color: "white", 
                }}>GET</span>
        </div>
    )
}

export default APIButton;