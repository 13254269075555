import React, { Component } from 'react';
import Panel from './shared/Panel';
import PeriodSelector from './shared/PeriodSelector';
import Info from './shared/Info';
import ContractBalanceChart from './containers/ContractBalanceChart';
import { parseQueryParams } from  '../utils';
import { withRouter } from 'react-router-dom';
import Share from './shared/Share';

export class ContractBalanceChartPanel extends Component {
	state = {
		period: '1W',
		contractId: ''
	};

	componentDidMount() {
		const params = parseQueryParams(this.props.location.search);
		if(params && params.period) {
			this.setState({
					period: params.period,
			});
		}
	}

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
	};

	sendContractIdparam = (id) => {
		this.setState({contractId : id})
	}

	render() {
		const { period } = this.state;
		let url = `/chart?name=contract-acc-bal&period=${this.state.period}&contractId=${this.state.contractId}`;
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						BALANCE HISTORY <Info id="balanceHistory" />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				footer={<Share url={url} params={params} title={"Dragon Glass Contract Balance Chart"}/>}
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				{/* History coming soon */}
				<ContractBalanceChart period={period} sendContractIdparam={this.sendContractIdparam} />
			</Panel>
		);
	}
}

export default withRouter(ContractBalanceChartPanel);
