import React from 'react';
import { Table } from 'semantic-ui-react';

export default function AccountTableHeader(props) {
	return (
		<Table.Row>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'accountID' ? props.direction : null}
				onClick={() => props.handleSort('accountID')}
			>
				ID
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'payerID' ? props.direction : null}
				onClick={() => props.handleSort('payerID')}
			>
				PAYER
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'createdOn' ? props.direction : null}
				onClick={() => props.handleSort('createdOn')}
			>
				Creation Date
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'expiry' ? props.direction : null}
				onClick={() => props.handleSort('expiry')}
			>
				Expiry Date
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'balance' ? props.direction : null}
				onClick={() => props.handleSort('balance')}
			>
				Balance
			</Table.HeaderCell>
			<Table.HeaderCell
				width={3}
				sorted={props.column === 'memo' ? props.direction : null}
				onClick={() => props.handleSort('memo')}
			>
				Memo
			</Table.HeaderCell>
			{/* <Table.HeaderCell width={1} /> */}
		</Table.Row>
	);
}
