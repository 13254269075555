import React, { Component } from 'react';
import { Icon, List, Container } from 'semantic-ui-react';
import Number from './shared/Number';
import Carousel from 'nuka-carousel';
import { isMobile } from '../utils';
import { Link } from 'react-router-dom';

export default class NodesList extends Component {
	render() {
		const { nodes } = this.props;
		return (
			<Container textAlign="center" fluid>
				{nodes.length > 0 && (
					<Carousel
						style={{ height: '60px' }}
						slidesToShow={isMobile() ? 1 : 2}
						wrapAround={true}
						width={'100%'}
						transitionMode={'scroll3d'}
						renderBottomCenterControls={() => null}
						renderCenterRightControls={({ nextSlide }) => (
							<Icon
								onClick={nextSlide}
								name="angle right"
								className="clickable carousel-scroll carousel-scroll-right"
								circular
								inverted
							/>
						)}
						renderCenterLeftControls={({ previousSlide }) => null}
					>
						{nodes.map((node, idx) => {
							const { id, name, numTransactions, hbarEarned, council_name, stake, nodeId } = node;
							return (
								<List key={id}>
									<List.Item>
										<Link to={`/accounts/${nodeId}`}>
										<List.Content>
											<List.Header>{name} ({council_name})</List.Header>
											<strong className="text-gray">
												<Number>{numTransactions}</Number>
											</strong>{' '}
											<i>txns *</i> |{' '}
											<strong className="text-gray">
												<Number>{hbarEarned}</Number>
											</strong>{' '}
											ℏ 
											<List.Description><strong className="text-gray"> Stake : {stake} ℏ</strong></List.Description>
										</List.Content>
										</Link>
									</List.Item>
								</List>
							);
						})}
					</Carousel>
				)}
			</Container>
		);
	}
}
