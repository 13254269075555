import React from 'react';
import { ResponsiveContainer, LineChart, BarChart, Bar, Line, XAxis, YAxis, Tooltip, Cell } from 'recharts';

const NumberFormater = (number) => {
	if (number > 1000000000) {
		return (number / 1000000000).toString() + 'B';
	} else if (number > 1000000) {
		return (number / 1000000).toString() + 'M';
	} else if (number > 1000) {
		return (number / 1000).toString() + 'K';
	} else {
		return number.toString();
	}
};

export default ({ graphType, data = [], dataKey = 'value', tickFormatter, labelFormatter, valueFormatter }) => {
	console.log("graphType : ", graphType)
	return (
		<ResponsiveContainer width="100%" height={200} >

	{		graphType=="chart line" ? (
					<BarChart width={150} height={40} data={data} style={{fontSize: "80%", marginLeft: "-15px"}}>
						<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter} />
						<YAxis tickFormatter={NumberFormater} width={48} />
						<Tooltip
							labelFormatter={labelFormatter}
							formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
						/>
						<Bar dataKey={dataKey} >
							{
								 data.map((entry, index) => (
									 <Cell key={`cell-${index}`} fill={index === data.length -1 ? "purple" : "#8884d8"}/>
								 ))
							 }
						</Bar>
					</BarChart>
				):
				(
					<LineChart data={data} style={{fontSize: "80%", marginLeft: "-15px"}}>
							<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter} />
							<YAxis tickFormatter={NumberFormater} width={48} />
							<Tooltip
								labelFormatter={labelFormatter}
								formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
							/>
							<Line dataKey={dataKey} type="monotone" dot={false} strokeWidth={2} />
						</LineChart>
				)
			}



		{/*<LineChart data={data} style={{fontSize: "80%", marginLeft: "-15px"}}>
				<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter} />
				<YAxis tickFormatter={NumberFormater} width={48} />
				<Tooltip
					labelFormatter={labelFormatter}
					formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
				/>
				<Line dataKey={dataKey} type="monotone" dot={false} strokeWidth={2} />
			</LineChart>
			<BarChart width={150} height={40} data={data} style={{fontSize: "80%", marginLeft: "-15px"}}>
				<XAxis dataKey="timestamp" domain={[ 'auto', 'auto' ]} name="Time" tickFormatter={tickFormatter} />
				<YAxis tickFormatter={NumberFormater} width={48} />
				<Tooltip
					labelFormatter={labelFormatter}
					formatter={valueFormatter ? valueFormatter : (value) => new Intl.NumberFormat('en').format(value)}
				/>
        <Bar dataKey={dataKey} fill="#8884d8" />
      </BarChart>*/}
		</ResponsiveContainer>
	);
};
