import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Panel from './shared/Panel';
import Counts from './containers/Counts';
import { connect } from 'react-redux';
import { getStats } from '../redux/reducers';
import SourceInWidget from './SourceInWidget';
import { parseQueryParams } from  '../utils';
import Share from './shared/Share';

export class CountsPanel extends Component {
	render() {
		let url = "/count?name=transaction-counts";
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						<SourceInWidget />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				footer={<Share url={url} params={params} title={"Dragon Glass Transaction Counts"}/>}
				>
				<Counts />
			</Panel>
		)
	}
};

const mapStateToProps = (state) => ({
	stats: getStats(state)
});

export default withRouter(connect(mapStateToProps, null)(CountsPanel));
