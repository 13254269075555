import React from 'react';
import Panel from './shared/Panel';
import TopicSummary from './containers/TopicSummary';
import Info from './shared/Info';

export default function TopicSummaryPanel({ topic }) {
	return (
		<Panel
			title={
				<React.Fragment>
					TOPIC SUMMARY {/*<Info id="contractSummary" />*/}
				</React.Fragment>
			}
			description=""
		>
			<TopicSummary topic={topic} />
		</Panel>
	);
}