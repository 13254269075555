import {
  FETCH_USAGE_SUMMARY_SUCCESS,
  FETCH_USAGE_SUMMARY_FAILURE
} from '../types';

const initialState = {
};

export default function usageSummary(state = initialState, action) {
	switch (action.type) {
		case FETCH_USAGE_SUMMARY_SUCCESS:
			return {
				...state,
				summaryData: action.data
			};
		case FETCH_USAGE_SUMMARY_FAILURE:
			return {
				...state,
				summaryData: []
			};
		default:
			return state;
	}
}
