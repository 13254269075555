import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';

const FwdPrePagination = (props) => {
	const { totalCount, limit } = props;
	const [page, setPage] = useState(1);

	// const lastPage = Math.ceil(totalCount / limit);
	const lastPage = Math.ceil(totalCount >= 10000 ? 10000 : totalCount / limit);

	const moveToStart = () => {
		setPage(1);
		props.onLoadMore({ offset: 0, page: 1 });
	}

	const moveToEnd = () => {
		// const lastPage = Math.ceil(totalCount / limit);
		const lastPage = Math.ceil((totalCount >= 10000 ? 10000 : totalCount) / limit);
		setPage(lastPage);
		let offset = limit;
		if (totalCount >= 10000) {
			offset = (10000 - limit);
		} else {
			offset = limit * (lastPage - 1);
		}
		props.onLoadMore({ offset, page: lastPage });
	}

	const moveNext = () => {
		const nextPage = page + 1;
		const offset = limit * (nextPage - 1);
		setPage(nextPage);
		props.onLoadMore({ offset, page: nextPage });
	}

	const movePrevious = () => {
		const previousPage = page - 1;
		const offset = limit * (previousPage - 1);
		setPage(previousPage);
		props.onLoadMore({ offset, page: previousPage });
	}

	return (
		<React.Fragment>
			<Grid stackable>
				<Grid.Row> 
					<Grid.Column width={4}>
						<Button disabled={page == 1 ? true : false} onClick={() => moveToStart()}> <Icon name='angle double left' /></Button>
					</Grid.Column>
					<Grid.Column width={4}>
						<Button disabled={page == 1 ? true : false} onClick={() => movePrevious()}>Previous</Button>
					</Grid.Column>
					<Grid.Column width={5}>
						<Button disabled={(lastPage == 0) || (lastPage == page) ? true : false} onClick={() => moveNext()} style={{width: '100%'}}>Next</Button>
					</Grid.Column>
					<Grid.Column width={3}>
						<Button disabled={(lastPage == 0) || (lastPage == page) ? true : false} onClick={() => moveToEnd()}><Icon name='angle double right' /></Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</React.Fragment>
	);
}

export default FwdPrePagination;
