import { combineReducers } from 'redux';
import {
	FETCH_TOPIC_SUCCESS,
	FETCH_TOPICS_SUCCESS,
	FETCH_TOPIC_MESSAGES_SUCCESS,
	// FETCH_TOPIC_TRANSFERS_SUCCESS,
	// FETCH_TOPIC_BALANCE_SUCCESS,
	// FETCH_TOPIC_TX_COUNT_SUCCESS,
	// FETCH_TOPIC_BALANCE_HISTORY_SUCCESS,
	// FETCH_TOPIC_TRANSACTION_VOLUME_SUCCESS,
	// FETCH_TOPIC_USERS_COUNT_SUCCESS,
	// FETCH_TOPIC_BYTECODE_SUCCESS
} from '../types';

const addToTopic = (state, id, data) => {
	const topic = state[id];
	return {
		...state,
		[id]: {
			...(topic ? topic : {}),
			...data
		}
	};
};

const addEntry = (state, topic) => {
	const { topicID } = topic;
	return addToTopic(state, topicID, topic);
};

const addEntries = (state, topics) => {
	const entries = topics && topics.reduce((r, c) => addEntry(r, c), {});
	return {
		...state,
		...entries
	};
};

const addMessages = (state, action) => {
	console.log("RESPONSE IN addMessages: ", action)
	const { id, response } = action;
	return addToTopic(state, id, { messages: response });
};

// const addTransfers = (state, action) => {
// 	const { id, response } = action;
// 	return addToTopic(state, id, { transfers: response });
// };

// const addBalance = (state, payload) => {
// 	const { id, balance } = payload;
// 	return addToTopic(state, id, { balance });
// };

// const addCount = (state, payload) => {
// 	const { id, numRecentTxns } = payload;
// 	return addToTopic(state, id, { numRecentTxns });
// };

// const addBalanceHistory = (state, payload) => {
// 	const { id, balanceHistory } = payload;
// 	return addToTopic(state, id, { balanceHistory });
// };

// const addByteCode = (state, payload) => {
// 	const { id, byteCode } = payload;
// 	return addToTopic(state, id, { byteCode });
// };

// const addTxVolume = (state, action) => {
// 	const { id, payload } = action;
// 	return addToTopic(state, id, { txVolume: payload });
// };

// const addUsersCount = (state, action) => {
// 	const { id, payload } = action;
// 	return addToTopic(state, id, { usersCount: payload });
// };

const topicsById = (state = {}, action) => {
	switch (action.type) {
		case FETCH_TOPIC_SUCCESS:
			console.log("SINGLE TOPIC IN TOPICS REDUCER: ", action.response)
			return addEntry(state, action.response);
		case FETCH_TOPICS_SUCCESS:
			return addEntries(state, action.response.data);
		case FETCH_TOPIC_MESSAGES_SUCCESS:
			console.log("MESSAGES IN TOPIC REDUCER: ", action.response)
			return addMessages(state, action.response);
		// case FETCH_TOPIC_TRANSFERS_SUCCESS:
		// 	return addTransfers(state, action);
		// case FETCH_TOPIC_BALANCE_SUCCESS:
		// 	return addBalance(state, action.payload);
		// case FETCH_TOPIC_TX_COUNT_SUCCESS:
		// 	return addCount(state, action.payload);
		// case FETCH_TOPIC_BALANCE_HISTORY_SUCCESS:
		// 	return addBalanceHistory(state, action.payload);
		// case FETCH_TOPIC_BYTECODE_SUCCESS:
		// 	return addByteCode(state, action.payload);
		// case FETCH_TOPIC_TRANSACTION_VOLUME_SUCCESS:
		// 	return addTxVolume(state, action);
		// case FETCH_TOPIC_USERS_COUNT_SUCCESS:
		// 	return addUsersCount(state, action);
		default:
			return state;
	}
};

const allTopics = (state = [], action) => {
	switch (action.type) {
		case FETCH_TOPICS_SUCCESS:
			const entries = action.response.data && action.response.data.reduce((r, t) => {
				if (!state.includes(t.topicID)) r.push(t.topicID);
				return r;
			}, []);
			return (entries ? [...state, ...entries] : [ ...state]);
		default:
			return state;
	}
};

const topicsReducer = combineReducers({
	byId: topicsById,
	allIds: allTopics
});

export default topicsReducer;

export const getTopic = (state, id) => state.byId[id];
export const getTopics = (state) => state.allIds;

export const getTopicMessages = (state, id) => {
	const topic = getTopic(state, id);
	topic && console.log("IN GET TOPIC MESSAGES VARIABLE: ", topic)
	if (topic && topic.messages) return topic.messages;
	else return [];
};
// export const getTopicTransfers = (state, id) => {
// 	const topic = getTopic(state, id);
// 	if (topic && topic.transfers) return topic.transfers.data;
// 	else return [];
// };
// export const getTopicTransactionsCount = (state, id) => {
// 	const topic = getTopic(state, id);
// 	if (topic && topic.transactions) return topic.transactions.totalCount;
// 	else return 0;
// };
// export const getTopicTransfersCount = (state, id) => {
// 	const topic = getTopic(state, id);
// 	if (topic && topic.transfers) return topic.transfers.totalCount;
// 	else return 0;
// };
// export const getTopicBalanceHistory = (state, id) => {
// 	const topic = getTopic(state, id);
// 	if (topic && topic.balanceHistory) return topic.balanceHistory;
// 	else return {};
// };

// export const getTopicByteCode = (state, id) => {
// 	const topic = getTopic(state, id);
// 	if (topic && topic.byteCode) return topic.byteCode;
// 	else return {};
// };

// export const getTopicTransactionVolume = (state, id) => {
// 	const topic = getTopic(state, id);
// 	if (topic && topic.txVolume) return topic.txVolume;
// 	else return {};
// };

// export const getTopicUsersCount = (state, id) => {
// 	const topic = getTopic(state, id);
// 	if (topic) return topic.usersCount;
// };
