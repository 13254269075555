import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Statistic, Divider, Grid } from 'semantic-ui-react';
import Number from '../shared/Number';
import * as actions from '../../redux/actions';
import { getStats, getNetworks } from '../../redux/reducers';
import * as constants from '../../constants';
import { fromDate } from '../../utils';
import Info from '../shared/Info';
import LoadingIndicator from './LoadingIndicator';
import { fetchAccCount, fetchTxnCount} from '../../apis';

const Stat = ({ label, value }) => <Statistic horizontal size="small" label={label} value={<Number>{value}</Number>} />;

class Stats extends Component {
	interval = null;
	state = { 
		numAccountsCount: 0,
		latestAccountId: '',
		numTransactions: 0,
	}
	componentDidMount() {
		this.fetchData();
		this.interval = setInterval(() => this.fetchData(), constants.REFRESH_INTERVAL);
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	fetchData() {
		if (!document.hidden) {
			const { fetchStats } = this.props;
			// fetchStats();
			fetchTxnCount().then(resp => {
				console.log('fetchTxnCount', resp);
				this.setState({numTransactions: resp.numTransactions})
				this.setState({latestAccountId: resp.latestAccountId})
				
			});
			fetchAccCount().then(resp => {
				if (resp) {
					console.log('fetchAccCount', resp);
					this.setState({numAccountsCount: resp.numAccounts || 0})
				}
			});
		}
	}

	getNetworkDate = () => {
		const { networks } = this.props;
		if (networks) {
			const network = networks.find((n) => n.url === window.location.origin);
			return network ? network.genesisDate : null;
		} else {
			return null;
		}
	};

	render() {
		// const { numTransactions, numAccounts, latestAccountId } = this.props.stats;
		const { numTransactions, numAccountsCount, latestAccountId } = this.state;
		const genesisDate = this.getNetworkDate();
		// const isLoading = numTransactions || numAccounts ? false : true;
		const isLoading = numTransactions || numAccountsCount ? false : true;
		if (!isLoading){
			return (
				<Fragment>
					<Stat label="transactions" value={numTransactions} />
					<span>
						<Info id="totalTxns" />
					</span>
					<div style={{ fontSize: '12px', color: '#999' }}>
						{`${genesisDate ? `Since, ${fromDate(genesisDate)}` : ''}`}
					</div>
					<Divider />
					<Stat label="total accounts" value={numAccountsCount} />
					<span>
						<Info id="totalAccounts" />
					</span>
					<div style={{ fontSize: '12px', color: '#999' }}>{`${latestAccountId
						? `Last account created, ${latestAccountId}`
						: ''}`}</div>
				</Fragment>
			);
		} else {
			return  <LoadingIndicator />
			
		}
	}
}

const mapStateToProps = (state) => ({
	stats: getStats(state),
	networks: getNetworks(state)
});

Stats = connect(mapStateToProps, actions)(Stats);

export default Stats;
