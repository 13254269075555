import React,  { useState } from 'react';
import { Button, Icon } from "semantic-ui-react";
import DisplayContent from "../atoms/DisplayContent";
import * as api from "../apis";
import {isEmpty, getEnviornment} from "../utils";
import CodeSnippet from "../components/shared/CodeSnippet";


const MultiNFTFiles = ({file }) => {
	
	const [jsonData, setJsonData] = useState({});

	const ImageBaseUrl = `https://nftstorage.link/ipfs/`;
	const BASE_URL = getEnviornment().baseUrl;

	const getImageUrl = (uri) => {
		if (uri.includes('ipfs://')) {
			const splitedlink = uri.split('ipfs://')[1];
			const ipfsId = splitedlink;
			const imageUrl = `${ImageBaseUrl}${ipfsId}`;
			return imageUrl;
		}
		return uri;
    };

	const gethttpsJsonData = async(ipfsId) => {
		let jsond = await api.fetchJSONdata(ipfsId);
		setJsonData(jsond);
		return jsond;
	}

	const ShowFiles = () => {

		const fileType = file && file.type;
		if (!(file && fileType)) return (<div style={{textAlign: 'center', fontSize: '20px', margin: '10%'}}>Unsupported Format</div>)
		if (fileType && (fileType.includes('image') || fileType.includes('video'))) {
			return (<DisplayContent size={fileType.includes('video') ? 'xl' : 'large'} playVideo={true} mimeType={fileType} src={getImageUrl(file.uri)} alt={getImageUrl(file.uri)}/>)
		} if (fileType && fileType.includes('application/json')) {
			let ipfsId = file.uri;
			if (file && file.uri.includes('ipfs://')) {
				const splitedlink = file.uri.split('ipfs://')[1];
				ipfsId = splitedlink;
				if (isEmpty(jsonData)) {
					gethttpsJsonData(ipfsId);
				}
			}
			return (<><CodeSnippet style={{overflow: 'scroll', height: '70vh'}} code={jsonData} title="Meta Data Json"></CodeSnippet></>)

	  	} else if (fileType && fileType.includes('application/pdf')) {
			return (<iframe src={getImageUrl(file.uri)} style={{width:'100%'}} frameborder="0"></iframe>)
		} 
		else if (fileType && fileType.includes('model/vnd.usdz+zip')) {
			let finalUrl = file.uri;
			if (file && file.uri.includes('ipfs://')) {
				const splitedlink = file.uri.split('ipfs://')[1];
				const ipfsId = splitedlink;
				const ImageBaseUrl = `https://nftstorage.link/ipfs/`;
				finalUrl = `${ImageBaseUrl}${ipfsId}`;
			}
			const TEMP_BASE_URL = `http://localhost:3000/`; //BASE_URL
			// return (<iframe
			// 	 		// sandbox="allow-scripts"
			// 			// credentialless="true"
			// 			// allow="shared-cookies"
			// 			// sandbox="allow-scripts allow-popups"
			// 			// referrerpolicy="cross-origin"
			// 			allow="cross-origin-isolated"
			// 			src={`${TEMP_BASE_URL}3dModel/?file=${finalUrl}`}
			// 	 		style={{width:'100%', height: '70vh'}} frameborder="0"
			// 	 ></iframe>)
			return (<div style={{textAlign: 'center', fontSize: '30px', paddingTop: '20%'}}>
						<Button size='large' target='_blank' href={`${BASE_URL}3dModel/?file=${finalUrl}`} primary>Click Here to View 3D Model</Button>
					</div>)
		} 
		else {
			return (<div style={{textAlign: 'center', fontSize: '150px', padding: '10%'}}><Icon name="file outline" ></Icon></div>)
		}
	}

	return (
		<div>
			{ShowFiles()}
		</div>
	);
};

export default MultiNFTFiles;
