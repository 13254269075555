import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import info from '../../info';

export default function Info({ id, extra, backgroundColor }) {
	const content = id && info[id] ? info[id] : '';
	return (
		<Popup
			className="info-popup"
			trigger={<Icon className="info-icon" size="tiny" name="info" circular style={{background: backgroundColor}}/>}
			content={
				<React.Fragment>
					<span className="info-content">{content}</span>{' '}
					{extra && (
						<React.Fragment>
							<br />
							<br />
							<span className="info-content">{extra}</span>
						</React.Fragment>
					)}
				</React.Fragment>
			}
		/>
	);
}

//style={{ marginTop: '-5px' }}
