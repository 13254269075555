import { combineReducers } from "redux";
import search, * as fromSearch from "./search";
import news, * as fromNews from "./news";
import dapps, * as fromDapps from "./dapps";
import stats, * as fromStats from "./stats";
import nodes, * as fromNodes from "./nodes";
import transactionTypes, * as fromTransactionTypes from "./transactionTypes";
import transactionTypesNonHcs, * as fromTransactionTypesNonHcs from "./transactionTypesNonHcs";
import transactionVolume, * as fromTransactionVolume from "./transactionVolume";
import accountCreationVolume, * as fromAccountCreationVolume from "./accountCreationVolume";
import transactionValues, * as fromTransactionValues from "./transactionValues";
import accounts, * as fromAccounts from "./accounts";
import transactions, * as fromTransactions from "./transactions";
import contracts, * as fromContracts from "./contracts";
// import nfts, * as fromNfts from "./nfts";

import topics, * as fromTopics from "./topics";

import searchedTransactions, * as fromSearchedTransactions from "./searchedTransactions";
import searchedContracts, * as fromSearchedContracts from "./searchedContracts";

import searchedTopics, * as fromSearchedTopics from "./searchedTopics";

import searchedAccounts, * as fromSearchedAccounts from "./searchedAccounts";

import auth, * as fromAuth from "./auth";
import config, * as fromConfig from "./config";

import apiCatalog, * as fromApiCatalog from "./apiCatalog";

import keys, * as fromApiKeys from "./apiKeys";

import dapp, * as fromDapp from "./dapp";

import subscriptions, * as fromSubscriptions from "./subscriptions";
import subscriptionFilters, * as fromSubscriptionFilters from "./subscriptionFilters";
import subscriptionModels, * as fromSubscriptionModels from "./subscriptionModels";

import currency, * as fromCurrency from "./currency";
import symbol, * as fromSymbol from "./symbol";

import messages, * as fromMessages from "./messages";

import announcements, * as fromAnnouncements from "./announcements";
import profileBalance from "./profileBalance";
import userInvoice from "./userInvoice";
import usageSummary from "./usageSummary";
import pricingPlan from "./pricingPlan";
import allFeatures from "./features";
import envConfigs from "./envConfig";
import topTenTokensByValue from "./topTenTokensByValue";
import topTenTokensByVolume from "./topTenTokensByVolume";
import hts from "./hts";
import nfts, * as fromNfts from "./nfts";

import charts, * as fromCharts from "./charts";

const hashscan = combineReducers({
    auth,
    news,
    stats,
    nodes,
    transactionTypes,
    transactionTypesNonHcs,
    transactionVolume,
    accountCreationVolume,
    transactionValues,
    accounts,
    transactions,
    contracts,
    search,
    searchedTransactions,
    searchedContracts,
    searchedAccounts,
    config,
    apiCatalog,
    keys,
    dapps,
    dapp,
    subscriptions,
    subscriptionFilters,
    subscriptionModels,
    currency,
    symbol,
    searchedTopics,
    messages,
    announcements,
    topics,
    profileBalance,
    userInvoice,
    usageSummary,
    pricingPlan,
    allFeatures,
    envConfigs,
    topTenTokensByValue,
    topTenTokensByVolume,
    hts,
    nfts,
    charts
});

export default hashscan;

/* selectors */
export const getNews = state => fromNews.getNews(state.news);
export const getDapps = state => fromDapps.getDapps(state.dapps);
export const getStats = state => fromStats.getStats(state.stats);
export const getIsFetchingStats = state => fromStats.getIsFetchingStats(state.stats);

export const getNodes = state => fromNodes.getNodes(state.nodes);
export const getIsFetchingNodes = state => fromNodes.getIsFetchingNodes(state.nodes);

export const getTransactionTypes = state => fromTransactionTypes.getTransactionTypes(state.transactionTypes);
export const getTransactionTypesNonHcs = state => fromTransactionTypesNonHcs.getTransactionTypesNonHcs(state.transactionTypesNonHcs);
export const getTransactionVolume = state => fromTransactionVolume.getTransactionVolume(state.transactionVolume);
export const getAccountCreationVolume = state =>
    fromAccountCreationVolume.getAccountCreationVolume(state.accountCreationVolume);
export const getTransactionValues = state => fromTransactionValues.getTransactionValues(state.transactionValues);
export const getAccount = (state, id) => fromAccounts.getAccount(state.accounts, id);
export const getAccountTransfersCount = (state, id) => fromAccounts.getAccountTransfersCount(state.accounts, id);
export const getAccountTransfers = (state, id) => fromAccounts.getAccountTransfers(state.accounts, id);
export const getAccountTransactions = (state, id) => fromAccounts.getAccountTransactions(state.accounts, id);
export const getAccountTransactionsCount = (state, id) => fromAccounts.getAccountTransactionsCount(state.accounts, id);
export const getAccountBalanceHistory = (state, id) => fromAccounts.getAccountBalanceHistory(state.accounts, id);
export const getAccountBalanceHistoryLoader = (state, id) => fromAccounts.getAccountBalanceHistoryLoader(state.accounts, id);
export const getAccountFiles = (state, id) => fromAccounts.getAccountFiles(state.accounts, id);

export const getTransaction = (state, id) => fromTransactions.getTransaction(state.transactions, id);
export const getRawTransaction = (state, id) => fromTransactions.getRawTransaction(state.transactions, id);
export const getTransactions = state => fromTransactions.getTransactions(state.transactions);
export const getTransactionsPieChart = state => fromTransactions.getTransactionsPieChart(state.transactions);

/* ------------------------------------------------------------------------------------------------------------------------ */
export const getContract = (state, id) => fromContracts.getContract(state.contracts, id);
export const getContractTransactions = (state, id) => fromContracts.getContractTransactions(state.contracts, id);
export const getContractTransfers = (state, id) => fromContracts.getContractTransfers(state.contracts, id);
export const getContractTransactionsCount = (state, id) =>
    fromContracts.getContractTransactionsCount(state.contracts, id);
export const getContractTransfersCount = (state, id) => fromContracts.getContractTransfersCount(state.contracts, id);
export const getContractBalanceHistory = (state, id) => fromContracts.getContractBalanceHistory(state.contracts, id);
export const getContractBalanceHistoryLoader = (state, id) => fromContracts.getContractBalanceHistoryLoader(state.contracts, id);
export const getContractTransactionVolume = (state, id) =>
    fromContracts.getContractTransactionVolume(state.contracts, id);
export const getContractTransactionVolumeLoader = (state, id) => fromContracts.getContractTransactionVolumeLoader(state.contracts, id);
export const getContractUsersCount = (state, id) => fromContracts.getContractUsersCount(state.contracts, id);

export const getContractByteCode = (state, id) => fromContracts.getContractByteCode(state.contracts, id);
/* ------------------------------------------------------------------------------------------------------------------------ */

/*Search*/
export const getSearchParams = state => fromSearch.getSearchParams(state.search);

export const getSearchedTransactions = state =>
    fromSearchedTransactions.getSearchedTransactions(state.searchedTransactions);
export const getSearchedTransactionsCount = state =>
    fromSearchedTransactions.getSearchedTransactionsCount(state.searchedTransactions);
export const getSearchedTransactionsFilters = state =>
    fromSearchedTransactions.getSearchedTransactionsFilters(state.searchedTransactions);

/* ------------------------------------------------------------------------------------------------------------------------ */
export const getSearchedContracts = state => fromSearchedContracts.getSearchedContracts(state.searchedContracts);
export const getSearchedContractsCount = state =>
    fromSearchedContracts.getSearchedContractsCount(state.searchedContracts);
export const getSearchedContractsFilters = state =>
    fromSearchedContracts.getSearchedContractsFilters(state.searchedContracts);
/* ------------------------------------------------------------------------------------------------------------------------ */

/* -------------------------------------------------------- TOPICS -------------------------------------------------------- */
export const getSearchedTopics = state => fromSearchedTopics.getSearchedTopics(state.searchedTopics);
export const getSearchedTopicsCount = state => fromSearchedTopics.getSearchedTopicsCount(state.searchedTopics);
export const getSearchedTopicsFilters = state => fromSearchedTopics.getSearchedTopicsFilters(state.searchedTopics);

export const getTopic = (state, id) => fromTopics.getTopic(state.topics, id);
export const getTopicMessages = (state, id) => fromTopics.getTopicMessages(state.topics, id);

export const getMessages = state => fromMessages.getMessages(state.messages);
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

export const getSearchedAccounts = state => fromSearchedAccounts.getSearchedAccounts(state.searchedAccounts);
export const getSearchedAccountsCount = state => fromSearchedAccounts.getSearchedAccountsCount(state.searchedAccounts);
export const getSearchedAccountsFilters = state =>
    fromSearchedAccounts.getSearchedAccountsFilters(state.searchedAccounts);

/* End Search*/

export const isAuthed = state => fromAuth.isAuthed(state.auth);
export const getUser = state => fromAuth.getUser(state.auth);
export const getUserAccounts = state => fromAuth.getUserAccounts(state.auth);

export const getConfig = state => fromConfig.getConfig(state.config);
export const getNetworks = state => fromConfig.getNetworks(state.config);

export const getCategories = state => fromApiCatalog.getCategories(state.apiCatalog);
export const getAllApis = state => fromApiCatalog.getAllApis(state.apiCatalog);
export const getParameters = state => fromApiCatalog.getParameters(state.apiCatalog);

export const getKeys = state => fromApiKeys.getKeys(state.keys);

export const getDapp = (state, id) => fromDapp.getDapp(state.dapp, id);
export const getDappContracts = (state, id) => fromDapp.getDappContracts(state.dapp, id);
export const getDappTransactions = (state, id) => fromDapp.getDappTransactions(state.dapp, id);
export const getDappTransfers = (state, id) => fromDapp.getDappTransfers(state.dapp, id);
export const getDappTokenTransfers = (state, id) => fromDapp.getDappTokenTransfers(state.dapp, id);

export const getDappTransfersCount = (state, id) => fromDapp.getDappTransfersCount(state.dapp, id);

export const getSubscriptions = state => fromSubscriptions.getSubscriptions(state.subscriptions);
export const getSubscriptionFilters = state =>
    fromSubscriptionFilters.getSubscriptionFilters(state.subscriptionFilters);
export const getSubscriptionModels = state => fromSubscriptionModels.getSubscriptionModels(state.subscriptionModels);

export const getCurrency = state => fromCurrency.getCurrency(state.currency);
export const getSymbol = state => fromSymbol.getSymbol(state.symbol);

export const getAnnouncements = state => fromAnnouncements.getAnnouncements(state.announcements);

/* ------------------------------------------------------------------------------------------------------------------------ */

/*Nfts*/
export const getNftsDetail = (state) => fromNfts.getNftsDetail(state.nfts);
export const getNftsHistoricalDetail = (state) => fromNfts.getNftsHistoricalDetail(state.nfts);
export const getNftsData = (state) => fromNfts.getNftsData(state.nfts);
export const getNftsHistoricalDetailCount = (state) => fromNfts.getNftsHistoricalDetailCount(state.nfts);
export const getSearchedNftsCount = (state) => fromNfts.getSearchedNftsCount(state.nfts);

export const getTopNftsData = (state) => fromNfts.getTopNftsData(state.nfts);
export const getNftFilters = (state) => fromNfts.getNftFilters(state.nfts);
export const getLatestCollectionsData = (state) => fromNfts.getLatestCollectionsData(state.nfts);
export const getActiveCollectionsData = (state) => fromNfts.getActiveCollectionsData(state.nfts);
export const getSearchNFTsData = (state) => fromNfts.getSearchNFTsData(state.nfts);
export const getLatestNftsData = (state) => fromNfts.getLatestNftsData(state.nfts);

/* ------------------------------------------------------------------------------------------------------------------------ */

/*Charts*/
export const getAllTransactionsChart = (state) => fromCharts.getAllTransactionsChart(state);
export const getTransactionsTypeDimensionCharts = (state) => fromCharts.getTransactionsTypeDimensionCharts(state);
export const getAccountsCreatedCharts = (state) => fromCharts.getAccountsCreatedCharts(state);
export const getNftMintedCharts = (state) => fromCharts.getNftMintedCharts(state);
export const getNftTransferCharts = (state) => fromCharts.getNftTransferCharts(state);
export const getTokenTransferCharts = (state) => fromCharts.getTokenTransferCharts(state);
export const getTokenMintedCharts = (state) => fromCharts.getTokenMintedCharts(state);
