import {
  FETCH_PROFILE_BALANCE_SUCCESS,
  FETCH_PROFILE_BALANCE_FAILURE
} from '../types';

const initialState = {};

export default function profileBalance(state = initialState, action) {
	switch (action.type) {
		case FETCH_PROFILE_BALANCE_SUCCESS:
			return {
				...state,
				balanceData: action.data
			};
		case FETCH_PROFILE_BALANCE_FAILURE:
			return {
				...state,
				balanceData: []
			};
		default:
			return state;
	}
}
