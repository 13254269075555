import React from 'react';
import { Grid, Header, Icon } from 'semantic-ui-react';
import Item from './shared/Item';
import Number from './shared/Number';
import { fromDate } from '../utils';

export default function ContractSummaryTable({ contract = {}, onShowKey }) {
	const {
		contractID,
		expiry,
		createdOn,
		lastUpdatedOn,
		fileID,
		memo = 'No memo added',
		balance,
		numRecentTxns
	} = contract;
	return (
		<Grid relaxed="very" stackable>
			<Grid.Row>
				<Grid.Column width="9">
					<Header as="h2">{contractID}</Header>
				</Grid.Column>
				{/* <Grid.Column width="7" className="summary-balance">
					<Header as="h2">
						ℏ <Number>{balance}</Number>
					</Header>
					<p className="balance-info">
						Current Balance (<Number>{numRecentTxns}</Number> txns last 30 days)
					</p>
				</Grid.Column> */}
			</Grid.Row>

			<Grid.Row columns="equal">
				<Grid.Column>
					<Item text="Deployed" sub={createdOn ? fromDate(createdOn) : 'N/A'} />
				</Grid.Column>
				<Grid.Column>
					<Item text="Updated" sub={lastUpdatedOn ? fromDate(lastUpdatedOn) : 'N/A'} />
				</Grid.Column>
				<Grid.Column>
					<Item text="Expires" sub={expiry ? fromDate(expiry) : 'N/A'} />
				</Grid.Column>
			</Grid.Row>

			<Grid.Row columns="equal">
				<Grid.Column>
					<Item text="Admin Key" sub={<Icon name="eye" onClick={onShowKey} className="clickable" />} />
				</Grid.Column>
				<Grid.Column>
					<Item text="File ID" sub={fileID} />
				</Grid.Column>
				<Grid.Column>
					<Item text="Memo" sub={memo} />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
}
