import React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

export default function LoginForm({ email, password, onChange, onSubmit, errors = {}, loading }) {
	const isError = Object.keys(errors).length !== 0;
	return (
		<Form size="large" onSubmit={onSubmit} error={isError}>
			<Message error content={errors.non_field_errors} />
			<Form.Input
				id="email"
				name="email"
				fluid
				icon="mail"
				iconPosition="left"
				placeholder="Email"
				autoComplete="off"
				autoFocus
				value={email}
				onChange={onChange}
				error={!!errors.email}
			/>
			<Message error content={errors.email} />
			<Form.Input
				id="password"
				name="password"
				fluid
				icon="lock"
				iconPosition="left"
				placeholder="Password"
				type="password"
				autoComplete="off"
				value={password}
				onChange={onChange}
				error={!!errors.password}
			/>
			<Message error content={errors.password} />
			<Button primary={true} fluid size="large" loading={loading}>
				ACCESS ACCOUNT
			</Button>
			<Message error content={errors.auth} />
		</Form>
	);
}
