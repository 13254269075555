import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../redux/actions';
import {getDapp, getDappTransfers, getDappTokenTransfers, getDappTransfersCount } from '../redux/reducers';
import { Container, Grid, Tab, Radio } from 'semantic-ui-react';
import DappTransactionsPanel from './DappTransactionsPanel';
import DappTransfersPanel from './DappTransfersPanel';
import DappTokenTransfersPanel from './DappTokenTransfersPanel';

export const DappTransactionsAndTransfers = (props) => {

	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const [showTokens, setShowTokens] = useState(true);

	console.log("THIS IS THE DAPP IN TRANSACTIONS AND TRANSFERS: ", props.dapp)

	const fetchTokenInfo = () => {
		const query = (`contractID=${props.dapp.accountID}&from=0&size=50&sortBy=desc`)
		actions.fetchDappTokenTransfers(props.dapp.id, props.dapp.accountID, query)
		// console.log("THESE ARE THE PROPS IN DAPP TRANSACTIONS AND TRANSFERS: ", props)
	}

	// useEffect(() => {console.log("DAPP TRANSACTIONS AND TRANSFERS LOADED: ", props.dapp)}, []);
	
	useEffect(() => {fetchTokenInfo()}, [])

    const handleTabChange = (e, { activeIndex }) => {
		console.log('handleTabChange = ', activeIndex);
		setActiveTabIndex(activeIndex);
		switch (activeIndex) {
			case 0:
				console.log('all transactions tab');
				break;
			case 1:
				console.log('contract calls tab');
				break;
			default:
				console.log('token transfers tab');
		}
    };
    
    const panes = () => [
		{
			menuItem: {
				key: 'allTransactions',
				content: (
					<span>
						ALL TRANSACTIONS 
                        {/* (<Number>{this.props.txCount}</Number>) */}
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<DappTransactionsPanel />
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'contractCalls',
				content: (
					<span>
						CONTRACT CALLS 
                        {/* (<Number>{this.props.acctCount}</Number>) */}
					</span>
				)
			},
			render: () => (
				<Tab.Pane as="div">
					<DappTransfersPanel />
				</Tab.Pane>
			)
		},
		(props.dapp && props.dapp.contracts[0].token &&
			{
				menuItem: {
					key: 'tokenTransfers',
					content: (
						<span>
							TOKEN TRANSFERS 
							{/* (<Number>{this.props.txCount}</Number>) */}
						</span>
					)
				},
				render: () => (
					<Tab.Pane as="div">
						<DappTokenTransfersPanel />
					</Tab.Pane>
				)
			}),
    ]

    return (
        <div>
            <Tab
                menu={{ stackable: true, secondary: true, pointing: true }}
                panes={panes()}
                renderActiveOnly={true}
                onTabChange={handleTabChange}
                // activeIndex={activeIndex}
            />
            
        </div>
    )
}

const mapStateToProps = (state, ownProps/*{ match: { params: { dappId } } }*/) => {
	const dapp = ownProps.dapp;  // getDapp(state, dappId);
	state.dapp.dappById.undefined && console.log(state.dapp.dappById.undefined.tokenTransfers)
    return ({
		dapp,
		// dappId,
		// accountId: dapp && dapp.accountId ? dapp.accountId : null,
		// tokenTransfers: dapp ? getDappTokenTransfers(state, dapp.accountId) : [],
		// tokenTransfers: state.dapp.dappById.undefined.tokenTransfers
    })
};


export default withRouter(connect(mapStateToProps, actions)(DappTransactionsAndTransfers));
