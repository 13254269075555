import React, { useEffect, useState } from 'react';
import { Breadcrumb, Container } from 'semantic-ui-react';
import { useLocation, Link } from 'react-router-dom';

export default function CustomBreadcrumb(props) {
	const location = useLocation();
	const { pathname, search } = location;
	// console.log('location:', location);
	// console.log('pathname:', pathname);
	const segments = pathname.split('/');
	// console.log('segments:', segments);
	const [previousLocation, setPreviousLocation] = useState(null);
	const bredCrumbOptionsArray = [{title: 'Home', link: '/'}, ...props.options || []];
	// const bredCrumbOptionsArray = [{title: 'Home', link: '/'}, {title: 'Category', link: '/category'}];
	useEffect(() => {
		const storedPreviousLocation = localStorage.getItem('previousLocation');
		// console.log('storedPreviousLocation:', storedPreviousLocation);

		if (previousLocation) {
			// console.log('Previous page:', previousLocation.pathname);
		}

		// Store the current location for the next visit
		localStorage.setItem('previousLocation', location.pathname);
		
		setPreviousLocation(location);
	}, [location]);
	
	const BreadcrumbLinks = () => {
		return (
			<Breadcrumb>
			{
				segments.map((segment, index) => {
					let url = '';
					url += `/${segment}${search}`;
					console.log('url:', url);
					return (
						<>
						<Breadcrumb.Section active={segment.isActive}><Link to={url}>{ segment == '' ? 'Home' : segment}</Link></Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						</>
					)
				})
			}
			</Breadcrumb>
		)
	}

	return (
		<Container fluid className="w-98" style={{paddingTop: '1%'}}>
			<>
			{/* <BreadcrumbLinks /> */}
			</>
			{/* <Breadcrumb>
			{
				bredCrumbOptionsArray.map((item, index) => {
					return (
						<>
							<Breadcrumb.Section active={item.isActive}><Link to={item.link}>{item.title}</Link></Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
						</>
					)
				})

			} */}
				{/* <Breadcrumb.Section link>Home</Breadcrumb.Section>
				<Breadcrumb.Divider icon="right chevron" />
				<Breadcrumb.Section link>Category</Breadcrumb.Section>
				<Breadcrumb.Divider icon="right chevron" />
				<Breadcrumb.Section active>Product</Breadcrumb.Section> */}
			{/* </Breadcrumb> */}
		</Container>
	  );
}