import React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export default function bEditProfile({ name, contact, company, onChange, onSubmit, onCancel, errors = {}, loading }) {
	const isError = Object.keys(errors).length !== 0;
	return (
		<Form error={isError}>
			<Message error content={errors.non_field_errors} />
			<Form.Field>
				<label>Name</label>
				<Form.Input
					id="name"
					name="name"
					icon="user"
					iconPosition="left"
					placeholder="Name"
					autoFocus
					value={name}
					onChange={onChange}
					error={!!errors.name}
				/>
			</Form.Field>
			<Message error content={errors.name} />

			<Form.Field>
				<label>Company</label>
				<Form.Input 
					style={{color: "grey"}}
					id="company"
					name="company"
					icon="building"
					iconPosition="left"
					placeholder="Company"
					autoFocus
					value={company}
					onChange={onChange}
					// error={!!errors.name}
				/>
			</Form.Field>

			<Form.Field>
				<label>Mobile Number</label>
				<PhoneInput
					id="contact"
					name="contact"
					placeholder="Enter phone number"
					onChange={(phone) => onChange(null, { name: 'contact', value: phone })}
					value={contact}
					country='US'
					countryOptions={["US", "CA", "GB", "IN", "KR", "RU", "UA", "|", "..."]}
				/>
			</Form.Field>
			<Message error content={errors.contact} />
			<Button primary loading={loading} onClick={onSubmit}>
				SAVE
			</Button>
			<Button basic onClick={onCancel}>
				CANCEL
			</Button>
			<Message error content={errors.auth} />
		</Form>
	);
}
