import React, { Component } from 'react';
import { getNftsData, getNftFilters } from '../redux/reducers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../redux/actions';
import NftsBalanceTable from './NftsBalanceTable';
import { Grid } from 'semantic-ui-react';
import Panel from "./shared/Panel";
import { removeEmpty, concat } from "../utils";
import LoadingIndicator from "./containers/LoadingIndicator";
import SearchFacets from './SearchFacets';
class NftsBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: props.isSearchPage ? 25 : 5,
      sort: 'createdOn',
	    order: 'desc',
      page: 1,
      viewParams: {
        offset: 0,
        size: 25,
        page: 1,
        limit: props.isSearchPage ? 25 : 5,
    },
    filterParams: {},
    };
  }


  componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
  }

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams, viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		const { fetchNftsDetails, accountId, searchParams } = this.props;
		const { viewParams, filterParams } = this.state;

    const params = { owner: accountId, ...this.state, ...searchParams, ...viewParams, ...filterParams };
    const queryParams = removeEmpty(params);
		const esc = encodeURIComponent;
    const filterQuery = Object.keys(filterParams)
			.map((f) => {
				const values = filterParams[f];
				return values.map((v) => esc(f) + '=' + esc(v)).join('&');
			})
			.join('&');
		fetchNftsDetails(queryParams, filterQuery ? true : false);
  };

  getPaginatedNftData = (nftsData, offset, limit) => {
    let paginatedNftData = [];
    if (nftsData && nftsData.length) {
      paginatedNftData = nftsData.splice(offset, limit);
      console.log(paginatedNftData);
    }
    return paginatedNftData;
  };

  handleFilterToggle = (e, { name, checked, category }) => {
		const oldArray = this.state.filterParams[category] || [];
		let newArray;
		if (checked) newArray = concat([ name ], oldArray);
		else {
			newArray = oldArray.filter((k) => k !== name);
		}
		this.setState(
			{
				filterParams: {
					...this.state.filterParams,
					[category]: newArray
				},
				viewParams: {
					...this.state.viewParams,
					offset: 0
				}
			},
			() => this.fetchData()
		);
	};

	render() {
   const { nftsData, token, isSearchPage, isHomePage, filters } = this.props;
   const { limit, offset } = this.state;
   const isLoading = nftsData && nftsData.nftsData && nftsData.nftsData.length ? false : true;
   const totalCount = (nftsData && nftsData.totalCount) ? nftsData.totalCount : 0;
   let paginatedNftData = nftsData.nftsData;
   if (nftsData && nftsData.nftsData && nftsData.nftsData.length && this.props.filterText) {
    paginatedNftData = nftsData.nftsData.filter(nft=> nft.serialNumber == this.props.filterText || nft.token == this.props.filterText)
   }
  //  if (!isLoading) {
     if (isSearchPage) {
       return (
        <Grid padded stackable>
          {filters && (
                  <Grid.Column width={4}>
                      <SearchFacets filters={filters} onFilterToggle={this.handleFilterToggle} />
                  </Grid.Column>
              )}
            <Grid.Column width={filters ? 12 : 16}>
            <Panel>
              <div id="token-search-wrapper scroll-table-x">
                <NftsBalanceTable
                  token={token}
                  nftsData={paginatedNftData}
                  size={limit}
                  offset={offset}
                  sort={this.state.sort}
                  order={this.state.order}
                  totalCount={totalCount}
                  loadData={this.loadData}
                  filters={filters}
                />
              </div>
            </Panel>
            </Grid.Column>
          </Grid>
         );
     } else {
      return (
        <div id="token-search-wrapper">
          <NftsBalanceTable
            token={token}
            nftsData={paginatedNftData}
            size={limit}
            offset={offset}
            sort={this.state.sort}
            order={this.state.order}
            totalCount={totalCount}
            loadData={this.loadData}
            isHomePage={isHomePage}
          />
        </div>
      )
     }
  //  } else {
  //   return <LoadingIndicator />
  //  }
	}
}

const mapStateToProps = (state, { match: { params: { accountId } } }) => ({
  accountId: accountId,
  nftsData : getNftsData(state),
  filters: getNftFilters(state),
});

export default withRouter(connect(mapStateToProps, actions)(NftsBalance));