import React, { useState, useEffect } from "react";
import { Grid, Image, Segment, Embed } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as actions from "../redux/actions";
import { getDapp, getContract } from "../redux/reducers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyTextToClipboard from "./CopyTextToClipboard";
import "../animate.css";
import { isMobile, fromDate, decodeBase64, isImgUrl } from '../utils';
import Base64Icon from "../assets/images/icons8-base-64-24.png";
import TxtIcon from "../assets/images/icons8-txt-24.png";
// import DisplayContent from "../components/shared/DisplayContent";
import DisplayContent from "../atoms/DisplayContent";


const NftsDetails = ({
    nftsDetail,
}) => {
    const [clicked, setClicked] = useState(false);
    const [showText, setShowText] = useState(true);
    const handleCopyClick = () => {
        console.log("MESSAGE Nft metaData COPIED");
        // audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000);
    };

    // const nftsDetail = {
    //     "serialNumber": 11893,
    //     "token": "0.0.1714683",
    //     "metaData": "aXBmczovL2JhZmtyZWljb2JsbW1waXlreGY2bzd6aGZ3NWplcWRtM3g1dWE2a2R2NzMzeHE1emJmYWZieXBicnd5",
    //     "owner": "0.0.3145713",
    //     "createdOn": "2023-07-11T04:38:17.136741802Z",
    //     "deleted": false,
    //     "mintTransaction": "0010258961689050285258793369",
    //     "imageUrl": "https://ipfs.io/ipfs/bafybeieg4bhc5yhdk3j65r5tzjwwxdrv64ry7grqiwgj6qixipekpvbffu",
    //     "dataType": "video/mp4",
    //     "name": "Road Code Access Tokens"
    //   }

    const handleShowTextClick = () => {
        setShowText(!showText);
    };

    return (
        <React.Fragment>
            <Grid basic="very" size="small" className="animated fadeIn" columns={isMobile() ? 1 : 2} divider>
                <Grid.Row>
                    <Grid.Column className="nft-bottom">
                        { nftsDetail.imageUrl ?
                            <Grid.Row>
                                <Grid.Column column={1}>
                                    {/* {
                                        nftsDetail.imageUrl && isImgUrl(nftsDetail.imageUrl)
                                        ? <Image style={{margin: '0 auto', width: '50%'}} alt="NFT Image" src={nftsDetail.imageUrl} />
                                        : <Embed
                                            style={{margin: '0 auto', width: '100%'}}
                                            icon='play circle outline'
                                            url={nftsDetail.imageUrl}
                                        />
                                    } */}
                                    {
                                        <DisplayContent playVideo={true} mimeType={nftsDetail.dataType} src={nftsDetail.imageUrl} alt="NFT"/>
                                    }
                                    
                                </Grid.Column>
                            </Grid.Row>
                            : ''
                        }
                    </Grid.Column>
                    <Grid.Column>
                        <Grid.Row className="nft-bottom">
                            <Grid.Column width="4" className="bold">TOKEN</Grid.Column>
                            <Grid.Column width="12" style={{ overflowWrap: "anywhere" }}>
                                <Link to={`/tokens/${nftsDetail.token}`}>{nftsDetail.token}</Link>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="nft-bottom">
                            <Grid.Column width="4" className="bold">SERIAL NUMBER</Grid.Column>
                            <Grid.Column width="12" >{nftsDetail.serialNumber}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="nft-bottom">
                            <Grid.Column width="4" className="bold">META DATA</Grid.Column>
                            <Grid.Column width="12" style={{ display: "flex" }}>
                                {nftsDetail.dataType == 'JSON'
                                    ?
                                    <Segment className="nft-code-snippet">
                                        <pre style={{ overflowX: 'auto' }}>{showText ? decodeBase64(nftsDetail.metaData) : JSON.stringify(nftsDetail.metaData, null, 2)}</pre>
                                    </Segment>
                                    : <div style={{maxWidth: '80%', overflowWrap: 'anywhere' }}> {showText ? decodeBase64(nftsDetail.metaData) : nftsDetail.metaData} </div>
                                }
                                &nbsp;
                                { nftsDetail && nftsDetail.metaData
                                    ?
                                    <>
                                        <CopyToClipboard text={showText ? decodeBase64(nftsDetail.metaData) : nftsDetail.metaData} onCopy={() => handleCopyClick({ copied: true })}>
                                        <span>
                                            <CopyTextToClipboard clicked={clicked} />
                                        </span>
                                        </CopyToClipboard>
                                        { showText 
                                            ? <Image src={Base64Icon} className="copy-icon" size="mini" style={{ width: '18px', height: '18px' }} onClick={handleShowTextClick}/>
                                            : <Image src={TxtIcon} className="copy-icon" size="mini" style={{ width: '18px', height: '18px' }} onClick={handleShowTextClick}/>
                                        }
                                    </>
                                    : null
                                }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="nft-bottom">
                            <Grid.Column width="4" className="bold">OWNER</Grid.Column>
                            <Grid.Column width="12" style={{ overflowWrap: "anywhere" }}>
                                {nftsDetail.owner != "0.0.0" ?
                                    <Link to={`/accounts/${nftsDetail.owner}`}>{nftsDetail.owner}</Link>
                                    : <>{nftsDetail.owner}</>
                                }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="nft-bottom">
                            <Grid.Column width="4" className="bold">Created On</Grid.Column>
                            <Grid.Column width="12">
                                {nftsDetail && nftsDetail.createdOn ? fromDate(nftsDetail.createdOn) : 'N/A' }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    const id = `${ownProps.id}-SUCCESS`;
    let contract = null;
    if (ownProps.transaction) contract = getContract(state, ownProps.transaction.contractID);
    return {
        dapp: getDapp(state, id),
        contract,
    };
};

export default withRouter(connect(mapStateToProps, actions)(NftsDetails));
