import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDapp } from '../redux/reducers';
import Panel from './shared/Panel';
import DappSummary from './containers/DappSummary';
import SettingsDropdown from './shared/SettingsDropdown';
import Info from './shared/Info';

const DappAboutPanel = (props) => {

	const title = props.dapp && props.dapp.title && props.dapp.title.toUpperCase();

	return (
		<Panel
			title={
				props.dapp && <Fragment>
					<div style={{marginLeft: "0px"/*"9px"*/}}>ABOUT {title} {/*<Info id="" />*/}</div>
                    <div id="dapp-setting"><SettingsDropdown /></div>
				</Fragment>
			}
			description=""
		>
			<DappSummary />
		</Panel>
	);
} 

const mapStateToProps = (state, { match: { params: { dappId } } }) => ({
	id: dappId,
	dapp: getDapp(state, dappId)
});

export default withRouter(connect(mapStateToProps)(DappAboutPanel));