import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup } from 'semantic-ui-react';
import DateCell from './shared/DateCell';
import eye from '../assets/images/eye@2x.png';
import { isMobile } from '../utils';

const redirectTo = (id, isAccount) => {
	return window.location.href = isAccount ? `/accounts/${id}` : `/contracts/${id}`;
};

const ContractRow = ({ contract }) => (
	<Table.Row className="clickable hoverRow">
		<Table.Cell collapsing onClick={()=> redirectTo(contract.contractID)}>
			{contract.contractID}
		</Table.Cell>
		<Table.Cell collapsing onClick={()=> redirectTo(contract.payerID, true)}>
			<Link to={`/accounts/${contract.payerID}`}>{contract.payerID}</Link>
		</Table.Cell>
		<Table.Cell collapsing onClick={()=> redirectTo(contract.contractID)}>
			<DateCell date={contract.createdOn} />
		</Table.Cell>
		<Table.Cell collapsing onClick={()=> redirectTo(contract.contractID)}>
			<DateCell date={contract.expiry} />
		</Table.Cell>
		<Table.Cell 
		style={{
			width: isMobile() ? '200px' : '50px', 
			maxWidth: isMobile() ? '200px' : '50px',
			overflow: 'hidden',
			textOverflow: 'ellipsis', 
			whiteSpace: 'nowrap',
		}}
		onClick={()=> redirectTo(contract.contractID)}>
			<Popup
				trigger={<span>{contract.memo}</span>}
				content={contract.memo}
				position="top center"
				hoverable
			/>
			</Table.Cell>
		{/* <Table.Cell textAlign="center" collapsing onClick={()=> redirectTo(contract.contractID)}>
			<Link to={`/contracts/${contract.contractID}`}>
				<Popup
					content="View Contract Details"
					trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
				/>
			</Link>
		</Table.Cell> */}
	</Table.Row>
);

export default ContractRow;

ContractRow.propTypes = {
	contract: PropTypes.object.isRequired
};
