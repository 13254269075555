import React from 'react';
import { Table } from 'semantic-ui-react';

export default function TransferTableHeader(props) {
	return (
		<Table.Row>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'transactionDirection' ? props.direction : null}
				onClick={() => props.handleSort('transactionDirection')}
			>
				Type
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'consensusTime' ? props.direction : null}
				onClick={() => props.handleSort('consensusTime')}
			>
				Date
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'toFromAccount' ? props.direction : null}
				onClick={() => props.handleSort('toFromAccount')}
			>
				Account
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'amount' ? props.direction : null}
				onClick={() => props.handleSort('amount')}
			>
				Amount
			</Table.HeaderCell>
			<Table.HeaderCell
				width={3}
				sorted={props.column === 'memo' ? props.direction : null}
				onClick={() => props.handleSort('memo')}
			>
				Memo
			</Table.HeaderCell>
			{/* <Table.HeaderCell width={1} /> */}
		</Table.Row>
	);
}
