import React from 'react';
import { fromDate, fromTime, localDateTime } from '../../utils';

export default function DateCell({ date }) {
	return (
		<React.Fragment>
			{/* {fromDate(date)} */}
			{localDateTime(date, 'dateOnly')}
			<br />
			{/* <small>{fromTime(date)}</small> */}
			<small>{localDateTime(date, 'timeOnly')}</small>
		</React.Fragment>
	);
}
