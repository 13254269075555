import React, { Component } from "react";
import Panel from "./shared/Panel";
import HtsTxnTypeChart from "./containers/HtsTxnTypeChart";
import PeriodSelector from "./shared/PeriodSelector";
import Info from "./shared/Info";
import SourceInWidget from "./SourceInWidget";
import { parseQueryParams } from  '../utils';
import { withRouter } from 'react-router-dom';
import Share from './shared/Share';

export class HtsTxnTypeChartPanel extends Component {
    state = {
        period: "1D",
    };

    componentDidMount() {
		const params = parseQueryParams(this.props.location.search);
		if(params && params.period) {
			this.setState({
					period: params.period,
			});
		}
	}

    onPeriodSelect = (e, { value }) => {
        this.setState({ period: value });
        //to do
        //update price and percent change
    };

    render() {
        const { period } = this.state;
        let url = `/chart?name=${this.props.nonFungible ? "non-fungible" : "fungible"}-token-transaction&period=${this.state.period}`;
        const params = parseQueryParams(this.props.location.search);
        return (
            <Panel
                title={
                    <React.Fragment>
                        {this.props.nonFungible ? "NON FUNGIBLE" : "FUNGIBLE"} TOKEN TRANSACTIONS <Info id="htsTokenTxValue" />
                        <SourceInWidget />
                    </React.Fragment>
                }
                titleClass="light"
                description=""
                footer={<Share url={url} params={params} title={`Dragon Glass ${this.props.nonFungible ? "Non Fungible" : "Fungible"} Transaction Type Chart`}/>}
                toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}>
                <div style={{ marginBottom: "30px" }}>
                    <HtsTxnTypeChart period={period} />
                </div>
            </Panel>
        );
    }
}

export default withRouter(HtsTxnTypeChartPanel);