import React from 'react';
import { Table } from 'semantic-ui-react';

export default function TokenTransferTableHeader(props) {
	return (
		<Table.Row>
			{
				props.showArrow && (<Table.HeaderCell width={1} />)
			}
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'transactionId' ? props.direction : null}
				onClick={() => props.handleSort('transactionId')}
			>
				Token ID
			</Table.HeaderCell>
			{/* <Table.HeaderCell
				width={1}
			>
				Account
			</Table.HeaderCell> */}
			<Table.HeaderCell
				width={1}
			>
				From
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
			>
				To
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'amount' ? props.direction : null}
				onClick={() => props.handleSort('amount')}
			>
				Quantity
			</Table.HeaderCell>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'transferTime' ? props.direction : null}
				onClick={() => props.handleSort('transferTime')}
			>
				Transfer On
			</Table.HeaderCell>
			{/* {
				!props.isNoPagination && (<Table.HeaderCell width={1} />)
			} */}
		</Table.Row>
	);
}
