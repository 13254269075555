import React from 'react';
import { Image } from 'semantic-ui-react';
import logoDG from '../../assets/images/logo_dragonglass@2x.png';
import { Link } from 'react-router-dom';

export default function Logo() {
	return (
		<div className="flex content-center">
			<Link to="/">
				<Image className="logo" src={logoDG} />
			</Link>
			{/* <Image className="logo" size="small" src={logoHH} /> */}
		</div>
	);
}
