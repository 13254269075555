import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import Info from "../shared/Info";
import Panel from "../shared/Panel";
import HtsTxnTypeChartPanel from "../HtsTxnTypeChartPanel";
import TokenTable from "../TokenTable";
import * as actions from "../../redux/actions";
import { getSearchParams, getSearchedContractsFilters } from "../../redux/reducers";
import { removeEmpty, concat } from "../../utils";
import { getHederaTokens, getHederaTokensCount, getSearchedTokenFilters } from "../../redux/reducers/hts";
import "../../assets/css/components/tokenDetails.scss";
import { FUNGIBLE_TOKEN } from "../../constants";
import SearchFacets from '../SearchFacets';

class TokenSearch extends Component {
    state = {
        viewParams: {
            offset: 0,
            size: 25,
            page: 1,
        },
        filterParams: {},
    };

    componentDidMount = () => {
        this.fetchData();
    };

    componentDidUpdate(prevProps) {
        if (this.props.searchParams !== prevProps.searchParams) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const { searchParams, fetchTokens, location } = this.props;
        const { viewParams, filterParams } = this.state;
        let tokenType = '';
        if(location && location.pathname && location.pathname.includes(FUNGIBLE_TOKEN)) {
            tokenType = 'FUNGIBLE_COMMON';
        }
        const params = { ...searchParams, ...viewParams, tokenType };
        const queryParams = removeEmpty(params);
		// console.log('queryParams = ', queryParams);
		const esc = encodeURIComponent;
		const filterQuery = Object.keys(filterParams)
			.map((f) => {
				const values = filterParams[f];
				console.log('values = ', values, f)
				const key = f === 'Treasury_Account' ? 'treasuryAccount' : f;
				return values.map((v) => esc(key) + '=' + esc(v)).join('&');
			})
			.join('&');
		// console.log('filterQuery = ', filterQuery);
        fetchTokens(queryParams, filterQuery);
    };

    loadData = viewParams => {
        const params = { ...this.state.viewParams, ...viewParams };
        this.setState({ viewParams: params }, () => this.fetchData());
    };

    showTokenDetails = token => this.props.history.push(`/tokens/${token.id}`);

    handleFilterToggle = (e, { name, checked, category }) => {
		// console.log('handleFilterToggle');
		// console.log('name = ', name);
		// console.log('checked = ', checked);
		// console.log('category = ', category);
		const oldArray = this.state.filterParams[category] || [];
		// console.log('oldArray = ', oldArray);
		let newArray;
		if (checked) newArray = concat([ name ], oldArray);
		else {
			newArray = oldArray.filter((k) => k !== name);
		}

		// console.log('newArray = ', newArray);
		//...this.state.filterParams.category, name ==== this line was commented out before commenting out the logs
		this.setState(
			{
				filterParams: {
					...this.state.filterParams,
					[category]: newArray
				},
				viewParams: {
					...this.state.viewParams,
					offset: 0
				}
			},
			() => this.fetchData()
		);
	};

    render() {
        const { tokens, totalCount, showTitle, isShowType, isSearchPage, filters } = this.props;
        const {
            viewParams: { size, offset, sort, order },
        } = this.state;
        console.log('filters', filters);
        return (
            <Grid padded stackable>
                {
                    isSearchPage ?
                    <>
                        {filters && (
                            <Grid.Column width={4}>
                                <SearchFacets filters={filters} onFilterToggle={this.handleFilterToggle} />
                            </Grid.Column>
                        )}
                        <Grid.Column width={filters ? 12 : 16}>
                            <Panel>
                                {tokens && <div id="token-search-wrapper scroll-table-x">
                                    <TokenTable
                                        totalCount={totalCount}
                                        tokens={tokens}
                                        size={size}
                                        offset={offset}
                                        sort={sort}
                                        order={order}
                                        loadData={this.loadData}
                                        showDetails={this.showTokenDetails}
                                        isShowType={isShowType}
                                    />
                                </div>}
                            </Panel>
                        </Grid.Column>
                    </>
                    :
                        <Grid.Column>
                            <Panel
                                title={
                                    showTitle ? (
                                        <Fragment>
                                            <div style={{ marginLeft: "0px" }}>
                                                Fungible Tokens <Info id="hederaTokensTooltip" />
                                            </div>
                                        </Fragment>
                                    ) : (
                                        ""
                                    )
                                }
                                >
                                <div id="token-search-wrapper scroll-table-x">
                                    <TokenTable
                                        totalCount={totalCount}
                                        tokens={tokens}
                                        size={size}
                                        offset={offset}
                                        sort={sort}
                                        order={order}
                                        loadData={this.loadData}
                                        showDetails={this.showTokenDetails}
                                        isShowType={isShowType}
                                    />
                                </div>
                            </Panel>
                        </Grid.Column>
                }
                {/* <Grid.Column computer={5} tablet={16} mobile={16}>
                    <HtsTxnTypeChartPanel />
                </Grid.Column> */}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    searchParams: getSearchParams(state),
    tokens: getHederaTokens(state),
    totalCount: getHederaTokensCount(state),
    filters: getSearchedTokenFilters(state),
});

TokenSearch = withRouter(connect(mapStateToProps, actions)(TokenSearch));

export default TokenSearch;
