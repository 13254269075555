import * as api from "../../apis";
import * as types from "../types";
import * as constants from "../../constants";
import { getIsFetchingNodes, getIsFetchingStats } from "../reducers";
import axios from "axios";

export const fetchConfig = () => (dispatch, getState) =>
    api.fetchConfig().then(
        response => {
            dispatch({
                type: types.FETCH_CONFIG_SUCCESS,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONFIG_FAILURE,
                error,
            });
        }
    );

export const fetchPriceTrend = period => (dispatch, getState) => api.fetchPriceTrend(period); // - this is the original code

/* ------------------- FETCHING HBAR PRICE FROM COINGECKO: ------------------- */

// Scraping the Coingecko website:

// export const fetchPriceTrend = (period) => (dispatch, getState) => api.fetchPriceTrend(period)
// .then((response) => {
// 	return response.split(',')[0].slice(1, 11);
// })

// Calling the Coingecko API endpoint:

export const fetchGecko = () => (dispatch, getState) =>
    api.fetchGecko().then(response => {
        // console.log("RESPONSE GECKO IN ACTIONS: ", response);
        return response["hedera-hashgraph"].usd;
    });

/* --------------------------------------------------------------------------- */

export const login = (username, password) => (dispatch, getState) => {
    dispatch({
        type: types.LOGIN_REQUEST,
        user: { username },
    });

    return api.login(username, password).then(
        user => {
            localStorage.setItem(constants.DG_USER, JSON.stringify(user));
            dispatch({
                type: types.LOGIN_SUCCESS,
                user,
            });
            return user;
        },
        error => {
            dispatch({
                type: types.LOGIN_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const logout = () => (dispatch, getState) => {
    localStorage.removeItem(constants.DG_USER);
    dispatch({
        type: types.LOGOUT_SUCCESS,
    });
};

export const signup = user => (dispatch, getState) => {
    const { password, ...rest } = user;
    dispatch({
        type: types.SIGNUP_REQUEST,
        user: rest,
    });

    return api.signup(user).then(
        response => {
            console.log("SIGNED UP: ", response);
            dispatch({
                type: types.SIGNUP_SUCCESS,
                user: rest,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.SIGNUP_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const resetPassword = email => (dispatch, getState) => {
    return api.resetPassword(email).then(
        response => {
            dispatch({
                type: types.RESET_PASSWORD_SUCCESS,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.RESET_PASSWORD_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const updatePassword = (password, token) => (dispatch, getState) => {
    return api.updatePassword(password, token).then(
        response => {
            dispatch({
                type: types.UPDATE_PASSWORD_SUCCESS,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.UPDATE_PASSWORD_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const addUserAccount = (account, userId) => (dispatch, getState) => {
    return api.addUserAccount(account, userId).then(
        response => {
            dispatch({
                type: types.ADD_USER_ACCOUNT_SUCCESS,
                userId,
                account,
            });
        },
        error => {
            dispatch({
                type: types.ADD_USER_ACCOUNT_FAILURE,
                error,
            });
        }
    );
};

export const removeUserAccount = (account, userId) => (dispatch, getState) => {
    return api.removeUserAccount(account, userId).then(
        response => {
            dispatch({
                type: types.REMOVE_USER_ACCOUNT_SUCCESS,
                userId,
                account,
            });
        },
        error => {
            dispatch({
                type: types.REMOVE_USER_ACCOUNT_FAILURE,
                error,
            });
        }
    );
};

export const updateUserAccount = (account, userId) => (dispatch, getState) => {
    return api.updateUserAccount(account, userId).then(
        response => {
            dispatch({
                type: types.UPDATE_USER_ACCOUNT_SUCCESS,
                userId,
                account,
            });

            return response;
        },
        error => {
            dispatch({
                type: types.UPDATE_USER_ACCOUNT_FAILURE,
                message: error.message || "Something went wrong.",
            });

            return error;
        }
    );
};

export const fetchUserAccounts = id => (dispatch, getState) => {
    return api.fetchUserAccounts(id).then(
        response => {
            dispatch({
                type: types.FETCH_USER_ACCOUNTS_SUCCESS,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_USER_ACCOUNTS_FAILURE,
                error,
            });
        }
    );
};

export const saveProfile = (profile, userId) => (dispatch, getState) => {
    return api.saveProfile(profile, userId).then(
        response => {
            const user = JSON.parse(localStorage.getItem(constants.DG_USER));
            localStorage.setItem(constants.DG_USER, JSON.stringify({ ...user, ...profile }));
            dispatch({
                type: types.SAVE_USER_PROFILE_SUCCESS,
                userId,
                profile,
            });
        },
        error => {
            dispatch({
                type: types.SAVE_USER_PROFILE_FAILURE,
                error,
            });
        }
    );
};

export const fetchNodes = period => (dispatch, getState) => {
    if (getIsFetchingNodes(getState(), period)) {
        console.log("nodes req already in progress...abort");
        return Promise.resolve();
    }

    dispatch({
        type: types.FETCH_NODES_REQUEST,
        period,
    });

    return api.fetchNodes(period).then(
        response => {
            dispatch({
                type: types.FETCH_NODES_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_NODES_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
};

export const fetchNews = () => (dispatch, getState) =>
    api.fetchNews().then(
        response => {
            dispatch({
                type: types.FETCH_NEWS_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_NEWS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchDapps = () => (dispatch, getState) =>
    api.fetchDapps().then(
        response => {
            dispatch({
                type: types.FETCH_DAPPS_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_DAPPS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchStats = () => (dispatch, getState) => {
    if (getIsFetchingStats(getState())) {
        console.log("stats req already in progress...abort");
        return Promise.resolve();
    }

    dispatch({
        type: types.FETCH_STATS_REQUEST,
    });

    return api.fetchStats().then(
        response => {
            dispatch({
                type: types.FETCH_STATS_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_STATS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
};

export const fetchTransactionTypes = period => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_TRANSACTION_TYPES_LOAD,
        payload: [],
    });
    return api.fetchTransactionTypes(period).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTION_TYPES_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTION_TYPES_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchTransactionTypesNonHcs = period => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_TRANSACTION_TYPES_NON_HCS_LOAD,
        payload: [],
    });
    return api.fetchTransactionTypes(period).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTION_TYPES_NON_HCS_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTION_TYPES_NON_HCS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchTransactionVolume = period => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_TRANSACTION_VOLUME_LOAD,
        payload: [],
    });
    return api.fetchTransactionVolume(period).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTION_VOLUME_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTION_VOLUME_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchAccountCreationVolume = (period, transactionTypes) => (dispatch, getState) => {
    dispatch({
        type: types.FETCH_ACCOUNT_CREATION_VOLUME_LOAD,
         payload: [],
     });
    return api.fetchAccountCreationVolume(period, transactionTypes).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNT_CREATION_VOLUME_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNT_CREATION_VOLUME_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchTransactionValues = period => (dispatch, getState) =>{
    dispatch({
        type: types.FETCH_TRANSACTION_VALUES_LOAD,
         payload: [],
     });
    return api.fetchTransactionValues(period).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTION_VALUES_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTION_VALUES_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchAccount = id => (dispatch, getState) =>
    api.fetchAccount(id).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNT_SUCCESS,
                payload: { ...response, id },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNT_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchAccountBalance = id => (dispatch, getState) =>
    api.fetchAccountBalance(id).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNT_BALANCE_SUCCESS,
                payload: { id, balance: response.balance },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNT_BALANCE_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchAccountRecentTransactionsCount = id => (dispatch, getState) =>
    api.fetchAccountRecentTransactionsCount(id).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNT_TX_COUNT_SUCCESS,
                payload: { id, numRecentTxns: response.numRecentTxns },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNT_TX_COUNT_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchAccountTransfers = (id, query) => (dispatch, getState) =>
    api.fetchAccountTransfers(id, query).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNT_TRANSFERS_SUCCESS,
                id,
                query,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNT_TRANSFERS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchAccountTransactions = (id, query) => (dispatch, getState) =>
    api.fetchAccountTransactions(id, query).then(
        response => {
            console.log("TRANSACTIONS RESPONSE", response);
            dispatch({
                type: types.FETCH_ACCOUNT_TRANSACTIONS_SUCCESS,
                id,
                query,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNT_TRANSACTIONS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchAccountTransaction = (transactionId, accountId) => (dispatch, getState) =>
    api.fetchAccountTransaction(transactionId, accountId);

export const fetchAccountBalanceHistory = (id, period) => (dispatch, getState) => {
    dispatch({
        type: types.FETCH_ACCOUNT_BALANCE_HISTORY_LOAD,
        payload: {
            id,
            balanceHistory: [],
        },
    });
    return api.fetchAccountBalanceHistory(id, period).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNT_BALANCE_HISTORY_SUCCESS,
                payload: {
                    id,
                    balanceHistory: response,
                },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNT_BALANCE_HISTORY_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchAccountFiles = id => (dispatch, getState) =>
    api.fetchAccountFiles(id).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNT_FILES_SUCCESS,
                payload: {
                    id,
                    files: response,
                },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNT_FILES_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchTransaction = id => (dispatch, getState) =>
    api.fetchTransaction(id).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTION_SUCCESS,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTION_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchRawTransaction = id => (dispatch, getState) =>
    api.fetchRawTransaction(id).then(
        response => {
            dispatch({
                type: types.FETCH_RAW_TRANSACTION_SUCCESS,
                id: id.split("-")[0],
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_RAW_TRANSACTION_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const doSearch = query => (dispatch, getState) => {
    dispatch({ type: types.SEARCH_REQUEST, query });
};

export const resetSearch = () => (dispatch, getState) => {
    dispatch({ type: types.RESET_SEARCH });
};

export const fetchTransactions = (query, filtered) => (dispatch, getState) => {
    dispatch({
        type: types.FETCH_TRANSACTIONS_REQUEST,
        query,
    });
    return api.fetchTransactions(query).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTIONS_SUCCESS,
                query,
                filtered,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTIONS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
};

export const fetchTransactionsPieChart = query => (dispatch, getState) => {
    dispatch({
        type: types.FETCH_TRANSACTIONS_PIE_CHART_REQUEST,
        response: [],
    });
    return api.fetchTransactionsPieChart(query).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTIONS_PIE_CHART_SUCCESS,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTIONS_PIE_CHART_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
};

export const fetchClientIp = () => (dispatch, getState) => {
    return api.fetchClientIp();
};

export const fetchContracts = (query, filtered) => (dispatch, getState) => {
    dispatch({
        type: types.FETCH_CONTRACTS_REQUEST,
        query,
    });

    return api.fetchContracts(query).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACTS_SUCCESS,
                query,
                filtered,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACTS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
};

export const fetchTopics = (query, filtered) => (dispatch, getState) => {
    dispatch({
        type: types.FETCH_TOPICS_REQUEST,
        query,
    });

    return api.fetchTopics(query).then(
        response => {
            console.log("RESPONSE IN ACTION FETCH TOPICS: ", response);
            dispatch({
                type: types.FETCH_TOPICS_SUCCESS,
                query,
                filtered,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TOPICS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
};

export const fetchTopicMessages = (id, query, filtered) => (dispatch, getState) => {
    console.log("IN ACTIONS FETCH MESSAGES: ", id, query);
    api.fetchTopicMessages(id, query).then(
        response => {
            console.log("RESPONSE IN ACTION FETCH TOPIC MESSAGES: ", response);
            dispatch({
                // type: types.FETCH_TOPIC_MESSAGES_SUCCESS,
                type: types.FETCH_MESSAGES_SUCCESS,
                query,
                filtered,
                response,
            });
        },
        error => {
            dispatch({
                // type: types.FETCH_TOPIC_MESSAGES_FAILURE,
                type: types.FETCH_MESSAGES_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
};

export const fetchTopic = id => (dispatch, getState) =>
    api.fetchTopic(id).then(
        response => {
            console.log("RESPONSE IN ACTION FETCH SINGLE TOPIC: ", response);
            dispatch({
                type: types.FETCH_TOPIC_SUCCESS,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTION_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchAccounts = (query, filtered) => (dispatch, getState) => {
    dispatch({
        type: types.FETCH_ACCOUNTS_REQUEST,
        query,
    });

    return api.fetchAccounts(query).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNTS_SUCCESS,
                query,
                filtered,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNTS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
};

export const lookupAccount = account => (dispatch, getState) => api.fetchAccount(account);

export const contact = data => (dispatch, getState) => api.contact(data);

export const fetchContract = id => (dispatch, getState) =>
    api.fetchContract(id).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_SUCCESS,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchContractBalance = id => (dispatch, getState) =>
    api.fetchContractBalance(id).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_BALANCE_SUCCESS,
                payload: { id, balance: response.balance },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_BALANCE_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchContractRecentTransactionsCount = id => (dispatch, getState) =>
    api.fetchContractRecentTransactionsCount(id).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_TX_COUNT_SUCCESS,
                payload: { id, numRecentTxns: response.numRecentTxns },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_TX_COUNT_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchContractTransactions = (id, query) => (dispatch, getState) =>
    api.fetchContractTransactions(id, query).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_TRANSACTIONS_SUCCESS,
                id,
                query,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_TRANSACTIONS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchContractTransfers = (id, query) => (dispatch, getState) =>
    api.fetchContractTransfers(id, query).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_TRANSFERS_SUCCESS,
                id,
                query,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_TRANSFERS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchContractBalanceHistory = (id, period) => (dispatch, getState) =>{
    dispatch({
        type: types.FETCH_CONTRACT_BALANCE_HISTORY_LOAD,
        payload: {
            id,
            balanceHistory: [],
        },
    });
    return api.fetchContractBalanceHistory(id, period).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_BALANCE_HISTORY_SUCCESS,
                payload: {
                    id,
                    balanceHistory: response,
                },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_BALANCE_HISTORY_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchContractByteCode = id => (dispatch, getState) =>
    api.fetchContractByteCode(id).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_BYTECODE_SUCCESS,
                payload: {
                    id,
                    byteCode: response.byteCode,
                },
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_BYTECODE_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchContractTransactionVolume = (id, period) => (dispatch, getState) => {
    dispatch({
        type: types.FETCH_CONTRACT_TRANSACTION_VOLUME_LOAD,
        id,
        payload: [],
    });
    return api.fetchContractTransactionVolume(id, period).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_TRANSACTION_VOLUME_SUCCESS,
                id,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_TRANSACTION_VOLUME_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchContractUsersCount = (id, period) => (dispatch, getState) =>
    api.fetchContractUsersCount(id, period).then(
        response => {
            dispatch({
                type: types.FETCH_CONTRACT_USERS_COUNT_SUCCESS,
                id,
                payload: response.contractUsers,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_CONTRACT_USERS_COUNT_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

/* PUBLIC API SELECTION */

export const fetchApiCategories = () => (dispatch, getState) =>
    api.fetchApiCategories().then(
        response => {
            dispatch({
                type: types.FETCH_API_CATEGORIES_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_API_CATEGORIES_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchApiList = () => (dispatch, getState) =>
    api.fetchApiList().then(
        response => {
            dispatch({
                type: types.FETCH_ALL_APIS_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ALL_APIS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchApiParameters = () => (dispatch, getState) =>
    api.fetchApiParameters().then(
        response => {
            dispatch({
                type: types.FETCH_API_PARAMETERS_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_API_PARAMETERS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const cleanUpApiParameters = () => dispatch => {
    dispatch({
        type: types.FETCH_API_PARAMETERS_FAILURE,
    });
};

export const fetchSampleResponse = url => (dispatch, getState) =>
    console.log("in fetchSampleResponse: ", url) ||
    api.fetchSampleResponse(url).then(response => {
        console.log("RESPONSE IN ACTIONS, FETCH SAMPLE RESPONSE: ", response);
        return response;
    });

/* CREATION OF API KEYS */

export const createApiKey = () => (dispatch, getState) =>
    api.createApiKey().then(
        response => {
            dispatch({
                type: types.CREATE_API_KEY_SUCCESS,
                payload: response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.CREATE_API_KEY_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchApiKey = () => (dispatch, getState) =>
    api.fetchApiKey().then(
        response => {
            dispatch({
                type: types.FETCH_API_KEY_SUCCESS,
                payload: response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.FETCH_API_KEY_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const deleteApiKey = keyId => (dispatch, getState) =>
    api.deleteApiKey(keyId).then(
        response => {
            dispatch({
                type: types.DELETE_API_KEY_SUCCESS,
                payload: keyId,
            });
            dispatch(fetchApiKey());
            return response;
        },
        error => {
            dispatch({
                type: types.DELETE_API_KEY_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const deactivateApiKey = keyId => (dispatch, getState) =>
    api.deactivateApiKey(keyId).then(
        response => {
            dispatch({
                type: types.DEACTIVATE_API_KEY_SUCCESS,
                payload: keyId,
            });
            dispatch(fetchApiKey());
            return response;
        },
        error => {
            dispatch({
                type: types.DEACTIVATE_API_KEY_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const activateApiKey = keyId => (dispatch, getState) =>
    api.activateApiKey(keyId).then(
        response => {
            dispatch({
                type: types.ACTIVATE_API_KEY_SUCCESS,
                payload: keyId,
            });
            dispatch(fetchApiKey());
            return response;
        },
        error => {
            dispatch({
                type: types.ACTIVATE_API_KEY_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

/* DAPP PAGE */

export const fetchDapp = id => (dispatch, getState) =>
    api.fetchDapp(id).then(
        response => {
            dispatch({
                type: types.FETCH_DAPP_SUCCESS,
                payload: response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.FETCH_DAPP_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchDappContracts = id => (dispatch, getState) =>
    api.fetchDappContracts(id).then(
        response => {
            dispatch({
                type: types.FETCH_DAPP_CONTRACTS_SUCCESS,
                id,
                payload: response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.FETCH_DAPP_CONTRACTS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchDappTransactions = (id, query) => (dispatch, getState) =>
    console.log("fetching dapp transactions", id, query) ||
    api.fetchDappTransactions(id, query).then(
        response => {
            dispatch({
                type: types.FETCH_DAPP_TRANSACTIONS_SUCCESS,
                id,
                query,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_DAPP_TRANSACTIONS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchDappTransfers = (id, accountId, query) => (dispatch, getState) =>
    api.fetchDappTransfers(accountId, query).then(
        response => {
            dispatch({
                type: types.FETCH_DAPP_TRANSFERS_SUCCESS,
                id,
                accountId,
                query,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_DAPP_TRANSFERS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchDappTokenTransfers = (id, accountId, query) => (dispatch, getState) =>
    console.log("fetching tokens", id, accountId, query) ||
    api.fetchDappTokenTransfers(accountId, query).then(
        response => {
            console.log(response);
            dispatch({
                type: types.FETCH_DAPP_TOKEN_TRANSFERS_SUCCESS,
                id,
                accountId, //this is the contract ID that goes in the query
                query,
                response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_DAPP_TOKEN_TRANSFERS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const resetDapp = () => (dispatch, getState) => {
    dispatch({
        type: types.RESET_DAPP_STATE,
    });
};

/* TRANSACTION PARAMETERS */

export const fetchTransactionParams = (contractID, transactionID) => (dispatch, getState) =>
    api.fetchTransactionParams(contractID, transactionID).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTION_PARAMS_SUCCESS,
                contractID,
                txId: transactionID,
                response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTION_PARAMS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

/* OWN OR CREATE A DAPP */

export const selectDappToOwn = (id, data) => (dispatch, getState) =>
    console.log("this dapp has been selected: ", id) || api.selectDappToOwn(id, data);

export const createNewDapp = (id, data) => (dispatch, getState) =>
    console.log("this is a newly created dapp: ", id, data) ||
    api.createNewDapp(id, data).then(response => {
        console.log("RESPONSE IN ACTIONS, CREATE NEW DAPP: ", response);
        return response;
    });

export const updateDapp = (id, data) => (dispatch, getState) =>
    console.log("this dapp is being updated: ", id, data) ||
    api.updateDapp(id, data).then(response => {
        console.log("RESPONSE IN ACTIONS, UPDATE DAPP: ", response);
        return response;
    });

export const dappAddContract = (id, data) => (dispatch, getState) =>
    console.log("a contract is being added to a dapp: ", id, data) || api.dappAddContract(id, data); //.then(
// 	(response) => {
// 		console.log("RESPONSE IN ADD CONTRACT ACTION: ", response);
// 		return(response)
// 	},
// 	(error) => {
// 		console.log("ERROR MESSAGE IN ADD CONTRACT ACTION: ", error.message)
// 	}
// )

export const dappUpdateContract = (id, dappId, contracts) => (dispatch, getState) =>
    console.log("in actions, update this contract: ", id, dappId, contracts) ||
    api.dappUpdateContract(id, dappId, contracts).then(
        response => {
            console.log("RESPONSE IN UPDATE CONTRACT ACTION: ", response);
            return response;
        },
        error => {
            console.log("ERROR MESSAGE IN UPDATE CONTRACT ACTION: ", error.message);
        }
    );

/* EVENT SUBSCRIPTION */

export const registerForSubscriptions = id => (dispatch, getState) =>
    console.log("register for subscription action for: ", id) || api.registerForSubscriptions(id);

export const cancelSubscriptionRegistration = id => (dispatch, getState) =>
    console.log("cancel subscription registration action for: ", id) || api.cancelSubscriptionRegistration(id);

export const createSubscription = form => (dispatch, getState) =>
    console.log("create subscription registration action for: ", form) ||
    api.createSubscription(form).then(
        response => {
            console.log("THIS IS THE RESPONSE IN ACTIONS: ", response);
            dispatch({
                type: types.CREATE_SUBSCRIPTION_SUCCESS,
                payload: response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.CREATE_SUBSCRIPTION_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const addAwsArn = (id, arn) => (dispatch, getState) =>
    console.log("IN ACTIONS, ADD AWS ARN: ", id, arn) ||
    api.addAwsArn(id, arn).then(response => {
        console.log("THIS IS THE RESPONSE IN ADD AWS ACTION: ", response);
        return response;
    });

export const fetchSubscriptions = () => (dispatch, getState) =>
    console.log("fetch subscriptions action") ||
    api.fetchSubscriptions().then(
        response => {
            dispatch({
                type: types.FETCH_SUBSCRIPTION_SUCCESS,
                payload: response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.FETCH_SUBSCRIPTION_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchSubscriptionModels = () => (dispatch, getState) =>
    console.log("fetch subscription models action") ||
    api.fetchSubscriptionModels().then(
        response => {
            dispatch({
                type: types.FETCH_SUBSCRIPTION_MODELS_SUCCESS,
                payload: response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.FETCH_SUBSCRIPTION_MODELS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const fetchSubscriptionFilters = query => (dispatch, getState) =>
    console.log("fetch subscription filters action for: ", query) ||
    api.fetchSubscriptionFilters(query).then(
        response => {
            dispatch({
                type: types.FETCH_SUBSCRIPTION_FILTERS_SUCCESS,
                payload: response,
            });
            return response;
        },
        error => {
            dispatch({
                type: types.FETCH_SUBSCRIPTION_FILTERS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const postSubscriptionFilters = (form, query) => (dispatch, getState) =>
    console.log("add subscription filters action for: ", query) || api.postSubscriptionFilters(form, query);

export const removeFilter = (subscriptionId, filterId) => (dispatch, getState) =>
    console.log("remove filter action with these ids: ", subscriptionId, filterId) ||
    api.removeFilter(subscriptionId, filterId);

export const uploadAbi = (subId, data, fileName) => (dispatch, getState) =>
    console.log("uploadAbi in actions: ", subId, data, fileName) ||
    api.uploadAbi(subId, data).then(response => {
        console.log("RESPONSE IN ACTIONS, UPLOAD ABI: ", response);
        return response;
    });

export const removeSubscription = subId => (dispatch, getState) =>
    console.log("remove subscription action with this id: ", subId) || api.removeSubscription(subId); //.then(
// 	(response) => {
// 		console.log("THIS IS THE REMOVE RESPONSE IN ACTIONS: ", response)
// 		dispatch({
// 			type: types.REMOVE_SUBSCRIPTION_SUCCESS,
// 			payload: response
// 		});
// 		return response;
// 	},
// 	(error) => {
// 		dispatch({
// 			type: types.REMOVE_SUBSCRIPTION_FAILURE,
// 			message: error.message || 'Something went wrong.'
// 		});
// 	}
// );

/* CURRENCY SELECTION */

export const selectHbar = () => async dispatch => {
    try {
        console.log("HBAR IN ACTIONS");
        dispatch({
            type: types.HBAR_SELECTED,
        });
        dispatch({
            type: types.HBAR_SYMBOL_SELECTED,
        });
    } catch (error) {
        console.log(error);
    }
};

export const selectTbar = () => async dispatch => {
    try {
        console.log("TBAR IN ACTIONS");
        dispatch({
            type: types.TBAR_SELECTED,
        });
        dispatch({
            type: types.TBAR_SYMBOL_SELECTED,
        });
    } catch (error) {
        console.log(error);
    }
};

export const selectUSD = () => async dispatch => {
    try {
        console.log("USD IN ACTIONS");
        dispatch({
            type: types.USD_SELECTED,
        });
        dispatch({
            type: types.USD_SYMBOL_SELECTED,
        });
    } catch (error) {
        console.log(error);
    }
};

/* CONFIGURABLE ANNOUNCEMENTS */

export const fetchAnnouncements = () => (dispatch, getState) =>
    api.fetchAnnouncements().then(
        response => {
            // console.log("ANNOUNCEMENTS - RESPONSE IN ACTIONS: ", response);
            dispatch({
                type: types.FETCH_ANNOUNCEMENTS_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ANNOUNCEMENTS_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );

export const hideAnnouncement = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ANNOUNCEMENTS_FAILURE });
};

/* FETCHING HBAR RATES FROM THE EXCHANGES */

export const fetchBinance = () => (dispatch, getState) =>
    api.fetchBinance().then(response => {
        console.log("RESPONSE BINANCE IN ACTIONS: ", response);
        return response;
    });

export const fetchOkex = () => (dispatch, getState) =>
    api.fetchOkex().then(response => {
        console.log("RESPONSE OKEX IN ACTIONS: ", response);
        return response;
    });

export const fetchBittrex = () => (dispatch, getState) =>
    api.fetchBittrex().then(response => {
        console.log("RESPONSE BITTREX IN ACTIONS: ", response);
        return response;
    });

/* CAPTURING LAST LOGIN */

export const captureLastLogin = (id, time) => (dispatch, getState) =>
    console.log("a contract is being added to a dapp: ", id, time) || api.captureLastLogin(id, time);

export const fetchDroppPricing = () => (dispatch, getState) =>
    api.fetchDroppPricing().then(response => {
        console.log("DROPP PRICING INFO: ", response);
        return response;
    });

export const fetchProfileBalance = params => dispatch => {
    return api.fetchProfileBalance(params).then(
        response => {
            dispatch({
                type: types.FETCH_PROFILE_BALANCE_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_PROFILE_BALANCE_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchTopTenTokensByValue = params => dispatch => {
    return api.fetchTopTenTokensByValue(params).then(
        response => {
            dispatch({
                type: types.FETCH_TOP_TEN_TOKEN_VALUE_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TOP_TEN_TOKEN_VALUE_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchTopTenTokensByVolume = params => dispatch => {
    return api.fetchTopTenTokensByVolume(params).then(
        response => {
            dispatch({
                type: types.FETCH_TOP_TEN_TOKEN_VOLUME_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TOP_TEN_TOKEN_VOLUME_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchTokens = (params, filterQuery) => dispatch => {
    return api.searchToken(params, filterQuery).then(
        response => {
            dispatch({
                type: types.FETCH_HEDERA_TOKENS_SUCCESS,
                data: response,
                filtered: filterQuery ? true : false,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_HEDERA_TOKENS_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchTokenDetails = params => dispatch => {
    return api.searchToken(params).then(
        response => {
            dispatch({
                type: types.FETCH_TOKEN_DETAIL_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TOKEN_DETAIL_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchUsageSummary = params => dispatch => {
    return api.fetchUsageSummary(params).then(
        response => {
            dispatch({
                type: types.FETCH_USAGE_SUMMARY_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_USAGE_SUMMARY_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchTokenTransfers = params => dispatch => {
    return api.searchTransfers(params).then(
        response => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_TRANSFERS_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_TRANSFERS_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchSearchTokenTransfer = params => dispatch => {
    return api.searchTransfers(params, true).then(
        response => {
            dispatch({
                type: types.FETCH_SEARCH_TOKEN_TRANSFERS_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_SEARCH_TOKEN_TRANSFERS_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchUserInvoices = params => dispatch => {
    return api.fetchUserInvoices(params).then(
        response => {
            if (params.unpaidOnly) {
                dispatch({
                    type: types.FETCH_USER_UNPAID_INVOICES_SUCCESS,
                    data: response,
                });
            } else {
                dispatch({
                    type: types.FETCH_USER_INVOICES_SUCCESS,
                    data: response,
                });
            }
        },
        error => {
            dispatch({
                type: types.FETCH_USER_INVOICES_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchTokenTransactions = params => dispatch => {
    return api.searchTokenTransactions(params).then(
        response => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_TRANSACTIONS_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_TRANSACTIONS_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchPricingPlans = params => dispatch => {
    return api.fetchPricingPlans(params).then(
        response => {
            dispatch({
                type: types.FETCH_PRICING_PLANS_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_PRICING_PLANS_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchTokenBalances = params => dispatch => {
    return api.fetchTokenBalancesForAccount(params).then(
        response => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_BALANCES_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_BALANCES_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchAllFeatures = params => dispatch => {
    return api.fetchAllFeatures(params).then(
        response => {
            dispatch({
                type: types.FETCH_ALL_FEATURES_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ALL_FEATURES_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchTokenTxnVolume = params => dispatch => {
    dispatch({
        type: types.FETCH_HEDERA_TOKEN_TXN_VOLUME_LOAD,
        data: [],
    });
    return api.fetchTokenTxnVolume(params).then(
        response => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_TXN_VOLUME_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_TXN_VOLUME_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchEnvConfigs = () => dispatch =>
    api.fetchEnvConfigs().then(
        response => {
            dispatch({
                type: types.FETCH_ENV_CONFIGS_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ENV_CONFIGS_FAILURE,
                error,
            });
            throw error;
        }
    );

export const fetchTokenTxnValue = params => dispatch => {
    return api.fetchTokenTxnValue(params).then(
        response => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_TXN_VALUE_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_HEDERA_TOKEN_TXN_VALUE_FAILURE,
                error,
            });
            throw error;
        }
    );
};

export const fetchNftsDetails = (params, filtered) => dispatch => {
    dispatch({
        type: types.FETCH_NFTS_DETAIL_REQUEST,
         isLoading: true,
     });
    return api.fetchNftsDetails(params).then(
        response => {
            dispatch({
                type: types.FETCH_NFTS_DETAIL_SUCCESS,
                filtered,
                data: response,
                isLoading: false,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_NFTS_DETAIL_FAILURE,
                error,
                isLoading: false,
            });
            throw error;
        }
    ).catch(error => {
        dispatch({
            type: types.FETCH_NFTS_DETAIL_FAILURE,
            error,
            isLoading: false,
        });
        throw error;
    });
};

export const fetchTopNftsDetails = params => dispatch => {
    return api.fetchNftsDetails(params).then(
        response => {
            dispatch({
                type: types.FETCH_TOP_NFTS_DETAIL_SUCCESS,
                data: response,
                isLoading: false,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TOP_NFTS_DETAIL_FAILURE,
                error,
                isLoading: false,
            });
            throw error;
        }
    );
};

export const fetchLatestNfts = params => dispatch => {
    dispatch({
        type: types.FETCH_LATEST_NFTS_LOAD,
        isLoading: true,
     });
    return api.fetchNftsDetails(params).then(
        response => {
            dispatch({
                type: types.FETCH_LATEST_NFTS_SUCCESS,
                data: response,
                isLoading: false,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_LATEST_NFTS_FAILURE,
                error,
                isLoading: false,
            });
            throw error;
        }
    );
};

export const fetchNftsHistoricalDetails = params => dispatch => {
    return api.fetchNftsHistoricalDetails(params).then(
        response => {
            dispatch({
                type: types.FETCH_NFTS_HISTORICAL_DETAIL_SUCCESS,
                data: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_NFTS_HISTORICAL_DETAIL_FAILURE,
                error,
            });
            throw error;
        }
    );
};

// Charts

export const fetchChartAllTransaction = query => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_ALL_TRANSACTION_CHART_LOAD,
        payload: [],
    });
    return api.fetchChartAllTransaction(query).then(
        response => {
            dispatch({
                type: types.FETCH_ALL_TRANSACTION_CHART_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ALL_TRANSACTION_CHART_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchTransactionTypeDimension = query => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_TRANSACTION_TYPE_DIMENSION_CHART_LOAD,
        payload: [],
    });
    return api.fetchTransactionTypeDimension(query).then(
        response => {
            dispatch({
                type: types.FETCH_TRANSACTION_TYPE_DIMENSION_CHART_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TRANSACTION_TYPE_DIMENSION_CHART_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}
export const fetchAccountsCreated = query => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_ACCOUNTS_CREATED_CHART_LOAD,
        payload: [],
    });
    return api.fetchAccountsCreated(query).then(
        response => {
            dispatch({
                type: types.FETCH_ACCOUNTS_CREATED_CHART_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACCOUNTS_CREATED_CHART_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchNftMinted = query => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_NFT_MINTED_CHART_LOAD,
        payload: [],
    });
    return api.fetchNftMinted(query).then(
        response => {
            dispatch({
                type: types.FETCH_NFT_MINTED_CHART_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_NFT_MINTED_CHART_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchNftTransfer = query => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_NFT_TRANSFER_CHART_LOAD,
        payload: [],
    });
    return api.fetchNftTransfer(query).then(
        response => {
            dispatch({
                type: types.FETCH_NFT_TRANSFER_CHART_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_NFT_TRANSFER_CHART_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}


export const fetchTokenTransfer = query => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_TOKEN_TRANSFER_CHART_LOAD,
        payload: [],
    });
    return api.fetchTokenTransfer(query).then(
        response => {
            dispatch({
                type: types.FETCH_TOKEN_TRANSFER_CHART_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TOKEN_TRANSFER_CHART_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchTokenMinted = query => (dispatch, getState) => {
    dispatch({
       type: types.FETCH_TOKEN_MINTED_CHART_LOAD,
        payload: [],
    });
    return api.fetchTokenMinted(query).then(
        response => {
            dispatch({
                type: types.FETCH_TOKEN_MINTED_CHART_SUCCESS,
                payload: response,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_TOKEN_MINTED_CHART_FAILURE,
                message: error.message || "Something went wrong.",
            });
        }
    );
}

export const fetchLatestCollection = params => dispatch => {
    dispatch({
        type: types.FETCH_LATEST_COLLECTION_NFTS_REQUEST,
         isLoading: true,
     });
    return api.fetchLatestCollection(params).then(
        response => {
            dispatch({
                type: types.FETCH_LATEST_COLLECTION_NFTS_SUCCESS,
                data: response,
                isLoading: false,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_LATEST_COLLECTION_NFTS_FAILURE,
                error,
                isLoading: false,
            });
            throw error;
        }
    ).catch(error => {
        dispatch({
            type: types.FETCH_LATEST_COLLECTION_NFTS_FAILURE,
            error,
            isLoading: false,
        });
        throw error;
    });;
};

export const fetchActiveCollection = params => dispatch => {
    dispatch({
        type: types.FETCH_ACTIVE_COLLECTION_NFTS_REQUEST,
         isLoading: true,
     });
    return api.fetchActiveCollection(params).then(
        response => {
            dispatch({
                type: types.FETCH_ACTIVE_COLLECTION_NFTS_SUCCESS,
                data: response,
                isLoading: false,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_ACTIVE_COLLECTION_NFTS_FAILURE,
                error,
                isLoading: false,
            });
            throw error;
        }
    ).catch(error => {
        dispatch({
            type: types.FETCH_ACTIVE_COLLECTION_NFTS_FAILURE,
            error,
            isLoading: false,
        });
        throw error;
    });
};

export const fetchSearchNfts = params => dispatch => {
    dispatch({
        type: types.FETCH_SEARCH_NFTS_REQUEST,
         isLoading: true,
     });
    return api.fetchNftsDetails(params).then(
        response => {
            dispatch({
                type: types.FETCH_SEARCH_NFTS_SUCCESS,
                data: response,
                isLoading: false,
            });
        },
        error => {
            dispatch({
                type: types.FETCH_SEARCH_NFTS_FAILURE,
                error,
                isLoading: false,
            });
            throw error;
        }
    );
};