import React, { Component } from 'react';
import { Grid, Breadcrumb } from 'semantic-ui-react';
import Page from '../shared/Page';
import TxnVolumeChartPanel from '../TxnVolumeChartPanel';
import AccountCreationVolumeChartPanel from '../AccountCreationVolumeChartPanel';
import TxnValueChartPanel from '../TxnValueChartPanel';
// import TxnTypeChartPanel from '../TxnTypeChartPanel';
import HtsTxnTypeChartPanel from '../HtsTxnTypeChartPanel';
import DappAccountBalanceChartPanel from '../DappAccountBalanceChartPanel';
import AccountBalanceChartPanel from '../AccountBalanceChartPanel';
import ContractBalanceChartPanel from '../ContractBalanceChartPanel';
import TokenTxnVolumeChartPanel from '../TokenTxnVolumeChartPanel';
import DappContractTxnVolumeChartPanel from '../DappContractTxnVolumeChartPanel';
import ContractTxnVolumeChartPanel from '../ContractTxnVolumeChartPanel';
import TxnTypeDimensionChartPanel from '../../organisms/chartsPanel/TxnTypeDimensionChartPanel';
import AllTxnChartPanel from '../../organisms/chartsPanel/AllTxnChartPanel';
import AccountCreationChartPanel from '../../organisms/chartsPanel/AccountCreationChartPanel';
import NftMintedChartPanel from '../../organisms/chartsPanel/NftMintedChartPanel';
import NftTransferChartPanel from '../../organisms/chartsPanel/NftTransferChartPanel';
import TokenMintedChartPanel from '../../organisms/chartsPanel/TokenMintedChartPanel';
import TokenTransferChartPanel from '../../organisms/chartsPanel/TokenTransferChartPanel';
import TxnTypeChartPanel from '../../organisms/chartsPanel/TxnTypeChartPanel';
import TxnTypeNonHcsChartPanel from '../../organisms/chartsPanel/TxnTypeNonHcsChartPanel';

import { parseQueryParams } from  '../../utils';
import { withRouter, Link } from 'react-router-dom';

export class Charts extends Component {
	render() {
		const params = parseQueryParams(this.props.location.search);
		return (
			<Page className="page-chart">
				<Grid>
					<Grid.Row  padded stackable columns={1}>
						<Grid.Column>
							<Breadcrumb style={{padding: '20px 0 0 10px'}}>
								<Breadcrumb.Section link> <Link to='home' >Home</Link></Breadcrumb.Section>
								<Breadcrumb.Divider />
								<Breadcrumb.Section active>Charts</Breadcrumb.Section>
							</Breadcrumb>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row columns={1}>
						<Grid.Column>
							{ params && params.name == 'txn-volume'
								? <TxnVolumeChartPanel />
								: ''
							}

							{/* { params && params.name == 'account-creation'
								? <AccountCreationVolumeChartPanel />
								: ''
							} */}

							{ params && params.name == 'txn-val'
								? <TxnValueChartPanel />
								: ''
							}

							{ params && params.name == 'txn-service-type'
								? <TxnTypeChartPanel isLegend={true} customHeight={300}/>
								: ''
							}

							{ params && params.name == 'txn-service-type-non-hcs'
								? <TxnTypeNonHcsChartPanel isLegend={true} customHeight={300}/>
								: ''
							}

							{ params && params.name == 'fungible-token-transaction'
								? <HtsTxnTypeChartPanel />
								: ''
							}

							{ params && params.name == 'non-fungible-token-transaction'
								? <HtsTxnTypeChartPanel nonFungible={true}/>
								: ''
							}

							{ params && params.name == 'dapp-acc-bal'
								? <DappAccountBalanceChartPanel accountNumber={params.accountNumber} />
								: ''
							}

							{ params && params.name == 'acc-bal'
								? <AccountBalanceChartPanel />
								: ''
							}

							{ params && params.name == 'contract-acc-bal'
								? <ContractBalanceChartPanel />
								: ''
							}

							{ params && params.name == 'token-volume'
								? <TokenTxnVolumeChartPanel />
								: ''
							}

							{ params && params.name == 'dapp-token-volume'
								? <DappContractTxnVolumeChartPanel />
								: ''
							}

							{ params && params.name == 'contract-txn-vol'
								? <ContractTxnVolumeChartPanel />
								: ''
							}

							{ params && params.name == 'txn-type-dimension'
								? <TxnTypeDimensionChartPanel isLegend={true} />
								: ''
							}

							{ params && params.name == 'all-txn'
								? <AllTxnChartPanel />
								: ''
							}

							{ params && params.name == 'account-creation'
								? <AccountCreationChartPanel />
								: ''
							}

							{ params && params.name == 'nft-minted'
								? <NftMintedChartPanel />
								: ''
							}

							{ params && params.name == 'nft-transfer'
								? <NftTransferChartPanel />
								: ''
							}


							{ params && params.name == 'token-transfer'
								? <TokenTransferChartPanel />
								: ''
							}


							{ params && params.name == 'token-minted'
								? <TokenMintedChartPanel />
								: ''
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Page>
		);
	}
};

export default withRouter(Charts);
