import { FETCH_TOKEN_DETAIL_SUCCESS,
  FETCH_TOKEN_DETAIL_FAILURE,
  FETCH_HEDERA_TOKEN_TRANSFERS_SUCCESS,
	FETCH_HEDERA_TOKEN_TRANSFERS_FAILURE,
	FETCH_HEDERA_TOKEN_TXN_VOLUME_LOAD,
	FETCH_HEDERA_TOKEN_TXN_VOLUME_SUCCESS,
	FETCH_HEDERA_TOKEN_TXN_VOLUME_FAILURE,
	FETCH_HEDERA_TOKEN_TXN_VALUE_SUCCESS,
	FETCH_HEDERA_TOKEN_TXN_VALUE_FAILURE,
	FETCH_HEDERA_TOKENS_SUCCESS,
	FETCH_HEDERA_TOKENS_FAILURE,
	FETCH_HEDERA_TOKEN_TRANSACTIONS_SUCCESS,
	FETCH_HEDERA_TOKEN_TRANSACTIONS_FAILURE,
	FETCH_HEDERA_TOKEN_BALANCES_SUCCESS,
	FETCH_HEDERA_TOKEN_BALANCES_FAILURE,
	FETCH_SEARCH_TOKEN_TRANSFERS_SUCCESS,
	FETCH_SEARCH_TOKEN_TRANSFERS_FAILURE
 } from '../types';
import { combineReducers } from 'redux';

const initialState = {};

const hederaTokens = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_HEDERA_TOKENS_SUCCESS:
			return {
				...state,
				tokens: action.data,
				filters: action.filtered === true ? state.filters : action.data.facets,
			};
		case FETCH_HEDERA_TOKENS_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};

const tokenDetails = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_TOKEN_DETAIL_SUCCESS:
			return {
				...state,
				token: action.data
			};
		case FETCH_TOKEN_DETAIL_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};

const hederaTokenTransfers = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_HEDERA_TOKEN_TRANSFERS_SUCCESS:
			return {
				...state,
				tokenTransfers: action.data
			};
		case FETCH_HEDERA_TOKEN_TRANSFERS_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};

const searchTokenTransfers = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_SEARCH_TOKEN_TRANSFERS_SUCCESS:
			return {
				...state,
				tokenTransfers: action.data
			};
		case FETCH_SEARCH_TOKEN_TRANSFERS_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};

const hederaTokenTxnVolume = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_HEDERA_TOKEN_TXN_VOLUME_LOAD:
			return {
				...state,
				loading: true,
				tokenTxnVolume: action.data
			}
		case FETCH_HEDERA_TOKEN_TXN_VOLUME_SUCCESS:
			return {
				...state,
				loading: false,
				tokenTxnVolume: action.data
			};
		case FETCH_HEDERA_TOKEN_TXN_VOLUME_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};

const hederaTokenTxnValue = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_HEDERA_TOKEN_TXN_VALUE_SUCCESS:
			return {
				...state,
				tokenTxnValue: action.data
			};
		case FETCH_HEDERA_TOKEN_TXN_VALUE_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};

const hederaTokenTransactions = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_HEDERA_TOKEN_TRANSACTIONS_SUCCESS:
			return {
				...state,
				tokenTransactions: action.data
			};
		case FETCH_HEDERA_TOKEN_TRANSACTIONS_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};

const tokenBalances = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_HEDERA_TOKEN_BALANCES_SUCCESS:
			return {
				...state,
				balances: action.data
			};
		case FETCH_HEDERA_TOKEN_BALANCES_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};

const hts = combineReducers({
	hederaTokens,
	tokenDetails,
	hederaTokenTransfers,
	hederaTokenTxnVolume,
	hederaTokenTxnValue,
	hederaTokenTransactions,
	tokenBalances,
	searchTokenTransfers
});

export default hts;

export const getHederaTokens = (state) => state.hts && state.hts.hederaTokens && state.hts.hederaTokens.tokens ? state.hts.hederaTokens.tokens.data : [];
export const getHederaTokensCount = (state) => state.hts && state.hts.hederaTokens && state.hts.hederaTokens.tokens ? state.hts.hederaTokens.tokens.totalCount : 0;
export const getTokenDetails = (state) => state.hts && state.hts.tokenDetails && state.hts.tokenDetails.token && state.hts.tokenDetails.token.data ? state.hts.tokenDetails.token.data[0] : {};
export const getHederaTokenTransfers = (state) => state.hts.hederaTokenTransfers && state.hts.hederaTokenTransfers.tokenTransfers && state.hts.hederaTokenTransfers.tokenTransfers.data ? state.hts.hederaTokenTransfers.tokenTransfers.data : [];
export const getTokenTransfersCount = (state) => state.hts.hederaTokenTransfers && state.hts.hederaTokenTransfers.tokenTransfers ? state.hts.hederaTokenTransfers.tokenTransfers.totalCount : 0;
export const getTokenTxnVolumeLoader = (state) => state.hts.hederaTokenTxnVolume && state.hts.hederaTokenTxnVolume.loading;
export const getTokenTxnVolume = (state) => state.hts.hederaTokenTxnVolume && state.hts.hederaTokenTxnVolume.tokenTxnVolume ? state.hts.hederaTokenTxnVolume.tokenTxnVolume :  {};
export const getTokenTxnValue = (state) => state.hts.hederaTokenTxnValue && state.hts.hederaTokenTxnValue.tokenTxnValue ? state.hts.hederaTokenTxnValue.tokenTxnValue :  {};
export const getTokenTransations = (state) => state.hts.hederaTokenTransactions && state.hts.hederaTokenTransactions.tokenTransactions && state.hts.hederaTokenTransactions.tokenTransactions.data ? state.hts.hederaTokenTransactions.tokenTransactions.data : [];
export const getTokenTransactionsCount = (state) => state.hts.hederaTokenTransactions && state.hts.hederaTokenTransactions.tokenTransactions ? state.hts.hederaTokenTransactions.tokenTransactions.totalCount : 0;
export const getTokenBalances = (state) => state.hts.tokenBalances && state.hts.tokenBalances.balances && state.hts.tokenBalances.balances.tokenBalance ? state.hts.tokenBalances.balances.tokenBalance : [];
export const getTokenBalancesCount = (state) => state.hts.tokenBalances && state.hts.tokenBalances.balances && state.hts.tokenBalances.balances.tokenBalance ? state.hts.tokenBalances.balances.tokenBalance.totalCount ? state.hts.tokenBalances.balances.tokenBalance.totalCount : state.hts.tokenBalances.balances.tokenBalance.length : 0;
export const getSearchTokenTransfers = (state) => state.hts.searchTokenTransfers && state.hts.searchTokenTransfers.tokenTransfers && state.hts.searchTokenTransfers.tokenTransfers.data ? state.hts.searchTokenTransfers.tokenTransfers.data : [];
export const getSearchTokenTransfersCount = (state) => state.hts.searchTokenTransfers && state.hts.searchTokenTransfers.tokenTransfers ? state.hts.searchTokenTransfers.tokenTransfers.totalCount : 0;
export const getSearchedTokenFilters = (state) => state.hts && state.hts.hederaTokens && state.hts.hederaTokens.tokens ? state.hts.hederaTokens.filters : {};