import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Modal, Image, Button, Icon } from 'semantic-ui-react';
import * as actions from '../redux/actions';
import DisplayContent from '../atoms/DisplayContent';
import { localDateTime, isMobile, decodeBase64 } from '../utils';
import { Link } from "react-router-dom";
import Base64Icon from "../assets/images/icons8-base-64-24.png";
import TxtIcon from "../assets/images/icons8-txt-24.png";
import * as api from "../apis";
import CodeSnippet from "../components/shared/CodeSnippet";
import CopyTextToClipboard from "../components/CopyTextToClipboard";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Carousel from 'nuka-carousel';
import MultiNFTFiles from '../atoms/MultiNFTFiles';

const NFTDetailsSummary = (props) => {

	const [modalOpen, setModalOpen] = useState(false);
	const [modalOpenForFiles, setModalOpenForFiles] = useState(false);
	const [ipfsID, setIpfsID] = useState('');
	const [httpsUrl, setHttpsUrl] = useState('');
	const [jsonData, setJSONData] = useState(null);
	const [clicked, setClicked] = useState(false);
	const [showText, setShowText] = useState(true);
	const [showFiles, setShowFiles] = useState(false);
	const [isLoadingJson, setIsLoadingJson] = useState(false);
	const ImageBaseUrl = `https://nftstorage.link/ipfs/`;

	const { nftsDetail, tokenDetails, transactions } = props;
	const {
        name,
		dataType,
		imageUrl,
		serialNumber,
		token,
		createdOn,
		owner,
		mintTransaction,
		metaData,
		description,
		marketplaceLink
    } = nftsDetail;

	useEffect(() => {
		getipfsId()
	}, [nftsDetail]);

	const customFees = tokenDetails && tokenDetails.customFees || [];
	const fixed_fees = customFees.length && customFees.find(fee => fee.fixed_fees);
	const royalty_fees = customFees.length && customFees.find(fee => fee.royalty_fees);

	  const FeeSection = () => {
		return (
			<>
				<Grid.Row className="bold" style={{backgroundColor: '#f2f2f2', paddingBottom: '0px'}}>
					<Grid.Column width={8}>Fixed Fees</Grid.Column>
					<Grid.Column width={8}>Paid To</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{backgroundColor: '#f2f2f2', paddingTop: '0px'}}>
					{
						fixed_fees && fixed_fees.fixed_fees ?
							<>
								<Grid.Column width={8}>
								<div >{fixed_fees.fixed_fees.amount} hbars</div>
								</Grid.Column>
								<Grid.Column width={8}>
									<div >{fixed_fees.collector_account_id}</div>
								</Grid.Column>
							</>
						: <>
							<Grid.Column width={8}>
								<div >None</div>
							</Grid.Column>
							<Grid.Column width={8}>
								<div >None</div>
							</Grid.Column>
						</>
					}
				</Grid.Row>
				<Grid.Row className="bold" style={{backgroundColor: '#f2f2f2', paddingBottom: '0px'}}>
					<Grid.Column width={8}>Royalty Fees</Grid.Column>
					<Grid.Column width={8}></Grid.Column>
				</Grid.Row>
				<Grid.Row style={{backgroundColor: '#f2f2f2', paddingTop: '0px'}}>
					{
						royalty_fees && royalty_fees.royalty_fees ?
							<>
								<Grid.Column width={8}>
									{
										royalty_fees.royalty_fees.exchange_value_fraction &&  (royalty_fees.royalty_fees.exchange_value_fraction.numerator && royalty_fees.royalty_fees.exchange_value_fraction.denominator) ?
										<div >{(royalty_fees.royalty_fees.exchange_value_fraction.numerator / royalty_fees.royalty_fees.exchange_value_fraction.denominator)*100 } %</div>
										: <div>Invalid</div>
									}
								{
									royalty_fees.royalty_fees && royalty_fees.royalty_fees.fallback_fee && royalty_fees.royalty_fees.fallback_fee.amount ?
										<div> Fallback {royalty_fees.royalty_fees.fallback_fee.amount} tℏ </div>
									: <div>None</div>
								}
								</Grid.Column>
								<Grid.Column width={8}>
									<div >{royalty_fees.collector_account_id}</div>
								</Grid.Column>
							</>
						: <>
							<Grid.Column width={8}>
								<div >None</div>
							</Grid.Column>
							<Grid.Column width={8}>
								<div >None</div>
							</Grid.Column>
						</>
					}
				</Grid.Row>
			</>
		)
	  }

	  const handleShowTextClick = () => {
		  setShowText(!showText);
		  return;
	  };

	  const openModal = () => {
		setModalOpen(true);
	};
	
	const closeModal = () => {
		setModalOpen(false);
	};

	const getipfsId = () => {
		let ipfsLink = decodeBase64(metaData) || '';
		let ipfsId = null;
		if (ipfsLink.includes('ipfs://')) {
			const splitedlink = ipfsLink.split('ipfs://')[1];
			ipfsId = splitedlink;
		} else if (ipfsLink.includes('https://')) {
			setHttpsUrl(ipfsLink);
		} else if (ipfsLink.includes('http://')) {
			setHttpsUrl(ipfsLink);
		}
		setIpfsID(ipfsId);
		gethttpsJsonData(ipfsLink, metaData);
	  }

	const gethttpsJsonData = async(url, base64) => {
		setIsLoadingJson(true);
		let jsond = await api.fetchJSONdataByUrl(url, base64);
		if (jsond) {
			if (jsond.files && jsond.files.length) {
				setShowFiles(true);
			}
		}
		const files= [
	   {
		   "type": "image/png",
			"uri": "ipfs://bafybeicz6gfdcat5ek2x3z6n3eyvjmaun2ydmlw3vs3p7tieii4tuz6fqu"
	   },
	   {
		   "uri": "ipfs://bafybeiglaq2yc6mubkwau2rqhy3ofr4sfs675qlwguizfiwjmpc2dn4nx4/6B7220FBE9C217D6.usdz",
		   "type": "model/vnd.usdz+zip",
	   },
	   {
			"uri": "ipfs://bawlkjaklfjoiaefklankfldanmfoieiajfl",
			"type": "application/pdf",
	   },
	   {
			"uri": "ipfs://bafybeie7choj4rhyiy3ch43lytgenwjem2fm5lujq6do7y4jmh4x6etify/E0601A7C83823863.json",
			"type": "application/json",
   		}
   ]
		// jsond = {...jsond, files: files }
		setJSONData(jsond);
		setIsLoadingJson(false);
	}


	const handleCopyClick = () => {
        setClicked(true);
        setTimeout(() => setClicked(false), 2000);
    };

	const getImageUrl = (uri) => {
		if (uri.includes('ipfs://')) {
			const splitedlink = uri.split('ipfs://')[1];
			const ipfsId = splitedlink;
			const imageUrl = `${ImageBaseUrl}${ipfsId}`;
			return imageUrl;
		}
		return uri;
    };

	const downloadJsonFile = () => {
		const jsonStr = JSON.stringify(jsonData, null, 2);
		const blob = new Blob([jsonStr], { type: 'text/plain' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = `nft_metadata_from_dragonglass_${token}_${serialNumber}.json`;
		document.body.appendChild(a);
		a.click();
		URL.revokeObjectURL(url);
		document.body.removeChild(a);
	  };

	  const downloadImage = async(url, fileName) => {
		try {
			const link = document.createElement('a');
			link.href = url;
			link.target = "_blank";
			link.download = fileName || 'downloaded-image.pNg';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		  } catch (error) {
			console.error('Error downloading image:', error);
		  }
	}

	const MetaDataJsonModal = () => {
		return (
			<div>
			  <Modal open={modalOpen} onClose={closeModal}>
				<Modal.Header>
					<div style={{color: 'black', display: 'flex', justifyContent: 'space-between'}}>
						<div>
							{tokenDetails && tokenDetails.name} Metadata JSON
						</div>
						<div style={{fontSize: '18px',display: 'flex', justifyContent: 'space-around'}}>
							<div className="clickable" onClick={() => downloadJsonFile()}><Icon name='download' /></div>
							<div> 
								<CopyToClipboard text={JSON.stringify(jsonData)} onCopy={() => handleCopyClick({ copied: true })}>
									<span>
										<CopyTextToClipboard clicked={clicked} />
									</span>
								</CopyToClipboard>
							 </div>
							<div> <Icon name='close' onClick={closeModal}/> </div>
						</div>
					</div>
					</Modal.Header>
				<Modal.Content>
					{
					isLoadingJson
					? <CodeSnippet code={"Loading..."} title="Meta Data Json"></CodeSnippet>
					: <CodeSnippet code={jsonData} title="Meta Data Json"></CodeSnippet>
					}
				</Modal.Content>
			  </Modal>
			</div>
		  );
	}

	const FilesModal = () => {
		return (
			<div>
			  <Modal size="large" open={modalOpenForFiles} onClose={() => setModalOpenForFiles(false)}>
				<Modal.Header>
					<div style={{color: 'black', display: 'flex', justifyContent: 'space-between'}}>
						<div>
							Files
						</div>
						<div style={{fontSize: '18px',display: 'flex', justifyContent: 'space-around'}}>
							<div> <Icon name='close' onClick={() => setModalOpenForFiles(false)}/> </div>
						</div>
					</div>
					</Modal.Header>
				<Modal.Content image 
				style={{ height: '80vh'}}
					>
				<Carousel
					style={{ height: '100%' }}
					heightMode={'max'}
					initialSlideHeight={800}
					adaptiveHeight={true}
					wrapAround={false}
					width={'100%'}
					// transitionMode={'scroll3d'}
					renderBottomCenterControls={() => null}
					renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) => (
						<div>
							{
								(slideCount !== 1) && (slideCount - 1) !== currentSlide ?
								<Icon
									onClick={nextSlide}
									name="angle right"
									className="clickable carousel-scroll carousel-scroll-right"
									circular
									inverted
								/>
								:  null
							}
						</div>
					)}
					renderCenterLeftControls={({ previousSlide, slideCount, currentSlide, endSlide }) => (
						<div>
							{
								(slideCount !== 1) && (slideCount - 1) == currentSlide ?
								<Icon
									onClick={previousSlide}
									name="angle left"
									className="clickable carousel-scroll carousel-scroll-left"
									circular
									inverted
								/>
								:  null
							}
						</div>
					)}
				>
				{jsonData && jsonData.files && jsonData.files.length && jsonData.files.map((file, idx) => (
					<div id="image-id">
						<div style={{textAlign: 'center', fontSize: '20px', marginBottom: '1%'}}>
							<span> {`${file.type}`} | </span>
							<span title="download" className="clickable" onClick={() => downloadImage(getImageUrl(file.uri), `${new Date().getTime()}.png`)}><Icon name='download' /></span>
						</div>
						{
							<MultiNFTFiles file={file} />
						}

					</div>
				))}
				</Carousel>
				</Modal.Content>
			  </Modal>
			</div>
		  );
	}

	return (
		<Grid stackable basic="very" size="small" className="animated fadeIn" columns={isMobile() ? 1 : 2} divider>
			<Grid.Row>
				<Grid.Column width={4} style={{maxWidth: '600px'}}>
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<div>
									<div className="bold font20">Serial #{serialNumber}</div>
									<div>Minted: {localDateTime(createdOn)}</div>
								</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column className="bold" width={16}>Token ID</Grid.Column>
							<Grid.Column width={16}><Link to={`/tokens/${token}`}>{token}</Link></Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column className="bold" width={16}>Collection Creator</Grid.Column>
							{
								transactions && transactions.length? 
									<Grid.Column width={16}><Link to={`/accounts/${transactions[0].payerID}`}>{transactions[0].payerID}</Link></Grid.Column>
								: <Grid.Column width={16}>None</Grid.Column>
							}
						</Grid.Row>
						{/* <Grid.Row>
							<Grid.Column className="bold" width={16}>Description</Grid.Column>
							<Grid.Column width={16}>{description}</Grid.Column>
						</Grid.Row> */}
						<Grid.Row>
							<Grid.Column className="bold" width={16}>Metadata</Grid.Column>
							{
								metaData ? 
									<Grid.Column title={showText ? decodeBase64(metaData) : metaData} width={14}>
										<div title={showText ? decodeBase64(metaData) : metaData}
										style={{
											maxWidth: "100%",
											wordWrap: 'break-word', wordBreak: 'break-word', overflow: "auto",
										}}>
											<span>{showText ? decodeBase64(metaData) : metaData}</span>
										</div>
											{ metaData
												?<>
												<span style={{"position": "relative", "bottom": "12px"}}>
													{ showText 
														? <Image src={Base64Icon} className="copy-icon" size="mini" style={{ width: '18px', height: '18px' }} onClick={handleShowTextClick}/>
														: <Image src={TxtIcon} className="copy-icon" size="mini" style={{ width: '18px', height: '18px' }} onClick={handleShowTextClick}/>
													}
												</span>
												</>
												: null
											}
									</Grid.Column>
								: <Grid.Column width={16}>None</Grid.Column>
							}
						</Grid.Row>
						{ (ipfsID || httpsUrl) && (
							<Grid.Row>
								{MetaDataJsonModal()}
								<Grid.Column width={16}>
									<span style={{cursor: 'pointer', color: '#1b97ae'}} onClick={() => openModal()}>Show Metadata</span>
									</Grid.Column>
							</Grid.Row>
						)
						}
						{ (showFiles) && (
							<Grid.Row>
								{FilesModal()}
								<Grid.Column width={16}>
									<span style={{cursor: 'pointer', color: '#1b97ae'}} onClick={() => setModalOpenForFiles(true)}>Show Files</span>
								</Grid.Column>
							</Grid.Row>
						)
						}
						<Grid.Row>
							<Grid.Column className="bold" width={16}>Create Transaction</Grid.Column>
							<Grid.Column width={16}><Link to={`/transactions/${mintTransaction}`}>{mintTransaction}</Link></Grid.Column>
						</Grid.Row>
						{
							FeeSection()
						}
						<Grid.Row>
							<Grid.Column className="bold" width={16}>
								Current Owner
							</Grid.Column>
							<Grid.Column width={16}>
								<Link to={`/accounts/${owner}`}>{owner}</Link>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Grid.Column>
				<Grid.Column width={12}>
					<Grid.Row>
						<Grid.Column column={1}>
							{
								<DisplayContent size="xl" playVideo={true} mimeType={dataType} src={imageUrl} alt="NFT"/>
							}
						</Grid.Column>
					</Grid.Row>
					{
						<Grid.Row style={{marginTop: '20px'}}>
							<Grid>
								<Grid.Row column={2}>
									<Grid.Column width={13}>{description}</Grid.Column>
									{
										nftsDetail && nftsDetail.marketplaceLink ? (
											<Grid.Column width={3} style={{display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
												<div>
													<Button style={{maxWidth: '80%'}} className="ellipsis" primary content={`Listed at ${nftsDetail.marketplaceLink}`} size="tiny" href={nftsDetail.marketplaceLink} target='_blank' />
												</div>
											</Grid.Column>
										)
										:	<Grid.Column width={3} style={{display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
												<Button primary content="Add NFT Marketplace" size="tiny" href={`/nft-marketplace?token=${token}&nftOwner=${owner}&serial=${serialNumber}`} />
											</Grid.Column>
									}
									
								</Grid.Row>
							</Grid>
						</Grid.Row>
					}
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
	id: tokenId,
});

export default withRouter(connect(mapStateToProps, actions)(NFTDetailsSummary));

