import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import Page from '../components/shared/Page';
import RegisterForMarketPlaceForm from '../organisms/forms/RegisterForMarketPlaceForm';

const NftMarketplaceFormPage = (props) => {
	return (
    <>
      <Helmet>
        <title>Dragon Glass - Register NFT Market Place</title>
      </Helmet>
      <Page>
        <Container fluid className="w-98">
          <Grid padded stackable>
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <Grid columns={1}>
                <Grid.Column>
                  <Grid stackable>
                    <Grid.Row style={{paddingBottom:'0px', paddingTop: '2%', borderBottom: '1px solid black',}}>
                        <Grid.Column>
                          <h2 style={{margin: '0px'}}>Register NFT Market Place</h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <RegisterForMarketPlaceForm {...props} />
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Container>
      </Page>
    </>
	);
}

export default NftMarketplaceFormPage;

