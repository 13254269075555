import React from 'react';
import { Link } from 'react-router-dom';
import Signup from '../containers/SignUp';
import Page from '../shared/Page';
import { Grid, Icon } from 'semantic-ui-react';
import Logo from '../shared/Logo';

export default () => (
	<Page className="auth">
		<Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
			<Grid.Column style={{ maxWidth: 450 }}>
				<Logo />
				<Signup />
				<br />
				<Link to="/">
					<Icon name="arrow left" /> BACK TO HOME
				</Link>
			</Grid.Column>
		</Grid>
	</Page>
);
