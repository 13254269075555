import React from 'react';
import PropTypes from 'prop-types';
import DataTable from './shared/DataTable';
import NftsTokenBalanceRow from './NftsTokenBalanceRow';
import NftsTokenBalanceTableHeader from './NftsTokenBalanceTableHeader';

const NftsTokenBalanceTable = ({ nftsBalances = [], size, offset, sort, order, totalCount, loadData }) => {
	const tokenBalanceRows = nftsBalances.map((data, index) => (
		<NftsTokenBalanceRow key={index} nftsBalance={data} />
	));
	return (
		<DataTable
			limit={size}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={NftsTokenBalanceTableHeader}
			rows={tokenBalanceRows}
			loadData={loadData}
		/>
	);
};

export default NftsTokenBalanceTable;

NftsTokenBalanceTable.propTypes = {
	// totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired
};
