import React, { useState, useEffect } from 'react';
import SubscriptionFiltersRemoveForm from './forms/SubscriptionFiltersRemoveForm';
import SubscriptionFiltersTable from './SubscriptionFiltersTable';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getUser, getSubscriptionFilters } from '../redux/reducers';

const SubscriptionFiltersDisplay = ({ 
    handleCancel, handleAddFilters, 
    handleSubscribeMessage, 
    user, 
    newFilter,
    subscription, 
    subscriptionFilters, 
    addSubscriptionFilters, 
    removeFilter 
    }) => {

    console.log("THIS IS SUPPOSED TO BE THE QUEUE NAME: ", subscription.queueName)
    console.log("THESE ARE THE FILTERS IN FILTERS REMOVE: ", subscriptionFilters)

    const [form, setForm] = useState({
        subscription: subscription,
        filters: []
    });

    useEffect(() => { setForm({ ...form, filters: subscriptionFilters }) }, [subscriptionFilters])
    useEffect(() => { setForm({ ...form, filters: [ ...form.filters, newFilter] }) }, [newFilter])

    console.log("THIS IS THE NEW FILTER IN FILTERS DISPLAY: ", newFilter)

    console.log("THIS IS THE FORM IN FILTERS DISPLAY: ", form )

    const [errors, setErrors] = useState({});
    // const [loading, setLoading] = useState(false);

    // const onSubmit = async e => {
    //     console.log('ON SUBMIT IS CALLED')
    //     e.preventDefault();
    //     // const errors = validate(form);
    //     // setErrors(errors);
    //     // if (Object.keys(errors).length === 0) {
    //     handleCancel();
    //     console.log("THIS IS THE FORM: ", form);
    //     addSubscriptionFilters(form);
    //     // }
    // }

    const onModelSelect = (e) => {
        console.log("ON MODEL SELECT CLICKED", e.target.textContent);
        setForm({ ...form, model: e.target.textContent })
    }

    const onFilterTypeSelect = (e) => {
        console.log("ON TYPE SELECT CLICKED", e.target.textContent);
        setForm({ ...form, filterType: e.target.textContent })
    }

    const handleFilterValues = (filter) => {
        setForm({ ...form, ids: [ ...form.ids, filter] });
        console.log("THIS IS THE FORM IN FILTERS: ", form)
    }

    const handleSignature = (sign) => {
        setForm({ ...form, signature: [ ...form.signature, sign] });
        console.log("THIS IS THE FORM IN FILTERS: ", form)
    }

    const deleteFilter = (filter, subscription) => {
        const newFilters = form.filters.filter(el => el !== filter);
        setForm({ ...form, filters: newFilters });
        console.log("THIS IS THE FORM AND SUBSCRIPTION IN FILTERS AFTER REMOVE: ", filter, form, subscription)
        removeFilter(subscription.id, filter.filterId);
    }

    const deleteSignature = (sign) => {
        const newSignature = form.signature.filter(el => el !== sign);
        setForm({ ...form, signature: newSignature });
        console.log("THIS IS THE FORM IN FILTERS AFTER REMOVE: ", form)
    }

    const onTransactionTypeSelect = (e, data) => {
        console.log("TRANSACTION TYPE SELECT: ", e.target.textContent);
        console.log("TRANSACTION TYPE SELECT - DATA: ", data.value);
        setForm({ ...form, transactionType: data.value })
    }

    // const validate = (form) => {
    //     const { queueName, user, ids } = form;
    //     let errors = {};
    //     if (!queueName || queueName.trim().length === 0) errors.queueName = "Queue Name name can't be blank";
    //     if (!user || user.trim().length === 0) errors.user = "User can't be blank";
    //     if (!ids || ids.length === 0) errors.accountId = "Ids can't be blank";

    //     return errors;
    // }

    // const { selectedModel, date, dataPeriod, count } = form;

    return (
        <SubscriptionFiltersTable 
            subscription={subscription}
            handleAddFilters={handleAddFilters}
            filters={form.filters}
            deleteFilter={deleteFilter}
        />
    )

    // return (
    //     <SubscriptionFiltersRemoveForm 
    //         // selectedModel={selectedModel}
    //         // date={date} 
    //         // dataPeriod={dataPeriod}
    //         // count={count}

    //         // setSelectedModel={setSelectedModel}
    //         // setDate={setDate}
    //         // setDataPeriod={setDataPeriod}
    //         // setCount={setCount}

    //         subscription={subscription}
    //         // subscriptionFilters={subscriptionFilters}
    //         filters={form.filters}
    //         signature={form.signature}

    //         onModelSelect={onModelSelect}
    //         onFilterTypeSelect={onFilterTypeSelect}
    //         handleFilterValues={handleFilterValues}
    //         deleteFilter={deleteFilter}
    //         handleSignature={handleSignature}
    //         deleteSignature={deleteSignature}
    //         onTransactionTypeSelect={onTransactionTypeSelect}

    //         handleSubscribeMessage={handleSubscribeMessage}
    //         handleCancel={handleCancel}
    //         onSubmit={onSubmit}
    //         errors={errors}
    //     />
    // )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
    subscriptionFilters: getSubscriptionFilters(state)
});

export default connect(mapStateToProps, actions)(SubscriptionFiltersDisplay);
