import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Tab, Divider } from 'semantic-ui-react';
import CodeSnippet from './shared/CodeSnippet';
import Panel from './shared/Panel';

const DappContractView = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        clearView() {
          setContent("");
          setStyles({
            style1: {fontWeight: "600", color: "black"},
            style2: {fontWeight: "600", color: "black"},
            style3: {fontWeight: "600", color: "black"},
        })
        }
      }));

    const panes = () => [
        {
            menuItem: {
                key: 'source',
                content: (
                    <span className="dapp-menu-item contract" style={styles.style1}>
                        BYTE CODE
                        &nbsp;<span style={{color: "black", fontWeight: "700"}}>|</span>
                    </span>
                )
            },
            // render: () => (
            //     <Tab.Pane as="div">
            //         {content}
            //     </Tab.Pane>
            // )
        },
        {
            menuItem: {
                key: 'byte',
                content: (
                    <span className="dapp-menu-item second contract" style={styles.style2}>
                        SOURCE
                        {/* &nbsp;<span style={{color: "black", fontWeight: "700"}}>|</span> */}
                    </span>
                )
            }
        },
        // {
        //     menuItem: {
        //         key: 'byte',
        //         content: (
        //             <span className="dapp-menu-item third contract" style={styles.style3}>
        //                 BYTE CODE
        //             </span>
        //         )
        //     }
        // },
    ];

    const snippet = <CodeSnippet style={{width: "70%", height: '80px'}}>{props.contract.byteCode}</CodeSnippet>
    const source = <CodeSnippet style={{width: "70%", height: '80px'}}>{props.contract.sourceCode}</CodeSnippet>

    const handleTabChange = (e, { activeIndex }) => {
        setActiveTabIndex(activeIndex);
        switch (activeIndex) {
            case 0:
                setStyles({
                    style1: {fontWeight: "700", color: "#2EA0B5"},
                    style2: {fontWeight: "600", color: "black"},
                    style3: {fontWeight: "600", color: "black"},
                })
                setContent(snippet);
                break;
            case 1:
                setStyles({
                    style1: {fontWeight: "600", color: "black"},
                    style2: {fontWeight: "700", color: "#2EA0B5"},
                    style3: {fontWeight: "600", color: "black"},
                })
                // setContent(`This is test for ${props.contract.name}`);
                setContent(source);
                break;
            // case 2:
            //     setStyles({
            //         style1: {fontWeight: "600", color: "black"},
            //         style2: {fontWeight: "600", color: "black"},
            //         style3: {fontWeight: "700", color: "#2EA0B5"},
            //     })
            //     setContent(snippet);
            //     break;
            default:
                setContent("");
        }
    };

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [styles, setStyles] = useState({
        style1: {fontWeight: "700", color: "#2EA0B5"},
        style2: {fontWeight: "600", color: "black"},
        style3: {fontWeight: "600", color: "black"},
    });
    const [content, setContent] = useState(source);

    useEffect(() => { setContent('') }, []);

    useEffect(() => {
        setStyles({
            style1: {fontWeight: "600", color: "black"},
            style2: {fontWeight: "600", color: "black"},
            style3: {fontWeight: "600", color: "black"},
        })
    }, []);


    return (
        <Fragment>
        <div id="tab" style={{position: "relative", left: "40%"}}>
                {Object.keys(props.contract).length > 0 && <Tab style={{width: "30%", position: "relative", top: "-10px", background: "white"}}
                    // menu={{ stackable: false, secondary: true, pointing: true }}
                    menu={{ text: true }}
                    panes={panes()}
                    renderActiveOnly={true}
                    onTabChange={handleTabChange}
                    // activeIndex={activeIndex}
                />}
            </div>
            <div style={{position: "relative", top: "-10px", width: "95%", left: "17%"}}>{content}</div>
        </Fragment>
    )
})

export default DappContractView;
