import React from 'react';
import { Table } from 'semantic-ui-react';

export default function NftsHistoricTableHeader(props) {
	return (
		<Table.Row>
			{
				props.isAccountPage && props.showArrow && (<Table.HeaderCell width={1}/>)
			}
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'tokenId' ? props.direction : null}
				onClick={() => props.handleSort('tokenId')}
			>
				Token ID
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'tokenId' ? props.direction : null}
				onClick={() => props.handleSort('tokenId')}
			>
				Serial Number
			</Table.HeaderCell>
			<Table.HeaderCell
				width={2}
				sorted={props.column === 'tokenId' ? props.direction : null}
				onClick={() => props.handleSort('tokenId')}
			>
				FROM
			</Table.HeaderCell>
			<Table.HeaderCell
        width={2}
        sorted={props.column === 'balance' ? props.direction : null}
				onClick={() => props.handleSort('balance')}
			>
				TO
			</Table.HeaderCell>
			<Table.HeaderCell
        width={2}
        sorted={props.column === 'balance' ? props.direction : null}
				onClick={() => props.handleSort('balance')}
			>
				TRANSFER ON
			</Table.HeaderCell>
			{
				// !props.isTransactionPage && (<Table.HeaderCell width={1} />)
			}
		</Table.Row>
	);
}
