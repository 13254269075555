import { FETCH_NODES_REQUEST, FETCH_NODES_SUCCESS, FETCH_NODES_FAILURE } from '../types';
import { combineReducers } from 'redux';

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_NODES_REQUEST:
			return true;
		case FETCH_NODES_SUCCESS:
		case FETCH_NODES_FAILURE:
			return false;
		default:
			return state;
	}
};

function nodesList(state = [], action) {
	switch (action.type) {
		case FETCH_NODES_SUCCESS:
			return [ ...action.payload ];
		default:
			return state;
	}
}

const nodes = combineReducers({
	isFetching,
	nodesList
});

export default nodes;

export const getNodes = (state) => state.nodesList;
export const getIsFetchingNodes = (state) => state.isFetching;
