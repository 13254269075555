import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Tab } from 'semantic-ui-react';
import DappSourceCodeSnippet from './shared/DappSourceCodeSnippet';
import DappByteCodeSnippet from './shared/DappByteCodeSnippet';

const DappContractCodeView = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        clearView() {
          setContent("");
        }
      }));

    const snippet =  
        <DappByteCodeSnippet style={{width: '100%', maxWidth: '100%', wordWrap: 'break-word', wordBreak: 'break-word', overflow: "auto", height: "200px"}}>
            <div>{props.contract.byteCode ? props.contract.byteCode : "Byte Code not available"}</div>
        </DappByteCodeSnippet>;

    const source = props.contract.sourceCode ? <DappSourceCodeSnippet style={{width: "100%", height: '200px', overflow: "auto"}}>
        <div>
            {props.contract.sourceCode}
        </div>
    </DappSourceCodeSnippet> : "Source Code not available";

    const panes = () => [
		{
			menuItem: {
				key: 'byte',
				content: <span>Byte Code</span>
			},
			render: () => (
				<Tab.Pane as="div">
					{snippet}
				</Tab.Pane>
			)
		},
		{
			menuItem: {
				key: 'source',
				content: <span>Source Code</span>
			},
			render: () => (
				<Tab.Pane as="div">
					{source}
				</Tab.Pane>
			)
		}
	];

    const handleTabChange = (e, { activeIndex }) => {
        // console.log('handleTabChange = ', activeIndex);
        setActiveTabIndex(activeIndex);
        switch (activeIndex) {
            case 0:
                setContent(snippet);
                break;
            case 1:
                setContent(source);
                break;
            default:
                console.log('unknown tab');
                setContent("");
        }
    };

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [content, setContent] = useState(source);

    useEffect(() => { setContent('') }, []);

    return (
        <Fragment>
            <Tab
				menu={{ stackable: true, secondary: true, pointing: true, tabular: true, style: {
                    display: "flex",
                    justifyContent: "flex-end"
                  } }}
				panes={panes()}
				renderActiveOnly={true}
				onTabChange={handleTabChange}
			/>
        </Fragment>
    )
})

export default DappContractCodeView;
