import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FilesList from '../FilesList';
import * as actions from '../../redux/actions';
import { getAccountFiles } from '../../redux/reducers';
import { Pagination } from 'semantic-ui-react';

const limit = 5;
class DappAccountFiles extends Component {
	state = {
		page: 1
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.accountNumber !== prevProps.accountNumber) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchAccountFiles, accountNumber } = this.props;
		fetchAccountFiles(accountNumber);
	}

	onChangePage = (event, data) => {
		const { activePage } = data;
		if (activePage !== this.state.page) {
			this.setState({ page: activePage }, () => {});
		}
	};

	render() {
		const activePage = this.state.page;
		const totalCount = this.props.files.length;
		const offset = limit * (activePage - 1);
		const pagedData = this.props.files.slice(offset, offset + limit);
		return (
			<React.Fragment>
				<FilesList files={pagedData} />
				{totalCount > limit && (
					<Pagination
						totalPages={Math.ceil(totalCount / limit)}
						activePage={this.state.page}
						boundaryRange={0}
						siblingRange={0}
						onPageChange={this.onChangePage}
					/>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	// id: accountId,
	files: getAccountFiles(state, ownProps.accountNumber)
});

DappAccountFiles = withRouter(connect(mapStateToProps, actions)(DappAccountFiles));

export default DappAccountFiles;
