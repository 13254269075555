import { combineReducers } from "redux";
import {
    FETCH_TRANSACTION_SUCCESS,
    FETCH_TRANSACTIONS_SUCCESS,
    FETCH_TRANSACTIONS_PIE_CHART_REQUEST,
    FETCH_TRANSACTIONS_PIE_CHART_SUCCESS,
    FETCH_RAW_TRANSACTION_SUCCESS,
    FETCH_TRANSACTION_PARAMS_SUCCESS,
    FETCH_TRANSACTION_PARAMS_FAILURE,
} from "../types";

const addEntry = (state, txn) => {
    return {
        ...state,
        [txn.transactionID]: { ...state[txn.transactionID], ...txn },
    };
};

const addEntries = (state, transactions) => {
    const entries = transactions.reduce((r, t) => addEntry(r, t), {});
    return {
        ...state,
        ...entries,
    };
};

const addRaw = (state, txId, data) => {
    return {
        ...state,
        [txId]: { ...state[txId], raw: data },
    };
};

const addParams = (state, txId, data) => {
    return {
        ...state,
        [txId]: { ...state[txId], params: data },
    };
};

const transactionsById = (state = {}, action) => {
    switch (action.type) {
        case FETCH_TRANSACTION_SUCCESS:
            return addEntry(state, action.response);
        case FETCH_TRANSACTIONS_SUCCESS:
            return addEntries(state, action.response.data);
        case FETCH_RAW_TRANSACTION_SUCCESS:
            return addRaw(state, action.id, action.response);
        case FETCH_TRANSACTION_PARAMS_SUCCESS:
            return addParams(state, action.txId, action.response);
        default:
            return state;
    }
};

const alltransactions = (state = [], action) => {
    switch (action.type) {
        case FETCH_TRANSACTIONS_SUCCESS:
            const entries = action.response.data.reduce((r, t) => {
                if (!state.includes(t.transactionID)) r.push(t.transactionID);
                return r;
            }, []);
            return [...state, ...entries];
        default:
            return state;
    }
};

const pieChartData = (state = [], action) => {
    switch (action.type) {
        case FETCH_TRANSACTIONS_PIE_CHART_REQUEST:
            return {
                loading: true,
            }
        case FETCH_TRANSACTIONS_PIE_CHART_SUCCESS:
            let entries = [];
            if (action.response.facets && action.response.facets.transactionTypes) {
                for (var i in action.response.facets.transactionTypes) {
                    let eachType = action.response.facets.transactionTypes;
                    let obj = {
                        name: i,
                        value: eachType[i],
                        type: "TOKEN",
                    };
                    entries.push(obj);
                }
            }
            return { ...state, entries: entries, loading: false,};
        default:
            return state;
    }
};

const transactionsReducer = combineReducers({
    byId: transactionsById,
    allIds: alltransactions,
    pieChart: pieChartData,
});

export default transactionsReducer;

export const getTransaction = (state, id) => state.byId[id];
export const getTransactions = state => state.allIds;
export const getRawTransaction = (state, id) => (state.byId[id] ? state.byId[id].raw : null);
export const getTransactionsPieChart = state => state.pieChart;
