import React, { Component } from 'react';
import { getNftsData } from '../redux/reducers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../redux/actions';
import NftsTokenBalanceTable from './NftsTokenBalanceTable';

class NftsTokenBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 5,
      sort: 'serialNumber',
	    order: 'desc',
      page: 1
    };
  }


  componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
  }

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		const { fetchNftsDetails, tokenId } = this.props;
		const params = { tokenId: tokenId, ...this.state };
		fetchNftsDetails(params);
  };

  getPaginatedTokenBalances = (nftTokenBalances, offset, limit) => {
    let paginatedBalances = [];
    if (nftTokenBalances && nftTokenBalances.length) {
      console.log('offset = ' + offset);
      console.log('limit = ' + limit);
      paginatedBalances = nftTokenBalances.splice(offset, limit);
      console.log(paginatedBalances);
    }
    return paginatedBalances;
  };

	render() {
   const { nftTokenBalances, token } = this.props;
   const { limit, offset } = this.state;
   const totalCount = (nftTokenBalances && nftTokenBalances.totalCount) ? nftTokenBalances.totalCount : 0;
   let NftsTokenBalances = nftTokenBalances.nftsData;
   if (nftTokenBalances && nftTokenBalances.nftsData && nftTokenBalances.nftsData.length && this.props.filterText) {
    NftsTokenBalances = nftTokenBalances.nftsData.filter(nft=> nft.serialNumber == this.props.filterText || nft.owner == this.props.filterText)
   }
		return (
      <div id="nfts-transactions-table">
        <NftsTokenBalanceTable
          token={token}
          nftsBalances={NftsTokenBalances}
          limit={limit}
          offset={offset}
          sort={this.state.sort}
          order={this.state.order}
          totalCount={totalCount}
          loadData={this.loadData}
        />
      </div>
    );
	}
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
  tokenId: tokenId,
  nftTokenBalances: getNftsData(state)
});

export default withRouter(connect(mapStateToProps, actions)(NftsTokenBalance));