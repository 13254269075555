import React, { Component } from 'react';
import { getTokenDetails, getHederaTokenTransfers, getTokenTransfersCount, getSearchTokenTransfers, getSearchTokenTransfersCount } from '../redux/reducers/hts';
import {  Tab, Table, Pagination } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as actions from '../redux/actions';
import { formatCurrency, formatNumber } from "../utils/index";
import PageSizeSelect from './shared/PageSizeSelect';
import TokenTransferTable from './TokenTransferTable';

class TokenTransfers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 5,
      sort: 'transferTime',
	    order: 'desc',
      page: 1
    };
    this.showTransactionDetails = this.showTransactionDetails.bind(this);
  }


  componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
    if (this.props.accountId !== prevProps.accountId || this.props.filterText !== prevProps.filterText) {
			this.setState(
				{
					offset: 0,
					page: 1
				},
				() => this.fetchData()
			);
		}
  }

	loadData = (viewParams) => {
		const params = { ...this.state, ...viewParams };
		this.setState({ ...params }, () => this.fetchData());
	};

	fetchData = () => {
		const { filterText, fetchTokenTransfers, fetchSearchTokenTransfer, id, accountId, transactionID, isTransactionPage } = this.props;
		const params = { q: filterText, tokenId: id, ...this.state };
    if (isTransactionPage) {
      params['limit'] = 10;
      this.setState({limit: 10});  
    }
    if (accountId || transactionID) {
      params['accountId'] = accountId;
      params['transactionId'] = transactionID;
      fetchSearchTokenTransfer(params)
    } else {
      fetchTokenTransfers(params);
    }
  };

  showTransactionDetails = (transfer) => {
		this.props.history.push(`/transactions/${transfer.transactionID}`);
	};

	render() {
    const { tokenTransfers, totalCount, searchTokenTransferCount, token, searchTokenTransfer, isAccountPage, isTransactionPage, showArrow } = this.props;
    const { limit, offset } = this.state;
    let tokenTransfer = tokenTransfers;
    let totalCounts = totalCount;
    if (isAccountPage || isTransactionPage) {
      tokenTransfer = searchTokenTransfer;
      totalCounts = searchTokenTransferCount;
    }
    // if (isTransactionPage && tokenTransfer.length) {
    //   tokenTransfer = tokenTransfer.filter(tf => tf.amount > 0);
    // }

	  return (
      <TokenTransferTable
        token={token}
        transfers={tokenTransfer}
        size={limit}
        limit={limit}
        offset={offset}
        sort={this.state.sort}
			  order={this.state.order}
        totalCount={totalCounts}
        loadData={this.loadData}
        showDetails={this.showTransactionDetails}
        showArrow={showArrow}
        isNoPagination={isTransactionPage ? true : false}
      />
    );
	}
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => ({
  id: tokenId,
  token: getTokenDetails(state),
  tokenTransfers: getHederaTokenTransfers(state),
  totalCount: getTokenTransfersCount(state),
  searchTokenTransfer: getSearchTokenTransfers(state),
  searchTokenTransferCount: getSearchTokenTransfersCount(state)

});

export default withRouter(connect(mapStateToProps, actions)(TokenTransfers));