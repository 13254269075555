import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from 'react-redux';
import { Button, Container, Grid, Image, Segment, Table } from 'semantic-ui-react';
import * as actions from '../redux/actions';
import { getTopNftsData } from '../redux/reducers';
import { removeEmpty } from "../utils";
import DisplayContent from "../atoms/DisplayContent";
import Info from "../atoms/Info";
import LoadingIndicator from "../components/containers/LoadingIndicator";
import { fromDate } from '../utils';
import Item from '../components/shared/Item';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import NFTExplorerList from "../atoms/NFTExplorerList";


const styles = {
	containerStyle: {
		marginTop: '2%',
	  },
	contentGrid: {
		marginBottom: '20px',
	  },
	  nftContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		// height: '10vh',
	  },
	  ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis', 
		whiteSpace: 'nowrap',
		// fontWeight: 600,
		// maxWidth: '180px',
	  },
	  breakEllipsis: {
		width: '100%',
		maxWidth: '100%',
		wordWrap: 'break-word',
		wordBreak: 'break-word'
	  },
	  bold: {
		fontWeight: 600,
	  }
}
export const TopNfts = (props) => {
	const { nftsData } = props;
	const fixedValue = 1;
	const nfts = nftsData && nftsData.nftsData || [];
	// console.log('nfts', nfts);
	const history = useHistory();

	useEffect(() => {
		fetchData();
	  }, [fixedValue]);

	const fetchData = () => {
		const { fetchNftsDetails, fetchTopNftsDetails, searchParams } = props;
		const params = { ...searchParams, limit: 5, sort: 'createdOn', order: 'desc' };
		const queryParams = removeEmpty(params);
		fetchTopNftsDetails(queryParams)
		// fetchNftsDetails(queryParams);
	};

	const handleDetailsClick = (token, serialNumber) => {
		console.log('handleDetailsClick called');
		history.push(`/nft-token/${token}?serialNumber=${serialNumber}`);
	}

	const handleTokenClick = (token, serialNumber) => {
		console.log('handleTokenClick called');
		history.push(`/tokens/${token}`);
	}

	const redirectToSearch = () => {
		history.push(`/nft-explorer`);
	}

	const NFTComponent = (nft) => {
		// const nft = {
		// 	"serialNumber": 11893,
		// 	"token": "0.0.1714683",
		// 	"metaData": "aXBmczovL2JhZmtyZWljb2JsbW1waXlreGY2bzd6aGZ3NWplcWRtM3g1dWE2a2R2NzMzeHE1emJmYWZieXBicnd5",
		// 	"owner": "0.0.3145713",
		// 	"createdOn": "2023-07-11T04:38:17.136741802Z",
		// 	"deleted": false,
		// 	"mintTransaction": "0010258961689050285258793369",
		// 	"imageUrl": "https://ipfs.io/ipfs/bafybeieg4bhc5yhdk3j65r5tzjwwxdrv64ry7grqiwgj6qixipekpvbffu",
		// 	"dataType": "video/mp4",
		// 	"name": "Road Code Access Tokens"
		//   }
		let padding = { paddingBottom: nft.name ? '0px': '16px' };
		let metaPadding = { paddingBottom: nft.metaData ? '0px': '16px' };
		return (
			<div onClick={() => handleDetailsClick(nft.token, nft.serialNumber)} style={{cursor: 'pointer'}}>
				<div style={{ position: 'relative', paddingBottom: '100%', height: '100%' }}>
					<DisplayContent height="280" size={'xl'} mimeType={nft.dataType} src={nft.imageUrl} alt="NFT" styles={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}/>
				</div>
				<div style={{background: 'white', padding: '0px'}}>
					<Table>
						<Table.Body>
							<Table.Row>
								<Table.Cell>
									<div style={styles.ellipsis}>
										<p style={styles.bold}>Token</p>
										<p>{nft.token}</p>
									</div>
								</Table.Cell>
								<Table.Cell>
									<div style={styles.ellipsis}>
										<p style={styles.bold}>Name</p>
										<p style={{...styles.ellipsis, ...padding}} >{nft.name || ''}</p>
									</div>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<div style={styles.ellipsis}>
										<p style={styles.bold}>Serial #</p>
										<p>#{nft.serialNumber}</p>
									</div>
								</Table.Cell>
								<Table.Cell>
									<div style={styles.ellipsis}>
										<p style={styles.bold}>Create Date</p>
										<p>{nft.createdOn ? fromDate(nft.createdOn) : ''}</p>
									</div>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<div style={styles.ellipsis}>
										<p style={styles.bold}>Owner</p>
										<p>{nft.owner}</p>
									</div>
								</Table.Cell>
								<Table.Cell>
									<div style={styles.ellipsis}>
										<p style={styles.bold}>Metadata</p>
										<p style={{...styles.ellipsis, ...metaPadding}} >{nft.metaData || ''}</p>
									</div>
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</div>
			</div>
		);
	  };

	  const NewNFTComponent = (nft) => {
		// const nft = {
		// 	"serialNumber": 11893,
		// 	"token": "0.0.1714683",
		// 	"metaData": "aXBmczovL2JhZmtyZWljb2JsbW1waXlreGY2bzd6aGZ3NWplcWRtM3g1dWE2a2R2NzMzeHE1emJmYWZieXBicnd5",
		// 	"owner": "0.0.3145713",
		// 	"createdOn": "2023-07-11T04:38:17.136741802Z",
		// 	"deleted": false,
		// 	"mintTransaction": "0010258961689050285258793369",
		// 	"imageUrl": "https://ipfs.io/ipfs/bafybeieg4bhc5yhdk3j65r5tzjwwxdrv64ry7grqiwgj6qixipekpvbffu",
		// 	"dataType": "video/mp4",
		// 	"name": "Road Code Access Tokens"
		//   }
		let padding = { paddingBottom: nft.name ? '0px': '16px' };
		let metaPadding = { paddingBottom: nft.metaData ? '0px': '16px' };
		return (
			<div onClick={() => handleDetailsClick(nft.token, nft.serialNumber)} style={{cursor: 'pointer'}}>
				<div className="visual" style={{ position: 'relative', paddingBottom: '100%', height: '100%' }}>
					<DisplayContent height="280" size={'xl'} mimeType={nft.dataType} src={nft.imageUrl} alt="NFT" styles={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}/>
				</div>
				<div style={{background: 'white', padding: '10px'}}>
					{/* <Grid centered columns={2} stackable>
						<Grid.Row>
							<Grid.Column style={styles.ellipsis}>{nft.name}</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2}>
							<Grid.Column style={styles.ellipsis} width={8}>{nft.token}</Grid.Column>
							<Grid.Column style={styles.ellipsis} width={8}>#{nft.serialNumber}</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2}>
							<Grid.Column style={styles.ellipsis} width={8}>Created</Grid.Column>
							<Grid.Column style={styles.ellipsis} width={8}>Owner</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2}>
							<Grid.Column style={styles.ellipsis} width={16}>{localDateTime(nft && nft.createdOn)}</Grid.Column>
							<Grid.Column style={styles.ellipsis} width={16}>{nft.owner}</Grid.Column>
						</Grid.Row>
					</Grid> */}
					<div style={styles.ellipsis} className="bold">{nft.name || "None"}</div>
					<div>{nft.token}  #{nft.serialNumber}</div>
					<div>
						<Grid>
							<Grid.Row columns={2} style={{paddingBottom: "2px"}}>
								<Grid.Column>Created</Grid.Column>
								<Grid.Column>Owner</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
					<div>
						<Grid>
							<Grid.Row columns={2}>
								<Grid.Column >{nft.createdOn ? fromDate(nft.createdOn) : ''}</Grid.Column>
								<Grid.Column>{nft.owner}</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
				</div>
			</div>
		);
	  };
	  if (!(nfts && nfts.length)) {
		return (<LoadingIndicator />)
	  } else {
		  return (
			  <Fragment>
					  <Container textAlign="center" style={styles.containerStyle} fluid className="w-98">
						  <Grid columns={1} style={styles.contentGrid}>
							  <Grid.Row style={{background: "black", color: 'white', padding: '5px'}}>
								  <div>
									  <span style={{padding: '12px'}}>Recently Minted NFTs <Info id="recentNfts" /></span>
								  </div>
							  </Grid.Row>
							  {/* <Grid.Row>
							  	<NFTExplorerList nfts={nfts} noPagination={true} isCenter={true} noRoundBorder={true}/>
							  </Grid.Row> */}
							  <Grid.Row>
								  <Grid centered columns={5} stackable>
									  {
										  nfts && nfts.length
										  ? 	(
												  <Grid.Row >
													  {
														  nfts.map((nft, i) => {
															  return (
																//   <Grid.Column key={i} mobile={16} computer={3} widescreen={2}>
																  <Grid.Column key={i} mobile={16} computer={3}>
																	  {NewNFTComponent(nft)}
																  </Grid.Column>
															  )
														  })
													  }
												  </Grid.Row>
											  )
										  : null
									  }
								  </Grid>
							  </Grid.Row>
							  <Grid.Row>
								  <Grid.Column className="bold clickable" style={{textAlign: 'right', paddingRight: '5%'}} onClick={redirectToSearch}>
									<Link to={`/nft-explorer`}>
										See More ....
									</Link>
									</Grid.Column>
							  </Grid.Row>
						  </Grid>
					  </Container>
				  </Fragment>
		  );
	  }
};

const mapStateToProps = (state) => ({
	nftsData: getTopNftsData(state),
});

export default connect(mapStateToProps, actions)(TopNfts);
