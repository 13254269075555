import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getLatestCollectionsData } from '../redux/reducers';
import * as actions from '../redux/actions';
import { removeEmpty } from '../utils';
import Collections from '../atoms/Collections';
import { useHistory } from "react-router";
import LoadingIndicator from "../components/containers/LoadingIndicator";

const LatestCollections = (props) => {
	
	const history = useHistory();
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState('createdOn');
	const [order, setOrder] = useState('desc');
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	
	const { latestCollection } = props;
	const latestCollections = latestCollection && latestCollection.latestCollections && latestCollection.latestCollections || [];
	const totalCount = latestCollection && latestCollection.totalCount || 0;
    useEffect(() => { fetchData() }, [limit, page]);

	useEffect(() => {
		setIsLoading(props.latestCollection.isLoading)
	}, [props.latestCollection.isLoading]);
	
	const fetchData = () => {
		 const queryParams = {
			offset,
			limit,
			page,
			sort,
			order,
		 }
		const { fetchLatestCollection, searchParams } = props;
		const params = { ...searchParams, ...queryParams};
		const queryParamsF = removeEmpty(params);
		fetchLatestCollection(queryParamsF)
	};

	const onLoadMore = (params) => {
		setIsLoading(true);
		setOffset(params.offset);
		setPage(params.page)
		setLimit(limit);
	}

    const handleDetailsClick = (token) => {
        history.push(`/tokens/${token}`);
    }

	const handleInternalDetailsClick = (token, serialNumber) => {
        history.push(`/nft/${token}?serialNumber=${serialNumber}`);
    }

	return (
			<Collections 
			isLoading={isLoading}
			onLoadMore={onLoadMore} totalCount={totalCount} limit={limit} 
			collections={latestCollections} handleDetailsClick={handleDetailsClick} 
			handleInternalDetailsClick={handleInternalDetailsClick} />
	)
}

const mapStateToProps = (state) => ({
	latestCollection: getLatestCollectionsData(state),
});

export default connect(mapStateToProps, actions)(LatestCollections);