import React from 'react';
import { Header, Button } from 'semantic-ui-react';
import { parseQueryParams } from '../../utils/index';
import Page from '../shared/Page';
import '../../assets/css/components/paymentErrorPage.scss';



export default (props) => {
  console.log(props);
  const goToProfile = () => {
    props.history.replace('/profile');
  };

  const getErrorMsg = (params) => {
    let errorMsg = "";
    if (params && params.noPayment === 'true') {
      errorMsg = `Sorry, there was an error. Don't worry - we haven't taken payment yet and you can try again. If this error persists, please contact <a href="mailto:info@dragonglass.me"><u>info@dragonglass.me</u></a>.`;
    } else if (params && params.noInvoice === 'true') {
      errorMsg = `Sorry, there was an error while adding units to your DragonGlass A/C. If you don't see the purchased units in 1 or 2 business days, please contact <a href="mailto:info@dragonglass.me"><u>info@dragonglass.me</u></a>.`;
    }
    return errorMsg;
  };
  const params = parseQueryParams(props.location.search);
  const errMsg = getErrorMsg(params);

  return (
    <Page>
      <div className="page payment-error">
        <div className="page-content">
          <div className="error-block">
            <Header size="huge" className="error-heading">
              Payment Failed!
            </Header>
            <p dangerouslySetInnerHTML={{__html: errMsg}}></p>
            <Button onClick={goToProfile} primary basic>
              Go to Profile
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
};
