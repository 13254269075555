import { 
    FETCH_SUBSCRIPTION_MODELS_SUCCESS,
    FETCH_SUBSCRIPTION_MODELS_FAILURE
} from '../types';

export default function subscriptionModels (state = [], action) {
	switch (action.type) {
		case FETCH_SUBSCRIPTION_MODELS_SUCCESS:
			console.log("IN REDUCER, FETCH SUBSCRIPTION MODELS", action.payload)
			return [ ...action.payload ];
		case FETCH_SUBSCRIPTION_MODELS_FAILURE:
			return [];

		// case DELETE_API_KEY_SUCCESS:
		// 	return [...state].filter(el => el.key !== action.payload);
		// case DELETE_API_KEY_FAILURE:
		// 	return [...state];

		default:
			return state;
	}
}

export const getSubscriptionModels = (state) => state;