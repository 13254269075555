import React from 'react';
import { Link } from 'react-router-dom';

import '../animate.css';

const profile = <Link to="/profile" target="_blank">User Profile page</Link>

const signingup = <Link to="/signup" target="_blank">signing up</Link>

const catalog = <Link to="/dappcatalog" target="_blank">DApp Catalog</Link>

export const DeploymentDescription = () => {
    return (
        <div className="description-container animated fadeIn">
            <div className="paragraph-header">Overview</div>
            <div className="description-text">
                DragonGlass provides DApp development community an ability to register 
                their DApp on the platform. DApp registered on the DragonGlass platform 
                will have a custom page that could be accessed from the {catalog}. 
                The registered DApp might also be featured on DragonGlass Featured DApps 
                section on the home page. 
            </div>
            <div className="description-text">
                In this section, we will cover the DApp self-service registration on DragonGlass. 
            </div>
            <div className="paragraph-header" style={{marginTop: "30px"}}>Pre-requisites</div>
            <div className="description-text">
                In order to register your DApp on DragonGlass, you are required to create DragonGlass 
                User Profile by {signingup} on DragonGlass.
            </div>
            <div className="image-div" style={{marginLeft: "30%", marginBottom: "30px"}}>
                <img width="55%" src="/sign_up_menu.jpg" />
            </div>
            <div className="paragraph-header" style={{marginTop: "30px"}}>Registration Process</div>
            <div className="description-text" style={{marginLeft: "30px", marginRight: "30px", display: "flex"}}>
                1.	<span style={{paddingLeft: "10px"}}>Sign in and navigate to the {profile} by clicking on top right profile icon.</span>
            </div>
            <div className="image-div" style={{margin: "30px 0"}}>
                <img width="90%" src="/dapp_click_profile.png" />
            </div>
            <div className="description-text" style={{marginLeft: "30px", marginRight: "30px", display: "flex"}}>
                2.	<span style={{paddingLeft: "10px"}}>Click “Register A DApp” button on the Profile page under “My DApp” section.</span>
            </div>
            <div className="image-div" style={{marginLeft: "15%", marginBottom: "20px"}}>
                <img width="80%" src="/register_a_dapp.png" alt="" />
            </div>
            <div className="description-text" style={{marginLeft: "30px", marginRight: "30px", display: "flex"}}>
                3.	<span style={{paddingLeft: "10px"}}>This will open a registration form as shown below, where the user must provide 
                required information of company name, title of the DApp, and deployer’s account ID. 
                User can also upload abi, source code file, byte code file and logo.</span>
            </div>
            <div className="image-div" style={{margin: "30px 0"}}>
                <img width="90%" src="/dapp_form.png" />
            </div>
            <div className="description-text" style={{marginLeft: "30px", marginRight: "30px", display: "flex"}}>
                4.	<span style={{paddingLeft: "10px"}}>On Submit, the request will be sent to DragonGlass DApp registration team for 
                approval. Once approved, the DApp will be available on DApp marketplace. </span>
            </div>


            {/* <div className="description-text">
                In the application form user must provide required information, such as 
                name of the company, title of the DApp, and deployer’s account ID. 
            </div>
            <div className="image-div">
                <img width="90%" src="/dapp_compulsory_fields.png" />
            </div>
            <div className="description-text">
                User can also provide optional data, such as a description of the DApp, contract ID, date 
                of deployment of the DApp, and links to the website as well as Android and iOS apps, if available. 
            </div>
            <div className="description-text">
                Next user can select Abi, Source code, Byte code, and Logo files to be uploaded. Upload 
                controls can be found at the bottom of the application form. 
            </div>
            <div className="image-div">
                <img width="40%" src="/dapp_file_upload.png" />
            </div>
            <div className="description-text">
                The process of deployment is completed by clicking the Submit button at the bottom of the application form. 
            </div>
            <div className="image-div" style={{marginLeft: "37%"}}>
                <img width="32%" src="/submit_button.png" />
            </div> */}
        </div>
    )
}

export default DeploymentDescription;
