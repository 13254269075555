import React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

export default function ResetPasswordForm({ password, confirmPassword, onChange, onSubmit, errors = {}, loading }) {
	const isError = Object.keys(errors).length !== 0;
	return (
		<Form size="large" onSubmit={onSubmit} error={isError}>
			<Message error content={errors.non_field_errors} />
			<Form.Input
				name="password"
				fluid
				icon="lock"
				iconPosition="left"
				placeholder="Password"
				type="password"
				value={password}
				onChange={onChange}
				autoComplete="password"
				error={!!errors.password}
			/>
			<Form.Input
				name="confirmPassword"
				fluid
				icon="lock"
				iconPosition="left"
				placeholder="Confirm Password"
				type="password"
				value={confirmPassword}
				onChange={onChange}
				autoComplete="password"
				error={!!errors.confirmPassword}
			/>
			<Message error content={errors.confirmPassword} />
			<Button primary={true} fluid size="large" loading={loading}>
				SAVE PASSWORD
			</Button>
			<Message error content={errors.auth} />
		</Form>
	);
}
