import { 
	CREATE_SUBSCRIPTION_SUCCESS,
	CREATE_SUBSCRIPTION_FAILURE,
	FETCH_SUBSCRIPTION_SUCCESS,
	FETCH_SUBSCRIPTION_FAILURE,
	ACTIVATE_SUBSCRIPTION_SUCCESS,
	ACTIVATE_SUBSCRIPTION_FAILURE,
	DEACTIVATE_SUBSCRIPTION_SUCCESS,
	DEACTIVATE_SUBSCRIPTION_FAILURE,
	REMOVE_SUBSCRIPTION_SUCCESS,
	REMOVE_SUBSCRIPTION_FAILURE
} from '../types';

export default function subscriptions (state = [], action) {
	switch (action.type) {
		case CREATE_SUBSCRIPTION_SUCCESS:
			console.log("IN REDUCER, CREATE SUBSCRIPTION")
			return [ ...state, action.payload ];
		case FETCH_SUBSCRIPTION_SUCCESS:
			return [ ...action.payload ];
		case CREATE_SUBSCRIPTION_FAILURE:
		case FETCH_SUBSCRIPTION_FAILURE:
			return [];
		case REMOVE_SUBSCRIPTION_SUCCESS: 
			console.log(action.payload)

		// case DEACTIVATE_API_KEY_SUCCESS:
		// 	const keyToDeactivate = [...state].find(el => el.key === action.payload);
		// 	const indexToDeactivate = [...state].indexOf(keyToDeactivate)
		// 	const inactiveKey = {...keyToDeactivate, state: 'INACTIVE'} 
		// 	return [...state].slice(0, indexToDeactivate).concat(inactiveKey).concat([...state].slice(indexToDeactivate + 1))
		// case DEACTIVATE_API_KEY_FAILURE:
		// 	return [...state];

		// case ACTIVATE_API_KEY_SUCCESS:
		// 	const keyToActivate = [...state].find(el => el.key === action.payload);
		// 	const indexToActivate = [...state].indexOf(keyToActivate)
		// 	const newKey = {...keyToActivate, state: 'ACTIVE'} 
		// 	return [...state].slice(0, indexToActivate).concat(newKey).concat([...state].slice(indexToActivate + 1))
		// case ACTIVATE_API_KEY_FAILURE:
		// 	return [...state];

		// case DELETE_API_KEY_SUCCESS:
		// 	return [...state].filter(el => el.key !== action.payload);
		// case DELETE_API_KEY_FAILURE:
		// 	return [...state];

		default:
			return state;
	}
}

export const getSubscriptions = (state) => state;