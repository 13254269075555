import React from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getTime } from 'date-fns';

export default function AdvNFTSearchForm (props) {

	const { onChange, advanceSearchParams } = props;
	return (
		<Form>
				<React.Fragment>
					<Form.Group widths="equal">
						<Form.Input
							fluid
							label="Collection Name"
							name="collectionName"
							value={advanceSearchParams.collectionName}
							onChange={onChange}
							placeholder="e.g. 0.0.3"
						/>
						<Form.Input
							fluid
							label="NFT Name"
							name="nftName"
							value={advanceSearchParams.nftName}
							onChange={onChange}
							placeholder="e.g. 0.0.3208"
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Input
							fluid
							label="Owner"
							name="owner"
							value={advanceSearchParams.owner}
							onChange={onChange}
							placeholder="e.g. 0.0.9054"
						/>
						<Form.Input
							fluid
							label="NFT Creator"
							name="nftCreator"
							value={advanceSearchParams.nftCreator}
							onChange={onChange}
							placeholder="e.g. 0.0.9054"
						/>
					</Form.Group>

					<Form.Field style={{ margin: '0 0 .28571429rem 0' }}>
						<label>Collection Creation Date</label>
					</Form.Field>
					<Form.Group widths="equal">
						<Form.Field>
							<DatePicker
								name="collectionCreationDateFrom"
								selected={advanceSearchParams.collectionCreationDateFrom ? new Date(advanceSearchParams.collectionCreationDateFrom) : null}
								onChange={(date) => {
									onChange(null, { name: 'collectionCreationDateFrom', value: date ? getTime(date) : '' });
								}}
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="time"
								dateFormat="d MMM yyyy, h:mm aa"
								placeholderText="Date From"
								minDate={new Date(2018, 8, 1)}
								maxDate={new Date()}
								autoComplete="off"
							/>
						</Form.Field>
						<Form.Field>
							<DatePicker
								name="collectionCreationDateTo"
								selected={advanceSearchParams.collectionCreationDateTo ? new Date(advanceSearchParams.collectionCreationDateTo) : null}
								onChange={(date) => {
									onChange(null, { name: 'collectionCreationDateTo', value: date ? getTime(date) : '' });
								}}
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="time"
								dateFormat="d MMM yyyy, h:mm aa"
								placeholderText="Date To"
								minDate={advanceSearchParams.collectionCreationDateFrom ? new Date(advanceSearchParams.collectionCreationDateFrom) : null}
								maxDate={new Date()}
								autoComplete="off"
							/>
						</Form.Field>
					</Form.Group>
					
					<Form.Field style={{ margin: '0 0 .28571429rem 0' }}>
						<label>Nft Mint Date</label>
					</Form.Field>
					<Form.Group widths="equal">
						<Form.Field>
							<DatePicker
								name="nftMintDateFrom"
								selected={advanceSearchParams.nftMintDateFrom ? new Date(advanceSearchParams.nftMintDateFrom) : null}
								onChange={(date) => {
									onChange(null, { name: 'nftMintDateFrom', value: date ? getTime(date) : '' });
								}}
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="time"
								dateFormat="d MMM yyyy, h:mm aa"
								placeholderText="Date From"
								minDate={new Date(2018, 8, 1)}
								maxDate={new Date()}
								autoComplete="off"
							/>
						</Form.Field>
						<Form.Field>
							<DatePicker
								name="nftMintDateto"
								selected={advanceSearchParams.nftMintDateto ? new Date(advanceSearchParams.nftMintDateto) : null}
								onChange={(date) => {
									onChange(null, { name: 'nftMintDateto', value: date ? getTime(date) : '' });
								}}
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="time"
								dateFormat="d MMM yyyy, h:mm aa"
								placeholderText="Date To"
								minDate={advanceSearchParams.nftMintDateFrom ? new Date(advanceSearchParams.nftMintDateFrom) : null}
								maxDate={new Date()}
								autoComplete="off"
							/>
						</Form.Field>
					</Form.Group>
					
				</React.Fragment>
		</Form>
	);
}
