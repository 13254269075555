import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Stat from '../shared/Stat';
import LineChart from '../shared/LineChart';
import * as actions from '../../redux/actions';
import { getTokenTxnVolume, getTokenDetails, getTokenTxnVolumeLoader } from '../../redux/reducers/hts';
import { formatTimestamp, parseQueryParams } from '../../utils';
import LoadingIndicator from "./LoadingIndicator";

class TokenTxnVolumeChart extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.period !== prevProps.period) {
			this.fetchData();
		}
	}

	fetchData() {
		const { fetchTokenTxnVolume, period, tokenId } = this.props;
		fetchTokenTxnVolume({tokenId: tokenId, interval: period});
		this.props.sendTokenIdparam(tokenId);
  }

	render() {
    const { volume: { totalSum, pctChange, data }, period } = this.props;
		const formatter = (date) => formatTimestamp(date, period);
		const valueFormatter = (value, name, props) => [ new Intl.NumberFormat('en').format(value) + ' txns' ];
		if (!this.props.loading) {
			return (
				data && data.length ?
					<Fragment>
						<Stat count={totalSum} pct={pctChange} label="txns" />
						<br />
						<LineChart
							data={data}
							tickFormatter={formatter}
							labelFormatter={formatter}
							valueFormatter={valueFormatter}
						/>
					</Fragment>
				: <div>No transactions available for the selected period</div>
			);
		} else {
			return <LoadingIndicator />
		}
	}
}

const mapStateToProps = (state, { match: { params: { tokenId } } }) => {
	const params = parseQueryParams(window.location.search);
	return ({
		tokenId: tokenId ? tokenId : params.tokenId,
		volume: getTokenTxnVolume(state),
		loading: getTokenTxnVolumeLoader(state),
  })
};

TokenTxnVolumeChart = withRouter(connect(mapStateToProps, actions)(TokenTxnVolumeChart));

export default TokenTxnVolumeChart;
