import React, { Component } from 'react';
import Panel from './shared/Panel';
import Filter from './shared/Filter';
import AccountTransactions from './containers/AccountTransactions';
import Info from './shared/Info';

export default class AccountTransactionsPanel extends Component {
	state = {
		q: ''
	};

	onSubmitFilter = (value) => {
		this.setState({ q: value });
	};

	render() {
		const { q } = this.state;
		return (
			<Panel
				title={
					<React.Fragment>
						{/* TRANSACTIONS <Info id="txns" /> */}
						<Info id="txns" />
					</React.Fragment>
				}
				description=""
				toolbar={
					<React.Fragment>
						<Filter onSubmitFilter={this.onSubmitFilter} />
						{/* <PeriodSelector /> */}
					</React.Fragment>
				}
			>
				<AccountTransactions filter={q} />
			</Panel>
		);
	}
}
