import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ApiCopyUrl from './ApiCopyUrl';

import '../animate.css';

const APICodeJava = ({api}) => {

    const handleCopyClick = () => {
        audio.play();
        setClicked(true);
        setTimeout(() => setClicked(false), 2000)
    }
    
    const audio = new Audio('/click.mp3');

    const [ clicked, setClicked ] = useState(false);

    const codeString =

`OkHttpClient client = new OkHttpClient().newBuilder()
.build();
Request request = new Request.Builder()
.url("${api.url}")
.method("GET", null)
.addHeader("X-API-KEY", "<YOUR_ACCESS_KEY>")
.build();
Response response = client.newCall(request).execute();`;

    return (

        <div style={{overflowWrap: "anywhere", fontSize: "95%"}}>

            <CopyToClipboard text={codeString}
                onCopy={() => handleCopyClick({copied: true})}>
                <span style={{marginLeft: "1%", position: "relative", top: "-10px"}}><ApiCopyUrl clicked={clicked} /></span>
            </CopyToClipboard>

            <div style={{position: "relative", top: "-18px"}}>
                <SyntaxHighlighter language="java" style={docco}>
                        {codeString}
                </SyntaxHighlighter>
            </div>

        </div>
    );

};
export default APICodeJava;