import React, { Component } from 'react';
import Panel from './shared/Panel';
import PeriodSelector from './shared/PeriodSelector';
import Info from './shared/Info';
import AccountBalanceChart from './containers/AccountBalanceChart';
import { parseQueryParams } from  '../utils';
import { withRouter } from 'react-router-dom';
import Share from './shared/Share';

// export default () => (
// 	<Panel title="BALANCE HISTORY" description="">
// 		<AccountBalanceChart />
// 	</Panel>
// );

export class AccountBalanceChartPanel extends Component {
	state = {
		period: '1W',
		accountId: ''
	};

	componentDidMount() {
		const params = parseQueryParams(this.props.location.search);
		if(params && params.period) {
			this.setState({
					period: params.period,
			});
		}
	}

	onPeriodSelect = (e, { value }) => {
		this.setState({ period: value });
		//to do
		//update price and percent change
	};

	sendAccountIdparam = (id) => {
		this.setState({accountId : id})
	}

	render() {
		const { period } = this.state;
		let url = `/chart?name=acc-bal&period=${this.state.period}&accountId=${this.state.accountId}`;
		const params = parseQueryParams(this.props.location.search);
		return (
			<Panel
				title={
					<React.Fragment>
						BALANCE HISTORY <Info id="balanceHistory" />
					</React.Fragment>
				}
				titleClass="light"
				description=""
				footer={ <Share url={url} params={params} title={"Dragon Glass Account Balance Chart"}/>}
				toolbar={<PeriodSelector value={period} onSelect={this.onPeriodSelect} />}
			>
				{/* History coming soon */}
				<AccountBalanceChart period={period} sendAccountIdparam={this.sendAccountIdparam}/>
			</Panel>
		);
	}
}

export default withRouter(AccountBalanceChartPanel);
