import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './shared/DataTable';
import ContractRow from './ContractRow';
import ContractTableHeader from './ContractTableHeader';

const ContractTable = ({ contracts = [], limit, offset, sort, order, totalCount, loadData }) => {
	const ContractRows = contracts.map((contract, index) => <ContractRow key={index} contract={contract} />);
	return (
		<DataTable
			limit={limit}
			offset={offset}
			sort={sort}
			order={order}
			totalCount={totalCount}
			header={ContractTableHeader}
			rows={ContractRows}
			loadData={loadData}
		/>
	);
};

export default ContractTable;

ContractTable.propTypes = {
	totalCount: PropTypes.number.isRequired,
	loadData: PropTypes.func.isRequired
};
