import React from 'react';
//import _ from 'lodash';
import { Form, /*Search, Label,*/ Input, Button, Placeholder } from 'semantic-ui-react';

//const categoryRenderer = ({ name }) => <Label as="span" content={name} />;
export default function Autocomplete({ onSubmit, onSearchChange, onlyHts, showSearchBtn = true, ...rest }) {
	console.log('showSearchBtn', showSearchBtn);
	return (
		<div className="search-home-page">
			<Form onSubmit={onSubmit}>
				<Input className="search-input"
					style={{height: !showSearchBtn ? '40px' : ''}}
					fluid
					size="mini"
					action={
						showSearchBtn ?
						<div className="ui  input-button__wrapper">
							<Button primary content="SEARCH" size="tiny" onClick={onSubmit} />
						</div>
						: <div></div>
					}
					icon="search"
					iconPosition="left"
					placeholder={onlyHts ? "Search Hedera Tokens" : "Search by Transaction ID/Hash, Account ID, ContractID, Topic ID, Hedera Token ID, or memo field"}
					onChange={onSearchChange}
					// {...rest}
				/>
				{/* <Search
					fluid
					category
					categoryRenderer={categoryRenderer}
					input={
						<Input
							fluid
							action={
								<div className="ui  input-button__wrapper">
									<Button primary content="SEARCH" size="tiny" />
								</div>
							}
							icon="search"
							iconPosition="left"
							placeholder="Search transactions"
						/>
					}
					onSearchChange={_.debounce(onSearchChange, 500, {
						leading: true
					})}
					{...rest}
				/> */}
			</Form>
		</div>
	);
}
