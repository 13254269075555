import React, { useState, Fragment } from 'react';
import SubscriptionFormNew from './forms/SubscriptionFormNew';
import { Button, Icon, Modal, Header, Message, Popup} from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getUser } from '../redux/reducers';

import '../animate.css';    

import { CSVLink } from "react-csv";

const SubscribeNew = (
    { 
        handleCancel, 
        handleSubscribeMessage, 
        subscribe, 
        user, 
        uploadFiles, 
        createSubscription, 
        subscriptionModels,
    }) => {

    const [form, setForm] = useState({
        user: user.email,
        model: '',
        transactionType: [],
        subscriptionType: '',
        iAmUser: ''
    });

    const csvData = [
        {secretKey: "secret_key", accessKey: "access_key"}
    ]

    var parser = require('aws-arn-parser');

    const [errors, setErrors] = useState({});

    // const [displayKeys, setDisplayKeys] = useState(true);
    const [displayKeys, setDisplayKeys] = useState(false);
    const [iamOpen, setIamOpen] = useState(false);

    const handleIamCancel = () => {
        setIamOpen(false);
        handleCancel();
        createSubscription(form);
        window.location.reload();
    }

    const onSubmit = async e => {
        e.preventDefault();
        const errors = validate(form);
        setErrors(errors);
        console.log("THIS IS THE FORM: ", form);
        if (displayKeys) {
            setIamOpen(true);
        } else {
            if (Object.keys(errors).length === 0) {
            // console.log("PARSED IAMUSER: ", parser(form.iAmUser))
            console.log(form)
            handleCancel();
            createSubscription(form);
            window.location.reload();
            }
        }
    }

    // const onIamSelection = value => {
    //     value === "default" ? setDisplayKeys(true) : setDisplayKeys(false);
    // }

    const addArn = (arn) => {
        console.log("THIS IS SUPPOSED TO BE URI ENCODED ARN: ", arn.replace(/:/g, '%3A').replace(/\//g, '%2F'))
        setForm({ ...form, iAmUser: [ ...form.iAmUser, arn.replace(/:/g, '%3A').replace(/\//g, '%2F') ]})
    }

    const onModelSelect = (e) => {
        console.log("ON MODEL SELECT CLICKED", e.target.textContent);
        setForm({ ...form, model: e.target.textContent })
    }

    const onSubscriptionTypeSelect = (e) => {
        console.log("ON TYPE SELECT CLICKED", e.target.textContent);
        setForm({ ...form, subscriptionType: e.target.textContent })
    }

    const onTransactionTypeSelect = (e, data) => {
        console.log("TRANSACTION TYPE SELECT: ", e.target.textContent);
        console.log("TRANSACTION TYPE SELECT - DATA: ", data.value);
        setForm({ ...form, transactionType: data.value })
    }

    const onInputChange = e => {
        setForm({ ...form, iAmUser: e.target.value })
    }

    const validate = (form) => {
        const { user, model, transactionType, subscriptionType, iAmUser } = form;
        let errors = {};
        // let iam = parser(iAmUser);
        // if (!company || company.trim().length === 0) errors.company = "Company name can't be blank";
        // if (!title || title.trim().length === 0) errors.title = "Title can't be blank";
        // if (!accountId || accountId.trim().length === 0) errors.accountId = "Account Id can't be blank";
        // if (!contractId || contractId.trim().length === 0) errors.contractId = "Contract Id can't be blank";

        // if (Object.keys(iam).length < 3) {errors.iAmUser = "Invalid ARN format"; console.log("ERRORS IN SUBSCRIPTION: ", errors)}

        return errors;
    }

    // const { selectedModel, date, dataPeriod, count } = form;

    return (
        <Fragment>
            <SubscriptionFormNew 
                // selectedModel={selectedModel}
                // date={date} 
                // dataPeriod={dataPeriod}
                // count={count}

                // setSelectedModel={setSelectedModel}
                // setDate={setDate}
                // setDataPeriod={setDataPeriod}
                // setCount={setCount}

                subscriptionModels={subscriptionModels}

                onModelSelect={onModelSelect}
                onSubscriptionTypeSelect={onSubscriptionTypeSelect}
                onTransactionTypeSelect={onTransactionTypeSelect}

                onInputChange={onInputChange}

                addArn={addArn}

                // onIamSelection={onIamSelection}

                handleSubscribeMessage={handleSubscribeMessage}
                handleCancel={handleCancel}
                onSubmit={onSubmit}
                errors={errors}
            />

            {/* ---------------- IAM SECRET KEY AND ACCESS KEY ---------------- */}
            <Modal open={iamOpen} closeIcon onClose={handleIamCancel} dimmer="blurring" className="animated bounceInDown">
				<Modal.Header className="modal-header">Your Secret Key and Access Key</Modal.Header>
				<Modal.Content>
                <Message positive>
                    <Message.Header>Here are your Secret Key and Access Key</Message.Header>
                    <p>This is the only time your keys can be viewed or downloaded.</p>
                </Message><br />
                <CSVLink filename="IAMAccessKeys.csv" data={csvData}><Button icon labelPosition='left'><Icon name='download' />Click to download your keys</Button></CSVLink>
				<br /><br /><br />
				<Modal.Description>
					<Header>Your Secret Key:</Header>
					<p>secret_key</p>
					<Header>Your Access Key:</Header>
					<p>access_key</p>
				</Modal.Description>
				</Modal.Content>
			</Modal>
            {/* --------------------------------------------------------------- */}
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default connect(mapStateToProps, actions)(SubscribeNew);
