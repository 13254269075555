import React, { Component } from 'react';
import Panel from './shared/Panel';
import { Button } from 'semantic-ui-react';
import ContractCode from './containers/ContractCode';

export default class ContractCodePanel extends Component {
	onValidate = (e) => {};
	render() {
		return (
			<Panel
				title={<React.Fragment>CONTRACT CODE</React.Fragment>}
				toolbar={<Button primary basic content="VALIDATE" disabled />}
			>
				<ContractCode />
			</Panel>
		);
	}
}
