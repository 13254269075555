import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Table, Button } from 'semantic-ui-react';
import { getUser } from '../redux/reducers';
import subscriptions from '../redux/reducers/subscriptions';

const DappContractsTable = ({
    contracts,
    dapp,
    handleAddContract,
    handleCancel,
    handleAddFilters, 
    filters,
    errors,
    deleteFilter
}) => {

    console.log("DAPP IN DAPP CONTRACTS TABLE: ", dapp)

    const rowsToDisplay = contracts.map(contract => {
        return (
            <Table.Row key={Math.random()}>
                <Table.Cell width={3}><span className="text-gray">{contract.id}</span></Table.Cell>

                {/* <Table.Cell style={{wordWrap: 'break-word', wordBreak: 'break-word', overflow: "auto"}}>
                    {filter.ids && filter.ids.map(id => filter.ids.indexOf(id) === filter.ids.length - 1 ? ` ${id} ` : ` ${id}, `)}
                </Table.Cell> */}

                <Table.Cell width={3}><span className="text-gray">{contract.primary ? "YES" : "NO"}</span></Table.Cell>

                {/* <Table.Cell
                    // className={sub.expiry.slice(0, 10) <= date && "disabled"}
                    style={{cursor: "pointer", textAlign: "center"}}
                    onClick={e => deleteFilter(filter, subscription)}
                    width={1}>
                    <i className="close icon red"></i>
                </Table.Cell> */}
            </Table.Row>
        )
    })

    return (
        <div>

            <Button 
                type="submit" 
                style={{margin: "30px 0 20px 0", float: "right"}} 
                primary 
                // disabled={ids.length === 0} 
                onClick={e => {/*onSubmit(e);*/ handleAddContract(dapp)}}
                >
                ADD CONTRACT
            </Button>

            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>CONTRACT ID</Table.HeaderCell>
                    {/* <Table.HeaderCell>
                        {subscription.subscriptionType === "EVENT" ? `Contract ID's` : "Values"}
                    </Table.HeaderCell> */}
                    <Table.HeaderCell>PRIMARY</Table.HeaderCell>
                    {/* <Table.HeaderCell>Delete</Table.HeaderCell> */}
                </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rowsToDisplay}
                </Table.Body>
            </Table>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state)
});

export default connect(mapStateToProps, actions)(DappContractsTable);