import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../redux/actions';
import Panel from './shared/Panel';
import { List, Button, Icon } from 'semantic-ui-react';
import { getCategories, isAuthed, getKeys } from '../redux/reducers';
import APIGetList from './APIGetList';
import APIIntroList from './APIIntroList';

const APISelectPanelNew = (props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchData() }, [])

  const fetchData = async () => {
    if (props.isAuthed) {
      await props.fetchApiKey();
    }
    props.fetchApiCategories();
  }

  const handleOnClick = (cat) => {
    console.log("CLICKED: ", cat)
    // setSelected(cat.title);
    cat.id === "0" && props.handleCategorySelect(cat);
  }

  const [selected, setSelected] = useState('');

  // props.categories && console.log('CATEGORIES IN API: ', props.categories)

  const introduction = {id: '0', title: 'Getting Started', description: ''}
  const ids = [];
  props.categories.map(cat => ids.push(Object.values(cat)[0]));
  !ids.includes("0") && props.categories.unshift(introduction);

  console.log("ACTIVE API IN SELECT PANEL: ", props.activeApi)

  return (
    <Panel
      title={
        <Fragment>
          PUBLIC API SELECTION
          {(!props.isAuthed || props.keys.length < 1) && <div className="api-button-div">
          <Link to="/profile"><Button primary className="api-button-small"><Icon name="key" />GENERATE API KEY</Button></Link>
          </div>}
        </Fragment>
      }
    >
      <div style={{height: "3px"}}></div>
      <List divided relaxed /*className="categories-list"*/>
        {props.categories.map(
          cat => <List.Item
          id="padded"
          key={cat.id}
          // className={selected===cat.title ? "selected" : ""}
          // active={selected===cat.title ? true : false}
          // onClick={() => handleOnClick(cat) }
          >
          <span className={cat.id === "0" ? "clickable" : ""} style={{fontWeight: "600"}} onClick={() => handleOnClick(cat) }>{cat.title}</span>
          <div>
            {cat.id === "0" ? <APIIntroList cat={cat} handleCategorySelect ={props.handleCategorySelect} /> :
            <APIGetList cat={cat} apis={props.apis} handleApiSelect={props.handleApiSelect} activeApi={props.activeApi} />}
            {/* <APIGetList cat={cat} apis={props.apis} handleApiSelect={props.handleApiSelect} activeApi={props.activeApi} /> */}
          </div>
        </List.Item>)}
      </List>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  categories: getCategories(state),
  isAuthed: isAuthed(state),
  keys: getKeys(state)
});

export default connect(mapStateToProps, actions)(APISelectPanelNew);