import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Image, Popup, Grid, Icon } from 'semantic-ui-react';
import Number from './shared/Number';
import eye from '../assets/images/eye@2x.png';
import { decodeBase64, isImgUrl } from '../utils';
import Base64Icon from "../assets/images/icons8-base-64-24.png";
import TxtIcon from "../assets/images/icons8-txt-24.png";
import DisplayContent from "../atoms/DisplayContent";

const NftsTokenBalanceRow = ({ nftsBalance }) => {
	const [showText, setShowText] = useState(true);
    const handleShowTextClick = () => {
        setShowText(!showText);
    };

	const redirectTo = (nftData) => {
		return window.location.href =`/nft-token/${nftData.token}?serialNumber=${nftData.serialNumber}`;
	};

	return (
		<Table.Row style={{cursor: "pointer"}} className="hoverRow">
			<Table.Cell collapsing onClick={()=> redirectTo(nftsBalance)}>
				<Grid.Column column={1}>
					{/* {
						nftsBalance.imageUrl && isImgUrl(nftsBalance.imageUrl)
						? <Image style={{margin: '0 auto', width: '15%'}} alt="NFT Image" src={nftsBalance.imageUrl} />
						: <Icon style={{margin: '0 auto', width: '100%'}} name="video"></Icon>
					} */}
					{
						<DisplayContent size={'medium'} mimeType={nftsBalance.dataType} src={nftsBalance.imageUrl} alt="NFT"/>
					}
				</Grid.Column>
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(nftsBalance)}>
				<span>{nftsBalance.serialNumber}</span>
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(nftsBalance)}>
				{nftsBalance.owner}
				{/* {nftsBalance.owner != "0.0.0" ?
					<Link to={`/accounts/${nftsBalance.owner}`}>{nftsBalance.owner}</Link>
					: <>{nftsBalance.owner}</>
				} */}
			</Table.Cell>
			<Table.Cell collapsing width={2} style={{cursor: "default"}}>
				<div onClick={()=> redirectTo(nftsBalance)} title={showText ? decodeBase64(nftsBalance.metaData) : nftsBalance.metaData}
				style={{
					overflow: "hidden",
					maxWidth: showText ? "200px" : "200px",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					position: "relative",
					top: "8px"
				}}>
				{showText ? decodeBase64(nftsBalance.metaData) : nftsBalance.metaData}
				</div>
					{ nftsBalance && nftsBalance.metaData
						?<>
						<span style={{"position": "relative", "bottom": "12px"}}>
							{ showText 
								? <Image src={Base64Icon} className="copy-icon" size="mini" style={{ width: '18px', height: '18px' }} onClick={handleShowTextClick}/>
								: <Image src={TxtIcon} className="copy-icon" size="mini" style={{ width: '18px', height: '18px' }} onClick={handleShowTextClick}/>
							}
						</span>
						</>
						: null
					}
			</Table.Cell>
			<Table.Cell collapsing onClick={()=> redirectTo(nftsBalance)}>
				<span>{nftsBalance.deleted == false ? "NO" : "YES"}</span>
			</Table.Cell>
			{/* <Table.Cell textAlign="center" collapsing>
				<Link to={`/nft-token/${nftsBalance.token}?serialNumber=${nftsBalance.serialNumber}`}>
				<Popup
					content="Nfts Token Details"
					trigger={<Image src={eye} rounded size="mini" style={{ width: '28px' }} />}
				/>
				</Link>
      		</Table.Cell> */}
		</Table.Row>
	);
};

export default NftsTokenBalanceRow;

NftsTokenBalanceRow.propTypes = {
	nftsBalance: PropTypes.object.isRequired
};
