import React, { useState } from 'react';
import SubscriptionFiltersForm from './forms/SubscriptionFiltersForm';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { getUser } from '../redux/reducers';

const SubscriptionFilters = ({ 
    handleCancel, 
    handleSubscribeMessage, 
    subscribe, 
    user, 
    uploadFiles, 
    createSubscription, 
    subscription, 
    addSubscriptionFilters
    }) => {

    console.log("THIS IS SUPPOSED TO BE THE QUEUE NAME: ", subscription.queueName)

    const [form, setForm] = useState({
        queueName: subscription.queueName,
        filterType: '',
        ids: [],
        signature: [],
        user: user.email
    });

    const [filterValuesFieldName, setFilterValuesFieldName] = useState("FILTER VALUES");

    // const setSelectedModel = (model) => {
    //     setForm({ ...form, selectedModel: model });
    // }

    // const setDate = (date) => {
    //     setForm({ ...form, date: date, period: '', count: '' });
    // }

    // const setDataPeriod = (period) => {
    //     setForm({ ...form, period: period, date: '', count: '' });
    // }

    // const setCount = (count) => {
    //     setForm({ ...form, count: count, date: '', period: '' });
    // }

    const [errors, setErrors] = useState({});
    // const [loading, setLoading] = useState(false);

    const onSubmit = async e => {
        console.log('ON SUBMIT IS CALLED')
        e.preventDefault();
        // const errors = validate(form);
        // setErrors(errors);
        // if (Object.keys(errors).length === 0) {
        handleCancel();
        console.log("THIS IS THE FORM: ", form);
        addSubscriptionFilters(form);
        // }
    }

    const onModelSelect = (e) => {
        console.log("ON MODEL SELECT CLICKED", e.target.textContent);
        setForm({ ...form, model: e.target.textContent })
    }

    const onFilterTypeSelect = (e) => {
        console.log("ON TYPE SELECT CLICKED", e.target.textContent);
        setForm({ ...form, filterType: e.target.textContent });
        e.target.textContent === "FUNCTION_NAME" && setFilterValuesFieldName("CONTRACT IDs");
    }

    const handleFilterValues = (filter) => {
        setForm({ ...form, ids: [ ...form.ids, filter] });
        console.log("THIS IS THE FORM IN FILTERS: ", form)
    }

    const handleSignature = (sign) => {
        setForm({ ...form, signature: [ ...form.signature, sign] });
        console.log("THIS IS THE FORM IN FILTERS: ", form)
    }

    const deleteFilterValue = (value) => {
        const newFilterValues = form.ids.filter(el => el !== value);
        setForm({ ...form, ids: newFilterValues });
        console.log("THIS IS THE FORM IN FILTERS AFTER REMOVE: ", form)
    }

    const deleteSignature = (sign) => {
        const newSignature = form.signature.filter(el => el !== sign);
        setForm({ ...form, signature: newSignature });
        console.log("THIS IS THE FORM IN FILTERS AFTER REMOVE: ", form)
    }

    const onTransactionTypeSelect = (e, data) => {
        console.log("TRANSACTION TYPE SELECT: ", e.target.textContent);
        console.log("TRANSACTION TYPE SELECT - DATA: ", data.value);
        setForm({ ...form, transactionType: data.value })
    }

    // const validate = (form) => {
    //     const { queueName, user, ids } = form;
    //     let errors = {};
    //     if (!queueName || queueName.trim().length === 0) errors.queueName = "Queue Name name can't be blank";
    //     if (!user || user.trim().length === 0) errors.user = "User can't be blank";
    //     if (!ids || ids.length === 0) errors.accountId = "Ids can't be blank";

    //     return errors;
    // }

    // const { selectedModel, date, dataPeriod, count } = form;

    return (
        <SubscriptionFiltersForm 
            // selectedModel={selectedModel}
            // date={date} 
            // dataPeriod={dataPeriod}
            // count={count}

            // setSelectedModel={setSelectedModel}
            // setDate={setDate}
            // setDataPeriod={setDataPeriod}
            // setCount={setCount}

            subscription={subscription}
            ids={form.ids}
            signature={form.signature}

            onModelSelect={onModelSelect}
            onFilterTypeSelect={onFilterTypeSelect}
            handleFilterValues={handleFilterValues}
            deleteFilterValue={deleteFilterValue}
            handleSignature={handleSignature}
            deleteSignature={deleteSignature}
            onTransactionTypeSelect={onTransactionTypeSelect}

            handleSubscribeMessage={handleSubscribeMessage}
            handleCancel={handleCancel}
            onSubmit={onSubmit}
            errors={errors}

            filterValuesFieldName={filterValuesFieldName}
        />
    )
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default connect(mapStateToProps, actions)(SubscriptionFilters);
