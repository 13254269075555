import React from 'react';
import { Table } from 'semantic-ui-react';

export default function HederaTokenBalanceTableHeader(props) {
	return (
		<Table.Row>
			<Table.HeaderCell
				width={1}
				sorted={props.column === 'tokenId' ? props.direction : null}
				onClick={() => props.handleSort('tokenId')}
			>
				Token ID
			</Table.HeaderCell>
			<Table.HeaderCell
        width={1}
        sorted={props.column === 'balance' ? props.direction : null}
				onClick={() => props.handleSort('balance')}
			>
				Balance
			</Table.HeaderCell>
			{/* <Table.HeaderCell width={1} /> */}
		</Table.Row>
	);
}
